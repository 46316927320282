import * as React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from "./index.module.scss";
import OrderLoading from 'components/Loading/OrderLoading';
import { useState, useCallback, useEffect } from 'react';
import { useStore, StoreTypes } from "context";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';

let codeUsageStart = null
let codeUsageEnd = null
let durationValue = 366


const limitType = {
	"是": true,
	"否": false
}

const SelectCustomOrderCode = (props) => {
	const { naniProducts } = props
	const [{ token, userId, name, isLogin }, userDispatch] = useStore(StoreTypes.user);
	const params = (new URL(document.location)).searchParams;
	const [productsList, setProducts] = useState(naniProducts)
	const [purchasedProducts, setPurchasedProducts] = useState([]);
	const [enterOrderId, setEnterOrderId] = useState('');
	const [description, setDescription] = useState('');
	const [loading, setLoading] = useState(false)
	const [startTime, setStartTime] = useState(dayjs());
	const [endTime, setEndTime] = useState(dayjs().add(1, 'year'));
	const [usageDurationValue, setUsageDurationValue] = useState(366);

	const [remain, setRemain] = useState(1)
	const [personalLimit, setPersonalLimit] = useState(1)
	const [isLimitPersonal, setIsLimitPersonal] = useState("是")
	const [isLimitTotal, setIsLimitTotal] = useState("是")

	useEffect(() => {
		if (naniProducts) {
			setProducts(naniProducts)
		}
	}, [naniProducts])

	const change = useCallback((e) => {
		if (e.target.value) {
			if (e.target.value.length > 0) {
				var keyWords = e.target.value.split(';');
				const list = []
				naniProducts.forEach((info) => {
					for (var j = 0; j < keyWords.length; j++) {
						if (info.product.indexOf(keyWords[j]) >= 0) {
							list.push(info)
						}
					}

				})
				setProducts(list)
			}
		} else {
			setProducts(naniProducts)
		}
	}, [naniProducts])

	const addProductsHandler = useCallback((e, products) => {
		const hasProduct = purchasedProducts.some((info) => info.product === products.product)
		!hasProduct && setPurchasedProducts([...purchasedProducts, products])
	}, [purchasedProducts])

	const removeProductsHandler = useCallback((e, products) => {
		const hasProduct = purchasedProducts.some((info) => info.product === products.product)
		let arr = []
		arr = arr.concat(purchasedProducts)
		if (hasProduct) {
			arr = arr.filter((info) => info.product !== products.product)
		} else {
			arr.push(products)
		}
		arr.sort()
		setPurchasedProducts(arr)
	}, [purchasedProducts])

	const selectAllProduct = useCallback(() => {
		const arr = productsList.filter((info) => {
			return !purchasedProducts.find((products) => products.product === info.product)
		})
		console.log(arr);
		const newList = [...purchasedProducts, ...arr]
		newList.sort((a, b) => a.codes > b.codes)
		setPurchasedProducts([...purchasedProducts, ...arr])
	}, [purchasedProducts, productsList])

	const createExchangeCode = useCallback(({ enterOrderId, description, items, remain, personalLimit, isLimitPersonal, isLimitTotal }) => {
		fetch(`${process.env.REACT_APP_API_DOMAIN}/order/createCode`, {
			method: "POST",
			body: JSON.stringify({
				codeName: enterOrderId,
				items: items,
				description,
				codeUsageStart: startTime,
				codeUsageEnd: endTime,
				usageDurationValue: usageDurationValue,
				available: {
					remain,
					personalLimit,
					isLimitPersonal: limitType[isLimitPersonal],
					isLimitTotal: limitType[isLimitTotal],
				}
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
				"Authorization": token
			}
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (response) {
				setLoading(false)
				if (response.status == "success") {
					alert("創建成功!!")
				}
				else {
					alert(`創建失敗:${response.error.message}`);
				}
			})
			.catch(function (err) {
				setLoading(false)
				alert(`創建失敗:${err.message ? err.message : err}`);
			});
	}, [token, startTime, endTime, usageDurationValue])

	const sendOrder = useCallback(async () => {
		if (!description) {
			alert("請輸入備註")
			return
		}
		if (!enterOrderId) {
			alert("請輸入兌換碼")
			return
		}

		var items = [];
		var codeNum = 1;

		purchasedProducts.forEach((productInfo) => {
			const { code, product } = productInfo
			items.push({
				code: code,
				product: product
			});
		})
		setLoading(true)
		createExchangeCode({ enterOrderId, description, items, remain, personalLimit, isLimitPersonal, isLimitTotal })
	}, [enterOrderId, description, purchasedProducts, naniProducts, remain, personalLimit, isLimitPersonal, isLimitTotal])


	const clearSelectProductList = useCallback(() => {
		setPurchasedProducts([])
	}, [setPurchasedProducts])

	return (
		<div className={styles.SelectCustomOrderCode}>
			{loading && <OrderLoading message={"兌換碼創建中..."} />}
			<div className={styles.tabs}>
				<div
					id="mainLeft"
					className={styles.leftSelect}
				>
					<h5>所有產品列表</h5>
					<TextField
						id="outlined-helperText"
						style={{ width: '95%' }}
						label=""
						defaultValue=""
						helperText="關鍵字 (以半形分號 ; 查詢關鍵字聯集)"
						variant="standard"
						onChange={(e) => change(e)}
					/>
					<div
						id="naniVideos"
						className={styles.productList}
					>
						<div>
							{productsList && productsList.map((products, index) => {
								return <div className={styles.listBtn} key={index} onClick={(e) => addProductsHandler(e, products)} >{products.product}</div>
							})}
						</div>
					</div>
					<div className={styles.sendBtn}>
						<Button onClick={(e) => selectAllProduct()}>全選</Button>
					</div>
				</div>
				<div
					id="mainCenter"
					className={styles.centerSelect}
				>
					<h5>已選擇列表</h5>
					<div
						id="naniVideos"
						className={styles.productList}
					>
						<div>
							{purchasedProducts && purchasedProducts.map((products, index) => {
								return <div className={styles.listBtn} key={index} onClick={(e) => removeProductsHandler(e, products)} >{products.product}</div>
							})}
						</div>
					</div>

					<div className={styles.sendBtn}>
						<Button onClick={(e) => clearSelectProductList()}>清除列表</Button>
					</div>
				</div>
				<div id="mainRight" className={styles.rightSelect}>
					<div id="purchasedProducts" className={styles.textContent}>
						<div>
							<h5>備註</h5>
							<TextareaAutosize
								maxRows={4}
								aria-label="maximum height"
								placeholder=""
								defaultValue=""
								style={{ width: '100%', height: 50 }}
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</div>
						<div className={styles.sendBtn}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateTimePicker
									label="開始時間"
									value={startTime}
									onChange={(value) => {
										codeUsageStart = value
										setStartTime(value)
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</div>
						<div className={styles.sendBtn}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateTimePicker
									label="結束時間"
									value={endTime}
									onChange={(value) => {

										codeUsageEnd = value
										setEndTime(value)
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</div>
						<div className={styles.deadline}>
							<div>產品使用期限(不輸入預設366日)</div>
							<div>
								<TextField
									id="outlined-number"
									label=""
									type="number"
									InputLabelProps={{
										shrink: true,
									}}
									value={usageDurationValue}
									onChange={(e) => {
										durationValue = e.target.value
										setUsageDurationValue(e.target.value)
									}}
								/>
							</div>

						</div>
						<div className={styles.totalUse}>
							<div>總使用次數:</div>
							<div>
								<div className={styles.input}>
									<input
										type="radio"
										id="isLimitTotal"
										value='是'
										name="isLimitTotal"
										checked={isLimitTotal === '是'} 
										disableUnderline={true}
										onChange={(e)=>setIsLimitTotal(e.target.value)}
									></input>
									<div>使用次數</div>
									<TextField
										id="outlined-number"
										type="number"
										border={true}
										style={{ paddingLeft: '20px' }}
										InputLabelProps={{
											shrink: true,
										}}
										disabled={isLimitTotal === '否'}
										value={remain}
										onChange={(e) => setRemain(e.target.value)}
									/>
								</div>
								<div className={styles.input}>
									<input
										type="radio"
										id="isLimitTotal"
										value='否'
										name="isLimitTotal"
										checked={isLimitTotal === '否'} 
										onChange={(e)=>setIsLimitTotal(e.target.value)}
										disableUnderline={true}
									></input>
									<div>不限次數</div>
								</div>
							</div>

						</div>
						<div className={styles.totalUse}>
							<div>每帳號可用次數:</div>
							<div>
								<div className={styles.input}>
									<input
										type="radio"
										id="isLimitPersonal"
										value='是'
										name="isLimitPersonal"
										checked={isLimitPersonal === '是'} 
										onChange={(e)=>setIsLimitPersonal(e.target.value)}
										disableUnderline={true}
									></input>
									<div>使用次數</div>
									<TextField
										id="outlined-number"
										type="number"
										style={{ paddingLeft: '20px' }}
										InputLabelProps={{
											shrink: true,
										}}
										disabled={isLimitPersonal === '否'}
										value={personalLimit}
										onChange={(e) => setPersonalLimit(e.target.value)}
									/>
								</div>
								<div className={styles.input}>
									<input
										type="radio"
										id="isLimitPersonal"
										name="isLimitPersonal"
										value='否'
										checked={isLimitPersonal === '否'} 
										onChange={(e)=>setIsLimitPersonal(e.target.value)}
										disableUnderline={true}
									></input>
									<div>不限次數</div>
								</div>
							</div>

						</div>
						<div className={styles.orderCode}>
							<h6>兌換碼</h6>
							<TextField
								id="outlined-number"
								label=""
								type="text"
								InputLabelProps={{
									shrink: true,
								}}
								value={enterOrderId}
								onChange={(e) => setEnterOrderId(e.target.value)}
							/>
						</div>
						<div className={styles.sendBtn}>
							<Button onClick={() => sendOrder()}>送出</Button>
							<div id="msgResult"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SelectCustomOrderCode;