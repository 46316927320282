import * as React from "react";
import { useState, useEffect } from "react";
import OrderLogin from "./OrderLogin";
import VideoExamSearch from "./VideoExamSearch";
import { Box } from "@mui/material/";
import dayjs from "dayjs";
import { StoreTypes, useStore } from "context";
import OrderLoading from "components/Loading/OrderLoading";
import { CountiesType } from "constants/countiesType"

import {
  SECOND_TAB_NAME,
  tabs,
  OrientationType,
  TAB_NAME
} from "constants/studentUserInfoTypes";

var CryptoJS = require("crypto-js");

const StudentsSearchInfoPage = () => {
  const [naniProducts, setNaniProducts] = useState(null);
  const [{ token ,schoolName,isAdmin}] = useStore(StoreTypes.user);
  const [open, setOpen] = useState(true);
  const [selectYear, setSelectYear] = useState(new Date().getFullYear());
  const [selectMonth, setSelectMonth] = useState(dayjs().month());
  const [startTime, setStartTime] = useState(dayjs().subtract(1, "year"));
  const [videoExamFindType, setVideoExamFindType] = useState(SECOND_TAB_NAME.ALL.type);
  const [endTime, setEndTime] = useState(dayjs());
  const [studentsInfo, setStudentsInfo] = useState({});
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(tabs[0].name);
  const [secondTab, setSecondTab] = useState(tabs[0].secondTabs[0]);
  const [orientation, setOrientation] = useState(OrientationType[currentTab][SECOND_TAB_NAME.ALL.key]);
  const [worker, setWorker] = useState(new Worker('/js/rangeStudentInfo.js'));
  const [loadingMessage, setLoadingMessage] = useState("查詢中...");

  const checkObjectProperties=(obj)=> {
    obj.score = obj.score * (obj.testNum || 0);
    obj.videoNum = obj.videoNum || 0;
    obj.watchMinute = obj.watchMinute || 0;
    obj.testNum = obj.testNum || 0;
    obj.correctPercent = obj.correctPercent || 0;
    return obj
  }

  useEffect(() => {
    console.time("start")
    setLoading(true);
    worker.addEventListener("message", (e) => {
      let mergedData = {};
      let mergedArray;
      const { studentInfos, users, currentTab, secondTab ,error} = e.data;
      if(error){
        alert(error)
        setLoading(false);
        return
      }
      let info = []

      studentInfos.forEach((res) => {
        if (!res.includes("Error")) {
          try {
            const decrypted = CryptoJS.AES.decrypt(res, process.env.REACT_APP_CRYPTOJS_KEY, { iv: process.env.REACT_APP_CRYPTOJS_IV }).toString(CryptoJS.enc.Utf8);
            if (currentTab === TAB_NAME.PRODUCT_RECORD) {
              info.push(JSON.parse(decrypted))
            } else {
              info.push(JSON.parse(decrypted).users)
            }
          } catch {
            //console.log("ERROR",res);
          }
        }
      })

      setUserInfo(users)
      switch (secondTab) {
        case '概覽':
          for (let i = 0; i < info.length; i++) {
            // 遍歷子陣列中的每個物件
            for (let j = 0; j < info[i].length; j++) {
              // 取得該物件的 username
              let key = info[i][j].username;
              let listSchoolName = info[i][j].schoolName;
              // 如果已經存在該 username 的物件，則將數字屬性加總
              if (mergedData[key]) {
                mergedData[key].score += info[i][j].score * (info[i][j].testNum || 0);
                mergedData[key].videoNum += info[i][j].videoNum || 0;
                mergedData[key].watchMinute += info[i][j].watchMinute || 0;
                mergedData[key].testNum += info[i][j].testNum || 0;

                mergedData[key].scoreCount++
                mergedData[key].correctPercent = info[i][j].correctPercent ? parseInt(mergedData[key].correctPercent) : 0
                mergedData[key].correctPercent += info[i][j].correctPercent ? parseInt(info[i][j].correctPercent) : 0;
              }
              // 如果不存在該 key 的物件，則將該物件加入 mergedData 中
              else {
                const shouldInclude = isAdmin || schoolName === listSchoolName;
                if (CountiesType[process.env.REACT_APP_PROJECT]) {
                  if (info[i][j].schoolCounty && info[i][j].schoolCounty.includes(CountiesType[process.env.REACT_APP_PROJECT])) {
                    if(shouldInclude){
                      mergedData[key] = { ...checkObjectProperties(info[i][j]), scoreCount: 0 }
                    }
                  }
                } else {
                  if(shouldInclude){
                    mergedData[key] = { ...checkObjectProperties(info[i][j]), scoreCount: 0 }
                  }
                }
              }
            }
          }

          // 將 mergedData 物件轉換為陣列
          mergedArray = Object.keys(mergedData).map((key) => {
            return mergedData[key];
          });

          mergedArray.map(info => {
            info.score = info.testNum?info.score / info.testNum:0
            return info
          })
          console.timeEnd("start")
          setStudentsInfo({ users: mergedArray })
          break;
        case '產品學校':
          // 遍歷每個子陣列
          for (let i = 0; i < info.length; i++) {
            // 遍歷子陣列中的每個物件
            for (let j = 0; j < info[i].length; j++) {
              // 取得該物件的 productCode
              let productName = info[i][j].productName;
              let listSchoolName = info[i][j].schoolName;
              let key = productName + listSchoolName
              // 如果已經存在該 key 的物件，則將數字屬性加總
              if (mergedData[key]) {
                mergedData[key].score += info[i][j].score * info[i][j].testNum || 0;
                mergedData[key].videoNum += info[i][j].videoNum || 0;
                mergedData[key].watchMinute += info[i][j].watchMinute || 0;
                mergedData[key].testNum += info[i][j].testNum || 0;

                mergedData[key].scoreCount++
                mergedData[key].correctPercent = info[i][j].correctPercent ? parseInt(mergedData[key].correctPercent) : 0
                mergedData[key].correctPercent += info[i][j].correctPercent ? parseInt(info[i][j].correctPercent) : 0;
              }
              // 如果不存在該 key 的物件，則將該物件加入 mergedData 中
              else {
                if (CountiesType[process.env.REACT_APP_PROJECT]) {
                  if (info[i][j].schoolCounty && info[i][j].schoolCounty.includes(CountiesType[process.env.REACT_APP_PROJECT])) {
                    if(isAdmin||schoolName===listSchoolName){
                      mergedData[key] = { ...checkObjectProperties(info[i][j]), scoreCount: 0 }
                    }
                  }
                } else {
                  if(isAdmin||schoolName===listSchoolName){
                    mergedData[key] = { ...checkObjectProperties(info[i][j]), scoreCount: 0 }
                  }
                }
              }
            }
          }

          // 將 mergedData 物件轉換為陣列
          mergedArray = Object.keys(mergedData).map((key) => {
            return mergedData[key];
          });

          mergedArray.map(info => {
            // if(info.productName+info.schoolName==='111國中七上社會'){
            //   console.log(info);
            // }
            info.score = info.testNum?info.score / info.testNum:0
            return info
          })
          console.timeEnd("start")
          setStudentsInfo({ users: mergedArray })
          break;
        case '產品縣市':
          // 遍歷每個子陣列
          for (let i = 0; i < info.length; i++) {
            // 遍歷子陣列中的每個物件
            for (let j = 0; j < info[i].length; j++) {
              // 取得該物件的 productCode
              let productName = info[i][j].productName;
              let schoolCounty = info[i][j].schoolCounty;
              let key = productName + schoolCounty
              // 如果已經存在該 key 的物件，則將數字屬性加總
              if (mergedData[key]) {
                mergedData[key].score += info[i][j].score * info[i][j].testNum || 0;
                mergedData[key].videoNum += info[i][j].videoNum || 0;
                mergedData[key].watchMinute += info[i][j].watchMinute || 0;
                mergedData[key].testNum += info[i][j].testNum || 0;

                mergedData[key].correctPercent = info[i][j].correctPercent ? parseInt(mergedData[key].correctPercent) : 0
                mergedData[key].correctPercent += info[i][j].correctPercent ? parseInt(info[i][j].correctPercent) : 0;
              }
              // 如果不存在該 key 的物件，則將該物件加入 mergedData 中
              else {
                if (CountiesType[process.env.REACT_APP_PROJECT]) {
                  if (info[i][j].schoolCounty && info[i][j].schoolCounty.includes(CountiesType[process.env.REACT_APP_PROJECT])) {
                    mergedData[key] = { ...checkObjectProperties(info[i][j]), scoreCount: 0 }
                  }
                } else {
                  mergedData[key] = { ...checkObjectProperties(info[i][j]), scoreCount: 0 }
                }
              }
            }
          }
          // 將 mergedData 物件轉換為陣列
          mergedArray = Object.keys(mergedData).map((key) => {
            return mergedData[key];
          });

          mergedArray.map(info => {
            info.score = info.score / (info.testNum||1)
            return info
          })
          console.timeEnd("start")
          setStudentsInfo({ users: mergedArray })
          break;

        case '學校':
          // 遍歷每個子陣列
          for (let i = 0; i < info.length; i++) {
            // 遍歷子陣列中的每個物件
            for (let j = 0; j < info[i].length; j++) {
              let listSchoolName = info[i][j].schoolName;
              let key = listSchoolName
              // 如果已經存在該 key 的物件，則將數字屬性加總
              if (mergedData[key]) {
                mergedData[key].score += info[i][j].score * (info[i][j].testNum || 0);
                mergedData[key].videoNum += info[i][j].videoNum || 0;
                mergedData[key].watchMinute += info[i][j].watchMinute || 0;
                mergedData[key].testNum += info[i][j].testNum || 0;
                mergedData[key].studentCount = Math.max(info[i][j].studentCount, mergedData[key].studentCount)

                mergedData[key].scoreCount++
                mergedData[key].correctPercent = info[i][j].correctPercent ? parseInt(mergedData[key].correctPercent) : 0
                mergedData[key].correctPercent += info[i][j].correctPercent ? parseInt(info[i][j].correctPercent) : 0;
              }
              // 如果不存在該 key 的物件，則將該物件加入 mergedData 中
              else {
                if (CountiesType[process.env.REACT_APP_PROJECT]) {
                  if (info[i][j].schoolCounty && info[i][j].schoolCounty.includes(CountiesType[process.env.REACT_APP_PROJECT])) {
                    if(isAdmin||schoolName===listSchoolName){
                      mergedData[key] = { ...checkObjectProperties(info[i][j]), scoreCount: 0 }
                    }
                  }
                } else {
                  if(isAdmin||schoolName===listSchoolName){
                    mergedData[key] = { ...checkObjectProperties(info[i][j]), scoreCount: 0 }
                  }
                }
              }
            }
          }

          // 將 mergedData 物件轉換為陣列
          mergedArray = Object.keys(mergedData).map((key) => {
            return mergedData[key];
          });

          mergedArray.map(info => {
            info.score = info.testNum?info.score / info.testNum:0
            return info
          })
          console.timeEnd("start")
          setStudentsInfo({ users: mergedArray })
          break;
        case '年級':
          // 遍歷每個子陣列
          for (let i = 0; i < info.length; i++) {
            // 遍歷子陣列中的每個物件
            for (let j = 0; j < info[i].length; j++) {
              let listSchoolName = info[i][j].schoolName;
              let grade = info[i][j].grade;
              let key = listSchoolName + grade
              // 如果已經存在該 key 的物件，則將數字屬性加總
              if (mergedData[key]) {
                mergedData[key].score += info[i][j].score * (info[i][j].testNum || 0);
                mergedData[key].videoNum += info[i][j].videoNum || 0;
                mergedData[key].watchMinute += info[i][j].watchMinute || 0;
                mergedData[key].testNum += info[i][j].testNum || 0;
                mergedData[key].studentCount = Math.max(info[i][j].studentCount, mergedData[key].studentCount)
                
                mergedData[key].scoreCount++
                mergedData[key].correctPercent = info[i][j].correctPercent ? parseInt(mergedData[key].correctPercent) : 0
                mergedData[key].correctPercent += info[i][j].correctPercent ? parseInt(info[i][j].correctPercent) : 0;
              }
              // 如果不存在該 key 的物件，則將該物件加入 mergedData 中
              else {
                if (CountiesType[process.env.REACT_APP_PROJECT]) {
                  if (info[i][j].schoolCounty && info[i][j].schoolCounty.includes(CountiesType[process.env.REACT_APP_PROJECT])) {
                    if(isAdmin||schoolName===listSchoolName){
                      mergedData[key] = { ...checkObjectProperties(info[i][j]), scoreCount: 0 }
                    }
                  }
                } else {
                  if(isAdmin||schoolName===listSchoolName){
                    mergedData[key] = { ...checkObjectProperties(info[i][j]), scoreCount: 0 }
                  }
                }
              }
            }
          }

          // 將 mergedData 物件轉換為陣列
          mergedArray = Object.keys(mergedData).map((key) => {
            return mergedData[key];
          });

          mergedArray.map(info => {
            info.score = info.testNum?info.score / info.testNum:0
            return info
          })
          console.timeEnd("start")
          setStudentsInfo({ users: mergedArray })
          break;
        case "影片觀看紀錄":
        case "測驗完成紀錄":
          const infos=[]
          info.forEach(arr => {
            const filterArr=arr.filter(obj => isAdmin||obj.schoolName === schoolName)
            if(filterArr.length>0){
              infos.push(filterArr)
            }
          });
          schoolName&&setStudentsInfo({ users: infos.flat() })
          break;
        default:
          console.timeEnd("start")

          const mergedData2 = [];
          const mergedMap = new Map();
          info.forEach(arr => {
            arr.forEach(obj => {
              const key = Object.values(obj).join("-"); // 生成合并对象的唯一键
              if (!mergedMap.has(key)) {
                mergedMap.set(key, obj);
                mergedData2.push(obj);
              }
            });
          });
          setStudentsInfo({ users: mergedData2 })
          break;
      }
      setLoading(false);
    });
    //worker.postMessage({ apiType: "getAllStudentsInfo", selectYear, selectMonth, token });
    //worker.postMessage({ apiType: "getAllStudentsInfo", from: startTime.format('YYYY/MM/DD'), to: endTime.format('YYYY/MM/DD'), token });
  }, [schoolName,isAdmin])

  useEffect(() => {
    if (!token) return;
    setLoading(true);
    setUserInfo([])
    setStudentsInfo([])
    worker.postMessage({
      apiDomain: process.env.REACT_APP_API_DOMAIN,
      apiType: "getAllStudentsInfo",
      from: startTime.format('YYYY/MM/DD'),
      to: endTime.format('YYYY/MM/DD'),
      orientation,
      videoExamFindType,
      token,
      currentTab,
      secondTab
    });
  }, [selectYear, startTime, endTime, orientation, videoExamFindType, token, currentTab, secondTab])

  return (
    <div style={{ overflow: "hidden" }}>
      {/* {loading && <OrderLoading message={loadingMessage} />} */}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          width: "100%",
        }}
      >
        <OrderLogin open={open} setOpen={setOpen} />
      </Box>
      <VideoExamSearch
        naniProducts={naniProducts}
        state={{
          selectYear, setSelectYear,
          selectMonth, setSelectMonth,
          startTime, setStartTime,
          endTime, setEndTime,
          studentsInfo, setStudentsInfo,
          userInfo, setUserInfo,
          loading, setLoading,
          currentTab, setCurrentTab,
          secondTab, setSecondTab,
          setVideoExamFindType,
          setOrientation,
          worker
        }}
      />
    </div>
  );
};

export default StudentsSearchInfoPage;
