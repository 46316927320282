import { useState, useCallback } from "react";
import { StoreTypes, useStore } from "context";
import { API } from "api";

export const useUpdateVideoTime = () => {
	const [{ token }] = useStore(StoreTypes.user);
	const updateTime = useCallback(
		async (playTimeInfo) => {
			if (playTimeInfo) {
				//剛開始近來 playTimeInfo 為空值
				const result = await API.post(
					`${process.env.REACT_APP_API_DOMAIN}/video/updateUserVideoWatchTime`,
					playTimeInfo,
					token
				);
			}
		},
		[token]
	);

	return { updateTime };
};

