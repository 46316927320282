export const IMAGES = {
	NANI_PRODUCT_C: {
		//nanistudy圖示
		"國小主題式課程互動-國語_國語":"./assets/img/nanistudy/productImgs/國小/國小國語互動學習包C.png",
		"國小主題式課程互動-三視圖_數學":"./assets/img/nanistudy/productImgs/國小/國小數學互動三視圖C.png",
		"國小主題式課程互動-自然_自然":"./assets/img/nanistudy/productImgs/國小/國小自然互動實驗室C.png",
		"國小主題式課程互動-生活_生活":"./assets/img/nanistudy/productImgs/國小/國小生活互動學習包C.png",
		國小主題式課程數位閱讀1_國語數位閱讀一年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀1C.jpg",
		國小主題式課程數位閱讀2_國語數位閱讀二年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀2C.jpg",
		國小主題式課程數位閱讀3_國語數位閱讀三年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀3C.jpg",
		國小主題式課程數位閱讀4_國語數位閱讀四年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀4C.jpg",
		國小主題式課程數位閱讀5_國語數位閱讀五年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀5C.jpg",
		國小主題式課程數位閱讀6_國語數位閱讀六年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀6C.jpg",
		國小主題式課程社會動畫3_社會主題式動畫學習教材三年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫3C.jpg",
		國小主題式課程社會動畫4_社會主題式動畫學習教材四年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫4C.jpg",
		國小主題式課程社會動畫5_社會主題式動畫學習教材五年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫5C.jpg",
		國小主題式課程社會動畫6_社會主題式動畫學習教材六年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫6C.jpg",

		"國中主題式課程互動-三視圖_數學":"./assets/img/nanistudy/productImgs/國中/國中數學互動三視圖B.png",
		國中主題式課程國文閱讀_國文閱讀動滋動: "./assets/img/nanistudy/productImgs/國中/國中主題式課程國文閱讀C.jpg",
		國中主題式課程FUN文法_英文文法FUN課堂: "./assets/img/nanistudy/productImgs/國中/國中主題式課程FUN文法C.jpg",
		國中主題式課程地科補救_地科概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程地科補救C.jpg",
		國中主題式課程生物補救_生物概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程生物補救C.jpg",
		國中主題式課程國文寫型_國文寫型分析: "./assets/img/nanistudy/productImgs/國中/國中主題式課程國文寫型C.jpg",
		國中主題式課程語文常識_國文語文常識動起來: "./assets/img/nanistudy/productImgs/國中/國中主題式課程語文常識C.jpg",
		國中主題式課程地理概念_地理概念主題包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程地理概念C.jpg",
		國中主題式課程歷史概念_歷史概念主題包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程歷史概念C.jpg",
		國中主題式課程公民概念_公民概念主題包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程公民概念C.jpg",
		國中主題式課程數學補救7_數學7年級概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程數學補救7C.jpg",
		國中主題式課程數學補救8_數學8年級概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程數學補救8C.jpg",
		國中主題式課程數學補救9_數學9年級概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程數學補救9C.jpg",
		國中主題式課程理化補救8_理化8年級概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程理化補救8C.jpg",
		國中主題式課程理化補救9_理化9年級概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程理化補救9C.jpg",

		"高中主題式課程地科測驗_地球科學學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程地科測驗C.jpg",
		"高中主題式課程生物測驗_生物科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程生物測驗C.jpg",
		"高中主題式課程地理測驗_地理科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程地理測驗C.jpg",
		"高中主題式課程歷史測驗_歷史科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程歷史測驗C.jpg",
		"高中主題式課程化學測驗_化學科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程化學測驗C.jpg",
		"高中主題式課程數學測驗_數學科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程數學測驗C.jpg",
		"高中主題式課程物理測驗_物理科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程物理測驗C.jpg",
		"高中主題式課程公社測驗_公民與社會科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程公社測驗C.jpg",

		主題式教師專區數學雙語_國中數學主題式雙語教學包: "./assets/img/nanistudy/productImgs/國中/主題式教師專區數學雙語(國中)C.jpg",
		主題式教師專區數學雙語_高中數學科雙語教學主題包: "./assets/img/nanistudy/productImgs/高中/主題式教師專區數學雙語(高中)C.jpg",
		主題式教師專區高中數學雙語_高中數學科雙語教學主題包:"./assets/img/nanistudy/productImgs/高中/主題式教師專區數學雙語(高中)C.jpg",
		主題式教師專區地科MR_國中自然地球科學MR主題包: "./assets/img/nanistudy/productImgs/國中/主題式教師專區地科MR(國中)C.jpg",
		主題式教師專區地科MR_高中地球科學MR主題包: "./assets/img/nanistudy/productImgs/高中/主題式教師專區地科MR(高中)C.jpg",
		主題式教師專區高中地科MR_高中地球科學MR主題包: "./assets/img/nanistudy/productImgs/高中/主題式教師專區地科MR(高中)C.jpg",
		主題式教師專區生物MR_國中自然生物MR主題包: "./assets/img/nanistudy/productImgs/國中/主題式教師專區生物MRC.jpg",
		主題式教師專區地理MR_高中地理MR: "./assets/img/nanistudy/productImgs/高中/主題式教師專區地理MRC.jpg",
		主題式教師專區地科探究_高中地球科學多元選修數位課程: "./assets/img/nanistudy/productImgs/高中/主題式教師專區地科探究C.jpg",
		主題式教師專區生物雙語_高中生物科雙語教學實驗主題包: "./assets/img/nanistudy/productImgs/高中/主題式教師專區生物雙語C.jpg",
		主題式教師專區生物探究_高中生物科多元選修數位課程: "./assets/img/nanistudy/productImgs/高中/主題式教師專區生物探究C.jpg",
		"主題式教師專區健康雙語_國小健康主題式雙語教學包（中年級）": "./assets/img/nanistudy/productImgs/國小/主題式教師專區健康雙語C.jpg",
		"主題式教師專區體育雙語_國小體育主題式雙語教學包（中年級）": "./assets/img/nanistudy/productImgs/國小/主題式教師專區體育雙語C.jpg",
		"主題式教師專區綜合雙語_國小綜合主題式雙語教學包（中年級）": "./assets/img/nanistudy/productImgs/國小/主題式教師專區綜合雙語C.jpg",
		主題式教師專區童軍雙語_國中童軍主題式雙語教學包: "./assets/img/nanistudy/productImgs/國中/主題式教師專區童軍雙語C.jpg",
		主題式教師專區化學雙語_高中化學科雙語教學實驗主題包: "./assets/img/nanistudy/productImgs/高中/主題式教師專區化學雙語C.jpg",
		主題式教師專區化學探究_高中化學科多元選修數位課程: "./assets/img/nanistudy/productImgs/高中/主題式教師專區化學探究C.jpg",
		主題式教師專區遊戲程式_玩遊戲學程式: "./assets/img/nanistudy/productImgs/國小/主題式教師專區遊戲程式C.jpg",
		主題式教師專區物理雙語_高中物理科雙語教學實驗主題包: "./assets/img/nanistudy/productImgs/高中/主題式教師專區物理雙語C.jpg",
		主題式教師專區物理探究_高中物理科多元選修數位課程: "./assets/img/nanistudy/productImgs/高中/主題式教師專區物理探究C.jpg",
		主題式教師專區律動學習1_國小主題式律動學習教材一年級: "./assets/img/nanistudy/productImgs/國小/主題式教師專區律動學習1C.jpg",
		主題式教師專區律動學習2_國小主題式律動學習教材二年級: "./assets/img/nanistudy/productImgs/國小/主題式教師專區律動學習2C.jpg",
		"主題式教師專區自然雙語_國中自然主題式(生物)雙語教學包": "./assets/img/nanistudy/productImgs/國中/主題式教師專區自然雙語C.jpg",

		"主題式教師專區健康MR_國小健康MR主題包": "./assets/img/nanistudy/productImgs/國小/國小健康MR主題包C.png",
		"主題式教師專區立體解題MR_國小數學立體解題MR主題包": "./assets/img/nanistudy/productImgs/國小/國小數學立體解題MR主題包1C.png",
		"主題式教師專區認識立體MR_國小數學認識立體MR主題包": "./assets/img/nanistudy/productImgs/國小/國小數學認識立體MR主題包1C.png",
		"主題式教師專區生物MR2_國中自然生物MR主題包2": "./assets/img/nanistudy/productImgs/國中/國中自然生物MR主題包2 C.png",
		"主題式教師專區地科MR2_國中自然地球科學MR主題包2": "./assets/img/nanistudy/productImgs/國中/國中自然地球科學MR主題包2 C.png",
		"主題式教師專區高中化學MR_高中化學科MR主題包": "./assets/img/nanistudy/productImgs/高中/高中化學科MR主題包 1C.png",
		"主題式教師專區高中生物MR_高中生物科MR主題包": "./assets/img/nanistudy/productImgs/高中/高中生物科MR主題包 C.png",
		"主題式教師專區高中數學雙語2_高中數學科雙語教學主題包2": "./assets/img/nanistudy/productImgs/高中/高中數學科雙語教學主題包2 C.png",
		"主題式教師專區高中數學MR_高中數學科MR主題包": "./assets/img/nanistudy/productImgs/高中/高中數學科MR主題包 1C.png",

		國小學程一上_國語首冊: "./assets/img/Elementary school first grade_Chinese_328x200.png",

		國小學程一上_國語:
			"./assets/img/Elementary school first grade_Chinese_328x200.png",
		國小學程一上_生活:
			"./assets/img/Elementary school first grade_Life_328x200.png",
		國小學程一上_數學:
			"./assets/img/Elementary school first grade_Math_328x200.png",

		國小學程二上_國語:
			"./assets/img/Elementary school second grade_Chinese_328x200.png",
		國小學程二上_生活:
			"./assets/img/Elementary school second grade_Life_328x200.png",
		國小學程二上_數學:
			"./assets/img/Elementary school second grade_Math_328x200.png",

		國小學程三上_國語:
			"./assets/img/Elementary school third grade_Chinese_328x200.png",
		國小學程三上_數學:
			"./assets/img/Elementary school third grade_Math_328x200.png",
		國小學程三上_自然:
			"./assets/img/Elementary school third grade_Science_328x200.png",
		國小學程三上_社會:
			"./assets/img/Elementary school third grade_Social studies_328x200.png",
		國小學程三上_英語:
		"./assets/img/Elementary school third grade_English_328x200.png",	

		國小學程四上_國語:
			"./assets/img/Elementary school fourth grade_Chinese_328x200.png",
		國小學程四上_數學:
			"./assets/img/Elementary school fourth grade_Math_328x200.png",
		國小學程四上_自然:
			"./assets/img/Elementary school fourth grade_Science_328x200.png",
		國小學程四上_社會:
			"./assets/img/Elementary school fourth grade_Social studies_328x200.png",
		國小學程四上_英語:
			"./assets/img/Elementary school fourth grade_English_328x200.png",

		國小學程五上_國語:
			"./assets/img/Elementary school fifth grade_Chinese_328x200.png",
		國小學程五上_數學:
			"./assets/img/Elementary school fifth grade_Math_328x200.png",
		國小學程五上_自然:
			"./assets/img/Elementary school fifth grade_Science_328x200.png",
		國小學程五上_社會:
			"./assets/img/Elementary school fifth grade_Social studies_328x200.png",
		國小學程五上_英語:
			"./assets/img/Elementary school fifth grade_English_328x200.png",

		國小學程六上_國語:
			"./assets/img/Elementary school sixth grade_Chinese_328x200.png",
		國小學程六上_數學:
			"./assets/img/Elementary school sixth grade_Math_328x200.png",
		國小學程六上_自然:
			"./assets/img/Elementary school sixth grade_Science_328x200.png",
		國小學程六上_社會:
			"./assets/img/Elementary school sixth grade_Social studies_328x200.png",
		國小學程六上_英語:
			"./assets/img/Elementary school sixth grade_English_328x200.png",

		國中學程七上_國文:
			"./assets/img/Junior high school seventh grade_Chinese_328x200.png",
		國中學程七上_英語:
			"./assets/img/Junior high school seventh grade_English_328x200.png",
		國中學程七上_數學:
			"./assets/img/Junior high school seventh grade_Math_328x200.png",
		國中學程七上_自然:
			"./assets/img/Junior high school seventh grade_Science_328x200.png",
		國中學程七上_社會:
			"./assets/img/Junior high school seventh grade_Social studies_328x200.png",

		國中學程八上_國文:
			"./assets/img/Junior high school eighth grade_Chinese_328x200.png",
		國中學程八上_英語:
			"./assets/img/Junior high school eighth grade_English_328x200.png",
		國中學程八上_數學:
			"./assets/img/Junior high school eighth grade_Math_328x200.png",
		國中學程八上_自然:
			"./assets/img/Junior high school eighth grade_Science_328x200.png",
		國中學程八上_社會:
			"./assets/img/Junior high school eighth grade_Social studies_328x200.png",

		國中學程九上_國文:
			"./assets/img/Junior high school ninth grade_Chinese_328x200.png",
		國中學程九上_英語:
			"./assets/img/Junior high school ninth grade_English_328x200.png",
		國中學程九上_數學:
			"./assets/img/Junior high school ninth grade_Math_328x200.png",
		國中學程九上_自然:
			"./assets/img/Junior high school ninth grade_Science_328x200.png",
		國中學程九上_社會:
			"./assets/img/Junior high school ninth grade_Social studies_328x200.png",

		國小學程一下_國語:
			"./assets/img/Elementary school first grade_Chinese_328x200.png",
		國小學程一下_生活:
			"./assets/img/Elementary school first grade_Life_328x200.png",
		國小學程一下_數學:
			"./assets/img/Elementary school first grade_Math_328x200.png",

		國小學程二下_國語:
			"./assets/img/Elementary school second grade_Chinese_328x200.png",
		國小學程二下_生活:
			"./assets/img/Elementary school second grade_Life_328x200.png",
		國小學程二下_數學:
			"./assets/img/Elementary school second grade_Math_328x200.png",

		國小學程三下_國語:
			"./assets/img/Elementary school third grade_Chinese_328x200.png",
		國小學程三下_數學:
			"./assets/img/Elementary school third grade_Math_328x200.png",
		國小學程三下_自然:
			"./assets/img/Elementary school third grade_Science_328x200.png",
		國小學程三下_社會:
			"./assets/img/Elementary school third grade_Social studies_328x200.png",
		國小學程三下_英語:
		"./assets/img/Elementary school third grade_English_328x200.png",

		國小學程四下_國語:
			"./assets/img/Elementary school fourth grade_Chinese_328x200.png",
		國小學程四下_數學:
			"./assets/img/Elementary school fourth grade_Math_328x200.png",
		國小學程四下_自然:
			"./assets/img/Elementary school fourth grade_Science_328x200.png",
		國小學程四下_社會:
			"./assets/img/Elementary school fourth grade_Social studies_328x200.png",
		國小學程四下_英語:
			"./assets/img/Elementary school fourth grade_English_328x200.png",

		國小學程五下_國語:
			"./assets/img/Elementary school fifth grade_Chinese_328x200.png",
		國小學程五下_數學:
			"./assets/img/Elementary school fifth grade_Math_328x200.png",
		國小學程五下_自然:
			"./assets/img/Elementary school fifth grade_Science_328x200.png",
		國小學程五下_社會:
			"./assets/img/Elementary school fifth grade_Social studies_328x200.png",
		國小學程五下_英語:
			"./assets/img/Elementary school fourth grade_English_328x200.png",

		國小學程六下_國語:
			"./assets/img/Elementary school sixth grade_Chinese_328x200.png",
		國小學程六下_數學:
			"./assets/img/Elementary school sixth grade_Math_328x200.png",
		國小學程六下_自然:
			"./assets/img/Elementary school sixth grade_Science_328x200.png",
		國小學程六下_社會:
			"./assets/img/Elementary school sixth grade_Social studies_328x200.png",
		國小學程六下_英語:
			"./assets/img/Elementary school fourth grade_English_328x200.png",
			
		國中學程七下_國文:
			"./assets/img/Junior high school seventh grade_Chinese_328x200.png",
		國中學程七下_英語:
			"./assets/img/Junior high school seventh grade_English_328x200.png",
		國中學程七下_數學:
			"./assets/img/Junior high school seventh grade_Math_328x200.png",
		國中學程七下_自然:
			"./assets/img/Junior high school seventh grade_Science_328x200.png",
		國中學程七下_社會:
			"./assets/img/Junior high school seventh grade_Social studies_328x200.png",

		國中學程八下_國文:
			"./assets/img/Junior high school eighth grade_Chinese_328x200.png",
		國中學程八下_英語:
			"./assets/img/Junior high school eighth grade_English_328x200.png",
		國中學程八下_數學:
			"./assets/img/Junior high school eighth grade_Math_328x200.png",
		國中學程八下_自然:
			"./assets/img/Junior high school eighth grade_Science_328x200.png",
		國中學程八下_社會:
			"./assets/img/Junior high school eighth grade_Social studies_328x200.png",

		國中學程九下_國文:
			"./assets/img/Junior high school ninth grade_Chinese_328x200.png",
		國中學程九下_英語:
			"./assets/img/Junior high school ninth grade_English_328x200.png",
		國中學程九下_數學:
			"./assets/img/Junior high school ninth grade_Math_328x200.png",
		國中學程九下_自然:
			"./assets/img/Junior high school ninth grade_Science_328x200.png",
		國中學程九下_社會:
			"./assets/img/Junior high school ninth grade_Social studies_328x200.png",
	},
	NANI_VOLUME: {
		//nanistudy圖示
		"國小主題式課程互動-國語_國語":"./assets/img/nanistudy/productImgs/國小/國小國語互動學習包A.png",
		"國小主題式課程互動-三視圖_數學":"./assets/img/nanistudy/productImgs/國小/國小數學互動三視圖A.png",
		"國小主題式課程互動-自然_自然":"./assets/img/nanistudy/productImgs/國小/國小自然互動實驗室A.png",
		"國小主題式課程互動-生活_生活":"./assets/img/nanistudy/productImgs/國小/國小生活互動學習包A.png",
		國小主題式課程數位閱讀1_國語數位閱讀一年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀1A.jpg",
		國小主題式課程數位閱讀2_國語數位閱讀二年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀2A.jpg",
		國小主題式課程數位閱讀3_國語數位閱讀三年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀3A.jpg",
		國小主題式課程數位閱讀4_國語數位閱讀四年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀4A.jpg",
		國小主題式課程數位閱讀5_國語數位閱讀五年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀5A.jpg",
		國小主題式課程數位閱讀6_國語數位閱讀六年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀6A.jpg",
		國小主題式課程社會動畫3_社會主題式動畫學習教材三年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫3A.jpg",
		國小主題式課程社會動畫4_社會主題式動畫學習教材四年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫4A.jpg",
		國小主題式課程社會動畫5_社會主題式動畫學習教材五年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫5A.jpg",
		國小主題式課程社會動畫6_社會主題式動畫學習教材六年級: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫6A.jpg",

		"國中主題式課程互動-三視圖_數學":"./assets/img/nanistudy/productImgs/國中/國中數學互動三視圖A.png",
		國中主題式課程國文閱讀_國文閱讀動滋動: "./assets/img/nanistudy/productImgs/國中/國中主題式課程國文閱讀A.jpg",
		國中主題式課程FUN文法_英文文法FUN課堂: "./assets/img/nanistudy/productImgs/國中/國中主題式課程FUN文法A.jpg",
		國中主題式課程地科補救_地科概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程地科補救A.jpg",
		國中主題式課程生物補救_生物概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程生物補救A.jpg",
		國中主題式課程國文寫型_國文寫型分析: "./assets/img/nanistudy/productImgs/國中/國中主題式課程國文寫型A.jpg",
		國中主題式課程語文常識_國文語文常識動起來: "./assets/img/nanistudy/productImgs/國中/國中主題式課程語文常識A.jpg",
		國中主題式課程地理概念_地理概念主題包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程地理概念A.jpg",
		國中主題式課程歷史概念_歷史概念主題包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程歷史概念A.jpg",
		國中主題式課程公民概念_公民概念主題包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程公民概念A.jpg",
		國中主題式課程數學補救7_數學7年級概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程數學補救7A.jpg",
		國中主題式課程數學補救8_數學8年級概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程數學補救8A.jpg",
		國中主題式課程數學補救9_數學9年級概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程數學補救9A.jpg",
		國中主題式課程理化補救8_理化8年級概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程理化補救8A.jpg",
		國中主題式課程理化補救9_理化9年級概念補救教學包: "./assets/img/nanistudy/productImgs/國中/國中主題式課程理化補救9A.jpg",

		"高中主題式課程地科測驗_地球科學學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程地科測驗A.jpg",
		"高中主題式課程生物測驗_生物科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程生物測驗A.jpg",
		"高中主題式課程地理測驗_地理科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程地理測驗A.jpg",
		"高中主題式課程歷史測驗_歷史科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程歷史測驗A.jpg",
		"高中主題式課程化學測驗_化學科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程化學測驗A.jpg",
		"高中主題式課程數學測驗_數學科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程數學測驗A.jpg",
		"高中主題式課程物理測驗_物理科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程物理測驗A.jpg",
		"高中主題式課程公社測驗_公民與社會科學測真人解析＆主題式試題演練": "./assets/img/nanistudy/productImgs/高中/高中主題式課程公社測驗A.jpg",

		主題式教師專區地理MR_高中地理MR: "./assets/img/nanistudy/productImgs/高中/主題式教師專區地理MRA.jpg",
		主題式教師專區數學雙語_國中數學主題式雙語教學包: "./assets/img/nanistudy/productImgs/國中/主題式教師專區數學雙語(國中)A.jpg",
		主題式教師專區數學雙語_高中數學科雙語教學主題包: "./assets/img/nanistudy/productImgs/高中/主題式教師專區數學雙語(高中)A.jpg",
		主題式教師專區高中數學雙語_高中數學科雙語教學主題包:"./assets/img/nanistudy/productImgs/高中/主題式教師專區數學雙語(高中)A.jpg",
		主題式教師專區地科MR_國中自然地球科學MR主題包: "./assets/img/nanistudy/productImgs/國中/主題式教師專區地科MR(國中)A.jpg",
		主題式教師專區地科MR_高中地球科學MR主題包: "./assets/img/nanistudy/productImgs/高中/主題式教師專區地科MR(高中)A.jpg",
		主題式教師專區高中地科MR_高中地球科學MR主題包:"./assets/img/nanistudy/productImgs/高中/主題式教師專區地科MR(高中)A.jpg",
		主題式教師專區生物MR_國中自然生物MR主題包: "./assets/img/nanistudy/productImgs/國中/主題式教師專區生物MRA.jpg",
		主題式教師專區地科探究_高中地球科學多元選修數位課程: "./assets/img/nanistudy/productImgs/高中/主題式教師專區地科探究A.jpg",
		主題式教師專區生物雙語_高中生物科雙語教學實驗主題包: "./assets/img/nanistudy/productImgs/高中/主題式教師專區生物雙語A.jpg",
		主題式教師專區生物探究_高中生物科多元選修數位課程: "./assets/img/nanistudy/productImgs/高中/主題式教師專區生物探究A.jpg",
		"主題式教師專區健康雙語_國小健康主題式雙語教學包（中年級）": "./assets/img/nanistudy/productImgs/國小/主題式教師專區健康雙語A.jpg",
		"主題式教師專區體育雙語_國小體育主題式雙語教學包（中年級）": "./assets/img/nanistudy/productImgs/國小/主題式教師專區體育雙語A.jpg",
		"主題式教師專區綜合雙語_國小綜合主題式雙語教學包（中年級）": "./assets/img/nanistudy/productImgs/國小/主題式教師專區綜合雙語A.jpg",
		主題式教師專區童軍雙語_國中童軍主題式雙語教學包: "./assets/img/nanistudy/productImgs/國中/主題式教師專區童軍雙語A.jpg",
		主題式教師專區化學雙語_高中化學科雙語教學實驗主題包: "./assets/img/nanistudy/productImgs/高中/主題式教師專區化學雙語A.jpg",
		主題式教師專區化學探究_高中化學科多元選修數位課程: "./assets/img/nanistudy/productImgs/高中/主題式教師專區化學探究A.jpg",
		主題式教師專區遊戲程式_玩遊戲學程式: "./assets/img/nanistudy/productImgs/國小/主題式教師專區遊戲程式A.jpg",
		主題式教師專區物理雙語_高中物理科雙語教學實驗主題包: "./assets/img/nanistudy/productImgs/高中/主題式教師專區物理雙語A.jpg",
		主題式教師專區物理探究_高中物理科多元選修數位課程: "./assets/img/nanistudy/productImgs/高中/主題式教師專區物理探究A.jpg",
		主題式教師專區律動學習1_國小主題式律動學習教材一年級: "./assets/img/nanistudy/productImgs/國小/主題式教師專區律動學習1A.jpg",
		主題式教師專區律動學習2_國小主題式律動學習教材二年級: "./assets/img/nanistudy/productImgs/國小/主題式教師專區律動學習2A.jpg",
		"主題式教師專區自然雙語_國中自然主題式(生物)雙語教學包": "./assets/img/nanistudy/productImgs/國中/主題式教師專區自然雙語A.jpg",
		
		"主題式教師專區健康MR_國小健康MR主題包": "./assets/img/nanistudy/productImgs/國小/國小健康MR主題包A.png",
		"主題式教師專區立體解題MR_國小數學立體解題MR主題包": "./assets/img/nanistudy/productImgs/國小/國小數學立體解題MR主題包1A.png",
		"主題式教師專區認識立體MR_國小數學認識立體MR主題包": "./assets/img/nanistudy/productImgs/國小/國小數學認識立體MR主題包1A.png",
		"主題式教師專區生物MR2_國中自然生物MR主題包2": "./assets/img/nanistudy/productImgs/國中/國中自然生物MR主題包2 A.png",
		"主題式教師專區地科MR2_國中自然地球科學MR主題包2": "./assets/img/nanistudy/productImgs/國中/國中自然地球科學MR主題包2 A.png",
		"主題式教師專區高中化學MR_高中化學科MR主題包": "./assets/img/nanistudy/productImgs/高中/高中化學科MR主題包 1A.png",
		"主題式教師專區高中生物MR_高中生物科MR主題包": "./assets/img/nanistudy/productImgs/高中/高中生物科MR主題包 A.png",
		"主題式教師專區高中數學雙語2_高中數學科雙語教學主題包2": "./assets/img/nanistudy/productImgs/高中/高中數學科雙語教學主題包2 A.png",
		"主題式教師專區高中數學MR_高中數學科MR主題包": "./assets/img/nanistudy/productImgs/高中/高中數學科MR主題包 1A.png",

		國小學程一上_國語首冊:
		"./assets/img/Elementary school first grade_Chinese_608x216.png",
		國小學程一上_國語:
			"./assets/img/Elementary school first grade_Chinese_608x216.png",
		國小學程一上_生活:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小學程一上_數學:
			"./assets/img/Elementary school first grade_Math_608x216.png",

		國小學程二上_國語:
			"./assets/img/Elementary school second grade_Chinese_608x216.png",
		國小學程二上_生活:
			"./assets/img/Elementary school second grade_Life_608x216.png",
		國小學程二上_數學:
			"./assets/img/Elementary school second grade_Math_608x216.png",

		國小學程三上_國語:
			"./assets/img/Elementary school third grade_Chinese_608x216.png",
		國小學程三上_生活:
			"./assets/img/Elementary school second grade_Life_608x216.png",
		國小學程三上_數學:
			"./assets/img/Elementary school third grade_Math_608x216.png",
		國小學程三上_自然:
			"./assets/img/Elementary school third grade_Science_608x216.png",
		國小學程三上_社會:
			"./assets/img/Elementary school third grade_Social studies_608x216.png",

		國小學程四上_國語:
			"./assets/img/Elementary school fourth grade_Chinese_608x216.png",
		國小學程四上_生活:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小學程四上_數學:
			"./assets/img/Elementary school fourth grade_Math_608x216.png",
		國小學程四上_自然:
			"./assets/img/Elementary school fourth grade_Science_608x216.png",
		國小學程四上_社會:
			"./assets/img/Elementary school fourth grade_Social studies_608x216.png",

		國小學程五上_國語:
			"./assets/img/Elementary school fifth grade_Chinese_608x216.png",
		國小學程五上_生活:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小學程五上_數學:
			"./assets/img/Elementary school fifth grade_Math_608x216.png",
		國小學程五上_自然:
			"./assets/img/Elementary school fifth grade_Science_608x216.png",
		國小學程五上_社會:
			"./assets/img/Elementary school fifth grade_Social studies_608x216.png",

		國小學程六上_國語:
			"./assets/img/Elementary school sixth grade_Chinese_608x216.png",
		國小學程六上_生活:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小學程六上_數學:
			"./assets/img/Elementary school sixth grade_Math_608x216.png",
		國小學程六上_自然:
			"./assets/img/Elementary school sixth grade_Science_608x216.png",
		國小學程六上_社會:
			"./assets/img/Elementary school sixth grade_Social studies_608x216.png",

		國中學程七上_國文:
			"./assets/img/Junior high school seventh grade_Chinese_608x216.png",
		國中學程七上_英語:
			"./assets/img/Junior high school seventh grade_English_608x216.png",
		國中學程七上_數學:
			"./assets/img/Junior high school seventh grade_Math_608x216.png",
		國中學程七上_自然:
			"./assets/img/Junior high school seventh grade_Science_608x216.png",
		國中學程七上_社會:
			"./assets/img/Junior high school seventh grade_Social studies_608x216.png",

		國中學程八上_國文:
			"./assets/img/Junior high school eighth grade_Chinese_608x216.png",
		國中學程八上_英語:
			"./assets/img/Junior high school eighth grade_English_608x216.png",
		國中學程八上_數學:
			"./assets/img/Junior high school eighth grade_Math_608x216.png",
		國中學程八上_自然:
			"./assets/img/Junior high school eighth grade_Science_608x216.png",
		國中學程八上_社會:
			"./assets/img/Junior high school eighth grade_Social studies_608x216.png",

		國中學程九上_國文:
			"./assets/img/Junior high school ninth grade_Chinese_608x216.png",
		國中學程九上_英語:
			"./assets/img/Junior high school ninth grade_English_608x216.png",
		國中學程九上_數學:
			"./assets/img/Junior high school ninth grade_Math_608x216.png",
		國中學程九上_自然:
			"./assets/img/Junior high school ninth grade_Science_608x216.png",
		國中學程九上_社會:
			"./assets/img/Junior high school ninth grade_Social studies_608x216.png",


		國小學程一下_國語:
			"./assets/img/Elementary school first grade_Chinese_608x216.png",
		國小學程一下_生活:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小學程一下_數學:
			"./assets/img/Elementary school first grade_Math_608x216.png",

		國小學程二下_國語:
			"./assets/img/Elementary school second grade_Chinese_608x216.png",
		國小學程二下_生活:
			"./assets/img/Elementary school second grade_Life_608x216.png",
		國小學程二下_數學:
			"./assets/img/Elementary school second grade_Math_608x216.png",

		國小學程三下_國語:
			"./assets/img/Elementary school third grade_Chinese_608x216.png",
		國小學程三下_生活:
			"./assets/img/Elementary school second grade_Life_608x216.png",
		國小學程三下_數學:
			"./assets/img/Elementary school third grade_Math_608x216.png",
		國小學程三下_自然:
			"./assets/img/Elementary school third grade_Science_608x216.png",
		國小學程三下_社會:
			"./assets/img/Elementary school third grade_Social studies_608x216.png",

		國小學程四下_國語:
			"./assets/img/Elementary school fourth grade_Chinese_608x216.png",
		國小學程四下_生活:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小學程四下_數學:
			"./assets/img/Elementary school fourth grade_Math_608x216.png",
		國小學程四下_自然:
			"./assets/img/Elementary school fourth grade_Science_608x216.png",
		國小學程四下_社會:
			"./assets/img/Elementary school fourth grade_Social studies_608x216.png",

		國小學程五下_國語:
			"./assets/img/Elementary school fifth grade_Chinese_608x216.png",
		國小學程五下_生活:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小學程五下_數學:
			"./assets/img/Elementary school fifth grade_Math_608x216.png",
		國小學程五下_自然:
			"./assets/img/Elementary school fifth grade_Science_608x216.png",
		國小學程五下_社會:
			"./assets/img/Elementary school fifth grade_Social studies_608x216.png",

		國小學程六下_國語:
			"./assets/img/Elementary school sixth grade_Chinese_608x216.png",
		國小學程六下_生活:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小學程六下_數學:
			"./assets/img/Elementary school sixth grade_Math_608x216.png",
		國小學程六下_自然:
			"./assets/img/Elementary school sixth grade_Science_608x216.png",
		國小學程六下_社會:
			"./assets/img/Elementary school sixth grade_Social studies_608x216.png",

		國中學程七下_國文:
			"./assets/img/Junior high school seventh grade_Chinese_608x216.png",
		國中學程七下_英語:
			"./assets/img/Junior high school seventh grade_English_608x216.png",
		國中學程七下_數學:
			"./assets/img/Junior high school seventh grade_Math_608x216.png",
		國中學程七下_自然:
			"./assets/img/Junior high school seventh grade_Science_608x216.png",
		國中學程七下_社會:
			"./assets/img/Junior high school seventh grade_Social studies_608x216.png",

		國中學程八下_國文:
			"./assets/img/Junior high school eighth grade_Chinese_608x216.png",
		國中學程八下_英語:
			"./assets/img/Junior high school eighth grade_English_608x216.png",
		國中學程八下_數學:
			"./assets/img/Junior high school eighth grade_Math_608x216.png",
		國中學程八下_自然:
			"./assets/img/Junior high school eighth grade_Science_608x216.png",
		國中學程八下_社會:
			"./assets/img/Junior high school eighth grade_Social studies_608x216.png",

		國中學程九下_國文:
			"./assets/img/Junior high school ninth grade_Chinese_608x216.png",
		國中學程九下_英語:
			"./assets/img/Junior high school ninth grade_English_608x216.png",
		國中學程九下_數學:
			"./assets/img/Junior high school ninth grade_Math_608x216.png",
		國中學程九下_自然:
			"./assets/img/Junior high school ninth grade_Science_608x216.png",
		國中學程九下_社會:
			"./assets/img/Junior high school ninth grade_Social studies_608x216.png",
	},
	PRODUCT_ITEM: {

		主題式國語_超越盃: "./assets/img/Chinese_328x200.png",
		主題式國語文_超越盃: "./assets/img/Chinese III_328x200.png",
		主題式國文_超越盃: "./assets/img/Chinese II_328x200.png",
		主題式數學_超越盃: "./assets/img/MathA_328x200.png",
		'主題式數學(全)_超越盃': "./assets/img/MathA_328x200.png",
		// 主題數學_測驗訓練: "./assets/img/MathA_328x200.png",
		主題式英語_超越盃: "./assets/img/English_328x200.png",
		主題式英文_超越盃: "./assets/img/English_328x200.png",
		'主題式英語(全)_會話': "./assets/img/English_328x200.png",
		'主題式英語(全)_文法': "./assets/img/English_328x200.png",
		// 主題英語_閱讀動畫: "./assets/img/English_328x200.png",
		// 主題式英語_超越盃: "./assets/img/English II_328x200.png",
		主題式英語_會話: "./assets/img/English II_328x200.png",
		主題式英語_文法: "./assets/img/English II_328x200.png",
		主題式生活_超越盃: "./assets/img/Life Curriculum_328x200.png",
		主題式社會_超越盃: "./assets/img/Social Studies_328x200.png",
		主題式自然_超越盃: "./assets/img/Science_328x200.png",
		主題式地理_超越盃: "./assets/img/Geography_328x200.png",
		主題式歷史_超越盃: "./assets/img/History_328x200.png",
		主題式公民與社會_超越盃: "./assets/img/Civics and Society _328x200.png",
		主題式地球科學_超越盃: "./assets/img/Earth Science_328x200.png",
		主題式生物_超越盃: "./assets/img/Biology_328x200.png",
		主題式化學_超越盃: "./assets/img/Chemical_328x200.png",
		主題式物理_超越盃: "./assets/img/Physics_328x200.png",
		主題式溫美玉ｘ魏瑛娟_ㄅㄆㄇ注音學習樂園:"./assets/img/WenMeiYu_328x200.png",
		主題式溫美玉ｘ魏瑛娟_中高年級人物寫作課:"./assets/img/WenMeiYu_328x200.png",
		主題式溫美玉ｘ魏瑛娟寫作課_中高年級人物寫作課:"./assets/img/WenMeiYu_328x200.png",
		主題式溫美玉ｘ魏瑛娟ㄅㄆㄇ_ㄅㄆㄇ注音學習樂園:"./assets/img/WenMeiYu_328x200.png",

		國小一上_CH:
			"./assets/img/Elementary school first grade_Chinese_328x200.png",
		國小一上_LI:
			"./assets/img/Elementary school first grade_Life_328x200.png",
		國小一上_MA:
			"./assets/img/Elementary school first grade_Math_328x200.png",

		國小二上_CH:
			"./assets/img/Elementary school second grade_Chinese_328x200.png",
		國小二上_LI:
			"./assets/img/Elementary school second grade_Life_328x200.png",
		國小二上_MA:
			"./assets/img/Elementary school second grade_Math_328x200.png",

		國小三上_CH:
			"./assets/img/Elementary school third grade_Chinese_328x200.png",
		國小三上_MA:
			"./assets/img/Elementary school third grade_Math_328x200.png",
		國小三上_SC:
			"./assets/img/Elementary school third grade_Science_328x200.png",
		國小三上_SO:
			"./assets/img/Elementary school third grade_Social studies_328x200.png",
		國小三上_EN:
		"./assets/img/Elementary school third grade_English_328x200.png",

		國小四上_CH:
			"./assets/img/Elementary school fourth grade_Chinese_328x200.png",
		國小四上_MA:
			"./assets/img/Elementary school fourth grade_Math_328x200.png",
		國小四上_SC:
			"./assets/img/Elementary school fourth grade_Science_328x200.png",
		國小四上_SO:
			"./assets/img/Elementary school fourth grade_Social studies_328x200.png",
		國小四上_EN:
			"./assets/img/Elementary school fourth grade_English_328x200.png",

		國小五上_CH:
			"./assets/img/Elementary school fifth grade_Chinese_328x200.png",
		國小五上_MA:
			"./assets/img/Elementary school fifth grade_Math_328x200.png",
		國小五上_SC:
			"./assets/img/Elementary school fifth grade_Science_328x200.png",
		國小五上_SO:
			"./assets/img/Elementary school fifth grade_Social studies_328x200.png",
		國小五上_EN:
			"./assets/img/Elementary school fifth grade_English_328x200.png",

		國小六上_CH:
			"./assets/img/Elementary school sixth grade_Chinese_328x200.png",
		國小六上_MA:
			"./assets/img/Elementary school sixth grade_Math_328x200.png",
		國小六上_SC:
			"./assets/img/Elementary school sixth grade_Science_328x200.png",
		國小六上_SO:
			"./assets/img/Elementary school sixth grade_Social studies_328x200.png",
		國小六上_EN:
			"./assets/img/Elementary school sixth grade_English_328x200.png",

		國中七上_CH:
			"./assets/img/Junior high school seventh grade_Chinese_328x200.png",
		國中七上_EN:
			"./assets/img/Junior high school seventh grade_English_328x200.png",
		國中七上_MA:
			"./assets/img/Junior high school seventh grade_Math_328x200.png",
		國中七上_SC:
			"./assets/img/Junior high school seventh grade_Science_328x200.png",
		國中七上_SO:
			"./assets/img/Junior high school seventh grade_Social studies_328x200.png",

		國中八上_CH:
			"./assets/img/Junior high school eighth grade_Chinese_328x200.png",
		國中八上_EN:
			"./assets/img/Junior high school eighth grade_English_328x200.png",
		國中八上_MA:
			"./assets/img/Junior high school eighth grade_Math_328x200.png",
		國中八上_SC:
			"./assets/img/Junior high school eighth grade_Science_328x200.png",
		國中八上_SO:
			"./assets/img/Junior high school eighth grade_Social studies_328x200.png",

		國中九上_CH:
			"./assets/img/Junior high school ninth grade_Chinese_328x200.png",
		國中九上_EN:
			"./assets/img/Junior high school ninth grade_English_328x200.png",
		國中九上_MA:
			"./assets/img/Junior high school ninth grade_Math_328x200.png",
		國中九上_SC:
			"./assets/img/Junior high school ninth grade_Science_328x200.png",
		國中九上_SO:
			"./assets/img/Junior high school ninth grade_Social studies_328x200.png",


		國小一下_CH:
			"./assets/img/Elementary school first grade_Chinese_328x200.png",
		國小一下_LI:
			"./assets/img/Elementary school first grade_Life_328x200.png",
		國小一下_MA:
			"./assets/img/Elementary school first grade_Math_328x200.png",
		國小一下_EN:
			"./assets/img/Elementary school first grade_English_328x200.png",

		國小二下_CH:
			"./assets/img/Elementary school second grade_Chinese_328x200.png",
		國小二下_LI:
			"./assets/img/Elementary school second grade_Life_328x200.png",
		國小二下_MA:
			"./assets/img/Elementary school second grade_Math_328x200.png",
		國小二下_EN:
			"./assets/img/Elementary school second grade_English_328x200.png",

		國小三下_CH:
			"./assets/img/Elementary school third grade_Chinese_328x200.png",
		國小三下_MA:
			"./assets/img/Elementary school third grade_Math_328x200.png",
		國小三下_SC:
			"./assets/img/Elementary school third grade_Science_328x200.png",
		國小三下_SO:
			"./assets/img/Elementary school third grade_Social studies_328x200.png",
		國小三下_EN:
			"./assets/img/Elementary school third grade_English_328x200.png",

		國小四下_CH:
			"./assets/img/Elementary school fourth grade_Chinese_328x200.png",
		國小四下_MA:
			"./assets/img/Elementary school fourth grade_Math_328x200.png",
		國小四下_SC:
			"./assets/img/Elementary school fourth grade_Science_328x200.png",
		國小四下_SO:
			"./assets/img/Elementary school fourth grade_Social studies_328x200.png",
		國小四下_EN:
			"./assets/img/Elementary school fourth grade_English_328x200.png",

		國小五下_CH:
			"./assets/img/Elementary school fifth grade_Chinese_328x200.png",
		國小五下_MA:
			"./assets/img/Elementary school fifth grade_Math_328x200.png",
		國小五下_SC:
			"./assets/img/Elementary school fifth grade_Science_328x200.png",
		國小五下_SO:
			"./assets/img/Elementary school fifth grade_Social studies_328x200.png",
		國小五下_EN:
			"./assets/img/Elementary school fifth grade_English_328x200.png",

		國小六下_CH:
			"./assets/img/Elementary school sixth grade_Chinese_328x200.png",
		國小六下_MA:
			"./assets/img/Elementary school sixth grade_Math_328x200.png",
		國小六下_SC:
			"./assets/img/Elementary school sixth grade_Science_328x200.png",
		國小六下_SO:
			"./assets/img/Elementary school sixth grade_Social studies_328x200.png",
		國小六下_EN:
			"./assets/img/Elementary school sixth grade_English_328x200.png",

		國中七下_CH:
			"./assets/img/Junior high school seventh grade_Chinese_328x200.png",
		國中七下_EN:
			"./assets/img/Junior high school seventh grade_English_328x200.png",
		國中七下_MA:
			"./assets/img/Junior high school seventh grade_Math_328x200.png",
		國中七下_SC:
			"./assets/img/Junior high school seventh grade_Science_328x200.png",
		國中七下_SO:
			"./assets/img/Junior high school seventh grade_Social studies_328x200.png",

		國中八下_CH:
			"./assets/img/Junior high school eighth grade_Chinese_328x200.png",
		國中八下_EN:
			"./assets/img/Junior high school eighth grade_English_328x200.png",
		國中八下_MA:
			"./assets/img/Junior high school eighth grade_Math_328x200.png",
		國中八下_SC:
			"./assets/img/Junior high school eighth grade_Science_328x200.png",
		國中八下_SO:
			"./assets/img/Junior high school eighth grade_Social studies_328x200.png",

		國中九下_CH:
			"./assets/img/Junior high school ninth grade_Chinese_328x200.png",
		國中九下_EN:
			"./assets/img/Junior high school ninth grade_English_328x200.png",
		國中九下_MA:
			"./assets/img/Junior high school ninth grade_Math_328x200.png",
		國中九下_SC:
			"./assets/img/Junior high school ninth grade_Science_328x200.png",
		國中九下_SO:
			"./assets/img/Junior high school ninth grade_Social studies_328x200.png",

		高中一上_CH:
			"./assets/img/Senior high school tenth grade_Chinese_328x200.png",
		高中一上_EN:
			"./assets/img/Senior high school tenth grade_English_328x200.png",
		高中一上_CI:
			"./assets/img/Senior high school tenth grade_Citizenship and Society_328x200.png",
		高中一上_MA:
			"./assets/img/Senior high school tenth grade_Math_328x200.png",
		高中一上_GE:
			"./assets/img/Senior high school tenth grade_Geography_328x200.png",
		高中一上_HI:
			"./assets/img/Senior high school tenth grade_History_328x200.png",
		高中一下_CH:
			"./assets/img/Senior high school tenth grade_Chinese_328x200.png",
		高中一下_EN:
			"./assets/img/Senior high school tenth grade_English_328x200.png",
		高中一下_CI:
			"./assets/img/Senior high school tenth grade_Citizenship and Society_328x200.png",
		高中一下_MA:
			"./assets/img/Senior high school tenth grade_Math_328x200.png",
		高中一下_GE:
			"./assets/img/Senior high school tenth grade_Geography_328x200.png",
		高中一下_HI:
			"./assets/img/Senior high school tenth grade_History_328x200.png",

		高中二上_CH:
			"./assets/img/Senior high school eleventh grade_Chinese_328x200.png",
		高中二上_M1:
			"./assets/img/Senior high school eleventh grade_MathA_328x200.png",
		高中二上_M2:
			"./assets/img/Senior high school eleventh grade_MathB_328x200.png",
		高中二上_EN:
			"./assets/img/Senior high school eleventh grade_English_328x200.png",
		高中二上_CI:
			"./assets/img/Senior high school eleventh grade_Citizenship and Society_328x200.png",
		高中二上_GE:
			"./assets/img/Senior high school eleventh grade_Geography_328x200.png",
		高中二上_HI:
			"./assets/img/Senior high school eleventh grade_History_328x200.png",
		高中二下_CH:
			"./assets/img/Senior high school eleventh grade_Chinese_328x200.png",
		高中二下_M1:
			"./assets/img/Senior high school eleventh grade_MathA_328x200.png",
		高中二下_M2:
			"./assets/img/Senior high school eleventh grade_MathB_328x200.png",
		高中二下_EN:
			"./assets/img/Senior high school eleventh grade_English_328x200.png",
		高中二下_CI:
			"./assets/img/Senior high school eleventh grade_Citizenship and Society_328x200.png",
		高中二下_GE:
			"./assets/img/Senior high school eleventh grade_Geography_328x200.png",
		高中二下_HI:
			"./assets/img/Senior high school eleventh grade_History_328x200.png",


		高中三上_CH:
			"./assets/img/Senior high school twelfth grade_Chinese_328x200.png",
		高中三上_EN:
			"./assets/img/Senior high school twelfth grade_English_328x200.png",
		高中三下_CH:
			"./assets/img/Senior high school twelfth grade_Chinese_328x200.png",
		高中三下_EN:
			"./assets/img/Senior high school twelfth grade_English_328x200.png",

		高中全_BIO:
			"./assets/img/Senior high school  (Basic)_Biology_328x200.png",
		高中全_CHE:
			"./assets/img/Senior high school  (Basic)_Chemical_328x200.png",
		高中全_EAR:
			"./assets/img/Senior high school  (Basic)_Earth Science_328x200.png",
		高中全_PHY:
			"./assets/img/Senior high school  (Basic)_physics_328x200.png",

		高中選修I_BIO:
			"./assets/img/Senior high school  (Elective I)_Biology_328x200.png",
		高中選修I_CHE:
			"./assets/img/Senior high school  (Elective I)_Chemical_328x200.png",
		高中選修I_CI:
			"./assets/img/Senior high school  (Elective I)_Citizenship and Society_328x200.png",
		高中選修I_GE:
			"./assets/img/Senior high school  (Elective I)_Geography_328x200.png",
		高中選修I_HI:
			"./assets/img/Senior high school  (Elective I)_History_328x200.png",
		高中選修I_PHY:
			"./assets/img/Senior high school  (Elective I)_physics_328x200.png",

		高中選修II_BIO:
			"./assets/img/Senior high school  (Elective II)_Biology_328x200.png",
		高中選修II_CHE:
			"./assets/img/Senior high school  (Elective II)_Chemical_328x200.png",
		高中選修II_CI:
			"./assets/img/Senior high school  (Elective II)_Citizenship and Society_328x200.png",
		高中選修II_GE:
			"./assets/img/Senior high school  (Elective II)_Geography_328x200.png",
		高中選修II_HI:
			"./assets/img/Senior high school  (Elective II)_History_328x200.png",
		高中選修II_PHY:
			"./assets/img/Senior high school  (Elective II)_physics_328x200.png",

		高中選修III_BIO:
			"./assets/img/Senior high school  (Elective III)_Biology_328x200.png",
		高中選修III_CHE:
			"./assets/img/Senior high school  (Elective III)_Chemical_328x200.png",
		高中選修III_PHY:
			"./assets/img/Senior high school  (Elective III)_physics_328x200.png",

		高中選修IV_BIO:
			"./assets/img/Senior high school  (Elective IV)_Biology_328x200.png",
		高中選修IV_CHE:
			"./assets/img/Senior high school  (Elective IV)_Chemical_328x200.png",
		高中選修IV_PHY:
			"./assets/img/Senior high school  (Elective IV)_physics_328x200.png",

		高中選修V_CHE:
			"./assets/img/Senior high school  (Elective V)_Chemical_328x200.png",
		高中選修V_PHY:
			"./assets/img/Senior high school  (Elective V)_physics_328x200.png",

		高中總複習_BIO:
			"./assets/img/Senior high school (review)_Biology_328x200.png",
		高中總複習_CHE:
			"./assets/img/Senior high school (review)_Chemical_328x200.png",
		高中總複習_CH:
			"./assets/img/Senior high school (review)_Chinese_328x200.png",
		高中總複習_CI:
			"./assets/img/Senior high school (review)_Citizenship and Society_328x200.png",
		高中總複習_EAR:
			"./assets/img/Senior high school (review)_Earth Science_328x200.png",
		高中總複習_EN:
			"./assets/img/Senior high school (review)_English_328x200.png",
		高中總複習_GE:
			"./assets/img/Senior high school (review)_Geography_328x200.png",
		高中總複習_HI:
			"./assets/img/Senior high school (review)_History.png",
		高中總複習_MA:
			"./assets/img/Senior high school (review)_Math_328x200.png",
		高中總複習_PHY:
			"./assets/img/Senior high school (review)_physics_328x200.png",

		高中選修甲_MA1:
			"./assets/img/Senior high school Math Enrichment I_Math_328x200.png",
		高中選修甲_MA2:
			"./assets/img/Senior high school Math Enrichment I_Math_328x200 (1).png",
		高中選修乙_MA3:
			"./assets/img/Senior high school Math Enrichment II_Math__200x120.png",
		高中選修乙_MA4:
			"./assets/img/Senior high school Math Enrichment II_Math__200x120 (1).png",
		高中龘選修甲_MA1:
			"./assets/img/Senior high school Math Enrichment I_Math_328x200.png",
		高中龘選修甲_MA2:
			"./assets/img/Senior high school Math Enrichment I_Math_328x200 (1).png",

		高中龘選修乙_MA3:
			"./assets/img/Senior high school Math Enrichment II_Math__200x120.png",
		高中龘選修乙_MA4:
			"./assets/img/Senior high school Math Enrichment II_Math__200x120 (1).png",
	},
	VOLUME: {
		主題式國語: "./assets/img/Chinese_608x216.png",
		主題式國語_超越盃: "./assets/img/Chinese_608x216.png",
		主題式國語文: "./assets/img/Chinese III_608x216.png",
		主題式國語文_超越盃: "./assets/img/Chinese III_608x216.png",
		主題式國文: "./assets/img/Chinese II_608x216.png",
		主題式國文_超越盃: "./assets/img/Chinese II_608x216.png",
		主題式數學: "./assets/img/MathA_608x216.png",
		'主題式數學(全)_超越盃': "./assets/img/MathA_608x216.png",
		主題式英語: "./assets/img/English_608x216.png",
		主題式英語_超越盃: "./assets/img/English_608x216.png",
		'主題式英語(全)_超越盃': "./assets/img/English_608x216.png",
		'主題式英語(全)_會話': "./assets/img/English_608x216.png",
		'主題式英語(全)_文法': "./assets/img/English_608x216.png",
		主題式英文: "./assets/img/English II_608x216.png",
		主題式英文_超越盃: "./assets/img/English II_608x216.png",
		主題式生活: "./assets/img/Life Curriculum_608x216.png",
		主題式生活_超越盃: "./assets/img/Life Curriculum_608x216.png",
		主題式社會: "./assets/img/Social Studies_608x216.png",
		主題式社會_超越盃: "./assets/img/Social Studies_608x216.png",
		主題式自然: "./assets/img/Science_608x216.png",
		主題式自然_超越盃: "./assets/img/Science_608x216.png",
		主題式地理: "./assets/img/Geography_608x216.png",
		主題式地理_超越盃: "./assets/img/Geography_608x216.png",
		主題式歷史: "./assets/img/History_608x216.png",
		主題式歷史_超越盃: "./assets/img/History_608x216.png",
		主題式公民與社會: "./assets/img/Civics and Society _608x216.png",
		主題式公民與社會_超越盃: "./assets/img/Civics and Society _608x216.png",
		主題式地球科學: "./assets/img/Earth Science_608x216.png",
		主題式地球科學_超越盃: "./assets/img/Earth Science_608x216.png",
		主題式生物: "./assets/img/Biology_608x216.png",
		主題式生物_超越盃: "./assets/img/Biology_608x216.png",
		主題式化學: "./assets/img/Chemical_608x216.png",
		主題式化學_超越盃: "./assets/img/Chemical_608x216.png",
		主題式物理: "./assets/img/Physics_608x216.png",
		主題式物理_超越盃: "./assets/img/Physics_608x216.png",
		// 主題式國語: "./assets/img/Chinese_608x216.png",
		主題式溫美玉ｘ魏瑛娟ㄅㄆㄇ_ㄅㄆㄇ注音學習樂園:"./assets/img/WenMeiYu_608x216.png",
		主題式溫美玉ｘ魏瑛娟寫作課_中高年級人物寫作課:"./assets/img/WenMeiYu_608x216.png",

		國小一上_CH:
			"./assets/img/Elementary school first grade_Chinese_608x216.png",
		國小一上_LI:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小一上_MA:
			"./assets/img/Elementary school first grade_Math_608x216.png",

		國小二上_CH:
			"./assets/img/Elementary school second grade_Chinese_608x216.png",
		國小二上_LI:
			"./assets/img/Elementary school second grade_Life_608x216.png",
		國小二上_MA:
			"./assets/img/Elementary school second grade_Math_608x216.png",

		國小三上_CH:
			"./assets/img/Elementary school third grade_Chinese_608x216.png",
		國小三上_LI:
			"./assets/img/Elementary school second grade_Life_608x216.png",
		國小三上_MA:
			"./assets/img/Elementary school third grade_Math_608x216.png",
		國小三上_SC:
			"./assets/img/Elementary school third grade_Science_608x216.png",
		國小三上_SO:
			"./assets/img/Elementary school third grade_Social studies_608x216.png",
		國小三上_EN:
		"./assets/img/Elementary school third grade_English_608x216.png",

		國小四上_CH:
			"./assets/img/Elementary school fourth grade_Chinese_608x216.png",
		國小四上_LI:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小四上_MA:
			"./assets/img/Elementary school fourth grade_Math_608x216.png",
		國小四上_SC:
			"./assets/img/Elementary school fourth grade_Science_608x216.png",
		國小四上_SO:
			"./assets/img/Elementary school fourth grade_Social studies_608x216.png",
		國小四上_EN:
		"./assets/img/Elementary school fourth grade_English_608x216.png",

		國小五上_CH:
			"./assets/img/Elementary school fifth grade_Chinese_608x216.png",
		國小五上_LI:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小五上_MA:
			"./assets/img/Elementary school fifth grade_Math_608x216.png",
		國小五上_SC:
			"./assets/img/Elementary school fifth grade_Science_608x216.png",
		國小五上_SO:
			"./assets/img/Elementary school fifth grade_Social studies_608x216.png",
		國小五上_EN:
		"./assets/img/Elementary school fifth grade_English_608x216.png",

		國小六上_CH:
			"./assets/img/Elementary school sixth grade_Chinese_608x216.png",
		國小六上_LI:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小六上_MA:
			"./assets/img/Elementary school sixth grade_Math_608x216.png",
		國小六上_SC:
			"./assets/img/Elementary school sixth grade_Science_608x216.png",
		國小六上_SO:
			"./assets/img/Elementary school sixth grade_Social studies_608x216.png",
		國小六上_EN:
		"./assets/img/Elementary school sixth grade_English_608x216.png",

		國中七上_CH:
			"./assets/img/Junior high school seventh grade_Chinese_608x216.png",
		國中七上_EN:
			"./assets/img/Junior high school seventh grade_English_608x216.png",
		國中七上_MA:
			"./assets/img/Junior high school seventh grade_Math_608x216.png",
		國中七上_SC:
			"./assets/img/Junior high school seventh grade_Science_608x216.png",
		國中七上_SO:
			"./assets/img/Junior high school seventh grade_Social studies_608x216.png",

		國中八上_CH:
			"./assets/img/Junior high school eighth grade_Chinese_608x216.png",
		國中八上_EN:
			"./assets/img/Junior high school eighth grade_English_608x216.png",
		國中八上_MA:
			"./assets/img/Junior high school eighth grade_Math_608x216.png",
		國中八上_SC:
			"./assets/img/Junior high school eighth grade_Science_608x216.png",
		國中八上_SO:
			"./assets/img/Junior high school eighth grade_Social studies_608x216.png",

		國中九上_CH:
			"./assets/img/Junior high school ninth grade_Chinese_608x216.png",
		國中九上_EN:
			"./assets/img/Junior high school ninth grade_English_608x216.png",
		國中九上_MA:
			"./assets/img/Junior high school ninth grade_Math_608x216.png",
		國中九上_SC:
			"./assets/img/Junior high school ninth grade_Science_608x216.png",
		國中九上_SO:
			"./assets/img/Junior high school ninth grade_Social studies_608x216.png",


		國小一下_CH:
			"./assets/img/Elementary school first grade_Chinese_608x216.png",
		國小一下_LI:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小一下_MA:
			"./assets/img/Elementary school first grade_Math_608x216.png",
		國小一下_EN:
			"./assets/img/Elementary school first grade_English_608x216.png",

		國小二下_CH:
			"./assets/img/Elementary school second grade_Chinese_608x216.png",
		國小二下_LI:
			"./assets/img/Elementary school second grade_Life_608x216.png",
		國小二下_MA:
			"./assets/img/Elementary school second grade_Math_608x216.png",
		國小二下_EN:
			"./assets/img/Elementary school second grade_English_608x216.png",

		國小三下_CH:
			"./assets/img/Elementary school third grade_Chinese_608x216.png",
		國小三下_LI:
			"./assets/img/Elementary school second grade_Life_608x216.png",
		國小三下_MA:
			"./assets/img/Elementary school third grade_Math_608x216.png",
		國小三下_SC:
			"./assets/img/Elementary school third grade_Science_608x216.png",
		國小三下_SO:
			"./assets/img/Elementary school third grade_Social studies_608x216.png",
		國小三下_EN:
			"./assets/img/Elementary school third grade_English_608x216.png",

		國小四下_CH:
			"./assets/img/Elementary school fourth grade_Chinese_608x216.png",
		國小四下_LI:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小四下_MA:
			"./assets/img/Elementary school fourth grade_Math_608x216.png",
		國小四下_SC:
			"./assets/img/Elementary school fourth grade_Science_608x216.png",
		國小四下_SO:
			"./assets/img/Elementary school fourth grade_Social studies_608x216.png",
		國小四下_EN:
			"./assets/img/Elementary school fourth grade_English_608x216.png",

		國小五下_CH:
			"./assets/img/Elementary school fifth grade_Chinese_608x216.png",
		國小五下_LI:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小五下_MA:
			"./assets/img/Elementary school fifth grade_Math_608x216.png",
		國小五下_SC:
			"./assets/img/Elementary school fifth grade_Science_608x216.png",
		國小五下_SO:
			"./assets/img/Elementary school fifth grade_Social studies_608x216.png",
		國小五下_EN:
			"./assets/img/Elementary school fifth grade_English_608x216.png",

		國小六下_CH:
			"./assets/img/Elementary school sixth grade_Chinese_608x216.png",
		國小六下_LI:
			"./assets/img/Elementary school first grade_Life_608x216.png",
		國小六下_MA:
			"./assets/img/Elementary school sixth grade_Math_608x216.png",
		國小六下_SC:
			"./assets/img/Elementary school sixth grade_Science_608x216.png",
		國小六下_SO:
			"./assets/img/Elementary school sixth grade_Social studies_608x216.png",
		國小六下_EN:
			"./assets/img/Elementary school sixth grade_English_608x216.png",

		國中七下_CH:
			"./assets/img/Junior high school seventh grade_Chinese_608x216.png",
		國中七下_EN:
			"./assets/img/Junior high school seventh grade_English_608x216.png",
		國中七下_MA:
			"./assets/img/Junior high school seventh grade_Math_608x216.png",
		國中七下_SC:
			"./assets/img/Junior high school seventh grade_Science_608x216.png",
		國中七下_SO:
			"./assets/img/Junior high school seventh grade_Social studies_608x216.png",

		國中八下_CH:
			"./assets/img/Junior high school eighth grade_Chinese_608x216.png",
		國中八下_EN:
			"./assets/img/Junior high school eighth grade_English_608x216.png",
		國中八下_MA:
			"./assets/img/Junior high school eighth grade_Math_608x216.png",
		國中八下_SC:
			"./assets/img/Junior high school eighth grade_Science_608x216.png",
		國中八下_SO:
			"./assets/img/Junior high school eighth grade_Social studies_608x216.png",

		國中九下_CH:
			"./assets/img/Junior high school ninth grade_Chinese_608x216.png",
		國中九下_EN:
			"./assets/img/Junior high school ninth grade_English_608x216.png",
		國中九下_MA:
			"./assets/img/Junior high school ninth grade_Math_608x216.png",
		國中九下_SC:
			"./assets/img/Junior high school ninth grade_Science_608x216.png",
		國中九下_SO:
			"./assets/img/Junior high school ninth grade_Social studies_608x216.png",

		高中一上_CH:
			"./assets/img/Senior high school tenth grade_Chinese_608x216.png",
		高中一上_EN:
			"./assets/img/Senior high school tenth grade_English_608x216.png",
		高中一上_CI:
			"./assets/img/Senior high school tenth grade_Citizenship and Society_608x216.png",
		高中一上_MA:
			"./assets/img/Senior high school tenth grade_Math_608x216.png",
		高中一上_GE:
			"./assets/img/Senior high school tenth grade_Geography_608x216.png",
		高中一上_HI:
			"./assets/img/Senior high school tenth grade_History_608x216.png",
		高中一下_CH:
			"./assets/img/Senior high school tenth grade_Chinese_608x216.png",
		高中一下_EN:
			"./assets/img/Senior high school tenth grade_English_608x216.png",
		高中一下_CI:
			"./assets/img/Senior high school tenth grade_Citizenship and Society_608x216.png",
		高中一下_MA:
			"./assets/img/Senior high school tenth grade_Math_608x216.png",
		高中一下_GE:
			"./assets/img/Senior high school tenth grade_Geography_608x216.png",
		高中一下_HI:
			"./assets/img/Senior high school tenth grade_History_608x216.png",

		高中二上_CH:
			"./assets/img/Senior high school eleventh grade_Chinese_608x216.png",
		高中二上_M1:
			"./assets/img/Senior high school eleventh grade_MathA_608x216.png",
		高中二上_M2:
			"./assets/img/Senior high school eleventh grade_MathB_608x216.png",
		高中二上_EN:
			"./assets/img/Senior high school eleventh grade_English_608x216.png",
		高中二上_CI:
			"./assets/img/Senior high school eleventh grade_Citizenship and Society_608x216.png",
		高中二上_GE:
			"./assets/img/Senior high school eleventh grade_Geography_608x216.png",
		高中二上_HI:
			"./assets/img/Senior high school eleventh grade_History_608x216.png",
		高中二下_CH:
			"./assets/img/Senior high school eleventh grade_Chinese_608x216.png",
		高中二下_M1:
			"./assets/img/Senior high school eleventh grade_MathA_608x216.png",
		高中二下_M2:
			"./assets/img/Senior high school eleventh grade_MathB_608x216.png",
		高中二下_EN:
			"./assets/img/Senior high school eleventh grade_English_608x216.png",
		高中二下_CI:
			"./assets/img/Senior high school eleventh grade_Citizenship and Society_608x216.png",
		高中二下_GE:
			"./assets/img/Senior high school eleventh grade_Geography_608x216.png",
		高中二下_HI:
			"./assets/img/Senior high school eleventh grade_History_608x216.png",


		高中三上_CH:
			"./assets/img/Senior high school twelfth grade_Chinese_608x216.png",
		高中三下_EN:
			"./assets/img/Senior high school twelfth grade_English_608x216.png",

		高中全_BIO:
			"./assets/img/Senior high school  (Basic)_Biology_608x216.png",
		高中全_CHE:
			"./assets/img/Senior high school  (Basic)_Chemical_608x216.png",
		高中全_EAR:
			"./assets/img/Senior high school  (Basic)_Earth Science_608x216.png",
		高中全_PHY:
			"./assets/img/Senior high school  (Basic)_physics_608x216.png",

		高中選修I_BIO:
			"./assets/img/Senior high school  (Elective I)_Biology_608x216.png",
		高中選修I_CHE:
			"./assets/img/Senior high school  (Elective I)_Chemical_608x216.png",
		高中選修I_CI:
			"./assets/img/Senior high school  (Elective I)_Citizenship and Society_608x216.png",
		高中選修I_GE:
			"./assets/img/Senior high school  (Elective I)_Geography_608x216.png",
		高中選修I_HI:
			"./assets/img/Senior high school  (Elective I)_History_608x216.png",
		高中選修I_PHY:
			"./assets/img/Senior high school  (Elective I)_physics_608x216.png",

		高中選修II_BIO:
			"./assets/img/Senior high school  (Elective II)_Biology_608x216.png",
		高中選修II_CHE:
			"./assets/img/Senior high school  (Elective II)_Chemical_608x216.png",
		高中選修II_CI:
			"./assets/img/Senior high school  (Elective II)_Citizenship and Society_608x216.png",
		高中選修II_GE:
			"./assets/img/Senior high school  (Elective II)_Geography_608x216.png",
		高中選修II_HI:
			"./assets/img/Senior high school  (Elective II)_History_608x216.png",
		高中選修II_PHY:
			"./assets/img/Senior high school  (Elective II)_physics_608x216.png",

		高中選修III_BIO:
			"./assets/img/Senior high school  (Elective III)_Biology_608x216.png",
		高中選修III_CHE:
			"./assets/img/Senior high school  (Elective III)_Chemical_608x216.png",
		高中選修III_PHY:
			"./assets/img/Senior high school  (Elective III)_physics_608x216.png",

		高中選修IV_BIO:
			"./assets/img/Senior high school  (Elective IV)_Biology_608x216.png",
		高中選修IV_CHE:
			"./assets/img/Senior high school  (Elective IV)_Chemical_608x216.png",
		高中選修IV_PHY:
			"./assets/img/Senior high school  (Elective IV)_physics_608x216.png",

		高中選修V_CHE:
			"./assets/img/Senior high school  (Elective V)_Chemical_608x216.png",
		高中選修V_PHY:
			"./assets/img/Senior high school  (Elective V)_physics_608x216.png",

		高中總複習_BIO:
			"./assets/img/Senior high school (review)_Biology_608x216.png",
		高中總複習_CHE:
			"./assets/img/Senior high school (review)_Chemical_608x216.png",
		高中總複習_CH:
			"./assets/img/Senior high school (review)_Chinese_608x216.png",
		高中總複習_CI:
			"./assets/img/Senior high school (review)_Citizenship and Society_608x216.png",
		高中總複習_EAR:
			"./assets/img/Senior high school (review)_Earth Science_608x216.png",
		高中總複習_EN:
			"./assets/img/Senior high school (review)_English_608x216.png",
		高中總複習_GE:
			"./assets/img/Senior high school (review)_Geography_608x216.png",
		高中總複習_HI:
			"./assets/img/Senior high school (review)_History_608x216.png",
		高中總複習_MA:
			"./assets/img/Senior high school (review)_Math_608x216.png",
		高中總複習_PHY:
			"./assets/img/Senior high school (review)_physics_608x216.png",

		高中選修甲_MA1:
			"./assets/img/Senior high school Math Enrichment I_Math_608x216.png",
		高中選修甲_MA2:
			"./assets/img/Senior high school Math Enrichment I_Math_608x216 (1).png",

		高中選修乙_MA3:
			"./assets/img/Senior high school Math Enrichment II_Math_608x216.png",
		高中選修乙_MA4:
			"./assets/img/Senior high school Math Enrichment II_Math_608x216 (1).png",
	},
	NANI_PRODUCT: {
		"國小主題式課程互動-國語":"./assets/img/nanistudy/productImgs/國小/國小國語互動學習包B.png",
		"國小主題式課程互動-三視圖":"./assets/img/nanistudy/productImgs/國小/國小數學互動三視圖B.png",
		
		"國小主題式課程互動-自然":"./assets/img/nanistudy/productImgs/國小/國小自然互動實驗室B.png",
		"國小主題式課程互動-生活":"./assets/img/nanistudy/productImgs/國小/國小生活互動學習包B.png",
		國小主題式課程數位閱讀1: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀1B.jpg",
		國小主題式課程數位閱讀2: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀2B.jpg",
		國小主題式課程數位閱讀3: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀3B.jpg",
		國小主題式課程數位閱讀4: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀4B.jpg",
		國小主題式課程數位閱讀5: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀5B.jpg",
		國小主題式課程數位閱讀6: "./assets/img/nanistudy/productImgs/國小/國小主題式課程數位閱讀6B.jpg",

		國小主題式課程社會動畫1: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫1B.jpg",
		國小主題式課程社會動畫2: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫2B.jpg",
		國小主題式課程社會動畫3: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫3B.jpg",
		國小主題式課程社會動畫4: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫4B.jpg",
		國小主題式課程社會動畫5: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫5B.jpg",
		國小主題式課程社會動畫6: "./assets/img/nanistudy/productImgs/國小/國小主題式課程社會動畫6B.jpg",

		"國中主題式課程互動-三視圖":"./assets/img/nanistudy/productImgs/國中/國中數學互動三視圖B.png",
		國中主題式課程國文閱讀: "./assets/img/nanistudy/productImgs/國中/國中主題式課程國文閱讀B.jpg",
		國中主題式課程FUN文法: "./assets/img/nanistudy/productImgs/國中/國中主題式課程FUN文法B.jpg",
		國中主題式課程地科補救: "./assets/img/nanistudy/productImgs/國中/國中主題式課程地科補救B.jpg",
		國中主題式課程生物補救: "./assets/img/nanistudy/productImgs/國中/國中主題式課程生物補救B.jpg",
		國中主題式課程國文寫型: "./assets/img/nanistudy/productImgs/國中/國中主題式課程國文寫型B.jpg",
		國中主題式課程語文常識: "./assets/img/nanistudy/productImgs/國中/國中主題式課程語文常識B.jpg",
		國中主題式課程地理概念: "./assets/img/nanistudy/productImgs/國中/國中主題式課程地理概念B.jpg",
		國中主題式課程歷史概念: "./assets/img/nanistudy/productImgs/國中/國中主題式課程歷史概念B.jpg",
		國中主題式課程公民概念: "./assets/img/nanistudy/productImgs/國中/國中主題式課程公民概念B.jpg",
		國中主題式課程數學補救7: "./assets/img/nanistudy/productImgs/國中/國中主題式課程數學補救7B.jpg",
		國中主題式課程數學補救8: "./assets/img/nanistudy/productImgs/國中/國中主題式課程數學補救8B.jpg",
		國中主題式課程理化補救8: "./assets/img/nanistudy/productImgs/國中/國中主題式課程理化補救8B.jpg",
		國中主題式課程數學補救9: "./assets/img/nanistudy/productImgs/國中/國中主題式課程數學補救9B.jpg",
		國中主題式課程理化補救9: "./assets/img/nanistudy/productImgs/國中/國中主題式課程理化補救9B.jpg",

		高中主題式課程地科測驗: "./assets/img/nanistudy/productImgs/高中/高中主題式課程地科測驗B.jpg",
		高中主題式課程生物測驗: "./assets/img/nanistudy/productImgs/高中/高中主題式課程生物測驗B.jpg",
		高中主題式課程地理測驗: "./assets/img/nanistudy/productImgs/高中/高中主題式課程地理測驗B.jpg",
		高中主題式課程歷史測驗: "./assets/img/nanistudy/productImgs/高中/高中主題式課程歷史測驗B.jpg",
		高中主題式課程化學測驗: "./assets/img/nanistudy/productImgs/高中/高中主題式課程化學測驗B.jpg",
		高中主題式課程數學測驗: "./assets/img/nanistudy/productImgs/高中/高中主題式課程數學測驗B.jpg",
		高中主題式課程物理測驗: "./assets/img/nanistudy/productImgs/高中/高中主題式課程物理測驗B.jpg",
		高中主題式課程公社測驗: "./assets/img/nanistudy/productImgs/高中/高中主題式課程公社測驗B.jpg",

		主題式教師專區數學雙語: "./assets/img/nanistudy/productImgs/主題式教師專區數學雙語B.jpg",
		主題式教師專區高中數學雙語: "./assets/img/nanistudy/productImgs/高中/主題式教師專區數學雙語(高中)B.jpg",
		主題式教師專區地理MR: "./assets/img/nanistudy/productImgs/高中/主題式教師專區地理MRB.jpg",
		主題式教師專區地科MR: "./assets/img/nanistudy/productImgs/主題式教師專區地科MR(高中)B.jpg",
		主題式教師專區高中地科MR: "./assets/img/nanistudy/productImgs/高中/主題式教師專區地科MR(高中)B.jpg",
		主題式教師專區生物MR: "./assets/img/nanistudy/productImgs/國中/主題式教師專區生物MRB.jpg",
		主題式教師專區地科探究: "./assets/img/nanistudy/productImgs/高中/主題式教師專區地科探究B.jpg",
		主題式教師專區生物雙語: "./assets/img/nanistudy/productImgs/高中/主題式教師專區生物雙語B.jpg",
		主題式教師專區生物探究: "./assets/img/nanistudy/productImgs/高中/主題式教師專區生物探究B.jpg",
		主題式教師專區健康雙語: "./assets/img/nanistudy/productImgs/國小/主題式教師專區健康雙語B.jpg",
		主題式教師專區體育雙語: "./assets/img/nanistudy/productImgs/國小/主題式教師專區體育雙語B.jpg",
		主題式教師專區綜合雙語: "./assets/img/nanistudy/productImgs/國小/主題式教師專區綜合雙語B.jpg",
		主題式教師專區童軍雙語: "./assets/img/nanistudy/productImgs/國中/主題式教師專區童軍雙語B.jpg",
		主題式教師專區化學雙語: "./assets/img/nanistudy/productImgs/高中/主題式教師專區化學雙語B.jpg",
		主題式教師專區化學探究: "./assets/img/nanistudy/productImgs/高中/主題式教師專區化學探究B.jpg",
		主題式教師專區遊戲程式: "./assets/img/nanistudy/productImgs/國小/主題式教師專區遊戲程式B.jpg",
		主題式教師專區物理雙語: "./assets/img/nanistudy/productImgs/高中/主題式教師專區物理雙語B.jpg",
		主題式教師專區物理探究: "./assets/img/nanistudy/productImgs/高中/主題式教師專區物理探究B.jpg",
		主題式教師專區律動學習1: "./assets/img/nanistudy/productImgs/國小/主題式教師專區律動學習1B.jpg",
		主題式教師專區律動學習2: "./assets/img/nanistudy/productImgs/國小/主題式教師專區律動學習2B.jpg",
		主題式教師專區自然雙語: "./assets/img/nanistudy/productImgs/國中/主題式教師專區自然雙語B.jpg",
		
		"主題式教師專區健康MR": "./assets/img/nanistudy/productImgs/國小/國小健康MR主題包B.png",
		"主題式教師專區立體解題MR": "./assets/img/nanistudy/productImgs/國小/國小數學立體解題MR主題包1B.png",
		"主題式教師專區認識立體MR": "./assets/img/nanistudy/productImgs/國小/國小數學認識立體MR主題包1B.png",
		"主題式教師專區生物MR2": "./assets/img/nanistudy/productImgs/國中/國中自然生物MR主題包2 B.png",
		"主題式教師專區地科MR2": "./assets/img/nanistudy/productImgs/國中/國中自然地球科學MR主題包2 B.png",

		"主題式教師專區高中化學MR": "./assets/img/nanistudy/productImgs/高中/高中化學科MR主題包 1B.png",
		"主題式教師專區高中生物MR": "./assets/img/nanistudy/productImgs/高中/高中生物科MR主題包 B.png",
		"主題式教師專區高中數學雙語2": "./assets/img/nanistudy/productImgs/高中/高中數學科雙語教學主題包2 B.png",
		"主題式教師專區高中數學MR": "./assets/img/nanistudy/productImgs/高中/高中數學科MR主題包 1B.png",

		國小學程一上: "./assets/img/Elementary school first grade_415x265.png",
		國小學程二上: "./assets/img/Elementary school second grade_415x265.png",
		國小學程三上: "./assets/img/Elementary school third grade_415x265.png",
		國小學程四上: "./assets/img/Elementary school fourth grade_415x265.png",
		國小學程五上: "./assets/img/Elementary school fifth grade_415x265.png",
		國小學程六上: "./assets/img/Elementary school sixth grade_415x265.png",
		國中學程七上:
			"./assets/img/Junior higth school seventh grade_415x265.png",
		國中學程八上: "./assets/img/Junior higth school eighth grade_415x265.png",
		國中學程九上: "./assets/img/Junior higth school ninth grade_415x265.png",

		國小學程一下: "./assets/img/Elementary school first grade_415x265.png",
		國小學程二下: "./assets/img/Elementary school second grade_415x265.png",
		國小學程三下: "./assets/img/Elementary school third grade_415x265.png",
		國小學程四下: "./assets/img/Elementary school fourth grade_415x265.png",
		國小學程五下: "./assets/img/Elementary school fifth grade_415x265.png",
		國小學程六下: "./assets/img/Elementary school sixth grade_415x265.png",
		國中學程七下:
			"./assets/img/Junior higth school seventh grade_415x265.png",
		國中學程八下: "./assets/img/Junior higth school eighth grade_415x265.png",
		國中學程九下: "./assets/img/Junior higth school ninth grade_415x265.png",

	},

	PRODUCT: {
		主題式國語_S: "./assets/img/Chinese_640_408.png",
		主題式國語文上_S: "./assets/img/Chinese III_640_408.png",
		主題式國文_S: "./assets/img/Chinese II_640_408.png",
		主題式國語文_S: "./assets/img/Chinese II_640_408.png",
		主題式數學_S: "./assets/img/Math_640_408.png",
		'主題式數學(全)_S': "./assets/img/Math_640_408.png",
		'主題式英語(全)_S': "./assets/img/English_640_408.png",
		主題式溫美玉ｘ魏瑛娟ㄅㄆㄇ_S:"./assets/img/WenMeiYu_640_408.png",
		主題式溫美玉ｘ魏瑛娟寫作課_S:"./assets/img/WenMeiYu_640_408.png",
		主題式英語_S: "./assets/img/English_640_408.png",
		主題式英文_S: "./assets/img/English II_640_408.png",
		主題式生活_S: "./assets/img/Life Curriculum_640_408.png",
		主題式社會_S: "./assets/img/Social Studies_640_408.png",
		主題式自然_S: "./assets/img/Science_640_408.png",
		主題式地理_S: "./assets/img/Geography_640_408.png",
		主題式歷史_S: "./assets/img/History_640_408.png",
		主題式公民與社會_S: "./assets/img/Civics and Society_640_408.png",
		主題式地球科學_S: "./assets/img/Earth Sciences_640_408.png",
		主題式生物_S: "./assets/img/Biology_640_408.png",
		主題式化學_S: "./assets/img/Chemistry_640_408.png",
		主題式物理_S: "./assets/img/Physics_640_408.png",
		主題式溫美玉ｘ魏瑛娟_S: "./assets/img/WenMeiYu_640_408.png",
		// 主題式國語_S: "./assets/img/Chinese_640_408.png",
		主題式國語_B: "./assets/img/Chinese_640_408.png",
		主題式國語文_B: "./assets/img/Chinese III_640_408.png",
		主題式國文_B: "./assets/img/Chinese II_640_408.png",
		'主題式數學(全)_B': "./assets/img/Math_640_408.png",
		主題式數學_B: "./assets/img/Math_640_408.png",
		主題式英語_B: "./assets/img/English_640_408.png",
		'主題式英語(全)_B': "./assets/img/English_640_408.png",
		主題式英文_B: "./assets/img/English II_640_408.png",
		主題式生活_B: "./assets/img/Life Curriculum_640_408.png",
		主題式社會_B: "./assets/img/Social Studies_640_408.png",
		主題式自然_B: "./assets/img/Science_640_408.png",
		主題式地理_B: "./assets/img/Geography_640_408.png",
		主題式歷史_B: "./assets/img/History_640_408.png",
		主題式公民與社會_B: "./assets/img/Civics and Society_640_408.png",
		主題式地球科學_B: "./assets/img/Earth Sciences_640_408.png",
		主題式生物_B: "./assets/img/Biology_640_408.png",
		主題式化學_B: "./assets/img/Chemistry_640_408.png",
		主題式物理_B: "./assets/img/Physics_640_408.png",
		主題式溫美玉ｘ魏瑛娟_B: "./assets/img/WenMeiYu_640_408.png",
		主題式溫美玉ｘ魏瑛娟ㄅㄆㄇ_B: "./assets/img/WenMeiYu_640_408.png",
		主題式溫美玉ｘ魏瑛娟寫作課_B:"./assets/img/WenMeiYu_640_408.png",
		
		


		// 主題式國語上_B: "./assets/img/Chinese_200x120.png",
		// 主題式國語文上_B: "./assets/img/Chinese II_200x120.png",
		// 主題式國文上_B: "./assets/img/Chinese II_200x120.png",
		// 主題式數學上_B: "./assets/img/Math_200x120.png",
		// 主題式英語上_B: "./assets/img/English_200x120.png",
		// 主題式英文上_B: "./assets/img/English II_200x120.png",
		// 主題式生活上_B: "./assets/img/Life Curriculum_200x120.png",
		// 主題式社會上_B: "./assets/img/Social Studies_200x120.png",
		// 主題式自然上_B: "./assets/img/Science_200x120.png",
		// 主題式地理上_B: "./assets/img/Geography_200x120.png",
		// 主題式歷史上_B: "./assets/img/History_200x120.png",
		// 主題式公民與社會上_B: "./assets/img/Civics and Society __200x120.png",
		// 主題式地球科學上_B: "./assets/img/Earth Science_200x120.png",
		// 主題式生物上_B: "./assets/img/Biology_200x120.png",
		// 主題式化學上_B: "./assets/img/Chemical_200x120.png",
		// 主題式物理上_B: "./assets/img/Physics_200x120.png",



		國小一上_S: "./assets/img/Elementary school first grade_415x265.png",
		國小二上_S: "./assets/img/Elementary school second grade_415x265.png",
		國小三上_S: "./assets/img/Elementary school third grade_415x265.png",
		國小四上_S: "./assets/img/Elementary school fourth grade_415x265.png",
		國小五上_S: "./assets/img/Elementary school fifth grade_415x265.png",
		國小六上_S: "./assets/img/Elementary school sixth grade_415x265.png",
		國中七上_S:
			"./assets/img/Junior higth school seventh grade_415x265.png",
		國中八上_S: "./assets/img/Junior higth school eighth grade_415x265.png",
		國中九上_S: "./assets/img/Junior higth school ninth grade_415x265.png",

		// 高中一上_S: "./assets/img/Senior high school tenth grade_609x390.png",
		// 高中二上_S: "./assets/img/Senior high school eleventh grade_609x390.png",
		// 高中三上_S: "./assets/img/Senior high school twelfth grade_609x390.png",
		// 高中全上_S: "./assets/img/Senior high school  (Basic)_609x390.png",
		// 高中選修I上_S: "./assets/img/Senior high school  (Elective I)_609x390.png",
		// 高中選修II上_S: "./assets/img/Senior high school  (Elective II)_609x390.png",
		// 高中選修III上_S: "./assets/img/Senior high school  (Elective III)_609x390.png",
		// 高中選修IV上_S: "./assets/img/Senior high school  (Elective IV)_609x390.png",
		// 高中選修V上_S: "./assets/img/Senior high school  (Elective V)_609x390.png",
		// 高中總複習上_S: "./assets/img/Senior high school (review)_609x390.png",
		// 高中選修甲上_S: "./assets/img/Senior high school Math Enrichment I_609x390.png",
		// 高中選修乙上_S: "./assets/img/Senior high school Math Enrichment II_609x390.png",

		國小一上_B: "./assets/img/Elementary school first grade_609x390.png",
		國小二上_B: "./assets/img/Elementary school second grade_609x390.png",
		國小三上_B: "./assets/img/Elementary school third grade_609x390.png",
		國小四上_B: "./assets/img/Elementary school fourth grade_609x390.png",
		國小五上_B: "./assets/img/Elementary school fifth grade_609x390.png",
		國小六上_B: "./assets/img/Elementary school sixth grade_609x390.png",
		國中七上_B:
			"./assets/img/Junior higth school seventh grade_609x390.png",
		國中八上_B: "./assets/img/Junior higth school eighth grade_609x390.png",
		國中九上_B: "./assets/img/Junior higth school ninth grade_609x390.png",

		國小一下_S: "./assets/img/Elementary school first grade_415x265.png",
		國小二下_S: "./assets/img/Elementary school second grade_415x265.png",
		國小三下_S: "./assets/img/Elementary school third grade_415x265.png",
		國小四下_S: "./assets/img/Elementary school fourth grade_415x265.png",
		國小五下_S: "./assets/img/Elementary school fifth grade_415x265.png",
		國小六下_S: "./assets/img/Elementary school sixth grade_415x265.png",
		國中七下_S:
			"./assets/img/Junior higth school seventh grade_415x265.png",
		國中八下_S: "./assets/img/Junior higth school eighth grade_415x265.png",
		國中九下_S: "./assets/img/Junior higth school ninth grade_415x265.png",
		國小一下_B: "./assets/img/Elementary school first grade_609x390.png",
		國小二下_B: "./assets/img/Elementary school second grade_609x390.png",
		國小三下_B: "./assets/img/Elementary school third grade_609x390.png",
		國小四下_B: "./assets/img/Elementary school fourth grade_609x390.png",
		國小五下_B: "./assets/img/Elementary school fifth grade_609x390.png",
		國小六下_B: "./assets/img/Elementary school sixth grade_609x390.png",
		國中七下_B:
			"./assets/img/Junior higth school seventh grade_609x390.png",
		國中八下_B: "./assets/img/Junior higth school eighth grade_609x390.png",
		國中九下_B: "./assets/img/Junior higth school ninth grade_609x390.png",


		高中一上_S: "./assets/img/Senior high school tenth grade_415x265.png",
		高中二上_S: "./assets/img/Senior high school eleventh grade_415x265.png",
		高中三上_S: "./assets/img/Senior high school twelfth grade_415x265.png",
		高中一下_S: "./assets/img/Senior high school tenth grade_415x265.png",
		高中二下_S: "./assets/img/Senior high school eleventh grade_415x265.png",
		高中三下_S: "./assets/img/Senior high school twelfth grade_415x265.png",
		高中全_S: "./assets/img/Senior high school  (Basic)_415x265.png",
		高中選修I_S: "./assets/img/Senior high school  (Elective I)_415x265.png",
		高中選修II_S: "./assets/img/Senior high school  (Elective II)_415x265.png",
		高中選修III_S: "./assets/img/Senior high school  (Elective III)_415x265.png",
		高中選修IV_S: "./assets/img/Senior high school  (Elective IV)_415x265.png",
		高中選修V_S: "./assets/img/Senior high school  (Elective V)_415x265.png",
		高中總複習_S: "./assets/img/Senior high school (review)_415x265.png",
		高中選修甲_S: "./assets/img/Senior high school Math Enrichment I_415x265.png",
		高中選修乙_S: "./assets/img/Senior high school Math Enrichment II_415x265.png",


		高中一上_B: "./assets/img/Senior high school tenth grade_609x390.png",
		高中二上_B: "./assets/img/Senior high school eleventh grade_609x390.png",
		高中三上_B: "./assets/img/Senior high school twelfth grade_609x390.png",
		高中一下_B: "./assets/img/Senior high school tenth grade_609x390.png",
		高中二下_B: "./assets/img/Senior high school eleventh grade_609x390.png",
		高中三下_B: "./assets/img/Senior high school twelfth grade_609x390.png",
		高中全_B: "./assets/img/Senior high school  (Basic)_609x390.png",
		高中選修I_B: "./assets/img/Senior high school  (Elective I)_609x390.png",
		高中選修II_B: "./assets/img/Senior high school  (Elective II)_609x390.png",
		高中選修III_B: "./assets/img/Senior high school  (Elective III)_609x390.png",
		高中選修IV_B: "./assets/img/Senior high school  (Elective IV)_609x390.png",
		高中選修V_B: "./assets/img/Senior high school  (Elective V)_609x390.png",
		高中總複習_B: "./assets/img/Senior high school (review)_609x390.png",
		高中選修甲_B: "./assets/img/Senior high school Math Enrichment I_609x390.png",
		高中選修乙_B: "./assets/img/Senior high school Math Enrichment II_609x390.png",

	},
};