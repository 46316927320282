import React from 'react';
import styles from './index.module.scss';

const OrderLoading = ({ message = "" }) => {
	return (
		<div className={styles.loadWrapper}>
			<div className={styles.message}>{message}</div>
			<div className={styles.load}>
				<div className={styles.ring}></div>
			</div>
		</div>
	);
}

export default OrderLoading;