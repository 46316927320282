
import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import Header from "components/Header";
import Footer from "components/Footer";
import ParamsStorer from "../../util/paramsStorer";
import { Beforeunload } from 'components/Beforeunload';
import { useStore, StoreTypes } from "context";
import { useLocation, useParams, Link } from "react-router-dom";

const MyOrderDetails = () => {
    const router = useStore(StoreTypes.router);
    const [{ token, userId, product }, userDispatch] = useStore(StoreTypes.user);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    let orderId = query.get("orderId");


    //===========================================================================
    useEffect(() => {
        componentDidMount();
        return componentWillUnmount;
    }, []);

    useEffect(() => {
        updateOrderDetails(userId, orderId);
    }, [userId, orderId]);
    //===========================================================================

    function componentDidMount() {
        window.addEventListener('resize', onResize);
        onResize();
    }

    function componentWillUnmount() {
        window.removeEventListener('resize', onResize);
    }

    function onResize() {
        const deviceWidth = window.innerWidth;
        const deviceHeight = window.innerHeight;

        const uiMainArea = document.getElementById('mainArea');
        if (uiMainArea) {
            const headerHeight = 80;
            const footerHeight = 150;
            const minHeight = 500;
            var mainAreaHeight = deviceHeight - headerHeight - footerHeight;
            mainAreaHeight = mainAreaHeight > minHeight ? mainAreaHeight : minHeight;
            uiMainArea.style.height = `${mainAreaHeight}px`;
        }
    }

    const formatDate = (dateTime, includeHM) => {
        const time = new Date(dateTime);
        const year = time.getFullYear();
        const month = time.getMonth() + 1 < 10 ? `0${time.getMonth() + 1}` : time.getMonth() + 1;
        const date = time.getDate() < 10 ? `0${time.getDate()}` : time.getDate();
        const hours = time.getHours() < 10 ? `0${time.getHours()}` : time.getHours();
        const minutes = time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes();
        if (includeHM) return `${year}/${month}/${date} ${hours}:${minutes}`;
        else return `${year}/${month}/${date}`;
    }

    const updateOrderDetails = (userId, orderId) => {
        const userOrderInfos = ParamsStorer.Instance().getData("userOrderInfos");
        console.log(`userId:${userId}, orderId:${orderId}`);
        if (userOrderInfos) {
            var orderData = null;
            for (var i = 0; i < userOrderInfos.length; i++) {
                if (userOrderInfos[i].orderId == orderId) {
                    orderData = userOrderInfos[i];
                    break;
                }
            }
            if (orderData && orderData.userId === userId) {
                const uiCourseName = document.getElementById('courseName');
                if (uiCourseName) uiCourseName.innerHTML = `${orderData.course}全科`;
                const uiPrice = document.getElementById('price');
                if (uiPrice) uiPrice.innerHTML = `NT $${orderData.price}`;
                const uiCreateDate = document.getElementById('createDate');
                if (uiCreateDate) uiCreateDate.innerHTML = formatDate(orderData.createDate, true);
                if (orderData.payFlowInfo) {
                    const uiExpiredAt = document.getElementById('expiredAt');
                    if (uiExpiredAt) uiExpiredAt.innerHTML = formatDate(orderData.payFlowInfo.expiredAt, false);
                    const uiPayDate = document.getElementById('payDate');
                    if (uiPayDate) uiPayDate.innerHTML = formatDate(orderData.payFlowInfo.payDate, true);
                    const uiPayType = document.getElementById('payType');
                    if (uiPayType) uiPayType.innerHTML = orderData.payFlowInfo.payType ? orderData.payFlowInfo.payType : "信用卡";
                }
                if (orderData.options) {
                    const uiInvoce = document.getElementById('invoce');
                    if (uiInvoce) {
                        var content = orderData.options["發票類型"];
                        if (orderData.options["統編號碼"]) content += `，統編號碼:${orderData.options["統編號碼"]}`;
                        if (orderData.options["發票抬頭"]) content += `，發票抬頭:${orderData.options["發票抬頭"]}`;
                        uiInvoce.innerHTML = content;
                    }
                }
            }
        }
        else {
            router.history.push('MainPage');
        }
    }

    const backClick = useCallback(() => {
        router.history.goBack();
    }, [router]);

    const moreCourse = useCallback(() => {
        router.history.push('MainPage');
    });

    return (
        <Beforeunload>
            <div className={styles.myOrderDetails}>
                <Header />
                <div id="mainArea" className={styles.mainArea}>
                    <div className={styles.back} onClick={() => backClick()}>
                        <img src="./assets/img/chevron_left_outline.svg" /><span>返回上一頁</span>
                    </div>
                    <div className={styles.infos}>
                        <div>
                            <div className={styles.title}><b>交易明細</b></div>
                            <br></br>
                        </div>
                        <table>
                            <thead className={styles.header}>
                                <th style={{ width: "20%" }}></th>
                                <th style={{ width: "80%" }}></th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={styles.dataKey}>課程名稱</td>
                                    <td id='courseName' className={styles.dataValue}></td>
                                </tr>
                                <tr>
                                    <td className={styles.dataKey}>金額</td>
                                    <td id='price' className={styles.dataValue}></td>
                                </tr>
                                <tr>
                                    <td className={styles.dataKey}>使用期限</td>
                                    <td id='expiredAt' className={styles.dataValue}></td>
                                </tr>
                                <tr>
                                    <td className={styles.dataKey}>訂單成立時間</td>
                                    <td id='createDate' className={styles.dataValue}></td>
                                </tr>
                                <tr>
                                    <td className={styles.dataKey}>付款時間</td>
                                    <td id='payDate' className={styles.dataValue}></td>
                                </tr>
                                <tr>
                                    <td className={styles.dataKey}>付款方式</td>
                                    <td id='payType' className={styles.dataValue}></td>
                                </tr>
                                <tr>
                                    <td className={styles.dataKey}>開立發票</td>
                                    <td id='invoce' className={styles.dataValue}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button onClick={() => moreCourse()}>看更多課程</button>
                </div>
                <Footer />
            </div>
        </Beforeunload>
    )
}

export default MyOrderDetails;