import React, { useEffect, useCallback, useState } from "react";
import styles from "./index.module.scss";
import { StoreTypes, useStore } from "context";
import classnames from "classnames";
import { API } from "api";
import TestRecord from "components/StudyNode/TestRecord";
import Loading from "components/Loading";
import DialogModule from "components/Dialogs";
import moment from "moment";

let examKeyInfo;

const dataType = {
  南一: { type: "N" },
  康軒: { type: "K" },
  翰林: { type: "H" },
  何嘉仁: { type: "E" },
};

const EXAM_MESSAGE = {
  UPDATE:"這個單元更新中，請同學稍後再來挑戰!!!",
  CREATE_TEST:"創建測驗中...",
  CREATE:"出題中",
  PLZ_LOGIN:"請進行登入，才能進行測驗並留存測驗完成紀錄！",
}

const OnlineTest = ({ setDoUnload, productCode, volume, subjectName, purchaseTime }) => {
  const checkoutProductcode = (purchaseCode, code) => {
    return purchaseCode
      ? purchaseCode.find((info) => info.productCode === code)
      : false;
  };
  const [{ productName, testList, examRecodeList, isTryOut }, globalDispatch] =
    useStore(StoreTypes.global);
  const [{ token, userId, product, isTeacher, isOneLiveMember, education }] = useStore(
    StoreTypes.user
  );

  const [treeType, setTreeType] = useState("1");
  const [examList, setExamList] = useState([]);
  const [recodeList, setRecodeList] = useState(null);
  const [goExamLoading, setGoExamLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isPurchase, setIsPurchase] = useState(
    product &&
      (moment.unix(purchaseTime).isAfter(moment()) || isOneLiveMember) &&
      product.length > 0
      ? checkoutProductcode(product, productCode)
      : false
  );
  const [message, setMessage] = useState("message");
  const [createTestLoadingMessage, setCreateTestLoadinMessage] = useState("");
  const [isDemo, setIsDemo] = useState(testList[0].demo);

  const getExamKeyInfo = async (payload) => {
    const curTime = new Date();
    var year = curTime.getFullYear();
    if (curTime.getMonth() < 8) year -= 1;
    year = year - 1911;
    const { chapter, section, education, VolumeName ,product} = payload;
    let filtersObj = {
      product,
      VolumeName,
      chapter,
      section,
      education,
      publisher: dataType[testList[0].version].type,
    };
    // if (volume.indexOf("國小") > -1 && subjectName.indexOf("自然") > -1) {
    // 	filtersObj = {
    // 		chapter,
    // 	};
    // }

    const filterData = {
      year: year.toString(),
      filters: filtersObj,
    };
    const res = await API.post(
      `${process.env.REACT_APP_API_DOMAIN}/apis/getExams`,
      filterData
    );
    return res;
  };

  useEffect(() => {
    (async () => {
      if (!token) return
      setExamList([]);
      setMessage("");
      let codeList;
      const section = testList;
      switch (process.env.REACT_APP_PROJECT) {
        case "yunlinstudy":
        case "chiayistudy":
          setIsDemo(isTeacher ? "Y" : section[0].demo);
          break;
        default:
          setIsDemo(section[0].demo);
          break;
      }
      
      if (!isPurchase && !(process.env.REACT_APP_PROJECT === 'chiayistudy' || process.env.REACT_APP_PROJECT === 'yunlinstudy')) return
      examKeyInfo = await getExamKeyInfo(section[0]);
      if (examKeyInfo.status === "success") {
        if (examKeyInfo.data.length <= 0) {
          setMessage(EXAM_MESSAGE.UPDATE);
          setExamList(null);
          return;
        }
        const {
          publisher,
          book,
          eduSubject,
          library_chapter,
          time,
          year,
          question_num,
          section,
          chapter,
        } = examKeyInfo.data[0];

        const examParameters = {
          userId,
          year,
          section,
          chapter,
          question_num,
          education: eduSubject.substr(0, 1), //"J",
          subject: eduSubject.substr(1, 2), //"GE",
          bookId: `${year}${publisher}-${eduSubject}${book}`, //110N-JGEB01
          codes: library_chapter.toString().split(";"), //["1"]  ["1-1", "1-2", "1-3"],
        };

        setCreateTestLoadinMessage(EXAM_MESSAGE.CREATE_TEST);
        const res = await API.post(
          `${process.env.REACT_APP_API_DOMAIN}/exam/getInfo`,
          examParameters
        );

        setCreateTestLoadinMessage(EXAM_MESSAGE.CREATE);
        if (res && res.status === "success") {
          if (res.data.codeArr.length > 0) {
            codeList = res.data.codeArr;
            const newCodeList = [];
            const results = [];
            codeList.forEach(async (list) => {
              list.time = time;
              list.examParameters = examParameters;
              list.question_num = question_num;
              newCodeList.push({ examInfo: goExam(list), time });
            });

            Promise.all(
              newCodeList.map((list) =>
                list.examInfo.then((result) => {
                  if (result.status === "success") {
                    const url = `${process.env.REACT_APP_ONE_EXAM_DOMAIN}/user/answer/${result.data.examId}/${userId}`;
                    results.push({ ...result.data, time: list.time, url });
                    if (results.length === newCodeList.length) {
                      setExamList(results);
                      setCreateTestLoadinMessage("");
                    }
                  }
                })
              )
            );
          } else {
            setExamList(null);
            setMessage(EXAM_MESSAGE.UPDATE);
          }
        } else {
          if (
            res.status === "failure" &&
            res.error.message.indexOf("404") > -1
          ) {
            setExamList(null);
            setCreateTestLoadinMessage("");
            setMessage(EXAM_MESSAGE.UPDATE);
          }
        }
      }
    })();
  }, [token, productCode, testList, productName, setCreateTestLoadinMessage,isPurchase]);

  useEffect(() => {
    (async () => {
      if (!token) return;
      const result = await API.get(
        `${process.env.REACT_APP_API_DOMAIN}/video/getUserVideoLog/${productCode}`,
        null,
        token
      );
      if (result.status === "success") setRecodeList(result.data.log);
    })();
  }, [token, productCode]);

  const openWin = (url) => {
    var a = document.createElement("a"); //建立a標籤
    a.setAttribute("href", url);
    a.setAttribute("target", "_blank");
    document.body.appendChild(a);
    a.click(); //執行當前物件
  };

  const goExam = useCallback(
    async (list) => {
      const { eduSubject } = examKeyInfo.data[0];
      //const itemCode = list.knowledgeList[0].itemCode.split("-")[0];
      const education = eduSubject.substr(0, 1);
      const subject = eduSubject.substr(1, 2);
      const questionIds = [];
      let questionNum = parseInt(list.question_num);
      const data = {
        userId,
        education,
        subject,
        examPeriod: parseInt(list.time),
        examName: list.name,
        productCode: productCode,
        volume: volume,
        subjectName: subjectName,
        examInfo: list.examInfo,
        examParameters: list.examParameters,
        token,
      };

      return await API.post(
        `${process.env.REACT_APP_API_DOMAIN}/exam/create`,
        data
      );
    },
    [token, userId, setDoUnload]
  );

  const createExamList = useCallback(
    (testList) => {
      const isExamHide = () => {
        if ((process.env.REACT_APP_PROJECT === 'chiayistudy' || process.env.REACT_APP_PROJECT === 'yunlinstudy')){
          return isTeacher ? false : !testList[0].volume.includes(education)
        } else {
          return !isPurchase && isDemo === "N"
        }
      }
      return testList.map((list, index) => {
        return (
          <div key={index} className={styles.list}>
            <div className={styles.icon}>
              <img src="./assets/img/WritingNotes.svg" alt="" />
            </div>
            <div className={styles.text}>
              <div className={styles.content}>
                <div className={styles.text1}>{list.examName}</div>
                <div className={styles.text2}>
                  {`預計時長：${list.time} 分鐘`}
                </div>
              </div>
            </div>
            <div
              className={classnames(styles.testBtn, {
                [styles.hide]: isExamHide(),
              })}
              onClick={() => window.open(list.url)}
            >
              <img src="./assets/img/goTestBtn.svg" alt="前往測驗" />
            </div>
          </div>
        );
      });
    },
    [isDemo, isPurchase, education]
  );

  const dialogMessage = (
    <div className={styles.dialogMessage}>
      <div>
        <div>此範圍尚未開放測驗</div>
        <div>請先選擇其他範圍測驗</div>
        <div className={styles.closeBtn} onClick={() => setOpen(false)}>
          <div>確認</div>
        </div>
      </div>
    </div>
  );

  const checkExamList = useCallback(
    (examList) => {
      if (!token) return  <div className={styles.noquestion}>{EXAM_MESSAGE.PLZ_LOGIN}</div>;
      if (!isPurchase && !(process.env.REACT_APP_PROJECT === 'chiayistudy' || process.env.REACT_APP_PROJECT === 'yunlinstudy')) return <div className={styles.noquestion}>{EXAM_MESSAGE.UPDATE}</div>;
      if (examList) {
        return examList.length ? createExamList(examList) : <Loading />;
      } else {
        return <div className={styles.noquestion}>{message}</div>;
      }
    },
    [token, isPurchase, message]
  );

  return (
    <div
      className={classnames(styles.onlineTest, {
        [styles.loading]: goExamLoading,
      })}
    >
      {goExamLoading ? (
        <Loading message="前往測驗中..." />
      ) : (
        <div>
          <div className={styles.title}>{productName}</div>
          <div className={styles.textContent}>
            <div className={styles.circle}></div>
            <div className={styles.text}>線上測驗</div>
          </div>
          <div className={styles.listContent}>
            <div className={styles.testLoadingMessage}>
              {createTestLoadingMessage}
            </div>
            {checkExamList(examList)}
          </div>
          <TestRecord
            examRecodeList={examRecodeList}
            userId={userId}
            subject={subjectName}
          />
        </div>
      )}
      <DialogModule open={open} setOpen={setOpen} content={dialogMessage} />
    </div>
  );
};

export default OnlineTest;
