import { useEffect } from 'react';
import PropTypes from 'prop-types';
import ParamsStorer from "../../util/paramsStorer";
/**
 * Beforeunload 用組件
 */
const useBeforeunload = (doUnload=true) => {

  useEffect(() => {
		// if (process.env.NODE_ENV !== 'production') return;
		const handleBeforeunload = (event) => {
			let returnValue = "";
			if (event.defaultPrevented) {
				event.returnValue = "";
			}
			if (typeof returnValue === "string") {
				event.returnValue = returnValue;
				return returnValue;
			}
		};
		doUnload
			? window.addEventListener("beforeunload", handleBeforeunload)
			: window.removeEventListener("beforeunload", handleBeforeunload);
		return () => {
			window.removeEventListener("beforeunload", handleBeforeunload);
		};
  }, [doUnload]);
};
export const Beforeunload = props => {
  const { children ,doUnload} = props;
  useBeforeunload(doUnload);
  return children;
};
Beforeunload.propTypes = {
  children: PropTypes.node
};