
export const school = [
    {
      "代碼": "014501",
      "學校名稱": "市立板橋國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區中正路437號",
      "電話": "(02)29666498#234",
      "網址": "http://www.pcjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014503",
      "學校名稱": "市立重慶國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區國慶路221號",
      "電話": "(02)29543001",
      "網址": "http://www.ccjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014504",
      "學校名稱": "市立江翠國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區松江街63號",
      "電話": "(02)22513256",
      "網址": "http://www.ctjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014505",
      "學校名稱": "市立中山國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區文化路一段188巷56號",
      "電話": "(02)22508250#201",
      "網址": "http://www.csjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014506",
      "學校名稱": "市立新埔國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區新海路181號",
      "電話": "(02)22572275",
      "網址": "http://www.hpjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014507",
      "學校名稱": "市立新莊國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[242]新北市新莊區中正路211號",
      "電話": "(02)22766326",
      "網址": "http://www.hcjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014508",
      "學校名稱": "市立新泰國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[242]新北市新莊區新泰路359號",
      "電話": "(02)29960745",
      "網址": "http://www.htjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014509",
      "學校名稱": "市立福營國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[242]新北市新莊區富國路105號",
      "電話": "(02)22045556",
      "網址": "http://www.fyjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014510",
      "學校名稱": "市立頭前國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[242]新北市新莊區中原路2號",
      "電話": "(02)85222862",
      "網址": "http://www.tqjh.ntpc.edu.tw/default.asp",
      "": ""
    },
    {
      "代碼": "014512",
      "學校名稱": "市立光榮國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區介壽路26號",
      "電話": "(02)29721430",
      "網址": "http://www.grjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014513",
      "學校名稱": "市立明志國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區中正北路107號",
      "電話": "(02)29844132",
      "網址": "http://www.mcjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014514",
      "學校名稱": "市立碧華國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區自強路五段66號",
      "電話": "(02)29851121",
      "網址": "http://www.bhjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014516",
      "學校名稱": "市立永和國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[234]新北市永和區國中路111號",
      "電話": "(02)29214209",
      "網址": "http://www.jhjhs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014517",
      "學校名稱": "市立福和國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[234]新北市永和區永利路71號",
      "電話": "(02)29261836",
      "網址": "http://www.fhjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014518",
      "學校名稱": "市立中和國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[235]新北市中和區興南路二段3巷17號",
      "電話": "(02)29422270",
      "網址": "http://www.chjhs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014519",
      "學校名稱": "市立積穗國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[235]新北市中和區民安街66號",
      "電話": "(02)22234747",
      "網址": "http://www.gsjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014520",
      "學校名稱": "市立漳和國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[235]新北市中和區廣福路39號",
      "電話": "(02)22488616",
      "網址": "http://www.chjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014521",
      "學校名稱": "市立鶯歌國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[239]新北市鶯歌區尖山埔路108號",
      "電話": "(02)26701461",
      "網址": "http://www.ykjhs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014523",
      "學校名稱": "市立柑園國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[238]新北市樹林區柑園街二段125號",
      "電話": "(02)26801958",
      "網址": "http://www.gyjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014524",
      "學校名稱": "市立土城國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[236]新北市土城區永寧路18號",
      "電話": "(02)22675135#102",
      "網址": "http://www.tcjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014525",
      "學校名稱": "市立三峽國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[237]新北市三峽區復興路238號",
      "電話": "(02)26711043",
      "網址": "http://www.shjhs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014527",
      "學校名稱": "市立八里國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[249]新北市八里區荖阡坑路15號",
      "電話": "(02)26102016",
      "網址": "http://www.pljhs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014528",
      "學校名稱": "市立泰山國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[243]新北市泰山區泰林路二段498號",
      "電話": "(02)2296-2519",
      "網址": "http://www.ttcjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014529",
      "學校名稱": "市立五股國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[248]新北市五股區成泰路二段49巷15號",
      "電話": "(02)22915224",
      "網址": "http://www.wkjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014530",
      "學校名稱": "市立蘆洲國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[247]新北市蘆洲區中正路265號",
      "電話": "(02)22811571",
      "網址": "http://www.lcjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014531",
      "學校名稱": "市立林口國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[244]新北市林口區民治路25號",
      "電話": "(02)26011014",
      "網址": "http://www.lkjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014533",
      "學校名稱": "市立汐止國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[221]新北市汐止區禮門里大同路二段394號",
      "電話": "(02)26412065",
      "網址": "http://www.hcjhs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014534",
      "學校名稱": "市立淡水國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[251]新北市淡水區文化里真理街10號",
      "電話": "(02)26218821",
      "網址": "http://www.tsjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014536",
      "學校名稱": "市立三芝國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[252]新北市三芝區淡金路一段38號",
      "電話": "(02)26362004",
      "網址": "http://www.szjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014537",
      "學校名稱": "市立石門國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[253]新北市石門區中央路1之9號",
      "電話": "(02)26381273",
      "網址": "http://www.smjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014539",
      "學校名稱": "市立萬里國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[207]新北市萬里區獅頭路24號",
      "電話": "(02)24922053",
      "網址": "http://www.wljhs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014540",
      "學校名稱": "市立坪林國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[232]新北市坪林區國中路4號",
      "電話": "(02)26656210",
      "網址": "http://www.pljh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014541",
      "學校名稱": "市立文山國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區文中路38號",
      "電話": "(02)29111672",
      "網址": "http://www.wsjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014542",
      "學校名稱": "市立五峰國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區中興路一段253號",
      "電話": "(02)29112005",
      "網址": "http://www.wfjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014544",
      "學校名稱": "市立瑞芳國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[224]新北市瑞芳區三爪子坑路1號",
      "電話": "(02)24972145",
      "網址": "http://www.rfjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014545",
      "學校名稱": "市立欽賢國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[224]新北市瑞芳區崙頂路3之1號",
      "電話": "(02)24972261",
      "網址": "http://www.cejh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014546",
      "學校名稱": "市立貢寮國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[228]新北市貢寮區嵩陽街42號",
      "電話": "(02)24941941",
      "網址": "http://www.kljh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014549",
      "學校名稱": "市立深坑國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[222]新北市深坑區文化街41號",
      "電話": "(02)26621455",
      "網址": "http://www.skjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014550",
      "學校名稱": "市立平溪國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[226]新北市平溪區石底街92號",
      "電話": "(02)24951010",
      "網址": "http://www.phjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014551",
      "學校名稱": "市立烏來國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[233]新北市烏來區啦卡路5號",
      "電話": "(02)26616482",
      "網址": "http://www.wups.ntpc.edu.tw/default_page.asp",
      "": ""
    },
    {
      "代碼": "014552",
      "學校名稱": "市立溪崑國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區大觀路三段50巷30號",
      "電話": "(02)26869727",
      "網址": "http://www.ckjhs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014554",
      "學校名稱": "市立自強國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[235]新北市中和區莒光路191號",
      "電話": "(02)22259469",
      "網址": "http://www.zcjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014555",
      "學校名稱": "市立中正國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[236]新北市土城區金城路二段247號",
      "電話": "(02)22628456",
      "網址": "http://www.ccjhs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014558",
      "學校名稱": "市立義學國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[243]新北市泰山區民生路33號",
      "電話": "(02)22961168",
      "網址": "http://www.yhjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014559",
      "學校名稱": "市立中平國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[242]新北市新莊區中平路385號",
      "電話": "(02)29908092",
      "網址": "http://www.cpjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014560",
      "學校名稱": "市立鳳鳴國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[239]新北市鶯歌區永和街33號",
      "電話": "(02)26780609",
      "網址": "http://www.fmjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014561",
      "學校名稱": "市立三和國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區三和路四段216號",
      "電話": "(02)22879890",
      "網址": "http://www.shjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014565",
      "學校名稱": "市立尖山國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[239]新北市鶯歌區國中街1號",
      "電話": "(02)26781670",
      "網址": "http://www.jsjhs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014566",
      "學校名稱": "市立正德國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[251]新北市淡水區北新路109號",
      "電話": "(02)26205136",
      "網址": "http://www.jdjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014567",
      "學校名稱": "市立安溪國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[237]新北市三峽區大同路135號",
      "電話": "(02)26717851",
      "網址": "http://www.asjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014569",
      "學校名稱": "市立育林國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[238]新北市樹林區復興路395號",
      "電話": "(02)26841160",
      "網址": "http://www.yljh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014570",
      "學校名稱": "市立青山國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[221]新北市汐止區莊敬街33號",
      "電話": "(02)26917877",
      "網址": "http://www.csjhs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014571",
      "學校名稱": "市立崇林國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[244]新北市林口區文化一路一段20號",
      "電話": "(02)26095829",
      "網址": "http://www.ttcjhs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014572",
      "學校名稱": "市立二重國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區忠孝路三段89號",
      "電話": "(02)29800164",
      "網址": "http://www.ecjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014573",
      "學校名稱": "市立大觀國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區僑中一街1號",
      "電話": "(02)22725015",
      "網址": "http://www.tkjhs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014574",
      "學校名稱": "市立三多國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[238]新北市樹林區三多路101號",
      "電話": "(02)86881501",
      "網址": "http://www.sdjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014575",
      "學校名稱": "市立忠孝國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區成都街30號",
      "電話": "(02)29631350",
      "網址": "http://www.chjs.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014576",
      "學校名稱": "市立鷺江國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[247]新北市蘆洲區長樂路235號",
      "電話": "(02)82862517",
      "網址": "http://www.ljjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014577",
      "學校名稱": "市立桃子腳國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[238]新北市樹林區學勤路555號",
      "電話": "(02)89703225",
      "網址": "http://www.tykjh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "014579",
      "學校名稱": "市立佳林國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[244]新北市林口區中山路268號",
      "電話": "(02)26028555",
      "網址": "http://www.jljh.ntpc.edu.tw/default.asp",
      "": ""
    },
    {
      "代碼": "014580",
      "學校名稱": "市立達觀國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區僑信路1號",
      "電話": "(02)22159013",
      "網址": "http://www.dgejh.ntpc.edu.tw",
      "": ""
    },
    {
      "代碼": "313501",
      "學校名稱": "市立介壽國中",
      "公/私立": "公立",
      "縣市名稱": "[31]臺北市",
      "地址": "[105]臺北市松山區介壽里延壽街401號",
      "電話": "(02)27674496",
      "網址": "http://www.csjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "313502",
      "學校名稱": "市立民生國中",
      "公/私立": "公立",
      "縣市名稱": "[31]臺北市",
      "地址": "[105]臺北市松山區新東里新東街30巷1號",
      "電話": "(02)27653433",
      "網址": "http://www.msjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "313504",
      "學校名稱": "市立中山國中",
      "公/私立": "公立",
      "縣市名稱": "[31]臺北市",
      "地址": "[105]臺北市松山區復興北路361巷7號",
      "電話": "(02)27126701",
      "網址": "http://www.csjhs.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "313505",
      "學校名稱": "市立敦化國中",
      "公/私立": "公立",
      "縣市名稱": "[31]臺北市",
      "地址": "[105]臺北市松山區南京東路三段300號",
      "電話": "(02)87717890",
      "網址": "http://www.thjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "323502",
      "學校名稱": "市立興雅國中",
      "公/私立": "公立",
      "縣市名稱": "[32]臺北市",
      "地址": "[110]臺北市信義區松德路168巷15號",
      "電話": "(02)27232771",
      "網址": "http://www.syajh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "323503",
      "學校名稱": "市立永吉國中",
      "公/私立": "公立",
      "縣市名稱": "[32]臺北市",
      "地址": "[110]臺北市信義區四育里8鄰松隆路161號",
      "電話": "(02)27649066",
      "網址": "http://www.yjjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "323504",
      "學校名稱": "市立瑠公國中",
      "公/私立": "公立",
      "縣市名稱": "[32]臺北市",
      "地址": "[110]臺北市信義區福德街221巷15號",
      "電話": "(02)27261481",
      "網址": "http://www.lkjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "323505",
      "學校名稱": "市立信義國中",
      "公/私立": "公立",
      "縣市名稱": "[32]臺北市",
      "地址": "[110]臺北市信義區松仁路158巷1號",
      "電話": "(02)27236771",
      "網址": "http://www.syijh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "331502",
      "學校名稱": "私立立人國中(小)",
      "公/私立": "私立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區義榮里安和路二段99號",
      "電話": "(02)27381188",
      "網址": "http://www.ljjhps.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "333501",
      "學校名稱": "市立仁愛國中",
      "公/私立": "公立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區敦安里仁愛路4段130號",
      "電話": "(02)23255823",
      "網址": "http://www.jajh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "333502",
      "學校名稱": "市立大安國中",
      "公/私立": "公立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區大安路2段63號",
      "電話": "(02)27557131",
      "網址": "http://www.tajh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "333504",
      "學校名稱": "市立芳和實驗國中",
      "公/私立": "公立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區芳和里臥龍街170號",
      "電話": "(02)27321961",
      "網址": "http://www.fhjhs.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "333505",
      "學校名稱": "市立金華國中",
      "公/私立": "公立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區福住里新生南路二段32號",
      "電話": "(02)33931799",
      "網址": "http://www.chwjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "333506",
      "學校名稱": "市立懷生國中",
      "公/私立": "公立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區忠孝東路三段248巷30號",
      "電話": "(02)27215078",
      "網址": "http://www.hsjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "333507",
      "學校名稱": "市立民族實驗國中",
      "公/私立": "公立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區羅斯福路四段113巷13號",
      "電話": "(02)27322935",
      "網址": "http://www.mtjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "333508",
      "學校名稱": "市立龍門國中",
      "公/私立": "公立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區建國南路二段269號",
      "電話": "(02)27330299",
      "網址": "http://www.lmjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "343502",
      "學校名稱": "市立長安國中",
      "公/私立": "公立",
      "縣市名稱": "[34]臺北市",
      "地址": "[104]臺北市中山區興亞里松江路70巷11號",
      "電話": "(02)25112382",
      "網址": "http://www.cajh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "343504",
      "學校名稱": "市立北安國中",
      "公/私立": "公立",
      "縣市名稱": "[34]臺北市",
      "地址": "[104]臺北市中山區劍潭里明水路325號",
      "電話": "(02)25333888",
      "網址": "http://www.pajh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "343505",
      "學校名稱": "市立新興國中",
      "公/私立": "公立",
      "縣市名稱": "[34]臺北市",
      "地址": "[104]臺北市中山區聚葉里林森北路511號",
      "電話": "(02)25714211",
      "網址": "http://www.hhjh.tp.edu.tw/",
      "": ""
    },
    {
      "代碼": "343506",
      "學校名稱": "市立五常國中",
      "公/私立": "公立",
      "縣市名稱": "[34]臺北市",
      "地址": "[104]臺北市中山區下埤里復興北路430巷1號",
      "電話": "(02)25014320",
      "網址": "http://www.wcjhs.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "343507",
      "學校名稱": "市立濱江國中",
      "公/私立": "公立",
      "縣市名稱": "[34]臺北市",
      "地址": "[104]臺北市中山區樂群二路262號",
      "電話": "(02)85020126",
      "網址": "http://www.bjjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "353501",
      "學校名稱": "市立螢橋國中",
      "公/私立": "公立",
      "縣市名稱": "[35]臺北市",
      "地址": "[100]臺北市中正區林興里汀州路三段4號",
      "電話": "(02)23688667",
      "網址": "http://www.ycjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "353502",
      "學校名稱": "市立古亭國中",
      "公/私立": "公立",
      "縣市名稱": "[35]臺北市",
      "地址": "[100]臺北市中正區中華路二段465號",
      "電話": "(02)23090986",
      "網址": "http://www.ktjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "353503",
      "學校名稱": "市立南門國中",
      "公/私立": "公立",
      "縣市名稱": "[35]臺北市",
      "地址": "[100]臺北市中正區南門里廣州街6號",
      "電話": "(02)23142775",
      "網址": "http://www.nmjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "353504",
      "學校名稱": "市立弘道國中",
      "公/私立": "公立",
      "縣市名稱": "[35]臺北市",
      "地址": "[100]臺北市中正區公園路21號",
      "電話": "(02)23715520",
      "網址": "http://www.htjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "353505",
      "學校名稱": "市立中正國中",
      "公/私立": "公立",
      "縣市名稱": "[35]臺北市",
      "地址": "[100]臺北市中正區新營里愛國東路158號",
      "電話": "(02)23916697",
      "網址": "http://www.ccjhs.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "363501",
      "學校名稱": "市立建成國中",
      "公/私立": "公立",
      "縣市名稱": "[36]臺北市",
      "地址": "[103]臺北市大同區長安西路37之1號",
      "電話": "(02)25587042",
      "網址": "http://www.jcjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "363502",
      "學校名稱": "市立忠孝國中",
      "公/私立": "公立",
      "縣市名稱": "[36]臺北市",
      "地址": "[103]臺北市大同區玉泉里西寧北路32號",
      "電話": "(02)25524890",
      "網址": "http://www.chjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "363504",
      "學校名稱": "市立民權國中",
      "公/私立": "公立",
      "縣市名稱": "[36]臺北市",
      "地址": "[103]臺北市大同區重慶北路三段1號",
      "電話": "(02)25931951",
      "網址": "http://www.mqjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "363506",
      "學校名稱": "市立蘭州國中",
      "公/私立": "公立",
      "縣市名稱": "[36]臺北市",
      "地址": "[103]臺北市大同區至聖里大龍街187巷1號",
      "電話": "(02)25918269",
      "網址": "http://www.lcjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "363507",
      "學校名稱": "市立重慶國中",
      "公/私立": "公立",
      "縣市名稱": "[36]臺北市",
      "地址": "[103]臺北市大同區重慶里敦煌路19號",
      "電話": "(02)25948631",
      "網址": "http://www.cchs.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "373501",
      "學校名稱": "市立萬華國中",
      "公/私立": "公立",
      "縣市名稱": "[37]臺北市",
      "地址": "[108]臺北市萬華區日善里西藏路201號",
      "電話": "(02)23394567",
      "網址": "http://www.whjhs.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "373503",
      "學校名稱": "市立雙園國中",
      "公/私立": "公立",
      "縣市名稱": "[37]臺北市",
      "地址": "[108]臺北市萬華區和德里興義街2號",
      "電話": "(02)23030827",
      "網址": "http://www.syjhs.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "373505",
      "學校名稱": "市立龍山國中",
      "公/私立": "公立",
      "縣市名稱": "[37]臺北市",
      "地址": "[108]臺北市萬華區富裕里南寧路46號",
      "電話": "(02)23362789",
      "網址": "http://www.lsjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "383501",
      "學校名稱": "市立木柵國中",
      "公/私立": "公立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區木柵路三段102巷12號",
      "電話": "(02)29393031",
      "網址": "http://www.mcjhs.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "383502",
      "學校名稱": "市立實踐國中",
      "公/私立": "公立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區樟林里辛亥路七段67號",
      "電話": "(02)22362852",
      "網址": "http://www.sjjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "383503",
      "學校名稱": "市立北政國中",
      "公/私立": "公立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區指南里指南路三段2巷14號",
      "電話": "(02)29393651",
      "網址": "http://www.pcjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "383504",
      "學校名稱": "市立景美國中",
      "公/私立": "公立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區景華里景中街27號",
      "電話": "(02)89353130",
      "網址": "http://www.cmjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "383505",
      "學校名稱": "市立興福國中",
      "公/私立": "公立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區興旺里福興路80號",
      "電話": "(02)29322024",
      "網址": "http://www.hfjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "383507",
      "學校名稱": "市立景興國中",
      "公/私立": "公立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區景華里景興路46巷2號",
      "電話": "(02)29323794",
      "網址": "http://www.chhs.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "393501",
      "學校名稱": "市立誠正國中",
      "公/私立": "公立",
      "縣市名稱": "[39]臺北市",
      "地址": "[115]臺北市南港區新富里富康街1巷24號",
      "電話": "(02)27828094",
      "網址": "http://163.21.132.5",
      "": ""
    },
    {
      "代碼": "393503",
      "學校名稱": "市立成德國中",
      "公/私立": "公立",
      "縣市名稱": "[39]臺北市",
      "地址": "[115]臺北市南港區成福里東新街108巷23號",
      "電話": "(02)26515635",
      "網址": "http://www.cdjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "403501",
      "學校名稱": "市立內湖國中",
      "公/私立": "公立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區紫陽里陽光街1號",
      "電話": "(02)27900843",
      "網址": "http://www.nhjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "403502",
      "學校名稱": "市立麗山國中",
      "公/私立": "公立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區內湖路一段629巷42號",
      "電話": "(02)27991867",
      "網址": "http://www.lsjhs.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "403503",
      "學校名稱": "市立三民國中",
      "公/私立": "公立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區湖興里民權東路6段45號",
      "電話": "(02)27924772",
      "網址": "http://www.smjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "403504",
      "學校名稱": "市立西湖國中",
      "公/私立": "公立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區西湖里環山路一段27號",
      "電話": "(02)27991817",
      "網址": "http://www.xhjhs.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "403505",
      "學校名稱": "市立東湖國中",
      "公/私立": "公立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區樂康里康樂街131號",
      "電話": "(02)26330373",
      "網址": "http://www.dhjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "403506",
      "學校名稱": "市立明湖國中",
      "公/私立": "公立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區葫洲里康寧路三段60號",
      "電話": "(02)26320616",
      "網址": "http://www.mhjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "413501",
      "學校名稱": "市立士林國中",
      "公/私立": "公立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區福德里中正路345號",
      "電話": "(02)88613411",
      "網址": "http://www.sljh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "413502",
      "學校名稱": "市立蘭雅國中",
      "公/私立": "公立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區三玉里忠誠路二段51號",
      "電話": "(02)28329377",
      "網址": "http://163.21.26.20/enable01/",
      "": ""
    },
    {
      "代碼": "413504",
      "學校名稱": "市立至善國中",
      "公/私立": "公立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區臨溪里至善路二段360號",
      "電話": "(02)28411350",
      "網址": "http://www.jsjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "413505",
      "學校名稱": "市立格致國中",
      "公/私立": "公立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區陽明里仰德大道四段75號",
      "電話": "(02)28610079",
      "網址": "http://www.gjjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "413506",
      "學校名稱": "市立福安國中",
      "公/私立": "公立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區福安里延平北路七段250號",
      "電話": "(02)28108766",
      "網址": "http://www.fajh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "413508",
      "學校名稱": "市立天母國中",
      "公/私立": "公立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區天和里天母東路120號",
      "電話": "(02)28754864",
      "網址": "http://www.tmjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "423501",
      "學校名稱": "市立北投國中",
      "公/私立": "公立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區溫泉里溫泉路62號",
      "電話": "(02)28912091",
      "網址": "http://www.ptjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "423502",
      "學校名稱": "市立新民國中",
      "公/私立": "公立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區林泉里新民路10號",
      "電話": "(02)28979001",
      "網址": "http://www.hmjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "423503",
      "學校名稱": "市立明德國中",
      "公/私立": "公立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區建民里明德路50號",
      "電話": "(02)28232539",
      "網址": "http://www.mdjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "423504",
      "學校名稱": "市立桃源國中",
      "公/私立": "公立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區一德里中央北路四段48號",
      "電話": "(02)28929633",
      "網址": "http://www.tyjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "423505",
      "學校名稱": "市立石牌國中",
      "公/私立": "公立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區吉利里石牌路一段139號",
      "電話": "(02)28224682",
      "網址": "http://www.spjh.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "423506",
      "學校名稱": "市立關渡國中",
      "公/私立": "公立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區關渡里知行路212號",
      "電話": "(02)28581770",
      "網址": "http://www.ktjhs.tp.edu.tw",
      "": ""
    },
    {
      "代碼": "031502",
      "學校名稱": "私立有得國中(小)",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區長春一路288號",
      "電話": "(03)4522789",
      "網址": "https://www.yoderedu.org/",
      "": ""
    },
    {
      "代碼": "031503",
      "學校名稱": "私立康萊爾國中(小)",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區力行路288巷8號",
      "電話": "(03)3026889",
      "網址": "http://www.cornell.tyc.edu.tw/",
      "": ""
    },
    {
      "代碼": "034501",
      "學校名稱": "市立桃園國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區莒光街2號",
      "電話": "(03)3358282",
      "網址": "http://www.tyjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034502",
      "學校名稱": "市立青溪國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區中山東路124號",
      "電話": "(03)3392400",
      "網址": "http://www.chjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034503",
      "學校名稱": "市立文昌國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區民生路729號",
      "電話": "(03)3552776",
      "網址": "http://www.wcjhs.tyc.edu.tw/",
      "": ""
    },
    {
      "代碼": "034504",
      "學校名稱": "市立建國國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區介新街20號",
      "電話": "(03)3630081",
      "網址": "http://www.ckjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034505",
      "學校名稱": "市立中興國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區文中路122號",
      "電話": "(03)3601392",
      "網址": "http://www.chjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034506",
      "學校名稱": "市立南崁國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[338]桃園市蘆竹區五福六路1號",
      "電話": "(03)3525590",
      "網址": "http://www.nkjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034507",
      "學校名稱": "市立山腳國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[338]桃園市蘆竹區海山路319號",
      "電話": "(03)3241995",
      "網址": "http://www.sjjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034508",
      "學校名稱": "市立大竹國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[338]桃園市蘆竹區大竹里四鄰國中巷35號",
      "電話": "(03)3232764",
      "網址": "http://www.dcjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034509",
      "學校名稱": "市立大園國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[337]桃園市大園區園科路400號",
      "電話": "(03)3862029",
      "網址": "http://163.30.71.1",
      "": ""
    },
    {
      "代碼": "034510",
      "學校名稱": "市立竹圍國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[337]桃園市大園區海港路2巷87號",
      "電話": "(03)3835026",
      "網址": "http://www.cwjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034511",
      "學校名稱": "市立大溪國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[335]桃園市大溪區民權東路210號",
      "電話": "(03)3882024",
      "網址": "http://www.dsjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034513",
      "學校名稱": "市立仁和國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[335]桃園市大溪區仁和里仁和七街55號",
      "電話": "(03)3906626",
      "網址": "http://www.jhjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034515",
      "學校名稱": "市立大崗國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[333]桃園市龜山區文化里文化二路168號",
      "電話": "(03)3280888",
      "網址": "http://www.dgjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034516",
      "學校名稱": "市立八德國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[334]桃園市八德區興豐路321號",
      "電話": "(03)3685322",
      "網址": "http://www.ptjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034517",
      "學校名稱": "市立大成國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[334]桃園市八德區大勇里忠勇街12號",
      "電話": "(03)3625633",
      "網址": "http://www.tcjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034518",
      "學校名稱": "市立中壢國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[324]桃園市平鎮區延平路一段115號",
      "電話": "(03)4223214",
      "網址": "http://www.cljhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034520",
      "學校名稱": "市立平南國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[324]桃園市平鎮區中豐路南勢二段340號",
      "電話": "(03)4392164",
      "網址": "http://www.pnjh.tyc.edu.tw/",
      "": ""
    },
    {
      "代碼": "034521",
      "學校名稱": "市立新明國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區中正路487巷18號",
      "電話": "(03)4936194",
      "網址": "http://www.hmjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034522",
      "學校名稱": "市立內壢國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區復華里復華一街108號",
      "電話": "(03)4522494",
      "網址": "http://www.nljh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034523",
      "學校名稱": "市立大崙國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區月眉路一段50號",
      "電話": "(03)4982840",
      "網址": "http://www.dljh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034524",
      "學校名稱": "市立龍岡國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區龍東路147號",
      "電話": "(03)4562137",
      "網址": "http://www.lkjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034525",
      "學校名稱": "市立興南國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區育英路55號",
      "電話": "(03)4629991",
      "網址": "http://www.hnjh.tyc.edu.tw/",
      "": ""
    },
    {
      "代碼": "034526",
      "學校名稱": "市立自強國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區榮民路80號",
      "電話": "(03)4553494",
      "網址": "http://www.tcjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034527",
      "學校名稱": "市立東興國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區廣州路25號",
      "電話": "(03)34583500",
      "網址": "http://www.dsjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034528",
      "學校名稱": "市立楊梅國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[326]桃園市楊梅區校前路149號",
      "電話": "(03)4782024",
      "網址": "http://www.ymjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034529",
      "學校名稱": "市立仁美國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[326]桃園市楊梅區高榮里梅獅路539巷1號",
      "電話": "(03)4641123",
      "網址": "http://www.zmjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034530",
      "學校名稱": "市立富岡國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[326]桃園市楊梅區豐野里中正路456號",
      "電話": "(03)4721113",
      "網址": "http://www.fkjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034531",
      "學校名稱": "市立瑞原國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[326]桃園市楊梅區民豐路69號",
      "電話": "(03)4782242#11",
      "網址": "http://www.ryjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034533",
      "學校名稱": "市立觀音國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[328]桃園市觀音區白玉里下庄子53之1號",
      "電話": "(03)4732034",
      "網址": "http://www.gijh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034534",
      "學校名稱": "市立草漯國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[328]桃園市觀音區草漯里28鄰四維路73號",
      "電話": "(03)4830146#71",
      "網址": "http://www.ttjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034536",
      "學校名稱": "市立大坡國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[327]桃園市新屋區民有二路二段100號",
      "電話": "(03)4768350",
      "網址": "http://www.dpjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034537",
      "學校名稱": "市立永安國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[327]桃園市新屋區中山西路三段96號",
      "電話": "(03)4862507",
      "網址": "http://www.yajh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034538",
      "學校名稱": "市立龍潭國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[325]桃園市龍潭區中正里龍華路460號",
      "電話": "(03)4792075",
      "網址": "http://www.ltjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034539",
      "學校名稱": "市立凌雲國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[325]桃園市龍潭區中豐路上林段418號",
      "電話": "(03)4792604",
      "網址": "http://www.lyjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034540",
      "學校名稱": "市立石門國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[325]桃園市龍潭區佳安里文化路137號",
      "電話": "(03)4713610",
      "網址": "http://www.smjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034541",
      "學校名稱": "市立介壽國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[336]桃園市復興區澤仁里中正路248號",
      "電話": "(03)3822315",
      "網址": "http://www.jsjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034542",
      "學校名稱": "市立慈文國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區中正路835號",
      "電話": "(03)3269340",
      "網址": "http://www.twjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034543",
      "學校名稱": "市立平興國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[324]桃園市平鎮區環南路300號",
      "電話": "(03)4918239",
      "網址": "http://www.psjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034544",
      "學校名稱": "市立楊明國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[326]桃園市楊梅區新農街337號",
      "電話": "(03)4781525",
      "網址": "http://www.ymjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034545",
      "學校名稱": "市立龍興國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區龍勇路100號",
      "電話": "(03)4575200",
      "網址": "http://www.lsjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034546",
      "學校名稱": "市立福豐國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區延平路326號",
      "電話": "(03)3669547",
      "網址": "http://www.ffjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034549",
      "學校名稱": "市立東安國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[324]桃園市平鎮區平東路168號",
      "電話": "(03)4601407",
      "網址": "http://www.tajh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034550",
      "學校名稱": "市立光明國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[338]桃園市蘆竹區光明路一段123號",
      "電話": "(03)3114355",
      "網址": "http://www.gmjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034551",
      "學校名稱": "市立同德國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區南平路487號",
      "電話": "(03)2628955",
      "網址": "http://www.tdjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034552",
      "學校名稱": "市立幸福國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[333]桃園市龜山區中興路100巷20號",
      "電話": "(03)3298992",
      "網址": "http://www.hfjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034554",
      "學校名稱": "市立大有國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區大有路215號",
      "電話": "(03)2613298",
      "網址": "http://www.dyjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034555",
      "學校名稱": "市立龜山國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[333]桃園市龜山區自強西路66號",
      "電話": "(03)3205681",
      "網址": "http://www.gsjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034556",
      "學校名稱": "市立會稽國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區大興路222號",
      "電話": "(03)3551496",
      "網址": "http://www.kjjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034557",
      "學校名稱": "市立楊光國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[326]桃園市楊梅區瑞溪路1一段88號",
      "電話": "(03)4827987",
      "網址": "http://www.ygjps.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034559",
      "學校名稱": "市立迴龍國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[333]桃園市龜山區萬壽路一段168號",
      "電話": "(02)82096088",
      "網址": "http://www.hles.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034560",
      "學校名稱": "市立平鎮國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[324]桃園市平鎮區新富里振興路2號",
      "電話": "(03)4572150",
      "網址": "http://163.30.122.141/homepjjh/modules/tadnews/#A",
      "": ""
    },
    {
      "代碼": "034561",
      "學校名稱": "市立武漢國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[325]桃園市龍潭區武中路227號",
      "電話": "(03)4092810",
      "網址": "http://www.whjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034562",
      "學校名稱": "市立經國國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區經國路276號",
      "電話": "(03)3572699",
      "網址": "http://www.jgjhs.tyc.edu.tw/web/",
      "": ""
    },
    {
      "代碼": "034563",
      "學校名稱": "市立過嶺國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區松智路2號",
      "電話": "(03)4200026",
      "網址": "http://www.gljh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034564",
      "學校名稱": "市立瑞坪國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[326]桃園市楊梅區瑞坪里14鄰文化街535號",
      "電話": "(03)4821468",
      "網址": "http://www.rpjhs.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "034565",
      "學校名稱": "市立青埔國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區領航北路二段281號",
      "電話": "(03)2871886",
      "網址": "http://www.qpjh.tyc.edu.tw",
      "": ""
    },
    {
      "代碼": "063501",
      "學校名稱": "市立善水國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[435]臺中市梧棲區梧南路50號",
      "電話": "(04)26396160",
      "網址": "https://sites.google.com/tc.edu.tw/ssjhs/%E9%A6%96%E9%A0%81",
      "": ""
    },
    {
      "代碼": "064501",
      "學校名稱": "市立豐原國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[420]臺中市豐原區三豐路一段321號",
      "電話": "(04)25251200",
      "網址": "http://www.fyjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064502",
      "學校名稱": "市立豐東國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[420]臺中市豐原區豐東路75號",
      "電話": "(04)25221743",
      "網址": "http://www.ftjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064503",
      "學校名稱": "市立豐南國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[420]臺中市豐原區豐南街151號",
      "電話": "(04)25280185",
      "網址": "http://www.fnjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064504",
      "學校名稱": "市立潭子國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[427]臺中市潭子區潭興路二段419巷1號",
      "電話": "(04)25376192",
      "網址": "http://www.ttjhs.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064505",
      "學校名稱": "市立大雅國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[428]臺中市大雅區學府路280號",
      "電話": "(04)25672171",
      "網址": "http://www.tyjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064506",
      "學校名稱": "市立神岡國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[429]臺中市神岡區社南里中山路627號",
      "電話": "(04)25623421",
      "網址": "http://www.skjhs.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064507",
      "學校名稱": "市立后里國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[421]臺中市后里區眉山里第三鄰中眉路169號",
      "電話": "(04)25562409",
      "網址": "http://www.hljh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064509",
      "學校名稱": "市立外埔國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[438]臺中市外埔區大同里外埔路999號",
      "電話": "(04)26833721",
      "網址": "http://www.wpjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064510",
      "學校名稱": "市立大甲國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[437]臺中市大甲區育英路186號",
      "電話": "(04)26872564",
      "網址": "http://www.tjjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064511",
      "學校名稱": "市立日南國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[437]臺中市大甲區中山路二段69之11號",
      "電話": "(04)26813721",
      "網址": "http://www.jnjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064512",
      "學校名稱": "市立大安國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[439]臺中市大安區松雅里大安港路691號",
      "電話": "(04)26872571",
      "網址": "http://www.tajh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064513",
      "學校名稱": "市立清水國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[436]臺中市清水區鰲峰路250號",
      "電話": "(04)26262042",
      "網址": "http://www.csjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064514",
      "學校名稱": "市立清泉國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[436]臺中市清水區菁埔里臨海路27之2號",
      "電話": "(04)26113134",
      "網址": "http://www.ccjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064515",
      "學校名稱": "市立沙鹿國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[433]臺中市沙鹿區中正街1號",
      "電話": "(04)26622163",
      "網址": "http://www.sljh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064516",
      "學校名稱": "市立梧棲國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[435]臺中市梧棲區文化里民生街12號",
      "電話": "(04)26562850",
      "網址": "http://www.wcjs.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064517",
      "學校名稱": "市立龍井國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[434]臺中市龍井區觀光路9號",
      "電話": "(04)26353344",
      "網址": "http://www.lcjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064518",
      "學校名稱": "市立四箴國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[434]臺中市龍井區新庄里中沙路2號",
      "電話": "(04)26314606",
      "網址": "http://www.scjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064519",
      "學校名稱": "市立大道國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[432]臺中市大肚區仁德路60號",
      "電話": "(04)26992028",
      "網址": "http://www.ddjhs.tc.edu.tw/indexnew.htm",
      "": ""
    },
    {
      "代碼": "064520",
      "學校名稱": "市立烏日國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[414]臺中市烏日區新興路457號",
      "電話": "(04)23381009",
      "網址": "http://www.wjjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064521",
      "學校名稱": "市立溪南國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[414]臺中市烏日區溪南路一段731號",
      "電話": "(04)23353959",
      "網址": "http://www.snjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064522",
      "學校名稱": "市立霧峰國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[413]臺中市霧峰區國中路110號",
      "電話": "(04)23393175",
      "網址": "http://www.wfjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064523",
      "學校名稱": "市立光復國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[413]臺中市霧峰區南柳里柳豐路535號",
      "電話": "(04)23393141",
      "網址": "http://www.kfjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064525",
      "學校名稱": "市立太平國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[411]臺中市太平區中山路二段116號",
      "電話": "(04)23922540",
      "網址": "http://www.tpjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064526",
      "學校名稱": "市立中平國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[411]臺中市太平區中平里太平路300號",
      "電話": "(04)22767416",
      "網址": "http://www.cpjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064527",
      "學校名稱": "市立石岡國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[422]臺中市石岡區梅子里豐勢路國中巷1號",
      "電話": "(04)25814191",
      "網址": "http://www.sgjhs.tc.edu.tw/xoops/index.php",
      "": ""
    },
    {
      "代碼": "064529",
      "學校名稱": "市立東勢國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[423]臺中市東勢區東崎路五段415號",
      "電話": "(04)25873843",
      "網址": "http://www.tsjs.tc.edu.tw/",
      "": ""
    },
    {
      "代碼": "064530",
      "學校名稱": "市立東華國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[423]臺中市東勢區東關路五段500號",
      "電話": "(04)25853219",
      "網址": "http://www.thjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064531",
      "學校名稱": "市立東新國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[423]臺中市東勢區東新里5鄰東環街123號",
      "電話": "(04)25872684",
      "網址": "http://www.tsihs.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064532",
      "學校名稱": "市立成功國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區至善路157號",
      "電話": "(04)24927226",
      "網址": "http://www.ckjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064533",
      "學校名稱": "市立和平國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[424]臺中市和平區東關路三段崑崙巷62號",
      "電話": "(04)25941512",
      "網址": "http://www.hpjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064534",
      "學校名稱": "市立北勢國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[433]臺中市沙鹿區英才路150號",
      "電話": "(04)26322236",
      "網址": "http://www.bsjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064535",
      "學校名稱": "市立鹿寮國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[433]臺中市沙鹿區光華路385號",
      "電話": "(04)26622207",
      "網址": "http://www.lljh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064537",
      "學校名稱": "市立光榮國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區東榮路280號",
      "電話": "(04)24831428",
      "網址": "http://www.krjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064538",
      "學校名稱": "市立潭秀國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[427]臺中市潭子區雅潭路一段175號",
      "電話": "(04)25343542",
      "網址": "http://www.tsjhs.tc.edu.tw/",
      "": ""
    },
    {
      "代碼": "064539",
      "學校名稱": "市立順天國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[437]臺中市大甲區大安港路67號",
      "電話": "(04)26864263",
      "網址": "http://www.stjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064540",
      "學校名稱": "市立清海國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[436]臺中市清水區中央路51之60號",
      "電話": "(04)26271902",
      "網址": "http://www.chjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064541",
      "學校名稱": "市立大華國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[428]臺中市大雅區雅環路一段2號",
      "電話": "(04)25688251",
      "網址": "http://www.dhjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064543",
      "學校名稱": "市立新光國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[411]臺中市太平區樹德九街 139 號",
      "電話": "(04)23957597",
      "網址": "http://www.skjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064544",
      "學校名稱": "市立光正國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區鳳凰路68號",
      "電話": "(04)24911599",
      "網址": "http://www.gjjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064545",
      "學校名稱": "市立豐陽國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[420]臺中市豐原區朝陽路33號",
      "電話": "(04)25203470",
      "網址": "https://sites.google.com/a/fyjhs.tc.edu.tw/fengyang/",
      "": ""
    },
    {
      "代碼": "064546",
      "學校名稱": "市立光德國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[414]臺中市烏日區信義街99號",
      "電話": "(04)23389802",
      "網址": "http://www.gdjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064547",
      "學校名稱": "市立立新國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區甲堤路236號",
      "電話": "(04)22732299",
      "網址": "http://www.lsjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064548",
      "學校名稱": "市立爽文國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區永隆三街1號",
      "電話": "(04)24067545",
      "網址": "http://www.swjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064549",
      "學校名稱": "市立公明國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[433]臺中市沙鹿區公明里中清路六段567號",
      "電話": "(04)26154262",
      "網址": "http://www.gmjh.tc.edu.tw/",
      "": ""
    },
    {
      "代碼": "064551",
      "學校名稱": "市立神圳國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[429]臺中市神岡區神清路61號",
      "電話": "(04)25624669",
      "網址": "http://www.szjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "064552",
      "學校名稱": "市立梨山國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[424]臺中市和平區梨山里福壽路10號",
      "電話": "(04)25981510",
      "網址": "http://www.lses.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "191503",
      "學校名稱": "私立麗喆國中(小)",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[407]臺中市西屯區國安二路242巷199號",
      "電話": "(04)24613099",
      "網址": "http://www.litze.tc.edu.tw/",
      "": ""
    },
    {
      "代碼": "193501",
      "學校名稱": "市立居仁國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[403]臺中市西區自由路一段97號",
      "電話": "(04)22223620",
      "網址": "http://www.cjjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193502",
      "學校名稱": "市立雙十國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[404]臺中市北區力行路258號",
      "電話": "(04)22334445",
      "網址": "http://www.ssjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193504",
      "學校名稱": "市立崇倫國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[402]臺中市南區忠明南路653號",
      "電話": "(04)23712324",
      "網址": "http://www.cljh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193505",
      "學校名稱": "市立大德國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[406]臺中市北屯區雷中街2之23號",
      "電話": "(04)22916984",
      "網址": "http://www.ttjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193506",
      "學校名稱": "市立北新國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[406]臺中市北屯區柳楊東街180號",
      "電話": "(04)22449374",
      "網址": "http://www.phjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193507",
      "學校名稱": "市立東峰國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[401]臺中市東區仁和路330號",
      "電話": "(04)22825133",
      "網址": "http://www.tfjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193508",
      "學校名稱": "市立黎明國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[408]臺中市南屯區三義里一鄰干城街88號",
      "電話": "(04)22510983",
      "網址": "http://www.lmjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193509",
      "學校名稱": "市立光明國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[403]臺中市西區自由路一段75號",
      "電話": "(04)23756287",
      "網址": "http://www.kmjh.tc.edu.tw/",
      "": ""
    },
    {
      "代碼": "193510",
      "學校名稱": "市立向上國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[403]臺中市西區美村路一段389號",
      "電話": "(04)23726085",
      "網址": "http://www.hsjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193511",
      "學校名稱": "市立育英國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[401]臺中市東區育英路30號",
      "電話": "(04)22115313",
      "網址": "http://www.yejh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193512",
      "學校名稱": "市立四育國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[402]臺中市南區復興路二段152號",
      "電話": "(04)22633229",
      "網址": "http://www.syjhs.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193514",
      "學校名稱": "市立五權國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[404]臺中市北區英才路1號",
      "電話": "(04)22012180",
      "網址": "http://www.wcjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193516",
      "學校名稱": "市立中山國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[407]臺中市西屯區寧夏路179號",
      "電話": "(04)23132003",
      "網址": "http://www.csjhs.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193517",
      "學校名稱": "市立崇德國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[406]臺中市北屯區崇德路二段227號",
      "電話": "(04)22454081",
      "網址": "http://www.ctjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193518",
      "學校名稱": "市立立人國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[404]臺中市北區北平路一段62號",
      "電話": "(04)22963999",
      "網址": "http://www.ljjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193519",
      "學校名稱": "市立漢口國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[407]臺中市西屯區漢口路一段54之1號",
      "電話": "(04)23130511",
      "網址": "http://www.hkjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193520",
      "學校名稱": "市立安和國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[407]臺中市西屯區天助街1號",
      "電話": "(04)23589779",
      "網址": "http://www.ahjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193521",
      "學校名稱": "市立至善國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[407]臺中市西屯區青海路二段308號",
      "電話": "(04)27057587",
      "網址": "http://www.csjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193522",
      "學校名稱": "市立萬和國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[408]臺中市南屯區永春東路885號",
      "電話": "(04)23817264",
      "網址": "http://www.whjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193523",
      "學校名稱": "市立大業國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[408]臺中市南屯區大業路100號",
      "電話": "(04)23279458",
      "網址": "http://www.dyjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193524",
      "學校名稱": "市立三光國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[406]臺中市北屯區三光一街77號",
      "電話": "(04)22313699",
      "網址": "http://www.sgjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193525",
      "學校名稱": "市立四張犁國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[406]臺中市北屯區同榮里后庄路699號",
      "電話": "(04)24210380",
      "網址": "http://www.scljh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193526",
      "學校名稱": "市立福科國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[407]臺中市西屯區福林路333號",
      "電話": "(04)24618112",
      "網址": "http://www.fcjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "193527",
      "學校名稱": "市立大墩國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[408]臺中市南屯區惠中路三段98號",
      "電話": "(04)23826178#712",
      "網址": "http://www.ddjh.tc.edu.tw",
      "": ""
    },
    {
      "代碼": "111502",
      "學校名稱": "私立昭明國中(代用)",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[724]臺南市七股區大埕里315號",
      "電話": "(06)7872053",
      "網址": "http://jmjh.tn.edu.tw/",
      "": ""
    },
    {
      "代碼": "114501",
      "學校名稱": "市立仁德國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[717]臺南市仁德區民安路一段363號",
      "電話": "(06)2682724",
      "網址": "http://s2.rdjh.tn.edu.tw/xoops/index.php",
      "": ""
    },
    {
      "代碼": "114502",
      "學校名稱": "市立仁德文賢國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[717]臺南市仁德區保安里中正路一段185號",
      "電話": "(06)2662392",
      "網址": "https://www.wsjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114503",
      "學校名稱": "市立歸仁國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[711]臺南市歸仁區文化里文化街二段2號",
      "電話": "(06)2301873",
      "網址": "http://www.grjh.tn.edu.tw/index.php",
      "": ""
    },
    {
      "代碼": "114504",
      "學校名稱": "市立關廟國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[718]臺南市關廟區中山路二段172號",
      "電話": "(06)5951181",
      "網址": "http://gmjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114505",
      "學校名稱": "市立永康國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[710]臺南市永康區中山路43號",
      "電話": "(06)2015247",
      "網址": "http://www.ykjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114508",
      "學校名稱": "市立龍崎國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[719]臺南市龍崎區新市子258號",
      "電話": "(06)5941475",
      "網址": "http://www.lcjh.tn.edu.tw/",
      "": ""
    },
    {
      "代碼": "114509",
      "學校名稱": "市立新化國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[712]臺南市新化區中興路722號",
      "電話": "(06)5902269",
      "網址": "http://xoops2.shjhs.tn.edu.tw/",
      "": ""
    },
    {
      "代碼": "114510",
      "學校名稱": "市立善化國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[741]臺南市善化區溪美里656號",
      "電話": "(06)5817043",
      "網址": "http://www.shjh.tn.edu.tw/",
      "": ""
    },
    {
      "代碼": "114511",
      "學校名稱": "市立玉井國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[714]臺南市玉井區大成路152號",
      "電話": "(06)5742214",
      "網址": "http://www.yjjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114512",
      "學校名稱": "市立山上國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[743]臺南市山上區山上里130號",
      "電話": "(06)7021578",
      "網址": "http://www.ssjh.tn.edu.tw/",
      "": ""
    },
    {
      "代碼": "114513",
      "學校名稱": "市立安定國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[745]臺南市安定區安加里262之1號",
      "電話": "(06)5922003",
      "網址": "http://www.adjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114514",
      "學校名稱": "市立楠西國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[715]臺南市楠西區中興路107號",
      "電話": "(06)5751712",
      "網址": "http://www.nsjhs.tn.edu.tw/",
      "": ""
    },
    {
      "代碼": "114515",
      "學校名稱": "市立新市國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[744]臺南市新市區民族路76號",
      "電話": "(06)5991420",
      "網址": "http://www.ssjhs.tn.edu.tw/",
      "": ""
    },
    {
      "代碼": "114516",
      "學校名稱": "市立南化國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[716]臺南市南化區南化里227號",
      "電話": "(06)5771105",
      "網址": "http://www.nhjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114517",
      "學校名稱": "市立左鎮國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[713]臺南市左鎮區中正里170號",
      "電話": "(06)5731015",
      "網址": "http://www.tjjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114518",
      "學校名稱": "市立麻豆國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[721]臺南市麻豆區南勢里36號",
      "電話": "(06)5722128",
      "網址": "http://www.mdjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114519",
      "學校名稱": "市立下營國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[735]臺南市下營區中山路一段412號",
      "電話": "(06)6891105",
      "網址": "http://163.26.159.6/wordpress/",
      "": ""
    },
    {
      "代碼": "114520",
      "學校名稱": "市立六甲國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[734]臺南市六甲區民權街43號",
      "電話": "(06)6982040",
      "網址": "http://www.ljjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114521",
      "學校名稱": "市立官田國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[720]臺南市官田區隆田里三民路29號",
      "電話": "(06)5791371",
      "網址": "http://www.gtjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114522",
      "學校名稱": "市立大內國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[742]臺南市大內區內江里319之1號",
      "電話": "(06)5761010",
      "網址": "http://www.dnjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114523",
      "學校名稱": "市立佳里國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[722]臺南市佳里區安南路523號",
      "電話": "(06)7222244",
      "網址": "http://web.jljh.tn.edu.tw/wwwxoops2/",
      "": ""
    },
    {
      "代碼": "114524",
      "學校名稱": "市立佳興國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[722]臺南市佳里區民安里新宅34之1號",
      "電話": "(06)7260291",
      "網址": "http://www.jsjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114525",
      "學校名稱": "市立學甲國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[726]臺南市學甲區華宗路540號",
      "電話": "(06)7832128",
      "網址": "http://www.sjjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114526",
      "學校名稱": "市立西港國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[723]臺南市西港區文化路41號",
      "電話": "(06)7952071　 ",
      "網址": "http://www.sgjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114527",
      "學校名稱": "市立將軍國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[725]臺南市將軍區忠嘉里昌平1之1號",
      "電話": "(06)7942042",
      "網址": "http://www.jjjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114528",
      "學校名稱": "市立後港國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[724]臺南市七股區大潭里頂潭93號",
      "電話": "(06)7941357",
      "網址": "http://www.hgjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114529",
      "學校名稱": "市立竹橋國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[724]臺南市七股區義合里74之2號",
      "電話": "(06)7891733",
      "網址": "http://www.jcjhs.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114530",
      "學校名稱": "市立北門國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[727]臺南市北門區永華里1之1號",
      "電話": "(06)7862014",
      "網址": "http://www.bmjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114531",
      "學校名稱": "市立南新國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[730]臺南市新營區民治路65號",
      "電話": "(06)6563129",
      "網址": "http://www.nsjh.tn.edu.tw/",
      "": ""
    },
    {
      "代碼": "114532",
      "學校名稱": "市立太子國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[730]臺南市新營區太北里140之21號",
      "電話": "(06)6524762",
      "網址": "http://www.ttjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114533",
      "學校名稱": "市立新東國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[730]臺南市新營區民治東路30號",
      "電話": "(06)6322954",
      "網址": "http://www.sdjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114534",
      "學校名稱": "市立鹽水國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[737]臺南市鹽水區三福路63號",
      "電話": "(06)6521146",
      "網址": "http://www.ysjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114535",
      "學校名稱": "市立白河國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[732]臺南市白河區昇安里三間厝220號",
      "電話": "(06)6852067",
      "網址": "http://www.bhjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114536",
      "學校名稱": "市立柳營國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[736]臺南市柳營區東昇里中山東路二段956號",
      "電話": "(06)6223209",
      "網址": "http://www.lyjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114537",
      "學校名稱": "市立東山國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[733]臺南市東山區東中里青葉路一段132號",
      "電話": "(06)6802175",
      "網址": "http://www.dsjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114538",
      "學校名稱": "市立東原國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[733]臺南市東山區東原里瓦厝70號",
      "電話": "(06)6861009",
      "網址": "http://www.dyjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114539",
      "學校名稱": "市立後壁國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[731]臺南市後壁區嘉苳里124之20號",
      "電話": "(06)6871974",
      "網址": "http://www.hbjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114540",
      "學校名稱": "市立菁寮國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[731]臺南市後壁區長短樹里8鄰長短樹8之3號",
      "電話": "(06)6621974",
      "網址": "http://www.jljhs.tn.edu.tw/",
      "": ""
    },
    {
      "代碼": "114543",
      "學校名稱": "市立大橋國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[710]臺南市永康區東橋十街1號",
      "電話": "(06)3021793",
      "網址": "http://www.dcjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "114544",
      "學校名稱": "市立沙崙國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[711]臺南市歸仁區武東里歸仁六路2號",
      "電話": "(06)3032062",
      "網址": "http://sljh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213501",
      "學校名稱": "市立後甲國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區林森路二段260號",
      "電話": "(06)2388118",
      "網址": "http://www.hcjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213502",
      "學校名稱": "市立忠孝國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區崇善路151號",
      "電話": "(06)2670495",
      "網址": "http://www.chjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213504",
      "學校名稱": "市立大成國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[702]臺南市南區西門路一段306號",
      "電話": "(06)2630011",
      "網址": "http://www.tcjhs.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213505",
      "學校名稱": "市立金城國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[708]臺南市安平區怡平路8號",
      "電話": "(06)2975816",
      "網址": "http://www.jcjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213506",
      "學校名稱": "市立民德國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[704]臺南市北區西門路三段223號",
      "電話": "(06)2223014",
      "網址": "http://www.mtjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213507",
      "學校名稱": "市立成功國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[704]臺南市北區和緯路一段2號",
      "電話": "(06)2517906",
      "網址": "http://www.ckjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213508",
      "學校名稱": "市立延平國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[704]臺南市北區公園路750號",
      "電話": "(06)2514720",
      "網址": "http://www.ypjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213509",
      "學校名稱": "市立建興國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[700]臺南市中西區府前路一段239號",
      "電話": "(06)2139601",
      "網址": "http://www.csjh.tn.edu.tw/",
      "": ""
    },
    {
      "代碼": "213510",
      "學校名稱": "市立中山國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[700]臺南市中西區南寧街45號",
      "電話": "(06)2134792",
      "網址": "http://www.csjhs.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213511",
      "學校名稱": "市立安平國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[708]臺南市安平區慶平路687號",
      "電話": "(06)2990461",
      "網址": "http://www.apjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213512",
      "學校名稱": "市立安南國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[709]臺南市安南區安中路三段252號",
      "電話": "(06)2567384",
      "網址": "http://www.anjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213513",
      "學校名稱": "市立安順國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[709]臺南市安南區安和路三段227號",
      "電話": "(06)3559652",
      "網址": "http://www.asjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213514",
      "學校名稱": "市立復興國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區裕文路62號",
      "電話": "(06)3310688",
      "網址": "http://www.fhjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213515",
      "學校名稱": "市立新興國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[702]臺南市南區新孝路87號",
      "電話": "(06)2633171",
      "網址": "http://www.hhjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213517",
      "學校名稱": "市立文賢國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[704]臺南市北區文賢一路2號",
      "電話": "(06)2587571",
      "網址": "http://www.whjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213518",
      "學校名稱": "市立崇明國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區崇明路700號",
      "電話": "(06)2907261",
      "網址": "http://www.cmjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213519",
      "學校名稱": "市立和順國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[709]臺南市安南區安和路五段84巷66號",
      "電話": "(06)3551440",
      "網址": "http://www.hsjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "213520",
      "學校名稱": "市立海佃國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[709]臺南市安南區安富街166號",
      "電話": "(06)3507486",
      "網址": "http://www.hdjh.tn.edu.tw",
      "": ""
    },
    {
      "代碼": "121502",
      "學校名稱": "私立光禾華德福實驗學校(國中)",
      "公/私立": "私立",
      "縣市名稱": "[12]高雄市",
      "地址": "[831]高雄市大寮區至學路288號",
      "電話": "(07)7885128",
      "網址": "http://waldorf-tw-kh.weebly.com/",
      "": ""
    },
    {
      "代碼": "123501",
      "學校名稱": "市立巴楠花部落國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[846]高雄市杉林區大愛里和氣街15號",
      "電話": "(07)6776031",
      "網址": "http://www.mzu.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124501",
      "學校名稱": "市立鳳山國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區中山東路227號",
      "電話": "(07)7462774",
      "網址": "http://www.fsm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124503",
      "學校名稱": "市立鳳西國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區興中里光華路69號",
      "電話": "(07)7463452",
      "網址": "http://www.fxm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124504",
      "學校名稱": "市立五甲國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區龍成路111號",
      "電話": "(07)8215239",
      "網址": "http://www.wzm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124505",
      "學校名稱": "市立鳳甲國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區輜汽路300號",
      "電話": "(07)7675300",
      "網址": "http://www.fjm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124506",
      "學校名稱": "市立忠孝國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區國泰路二段81號",
      "電話": "(07)7635252",
      "網址": "http://www.zxm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124507",
      "學校名稱": "市立大寮國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[831]高雄市大寮區永芳里進學路150號",
      "電話": "(07)7818038",
      "網址": "http://www.dlm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124508",
      "學校名稱": "市立潮寮國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[831]高雄市大寮區潮寮里潮寮路51之9號",
      "電話": "(07)7879544#10",
      "網址": "http://www.tlm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124509",
      "學校名稱": "市立大樹國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[840]高雄市大樹區九曲里九曲路500號",
      "電話": "(07)6512026",
      "網址": "http://www.dsm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124510",
      "學校名稱": "市立溪埔國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[840]高雄市大樹區溪埔路1號",
      "電話": "(07)6561335",
      "網址": "http://www.xpm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124512",
      "學校名稱": "市立鳥松國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[833]高雄市鳥松區松埔北巷5之1號",
      "電話": "(07)7323977",
      "網址": "http://www.nsm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124514",
      "學校名稱": "市立大社國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[815]高雄市大社區神農里翠屏路100號",
      "電話": "(07)3530100",
      "網址": "http://www.dam.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124515",
      "學校名稱": "市立岡山國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[820]高雄市岡山區岡燕路238號",
      "電話": "(07)6212219",
      "網址": "http://www.gsm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124516",
      "學校名稱": "市立前峰國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[820]高雄市岡山區樹人路1號",
      "電話": "(07)6266171",
      "網址": "http://www.qfm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124517",
      "學校名稱": "市立永安國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[828]高雄市永安區保興一路3號",
      "電話": "(07)6912064",
      "網址": "http://www.yam.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124518",
      "學校名稱": "市立橋頭國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[825]高雄市橋頭區新興路70號",
      "電話": "(07)6113559",
      "網址": "http://www.qtm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124519",
      "學校名稱": "市立梓官國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[826]高雄市梓官區中學路71號",
      "電話": "(07)6172854",
      "網址": "http://www.zgm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124520",
      "學校名稱": "市立燕巢國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[824]高雄市燕巢區中興路267號",
      "電話": "(07)6161267",
      "網址": "http://www.ycm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124521",
      "學校名稱": "市立阿蓮國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[822]高雄市阿蓮區民生路178號",
      "電話": "(07)6312045",
      "網址": "http://www.alm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124523",
      "學校名稱": "市立湖內國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[829]高雄市湖內區公舘里中山路二段63號",
      "電話": "(07)6993008",
      "網址": "http://www.hn.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124524",
      "學校名稱": "市立茄萣國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[852]高雄市茄萣區白雲里濱海路四段29號",
      "電話": "(07)6900054",
      "網址": "http://www.qdm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124525",
      "學校名稱": "市立田寮國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[823]高雄市田寮區南安里崗安路48號",
      "電話": "(07)6361687",
      "網址": "http://www.tia.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124526",
      "學校名稱": "市立彌陀國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[827]高雄市彌陀區海尾里進學路136號",
      "電話": "(07)6178424",
      "網址": "http://www.mtm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124527",
      "學校名稱": "市立旗山國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[842]高雄市旗山區大德里中學路42號",
      "電話": "(07)6612103",
      "網址": "http://www.csj.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124528",
      "學校名稱": "市立圓富國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[842]高雄市旗山區圓富里旗甲路2段609號",
      "電話": "(07)6691004",
      "網址": "http://www.yfm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124529",
      "學校名稱": "市立大洲國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[842]高雄市旗山區新光里新南巷5號",
      "電話": "(07)6661378",
      "網址": "http://www.dz.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124530",
      "學校名稱": "市立美濃國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[843]高雄市美濃區合和里中正路一段191號",
      "電話": "(07)6816166",
      "網址": "http://www.mnm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124531",
      "學校名稱": "市立南隆國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[843]高雄市美濃區南中街3號",
      "電話": "(07)6831154",
      "網址": "http://www.nl.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124532",
      "學校名稱": "市立龍肚國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[843]高雄市美濃區龍山里中華路68號",
      "電話": "(07)6851314",
      "網址": "http://www.ldm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124534",
      "學校名稱": "市立寶來國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[844]高雄市六龜區寶來里中正路137號",
      "電話": "(07)6881258",
      "網址": "http://www.blm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124535",
      "學校名稱": "市立杉林國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[846]高雄市杉林區月眉里清水路6號",
      "電話": "(07)6771108",
      "網址": "http://www.slm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124536",
      "學校名稱": "市立內門國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[845]高雄市內門區中正路160巷30號",
      "電話": "(07)6671002",
      "網址": "http://www.nmm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124537",
      "學校名稱": "市立甲仙國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[847]高雄市甲仙區東安里文化南路6號",
      "電話": "(07)6751018",
      "網址": "http://www.jxm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124538",
      "學校名稱": "市立中芸國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[832]高雄市林園區中芸里中芸路3之100號",
      "電話": "(07)6425223",
      "網址": "http://www.zym.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124539",
      "學校名稱": "市立中庄國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[831]高雄市大寮區中庄里光明路三段1437號",
      "電話": "(07)7033166",
      "網址": "http://www.zzm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124541",
      "學校名稱": "市立蚵寮國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[826]高雄市梓官區智蚵里中正二街60號",
      "電話": "(07)6175389",
      "網址": "http://www.klm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124542",
      "學校名稱": "市立那瑪夏國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[849]高雄市那瑪夏區瑪雅里平和巷171號",
      "電話": "(07)6701813",
      "網址": "http://www.sm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124543",
      "學校名稱": "市立青年國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區青年路二段89號",
      "電話": "(07)7190419",
      "網址": "http://www.qnm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124544",
      "學校名稱": "市立一甲國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[821]高雄市路竹區一甲路111號",
      "電話": "(07)6977479",
      "網址": "http://www.yjm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124545",
      "學校名稱": "市立大灣國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[814]高雄市仁武區大灣里仁雄路99號",
      "電話": "(07)3711715",
      "網址": "http://www.dwm.ks.edu.tw/school/web/index.php",
      "": ""
    },
    {
      "代碼": "124546",
      "學校名稱": "市立嘉興國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[820]高雄市岡山區嘉興里信中街486號",
      "電話": "(07)6221039",
      "網址": "http://www.jsm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124547",
      "學校名稱": "市立茂林國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[851]高雄市茂林區茂林里4之5號",
      "電話": "(07)6801153",
      "網址": "http://www.ml.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124548",
      "學校名稱": "市立桃源國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[848]高雄市桃源區勤和里南橫公路三段201號",
      "電話": "(07)6861133",
      "網址": "http://www.ty.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124549",
      "學校名稱": "市立中崙國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區中崙二路487號",
      "電話": "(07)7538686",
      "網址": "http://www.zlm.ks.edu.tw",
      "": ""
    },
    {
      "代碼": "124550",
      "學校名稱": "市立鳳翔國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區保義街100號",
      "電話": "(07)7929337",
      "網址": "http://www.flyjh.kh.edu.tw/",
      "": ""
    },
    {
      "代碼": "513501",
      "學校名稱": "市立鹽埕國中",
      "公/私立": "公立",
      "縣市名稱": "[51]高雄市",
      "地址": "[803]高雄市鹽埕區新樂街46號",
      "電話": "(07)5211283",
      "網址": "http://www.yacjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "523502",
      "學校名稱": "市立壽山國中",
      "公/私立": "公立",
      "縣市名稱": "[52]高雄市",
      "地址": "[804]高雄市鼓山區鼓山二路37巷108號",
      "電話": "(07)5519150",
      "網址": "http://www.ssjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "523503",
      "學校名稱": "市立明華國中",
      "公/私立": "公立",
      "縣市名稱": "[52]高雄市",
      "地址": "[804]高雄市鼓山區明誠三路582號",
      "電話": "(07)5502002",
      "網址": "http://www.mhjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "523504",
      "學校名稱": "市立七賢國中",
      "公/私立": "公立",
      "縣市名稱": "[52]高雄市",
      "地址": "[804]高雄市鼓山區美術東三路110號",
      "電話": "(07)2810946",
      "網址": "http://www.chihjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "533501",
      "學校名稱": "市立左營國中",
      "公/私立": "公立",
      "縣市名稱": "[53]高雄市",
      "地址": "[813]高雄市左營區曾子路281號",
      "電話": "(07)3433080",
      "網址": "http://www.tyjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "533502",
      "學校名稱": "市立大義國中",
      "公/私立": "公立",
      "縣市名稱": "[53]高雄市",
      "地址": "[813]高雄市左營區翠華路687號",
      "電話": "(07)5820191",
      "網址": "http://www.dyjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "533503",
      "學校名稱": "市立立德國中",
      "公/私立": "公立",
      "縣市名稱": "[53]高雄市",
      "地址": "[813]高雄市左營區實踐路101號",
      "電話": "(07)5811985",
      "網址": "http://www.ltjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "533504",
      "學校名稱": "市立龍華國中",
      "公/私立": "公立",
      "縣市名稱": "[53]高雄市",
      "地址": "[813]高雄市左營區自由二路2號",
      "電話": "(07)5570720",
      "網址": "http://www.lhjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "533505",
      "學校名稱": "市立福山國中",
      "公/私立": "公立",
      "縣市名稱": "[53]高雄市",
      "地址": "[813]高雄市左營區重信路215號",
      "電話": "(07)3501581",
      "網址": "http://www.fsjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "533506",
      "學校名稱": "市立文府國中",
      "公/私立": "公立",
      "縣市名稱": "[53]高雄市",
      "地址": "[813]高雄市左營區福山里文府路401號",
      "電話": "(07)3495569",
      "網址": "http://www.zywfjh.kh.edu.tw/",
      "": ""
    },
    {
      "代碼": "543501",
      "學校名稱": "市立楠梓國中",
      "公/私立": "公立",
      "縣市名稱": "[54]高雄市",
      "地址": "[811]高雄市楠梓區楠梓路336號",
      "電話": "(07)3517191",
      "網址": "http://www.ntjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "543502",
      "學校名稱": "市立右昌國中",
      "公/私立": "公立",
      "縣市名稱": "[54]高雄市",
      "地址": "[811]高雄市楠梓區興昌里盛昌街161號",
      "電話": "(07)3640451",
      "網址": "http://www.yocjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "543503",
      "學校名稱": "市立後勁國中",
      "公/私立": "公立",
      "縣市名稱": "[54]高雄市",
      "地址": "[811]高雄市楠梓區加昌路180號",
      "電話": "(07)3654111",
      "網址": "http://www.hpjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "543504",
      "學校名稱": "市立國昌國中",
      "公/私立": "公立",
      "縣市名稱": "[54]高雄市",
      "地址": "[811]高雄市楠梓區德民路1010號",
      "電話": "(07)3644300",
      "網址": "http://www.kcjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "543505",
      "學校名稱": "市立翠屏國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[54]高雄市",
      "地址": "[811]高雄市楠梓區翠屏路135號",
      "電話": "(07)3683018",
      "網址": "http://www.tpjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "553501",
      "學校名稱": "市立鼎金國中",
      "公/私立": "公立",
      "縣市名稱": "[55]高雄市",
      "地址": "[807]高雄市三民區鼎山街445號",
      "電話": "(07)3831029",
      "網址": "http://www.djjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "553502",
      "學校名稱": "市立三民國中",
      "公/私立": "公立",
      "縣市名稱": "[55]高雄市",
      "地址": "[807]高雄市三民區十全一路200號",
      "電話": "(07)3227751",
      "網址": "http://www.smjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "553503",
      "學校名稱": "市立民族國中",
      "公/私立": "公立",
      "縣市名稱": "[55]高雄市",
      "地址": "[807]高雄市三民區覺民路363號",
      "電話": "(07)3818718",
      "網址": "http://www.mtjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "553504",
      "學校名稱": "市立陽明國中",
      "公/私立": "公立",
      "縣市名稱": "[55]高雄市",
      "地址": "[807]高雄市三民區義華路166號",
      "電話": "(07)3892919",
      "網址": "http://www.ymjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "553505",
      "學校名稱": "市立正興國中",
      "公/私立": "公立",
      "縣市名稱": "[55]高雄市",
      "地址": "[807]高雄市三民區覺民路850號",
      "電話": "(07)3809591",
      "網址": "http://www.chehjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "573501",
      "學校名稱": "市立前金國中",
      "公/私立": "公立",
      "縣市名稱": "[57]高雄市",
      "地址": "[801]高雄市前金區六合二路278號",
      "電話": "(07)2725981",
      "網址": "http://www.ccjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "583501",
      "學校名稱": "市立苓雅國中",
      "公/私立": "公立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區四維三路176號",
      "電話": "(07)3353307",
      "網址": "http://www.lyjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "583502",
      "學校名稱": "市立五福國中",
      "公/私立": "公立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區五福一路12號",
      "電話": "(07)2223036",
      "網址": "http://www.wfjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "583503",
      "學校名稱": "市立大仁國中",
      "公/私立": "公立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區建國一路148號",
      "電話": "(07)7114302",
      "網址": "http://www.tjjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "583505",
      "學校名稱": "市立英明國中",
      "公/私立": "公立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區英明路147號",
      "電話": "(07)7150949",
      "網址": "http://www.inmjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "593501",
      "學校名稱": "市立獅甲國中",
      "公/私立": "公立",
      "縣市名稱": "[59]高雄市",
      "地址": "[806]高雄市前鎮區中山三路43號",
      "電話": "(07)3331522",
      "網址": "http://www.scjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "593502",
      "學校名稱": "市立前鎮國中",
      "公/私立": "公立",
      "縣市名稱": "[59]高雄市",
      "地址": "[806]高雄市前鎮區新衙路17號",
      "電話": "(07)8217677",
      "網址": "http://www.qzjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "593503",
      "學校名稱": "市立瑞豐國中",
      "公/私立": "公立",
      "縣市名稱": "[59]高雄市",
      "地址": "[806]高雄市前鎮區瑞興街99號",
      "電話": "(07)7713181",
      "網址": "http://school.kh.edu.tw/index.php?WebID=65",
      "": ""
    },
    {
      "代碼": "593504",
      "學校名稱": "市立光華國中",
      "公/私立": "公立",
      "縣市名稱": "[59]高雄市",
      "地址": "[806]高雄市前鎮區和平二路170號",
      "電話": "(07)7222622",
      "網址": "http://www.khjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "593505",
      "學校名稱": "市立興仁國中",
      "公/私立": "公立",
      "縣市名稱": "[59]高雄市",
      "地址": "[806]高雄市前鎮區德昌路345號",
      "電話": "(07)8218110",
      "網址": "http://www.hjjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "603501",
      "學校名稱": "市立旗津國中",
      "公/私立": "公立",
      "縣市名稱": "[60]高雄市",
      "地址": "[805]高雄市旗津區中洲二路207號",
      "電話": "(07)5714595",
      "網址": "http://www.cjjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "613501",
      "學校名稱": "市立小港國中",
      "公/私立": "公立",
      "縣市名稱": "[61]高雄市",
      "地址": "[812]高雄市小港區平和南路185號",
      "電話": "(07)8215929",
      "網址": "http://www.hkjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "613502",
      "學校名稱": "市立鳳林國中",
      "公/私立": "公立",
      "縣市名稱": "[61]高雄市",
      "地址": "[812]高雄市小港區龍鳳路559號",
      "電話": "(07)8711130",
      "網址": "http://www.fljh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "613503",
      "學校名稱": "市立中山國中",
      "公/私立": "公立",
      "縣市名稱": "[61]高雄市",
      "地址": "[812]高雄市小港區漢民路352號",
      "電話": "(07)8021765",
      "網址": "http://www.csjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "613504",
      "學校名稱": "市立明義國中",
      "公/私立": "公立",
      "縣市名稱": "[61]高雄市",
      "地址": "[812]高雄市小港區明義街2號",
      "電話": "(07)7911295",
      "網址": "http://www.myjh.kh.edu.tw",
      "": ""
    },
    {
      "代碼": "024501",
      "學校名稱": "縣立宜蘭國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[260]宜蘭縣宜蘭市樹人路37號",
      "電話": "(03)9322519",
      "網址": "http://www.iljh.ilc.edu.tw/iljhs/iljhs.htm",
      "": ""
    },
    {
      "代碼": "024502",
      "學校名稱": "縣立中華國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[260]宜蘭縣宜蘭市女中路一段100號",
      "電話": "(03)9382704",
      "網址": "http://www.chjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024503",
      "學校名稱": "縣立復興國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[260]宜蘭縣宜蘭市復興路二段77號",
      "電話": "(03)9322942",
      "網址": "http://www.fsjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024504",
      "學校名稱": "縣立羅東國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[265]宜蘭縣羅東鎮中華路99號",
      "電話": "(03)9542075",
      "網址": "http://www.ltjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024505",
      "學校名稱": "縣立東光國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[265]宜蘭縣羅東鎮興東南路201號",
      "電話": "(03)9542603",
      "網址": "http://www.tkjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024506",
      "學校名稱": "縣立國華國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[265]宜蘭縣羅東鎮國華街101號",
      "電話": "(03)9513062",
      "網址": "http://www.ghjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024507",
      "學校名稱": "縣立頭城國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[261]宜蘭縣頭城鎮復興路145號",
      "電話": "(03)9771002",
      "網址": "http://www.tcjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024508",
      "學校名稱": "縣立蘇澳國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[270]宜蘭縣蘇澳鎮中山路二段1號",
      "電話": "(03)9962137",
      "網址": "http://www.sajh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024509",
      "學校名稱": "縣立文化國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[270]宜蘭縣蘇澳鎮永榮里新馬路2號",
      "電話": "(03)9903060",
      "網址": "http://www.whjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024510",
      "學校名稱": "縣立南安國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[270]宜蘭縣蘇澳鎮學府路117號",
      "電話": "(03)9962317",
      "網址": "http://www.najh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024511",
      "學校名稱": "縣立三星國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[266]宜蘭縣三星鄉月眉村月眉街54號",
      "電話": "(03)9892012",
      "網址": "http://www.ssjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024512",
      "學校名稱": "縣立礁溪國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[262]宜蘭縣礁溪鄉礁溪路四段23號",
      "電話": "(03)9882412",
      "網址": "http://www.csjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024513",
      "學校名稱": "縣立吳沙國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[262]宜蘭縣礁溪鄉育英路79號",
      "電話": "(03)9284701",
      "網址": "http://www.wsjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024514",
      "學校名稱": "縣立冬山國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[269]宜蘭縣冬山鄉南興村照安路26號",
      "電話": "(03)9592076",
      "網址": "http://www.dsjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024515",
      "學校名稱": "縣立順安國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[269]宜蘭縣冬山鄉順安村永興路一段359號",
      "電話": "(03)9581150",
      "網址": "http://www.sanjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024516",
      "學校名稱": "縣立五結國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[268]宜蘭縣五結鄉大吉村大吉五路58號",
      "電話": "(03)9501105",
      "網址": "http://www.wjjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024517",
      "學校名稱": "縣立興中國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[268]宜蘭縣五結鄉上四村中正東路50號",
      "電話": "(03)9653453",
      "網址": "http://www.scjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024518",
      "學校名稱": "縣立利澤國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[268]宜蘭縣五結鄉成興村利寶路99號",
      "電話": "(03)9504762",
      "網址": "http://www.ljjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024519",
      "學校名稱": "縣立員山國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[264]宜蘭縣員山鄉溫泉路92號",
      "電話": "(03)9222331",
      "網址": "http://www.ysjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024520",
      "學校名稱": "縣立內城國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[264]宜蘭縣員山鄉內城村內城路545號",
      "電話": "(03)9221846",
      "網址": "http://www.ryjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024521",
      "學校名稱": "縣立壯圍國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[263]宜蘭縣壯圍鄉富祥路588號",
      "電話": "(03)9381773",
      "網址": "http://www.cwjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024523",
      "學校名稱": "縣立大同國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[267]宜蘭縣大同鄉太平村一鄰泰雅路四段35號",
      "電話": "(03)9809611",
      "網址": "http://www.dtjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024524",
      "學校名稱": "縣立凱旋國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[260]宜蘭縣宜蘭市建業里10鄰縣政七街50巷1號",
      "電話": "(03)9255600",
      "網址": "http://www.ksjh.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "024526",
      "學校名稱": "縣立人文國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[261]宜蘭縣頭城鎮拔雅里文雅路150號",
      "電話": "(03)9773396",
      "網址": "http://www.jwps.ilc.edu.tw",
      "": ""
    },
    {
      "代碼": "041501",
      "學校名稱": "私立康乃薾國中(小)",
      "公/私立": "私立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[302]新竹縣竹北市六家一路二段115號",
      "電話": "(03)6589188",
      "網址": "http://w1.korrnell.com.tw",
      "": ""
    },
    {
      "代碼": "044501",
      "學校名稱": "縣立竹東國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[310]新竹縣竹東鎮公園路8號",
      "電話": "(03)5961207",
      "網址": "http://www.cdjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044502",
      "學校名稱": "縣立二重國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[310]新竹縣竹東鎮二重里學府路132號",
      "電話": "(03)5823083",
      "網址": "http://www.ecjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044503",
      "學校名稱": "縣立員東國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[310]新竹縣竹東鎮東峰路521號",
      "電話": "(03)5963324",
      "網址": "http://www.ydjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044504",
      "學校名稱": "縣立關西國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[306]新竹縣關西鎮北斗里北門口41號",
      "電話": "(03)5872008",
      "網址": "http://www.khjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044505",
      "學校名稱": "縣立石光國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[306]新竹縣關西鎮石光里308巷44號",
      "電話": "(03)5868019",
      "網址": "http://www.sgjh.hcc.edu.tw/bin/home.php",
      "": ""
    },
    {
      "代碼": "044506",
      "學校名稱": "縣立富光國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[306]新竹縣關西鎮東光里十六張49號",
      "電話": "(03)5872224",
      "網址": "http://www.fgjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044507",
      "學校名稱": "縣立新埔國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[305]新竹縣新埔鎮中正路792號",
      "電話": "(03)5882026",
      "網址": "http://www.hpjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044508",
      "學校名稱": "縣立照門國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[305]新竹縣新埔鎮照門里照鏡7號",
      "電話": "(03)5890214",
      "網址": "http://www.jmjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044509",
      "學校名稱": "縣立竹北國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[302]新竹縣竹北市中正西路154號",
      "電話": "(03)5552064",
      "網址": "http://www.cbjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044510",
      "學校名稱": "縣立鳳岡國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[302]新竹縣竹北市大義里鳳岡路三段100號",
      "電話": "(03)5561284",
      "網址": "http://www.fkjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044512",
      "學校名稱": "縣立芎林國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[307]新竹縣芎林鄉新鳳村74號",
      "電話": "(03)5922775",
      "網址": "http://www.cljh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044513",
      "學校名稱": "縣立新豐國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[304]新竹縣新豐鄉成德街36號",
      "電話": "(03)5596573",
      "網址": "http://www.sfjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044514",
      "學校名稱": "縣立精華國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[304]新竹縣新豐鄉後湖村八鄰166號",
      "電話": "(03)5680923",
      "網址": "http://www.ghjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044515",
      "學校名稱": "縣立橫山國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[312]新竹縣橫山鄉中豐路二段415號",
      "電話": "(03)5931665",
      "網址": "http://www.hsjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044516",
      "學校名稱": "縣立華山國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[312]新竹縣橫山鄉橫山街一段29號",
      "電話": "(03)5934084",
      "網址": "http://www.hwjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044517",
      "學校名稱": "縣立寶山國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[308]新竹縣寶山鄉三峰路二段600號",
      "電話": "(03)5202104",
      "網址": "http://www.bsjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044518",
      "學校名稱": "縣立北埔國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[314]新竹縣北埔鄉埔尾村141號",
      "電話": "(03)5802224",
      "網址": "http://www.ppjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044519",
      "學校名稱": "縣立峨眉國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[315]新竹縣峨眉鄉峨眉街十鄰54號",
      "電話": "(03)5800208",
      "網址": "http://www.omjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044521",
      "學校名稱": "縣立新湖國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[303]新竹縣湖口鄉新湖路200號",
      "電話": "(03)5992159",
      "網址": "http://www.hhjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044522",
      "學校名稱": "縣立中正國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[303]新竹縣湖口鄉仁興路135號",
      "電話": "(03)5981067",
      "網址": "http://www.ccjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044523",
      "學校名稱": "縣立五峰國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[311]新竹縣五峰鄉大隘村六鄰113號",
      "電話": "(03)5851006",
      "網址": "http://www.wfjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044524",
      "學校名稱": "縣立尖石國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[313]新竹縣尖石鄉嘉樂村三鄰八十號",
      "電話": "(03)5841027",
      "網址": "http://www.gsjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044525",
      "學校名稱": "縣立忠孝國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[304]新竹縣新豐鄉員山村孝六街1號",
      "電話": "(03)5572555",
      "網址": "http://www.chjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044526",
      "學校名稱": "縣立博愛國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[302]新竹縣竹北市縣政十三路81號",
      "電話": "(03)5530243",
      "網址": "http://www.pajh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044527",
      "學校名稱": "縣立仁愛國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[302]新竹縣竹北市三民路239號",
      "電話": "(03)5530238",
      "網址": "http://www.jajh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044528",
      "學校名稱": "縣立自強國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[310]新竹縣竹東鎮自強路169號",
      "電話": "(03)5103291",
      "網址": "http://www.jcjh.hcc.edu.tw",
      "": ""
    },
    {
      "代碼": "044529",
      "學校名稱": "縣立成功國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[302]新竹縣竹北市成功八路99號",
      "電話": "(03)5505924",
      "網址": "http://www.cgjh.hcc.edu.tw/bin/home.php",
      "": ""
    },
    {
      "代碼": "044530",
      "學校名稱": "縣立東興國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[302]新竹縣竹北市嘉豐六路一段101號",
      "電話": "(03)5506881",
      "網址": "http://www.dxjh.hcc.edu.tw/bin/home.php",
      "": ""
    },
    {
      "代碼": "044531",
      "學校名稱": "縣立北平華德福實驗學校(國中)",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[305]新竹縣新埔鎮北平里8鄰62號 　",
      "電話": "(03)5882897",
      "網址": "http://www.ppes.hcc.edu.tw/bin/home.php",
      "": ""
    },
    {
      "代碼": "054501",
      "學校名稱": "縣立苗栗國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[360]苗栗縣苗栗市高苗里木鐸山1號",
      "電話": "(037)320226",
      "網址": "http://www.mljh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054502",
      "學校名稱": "縣立大倫國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[360]苗栗縣苗栗市玉清里育英街82號",
      "電話": "(037)266311",
      "網址": "http://www.dljh.mlc.edu.tw/",
      "": ""
    },
    {
      "代碼": "054503",
      "學校名稱": "縣立明仁國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[360]苗栗縣苗栗市福麗里電台10號",
      "電話": "(037)321261",
      "網址": "http://www.mln.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054504",
      "學校名稱": "縣立頭屋國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[362]苗栗縣頭屋鄉中正街86巷14號",
      "電話": "(037)250738",
      "網址": "http://web.twjh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054505",
      "學校名稱": "縣立公館國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[363]苗栗縣公館鄉館中村大同路3號",
      "電話": "(037)222729",
      "網址": "http://www.ggjh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054506",
      "學校名稱": "縣立鶴岡國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[363]苗栗縣公館鄉鶴岡村178號",
      "電話": "(037)226204",
      "網址": "http://web.hgjh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054507",
      "學校名稱": "縣立文林國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[366]苗栗縣銅鑼鄉朝陽村文化街12號",
      "電話": "(037)981018",
      "網址": "http://www.wljh.mlc.edu.tw/",
      "": ""
    },
    {
      "代碼": "054510",
      "學校名稱": "縣立致民國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[358]苗栗縣苑裡鎮舊社里九鄰96之1號",
      "電話": "(037)744788",
      "網址": "http://www.cm.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054511",
      "學校名稱": "縣立通霄國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[357]苗栗縣通霄鎮中山路190巷1號",
      "電話": "(037)752002",
      "網址": "http://web.tsjh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054512",
      "學校名稱": "縣立南和國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[357]苗栗縣通霄鎮南和里9鄰112之1號",
      "電話": "(037)782404",
      "網址": "http://web.nhes.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054513",
      "學校名稱": "縣立烏眉國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[357]苗栗縣通霄鎮內湖里1鄰5-1號",
      "電話": "(037)752409",
      "網址": "http://web.wumeies.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054514",
      "學校名稱": "縣立啟新國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[357]苗栗縣通霄鎮白東里135之2號",
      "電話": "(037)792804",
      "網址": "http://web.csjh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054515",
      "學校名稱": "縣立西湖國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[368]苗栗縣西湖鄉龍洞村一鄰1之1號",
      "電話": "(037)921016",
      "網址": "http://www.shihujh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054516",
      "學校名稱": "縣立頭份國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[351]苗栗縣頭份市中央路275號",
      "電話": "(037)663207",
      "網址": "http://web.toufenjh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054518",
      "學校名稱": "縣立文英國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[351]苗栗縣頭份市尖下里200號",
      "電話": "(037)624260",
      "網址": "http://www.wyjh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054519",
      "學校名稱": "縣立竹南國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[350]苗栗縣竹南鎮正南里中正路92號",
      "電話": "(037)475100",
      "網址": "http://www.jnjh.mlc.edu.tw/",
      "": ""
    },
    {
      "代碼": "054520",
      "學校名稱": "縣立照南國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[350]苗栗縣竹南鎮佳興里３鄰公教路2號",
      "電話": "(037)472750",
      "網址": "http://www.jn.mlc.edu.tw/",
      "": ""
    },
    {
      "代碼": "054521",
      "學校名稱": "縣立三灣國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[352]苗栗縣三灣鄉三灣村忠信路17號",
      "電話": "(037)831002",
      "網址": "http://www.sw.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054522",
      "學校名稱": "縣立南庄國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[353]苗栗縣南庄鄉東村中正路165號",
      "電話": "(037)822027",
      "網址": "http://web.njjh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054523",
      "學校名稱": "縣立造橋國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[361]苗栗縣造橋鄉造橋村1鄰老庄27號",
      "電話": "(037)562773",
      "網址": "http://web.tcjh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054524",
      "學校名稱": "縣立大西國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[361]苗栗縣造橋鄉大中街73號",
      "電話": "(037)541080",
      "網址": "https://www.dc.mlc.edu.tw/",
      "": ""
    },
    {
      "代碼": "054525",
      "學校名稱": "縣立後龍國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[356]苗栗縣後龍鎮南龍里勝利路250號",
      "電話": "(037)722028",
      "網址": "http://www.houlungjh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054526",
      "學校名稱": "縣立維真國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[356]苗栗縣後龍鎮大庄里中山路252號",
      "電話": "(037)722537",
      "網址": "http://www.wjjh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054527",
      "學校名稱": "縣立大湖國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[364]苗栗縣大湖鄉靜湖村民族路80號",
      "電話": "(037)991022",
      "網址": "http://www.dahujh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054528",
      "學校名稱": "縣立南湖國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[364]苗栗縣大湖鄉義和村12號",
      "電話": "(037)992956",
      "網址": "http://www.nanhujh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054529",
      "學校名稱": "縣立獅潭國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[354]苗栗縣獅潭鄉新店村21號",
      "電話": "(037)931328",
      "網址": "http://web.stjh.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054531",
      "學校名稱": "縣立泰安國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[365]苗栗縣泰安鄉大興村32之1號",
      "電話": "(037)991224",
      "網址": "http://web.taian.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "054532",
      "學校名稱": "縣立建國國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[351]苗栗縣頭份市成功里建國路119號",
      "電話": "(037)691491",
      "網址": "http://www.jgjh.mlc.edu.tw/xoops/htdocs/",
      "": ""
    },
    {
      "代碼": "054534",
      "學校名稱": "縣立福興武術國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[357]苗栗縣通霄鎮福興里58之2號",
      "電話": "(037)782984",
      "網址": "http://web.fushing.mlc.edu.tw/",
      "": ""
    },
    {
      "代碼": "054535",
      "學校名稱": "縣立新港國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[356]苗栗縣後龍鎮校椅里校椅二路168號",
      "電話": "(037)722547",
      "網址": "http://www.skes.mlc.edu.tw",
      "": ""
    },
    {
      "代碼": "074501",
      "學校名稱": "縣立北斗國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[521]彰化縣北斗鎮文苑路一段136號",
      "電話": "(04)8882072",
      "網址": "http://www.ptjhs.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074502",
      "學校名稱": "縣立鹿港國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[506]彰化縣福興鄉橋頭村復興路78號",
      "電話": "(04)7772037",
      "網址": "http://www.lkjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074503",
      "學校名稱": "縣立鹿鳴國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[505]彰化縣鹿港鎮頭南里頂草路三段167號",
      "電話": "(04)7713846",
      "網址": "http://www.lmjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074504",
      "學校名稱": "縣立線西國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[507]彰化縣線西鄉寓埔村中央路二段145號",
      "電話": "(04)7584129",
      "網址": "http://www.hhjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074505",
      "學校名稱": "縣立陽明國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市長順街76號",
      "電話": "(04)7222263",
      "網址": "http://www.ymsc.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074506",
      "學校名稱": "縣立彰安國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市中正路二段530號",
      "電話": "(04)7236117",
      "網址": "http://www.cajh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074507",
      "學校名稱": "縣立彰德國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市彰南路二段512巷97號",
      "電話": "(04)7374461",
      "網址": "http://www.ctjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074509",
      "學校名稱": "縣立芬園國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[502]彰化縣芬園鄉彰南路四段27巷80號",
      "電話": "(049)2522001",
      "網址": "http://www.fyjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074510",
      "學校名稱": "縣立員林國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[510]彰化縣員林市南興里南潭路2號",
      "電話": "(04)8320873",
      "網址": "http://www.yljh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074511",
      "學校名稱": "縣立明倫國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[510]彰化縣員林市萬年里明倫路2號",
      "電話": "(04)8311349",
      "網址": "http://www.mljh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074512",
      "學校名稱": "縣立萬興國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[526]彰化縣二林鎮萬興里二溪路六段626號",
      "電話": "(04)8682978",
      "網址": "http://www.whjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074514",
      "學校名稱": "縣立竹塘國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[525]彰化縣竹塘鄉竹塘村竹林路一段550號",
      "電話": "(04)8972034",
      "網址": "http://www.ctjhs.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074515",
      "學校名稱": "縣立大城國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[527]彰化縣大城鄉東城村南平路224號",
      "電話": "(04)8941022",
      "網址": "http://www.tcjhs.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074516",
      "學校名稱": "縣立草湖國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[528]彰化縣芳苑鄉草湖村功湖路140號",
      "電話": "(04)8861051",
      "網址": "https://sites.google.com/a/thjh.chc.edu.tw/2016home/home",
      "": ""
    },
    {
      "代碼": "074517",
      "學校名稱": "縣立芳苑國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[528]彰化縣芳苑鄉仁愛村斗苑路芳苑段211號",
      "電話": "(04)8983264",
      "網址": "http://www.fyjhs.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074518",
      "學校名稱": "縣立溪湖國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[514]彰化縣溪湖鎮彰水路四段60號",
      "電話": "(04)8852132",
      "網址": "http://www.cfjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074519",
      "學校名稱": "縣立埔鹽國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[516]彰化縣埔鹽鄉好修村中正路162號",
      "電話": "(04)8653424",
      "網址": "http://www.pyjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074520",
      "學校名稱": "縣立埔心國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[513]彰化縣埔心鄉義民村忠義北路51號",
      "電話": "(04)8291129",
      "網址": "http://www.psjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074521",
      "學校名稱": "縣立福興國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[506]彰化縣福興鄉西勢村員鹿路二段270號",
      "電話": "(04)7772009",
      "網址": "http://163.23.69.129",
      "": ""
    },
    {
      "代碼": "074522",
      "學校名稱": "縣立秀水國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[504]彰化縣秀水鄉安東村中山路202號",
      "電話": "(04)7696031",
      "網址": "http://www.hsjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074524",
      "學校名稱": "縣立伸港國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[509]彰化縣伸港鄉新港路280巷101號",
      "電話": "(04)7988611",
      "網址": "http://www.skjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074525",
      "學校名稱": "縣立大村國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[515]彰化縣大村鄉村上村中山路二段240號",
      "電話": "(04)8521231",
      "網址": "http://www.ttjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074526",
      "學校名稱": "縣立花壇國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[503]彰化縣花壇鄉長沙村彰員路二段580號",
      "電話": "(04)7862043",
      "網址": "http://163.23.66.179/",
      "": ""
    },
    {
      "代碼": "074527",
      "學校名稱": "縣立永靖國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[512]彰化縣永靖鄉瑚璉村錫壽路123號",
      "電話": "(04)8221929",
      "網址": "http://www.ycjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074529",
      "學校名稱": "縣立二水國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[530]彰化縣二水鄉源泉村月眉巷13號",
      "電話": "(04)8791557 ",
      "網址": "http://www.esjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074530",
      "學校名稱": "縣立社頭國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[511]彰化縣社頭鄉廣興村中興路1號",
      "電話": "(04)8732047",
      "網址": "http://www.stjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074531",
      "學校名稱": "縣立田尾國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[522]彰化縣田尾鄉饒平村中學路二段18號",
      "電話": "(04)8832174",
      "網址": "http://www.twjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074532",
      "學校名稱": "縣立溪州國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[524]彰化縣溪州鄉溪州村中央路三段300號",
      "電話": "(04)8895054",
      "網址": "http://www.ccjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074533",
      "學校名稱": "縣立溪陽國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[524]彰化縣溪州鄉成功村下柑路1號",
      "電話": "(04)8802151",
      "網址": "http://163.23.101.189",
      "": ""
    },
    {
      "代碼": "074534",
      "學校名稱": "縣立埤頭國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[523]彰化縣埤頭鄉崙腳村中學路67號",
      "電話": "(04)8922004",
      "網址": "http://www.ptjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074535",
      "學校名稱": "縣立和群國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[508]彰化縣和美鎮柑井里美寮路一段390號",
      "電話": "(04)7350071",
      "網址": "http://www.hcjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074536",
      "學校名稱": "縣立大同國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[510]彰化縣員林市大同路一段345號",
      "電話": "(04)8358382",
      "網址": "http://www.ttjhs.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074537",
      "學校名稱": "縣立原斗國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[526]彰化縣二林鎮原斗里原竹路872號",
      "電話": "(04)8904600",
      "網址": "http://www.ydjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074538",
      "學校名稱": "縣立彰興國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市埔西街107號",
      "電話": "(04)7110743",
      "網址": "http://www.csjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074540",
      "學校名稱": "縣立彰泰國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市自強路357號",
      "電話": "(04)7369676",
      "網址": "http://www.ctsjh.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074541",
      "學校名稱": "縣立信義國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市向陽里向陽街168號",
      "電話": "(04)7635888",
      "網址": "http://www.hyjhes.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "074542",
      "學校名稱": "縣立鹿江國際國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[505]彰化縣鹿港鎮自由路300號",
      "電話": "(04)7775701",
      "網址": "http://www.ljis.chc.edu.tw/",
      "": ""
    },
    {
      "代碼": "074543",
      "學校名稱": "縣立民權華德福實驗國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[528]彰化縣芳苑鄉新生巷5之1號",
      "電話": "(04)8932625",
      "網址": "http://www.mces.chc.edu.tw",
      "": ""
    },
    {
      "代碼": "081502",
      "學校名稱": "私立均頭國中(小)",
      "公/私立": "私立",
      "縣市名稱": "[08]南投縣",
      "地址": "[545]南投縣埔里鎮水頭里4鄰水頭路48號",
      "電話": "(049)2980723",
      "網址": "http://www.jtjhs.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084501",
      "學校名稱": "縣立南投國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[540]南投縣南投市漳和里祖祠路361號",
      "電話": "(049)2222549",
      "網址": "http://www.ntjh.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084502",
      "學校名稱": "縣立南崗國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[540]南投縣南投市大庄路94號",
      "電話": "(049)2222460",
      "網址": "http://www.ngjh.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084503",
      "學校名稱": "縣立中興國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[540]南投縣南投市內興里新興路309號",
      "電話": "(049)2335283",
      "網址": "http://www.jsjhs.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084504",
      "學校名稱": "縣立鳳鳴國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[540]南投縣南投市鳳鳴里八卦路896號",
      "電話": "(049)2291831",
      "網址": "http://www.fmjhs.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084505",
      "學校名稱": "縣立埔里國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[545]南投縣埔里鎮西安路一段193號",
      "電話": "(049)2982055",
      "網址": "http://www.pljh.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084506",
      "學校名稱": "縣立大成國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[545]南投縣埔里鎮大城路169號",
      "電話": "(049)2915550",
      "網址": "http://www.dcjh.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084507",
      "學校名稱": "縣立宏仁國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[545]南投縣埔里鎮東門里公園路20號",
      "電話": "(049)2982548",
      "網址": "http://www.hjjh.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084508",
      "學校名稱": "縣立草屯國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[542]南投縣草屯鎮炎峰里虎山路808號",
      "電話": "(049)2362050",
      "網址": "http://163.22.76.1",
      "": ""
    },
    {
      "代碼": "084510",
      "學校名稱": "縣立日新國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[542]南投縣草屯鎮新豐里稻香路45號",
      "電話": "(049)2333480",
      "網址": "http://www.rsjhs.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084511",
      "學校名稱": "縣立竹山國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[557]南投縣竹山鎮中山里竹山路217號",
      "電話": "(049)2642068",
      "網址": "http://www.csjh.ntct.edu.tw/bin/home.php",
      "": ""
    },
    {
      "代碼": "084512",
      "學校名稱": "縣立延和國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[557]南投縣竹山鎮延和里鹿山路40號",
      "電話": "(049)2658641",
      "網址": "http://www.ehjhs.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084513",
      "學校名稱": "縣立社寮國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[557]南投縣竹山鎮集山路一段1729號",
      "電話": "(049)2622010",
      "網址": "http://www.sljh.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084514",
      "學校名稱": "縣立瑞竹國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[557]南投縣竹山鎮瑞竹里瑞東巷20號",
      "電話": "(049)2711014",
      "網址": "http://www.rujhs.ntct.edu.tw/bin/home.php",
      "": ""
    },
    {
      "代碼": "084515",
      "學校名稱": "縣立集集國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[552]南投縣集集鎮集集里成功路200號",
      "電話": "(049)2762013",
      "網址": "http://www.chijhs.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084516",
      "學校名稱": "縣立名間國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[551]南投縣名間鄉中山村彰南路237號",
      "電話": "(049)2732046",
      "網址": "http://www.mjjh.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084517",
      "學校名稱": "縣立三光國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[551]南投縣名間鄉新光村名山路50號",
      "電話": "(049)2732074",
      "網址": "http://www.sgjh.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084518",
      "學校名稱": "縣立鹿谷國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[558]南投縣鹿谷鄉彰雅村仁義路94之1號",
      "電話": "(049)2752001",
      "網址": "http://www.lkjh.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084519",
      "學校名稱": "縣立瑞峰國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[558]南投縣鹿谷鄉仁愛路154之1號",
      "電話": "(049)2671712",
      "網址": "http://www.rfjhs.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084520",
      "學校名稱": "縣立中寮國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[541]南投縣中寮鄉復興村初中巷50號",
      "電話": "(049)2691247",
      "網址": "http://www.cljh.ntct.edu.tw/bin/home.php",
      "": ""
    },
    {
      "代碼": "084521",
      "學校名稱": "縣立爽文國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[541]南投縣中寮鄉爽文村4 鄰竹坪巷40號",
      "電話": "(049)2601030",
      "網址": "http://163.22.78.1",
      "": ""
    },
    {
      "代碼": "084522",
      "學校名稱": "縣立魚池國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[555]南投縣魚池鄉魚池村魚池街441之6號",
      "電話": "(049)2895527",
      "網址": "http://www.ycjhs.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084523",
      "學校名稱": "縣立明潭國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[555]南投縣魚池鄉水社村中山路190號",
      "電話": "(049)2855145",
      "網址": "http://www.mtjhs.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084524",
      "學校名稱": "縣立國姓國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[544]南投縣國姓鄉石門村國姓路237號",
      "電話": "(049)2721144",
      "網址": "http://www.khjh.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084525",
      "學校名稱": "縣立北梅國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[544]南投縣國姓鄉北港村國姓路62號",
      "電話": "(049)2461004",
      "網址": "http://www.bmjh.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084526",
      "學校名稱": "縣立北山國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[544]南投縣國姓鄉北山村中正路四段157號",
      "電話": "(049)2451028#26",
      "網址": "http://www.bsjh.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084527",
      "學校名稱": "縣立水里國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[553]南投縣水里鄉民生路68號",
      "電話": "(049)2770134",
      "網址": "http://www.sljhs.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084528",
      "學校名稱": "縣立民和國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[553]南投縣水里鄉中學路29號",
      "電話": "(049)2741138",
      "網址": "http://www.mhjhs.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084529",
      "學校名稱": "縣立信義國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[556]南投縣信義鄉明德村玉山路20號",
      "電話": "(049)2791129",
      "網址": "http://www.syijh.ntct.edu.tw/bin/home.php",
      "": ""
    },
    {
      "代碼": "084530",
      "學校名稱": "縣立同富國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[556]南投縣信義鄉同富村同和巷7號",
      "電話": "(049)2701317",
      "網址": "http://www.tfjhs.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084531",
      "學校名稱": "縣立仁愛國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[546]南投縣仁愛鄉大同村信義巷28號",
      "電話": "(049)2802206",
      "網址": "http://www.jyjh.ntct.edu.tw",
      "": ""
    },
    {
      "代碼": "084532",
      "學校名稱": "縣立營北國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[540]南投縣南投市營北里向上路2號",
      "電話": "(049)2392683",
      "網址": "http://163.22.185.1",
      "": ""
    },
    {
      "代碼": "091502",
      "學校名稱": "私立淵明國中(代用)",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[643]雲林縣林內鄉林中村公園3號",
      "電話": "(05)5892027",
      "網址": "http://www.imjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "091503",
      "學校名稱": "私立東南國中(代用)",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[648]雲林縣西螺鎮東南路326號",
      "電話": "(05)5874176",
      "網址": "http://www.tnajh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "091506",
      "學校名稱": "私立福智實驗國中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[646]雲林縣古坑鄉麻園村平和20號",
      "電話": "05-582-8222分機6081",
      "網址": "",
      "": ""
    },
    {
      "代碼": "094502",
      "學校名稱": "縣立東明國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[630]雲林縣斗南鎮中興路111號",
      "電話": "(05)5965474",
      "網址": "http://www.tmjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094503",
      "學校名稱": "縣立大埤國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[631]雲林縣大埤鄉南和村民權路80號",
      "電話": "(05)5912051#103",
      "網址": "http://www.dpjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094504",
      "學校名稱": "縣立飛沙國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[654]雲林縣四湖鄉飛沙村大同路81號",
      "電話": "(05)7723218",
      "網址": "http://www.fsjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094505",
      "學校名稱": "縣立四湖國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[654]雲林縣四湖鄉湖西村中正路766號",
      "電話": "(05)7872040 ",
      "網址": "http://www.shjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094506",
      "學校名稱": "縣立水林國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[652]雲林縣水林鄉水北村水林路2號",
      "電話": "(05)7852618",
      "網址": "http://www.sljs.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094508",
      "學校名稱": "縣立二崙國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[649]雲林縣二崙鄉崙西村中正路88號",
      "電話": "(05)5982020",
      "網址": "http://www.eljh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094509",
      "學校名稱": "縣立褒忠國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[634]雲林縣褒忠鄉中勝路62號",
      "電話": "(05)6972056",
      "網址": "http://www.pcjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094510",
      "學校名稱": "縣立莿桐國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[647]雲林縣莿桐鄉農校路1號",
      "電話": "(05)5842014",
      "網址": "http://www.ttjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094511",
      "學校名稱": "縣立崙背國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[637]雲林縣崙背鄉大成路91號",
      "電話": "(05)6962013",
      "網址": "http://www.lpjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094512",
      "學校名稱": "縣立古坑國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[646]雲林縣古坑鄉朝陽村朝陽路2號",
      "電話": "(05)5822201",
      "網址": "http://www.kkjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094513",
      "學校名稱": "縣立東勢國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[635]雲林縣東勢鄉東榮路65號",
      "電話": "(05)6991035",
      "網址": "http://www.tsjhs.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094514",
      "學校名稱": "縣立元長國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[655]雲林縣元長鄉元南路16號",
      "電話": "(05)7882012",
      "網址": "http://www.yjjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094515",
      "學校名稱": "縣立斗六國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[640]雲林縣斗六市育英北街69號",
      "電話": "(05)5323296",
      "網址": "http://www.tljh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094516",
      "學校名稱": "縣立雲林國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[640]雲林縣斗六市明德路599號",
      "電話": "(05)5326071",
      "網址": "http://www.yljh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094517",
      "學校名稱": "縣立虎尾國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[632]雲林縣虎尾鎮中溪里中興路153號",
      "電話": "(05)6220647",
      "網址": "http://www.hwjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094518",
      "學校名稱": "縣立崇德國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[632]雲林縣虎尾鎮廉使里文科路1530號",
      "電話": "(05)6322806",
      "網址": "http://www.cdjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094519",
      "學校名稱": "縣立西螺國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[648]雲林縣西螺鎮興農西路217號",
      "電話": "(05)5863522",
      "網址": "http://www.sljhs.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094520",
      "學校名稱": "縣立北港國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[651]雲林縣北港鎮民生路102號",
      "電話": "(05)7832022",
      "網址": "http://www.pljh.ylc.edu.tw/www/html/",
      "": ""
    },
    {
      "代碼": "094521",
      "學校名稱": "縣立建國國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[651]雲林縣北港鎮大同路468號",
      "電話": "(05)7832724",
      "網址": "http://www.jgjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094522",
      "學校名稱": "縣立宜梧國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[653]雲林縣口湖鄉梧南村光明路1號",
      "電話": "(05)7907254",
      "網址": "http://www.ywjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094523",
      "學校名稱": "縣立口湖國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[653]雲林縣口湖鄉湖東村3鄰文明路133巷20號",
      "電話": "(05)7892027",
      "網址": "http://www.khjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094524",
      "學校名稱": "縣立臺西國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[636]雲林縣臺西鄉中山路408號",
      "電話": "(05)6982047",
      "網址": "http://www.tsjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094525",
      "學校名稱": "縣立土庫國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[633]雲林縣土庫鎮復興路318號",
      "電話": "(05)6622645",
      "網址": "http://www.tkjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094527",
      "學校名稱": "縣立東和國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[646]雲林縣古坑鄉東和村文化路130號",
      "電話": "(05)5263200",
      "網址": "http://www.thjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094528",
      "學校名稱": "縣立馬光國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[633]雲林縣土庫鎮馬光里和平街101號",
      "電話": "(05)6653587",
      "網址": "http://www.mkjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094529",
      "學校名稱": "縣立石榴國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[640]雲林縣斗六市榴中里光復路66號",
      "電話": "(05)5571713",
      "網址": "http://www.sljh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094530",
      "學校名稱": "縣立林內國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[643]雲林縣林內鄉烏麻村長源200號",
      "電話": "(05)5899306",
      "網址": "http://www.lnjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094543",
      "學校名稱": "縣立東仁國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[632]雲林縣虎尾鎮東仁里東明路85號",
      "電話": "(05)6312888",
      "網址": "http://www.drjh.ylc.edu.tw",
      "": ""
    },
    {
      "代碼": "094544",
      "學校名稱": "縣立樟湖生態國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[646]雲林縣古坑鄉石橋50-2號",
      "電話": "(05)5811044",
      "網址": "http://sites.google.com/site/newjhanghu/",
      "": ""
    },
    {
      "代碼": "104501",
      "學校名稱": "縣立朴子國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[613]嘉義縣朴子市竹圍里大同路245號",
      "電話": "(05)3792201",
      "網址": "http://www.ptjhs.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104502",
      "學校名稱": "縣立東石國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[613]嘉義縣朴子市安福里山通路6號",
      "電話": "(05)3792027",
      "網址": "http://www.tsjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104503",
      "學校名稱": "縣立布袋國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[625]嘉義縣布袋鎮光復里六棟寮40號",
      "電話": "(05)2949169",
      "網址": "http://schbase.cyc.edu.tw/schbase106/main.php",
      "": ""
    },
    {
      "代碼": "104504",
      "學校名稱": "縣立過溝國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[614]嘉義縣東石鄉西崙村438號",
      "電話": "(05)3451005",
      "網址": "http://www.kkjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104505",
      "學校名稱": "縣立大林國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[622]嘉義縣大林鎮大糖里中學路1號",
      "電話": "(05)2652014",
      "網址": "http://www.tljh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104506",
      "學校名稱": "縣立新港國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[616]嘉義縣新港鄉福德村福德路106號",
      "電話": "(05)3742024",
      "網址": "http://www.hkjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104507",
      "學校名稱": "縣立民雄國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[621]嘉義縣民雄鄉西安村西安路147號",
      "電話": "(05)2262527",
      "網址": "http://www.mihjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104508",
      "學校名稱": "縣立大吉國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[621]嘉義縣民雄鄉大崎村內埔仔2之1號",
      "電話": "(05)2211651",
      "網址": "http://www.djjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104509",
      "學校名稱": "縣立六嘉國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[615]嘉義縣六腳鄉蒜頭村樹仁路1號",
      "電話": "(05)3801215",
      "網址": "http://www.ljjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104511",
      "學校名稱": "縣立太保國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[612]嘉義縣太保市太保里36號",
      "電話": "(05)3711029",
      "網址": "http://www.tpjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104512",
      "學校名稱": "縣立嘉新國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[612]嘉義縣太保市中興路一段250號",
      "電話": "(05)2373029",
      "網址": "http://www.jsjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104513",
      "學校名稱": "縣立溪口國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[623]嘉義縣溪口鄉坪頂村中正東路103號",
      "電話": "(05)2691050",
      "網址": "http://www.ckjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104514",
      "學校名稱": "縣立鹿草國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[611]嘉義縣鹿草鄉鹿草路132號",
      "電話": "(05)3752037",
      "網址": "http://www.lsjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104515",
      "學校名稱": "縣立東榮國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[614]嘉義縣東石鄉永屯村39之2號",
      "電話": "(05)3733803",
      "網址": "http://www.drjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104516",
      "學校名稱": "縣立水上國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[608]嘉義縣水上鄉水上村進學街45號",
      "電話": "(05)2682024",
      "網址": "http://www.ssjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104517",
      "學校名稱": "縣立忠和國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[608]嘉義縣水上鄉忠和村6之2號",
      "電話": "(05)2891026",
      "網址": "http://www.jhjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104518",
      "學校名稱": "縣立中埔國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[606]嘉義縣中埔鄉中埔村177號",
      "電話": "(05)2531002",
      "網址": "http://www.cpjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104520",
      "學校名稱": "縣立昇平國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[604]嘉義縣竹崎鄉內埔村元興路138號",
      "電話": "(05)2541041",
      "網址": "http://www.spjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104521",
      "學校名稱": "縣立義竹國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[624]嘉義縣義竹鄉岸腳村59之2號",
      "電話": "(05)3412025",
      "網址": "http://www.icjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104522",
      "學校名稱": "縣立民和國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[602]嘉義縣番路鄉下坑村菜公店30號",
      "電話": "(05)2591109",
      "網址": "http://www.mhjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104523",
      "學校名稱": "縣立梅山國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[603]嘉義縣梅山鄉梅北村健康街136號",
      "電話": "(05)2621047",
      "網址": "http://www.msjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104524",
      "學校名稱": "縣立大埔國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[607]嘉義縣大埔鄉大埔村57號",
      "電話": "(05)2521014",
      "網址": "http://www.dpjh.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "104526",
      "學校名稱": "縣立阿里山國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[605]嘉義縣阿里山鄉樂野村1鄰31號",
      "電話": "(05)2561180",
      "網址": "http://www.aljes.cyc.edu.tw/",
      "": ""
    },
    {
      "代碼": "104527",
      "學校名稱": "縣立豐山實驗國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[605]嘉義縣阿里山鄉豐山村47號",
      "電話": "(05)2661071",
      "網址": "http://www.fses.cyc.edu.tw",
      "": ""
    },
    {
      "代碼": "131501",
      "學校名稱": "私立南榮國中(代用)",
      "公/私立": "私立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[924]屏東縣崁頂鄉園寮村田寮路1之15號",
      "電話": "(08)8631112",
      "網址": "http://www.nzjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "131502",
      "學校名稱": "私立崇華國中",
      "公/私立": "私立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[908]屏東縣長治鄉新興路38號",
      "電話": "(08)7626365",
      "網址": "http://www.chbes.ptc.edu.tw/",
      "": ""
    },
    {
      "代碼": "134501",
      "學校名稱": "縣立明正國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市大連路70號",
      "電話": "(08)7363078",
      "網址": "http://www.mcjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134502",
      "學校名稱": "縣立中正國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市民學路2號",
      "電話": "(08)7226387",
      "網址": "http://www.ccjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134503",
      "學校名稱": "縣立公正國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市公興路11號",
      "電話": "(08)7522097",
      "網址": "http://www.kcjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134505",
      "學校名稱": "縣立鶴聲國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市大武路225號",
      "電話": "(08)7520591",
      "網址": "http://www.hsjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134506",
      "學校名稱": "縣立至正國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市公裕街300號",
      "電話": "(08)7666394",
      "網址": "http://www.jjjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134507",
      "學校名稱": "縣立長治國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[908]屏東縣長治鄉德成村中興路578號",
      "電話": "(08)7621193",
      "網址": "http://www.cjjhs.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134508",
      "學校名稱": "縣立麟洛國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[909]屏東縣麟洛鄉中山路2號",
      "電話": "(08)7222812",
      "網址": "http://www.lljh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134509",
      "學校名稱": "縣立九如國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[904]屏東縣九如鄉玉水路26號",
      "電話": "(08)7392404",
      "網址": "http://www.jrjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134510",
      "學校名稱": "縣立里港國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[905]屏東縣里港鄉春林村里中路10號",
      "電話": "(08)7752029",
      "網址": "http://www.lgjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134511",
      "學校名稱": "縣立鹽埔國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[907]屏東縣鹽埔鄉鹽南村勝利路30號",
      "電話": "(08)7932001",
      "網址": "http://www.ypjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134512",
      "學校名稱": "縣立高樹國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[906]屏東縣高樹鄉高樹村南興路4號",
      "電話": "(08)7962034",
      "網址": "http://www.ksjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134513",
      "學校名稱": "縣立高泰國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[906]屏東縣高樹鄉泰山村產業路225號",
      "電話": "(08)7956073",
      "網址": "http://www.gtjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134514",
      "學校名稱": "縣立內埔國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[912]屏東縣內埔鄉文化路262號",
      "電話": "(08)7792013",
      "網址": "http://www.npjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134515",
      "學校名稱": "縣立崇文國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[912]屏東縣內埔鄉龍潭村昭勝路438號",
      "電話": "(08)7701864",
      "網址": "http://163.24.70.10/school/web/index.php",
      "": ""
    },
    {
      "代碼": "134516",
      "學校名稱": "縣立竹田國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[911]屏東縣竹田鄉西勢村龍門路26號",
      "電話": "(08)7792024",
      "網址": "http://www.jtjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134517",
      "學校名稱": "縣立潮州國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[920]屏東縣潮州鎮文化路66號",
      "電話": "(08)7882401",
      "網址": "http://www.cjjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134518",
      "學校名稱": "縣立光春國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[920]屏東縣潮州鎮光春路290號",
      "電話": "(08)7887038",
      "網址": "http://www.kcjhs.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134519",
      "學校名稱": "縣立萬巒國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[923]屏東縣萬巒鄉萬和村褒忠路5號",
      "電話": "(08)7812537",
      "網址": "http://www.wljh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134520",
      "學校名稱": "縣立新埤國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[925]屏東縣新埤鄉建功路190號",
      "電話": "(08)7971014",
      "網址": "http://www.spjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134522",
      "學校名稱": "縣立萬丹國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[913]屏東縣萬丹鄉四維村萬丹路2段5號",
      "電話": "(08)7772020",
      "網址": "http://www.wtjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134523",
      "學校名稱": "縣立新園國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[932]屏東縣新園鄉仙吉村仙吉路111號",
      "電話": "(08)8681020",
      "網址": "http://www.syjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134525",
      "學校名稱": "縣立林邊國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[927]屏東縣林邊鄉光林村和平路43號",
      "電話": "(08)8752051",
      "網址": "http://www.lpjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134526",
      "學校名稱": "縣立南州國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[926]屏東縣南州鄉人和路231號",
      "電話": "(08)8642041",
      "網址": "http://www.njjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134527",
      "學校名稱": "縣立佳冬國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[931]屏東縣佳冬鄉玉光村大同路1號",
      "電話": "(08)8662041",
      "網址": "http://www.jdjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134528",
      "學校名稱": "縣立琉球國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[929]屏東縣琉球鄉中正路158號",
      "電話": "(08)8612509",
      "網址": "http://www.lcjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134530",
      "學校名稱": "縣立車城國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[944]屏東縣車城鄉新興路42號",
      "電話": "(08)8821045",
      "網址": "http://www.ccjhs.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134531",
      "學校名稱": "縣立恆春國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[946]屏東縣恆春鎮文化路79號",
      "電話": "(08)8892039",
      "網址": "http://www.hcjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134532",
      "學校名稱": "縣立滿州國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[947]屏東縣滿州鄉文化路32號",
      "電話": "(08)8801106",
      "網址": "http://www.mjjhs.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134533",
      "學校名稱": "縣立瑪家國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[903]屏東縣瑪家鄉三和村三和巷16號",
      "電話": "(08)7994343",
      "網址": "http://www.mjjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134535",
      "學校名稱": "縣立泰武國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[921]屏東縣泰武鄉佳平路120號",
      "電話": "(08)7831025",
      "網址": "http://www.twjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134536",
      "學校名稱": "縣立牡丹國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[945]屏東縣牡丹鄉石門村石門路1之1號",
      "電話": "(08)8831014",
      "網址": "http://www.mdjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134537",
      "學校名稱": "縣立獅子國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[941]屏東縣枋山鄉楓港村舊庄280號",
      "電話": "(08)8771121",
      "網址": "http://www.stjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134538",
      "學校名稱": "縣立東新國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[928]屏東縣東港鎮興東里東新路1號",
      "電話": "(08)8324920",
      "網址": "http://www.dsjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134542",
      "學校名稱": "縣立萬新國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[913]屏東縣萬丹鄉新鐘村新鐘路8號",
      "電話": "(08)7764363",
      "網址": "http://www.wsjh.ptc.edu.tw",
      "": ""
    },
    {
      "代碼": "134543",
      "學校名稱": "縣立大路關國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[906]屏東縣高樹鄉廣興村中正路176號",
      "電話": "(08)7956844",
      "網址": "http://163.24.125.246/",
      "": ""
    },
    {
      "代碼": "144501",
      "學校名稱": "縣立新生國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市新生路641巷64號",
      "電話": "(089)229121",
      "網址": "http://www.hsjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144502",
      "學校名稱": "縣立東海國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市中華路一段719巷51號",
      "電話": "(089)323271",
      "網址": "http://www.thjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144503",
      "學校名稱": "縣立寶桑國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市仁七街1號",
      "電話": "(089)220011",
      "網址": "http://www.tcbjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144504",
      "學校名稱": "縣立卑南國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市更生北路766號",
      "電話": "(089)224045",
      "網址": "http://www.pnjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144505",
      "學校名稱": "縣立豐田國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市中興路四段611巷56弄27號",
      "電話": "(089)380241",
      "網址": "http://www.ftjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144506",
      "學校名稱": "縣立知本國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市青海路三段680號",
      "電話": "(089)512203",
      "網址": "http://www.cpjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144507",
      "學校名稱": "縣立初鹿國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[954]臺東縣卑南鄉明峰村忠孝路2號",
      "電話": "(089)571125",
      "網址": "http://www.cljh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144508",
      "學校名稱": "縣立鹿野國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[955]臺東縣鹿野鄉龍田村光榮路38號",
      "電話": "(089)551006",
      "網址": "http://www.lyjh.ttct.edu.tw/bin/home.php",
      "": ""
    },
    {
      "代碼": "144509",
      "學校名稱": "縣立瑞源國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[955]臺東縣鹿野鄉瑞和村瑞景路三段1號",
      "電話": "(089)581425",
      "網址": "http://www.ryjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144510",
      "學校名稱": "縣立關山國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[956]臺東縣關山鎮崁頂路22號",
      "電話": "(089)811114",
      "網址": "http://www.ksjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144511",
      "學校名稱": "縣立池上國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[958]臺東縣池上鄉新興村力學路88號",
      "電話": "(089)862040",
      "網址": "http://www.csjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144512",
      "學校名稱": "縣立大王國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[963]臺東縣太麻里鄉大王村633號",
      "電話": "(089)781324",
      "網址": "http://www.dwjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144513",
      "學校名稱": "縣立賓茂國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[963]臺東縣太麻里鄉金崙村77號",
      "電話": "(089)771076",
      "網址": "http://www.bmjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144514",
      "學校名稱": "縣立大武國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[965]臺東縣大武鄉大武村復興路1號",
      "電話": "(089)791023",
      "網址": "http://www.twjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144515",
      "學校名稱": "縣立都蘭國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[959]臺東縣東河鄉都蘭村398號",
      "電話": "(089)531234",
      "網址": "http://www.tljh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144516",
      "學校名稱": "縣立泰源國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[959]臺東縣東河鄉北源村3鄰30號",
      "電話": "(089)891252",
      "網址": "http://www.tyjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144517",
      "學校名稱": "縣立新港國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[961]臺東縣成功鎮中正路1號",
      "電話": "(089)851034",
      "網址": "http://www.hkjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144518",
      "學校名稱": "縣立長濱國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[962]臺東縣長濱鄉長濱村15鄰180號",
      "電話": "(089)831017",
      "網址": "http://www.cpjhs.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144519",
      "學校名稱": "縣立桃源國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[953]臺東縣延平鄉桃源村昇平路178號",
      "電話": "(089)561255",
      "網址": "http://www.tyjhs.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "144520",
      "學校名稱": "縣立海端國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[957]臺東縣海端鄉海端村山界1號",
      "電話": "(089)931390",
      "網址": "http://hdjh.boe.ttct.edu.tw/bin/home.php",
      "": ""
    },
    {
      "代碼": "144521",
      "學校名稱": "縣立綠島國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[951]臺東縣綠島鄉中寮村4號",
      "電話": "(089)672506",
      "網址": "http://www.ldjh.ttct.edu.tw",
      "": ""
    },
    {
      "代碼": "154501",
      "學校名稱": "縣立玉里國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[981]花蓮縣玉里鎮民族街30號",
      "電話": "(03)8882054",
      "網址": "http://www.yljh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154502",
      "學校名稱": "縣立玉東國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[981]花蓮縣玉里鎮松浦里松浦39號",
      "電話": "(03)8851062",
      "網址": "http://www.ytjh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154503",
      "學校名稱": "縣立三民國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[981]花蓮縣玉里鎮三民里151號",
      "電話": "(03)8841198",
      "網址": "http://www.smjh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154504",
      "學校名稱": "縣立美崙國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[970]花蓮縣花蓮市化道路40巷1號",
      "電話": "(03)8223537",
      "網址": "http://www.mljh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154505",
      "學校名稱": "縣立花崗國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[970]花蓮縣花蓮市公園路40號",
      "電話": "(03)8323924",
      "網址": "http://www.hgjh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154506",
      "學校名稱": "縣立國風國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[970]花蓮縣花蓮市國風里林政街7號",
      "電話": "(03)8323847",
      "網址": "http://www.kfjh.hlc.edu.tw/",
      "": ""
    },
    {
      "代碼": "154507",
      "學校名稱": "縣立秀林國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[971]花蓮縣新城鄉新城村中山路56號",
      "電話": "(03)8611010",
      "網址": "http://www.sljh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154508",
      "學校名稱": "縣立新城國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[971]花蓮縣新城鄉北埔村北埔路143號",
      "電話": "(03)8263911",
      "網址": "http://www.scjh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154509",
      "學校名稱": "縣立吉安國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[973]花蓮縣吉安鄉中山路三段662號",
      "電話": "(03)8523136",
      "網址": "http://www.cajh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154510",
      "學校名稱": "縣立宜昌國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[973]花蓮縣吉安鄉宜昌1街41號",
      "電話": "(03)8520803",
      "網址": "http://www.ycjh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154511",
      "學校名稱": "縣立壽豐國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[974]花蓮縣壽豐鄉豐裡村中山路132號",
      "電話": "(03)8652111",
      "網址": "http://www.sfjh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154512",
      "學校名稱": "縣立平和國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[974]花蓮縣壽豐鄉平和村平和路48號",
      "電話": "(03)8661221",
      "網址": "http://www.phjh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154513",
      "學校名稱": "縣立光復國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[976]花蓮縣光復鄉大馬村林森路200號",
      "電話": "(03)8701027",
      "網址": "http://www.kfjhs.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154514",
      "學校名稱": "縣立富源國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[976]花蓮縣光復鄉大富村中山路一段2號",
      "電話": "(03)8811002",
      "網址": "http://www.fyjh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154515",
      "學校名稱": "縣立鳳林國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[975]花蓮縣鳳林鎮光復路8號",
      "電話": "(03)8761101",
      "網址": "http://www.fles.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154516",
      "學校名稱": "縣立萬榮國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[975]花蓮縣鳳林鎮長橋路1號",
      "電話": "(03)8751264",
      "網址": "http://www.wljh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154517",
      "學校名稱": "縣立富里國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[983]花蓮縣富里鄉永安路173號",
      "電話": "(03)8830006",
      "網址": "http://www.fljh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154518",
      "學校名稱": "縣立富北國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[983]花蓮縣富里鄉新興村一鄰8號",
      "電話": "(03)8821134",
      "網址": "http://www.fbjh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154519",
      "學校名稱": "縣立豐濱國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[977]花蓮縣豐濱鄉光豐路26號",
      "電話": "(03)8791159",
      "網址": "http://www.fpjh.hlc.edu.tw/",
      "": ""
    },
    {
      "代碼": "154520",
      "學校名稱": "縣立瑞穗國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[978]花蓮縣瑞穗鄉成功北路18號",
      "電話": "(03)8873111",
      "網址": "http://www.zsjh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154521",
      "學校名稱": "縣立東里國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[983]花蓮縣富里鄉東里村道化路82號",
      "電話": "(03)8861174",
      "網址": "http://www.tljh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154522",
      "學校名稱": "縣立自強國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[970]花蓮縣花蓮市裕祥路89號",
      "電話": "(03)8579338",
      "網址": "http://www.zcjh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "154523",
      "學校名稱": "縣立化仁國中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[973]花蓮縣吉安鄉東昌村東海十街3號",
      "電話": "(03)8543471",
      "網址": "http://www.hrjh.hlc.edu.tw",
      "": ""
    },
    {
      "代碼": "164501",
      "學校名稱": "縣立馬公國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[880]澎湖縣馬公市西文里中華路326號",
      "電話": "(06)9263367",
      "網址": "http://www.mkjh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "164502",
      "學校名稱": "縣立中正國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[880]澎湖縣馬公市光華里101號",
      "電話": "(06)9211137",
      "網址": "http://www.ccjh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "164503",
      "學校名稱": "縣立澎南國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[880]澎湖縣馬公市五德里41號",
      "電話": "(06)9951212",
      "網址": "http://www.pnjh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "164504",
      "學校名稱": "縣立湖西國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[885]澎湖縣湖西鄉湖西村43之1號",
      "電話": "(06)9921929",
      "網址": "http://www.hsjh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "164505",
      "學校名稱": "縣立志清國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[885]澎湖縣湖西鄉許家村1之1號",
      "電話": "(06)9214069",
      "網址": "http://www.jcjh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "164506",
      "學校名稱": "縣立鎮海國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[884]澎湖縣白沙鄉鎮海村60號",
      "電話": "(06)9931311",
      "網址": "http://www.jhjh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "164507",
      "學校名稱": "縣立白沙國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[884]澎湖縣白沙鄉赤崁村294號",
      "電話": "(06)9931006",
      "網址": "http://www.psjh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "164508",
      "學校名稱": "縣立吉貝國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[884]澎湖縣白沙鄉吉貝村185號",
      "電話": "(06)9911090",
      "網址": "http://www.gbjh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "164509",
      "學校名稱": "縣立西嶼國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[881]澎湖縣西嶼鄉池東村213號",
      "電話": "(06)9981110",
      "網址": "http://www.hyjh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "164510",
      "學校名稱": "縣立望安國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[882]澎湖縣望安鄉西安村36之1號",
      "電話": "(06)9991007",
      "網址": "http://www.wajh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "164511",
      "學校名稱": "縣立將澳國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[882]澎湖縣望安鄉將軍村12之7號",
      "電話": "(06)9902079",
      "網址": "http://www.jajh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "164512",
      "學校名稱": "縣立七美國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[883]澎湖縣七美鄉中和村4號",
      "電話": "(06)9971019",
      "網址": "http://www.cmjh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "164513",
      "學校名稱": "縣立文光國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[880]澎湖縣馬公市文光路17號",
      "電話": "(06)9272992",
      "網址": "http://www.wgjh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "164514",
      "學校名稱": "縣立鳥嶼國中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[884]澎湖縣白沙鄉鳥嶼村1號",
      "電話": "(06)9916260",
      "網址": "http://www.nyjh.phc.edu.tw",
      "": ""
    },
    {
      "代碼": "173501",
      "學校名稱": "市立明德國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[206]基隆市七堵區東新街20號",
      "電話": "(02)24561274",
      "網址": "http://www.mdjh.kl.edu.tw",
      "": ""
    },
    {
      "代碼": "173502",
      "學校名稱": "市立銘傳國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[200]基隆市仁愛區劉銘傳路132號",
      "電話": "(02)24223120",
      "網址": "http://www.mcjh.kl.edu.tw",
      "": ""
    },
    {
      "代碼": "173503",
      "學校名稱": "市立信義國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[201]基隆市信義區東信路4號",
      "電話": "(02)24652199",
      "網址": "http://www.syjh.kl.edu.tw",
      "": ""
    },
    {
      "代碼": "173505",
      "學校名稱": "市立中正國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[202]基隆市中正區中船路36巷59號",
      "電話": "(02)24282191",
      "網址": "http://www.ccjh.kl.edu.tw",
      "": ""
    },
    {
      "代碼": "173508",
      "學校名稱": "市立南榮國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[200]基隆市仁愛區南榮路319巷36號",
      "電話": "(02)24282188",
      "網址": "http://www.nrjh.kl.edu.tw",
      "": ""
    },
    {
      "代碼": "173509",
      "學校名稱": "市立成功國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[201]基隆市信義區壽山路9號",
      "電話": "(02)24225594",
      "網址": "http://www.ckjh.kl.edu.tw",
      "": ""
    },
    {
      "代碼": "173510",
      "學校名稱": "市立正濱國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[202]基隆市中正區正榮街88號",
      "電話": "(02)24631490",
      "網址": "http://www.cbjh.kl.edu.tw",
      "": ""
    },
    {
      "代碼": "173512",
      "學校名稱": "市立建德國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[204]基隆市安樂區安和一街29號",
      "電話": "(02)24321234",
      "網址": "http://www.jdjh.kl.edu.tw",
      "": ""
    },
    {
      "代碼": "173513",
      "學校名稱": "市立百福國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[206]基隆市七堵區福五街1號",
      "電話": "(02)24511158",
      "網址": "http://www.pfjh.kl.edu.tw",
      "": ""
    },
    {
      "代碼": "173515",
      "學校名稱": "市立碇內國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[205]基隆市暖暖區源遠路152巷75號",
      "電話": "(02)24586105",
      "網址": "http://www.dnjh.kl.edu.tw",
      "": ""
    },
    {
      "代碼": "173516",
      "學校名稱": "市立武崙國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[204]基隆市安樂區武崙街205號",
      "電話": "(02)24342456",
      "網址": "http://www.wljh.kl.edu.tw",
      "": ""
    },
    {
      "代碼": "181502",
      "學校名稱": "私立新竹市康橋國中(小)",
      "公/私立": "私立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區柴橋路95號",
      "電話": "(03)5192000",
      "網址": "http://www.kcbs.ntpc.edu.tw/_Admission/HsinchuCampus.html",
      "": ""
    },
    {
      "代碼": "183501",
      "學校名稱": "市立建華國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區學府路2號",
      "電話": "(03)5238075",
      "網址": "http://www.chjh.hc.edu.tw",
      "": ""
    },
    {
      "代碼": "183502",
      "學校名稱": "市立培英國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區學府路4號",
      "電話": "(03)5721301",
      "網址": "http://www.pijh.hc.edu.tw",
      "": ""
    },
    {
      "代碼": "183503",
      "學校名稱": "市立光華國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市北區光華北街10號",
      "電話": "(03)5316605",
      "網址": "http://www.khjh.hc.edu.tw",
      "": ""
    },
    {
      "代碼": "183504",
      "學校名稱": "市立育賢國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區南大路569號",
      "電話": "(03)5223075",
      "網址": "http://www.ysjh.hc.edu.tw",
      "": ""
    },
    {
      "代碼": "183505",
      "學校名稱": "市立光武國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區光復路一段512號",
      "電話": "(03)5778784",
      "網址": "http://www.gwjh.hc.edu.tw",
      "": ""
    },
    {
      "代碼": "183508",
      "學校名稱": "市立南華國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市北區海濱路55號",
      "電話": "(03)5362204",
      "網址": "http://www.nhjh.hc.edu.tw",
      "": ""
    },
    {
      "代碼": "183509",
      "學校名稱": "市立富禮國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市香山區富禮街16號",
      "電話": "(03)5374793",
      "網址": "http://www.fljh.hc.edu.tw",
      "": ""
    },
    {
      "代碼": "183510",
      "學校名稱": "市立三民國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區自由路95巷15號",
      "電話": "(03)5339825",
      "網址": "http://www.smjh.hc.edu.tw",
      "": ""
    },
    {
      "代碼": "183511",
      "學校名稱": "市立內湖國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市香山區五福路一段12號",
      "電話": "(03)5373484",
      "網址": "http://www.nhjhs.hc.edu.tw",
      "": ""
    },
    {
      "代碼": "183512",
      "學校名稱": "市立虎林國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市香山區延平路二段76號",
      "電話": "(03)5309433",
      "網址": "http://www.hljh.hc.edu.tw",
      "": ""
    },
    {
      "代碼": "183514",
      "學校名稱": "市立新科國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區光復路一段89巷88號",
      "電話": "(03)6686387",
      "網址": "http://www.hkjh.hc.edu.tw",
      "": ""
    },
    {
      "代碼": "183515",
      "學校名稱": "市立竹光國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市北區和平路1號",
      "電話": "(03)5246683",
      "網址": "http://www.zgjh.hc.edu.tw",
      "": ""
    },
    {
      "代碼": "183516",
      "學校名稱": "市立華德福實驗國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市香山區中華路六段331巷168號",
      "電話": "(03)5370531",
      "網址": "http://sunwaldorf.blogspot.com/",
      "": ""
    },
    {
      "代碼": "203501",
      "學校名稱": "市立大業國中",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區大業街57號",
      "電話": "(05)2223082",
      "網址": "http://www.dyjh.cy.edu.tw",
      "": ""
    },
    {
      "代碼": "203502",
      "學校名稱": "市立北興國中",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區仁義里博東路262號",
      "電話": "(05)2766602",
      "網址": "http://www.psjh.cy.edu.tw",
      "": ""
    },
    {
      "代碼": "203503",
      "學校名稱": "市立嘉義國中",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區王田里圓福街86號",
      "電話": "(05)2762625",
      "網址": "http://www.cyjh.cy.edu.tw",
      "": ""
    },
    {
      "代碼": "203504",
      "學校名稱": "市立南興國中",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區芳安路111號",
      "電話": "(05)2224383",
      "網址": "http://www.nhjh.cy.edu.tw",
      "": ""
    },
    {
      "代碼": "203505",
      "學校名稱": "市立民生國中",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市西區新民路601號",
      "電話": "(05)2855331",
      "網址": "http://www.msjh.cy.edu.tw",
      "": ""
    },
    {
      "代碼": "203506",
      "學校名稱": "市立玉山國中",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市西區友忠路1號",
      "電話": "(05)2357980",
      "網址": "http://www.ysjh.cy.edu.tw",
      "": ""
    },
    {
      "代碼": "203507",
      "學校名稱": "市立蘭潭國中",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區民權東路32號",
      "電話": "(05)2773582",
      "網址": "http://www.ltjh.cy.edu.tw",
      "": ""
    },
    {
      "代碼": "203508",
      "學校名稱": "市立北園國中",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市西區雙園街280號",
      "電話": "(05)2374673",
      "網址": "http://163.27.36.1",
      "": ""
    },
    {
      "代碼": "714501",
      "學校名稱": "縣立金城國中",
      "公/私立": "公立",
      "縣市名稱": "[71]金門縣",
      "地址": "[893]金門縣金城鎮民權路32號",
      "電話": "(082)325454",
      "網址": "http://www.kcjh.km.edu.tw",
      "": ""
    },
    {
      "代碼": "714502",
      "學校名稱": "縣立金湖國中",
      "公/私立": "公立",
      "縣市名稱": "[71]金門縣",
      "地址": "[891]金門縣金湖鎮太湖路三段1號",
      "電話": "(082)332612",
      "網址": "https://sites.google.com/site/kmkhjhwebsite/",
      "": ""
    },
    {
      "代碼": "714503",
      "學校名稱": "縣立金寧國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[71]金門縣",
      "地址": "[892]金門縣金寧鄉安岐1號",
      "電話": "(082)325748",
      "網址": "http://www.cnjh.km.edu.tw/",
      "": ""
    },
    {
      "代碼": "714504",
      "學校名稱": "縣立金沙國中",
      "公/私立": "公立",
      "縣市名稱": "[71]金門縣",
      "地址": "[890]金門縣金沙鎮汶沙里國中路61號",
      "電話": "(082)352560",
      "網址": "http://teacher.hlc.edu.tw/?id=1261",
      "": ""
    },
    {
      "代碼": "714505",
      "學校名稱": "縣立烈嶼國中",
      "公/私立": "公立",
      "縣市名稱": "[71]金門縣",
      "地址": "[894]金門縣烈嶼鄉后井38號",
      "電話": "(082)362425",
      "網址": "http://www.lyjh.km.edu.tw",
      "": ""
    },
    {
      "代碼": "724501",
      "學校名稱": "縣立介壽國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[72]連江縣",
      "地址": "[209]連江縣南竿鄉介壽村13號",
      "電話": "(0836)22192",
      "網址": "http://www.jsps.matsu.edu.tw",
      "": ""
    },
    {
      "代碼": "724502",
      "學校名稱": "縣立中正國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[72]連江縣",
      "地址": "[209]連江縣南竿鄉馬祖村4號",
      "電話": "(0836)22196",
      "網址": "http://www.jjps.matsu.edu.tw",
      "": ""
    },
    {
      "代碼": "724503",
      "學校名稱": "縣立中山國中",
      "公/私立": "公立",
      "縣市名稱": "[72]連江縣",
      "地址": "[210]連江縣北竿鄉芹壁村84號",
      "電話": "(0836)55223",
      "網址": "http://www.jshjh.matsu.edu.tw",
      "": ""
    },
    {
      "代碼": "724504",
      "學校名稱": "縣立敬恆國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[72]連江縣",
      "地址": "[211]連江縣莒光鄉青帆村19號",
      "電話": "(0836)88179",
      "網址": "http://www.jhjh.matsu.edu.tw",
      "": ""
    },
    {
      "代碼": "724505",
      "學校名稱": "縣立東引國中(小)",
      "公/私立": "公立",
      "縣市名稱": "[72]連江縣",
      "地址": "[212]連江縣東引鄉中柳村94號",
      "電話": "(0836)77210",
      "網址": "http://www.tyjh.matsu.edu.tw",
      "": ""
    },
    {
      "代碼": "010301",
      "學校名稱": "國立華僑高級中等學校附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區大觀路一段32號",
      "電話": "(02)29684131",
      "網址": "http://www.nocsh.ntpc.edu.tw"
    },
    {
      "代碼": "011301",
      "學校名稱": "私立淡江高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[251]新北市淡水區真理街26號",
      "電話": "(02)26203850",
      "網址": "http://www.tksh.ntpc.edu.tw"
    },
    {
      "代碼": "011302",
      "學校名稱": "私立康橋高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區華城路800號",
      "電話": "(02)22166000",
      "網址": "http://www.kcbs.ntpc.edu.tw"
    },
    {
      "代碼": "011306",
      "學校名稱": "私立金陵女中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區重新路五段656號",
      "電話": "(02)29956776",
      "網址": "http://www.glghs.ntpc.edu.tw"
    },
    {
      "代碼": "011307",
      "學校名稱": "新北市裕德高級中等學校附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[236]新北市土城區擺接堡路1號",
      "電話": "(02)82617889",
      "網址": "http://www.yuteh.ntpc.edu.tw/yuteh/"
    },
    {
      "代碼": "011309",
      "學校名稱": "財團法人南山高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[235]新北市中和區廣福路41號",
      "電話": "(02)22453000",
      "網址": "http://www.nssh.ntpc.edu.tw"
    },
    {
      "代碼": "011310",
      "學校名稱": "財團法人恆毅高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[242]新北市新莊區中正路108號",
      "電話": "(02)29923619  ",
      "網址": "http://www.hchs.ntpc.edu.tw"
    },
    {
      "代碼": "011311",
      "學校名稱": "私立聖心女中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[249]新北市八里區龍米路一段263號",
      "電話": "(02)26182287",
      "網址": "http://www.shgsh.ntpc.edu.tw"
    },
    {
      "代碼": "011312",
      "學校名稱": "私立崇義高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[221]新北市汐止區大同路三段68號",
      "電話": "(02)86482078",
      "網址": "http://www.tyhs.ntpc.edu.tw/"
    },
    {
      "代碼": "011314",
      "學校名稱": "財團法人中華高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[236]新北市土城區城林路2號",
      "電話": "(02)22693641",
      "網址": "http://www.chsh.ntpc.edu.tw"
    },
    {
      "代碼": "011316",
      "學校名稱": "私立格致高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區大智街260號",
      "電話": "(02)29855892",
      "網址": "http://www.gjsh.ntpc.edu.tw"
    },
    {
      "代碼": "011317",
      "學校名稱": "私立醒吾高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[244]新北市林口區粉寮路一段75巷80號",
      "電話": "(02)26012644",
      "網址": "http://www.swsh.ntpc.edu.tw"
    },
    {
      "代碼": "011318",
      "學校名稱": "私立徐匯高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[247]新北市蘆洲區中山一路1號",
      "電話": "(02)22817565",
      "網址": "http://www.sish.ntpc.edu.tw"
    },
    {
      "代碼": "011322",
      "學校名稱": "財團法人崇光女中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區三民路18號",
      "電話": "(02)29112543",
      "網址": "http://www.ckgsh.ntpc.edu.tw"
    },
    {
      "代碼": "011323",
      "學校名稱": "私立光仁高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區中山路二段255巷18號",
      "電話": "(02)29615161",
      "網址": "http://www.kjsh.ntpc.edu.tw"
    },
    {
      "代碼": "011324",
      "學校名稱": "私立竹林高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[235]新北市中和區華新街143巷12號",
      "電話": "(02)29422128",
      "網址": "http://www.clsh.ntpc.edu.tw"
    },
    {
      "代碼": "011325",
      "學校名稱": "私立及人高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區安興路1號",
      "電話": "(02)22112581",
      "網址": "http://www.cjsh.ntpc.edu.tw"
    },
    {
      "代碼": "011329",
      "學校名稱": "財團法人辭修高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[237]新北市三峽區溪東路251號",
      "電話": "(02)86761277",
      "網址": "http://www.tsshs.ntpc.edu.tw"
    },
    {
      "代碼": "011330",
      "學校名稱": "新北市林口康橋高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[244]新北市林口區興林路55號",
      "電話": "(02)26015888",
      "網址": "http://www.kcislk.ntpc.edu.tw/zh/index.html"
    },
    {
      "代碼": "011399",
      "學校名稱": "私立時雨高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[224]新北市瑞芳區銅山里祈堂路217號",
      "電話": "(02)24962217",
      "網址": "http://www.syjh.ntpc.edu.tw"
    },
    {
      "代碼": "014302",
      "學校名稱": "市立海山高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區漢生東路215號",
      "電話": "(02)29517475",
      "網址": "http://www.hshs.ntpc.edu.tw"
    },
    {
      "代碼": "014311",
      "學校名稱": "市立三重高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區集美街212號",
      "電話": "(02)29760501",
      "網址": "http://www.schs.ntpc.edu.tw/"
    },
    {
      "代碼": "014315",
      "學校名稱": "市立永平高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[234]新北市永和區永平路205號",
      "電話": "(02)22319670",
      "網址": "http://www.yphs.ntpc.edu.tw"
    },
    {
      "代碼": "014322",
      "學校名稱": "市立樹林高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[238]新北市樹林區中華路8號",
      "電話": "(02)86852011",
      "網址": "http://www.slsh.ntpc.edu.tw"
    },
    {
      "代碼": "014326",
      "學校名稱": "市立明德高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[237]新北市三峽區中正路二段399號",
      "電話": "(02)26723302",
      "網址": "http://www.mdhs.ntpc.edu.tw"
    },
    {
      "代碼": "014332",
      "學校名稱": "市立秀峰高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[221]新北市汐止區忠孝東路201號",
      "電話": "(02)26412134",
      "網址": "http://www.sfhs.ntpc.edu.tw/default_page.asp"
    },
    {
      "代碼": "014338",
      "學校名稱": "市立金山高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[208]新北市金山區美田里文化二路2號",
      "電話": "(02)24982028",
      "網址": "http://www.cshs.ntpc.edu.tw"
    },
    {
      "代碼": "014343",
      "學校名稱": "市立安康高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區安興路25號",
      "電話": "(02)22111743",
      "網址": "http://www.akhs.ntpc.edu.tw"
    },
    {
      "代碼": "014347",
      "學校名稱": "市立雙溪高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[227]新北市雙溪區梅竹蹊3號",
      "電話": "(02)24931028",
      "網址": "http://www.sxhs.ntpc.edu.tw"
    },
    {
      "代碼": "014348",
      "學校名稱": "市立石碇高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[223]新北市石碇區隆盛里八分寮45號",
      "電話": "(02)26631224",
      "網址": "http://www.sdhs.ntpc.edu.tw"
    },
    {
      "代碼": "014353",
      "學校名稱": "市立丹鳳高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[242]新北市新莊區龍安路72號",
      "電話": "(02)29089627",
      "網址": "http://www.dfsh.ntpc.edu.tw/"
    },
    {
      "代碼": "014356",
      "學校名稱": "市立清水高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[236]新北市土城區明德路一段72號",
      "電話": "(02)22707801",
      "網址": "http://www.cssh.ntpc.edu.tw"
    },
    {
      "代碼": "014357",
      "學校名稱": "市立三民高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[247]新北市蘆洲區三民路96號",
      "電話": "(02)22894675",
      "網址": "http://www.smsh.ntpc.edu.tw"
    },
    {
      "代碼": "014362",
      "學校名稱": "市立錦和高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[235]新北市中和區錦和路163號",
      "電話": "(02)22498566",
      "網址": "http://www.jhsh.ntpc.edu.tw"
    },
    {
      "代碼": "014363",
      "學校名稱": "市立光復高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區光環路一段7號",
      "電話": "(02)29582366",
      "網址": "http://www.gfhs.ntpc.edu.tw/"
    },
    {
      "代碼": "014364",
      "學校名稱": "市立竹圍高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[251]新北市淡水區竹林路 35 號",
      "電話": "(02)28096990",
      "網址": "http://www.zwhs.ntpc.edu.tw/"
    },
    {
      "代碼": "014381",
      "學校名稱": "市立北大高級中學附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[237]新北市三峽區大義路277號",
      "電話": "(02)26742666 ",
      "網址": "http://www.bdsh.ntpc.edu.tw"
    },
    {
      "代碼": "014468",
      "學校名稱": "市立樟樹國際實中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[221]新北市汐止區樟樹二路135號",
      "電話": "(02)26430686",
      "網址": "http://www.ctjhs.ntpc.edu.tw/"
    },
    {
      "代碼": "313301",
      "學校名稱": "市立西松高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[31]臺北市",
      "地址": "[105]臺北市松山區鵬程里健康路325巷7號",
      "電話": "(02)25286618",
      "網址": "http://www.hssh.tp.edu.tw"
    },
    {
      "代碼": "313302",
      "學校名稱": "市立中崙高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[31]臺北市",
      "地址": "[105]臺北市松山區八德路四段101號",
      "電話": "(02)27535316",
      "網址": "http://www.zlsh.tp.edu.tw/"
    },
    {
      "代碼": "330301",
      "學校名稱": "國立師大附中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區和安里信義路3段143號",
      "電話": "(02)27075215",
      "網址": "http://www.hs.ntnu.edu.tw"
    },
    {
      "代碼": "331301",
      "學校名稱": "私立延平中學附設國中",
      "公/私立": "私立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區和安里建國南路1段275號",
      "電話": "(02)27071478",
      "網址": "http://www.yphs.tp.edu.tw"
    },
    {
      "代碼": "331304",
      "學校名稱": "私立復興實驗高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區敦化南路1段262 號",
      "電話": "(02)27715859",
      "網址": "http://www.fhjh.tp.edu.tw"
    },
    {
      "代碼": "333301",
      "學校名稱": "市立和平高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區芳和里臥龍街100號",
      "電話": "(02)27324300",
      "網址": "http://www.hpsh.tp.edu.tw"
    },
    {
      "代碼": "343302",
      "學校名稱": "市立大同高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[34]臺北市",
      "地址": "[104]臺北市中山區中央里長春路167號",
      "電話": "(02)25054269",
      "網址": "http://www.ttsh.tp.edu.tw"
    },
    {
      "代碼": "343303",
      "學校名稱": "市立大直高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[34]臺北市",
      "地址": "[104]臺北市中山區劍潭里北安路420號",
      "電話": "(02)25334017",
      "網址": "http://www.dcsh.tp.edu.tw"
    },
    {
      "代碼": "361301",
      "學校名稱": "私立靜修女中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[36]臺北市",
      "地址": "[103]臺北市大同區雙連里寧夏路59號",
      "電話": "(02)25574345",
      "網址": "http://www.bish.tp.edu.tw"
    },
    {
      "代碼": "363302",
      "學校名稱": "市立成淵高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[36]臺北市",
      "地址": "[103]臺北市大同區承德路二段235號",
      "電話": "(02)25531969",
      "網址": "http://www.cyhs.tp.edu.tw"
    },
    {
      "代碼": "373302",
      "學校名稱": "市立大理高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[37]臺北市",
      "地址": "[108]臺北市萬華區綠堤里長順街2號",
      "電話": "(02)23026959",
      "網址": "http://newweb.tlsh.tp.edu.tw/"
    },
    {
      "代碼": "380301",
      "學校名稱": "國立政大附中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區政大一街353號",
      "電話": "(02)82377500",
      "網址": "http://www.chahs.nccu.edu.tw"
    },
    {
      "代碼": "381301",
      "學校名稱": "私立東山高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區老泉里老泉街26巷3號",
      "電話": "(02)29395826",
      "網址": "http://www.tshs.tp.edu.tw"
    },
    {
      "代碼": "381304",
      "學校名稱": "私立再興中學附設國中",
      "公/私立": "私立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區明義里興隆路4段2號",
      "電話": "(02)29366803",
      "網址": "http://www.thsh.tp.edu.tw"
    },
    {
      "代碼": "381305",
      "學校名稱": "私立景文高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區木新里保儀路127號",
      "電話": "(02)29390310",
      "網址": "http://www.jwsh.tp.edu.tw"
    },
    {
      "代碼": "381306",
      "學校名稱": "臺北市靜心高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區興隆路二段46號",
      "電話": "(02)29323118",
      "網址": "http://www.chjhs.tp.edu.tw/dispPageBox/MainHp.aspx?ddsPageID=MAINHP"
    },
    {
      "代碼": "383302",
      "學校名稱": "市立萬芳高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區興隆路3段115巷1號",
      "電話": "(02)22309585",
      "網址": "http://www.wfsh.tp.edu.tw"
    },
    {
      "代碼": "393301",
      "學校名稱": "市立南港高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[39]臺北市",
      "地址": "[115]臺北市南港區西新里向陽路21號",
      "電話": "(02)27837863",
      "網址": "http://www.nksh.tp.edu.tw"
    },
    {
      "代碼": "400144",
      "學校名稱": "國立臺灣戲曲學院附設國中",
      "公/私立": "公立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖路二段177號",
      "電話": "(02)27962666",
      "網址": "http://www.tcpa.edu.tw"
    },
    {
      "代碼": "401302",
      "學校名稱": "私立方濟中學附設國中",
      "公/私立": "私立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區紫星里成功路三段61號",
      "電話": "(02)27910278",
      "網址": "http://www.sfh.tp.edu.tw"
    },
    {
      "代碼": "401303",
      "學校名稱": "私立達人女中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區湖濱里內湖路2段314號",
      "電話": "(02)27956899",
      "網址": "http://www.trgsh.tp.edu.tw"
    },
    {
      "代碼": "411301",
      "學校名稱": "私立泰北高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區福林里福林路240號",
      "電話": "(02)28825560",
      "網址": "http://www.tpsh.tp.edu.tw"
    },
    {
      "代碼": "411302",
      "學校名稱": "私立衛理女中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區翠山里至善路2段321號",
      "電話": "(02)28411487",
      "網址": "http://www.wlgsh.tp.edu.tw"
    },
    {
      "代碼": "411303",
      "學校名稱": "私立華興中學附設國中",
      "公/私立": "私立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區芝山里仰德大道1段101號",
      "電話": "(02)28316834",
      "網址": "http://www.hhhs.tp.edu.tw"
    },
    {
      "代碼": "413301",
      "學校名稱": "市立陽明高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區後港里中正路510號",
      "電話": "(02)28316675",
      "網址": "http://www.ymsh.tp.edu.tw"
    },
    {
      "代碼": "413302",
      "學校名稱": "市立百齡高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區義信里承德路4段177號",
      "電話": "(02)28831568",
      "網址": "http://www.blsh.tp.edu.tw"
    },
    {
      "代碼": "421301",
      "學校名稱": "私立薇閣高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區中心里珠海路50號",
      "電話": "(02)28925332",
      "網址": "http://www.wghs.tp.edu.tw"
    },
    {
      "代碼": "421302",
      "學校名稱": "臺北市幼華高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區北投路二段55號",
      "電話": "(02)28921166",
      "網址": "http://www.shvs.tp.edu.tw"
    },
    {
      "代碼": "421303",
      "學校名稱": "臺北市私立奎山實驗高級中學附設國中",
      "公/私立": "私立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區榮華里明德路200號",
      "電話": "(02)28212009",
      "網址": "https://www.kshs.tp.edu.tw/"
    },
    {
      "代碼": "031301",
      "學校名稱": "私立漢英高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[325]桃園市龍潭區中正路佳安段446號",
      "電話": "(03)4712531",
      "網址": "http://www.hhs.tyc.edu.tw/"
    },
    {
      "代碼": "031310",
      "學校名稱": "私立六和高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[324]桃園市平鎮區陸光路180號",
      "電話": "(03)4204000",
      "網址": "http://www.lioho.tw/tw/"
    },
    {
      "代碼": "031311",
      "學校名稱": "桃園市復旦高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[324]桃園市平鎮區復旦路二段122號",
      "電話": "(03)4932476",
      "網址": "http://www.fths.tyc.edu.tw"
    },
    {
      "代碼": "031312",
      "學校名稱": "桃園市治平高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[326]桃園市楊梅區埔心中興路137號",
      "電話": "(03)4823636",
      "網址": "http://www.cpshs.tyc.edu.tw/home"
    },
    {
      "代碼": "031313",
      "學校名稱": "桃園市振聲高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區復興路439號",
      "電話": "(03)3322605 ",
      "網址": "http://www.fxsh.tyc.edu.tw"
    },
    {
      "代碼": "031319",
      "學校名稱": "桃園市清華高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[327]桃園市新屋區中華路658號",
      "電話": "(03)4771196",
      "網址": "http://www.chvs.tyc.edu.tw"
    },
    {
      "代碼": "031320",
      "學校名稱": "桃園市新興高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[334]桃園市八德區永豐路563號",
      "電話": "(03)3796996",
      "網址": "http://www.hshs.tyc.edu.tw"
    },
    {
      "代碼": "031326",
      "學校名稱": "私立大華高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[326]桃園市楊梅區三民路二段200號",
      "電話": "(03)4820506",
      "網址": "http://www.thsh.tyc.edu.tw"
    },
    {
      "代碼": "034332",
      "學校名稱": "市立觀音高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[328]桃園市觀音區新坡里中山路2段519號",
      "電話": "(03)4981464",
      "網址": "http://www.gish.tyc.edu.tw"
    },
    {
      "代碼": "034335",
      "學校名稱": "市立新屋高級中等學校附設國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[327]桃園市新屋區中興路111號",
      "電話": "(03)4772029",
      "網址": "http://www.swjh.tyc.edu.tw/"
    },
    {
      "代碼": "034347",
      "學校名稱": "市立永豐高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[334]桃園市八德區永豐路609號",
      "電話": "(03)3692679",
      "網址": "http://www.yfms.tyc.edu.tw"
    },
    {
      "代碼": "060323",
      "學校名稱": "國立中科實驗高級中學附設國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[428]臺中市大雅區平和路227號",
      "電話": "(04)25686850",
      "網址": "http://www.nehs.tc.edu.tw"
    },
    {
      "代碼": "061301",
      "學校名稱": "財團法人常春藤高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[427]臺中市潭子區潭興路一段165巷320號",
      "電話": "(04)25395066",
      "網址": "http://www.ivyjhs.tc.edu.tw"
    },
    {
      "代碼": "061310",
      "學校名稱": "私立大明高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區新仁路三段210號",
      "電話": "(04)24821027",
      "網址": "http://www.tmsh.tc.edu.tw"
    },
    {
      "代碼": "061313",
      "學校名稱": "私立明道高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[414]臺中市烏日區中山路一段497號",
      "電話": "(04)23372101",
      "網址": "http://www.mingdao.edu.tw"
    },
    {
      "代碼": "061314",
      "學校名稱": "私立僑泰高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區樹王路342號",
      "電話": "(04)24063936",
      "網址": "http://www.ctas.tc.edu.tw"
    },
    {
      "代碼": "061315",
      "學校名稱": "私立華盛頓高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[411]臺中市太平區廍仔坑路26號(原弘仁)",
      "電話": "(04)23934712",
      "網址": "http://www.whs.tc.edu.tw"
    },
    {
      "代碼": "061317",
      "學校名稱": "私立弘文高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[427]臺中市潭子區弘文街100號",
      "電話": "(04)25340011",
      "網址": "http://www.hwhs.tc.edu.tw"
    },
    {
      "代碼": "061318",
      "學校名稱": "私立立人高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區中興路二段380號",
      "電話": "(04)24834138",
      "網址": "http://www.lzsh.tc.edu.tw"
    },
    {
      "代碼": "061322",
      "學校名稱": "華德福大地實驗學校附設國中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[414]臺中市烏日區公園路36號",
      "電話": "(04)23377520",
      "網址": "http://www.motherearth.com.tw/"
    },
    {
      "代碼": "064308",
      "學校名稱": "市立后綜高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[421]臺中市后里區廣福里三豐路三段968號",
      "電話": "(04):25562012",
      "網址": "http://www.hzsh.tc.edu.tw"
    },
    {
      "代碼": "064324",
      "學校名稱": "市立大里高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區國中路365號",
      "電話": "(04)24067870",
      "網址": "http://www.dlsh.tc.edu.tw"
    },
    {
      "代碼": "064328",
      "學校名稱": "市立新社高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[426]臺中市新社區復盛里中和街三段國中巷10號",
      "電話": "(04)25812116",
      "網址": "http://www.sshs.tc.edu.tw"
    },
    {
      "代碼": "064336",
      "學校名稱": "市立長億高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[411]臺中市太平區長億里長億六街1號",
      "電話": "(04)22799267",
      "網址": "http://www.cyhs.tc.edu.tw"
    },
    {
      "代碼": "064342",
      "學校名稱": "市立中港高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[435]臺中市梧棲區文昌路400號",
      "電話": "(04)26578270",
      "網址": "http://www.cgsh.tc.edu.tw"
    },
    {
      "代碼": "064350",
      "學校名稱": "市立龍津高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[434]臺中市龍井區三港路130號",
      "電話": "(04)26304536",
      "網址": "http://www.ljjhs.tc.edu.tw/home"
    },
    {
      "代碼": "191301",
      "學校名稱": "私立東大附中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[407]臺中市西屯區臺灣大道四段1727號",
      "電話": "(04)23590269",
      "網址": "http://www.hn.thu.edu.tw"
    },
    {
      "代碼": "191302",
      "學校名稱": "私立葳格高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[406]臺中市北屯區軍福十八路328號",
      "電話": "(04)24371728",
      "網址": "https://senior.wagor.tc.edu.tw/"
    },
    {
      "代碼": "191305",
      "學校名稱": "私立新民高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[404]臺中市北區健行路111號",
      "電話": "(04)22334105",
      "網址": "http://www.shinmin.tc.edu.tw"
    },
    {
      "代碼": "191309",
      "學校名稱": "私立明德高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[402]臺中市南區明德街84號",
      "電話": "(04)22877676",
      "網址": "http://www.mdhs.tc.edu.tw"
    },
    {
      "代碼": "191311",
      "學校名稱": "私立衛道高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[406]臺中市北屯區四平路161號",
      "電話": "(04)22911187",
      "網址": "http://www.vtsh.tc.edu.tw"
    },
    {
      "代碼": "191313",
      "學校名稱": "私立曉明女中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[404]臺中市北區中清路一段606號",
      "電話": "(04)22921175",
      "網址": "http://www.smgsh.tc.edu.tw"
    },
    {
      "代碼": "191314",
      "學校名稱": "私立嶺東高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[408]臺中市南屯區春社里嶺東路2號",
      "電話": "(04)23898940",
      "網址": "http://www.lths.tc.edu.tw"
    },
    {
      "代碼": "191315",
      "學校名稱": "私立磊川華德福實驗教育學校附設國中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[406]臺中市北屯區松竹五路233號及366號",
      "電話": "(04)24350110",
      "網址": "http://www.leichuan.tc.edu.tw/"
    },
    {
      "代碼": "193303",
      "學校名稱": "市立忠明高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[403]臺中市西區博館路166號",
      "電話": "(04)23224690",
      "網址": "http://www3.cmsh.tc.edu.tw"
    },
    {
      "代碼": "193313",
      "學校名稱": "市立西苑高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[407]臺中市西屯區西苑路268號",
      "電話": "(04)27016473",
      "網址": "http://www.sysh.tc.edu.tw"
    },
    {
      "代碼": "193315",
      "學校名稱": "市立東山高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[406]臺中市北屯區景賢六路200號",
      "電話": "(04)24360166",
      "網址": "http://www.tsjh.tc.edu.tw"
    },
    {
      "代碼": "193316",
      "學校名稱": "市立惠文高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[408]臺中市南屯區公益路二段298號",
      "電話": "(04)22503928",
      "網址": "http://www.hwsh.tc.edu.tw"
    },
    {
      "代碼": "110328",
      "學校名稱": "國立南科國際實驗高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[744]臺南市新市區三舍里大順6路12巷6號",
      "電話": "(06)5052916",
      "網址": "http://www.nnkieh.tn.edu.tw"
    },
    {
      "代碼": "111313",
      "學校名稱": "私立南光高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[730]臺南市新營區中興路62號",
      "電話": "(06)6335408",
      "網址": "http://www.nkhs.tn.edu.tw"
    },
    {
      "代碼": "111318",
      "學校名稱": "私立鳳和高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[736]臺南市柳營區中山東路二段1330號",
      "電話": "(06)6223208",
      "網址": "http://www.fhsh.tn.edu.tw/"
    },
    {
      "代碼": "111320",
      "學校名稱": "私立港明高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[723]臺南市西港區慶安路229號",
      "電話": "(06)7952025",
      "網址": "http://www.kmsh.tn.edu.tw"
    },
    {
      "代碼": "111321",
      "學校名稱": "臺南市興國高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[730]臺南市新營區大同路86號",
      "電話": "(06)6352201",
      "網址": "http://www.hkhs.tn.edu.tw/"
    },
    {
      "代碼": "111322",
      "學校名稱": "私立明達高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[737]臺南市鹽水區橋南里忠孝路73號",
      "電話": "(06)6521178",
      "網址": "http://www.mdsh.tn.edu.tw"
    },
    {
      "代碼": "111323",
      "學校名稱": "私立黎明高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[721]臺南市麻豆區井東里磚子井141號",
      "電話": "(06)5717123",
      "網址": "http://www.lmsh.tn.edu.tw"
    },
    {
      "代碼": "114306",
      "學校名稱": "市立大灣高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[710]臺南市永康區文賢街68巷1號",
      "電話": "(06)2714223",
      "網址": "http://www.dwhs.tn.edu.tw/"
    },
    {
      "代碼": "114307",
      "學校名稱": "市立永仁高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[710]臺南市永康區忠孝路74號",
      "電話": "(06)3115538",
      "網址": "http://web.yrhs.tn.edu.tw/bin/home.php"
    },
    {
      "代碼": "211301",
      "學校名稱": "私立長榮高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區林森路二段79號",
      "電話": "(06)2381711",
      "網址": "http://www.cjshs.tn.edu.tw"
    },
    {
      "代碼": "211304",
      "學校名稱": "財團法人聖功女中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[704]臺南市北區北園街87巷64號",
      "電話": "(06)2740126",
      "網址": "http://www.skgsh.tn.edu.tw"
    },
    {
      "代碼": "211310",
      "學校名稱": "臺南市光華高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區勝利路41號",
      "電話": "(06)2386501",
      "網址": "http://www.khgs.tn.edu.tw"
    },
    {
      "代碼": "211315",
      "學校名稱": "私立瀛海高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[709]臺南市安南區長溪路一段76號",
      "電話": "(06)2568582",
      "網址": "http://www.yhsh.tn.edu.tw"
    },
    {
      "代碼": "211317",
      "學校名稱": "私立崑山高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[704]臺南市北區開元路444號",
      "電話": "(06)2364408",
      "網址": "http://www.kssh.tn.edu.tw/bin/home.php"
    },
    {
      "代碼": "211318",
      "學校名稱": "私立德光高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區德光街106號",
      "電話": "(06)2894560",
      "網址": "http://www.tkgsh.tn.edu.tw"
    },
    {
      "代碼": "211320",
      "學校名稱": "財團法人慈濟高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[708]臺南市安平區建平五街111號",
      "電話": "(06)2932323",
      "網址": "http://www.tcsh.tn.edu.tw"
    },
    {
      "代碼": "213303",
      "學校名稱": "市立南寧高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[702]臺南市南區萬年路167號",
      "電話": "(06)2622458",
      "網址": "http://www.nnjh.tn.edu.tw"
    },
    {
      "代碼": "213316",
      "學校名稱": "市立土城高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[709]臺南市安南區安中路五段265號",
      "電話": "(06)2577014",
      "網址": "http://www.tcjh.tn.edu.tw"
    },
    {
      "代碼": "121307",
      "學校名稱": "財團法人普門中學附設國中",
      "公/私立": "私立",
      "縣市名稱": "[12]高雄市",
      "地址": "[840]高雄市大樹區大坑路140-11號",
      "電話": "(07)6562676",
      "網址": "http://www.pmsh.khc.edu.tw"
    },
    {
      "代碼": "121318",
      "學校名稱": "私立正義高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區南江街183號",
      "電話": "(07)7225529",
      "網址": "http://www.cysh.khc.edu.tw"
    },
    {
      "代碼": "121320",
      "學校名稱": "私立義大國際高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[12]高雄市",
      "地址": "[840]高雄市大樹區學城路1段6號",
      "電話": "(07)6577115 ",
      "網址": "https://www.iis.kh.edu.tw/tw"
    },
    {
      "代碼": "124302",
      "學校名稱": "市立文山高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[833]高雄市鳥松區大埤路31號",
      "電話": "(07)7777272",
      "網址": "http://www.wsm.ks.edu.tw"
    },
    {
      "代碼": "124311",
      "學校名稱": "市立林園高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[832]高雄市林園區林園北路481號",
      "電話": "(07)6412059",
      "網址": "http://www.ly.ks.edu.tw"
    },
    {
      "代碼": "124313",
      "學校名稱": "市立仁武高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[814]高雄市仁武區仁林路20號",
      "電話": "(07)3721640",
      "網址": "http://www.rwm.ks.edu.tw"
    },
    {
      "代碼": "124322",
      "學校名稱": "市立路竹高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[821]高雄市路竹區中華路292號",
      "電話": "(07)6963008",
      "網址": "http://www.lchs.ks.edu.tw"
    },
    {
      "代碼": "124333",
      "學校名稱": "市立六龜高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[844]高雄市六龜區義寶里光復路212號",
      "電話": "(07)6891023",
      "網址": "http://www.lgm.ks.edu.tw"
    },
    {
      "代碼": "124340",
      "學校名稱": "市立福誠高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區五甲三路176號",
      "電話": "(07)8224646",
      "網址": "http://www.ftm.ks.edu.tw"
    },
    {
      "代碼": "521301",
      "學校名稱": "私立明誠高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[52]高雄市",
      "地址": "[804]高雄市鼓山區中華一路97號",
      "電話": "(07)5521593",
      "網址": "http://www.mcsh.kh.edu.tw"
    },
    {
      "代碼": "521303",
      "學校名稱": "私立大榮高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[52]高雄市",
      "地址": "[804]高雄市鼓山區大榮街1號",
      "電話": "(07)5613281",
      "網址": "http://www.dystcs.kh.edu.tw"
    },
    {
      "代碼": "523301",
      "學校名稱": "市立鼓山高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[52]高雄市",
      "地址": "[804]高雄市鼓山區明德路2號",
      "電話": "(07)5213258",
      "網址": "http://www.kusjh.kh.edu.tw"
    },
    {
      "代碼": "540301",
      "學校名稱": "國立中山大學附屬國光高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[54]高雄市",
      "地址": "[811]高雄市楠梓區後昌路512號",
      "電話": "(07)3603600",
      "網址": "http://www.kksh.kh.edu.tw"
    },
    {
      "代碼": "551301",
      "學校名稱": "私立立志高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[55]高雄市",
      "地址": "[807]高雄市三民區立志街42號",
      "電話": "(07)3922601",
      "網址": "http://www.lcvs.kh.edu.tw"
    },
    {
      "代碼": "551303",
      "學校名稱": "南海月光實驗學校附設國中",
      "公/私立": "私立",
      "縣市名稱": "[55]高雄市",
      "地址": "[807]高雄市三民區大中一路110巷52弄15號",
      "電話": "(07)3458068",
      "網址": "https://rise-education2018.webnode.tw/"
    },
    {
      "代碼": "563301",
      "學校名稱": "市立新興高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[56]高雄市",
      "地址": "[800]高雄市新興區五福二路218號",
      "電話": "(07)2727127",
      "網址": "http://www.hhhs.kh.edu.tw"
    },
    {
      "代碼": "580301",
      "學校名稱": "國立高師大附中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區凱旋二路89號",
      "電話": "(07)7613875",
      "網址": "http://www.nknush.kh.edu.tw"
    },
    {
      "代碼": "581301",
      "學校名稱": "私立復華高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區民權一路42號",
      "電話": "(07)3344168   ",
      "網址": "http://www.fhhs.kh.edu.tw"
    },
    {
      "代碼": "581302",
      "學校名稱": "天主教道明中學附設國中",
      "公/私立": "私立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區建國一路354號",
      "電話": "(07)2240711",
      "網址": "http://www.dmhs.kh.edu.tw"
    },
    {
      "代碼": "583301",
      "學校名稱": "市立中正高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區中正一路8號",
      "電話": "(07)7491992",
      "網址": "http://www.cchs.kh.edu.tw"
    },
    {
      "代碼": "593302",
      "學校名稱": "市立瑞祥高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[59]高雄市",
      "地址": "[806]高雄市前鎮區班超路63號",
      "電話": "(07)8152271",
      "網址": "http://www.rssh.kh.edu.tw"
    },
    {
      "代碼": "610405",
      "學校名稱": "國立高餐大附屬餐旅中學附設國中",
      "公/私立": "公立",
      "縣市名稱": "[61]高雄市",
      "地址": "[812]高雄市小港區松和路40號",
      "電話": "(07)8060705",
      "網址": "http://nkhhs.nkuht.edu.tw/"
    },
    {
      "代碼": "021301",
      "學校名稱": "私立慧燈高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[264]宜蘭縣員山鄉枕山村枕山路117-60號",
      "電話": "(03)9229968",
      "網址": "http://www.hdsh.ilc.edu.tw"
    },
    {
      "代碼": "021310",
      "學校名稱": "私立中道高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[263]宜蘭縣壯圍鄉功勞村中央路三段312巷7號",
      "電話": "(03)9306696",
      "網址": "http://www.cdsh.ilc.edu.tw"
    },
    {
      "代碼": "024322",
      "學校名稱": "縣立南澳高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[272]宜蘭縣南澳鄉蘇花路二段301號",
      "電話": "(03)9981024",
      "網址": "http://www.nojh.ilc.edu.tw"
    },
    {
      "代碼": "024325",
      "學校名稱": "縣立慈心華德福實中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[269]宜蘭縣冬山鄉照安路257號",
      "電話": "(039)596222",
      "網址": "http://blog.ilc.edu.tw/blog/blog/25783"
    },
    {
      "代碼": "041303",
      "學校名稱": "私立義民高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[302]新竹縣竹北市中正西路15號",
      "電話": "(03)5552020",
      "網址": "http://www.ymsh.hcc.edu.tw"
    },
    {
      "代碼": "041305",
      "學校名稱": "私立忠信高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[304]新竹縣新豐鄉忠信街178號",
      "電話": "(03)5595775 ",
      "網址": "http://www.chvs.hcc.edu.tw"
    },
    {
      "代碼": "041306",
      "學校名稱": "私立東泰高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[310]新竹縣竹東鎮東峰路343號",
      "電話": "(03)5961232",
      "網址": "http://www.ttsh.hcc.edu.tw"
    },
    {
      "代碼": "041307",
      "學校名稱": "私立仰德高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[304]新竹縣新豐鄉員山村133號",
      "電話": "(03)5592158",
      "網址": "http://www.ydvs.hcc.edu.tw"
    },
    {
      "代碼": "041401",
      "學校名稱": "私立內思高工附設國中",
      "公/私立": "私立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[305]新竹縣新埔鎮楊新路一段40號",
      "電話": "(03)5882520",
      "網址": "http://www.savs.hcc.edu.tw"
    },
    {
      "代碼": "044311",
      "學校名稱": "縣立六家高級中學附設國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[302]新竹縣竹北市嘉興路356號",
      "電話": "(03)5503824",
      "網址": "http://www.ljjh.hcc.edu.tw/bin/home.php"
    },
    {
      "代碼": "044320",
      "學校名稱": "縣立湖口高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[303]新竹縣湖口鄉中正路二段263巷21號",
      "電話": "(03)5690772",
      "網址": "http://www.hkhs.hcc.edu.tw"
    },
    {
      "代碼": "050314",
      "學校名稱": "國立卓蘭高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[369]苗栗縣卓蘭鎮老庄里161號",
      "電話": "(04)25892007",
      "網址": "http://w2.cles.mlc.edu.tw/"
    },
    {
      "代碼": "051302",
      "學校名稱": "私立君毅高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[350]苗栗縣竹南鎮公義路245號",
      "電話": "(037)622009",
      "網址": "http://www.cish.mlc.edu.tw"
    },
    {
      "代碼": "051306",
      "學校名稱": "私立建臺高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[360]苗栗縣苗栗市福麗里至公路251號",
      "電話": "(037)353270",
      "網址": "http://www.ctsh.mlc.edu.tw"
    },
    {
      "代碼": "051307",
      "學校名稱": "私立全人實驗高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[369]苗栗縣卓蘭鎮內灣里內灣141-3號",
      "電話": "(04)25896909",
      "網址": "http://holistic.org.tw/"
    },
    {
      "代碼": "054308",
      "學校名稱": "縣立三義高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[367]苗栗縣三義鄉廣盛村11鄰122號",
      "電話": "(037)872015",
      "網址": "http://web.sjh.mlc.edu.tw"
    },
    {
      "代碼": "054309",
      "學校名稱": "縣立苑裡高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[358]苗栗縣苑裡鎮客庄里1鄰初中路2號",
      "電話": "(037)861042",
      "網址": "http://www.yljh.mlc.edu.tw"
    },
    {
      "代碼": "054317",
      "學校名稱": "縣立興華高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[351]苗栗縣頭份市中正一路401號",
      "電話": "(037)663403",
      "網址": "http://www.shhs.mlc.edu.tw"
    },
    {
      "代碼": "054333",
      "學校名稱": "縣立大同高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[350]苗栗縣竹南鎮大埔里六鄰公義路890號",
      "電話": "(037)580566",
      "網址": "http://www.dtjh.mlc.edu.tw"
    },
    {
      "代碼": "071311",
      "學校名稱": "私立精誠高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市林森路200號",
      "電話": "(04)7622790",
      "網址": "http://www.cchs.chc.edu.tw"
    },
    {
      "代碼": "071317",
      "學校名稱": "私立文興高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[520]彰化縣田中鎮員集路三段93號",
      "電話": "(04)8753889 ",
      "網址": "http://web1.hshs.chc.edu.tw/bin/home.php"
    },
    {
      "代碼": "071318",
      "學校名稱": "財團法人正德高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市莿桐里彰水路145號",
      "電話": "(04)7524109",
      "網址": "http://www.zdvs.chc.edu.tw"
    },
    {
      "代碼": "074308",
      "學校名稱": "縣立彰化藝術高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市卦山路13號",
      "電話": "(04)7222844",
      "網址": "http://www.chash.chc.edu.tw"
    },
    {
      "代碼": "074313",
      "學校名稱": "縣立二林高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[526]彰化縣二林鎮中西里二城路6號",
      "電話": "(04)8960121",
      "網址": "http://www.elsh.chc.edu.tw"
    },
    {
      "代碼": "074323",
      "學校名稱": "縣立和美高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[508]彰化縣和美鎮西園路三十一號",
      "電話": "(04)7552043",
      "網址": "http://www.hmjh.chc.edu.tw/"
    },
    {
      "代碼": "074328",
      "學校名稱": "縣立田中高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[520]彰化縣田中鎮文化街23號",
      "電話": "(04)8745820",
      "網址": "http://www.tcjh.chc.edu.tw/ischool/publish_page/0/"
    },
    {
      "代碼": "074339",
      "學校名稱": "縣立成功高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[514]彰化縣溪湖鎮福德路310號",
      "電話": "(04)8819676",
      "網址": "http://www.ckjh.chc.edu.tw/"
    },
    {
      "代碼": "081312",
      "學校名稱": "私立三育高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[08]南投縣",
      "地址": "[555]南投縣魚池鄉瓊文巷39號",
      "電話": "(049)2897212",
      "網址": "http://www.taa.ntct.edu.tw"
    },
    {
      "代碼": "081313",
      "學校名稱": "私立弘明實驗高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[08]南投縣",
      "地址": "[551]南投縣名間鄉東湖村大老巷102號",
      "電話": "(049)2731799",
      "網址": "http://www.holdmean.org.tw"
    },
    {
      "代碼": "081314",
      "學校名稱": "私立普台高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[08]南投縣",
      "地址": "[545]南投縣埔里鎮一新里中台路5號",
      "電話": "(049)2932899",
      "網址": "http://www.ctworld.org.tw/ptshs/index.htm"
    },
    {
      "代碼": "084309",
      "學校名稱": "縣立旭光高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[542]南投縣草屯鎮中正路568-23號",
      "電話": "(049)2563472",
      "網址": "http://www.skjhs.ntct.edu.tw"
    },
    {
      "代碼": "091307",
      "學校名稱": "私立永年高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[633]雲林縣土庫鎮建國路13號",
      "電話": "(05)6622540",
      "網址": "http://www.ynhs.ylc.edu.tw"
    },
    {
      "代碼": "091308",
      "學校名稱": "私立正心高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[640]雲林縣斗六市虎溪里正心路1號",
      "電話": "(05)5512502",
      "網址": "http://www.shsh.ylc.edu.tw"
    },
    {
      "代碼": "091311",
      "學校名稱": "私立文生高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[654]雲林縣四湖鄉中正路509號",
      "電話": "(05)7872024",
      "網址": "http://www.svsh.ylc.edu.tw"
    },
    {
      "代碼": "091316",
      "學校名稱": "私立揚子高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[632]雲林縣虎尾鎮潁川里林森路二段541號",
      "電話": "(05)6330181",
      "網址": "http://www.ytjh.ylc.edu.tw"
    },
    {
      "代碼": "091319",
      "學校名稱": "私立福智高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[646]雲林縣古坑鄉麻園村平和23號",
      "電話": "(05)5828223",
      "網址": "http://bwsh.ylc.edu.tw"
    },
    {
      "代碼": "091320",
      "學校名稱": "雲林縣維多利亞實驗高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[640]雲林縣斗六市鎮南路1110號",
      "電話": "(05)5378899",
      "網址": "http://www.victoria.ylc.edu.tw"
    },
    {
      "代碼": "094301",
      "學校名稱": "縣立斗南高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[630]雲林縣斗南鎮中山路212號",
      "電話": "(05)5972059",
      "網址": "http://www.tnjh.ylc.edu.tw"
    },
    {
      "代碼": "094307",
      "學校名稱": "縣立麥寮高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[638]雲林縣麥寮鄉中興路310號",
      "電話": "(05)6932050",
      "網址": "http://www.mljh.ylc.edu.tw"
    },
    {
      "代碼": "094308",
      "學校名稱": "縣立古坑華德福實驗高級中學附設國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[646]雲林縣古坑鄉荷苞村山峰17號",
      "電話": "(05)5822075",
      "網址": "http://tw.school.uschoolnet.com/?id=es00003779"
    },
    {
      "代碼": "094326",
      "學校名稱": "縣立蔦松藝術高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[652]雲林縣水林鄉松北村1之1號",
      "電話": "(05)7841801",
      "網址": "http://www.nsjh.ylc.edu.tw/"
    },
    {
      "代碼": "101303",
      "學校名稱": "私立同濟高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[622]嘉義縣大林鎮中興路二段303號",
      "電話": "(05)2652248",
      "網址": "http://www.tjsh.cyc.edu.tw"
    },
    {
      "代碼": "101304",
      "學校名稱": "私立協同高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[621]嘉義縣民雄鄉建國路二段31號",
      "電話": "(05)2213045",
      "網址": "http://www.cmsh.cyc.edu.tw"
    },
    {
      "代碼": "104319",
      "學校名稱": "縣立竹崎高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[604]嘉義縣竹崎鄉竹崎村文化路23號",
      "電話": "(05)2611006",
      "網址": "http://www.ccjh.cyc.edu.tw"
    },
    {
      "代碼": "104326",
      "學校名稱": "縣立永慶高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[612]嘉義縣太保市信義二路1號",
      "電話": "(05)3627226",
      "網址": "http://www.ycsh.cyc.edu.tw"
    },
    {
      "代碼": "131308",
      "學校名稱": "私立陸興高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市豐田里廣東路150號",
      "電話": "(08)7225837",
      "網址": "http://www.lssh.ptc.edu.tw"
    },
    {
      "代碼": "131311",
      "學校名稱": "私立美和高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[912]屏東縣內埔鄉美和村學人路323號",
      "電話": "(08)7792045",
      "網址": "http://www.mhsh.ptc.edu.tw"
    },
    {
      "代碼": "134304",
      "學校名稱": "縣立大同高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市和平路429號",
      "電話": "(08)7663916",
      "網址": "http://www.dtjh.ptc.edu.tw"
    },
    {
      "代碼": "134321",
      "學校名稱": "縣立枋寮高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[940]屏東縣枋寮鄉新龍村義民路3號",
      "電話": "(08)8782095",
      "網址": "http://www.flhs.ptc.edu.tw"
    },
    {
      "代碼": "134324",
      "學校名稱": "縣立東港高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[928]屏東縣東港鎮船頭路1號",
      "電話": "(08)8322014",
      "網址": "http://www.dkjh.ptc.edu.tw"
    },
    {
      "代碼": "134334",
      "學校名稱": "縣立來義高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[922]屏東縣來義鄉古樓村中正路147號",
      "電話": "(08)7850086",
      "網址": "http://www.lyhs.ptc.edu.tw"
    },
    {
      "代碼": "140301",
      "學校名稱": "國立臺東大學附屬體育高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市體中路1號",
      "電話": "(089)383629",
      "網址": "http://www.ntpehs.ttct.edu.tw"
    },
    {
      "代碼": "141301",
      "學校名稱": "臺東縣均一高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市中興路二段366巷36號",
      "電話": "(089)223301",
      "網址": "http://junyi.tw"
    },
    {
      "代碼": "141307",
      "學校名稱": "私立育仁高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市中興路四段485號",
      "電話": "(08)9382839",
      "網址": "http://www.lotus.ttct.edu.tw"
    },
    {
      "代碼": "144322",
      "學校名稱": "縣立蘭嶼高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[952]臺東縣蘭嶼鄉椰油村37號",
      "電話": "(089)732016",
      "網址": "http://www.layjh.ttct.edu.tw"
    },
    {
      "代碼": "151306",
      "學校名稱": "私立海星高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[971]花蓮縣新城鄉嘉新村嘉新路36號",
      "電話": "(03)8223116",
      "網址": "http://www.smhs.hlc.edu.tw"
    },
    {
      "代碼": "151312",
      "學校名稱": "財團法人慈濟大學附中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[970]花蓮縣花蓮市介仁街178號",
      "電話": "(03)8572823",
      "網址": "http://www.tcsh.hlc.edu.tw"
    },
    {
      "代碼": "171306",
      "學校名稱": "私立二信高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[17]基隆市",
      "地址": "[202]基隆市中正區立德路243號",
      "電話": "(02)24623131",
      "網址": "http://www.essh.kl.edu.tw/essh/index.asp"
    },
    {
      "代碼": "171308",
      "學校名稱": "輔大聖心高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[17]基隆市",
      "地址": "[203]基隆市中山區西定路166號",
      "電話": "(02)24282454",
      "網址": "http://www.shsh.kl.edu.tw"
    },
    {
      "代碼": "173304",
      "學校名稱": "市立中山高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[203]基隆市中山區文化路162號",
      "電話": "(02)24248191",
      "網址": "http://www.csjh.kl.edu.tw"
    },
    {
      "代碼": "173306",
      "學校名稱": "市立安樂高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[204]基隆市安樂區安一路360號",
      "電話": "(02)24236600",
      "網址": "http://www.aljh.kl.edu.tw"
    },
    {
      "代碼": "173307",
      "學校名稱": "市立暖暖高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[205]基隆市暖暖區暖中路112號",
      "電話": "(02)24575534",
      "網址": "http://www.nnjh.kl.edu.tw"
    },
    {
      "代碼": "173314",
      "學校名稱": "市立八斗高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[202]基隆市中正區新豐街100號",
      "電話": "(02)24692366",
      "網址": "http://www.bdjh.kl.edu.tw"
    },
    {
      "代碼": "180301",
      "學校名稱": "國立科學工業園區實驗高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區介壽路300號",
      "電話": "(03)5777011",
      "網址": "http://www.nehs.hc.edu.tw"
    },
    {
      "代碼": "181305",
      "學校名稱": "私立光復高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區光復路二段153號",
      "電話": "(03)5753698",
      "網址": "http://www.kfsh.hc.edu.tw"
    },
    {
      "代碼": "181306",
      "學校名稱": "私立曙光女中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區北大路61號",
      "電話": "(03)5325709",
      "網址": "http://www.sggs.hc.edu.tw"
    },
    {
      "代碼": "181307",
      "學校名稱": "私立磐石高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市北區西大路683號",
      "電話": "(03)5223946",
      "網址": "http://www.sphs.hc.edu.tw"
    },
    {
      "代碼": "183306",
      "學校名稱": "市立成德高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市北區崧嶺路128巷38號",
      "電話": "(03)5258748",
      "網址": "http://www.cdjh.hc.edu.tw"
    },
    {
      "代碼": "183307",
      "學校名稱": "市立香山高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市香山區元培街124號",
      "電話": "(03)5384332",
      "網址": "http://www.hhjh.hc.edu.tw"
    },
    {
      "代碼": "183313",
      "學校名稱": "市立建功高中附設國中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區建功二路17號",
      "電話": "(03)5745892",
      "網址": "http://www.ckjh.hc.edu.tw"
    },
    {
      "代碼": "201304",
      "學校名稱": "私立興華高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區林森東路239號",
      "電話": "(05)2764317",
      "網址": "http://www.hhsh.cy.edu.tw"
    },
    {
      "代碼": "201310",
      "學校名稱": "私立嘉華高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區民權東路45號",
      "電話": "(05)2761716",
      "網址": "http://www.chsh.cy.edu.tw"
    },
    {
      "代碼": "201312",
      "學校名稱": "私立輔仁高中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區吳鳳南路270號",
      "電話": "(05)2281001",
      "網址": "http://www.fjsh.cy.edu.tw"
    },
    {
      "代碼": "201313",
      "學校名稱": "私立宏仁女中附設國中",
      "公/私立": "私立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區忠孝路667號",
      "電話": "(05)2322802",
      "網址": "http://www.hjgs.cy.edu.tw"
    }
  ]



export default {
  school,
}