import { prototype } from "react-fontawesome";

function ParamsStorer()
{
    var mData = {};
    var mNotifyFun = {};

    ParamsStorer.prototype.setData = function(key, data)
    {
        // store data
        mData[key] = data;
        // if callback function exists, call it
        if (mNotifyFun[key]) {
            var cbFuns = mNotifyFun[key];
            for (var i = 0 ; i < cbFuns.length ; i++) cbFuns[i](key, data);
        }
    }

    ParamsStorer.prototype.getData = function(key)
    {
        return mData[key];
    }

    ParamsStorer.prototype.addCallback = function(key, cbFun)
    {
        var cbFuns = mNotifyFun[key] || [];
        for (var i = 0 ; i < cbFuns.length ; i++) {
            if (cbFuns[i] == cbFun) return;
        }
        cbFuns.push(cbFun);
        mNotifyFun[key] = cbFuns;
    }

    ParamsStorer.prototype.removeCallback = function(key, cbFun)
    {
        var cbFuns = mNotifyFun[key] || [];
        for (var i = 0 ; i < cbFuns.length ; i++) {
            if (cbFuns[i] == cbFun) {
                cbFuns.splice(i, 1);
                return;
            }
        }
        mNotifyFun[key] = cbFuns;
    }
}

var oInstance = null;
export default {
    Instance: function() {
        if (oInstance == null) oInstance = new ParamsStorer();
        return oInstance;
    }
};