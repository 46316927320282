import { timeFormatTohhmmss } from "util/timeFormater";
import { searchInfo } from "util/studentsSearchFilter";
import {
  SECOND_TAB_NAME,
  exportUsersExcelHeaders,
} from "constants/studentUserInfoTypes";
import StudentSearchLoading from "components/Loading/StudentsSearchLoading"
import { useStore, StoreTypes } from "context";
import { useState,useEffect } from "react";
import {Paper,Table,Box,TableBody,TableCell,TableContainer,TablePagination,TableHead,TableRow} from '@mui/material';
import {ArrowDownward,ArrowUpward} from '@mui/icons-material';

const tableTitleCellStyle = {
  color: '#637381',
  borderBottom: 'none'
}
const tableCellStyle = {
  borderBottom: 'none'
}

const pageStyle = {
  '.css-levciy-MuiTablePagination-displayedRows': {
    margin: '0 auto'
  }
}

const UserTable = (props) => {
  const [{ educationInfo: allEducationInfo }] = useStore(StoreTypes.global);
  const { secondTab, info,sort,setSort } = props;

  const BASE_FRONT_HEADERS = [
    {title:info.productName,property:"productName"},
    {title:info.schoolCounty,property:"schoolCounty"},
  ]

  const BASE_AFTER_HEADER = [
    {title:info.userCount,property:"userCount"},
    {title:info.videoNum,property:"videoNum"},
    {title:info.watchMinute,property:"watchMinute"},
    {title:info.testNum,property:"testNum"},
    {title:info.score,property:"score"},
  ]

  const HEADERS ={
    [SECOND_TAB_NAME.PRODUCT_COUNTY.key]:[
      ...BASE_FRONT_HEADERS,
      ...BASE_AFTER_HEADER,

    ],
    [SECOND_TAB_NAME.PRODUCT_SCHOOL.key]:[
      ...BASE_FRONT_HEADERS,
      {title:info.school,property:"schoolName"},
      ...BASE_AFTER_HEADER,
    ]
  }

  return <TableRow>
  {HEADERS[secondTab].map(item => 
  <TableCell sx={tableTitleCellStyle} key={item.title}>
    <Box sx={{display:"flex",minWidth:"fit-content",whiteSpace:'nowrap'}}>{item.title}
    <Box sx={{cursor:'pointer',display:"flex",alignItems:"center"}}
      onClick={()=>{if (sort.property === item.property){
        setSort(prev => ({...prev, order: prev.order === '+' ? '-':'+'}))
      } else {
        setSort({order:'+',property:item.property})
      }
    }}>
      {item.property &&(( sort.property === item.property) ? sort.order === '+' ? 
      <ArrowDownward sx={{fontSize:"1rem"}}/>:
      <ArrowUpward sx={{fontSize:"1rem"}}/> :
      <ArrowUpward sx={{color:"#DCE0E4",fontSize:"1rem"}}/>)}
    </Box>
    </Box>
  </TableCell>)}
</TableRow>
};

const productCodeTrans = (productCode, secondTab, allEducationInfo) => {
  if (secondTab === SECOND_TAB_NAME.VIDEO_RECORD.key) {
    for (const key in allEducationInfo) {
      for (const key2 in allEducationInfo[key]) {
        const obj = allEducationInfo[key][key2].find(info => info.productcode === productCode)
        return obj && obj.product
      }
    }
  }
}

const setTableContent = ({ studentsInfo, secondTab, allEducationInfo }) => {
  return studentsInfo.map((info) => (
    <>
      {
        secondTab === SECOND_TAB_NAME.PRODUCT_COUNTY.key && (
          <TableRow
            key={info.schoolName}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell sx={tableCellStyle}>{info.productName ? info.productName : "-"}</TableCell>
            <TableCell sx={tableCellStyle}>{(info.schoolCounty && info.schoolCounty !== 'undefined') ? info.schoolCounty : "-"}</TableCell>
            <TableCell sx={tableCellStyle} >{info.userCount ? info.userCount : "0"}</TableCell>
            <TableCell sx={tableCellStyle} >{info.videoNum ? info.videoNum : "0"}</TableCell>
            <TableCell sx={tableCellStyle} >
              {`${timeFormatTohhmmss(info.watchMinute ? info.watchMinute : 0)}`}
            </TableCell>
            <TableCell sx={tableCellStyle} >{info.testNum ? info.testNum : "0"}</TableCell>
            <TableCell sx={tableCellStyle}>{info.score ? `${info.score.toFixed(1)}分` : "0"}</TableCell>
          </TableRow>
        )
      }
      {
        secondTab === SECOND_TAB_NAME.PRODUCT_SCHOOL.key && (
          <TableRow
            key={info.schoolName}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell sx={tableCellStyle}>{info.productName ? info.productName : "-"}</TableCell>
            <TableCell sx={tableCellStyle}>{info.schoolCounty ? info.schoolCounty : "-"}</TableCell>
            <TableCell sx={tableCellStyle}>{info.schoolName ? info.schoolName : "-"}</TableCell>
            <TableCell sx={tableCellStyle} >{info.userCount ? info.userCount : "0"}</TableCell>
            <TableCell sx={tableCellStyle} >{info.videoNum ? info.videoNum : "0"}</TableCell>
            <TableCell sx={tableCellStyle} >
              {`${timeFormatTohhmmss(info.watchMinute ? info.watchMinute : 0)}`}
            </TableCell>
            <TableCell sx={tableCellStyle} >{info.testNum ? info.testNum : "0"}</TableCell>
            <TableCell sx={tableCellStyle}>{info.score ? `${info.score.toFixed(1)}分` : "0"}</TableCell>
          </TableRow>
        )
      }
    </>
  ))
}

const ProductTable = ({ searchType, searchKey, currentTab, secondTab, studentsInfo, schoolName, userInfo = null ,loading,loadingMessage}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort,setSort] = useState({order:'+',property:"productName"})
  let tableHeaders = {};
  const targetKey = Object.keys(exportUsersExcelHeaders).find(key => exportUsersExcelHeaders[key].tabType === secondTab);
  tableHeaders = exportUsersExcelHeaders[targetKey].headers

  let userInfos = []
  if (studentsInfo.users) {
    userInfos = userInfos.concat(studentsInfo.users)
    userInfos = searchInfo(userInfos, searchType, searchKey)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(()=>{
    setSort({order:'+',property:"productName"})
  },[secondTab])

  return (
    <>
      {loading ? <StudentSearchLoading message={loadingMessage} /> :
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ background: "#F4F6F8", borderRadius: '8px' }}>
              {
                tableHeaders && UserTable({ info: tableHeaders, secondTab,sort,setSort })
              }
            </TableHead>
            <TableBody>
              {
                userInfos && setTableContent({
                  studentsInfo: userInfos.sort((a,b) => {
                    
                      const propertyA = a[`${sort.property}`]
                      const propertyB = b[`${sort.property}`]
                      if (typeof propertyA === 'number' && typeof propertyB === 'number'){
                        return sort.order === '+' ? propertyA- propertyB : propertyB -propertyA
                      }else {
                        if (propertyA && propertyB){
                          return sort.order === '+' ? propertyA.localeCompare(propertyB) : propertyB.localeCompare(propertyA)
                        } else if (propertyA && !propertyB){
                          return sort.order === '+' ? -1: 1
                        } else if (!propertyA && propertyB){
                          return sort.order === '+' ? 1: -1
                        } else {
                          return 0
                        }
                      }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
                  secondTab,
                  productCodeTrans
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      }
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={userInfos.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={""}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={pageStyle}
      />
    </>

  );
};

export default ProductTable;
