import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import Header from "components/ChiayiMainPage/Header";
// import { Beforeunload } from 'components/Beforeunload';
import * as actionTypes from "constants/actionTypes";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

const MainPage = (props) => {
  const [{ userId }, userDispatch] = useStore(StoreTypes.user);
  const history = useHistory();

  const getCookie=(name)=> {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  useEffect(() => {
    let redirect = getCookie("redirect");
    redirect && history.push(redirect);
  }, [history]);

  const [moeUrl, setMoeUrl] = useState("");
  // 打這個 api 拿到可以前往教育雲登入的 url
  useEffect(() => {
    fetch(
      `https://zib0bugold.execute-api.ap-northeast-1.amazonaws.com/prod/users/createOrgLoginForMoe`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clientid: process.env.REACT_APP_CLIENT_ID,
          params: "",
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        setMoeUrl(res.data.url);
      })
      .catch((err) => console.error(err));
  }, []);

  // const [moeLoginCodeState, setMoeLoginCodeState] = useState({});
  // useEffect(() => {
  // 	if (props.location.state) {
  // 		const path = props.location.state;
  // 		const codeStart = path.indexOf('=') + 1;
  // 		const codeEnd = path.indexOf('&');
  // 		const stateStart = path.lastIndexOf('=') + 1;
  // 		setMoeLoginCodeState({
  // 			code: path.slice(codeStart, codeEnd),
  // 			state: path.slice(stateStart)
  // 		});
  // 	}
  // 	window.history.replaceState({}, '')
  // }, [props.location.state])

  // const [isLoading, setIsLoading] = useState(false);
  // function setYunlinStudyCookie(cname, cvalue, exdays) {
  // 	var d = new Date();
  // 	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  // 	var expires = "expires=" + d.toUTCString();
  // 	var hostname = window.location.hostname;
  // 	if (hostname.indexOf("oneclass.com.tw") > 0) {
  // 		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=oneclass.com.tw";
  // 	}
  // 	else {
  // 		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  // 	}
  // }
  // useEffect(() => {
  // 	if (Object.keys(moeLoginCodeState).length === 0) return;
  // 	setIsLoading(true);
  // 	fetch(`${process.env.REACT_APP_MOELOGIN_DOMAIN}/prod/users/moeLoginV1`, {
  // 		headers: { "Content-type": "application/json" },
  // 		method: 'POST',
  // 		body: JSON.stringify({
  // 			code: moeLoginCodeState.code,
  // 			state: moeLoginCodeState.state
  // 		})
  // 	})
  // 		.then(res => res.json())
  // 		.then(res => {
  // 			console.log(res)
  // 			if (!res.status) {return};
  // 			const cookieToken = res.data.token;

  // 			const saveToken = {
  // 				"code": "SUCCESS",
  // 				"jwt": cookieToken
  // 			}

  // 			setYunlinStudyCookie('nani_oneclass_login_token', JSON.stringify(saveToken));
  // 			let education;
  // 			if (res.data.userProfile.grade > 9) {
  // 				education = "高中";
  // 			} else if (res.data.userProfile.grade < 7) {
  // 				education = "國小";
  // 			} else {
  // 				education = "國中";
  // 			}
  // 			userDispatch({
  // 				type: actionTypes.SET_USER_INFO,
  // 				userId: res.data.userProfile.username,
  // 				token: cookieToken,
  // 				name: res.data.userProfile.name,
  // 				login: true,
  // 				isLogin: true,
  // 				isTeacher: res.data.userProfile.isTeacher,
  // 				education,
  // 				// product: response.product,
  // 			});
  // 			setIsLoading(false);
  // 		})
  // 		.catch(err => console.log(err))
  // }, [userDispatch, moeLoginCodeState]);

  return (
    // <Beforeunload>
    <div>
      <Header />
      <main className={styles.mainPage}>
        <img
          className={styles.mainbg}
          src={"./assets/img/chiayistudy/chiayistudyBG.png"}
          alt="bg"
        ></img>
        <section className={styles.loginArea}>
          {userId.length === 0 ? (
            <>
              <h2>歡迎</h2>
              <h1>請使用教育雲帳號登入</h1>
              <a
                className={classnames(styles.loginBTN, {
                  [styles.disable]: userId.length > 0,
                })}
                href={moeUrl}
              >
                <img
                  src={"./assets/img/yunlinstudy/moe.png"}
                  alt="moelogin"
                ></img>
                教育雲端帳號
              </a>
            </>
          ) : (
            <>
              <h2>歡迎使用OneStudy</h2>
              <h1>可至上方「選擇科目」觀看課程並進行測驗</h1>
            </>
          )}
          <hr
            style={{
              height: "1px",
              opacity: "0.24",
              width: "85%",
              marginTop: "40px",
            }}
          />
          <p style={{ textAlign: "center" }}>
            <span
              style={{ color: "#242C3F", fontSize: "11px", fontWeight: "400" }}
            >
              依照學年度開通以下品項 (未開通品項僅供試用內容)
            </span>
            <br />
            <span
              style={{ color: "#8B90A0", fontSize: "11px", fontWeight: "400" }}
            >
              ・國小學生：數學、國語科
            </span>
            <span
              style={{
                color: "#8B90A0",
                fontSize: "11px",
                fontWeight: "400",
                marginLeft: "24px",
              }}
            >
              ・國中學生：國英數自社5科
            </span>
          </p>
        </section>
      </main>
    </div>
    // </Beforeunload>
  );
};
export default MainPage;
