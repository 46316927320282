export const everyPageNumData = [100, 50, 10];

export const TAB_NAME={
  STUDENT_RECORD:'學生個人紀錄',
  ORGANIZATION_RECORD:'學校使用紀錄',
  PRODUCT_RECORD:'產品使用紀錄',
}

export const SECOND_TAB_NAME={
  ALL:{
    name:'概覽',//顯示名稱
    key:'概覽',//對應屬性
    type:"ALL"//API 參數 目前只有學生個人紀錄會使用到
  },
  VIDEO_RECORD:{
    name:'影片觀看紀錄',
    key:'影片觀看紀錄',
    type:'VIDEO'
  },
  EXAM_RECORD:{
    name:'測驗完成紀錄',
    key:'測驗完成紀錄',
    type:'EXAM'
  },
  SCHOOL:{
    name:'學校',
    key:'學校',
    type:"ALL"
  },
  GRADE:{
    name:'年級',
    key:'年級',
    type:"ALL"
  },
  PRODUCT_COUNTY:{
    name:'縣市',
    key:'產品縣市',
    type:"ALL"
  },
  PRODUCT_SCHOOL:{
    name:'學校',
    key:'產品學校',
    type:"ALL"
  }
}

export const OrientationType={
  [TAB_NAME.STUDENT_RECORD]:{
    [SECOND_TAB_NAME.ALL.key]:'student',
    [SECOND_TAB_NAME.VIDEO_RECORD.key]:'student',
    [SECOND_TAB_NAME.EXAM_RECORD.key]:'student',
  },
  [TAB_NAME.ORGANIZATION_RECORD]:{
    [SECOND_TAB_NAME.SCHOOL.key]:'institution',
    [SECOND_TAB_NAME.GRADE.key]:'institutionGrade',
  },
  [TAB_NAME.PRODUCT_RECORD]:{
    [SECOND_TAB_NAME.PRODUCT_COUNTY.key]:'productSchoolCounty',
    [SECOND_TAB_NAME.PRODUCT_SCHOOL.key]:'productSchool',
  },
}

export const tabs = [
  {
    name: TAB_NAME.STUDENT_RECORD,
    secondTabs: [
      SECOND_TAB_NAME.ALL.key,
      SECOND_TAB_NAME.VIDEO_RECORD.key,
      SECOND_TAB_NAME.EXAM_RECORD.key,
    ]
  },
  {
    name: TAB_NAME.ORGANIZATION_RECORD,
    secondTabs: [
      SECOND_TAB_NAME.SCHOOL.key,
      SECOND_TAB_NAME.GRADE.key,
    ]
  },
  {
    name: TAB_NAME.PRODUCT_RECORD,
    secondTabs: [
      SECOND_TAB_NAME.PRODUCT_COUNTY.key,
      SECOND_TAB_NAME.PRODUCT_SCHOOL.key,]
  },
  //{ name: "每月報表", tabType: "ALL_STUDENT" },
];

export const TABLE_TYPE={
  COUNTY:'縣市',
  DISTRICT:'鄉鎮市區',
  SCHOOL:'學校名稱',
  SYSTEM:'學制班級',
  SEAT_NUMBER:'座號',
  STUDENT_NAME:'學生名字',
  STUDENT_ACCOUNT:'學生帳號',
  VIDEO_NAME:'影片名稱',
  EXAM_NAME:'測驗名稱',
  GRADE:'年級',
  PRODUCT_NAME:'產品名稱',
  VIDEO_NUM:'已觀看部數',
  WATCH_MINUTE:'已觀看時間',
  TEST_NUM:'已測驗次數',
  SCORE:'測驗平均分',
  WATCH_TIME:'觀看時間',
  WATCH_PROGRESS:'觀看進度',
  SUM_TIME:'作答時長',
  CORRECT_PERCENT: "正確率",
  RESULT: "測驗結果",
  WATCH_TOTAL: "影片觀看總時數",
  TEST_COUNT: "測驗次數",
  EXAM_DATE: "測驗時間",
  STUDENT_COUNT: "瀏覽網站人數",
  REAL_COUNT: "使用人數",
}

export const searchTypeDatas = {
  [TAB_NAME.STUDENT_RECORD]:{
    [SECOND_TAB_NAME.ALL.key]:[
      {
        name:'縣市',
        type:'county'
      },
      {
        name:'鄉鎮市區',
        type:'district'
      },
      {
        name:'學校名稱',
        type:'school'
      },
      {
        name:'學制班級',
        type:'system'
      },
      {
        name:'學生名字',
        type:'name'
      },
      {
        name:'學生帳號',
        type:'account'
      },
    ],
    [SECOND_TAB_NAME.VIDEO_RECORD.key]:[
      {
        name:'縣市',
        type:'county'
      },
      {
        name:'鄉鎮市區',
        type:'district'
      },
      {
        name:'學校名稱',
        type:'school'
      },
      {
        name:'學制班級',
        type:'system'
      },
      {
        name:'學生名字',
        type:'name'
      },
      {
        name:'學生帳號',
        type:'account'
      },
      {
        name:'影片名稱',
        type:'videoName'
      },
    ],
    [SECOND_TAB_NAME.EXAM_RECORD.key]:[
      {
        name:'縣市',
        type:'county'
      },
      {
        name:'鄉鎮市區',
        type:'district'
      },
      {
        name:'學校名稱',
        type:'school'
      },
      {
        name:'學制班級',
        type:'system'
      },
      {
        name:'學生名字',
        type:'name'
      },
      {
        name:'學生帳號',
        type:'account'
      },
      {
        name:'測驗名稱',
        type:'examName'
      },
    ],
  },
  [TAB_NAME.ORGANIZATION_RECORD]:{
    [SECOND_TAB_NAME.SCHOOL.key]:[
      {
        name:'縣市',
        type:'county'
      },
      {
        name:'鄉鎮市區',
        type:'district'
      },
      {
        name:'學校名稱',
        type:'school'
      }
    ],
    [SECOND_TAB_NAME.GRADE.key]:[
      {
        name:'縣市',
        type:'county'
      },
      {
        name:'鄉鎮市區',
        type:'district'
      },
      {
        name:'學校名稱',
        type:'school'
      },
      {
        name:'年級',
        type:'grade'
      }
    ],
  },
  [TAB_NAME.PRODUCT_RECORD]:{
    [SECOND_TAB_NAME.PRODUCT_COUNTY.key]:[
      {
        name:'產品名稱',
        type:'productName'
      },
      {
        name:'縣市',
        type:'county'
      },
    ],
    [SECOND_TAB_NAME.PRODUCT_SCHOOL.key]:[
      {
        name:'產品名稱',
        type:'productName'
      },
      {
        name:'縣市',
        type:'county'
      },
      {
        name:'學校名稱',
        type:'school'
      },
    ],
  },
}

export const exportUsersExcelHeaders = [
  {
    tabType: SECOND_TAB_NAME.ALL.key,
    headers: {
      county: "縣市",
      district: "鄉鎮市區",
      school: "學校名稱",
      system: "學制",
      userClass: "班級",
      account: "學生帳號",
      name: "學生名字",
      videoNum: "已觀看部數",
      watchMinute: "已觀看時間",
      testNum: "已測驗次數",
      score: "測驗平均分",
    },
  },
  {
    tabType: SECOND_TAB_NAME.VIDEO_RECORD.key,
    headers: {
      county: "縣市",
      district: "鄉鎮市區",
      school: "學校名稱",
      system: "學制",
      userClass: "班級",
      account: "學生帳號",
      name: "學生名字",
      watchTime: "觀看時間",
      videoName: "影片名稱",
      watchProgress: "觀看進度",
    },
  },
  {
    tabType: SECOND_TAB_NAME.EXAM_RECORD.key,
    headers: {
      county: "縣市",
      district: "鄉鎮市區",
      school: "學校名稱",
      system: "學制",
      userClass: "班級",
      account: "學生帳號",
      name: "學生名字",
      examDate: "測驗時間",
      sumTime: "作答時長",
      examName: "測驗名稱",
      correctPercent: "測驗平均分",
      result: "測驗結果",
    },
  },
  {
      tabType: SECOND_TAB_NAME.SCHOOL.key,
      headers: {
        county: "縣市",
        district: "鄉鎮市區",
        school: "學校名稱",
        studentCount: "瀏覽網站人數",
        realCount: "使用人數",
        videoNum: "影片觀看次數",
        watchMinute: "影片觀看總時數",
        testNum: "測驗次數",
        score: "測驗平均分",
      },
  },
  {
      tabType: SECOND_TAB_NAME.GRADE.key,
      headers: {
        county: "縣市",
        district: "鄉鎮市區",
        school: "學校名稱",
        grade: "年級",
        studentCount: "瀏覽網站人數",
        realCount: "使用人數",
        videoNum: "影片觀看次數",
        watchMinute: "影片觀看總時數",
        testNum: "測驗次數",
        score: "測驗平均分",
      },
  },
  {
      tabType: SECOND_TAB_NAME.PRODUCT_COUNTY.key,
      headers: {
        productName: "產品名稱",
        schoolCounty: "縣市",
        userCount: "瀏覽網站人數",
        videoNum: "影片觀看次數",
        watchMinute: "影片觀看總時數",
        testNum: "測驗次數",
        score: "測驗平均分",
      },
  },
  {
      tabType: SECOND_TAB_NAME.PRODUCT_SCHOOL.key,
      headers: {
        productName: "產品名稱",
        schoolCounty: "縣市",
        school: "學校名稱",
        userCount: "瀏覽網站人數",
        videoNum: "影片觀看次數",
        watchMinute: "影片觀看總時數",
        testNum: "測驗次數",
        score: "測驗平均分",
      },
  },
];

export const EveryMonth = [
  "1月",
  "2月",
  "3月",
  "4月",
  "5月",
  "6月",
  "7月",
  "8月",
  "9月",
  "10月",
  "11月",
  "12月",
]


export const Grade_String = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二']
