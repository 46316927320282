export const RESET_USER_INFO = 'RESET_USER_INFO';
export const SET_VIDEO_LIST = 'SET_VIDEO_LIST';
export const SET_TEST_LIST = 'SET_TEST_LIST'; 
export const SET_RECORD_DATA = 'SET_RECORD_DATA';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_EDUCATION_INFO = 'SET_EDUCATION_INFO';
export const SET_PRODUCT_INFO = 'SET_PRODUCT_INFO';
export const SET_CLASS_SELECT_PANNEL = 'SET_CLASS_SELECT_PANNEL';
export const SET_PLAY_TIME_INFO = 'SET_PLAY_TIME_INFO';
export const SET_VIDEO_SRC = 'SET_VIDEO_SRC';
export const SET_NOW_HEAD_LIST_SELECT_NAME = 'SET_NOW_HEAD_LIST_SELECT_NAME';
export const SET_GO_BACK_URL = 'SET_GO_BACK_URL';
export const SET_EXAM_LIST = 'SET_EXAM_LIST';
