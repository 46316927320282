export const school = [
    {
      "代碼": "010301",
      "學校名稱": "國立華僑高級中等學校",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區大觀路一段32號",
      "電話": "(02)29684131",
      "網址": "http://www.nocsh.ntpc.edu.tw"
    },
    {
      "代碼": "011301",
      "學校名稱": "私立淡江高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[251]新北市淡水區真理街26號",
      "電話": "(02)26203850",
      "網址": "http://www.tksh.ntpc.edu.tw"
    },
    {
      "代碼": "011302",
      "學校名稱": "私立康橋高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區華城路800號",
      "電話": "(02)22166000",
      "網址": "http://www.kcbs.ntpc.edu.tw"
    },
    {
      "代碼": "011306",
      "學校名稱": "私立金陵女中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區重新路五段656號",
      "電話": "(02)29956776",
      "網址": "http://www.glghs.ntpc.edu.tw"
    },
    {
      "代碼": "011307",
      "學校名稱": "新北市裕德高級中等學校",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[236]新北市土城區擺接堡路1號",
      "電話": "(02)82617889",
      "網址": "http://www.yuteh.ntpc.edu.tw/yuteh/"
    },
    {
      "代碼": "011309",
      "學校名稱": "財團法人南山高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[235]新北市中和區廣福路41號",
      "電話": "(02)22453000",
      "網址": "http://www.nssh.ntpc.edu.tw"
    },
    {
      "代碼": "011310",
      "學校名稱": "財團法人恆毅高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[242]新北市新莊區中正路108號",
      "電話": "(02)29923619  ",
      "網址": "http://www.hchs.ntpc.edu.tw"
    },
    {
      "代碼": "011311",
      "學校名稱": "私立聖心女中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[249]新北市八里區龍米路一段263號",
      "電話": "(02)26182287",
      "網址": "http://www.shgsh.ntpc.edu.tw"
    },
    {
      "代碼": "011312",
      "學校名稱": "私立崇義高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[221]新北市汐止區大同路三段68號",
      "電話": "(02)86482078",
      "網址": "http://www.tyhs.ntpc.edu.tw/"
    },
    {
      "代碼": "011314",
      "學校名稱": "財團法人中華高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[236]新北市土城區城林路2號",
      "電話": "(02)22693641",
      "網址": "http://www.chsh.ntpc.edu.tw"
    },
    {
      "代碼": "011315",
      "學校名稱": "私立東海高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區忠孝路三段93巷12號",
      "電話": "(02)29822788 ",
      "網址": "http://www.thhs.ntpc.edu.tw"
    },
    {
      "代碼": "011316",
      "學校名稱": "私立格致高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區大智街260號",
      "電話": "(02)29855892",
      "網址": "http://www.gjsh.ntpc.edu.tw"
    },
    {
      "代碼": "011317",
      "學校名稱": "私立醒吾高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[244]新北市林口區粉寮路一段75巷80號",
      "電話": "(02)26012644",
      "網址": "http://www.swsh.ntpc.edu.tw"
    },
    {
      "代碼": "011318",
      "學校名稱": "私立徐匯高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[247]新北市蘆洲區中山一路1號",
      "電話": "(02)22817565",
      "網址": "http://www.sish.ntpc.edu.tw"
    },
    {
      "代碼": "011322",
      "學校名稱": "財團法人崇光女中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區三民路18號",
      "電話": "(02)29112543",
      "網址": "http://www.ckgsh.ntpc.edu.tw"
    },
    {
      "代碼": "011323",
      "學校名稱": "私立光仁高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區中山路二段255巷18號",
      "電話": "(02)29615161",
      "網址": "http://www.kjsh.ntpc.edu.tw"
    },
    {
      "代碼": "011324",
      "學校名稱": "私立竹林高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[235]新北市中和區華新街143巷12號",
      "電話": "(02)29422128",
      "網址": "http://www.clsh.ntpc.edu.tw"
    },
    {
      "代碼": "011325",
      "學校名稱": "私立及人高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區安興路1號",
      "電話": "(02)22112581",
      "網址": "http://www.cjsh.ntpc.edu.tw"
    },
    {
      "代碼": "011329",
      "學校名稱": "財團法人辭修高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[237]新北市三峽區溪東路251號",
      "電話": "(02)86761277",
      "網址": "http://www.tsshs.ntpc.edu.tw"
    },
    {
      "代碼": "011330",
      "學校名稱": "新北市林口康橋國際高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[244]新北市林口區興林路55號",
      "電話": "(02)26015888",
      "網址": "http://www.kcislk.ntpc.edu.tw/zh/index.html"
    },
    {
      "代碼": "011399",
      "學校名稱": "私立時雨高中",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[224]新北市瑞芳區銅山里祈堂路217號",
      "電話": "(02)24962217",
      "網址": "http://www.syjh.ntpc.edu.tw"
    },
    {
      "代碼": "011405",
      "學校名稱": "私立樹人家商",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[238]新北市樹林區大安路216號",
      "電話": "(02)26870391",
      "網址": "http://www.stgvs.ntpc.edu.tw"
    },
    {
      "代碼": "011407",
      "學校名稱": "私立復興商工",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[234]新北市永和區秀朗路一段201號",
      "電話": "(02)29262121",
      "網址": "http://www.fhvs.ntpc.edu.tw"
    },
    {
      "代碼": "011408",
      "學校名稱": "私立南強工商",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區文化路42號",
      "電話": "(02)29155144",
      "網址": "http://www.ncvs.ntpc.edu.tw"
    },
    {
      "代碼": "011413",
      "學校名稱": "私立穀保家商",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區中正北路560巷38號",
      "電話": "(02)29712343",
      "網址": "http://www.kpvs.ntpc.edu.tw"
    },
    {
      "代碼": "011419",
      "學校名稱": "私立開明工商",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區寶中路49號",
      "電話": "(02)29136061",
      "網址": "http://www.kmvs.ntpc.edu.tw"
    },
    {
      "代碼": "011420",
      "學校名稱": "私立智光商工",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[234]新北市永和區中正路100號",
      "電話": "(02)29432491",
      "網址": "http://www.ckvs.ntpc.edu.tw"
    },
    {
      "代碼": "011421",
      "學校名稱": "私立清傳高商",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區五谷王北街141巷1號",
      "電話": "(02)29955535",
      "網址": "http://www.ccvs.ntpc.edu.tw"
    },
    {
      "代碼": "011426",
      "學校名稱": "私立能仁家商",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區文中路53巷10號",
      "電話": "(02)29182399",
      "網址": "http://www.nrvs.ntpc.edu.tw"
    },
    {
      "代碼": "011427",
      "學校名稱": "私立豫章工商",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區四川路一段391號",
      "電話": "(02)29519810",
      "網址": "http://www.ycvs.ntpc.edu.tw"
    },
    {
      "代碼": "011431",
      "學校名稱": "私立莊敬工家",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區民生路45號",
      "電話": "(02)22188956",
      "網址": "http://www.jjvs.ntpc.edu.tw"
    },
    {
      "代碼": "011432",
      "學校名稱": "私立中華商海",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[207]新北市萬里區瑪鋉路15號",
      "電話": "(02)24922119",
      "網址": "http://www.chmvs.ntpc.edu.tw"
    },
    {
      "代碼": "013303",
      "學校名稱": "市立泰山高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[243]新北市泰山區辭修路7號",
      "電話": "(02)22963625",
      "網址": "http://www.tssh.ntpc.edu.tw"
    },
    {
      "代碼": "013304",
      "學校名稱": "市立板橋高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區文化路一段25號",
      "電話": "(02)29602500",
      "網址": "http://www.pcsh.ntpc.edu.tw"
    },
    {
      "代碼": "013335",
      "學校名稱": "市立新店高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區中央路93號",
      "電話": "(02)22193700",
      "網址": "http://www.htsh.ntpc.edu.tw"
    },
    {
      "代碼": "013336",
      "學校名稱": "市立中和高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[235]新北市中和區連城路460號",
      "電話": "(02)22227146",
      "網址": "http://www.chshs.ntpc.edu.tw"
    },
    {
      "代碼": "013337",
      "學校名稱": "市立新莊高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[242]新北市新莊區中平路135號",
      "電話": "(02)29912391",
      "網址": "http://www.hcsh.ntpc.edu.tw"
    },
    {
      "代碼": "013338",
      "學校名稱": "市立新北高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區三信路1號",
      "電話": "(02)28577326",
      "網址": "http://www.ntsh.ntpc.edu.tw/"
    },
    {
      "代碼": "013339",
      "學校名稱": "市立林口高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[244]新北市林口區仁愛路二段173號",
      "電話": "(02)26009482",
      "網址": "http://www.lksh.ntpc.edu.tw"
    },
    {
      "代碼": "013402",
      "學校名稱": "市立瑞芳高工",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[224]新北市瑞芳區瑞芳街60號",
      "電話": "(02)24972516",
      "網址": "http://www.jfvs.ntpc.edu.tw"
    },
    {
      "代碼": "013430",
      "學校名稱": "市立三重商工",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區中正北路163號",
      "電話": "(02)29715606",
      "網址": "http://www.scvs.ntpc.edu.tw"
    },
    {
      "代碼": "013433",
      "學校名稱": "市立新北高工",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[236]新北市土城區學府路一段241號",
      "電話": "(02)22612483",
      "網址": "http://www.ntvs.ntpc.edu.tw/bin/home.php"
    },
    {
      "代碼": "013434",
      "學校名稱": "市立淡水商工",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[251]新北市淡水區商工路307號",
      "電話": "(02)26203930",
      "網址": "http://www.tsvs.ntpc.edu.tw"
    },
    {
      "代碼": "014302",
      "學校名稱": "市立海山高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區漢生東路215號",
      "電話": "(02)29517475",
      "網址": "http://www.hshs.ntpc.edu.tw"
    },
    {
      "代碼": "014311",
      "學校名稱": "市立三重高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[241]新北市三重區集美街212號",
      "電話": "(02)29760501",
      "網址": "http://www.schs.ntpc.edu.tw/"
    },
    {
      "代碼": "014315",
      "學校名稱": "市立永平高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[234]新北市永和區永平路205號",
      "電話": "(02)22319670",
      "網址": "http://www.yphs.ntpc.edu.tw"
    },
    {
      "代碼": "014322",
      "學校名稱": "市立樹林高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[238]新北市樹林區中華路8號",
      "電話": "(02)86852011",
      "網址": "http://www.slsh.ntpc.edu.tw"
    },
    {
      "代碼": "014326",
      "學校名稱": "市立明德高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[237]新北市三峽區中正路二段399號",
      "電話": "(02)26723302",
      "網址": "http://www.mdhs.ntpc.edu.tw"
    },
    {
      "代碼": "014332",
      "學校名稱": "市立秀峰高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[221]新北市汐止區忠孝東路201號",
      "電話": "(02)26412134",
      "網址": "http://www.sfhs.ntpc.edu.tw/default_page.asp"
    },
    {
      "代碼": "014338",
      "學校名稱": "市立金山高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[208]新北市金山區美田里文化二路2號",
      "電話": "(02)24982028",
      "網址": "http://www.cshs.ntpc.edu.tw"
    },
    {
      "代碼": "014343",
      "學校名稱": "市立安康高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[231]新北市新店區安興路25號",
      "電話": "(02)22111743",
      "網址": "http://www.akhs.ntpc.edu.tw"
    },
    {
      "代碼": "014347",
      "學校名稱": "市立雙溪高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[227]新北市雙溪區梅竹蹊3號",
      "電話": "(02)24931028",
      "網址": "http://www.sxhs.ntpc.edu.tw"
    },
    {
      "代碼": "014348",
      "學校名稱": "市立石碇高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[223]新北市石碇區隆盛里八分寮45號",
      "電話": "(02)26631224",
      "網址": "http://www.sdhs.ntpc.edu.tw"
    },
    {
      "代碼": "014353",
      "學校名稱": "市立丹鳳高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[242]新北市新莊區龍安路72號",
      "電話": "(02)29089627",
      "網址": "http://www.dfsh.ntpc.edu.tw/"
    },
    {
      "代碼": "014356",
      "學校名稱": "市立清水高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[236]新北市土城區明德路一段72號",
      "電話": "(02)22707801",
      "網址": "http://www.cssh.ntpc.edu.tw"
    },
    {
      "代碼": "014357",
      "學校名稱": "市立三民高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[247]新北市蘆洲區三民路96號",
      "電話": "(02)22894675",
      "網址": "http://www.smsh.ntpc.edu.tw"
    },
    {
      "代碼": "014362",
      "學校名稱": "市立錦和高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[235]新北市中和區錦和路163號",
      "電話": "(02)22498566",
      "網址": "http://www.jhsh.ntpc.edu.tw"
    },
    {
      "代碼": "014363",
      "學校名稱": "市立光復高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區光環路一段7號",
      "電話": "(02)29582366",
      "網址": "http://www.gfhs.ntpc.edu.tw/"
    },
    {
      "代碼": "014364",
      "學校名稱": "市立竹圍高中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[251]新北市淡水區竹林路 35 號",
      "電話": "(02)28096990",
      "網址": "http://www.zwhs.ntpc.edu.tw/"
    },
    {
      "代碼": "014381",
      "學校名稱": "市立北大高級中學",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[237]新北市三峽區大義路277號",
      "電話": "(02)26742666 ",
      "網址": "http://www.bdsh.ntpc.edu.tw"
    },
    {
      "代碼": "014399",
      "學校名稱": "市立豐珠中學",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[228]新北市貢寮區美豐里雞母嶺街6-6號",
      "電話": "(02)24903727",
      "網址": "http://www.fjjh.ntpc.edu.tw/"
    },
    {
      "代碼": "014439",
      "學校名稱": "市立鶯歌工商",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[239]新北市鶯歌區中正三路154號",
      "電話": "(02)26775040",
      "網址": "http://www.ykvs.ntpc.edu.tw"
    },
    {
      "代碼": "014468",
      "學校名稱": "市立樟樹國際實中",
      "公/私立": "公立",
      "縣市名稱": "[01]新北市",
      "地址": "[221]新北市汐止區樟樹二路135號",
      "電話": "(02)26430686",
      "網址": "http://www.ctjhs.ntpc.edu.tw/"
    },
    {
      "代碼": "311401",
      "學校名稱": "私立育達家商",
      "公/私立": "私立",
      "縣市名稱": "[31]臺北市",
      "地址": "[105]臺北市松山區美仁里寧安街12號",
      "電話": "(02)25706767",
      "網址": "http://www.yudah.tp.edu.tw"
    },
    {
      "代碼": "313301",
      "學校名稱": "市立西松高中",
      "公/私立": "公立",
      "縣市名稱": "[31]臺北市",
      "地址": "[105]臺北市松山區鵬程里健康路325巷7號",
      "電話": "(02)25286618",
      "網址": "http://www.hssh.tp.edu.tw"
    },
    {
      "代碼": "313302",
      "學校名稱": "市立中崙高中",
      "公/私立": "公立",
      "縣市名稱": "[31]臺北市",
      "地址": "[105]臺北市松山區八德路四段101號",
      "電話": "(02)27535316",
      "網址": "http://www.zlsh.tp.edu.tw/"
    },
    {
      "代碼": "321399",
      "學校名稱": "臺北市私立協和祐德高級中學",
      "公/私立": "私立",
      "縣市名稱": "[32]臺北市",
      "地址": "[110]臺北市信義區忠孝東路5段790巷27號",
      "電話": "(02)27263131",
      "網址": "http://www.hhvs.tp.edu.tw/"
    },
    {
      "代碼": "323301",
      "學校名稱": "市立松山高中",
      "公/私立": "公立",
      "縣市名稱": "[32]臺北市",
      "地址": "[110]臺北市信義區基隆路一段156號",
      "電話": "(02)27535968",
      "網址": "http://www.sssh.tp.edu.tw"
    },
    {
      "代碼": "323302",
      "學校名稱": "市立永春高中",
      "公/私立": "公立",
      "縣市名稱": "[32]臺北市",
      "地址": "[110]臺北市信義區松隆里松山路654號",
      "電話": "(02)27272983",
      "網址": "http://www.ycsh.tp.edu.tw"
    },
    {
      "代碼": "323401",
      "學校名稱": "市立松山家商",
      "公/私立": "公立",
      "縣市名稱": "[32]臺北市",
      "地址": "[110]臺北市信義區松山路655號",
      "電話": "(02)27261118",
      "網址": "http://www.ssvs.tp.edu.tw"
    },
    {
      "代碼": "323402",
      "學校名稱": "市立松山工農",
      "公/私立": "公立",
      "縣市名稱": "[32]臺北市",
      "地址": "[110]臺北市信義區忠孝東路5段236巷15號",
      "電話": "(02)27226616",
      "網址": "http://www.saihs.edu.tw"
    },
    {
      "代碼": "330301",
      "學校名稱": "國立師大附中",
      "公/私立": "公立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區和安里信義路3段143號",
      "電話": "(02)27075215",
      "網址": "http://www.hs.ntnu.edu.tw"
    },
    {
      "代碼": "331301",
      "學校名稱": "私立延平中學",
      "公/私立": "私立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區和安里建國南路1段275號",
      "電話": "(02)27071478",
      "網址": "http://www.yphs.tp.edu.tw"
    },
    {
      "代碼": "331302",
      "學校名稱": "私立金甌女中",
      "公/私立": "私立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區光明里杭州南路2段1號",
      "電話": "(02)23214765",
      "網址": "http://www.cogsh.tp.edu.tw"
    },
    {
      "代碼": "331304",
      "學校名稱": "私立復興實驗高中",
      "公/私立": "私立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區敦化南路1段262 號",
      "電話": "(02)27715859",
      "網址": "http://www.fhjh.tp.edu.tw"
    },
    {
      "代碼": "331402",
      "學校名稱": "私立東方工商",
      "公/私立": "私立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區信義路四段186巷8號",
      "電話": "(02)27554616",
      "網址": "http://www.tfvs.tp.edu.tw"
    },
    {
      "代碼": "331403",
      "學校名稱": "私立喬治工商",
      "公/私立": "私立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區基隆路2段172號",
      "電話": "(02)27386515",
      "網址": "http://www.gvs.tp.edu.tw"
    },
    {
      "代碼": "331404",
      "學校名稱": "私立開平餐飲",
      "公/私立": "私立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區復興南路2段148巷24號",
      "電話": "(02)27556939",
      "網址": "http://www.kpvs.tp.edu.tw"
    },
    {
      "代碼": "333301",
      "學校名稱": "市立和平高中",
      "公/私立": "公立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區芳和里臥龍街100號",
      "電話": "(02)27324300",
      "網址": "http://www.hpsh.tp.edu.tw"
    },
    {
      "代碼": "333401",
      "學校名稱": "市立大安高工",
      "公/私立": "公立",
      "縣市名稱": "[33]臺北市",
      "地址": "[106]臺北市大安區龍圖里復興南路2段52號",
      "電話": "(02)27091630",
      "網址": "http://www.taivs.tp.edu.tw"
    },
    {
      "代碼": "341302",
      "學校名稱": "私立大同高中",
      "公/私立": "私立",
      "縣市名稱": "[34]臺北市",
      "地址": "[104]臺北市中山區聚英里中山北路3段40號",
      "電話": "(02)25925252",
      "網址": "http://www.tsh.ttu.edu.tw"
    },
    {
      "代碼": "341402",
      "學校名稱": "私立稻江護家",
      "公/私立": "私立",
      "縣市名稱": "[34]臺北市",
      "地址": "[104]臺北市中山區新生北路三段55號",
      "電話": "(02)25955161",
      "網址": "http://www.tcnvs.tp.edu.tw"
    },
    {
      "代碼": "343301",
      "學校名稱": "市立中山女中",
      "公/私立": "公立",
      "縣市名稱": "[34]臺北市",
      "地址": "[104]臺北市中山區力行里長安東路2段141號",
      "電話": "(02)25073148",
      "網址": "http://www.csghs.tp.edu.tw"
    },
    {
      "代碼": "343302",
      "學校名稱": "市立大同高中",
      "公/私立": "公立",
      "縣市名稱": "[34]臺北市",
      "地址": "[104]臺北市中山區中央里長春路167號",
      "電話": "(02)25054269",
      "網址": "http://www.ttsh.tp.edu.tw"
    },
    {
      "代碼": "343303",
      "學校名稱": "市立大直高中",
      "公/私立": "公立",
      "縣市名稱": "[34]臺北市",
      "地址": "[104]臺北市中山區劍潭里北安路420號",
      "電話": "(02)25334017",
      "網址": "http://www.dcsh.tp.edu.tw"
    },
    {
      "代碼": "351301",
      "學校名稱": "私立強恕中學",
      "公/私立": "私立",
      "縣市名稱": "[35]臺北市",
      "地址": "[100]臺北市中正區板溪里汀州路二段143號",
      "電話": "(02)23656574",
      "網址": "http://www.qshs.tp.edu.tw"
    },
    {
      "代碼": "351402",
      "學校名稱": "私立開南商工",
      "公/私立": "私立",
      "縣市名稱": "[35]臺北市",
      "地址": "[100]臺北市中正區東門里濟南路1段6號",
      "電話": "(02)23212666",
      "網址": "http://www.knvs.tp.edu.tw"
    },
    {
      "代碼": "353301",
      "學校名稱": "市立建國中學",
      "公/私立": "公立",
      "縣市名稱": "[35]臺北市",
      "地址": "[100]臺北市中正區龍光里南海路56號",
      "電話": "(02)23034381",
      "網址": "http://www.ck.tp.edu.tw"
    },
    {
      "代碼": "353302",
      "學校名稱": "市立成功中學",
      "公/私立": "公立",
      "縣市名稱": "[35]臺北市",
      "地址": "[100]臺北市中正區幸福里濟南路1段71號",
      "電話": "(02)23216256",
      "網址": "http://www.cksh.tp.edu.tw"
    },
    {
      "代碼": "353303",
      "學校名稱": "市立北一女中",
      "公/私立": "公立",
      "縣市名稱": "[35]臺北市",
      "地址": "[100]臺北市中正區黎明里重慶南路1段165號",
      "電話": "(02)23820484",
      "網址": "http://www.fg.tp.edu.tw"
    },
    {
      "代碼": "361301",
      "學校名稱": "私立靜修女中",
      "公/私立": "私立",
      "縣市名稱": "[36]臺北市",
      "地址": "[103]臺北市大同區雙連里寧夏路59號",
      "電話": "(02)25574345",
      "網址": "http://www.bish.tp.edu.tw"
    },
    {
      "代碼": "361401",
      "學校名稱": "私立稻江高商",
      "公/私立": "私立",
      "縣市名稱": "[36]臺北市",
      "地址": "[103]臺北市大同區國昌里民權西路225巷24號",
      "電話": "(02)25912001",
      "網址": "http://www.tkcvs.tp.edu.tw"
    },
    {
      "代碼": "363301",
      "學校名稱": "市立明倫高中",
      "公/私立": "公立",
      "縣市名稱": "[36]臺北市",
      "地址": "[103]臺北市大同區保安里承德路3段336號",
      "電話": "(02)25961567",
      "網址": "http://www.mlsh.tp.edu.tw"
    },
    {
      "代碼": "363302",
      "學校名稱": "市立成淵高中",
      "公/私立": "公立",
      "縣市名稱": "[36]臺北市",
      "地址": "[103]臺北市大同區承德路二段235號",
      "電話": "(02)25531969",
      "網址": "http://www.cyhs.tp.edu.tw"
    },
    {
      "代碼": "373301",
      "學校名稱": "市立華江高中",
      "公/私立": "公立",
      "縣市名稱": "[37]臺北市",
      "地址": "[108]臺北市萬華區日善里西藏路213號",
      "電話": "(02)23019946",
      "網址": "http://www.hcsh.tp.edu.tw"
    },
    {
      "代碼": "373302",
      "學校名稱": "市立大理高中",
      "公/私立": "公立",
      "縣市名稱": "[37]臺北市",
      "地址": "[108]臺北市萬華區綠堤里長順街2號",
      "電話": "(02)23026959",
      "網址": "http://newweb.tlsh.tp.edu.tw/"
    },
    {
      "代碼": "380301",
      "學校名稱": "國立政大附中",
      "公/私立": "公立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區政大一街353號",
      "電話": "(02)82377500",
      "網址": "http://www.chahs.nccu.edu.tw"
    },
    {
      "代碼": "381301",
      "學校名稱": "私立東山高中",
      "公/私立": "私立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區老泉里老泉街26巷3號",
      "電話": "(02)29395826",
      "網址": "http://www.tshs.tp.edu.tw"
    },
    {
      "代碼": "381302",
      "學校名稱": "私立滬江高中",
      "公/私立": "私立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區景美里羅斯福路6段336號",
      "電話": "(02)86631122",
      "網址": "http://www.hchs.tp.edu.tw"
    },
    {
      "代碼": "381303",
      "學校名稱": "私立大誠高中",
      "公/私立": "私立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區萬興里秀明路2段175號",
      "電話": "(02)22348989 ",
      "網址": "http://www.tcsh.tp.edu.tw"
    },
    {
      "代碼": "381304",
      "學校名稱": "私立再興中學",
      "公/私立": "私立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區明義里興隆路4段2號",
      "電話": "(02)29366803",
      "網址": "http://www.thsh.tp.edu.tw"
    },
    {
      "代碼": "381305",
      "學校名稱": "私立景文高中",
      "公/私立": "私立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區木新里保儀路127號",
      "電話": "(02)29390310",
      "網址": "http://www.jwsh.tp.edu.tw"
    },
    {
      "代碼": "381306",
      "學校名稱": "臺北市靜心高中",
      "公/私立": "私立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區興隆路二段46號",
      "電話": "(02)29323118",
      "網址": "http://www.chjhs.tp.edu.tw/dispPageBox/MainHp.aspx?ddsPageID=MAINHP"
    },
    {
      "代碼": "383301",
      "學校名稱": "市立景美女中",
      "公/私立": "公立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區樟新里木新路3段312號",
      "電話": "(02)29368847",
      "網址": "http://www.cmgsh.tp.edu.tw"
    },
    {
      "代碼": "383302",
      "學校名稱": "市立萬芳高中",
      "公/私立": "公立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區興隆路3段115巷1號",
      "電話": "(02)22309585",
      "網址": "http://www.wfsh.tp.edu.tw"
    },
    {
      "代碼": "383401",
      "學校名稱": "市立木柵高工",
      "公/私立": "公立",
      "縣市名稱": "[38]臺北市",
      "地址": "[116]臺北市文山區萬芳里木柵路4段77號",
      "電話": "(02)22300506",
      "網址": "http://www.mcvs.tp.edu.tw"
    },
    {
      "代碼": "393301",
      "學校名稱": "市立南港高中",
      "公/私立": "公立",
      "縣市名稱": "[39]臺北市",
      "地址": "[115]臺北市南港區西新里向陽路21號",
      "電話": "(02)27837863",
      "網址": "http://www.nksh.tp.edu.tw"
    },
    {
      "代碼": "393302",
      "學校名稱": "市立育成高中",
      "公/私立": "公立",
      "縣市名稱": "[39]臺北市",
      "地址": "[115]臺北市南港區重陽路366號",
      "電話": "(02)26530475",
      "網址": "http://www.yucsh.tp.edu.tw"
    },
    {
      "代碼": "393401",
      "學校名稱": "市立南港高工",
      "公/私立": "公立",
      "縣市名稱": "[39]臺北市",
      "地址": "[115]臺北市南港區南港里興中路29號",
      "電話": "(02)27825432",
      "網址": "http://www.nkhs.tp.edu.tw"
    },
    {
      "代碼": "401301",
      "學校名稱": "私立文德女中",
      "公/私立": "私立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區紫陽里成功路3段70號",
      "電話": "(02)27904570",
      "網址": "http://www.sfgsh.tp.edu.tw"
    },
    {
      "代碼": "401302",
      "學校名稱": "私立方濟中學",
      "公/私立": "私立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區紫星里成功路三段61號",
      "電話": "(02)27910278",
      "網址": "http://www.sfh.tp.edu.tw"
    },
    {
      "代碼": "401303",
      "學校名稱": "私立達人女中",
      "公/私立": "私立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區湖濱里內湖路2段314號",
      "電話": "(02)27956899",
      "網址": "http://www.trgsh.tp.edu.tw"
    },
    {
      "代碼": "403301",
      "學校名稱": "市立內湖高中",
      "公/私立": "公立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區紫陽里文德路218號",
      "電話": "(02)27977035",
      "網址": "http://www.nhsh.tp.edu.tw"
    },
    {
      "代碼": "403302",
      "學校名稱": "市立麗山高中",
      "公/私立": "公立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區環山路二段100號",
      "電話": "(02)26570435",
      "網址": "http://www.lssh.tp.edu.tw"
    },
    {
      "代碼": "403303",
      "學校名稱": "市立南湖高中",
      "公/私立": "公立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區康寧路三段220號",
      "電話": "(02)26308889",
      "網址": "http://www.nhush.tp.edu.tw"
    },
    {
      "代碼": "403401",
      "學校名稱": "市立內湖高工",
      "公/私立": "公立",
      "縣市名稱": "[40]臺北市",
      "地址": "[114]臺北市內湖區港墘里內湖路1段520號",
      "電話": "(02)26574874",
      "網址": "http://www.nihs.tp.edu.tw"
    },
    {
      "代碼": "411301",
      "學校名稱": "私立泰北高中",
      "公/私立": "私立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區福林里福林路240號",
      "電話": "(02)28825560",
      "網址": "http://www.tpsh.tp.edu.tw"
    },
    {
      "代碼": "411302",
      "學校名稱": "私立衛理女中",
      "公/私立": "私立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區翠山里至善路2段321號",
      "電話": "(02)28411487",
      "網址": "http://www.wlgsh.tp.edu.tw"
    },
    {
      "代碼": "411303",
      "學校名稱": "私立華興中學",
      "公/私立": "私立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區芝山里仰德大道1段101號",
      "電話": "(02)28316834",
      "網址": "http://www.hhhs.tp.edu.tw"
    },
    {
      "代碼": "411401",
      "學校名稱": "私立華岡藝校",
      "公/私立": "私立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區建業路73巷8號",
      "電話": "(02)28612354",
      "網址": "http://www.hka.edu.tw"
    },
    {
      "代碼": "413301",
      "學校名稱": "市立陽明高中",
      "公/私立": "公立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區後港里中正路510號",
      "電話": "(02)28316675",
      "網址": "http://www.ymsh.tp.edu.tw"
    },
    {
      "代碼": "413302",
      "學校名稱": "市立百齡高中",
      "公/私立": "公立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區義信里承德路4段177號",
      "電話": "(02)28831568",
      "網址": "http://www.blsh.tp.edu.tw"
    },
    {
      "代碼": "413401",
      "學校名稱": "市立士林高商",
      "公/私立": "公立",
      "縣市名稱": "[41]臺北市",
      "地址": "[111]臺北市士林區後港里士商路150號",
      "電話": "(02)28313114",
      "網址": "http://www.slhs.tp.edu.tw"
    },
    {
      "代碼": "421301",
      "學校名稱": "私立薇閣高中",
      "公/私立": "私立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區中心里珠海路50號",
      "電話": "(02)28925332",
      "網址": "http://www.wghs.tp.edu.tw"
    },
    {
      "代碼": "421302",
      "學校名稱": "臺北市幼華高中",
      "公/私立": "私立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區北投路二段55號",
      "電話": "(02)28921166",
      "網址": "http://www.shvs.tp.edu.tw"
    },
    {
      "代碼": "421303",
      "學校名稱": "臺北市私立奎山實驗高級中學",
      "公/私立": "私立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區榮華里明德路200號",
      "電話": "(02)28212009",
      "網址": "https://www.kshs.tp.edu.tw/"
    },
    {
      "代碼": "421404",
      "學校名稱": "私立惇敘工商",
      "公/私立": "私立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區泉源路221號",
      "電話": "(02)28912630",
      "網址": "http://www.thvs.tp.edu.tw"
    },
    {
      "代碼": "423301",
      "學校名稱": "市立復興高中",
      "公/私立": "公立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區復興四路70號",
      "電話": "(02)28914131",
      "網址": "http://www.fhsh.tp.edu.tw"
    },
    {
      "代碼": "423302",
      "學校名稱": "市立中正高中",
      "公/私立": "公立",
      "縣市名稱": "[42]臺北市",
      "地址": "[112]臺北市北投區文林北路77號",
      "電話": "(02)28234811",
      "網址": "http://www.ccsh.tp.edu.tw"
    },
    {
      "代碼": "030305",
      "學校名稱": "國立中央大學附屬中壢高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區三光路115號",
      "電話": "(03)4932181  ",
      "網址": "http://www.clhs.tyc.edu.tw"
    },
    {
      "代碼": "030403",
      "學校名稱": "國立北科大附屬桃園農工",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區成功路二段144號",
      "電話": "(03)3333921",
      "網址": "http://www.tyai.tyc.edu.tw"
    },
    {
      "代碼": "031301",
      "學校名稱": "私立漢英高中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[325]桃園市龍潭區中正路佳安段446號",
      "電話": "(03)4712531",
      "網址": "http://www.hhs.tyc.edu.tw/"
    },
    {
      "代碼": "031309",
      "學校名稱": "桃園市育達高中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[324]桃園市平鎮區育達路160號",
      "電話": "(03)4934101",
      "網址": "http://www.yuda.tyc.edu.tw"
    },
    {
      "代碼": "031310",
      "學校名稱": "私立六和高中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[324]桃園市平鎮區陸光路180號",
      "電話": "(03)4204000",
      "網址": "http://www.lioho.tw/tw/"
    },
    {
      "代碼": "031311",
      "學校名稱": "桃園市復旦高中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[324]桃園市平鎮區復旦路二段122號",
      "電話": "(03)4932476",
      "網址": "http://www.fths.tyc.edu.tw"
    },
    {
      "代碼": "031312",
      "學校名稱": "桃園市治平高中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[326]桃園市楊梅區埔心中興路137號",
      "電話": "(03)4823636",
      "網址": "http://www.cpshs.tyc.edu.tw/home"
    },
    {
      "代碼": "031313",
      "學校名稱": "桃園市振聲高中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區復興路439號",
      "電話": "(03)3322605 ",
      "網址": "http://www.fxsh.tyc.edu.tw"
    },
    {
      "代碼": "031317",
      "學校名稱": "私立光啟高中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[333]桃園市龜山區自由街40號",
      "電話": "(02)82098313",
      "網址": "http://www.phsh.tyc.edu.tw"
    },
    {
      "代碼": "031318",
      "學校名稱": "桃園市啟英高中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區中園路447號",
      "電話": "(03)4523036",
      "網址": "http://www.cyvs.tyc.edu.tw"
    },
    {
      "代碼": "031319",
      "學校名稱": "桃園市清華高中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[327]桃園市新屋區中華路658號",
      "電話": "(03)4771196",
      "網址": "http://www.chvs.tyc.edu.tw"
    },
    {
      "代碼": "031320",
      "學校名稱": "桃園市新興高中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[334]桃園市八德區永豐路563號",
      "電話": "(03)3796996",
      "網址": "http://www.hshs.tyc.edu.tw"
    },
    {
      "代碼": "031323",
      "學校名稱": "私立至善高中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[335]桃園市大溪區康莊路645號",
      "電話": "(03)3887528",
      "網址": "http://www.tzsavs.tyc.edu.tw"
    },
    {
      "代碼": "031324",
      "學校名稱": "桃園市大興高中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[337]桃園市大園區永興路142號",
      "電話": "(03)3862330",
      "網址": "http://www.dxhs.tyc.edu.tw"
    },
    {
      "代碼": "031326",
      "學校名稱": "私立大華高中",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[326]桃園市楊梅區三民路二段200號",
      "電話": "(03)4820506",
      "網址": "http://www.thsh.tyc.edu.tw"
    },
    {
      "代碼": "031414",
      "學校名稱": "私立成功工商",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[333]桃園市龜山區新興里明德路162巷100號",
      "電話": "(03)3294187",
      "網址": "http://www.ckvs.tyc.edu.tw"
    },
    {
      "代碼": "031415",
      "學校名稱": "私立方曙商工",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[325]桃園市龍潭區上林里中原路一段50號",
      "電話": "(03)4796345",
      "網址": "http://www.fsvs.tyc.edu.tw"
    },
    {
      "代碼": "031421",
      "學校名稱": "私立永平工商",
      "公/私立": "私立",
      "縣市名稱": "[03]桃園市",
      "地址": "[326]桃園市楊梅區(埔心)永平路480號",
      "電話": "(03)4822464",
      "網址": "http://www.ypvs.tyc.edu.tw"
    },
    {
      "代碼": "033302",
      "學校名稱": "市立龍潭高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[325]桃園市龍潭區神龍路155號",
      "電話": "(03)4792829",
      "網址": "http://www.ltsh.tyc.edu.tw/bin/home.php"
    },
    {
      "代碼": "033304",
      "學校名稱": "市立桃園高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區成功路三段38號",
      "電話": "(03)3946001",
      "網址": "http://www.tysh.tyc.edu.tw"
    },
    {
      "代碼": "033306",
      "學校名稱": "市立武陵高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區中山路889號",
      "電話": "(03)3698170",
      "網址": "http://www.wlsh.tyc.edu.tw"
    },
    {
      "代碼": "033316",
      "學校名稱": "市立楊梅高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[326]桃園市楊梅區高獅路5號",
      "電話": "(03)4789618",
      "網址": "http://www.ymhs.tyc.edu.tw/"
    },
    {
      "代碼": "033325",
      "學校名稱": "市立陽明高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[330]桃園市桃園區德壽街8號",
      "電話": "(03)3672706",
      "網址": "http://www.pymhs.tyc.edu.tw"
    },
    {
      "代碼": "033327",
      "學校名稱": "市立內壢高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區成章四街120號",
      "電話": "(03)4528080",
      "網址": "http://www.nlhs.tyc.edu.tw"
    },
    {
      "代碼": "033407",
      "學校名稱": "市立中壢高商",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區中央西路二段141巷100號",
      "電話": "(03)4929871",
      "網址": "http://www.clvsc.tyc.edu.tw"
    },
    {
      "代碼": "033408",
      "學校名稱": "市立中壢家商",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[320]桃園市中壢區德育路36號",
      "電話": "(03)4271627",
      "網址": "http://www.pclhvs.cl.edu.tw"
    },
    {
      "代碼": "034306",
      "學校名稱": "市立南崁高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[338]桃園市蘆竹區五福里仁愛路2段1號",
      "電話": "(03)3525580",
      "網址": "http://www.nkjh.tyc.edu.tw"
    },
    {
      "代碼": "034312",
      "學校名稱": "市立大溪高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[335]桃園市大溪區康莊路641號",
      "電話": "(03)3878628",
      "網址": "http://www.dssh.tyc.edu.tw"
    },
    {
      "代碼": "034314",
      "學校名稱": "市立壽山高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[333]桃園市龜山區大同路23號",
      "電話": "(03)3501778",
      "網址": "http://www.sssh.tyc.edu.tw"
    },
    {
      "代碼": "034319",
      "學校名稱": "市立平鎮高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[324]桃園市平鎮區環南路三段100號",
      "電話": "(03)4287288",
      "網址": "http://www.pjhs.tyc.edu.tw"
    },
    {
      "代碼": "034332",
      "學校名稱": "市立觀音高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[328]桃園市觀音區新坡里中山路2段519號",
      "電話": "(03)4981464",
      "網址": "http://www.gish.tyc.edu.tw"
    },
    {
      "代碼": "034335",
      "學校名稱": "市立新屋高級中等學校",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[327]桃園市新屋區中興路111號",
      "電話": "(03)4772029",
      "網址": "http://www.swjh.tyc.edu.tw/"
    },
    {
      "代碼": "034347",
      "學校名稱": "市立永豐高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[334]桃園市八德區永豐路609號",
      "電話": "(03)3692679",
      "網址": "http://www.yfms.tyc.edu.tw"
    },
    {
      "代碼": "034399",
      "學校名稱": "市立大園國際高中",
      "公/私立": "公立",
      "縣市名稱": "[03]桃園市",
      "地址": "[337]桃園市大園區大成路二段8號",
      "電話": "(03)3813001",
      "網址": "http://www.dysh.tyc.edu.tw"
    },
    {
      "代碼": "060322",
      "學校名稱": "國立興大附中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區東榮路369號",
      "電話": "(04)24875199",
      "網址": "http://www.dali.tc.edu.tw"
    },
    {
      "代碼": "060323",
      "學校名稱": "國立中科實驗高級中學",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[428]臺中市大雅區平和路227號",
      "電話": "(04)25686850",
      "網址": "http://www.nehs.tc.edu.tw"
    },
    {
      "代碼": "061301",
      "學校名稱": "財團法人常春藤高中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[427]臺中市潭子區潭興路一段165巷320號",
      "電話": "(04)25395066",
      "網址": "http://www.ivyjhs.tc.edu.tw"
    },
    {
      "代碼": "061306",
      "學校名稱": "私立明台高中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[413]臺中市霧峰區萊園路91號",
      "電話": "(04)23393071",
      "網址": "http://www.mths.tc.edu.tw"
    },
    {
      "代碼": "061309",
      "學校名稱": "私立致用高中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[437]臺中市大甲區甲東路512號",
      "電話": "(04)26872354",
      "網址": "http://www.cycivs.tc.edu.tw"
    },
    {
      "代碼": "061310",
      "學校名稱": "私立大明高中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區新仁路三段210號",
      "電話": "(04)24821027",
      "網址": "http://www.tmsh.tc.edu.tw"
    },
    {
      "代碼": "061311",
      "學校名稱": "私立嘉陽高中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[436]臺中市清水區中航路三段1號",
      "電話": "(04)26152168",
      "網址": "http://www.cysh.tc.edu.tw"
    },
    {
      "代碼": "061313",
      "學校名稱": "私立明道高中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[414]臺中市烏日區中山路一段497號",
      "電話": "(04)23372101",
      "網址": "http://www.mingdao.edu.tw"
    },
    {
      "代碼": "061314",
      "學校名稱": "私立僑泰高中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區樹王路342號",
      "電話": "(04)24063936",
      "網址": "http://www.ctas.tc.edu.tw"
    },
    {
      "代碼": "061315",
      "學校名稱": "私立華盛頓高中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[411]臺中市太平區廍仔坑路26號(原弘仁)",
      "電話": "(04)23934712",
      "網址": "http://www.whs.tc.edu.tw"
    },
    {
      "代碼": "061316",
      "學校名稱": "私立青年高中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區中湖路100號",
      "電話": "(04)24954181",
      "網址": "http://www.youth.tc.edu.tw"
    },
    {
      "代碼": "061317",
      "學校名稱": "私立弘文高中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[427]臺中市潭子區弘文街100號",
      "電話": "(04)25340011",
      "網址": "http://www.hwhs.tc.edu.tw"
    },
    {
      "代碼": "061318",
      "學校名稱": "私立立人高中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區中興路二段380號",
      "電話": "(04)24834138",
      "網址": "http://www.lzsh.tc.edu.tw"
    },
    {
      "代碼": "061319",
      "學校名稱": "私立玉山高中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[423]臺中市東勢區東崎路四段399號",
      "電話": "(04)25771313",
      "網址": "http://www.yssh.tc.edu.tw"
    },
    {
      "代碼": "061321",
      "學校名稱": "私立慈明高中",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[411]臺中市太平區光德路388號",
      "電話": "(04)22713911",
      "網址": "http://www.tmhs.tc.edu.tw"
    },
    {
      "代碼": "061322",
      "學校名稱": "華德福大地實驗學校",
      "公/私立": "私立",
      "縣市名稱": "[06]臺中市",
      "地址": "[414]臺中市烏日區公園路36號",
      "電話": "(04)23377520",
      "網址": "http://www.motherearth.com.tw/"
    },
    {
      "代碼": "063303",
      "學校名稱": "市立大甲高中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[437]臺中市大甲區中山路一段720號",
      "電話": "(04)26877165",
      "網址": "http://www.djsh.tc.edu.tw/"
    },
    {
      "代碼": "063305",
      "學校名稱": "市立清水高中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[436]臺中市清水區中山路90號",
      "電話": "(04)26222116",
      "網址": "http://www.cshs.tc.edu.tw"
    },
    {
      "代碼": "063312",
      "學校名稱": "市立豐原高中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[420]臺中市豐原區水源路150號",
      "電話": "(04)25290381",
      "網址": "http://www.fysh.tc.edu.tw/"
    },
    {
      "代碼": "063401",
      "學校名稱": "市立豐原高商",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[420]臺中市豐原區圓環南路50號",
      "電話": "(04)25283556",
      "網址": "http://www.fyvs.tc.edu.tw/"
    },
    {
      "代碼": "063402",
      "學校名稱": "市立大甲高工",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[437]臺中市大甲區頂店里開元路71號",
      "電話": "(04)26874132",
      "網址": "http://www.tcvs.tc.edu.tw/"
    },
    {
      "代碼": "063404",
      "學校名稱": "市立東勢高工",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[423]臺中市東勢區東關路6段1328號",
      "電話": "(04)25872136",
      "網址": "http://www.tsvs.tc.edu.tw/"
    },
    {
      "代碼": "063407",
      "學校名稱": "市立沙鹿高工",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[433]臺中市沙鹿區臺灣大道7段823號",
      "電話": "(04)26621795",
      "網址": "http://www.slvs.tc.edu.tw/"
    },
    {
      "代碼": "063408",
      "學校名稱": "市立霧峰農工",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[413]臺中市霧峰區中正路1222號",
      "電話": "(04)23303118",
      "網址": "http://www.wufai.edu.tw/"
    },
    {
      "代碼": "064308",
      "學校名稱": "市立后綜高中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[421]臺中市后里區廣福里三豐路三段968號",
      "電話": "(04):25562012",
      "網址": "http://www.hzsh.tc.edu.tw"
    },
    {
      "代碼": "064324",
      "學校名稱": "市立大里高中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[412]臺中市大里區國中路365號",
      "電話": "(04)24067870",
      "網址": "http://www.dlsh.tc.edu.tw"
    },
    {
      "代碼": "064328",
      "學校名稱": "市立新社高中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[426]臺中市新社區復盛里中和街三段國中巷10號",
      "電話": "(04)25812116",
      "網址": "http://www.sshs.tc.edu.tw"
    },
    {
      "代碼": "064336",
      "學校名稱": "市立長億高中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[411]臺中市太平區長億里長億六街1號",
      "電話": "(04)22799267",
      "網址": "http://www.cyhs.tc.edu.tw"
    },
    {
      "代碼": "064342",
      "學校名稱": "市立中港高中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[435]臺中市梧棲區文昌路400號",
      "電話": "(04)26578270",
      "網址": "http://www.cgsh.tc.edu.tw"
    },
    {
      "代碼": "064350",
      "學校名稱": "市立龍津高中",
      "公/私立": "公立",
      "縣市名稱": "[06]臺中市",
      "地址": "[434]臺中市龍井區三港路130號",
      "電話": "(04)26304536",
      "網址": "http://www.ljjhs.tc.edu.tw/home"
    },
    {
      "代碼": "190406",
      "學校名稱": "國立興大附農",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[401]臺中市東區台中路283號",
      "電話": "(04)22810010",
      "網址": "http://www.tcavs.tc.edu.tw"
    },
    {
      "代碼": "191301",
      "學校名稱": "私立東大附中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[407]臺中市西屯區臺灣大道四段1727號",
      "電話": "(04)23590269",
      "網址": "http://www.hn.thu.edu.tw"
    },
    {
      "代碼": "191302",
      "學校名稱": "私立葳格高中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[406]臺中市北屯區軍福十八路328號",
      "電話": "(04)24371728",
      "網址": "https://senior.wagor.tc.edu.tw/"
    },
    {
      "代碼": "191305",
      "學校名稱": "私立新民高中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[404]臺中市北區健行路111號",
      "電話": "(04)22334105",
      "網址": "http://www.shinmin.tc.edu.tw"
    },
    {
      "代碼": "191308",
      "學校名稱": "私立宜寧高中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[407]臺中市西屯區東大路一段555號",
      "電話": "(04)24621800",
      "網址": "http://www.inhs.tc.edu.tw"
    },
    {
      "代碼": "191309",
      "學校名稱": "私立明德高中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[402]臺中市南區明德街84號",
      "電話": "(04)22877676",
      "網址": "http://www.mdhs.tc.edu.tw"
    },
    {
      "代碼": "191311",
      "學校名稱": "私立衛道高中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[406]臺中市北屯區四平路161號",
      "電話": "(04)22911187",
      "網址": "http://www.vtsh.tc.edu.tw"
    },
    {
      "代碼": "191313",
      "學校名稱": "私立曉明女中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[404]臺中市北區中清路一段606號",
      "電話": "(04)22921175",
      "網址": "http://www.smgsh.tc.edu.tw"
    },
    {
      "代碼": "191314",
      "學校名稱": "私立嶺東高中",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[408]臺中市南屯區春社里嶺東路2號",
      "電話": "(04)23898940",
      "網址": "http://www.lths.tc.edu.tw"
    },
    {
      "代碼": "191315",
      "學校名稱": "私立磊川華德福實驗教育學校",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[406]臺中市北屯區松竹五路233號及366號",
      "電話": "(04)24350110",
      "網址": "http://www.leichuan.tc.edu.tw/"
    },
    {
      "代碼": "191412",
      "學校名稱": "財團法人光華高工",
      "公/私立": "私立",
      "縣市名稱": "[19]臺中市",
      "地址": "[411]臺中市太平區東平路18號",
      "電話": "(04)23949009",
      "網址": "http://www.khvs.tc.edu.tw"
    },
    {
      "代碼": "193301",
      "學校名稱": "市立臺中女中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[403]臺中市西區自由路一段95號",
      "電話": "(04)22205108",
      "網址": "http://itcgs.tcgs.tc.edu.tw/"
    },
    {
      "代碼": "193302",
      "學校名稱": "市立臺中一中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[404]臺中市北區育才街2號",
      "電話": "(04)22226081",
      "網址": "http://www.tcfsh.tc.edu.tw/"
    },
    {
      "代碼": "193303",
      "學校名稱": "市立忠明高中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[403]臺中市西區博館路166號",
      "電話": "(04)23224690",
      "網址": "http://www3.cmsh.tc.edu.tw"
    },
    {
      "代碼": "193313",
      "學校名稱": "市立西苑高中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[407]臺中市西屯區西苑路268號",
      "電話": "(04)27016473",
      "網址": "http://www.sysh.tc.edu.tw"
    },
    {
      "代碼": "193315",
      "學校名稱": "市立東山高中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[406]臺中市北屯區景賢六路200號",
      "電話": "(04)24360166",
      "網址": "http://www.tsjh.tc.edu.tw"
    },
    {
      "代碼": "193316",
      "學校名稱": "市立惠文高中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[408]臺中市南屯區公益路二段298號",
      "電話": "(04)22503928",
      "網址": "http://www.hwsh.tc.edu.tw"
    },
    {
      "代碼": "193404",
      "學校名稱": "市立臺中家商",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[401]臺中市東區和平街50號",
      "電話": "(04)22223307",
      "網址": "http://www.tchcvs.tc.edu.tw/"
    },
    {
      "代碼": "193407",
      "學校名稱": "市立臺中高工",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[402]臺中市南區高工路191號",
      "電話": "(04)22613158",
      "網址": "http://www.tcivs.tc.edu.tw/ischool/publish_page/0/"
    },
    {
      "代碼": "194303",
      "學校名稱": "市立臺中二中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[404]臺中市北區英士路109號",
      "電話": "(04)22021521",
      "網址": "http://www.tcssh.tc.edu.tw"
    },
    {
      "代碼": "194315",
      "學校名稱": "市立文華高中",
      "公/私立": "公立",
      "縣市名稱": "[19]臺中市",
      "地址": "[407]臺中市西屯區寧夏路240號",
      "電話": "(04)23124000",
      "網址": "http://www.whsh.tc.edu.tw"
    },
    {
      "代碼": "110302",
      "學校名稱": "國立新豐高中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[711]臺南市歸仁區中正北路一段148號",
      "電話": "(06)2304082",
      "網址": "http://www.sfsh.tn.edu.tw"
    },
    {
      "代碼": "110308",
      "學校名稱": "國立臺南大學附中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[710]臺南市永康區中山南路948號",
      "電話": "(06)2338501",
      "網址": "http://www.tntcsh.tn.edu.tw"
    },
    {
      "代碼": "110311",
      "學校名稱": "國立北門高中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[722]臺南市佳里區六安里269號",
      "電話": "(06)7222150",
      "網址": "http://www.bmsh.tn.edu.tw/"
    },
    {
      "代碼": "110312",
      "學校名稱": "國立新營高中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[730]臺南市新營區民權路101號",
      "電話": "(06)6562274",
      "網址": "http://www.hysh.tn.edu.tw"
    },
    {
      "代碼": "110314",
      "學校名稱": "國立後壁高中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[731]臺南市後壁區嘉苳里下茄苳132號",
      "電話": "(06)6671031",
      "網址": "http://www.hpsh.tn.edu.tw"
    },
    {
      "代碼": "110315",
      "學校名稱": "國立善化高中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[741]臺南市善化區大成路195號",
      "電話": "(06)5837312",
      "網址": "http://www.shsh.tn.edu.tw"
    },
    {
      "代碼": "110317",
      "學校名稱": "國立新化高中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[712]臺南市新化區忠孝路2號",
      "電話": "(06)5982065",
      "網址": "http://www.hhsh.tn.edu.tw"
    },
    {
      "代碼": "110328",
      "學校名稱": "國立南科國際實驗高中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[744]臺南市新市區三舍里大順6路12巷6號",
      "電話": "(06)5052916",
      "網址": "http://www.nnkieh.tn.edu.tw"
    },
    {
      "代碼": "110401",
      "學校名稱": "國立新化高工",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[712]臺南市新化區東榮里信義路54號",
      "電話": "(06)5903994",
      "網址": "http://www.hhvs.tn.edu.tw"
    },
    {
      "代碼": "110403",
      "學校名稱": "國立白河商工",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[732]臺南市白河區永安里新興路528號",
      "電話": "(06)6852054",
      "網址": "http://www.phvs.tn.edu.tw"
    },
    {
      "代碼": "110404",
      "學校名稱": "國立北門農工",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[722]臺南市佳里區六安里117號",
      "電話": "(06)7260148",
      "網址": "http://www.pmai.tn.edu.tw"
    },
    {
      "代碼": "110405",
      "學校名稱": "國立曾文家商",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[721]臺南市麻豆區和平路9號",
      "電話": "(06)5722079",
      "網址": "http://www.twvs.tn.edu.tw"
    },
    {
      "代碼": "110406",
      "學校名稱": "國立新營高工",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[730]臺南市新營區中正路68號",
      "電話": "(06)6322377",
      "網址": "http://www.hyivs.tn.edu.tw/default.asp"
    },
    {
      "代碼": "110407",
      "學校名稱": "國立玉井工商",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[714]臺南市玉井區中正路18號",
      "電話": "(06)5741101",
      "網址": "http://www.ycvs.tn.edu.tw"
    },
    {
      "代碼": "110409",
      "學校名稱": "國立臺南高工",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[710]臺南市永康區中山南路193號",
      "電話": "(06)2322131",
      "網址": "http://www.ptivs.tn.edu.tw"
    },
    {
      "代碼": "110410",
      "學校名稱": "國立曾文農工",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[721]臺南市麻豆區南勢里南勢1號",
      "電話": "(06)5721137",
      "網址": "http://www.twivs.tn.edu.tw"
    },
    {
      "代碼": "111313",
      "學校名稱": "私立南光高中",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[730]臺南市新營區中興路62號",
      "電話": "(06)6335408",
      "網址": "http://www.nkhs.tn.edu.tw"
    },
    {
      "代碼": "111318",
      "學校名稱": "私立鳳和高中",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[736]臺南市柳營區中山東路二段1330號",
      "電話": "(06)6223208",
      "網址": ""
    },
    {
      "代碼": "111320",
      "學校名稱": "私立港明高中",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[723]臺南市西港區慶安路229號",
      "電話": "(06)7952025",
      "網址": "http://www.kmsh.tn.edu.tw"
    },
    {
      "代碼": "111321",
      "學校名稱": "臺南市興國高中",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[730]臺南市新營區大同路86號",
      "電話": "(06)6352201",
      "網址": "http://www.hkhs.tn.edu.tw/"
    },
    {
      "代碼": "111322",
      "學校名稱": "私立明達高中",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[737]臺南市鹽水區橋南里忠孝路73號",
      "電話": "(06)6521178",
      "網址": "http://www.mdsh.tn.edu.tw"
    },
    {
      "代碼": "111323",
      "學校名稱": "私立黎明高中",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[721]臺南市麻豆區井東里磚子井141號",
      "電話": "(06)5717123",
      "網址": "http://www.lmsh.tn.edu.tw"
    },
    {
      "代碼": "111326",
      "學校名稱": "私立新榮高中",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[736]臺南市柳營區光福里132之12號",
      "電話": "(06)6222222",
      "網址": "http://www.srsh.tn.edu.tw"
    },
    {
      "代碼": "111419",
      "學校名稱": "私立陽明工商",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[720]臺南市官田區官田里423號",
      "電話": "(06)6901190",
      "網址": "http://www.ymvs.tn.edu.tw"
    },
    {
      "代碼": "111427",
      "學校名稱": "私立育德工家",
      "公/私立": "私立",
      "縣市名稱": "[11]臺南市",
      "地址": "[730]臺南市新營區健康路211號",
      "電話": "(06)6563275",
      "網址": "http://www.ytvs.tn.edu.tw"
    },
    {
      "代碼": "114306",
      "學校名稱": "市立大灣高中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[710]臺南市永康區文賢街68巷1號",
      "電話": "(06)2714223",
      "網址": "http://www.dwhs.tn.edu.tw/"
    },
    {
      "代碼": "114307",
      "學校名稱": "市立永仁高中",
      "公/私立": "公立",
      "縣市名稱": "[11]臺南市",
      "地址": "[710]臺南市永康區忠孝路74號",
      "電話": "(06)3115538",
      "網址": "http://web.yrhs.tn.edu.tw/bin/home.php"
    },
    {
      "代碼": "210303",
      "學校名稱": "國立臺南二中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[704]臺南市北區北門路二段125號",
      "電話": "(06)2514526",
      "網址": "http://www.tnssh.tn.edu.tw"
    },
    {
      "代碼": "210305",
      "學校名稱": "國立臺南一中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區民族路一段1號",
      "電話": "(06)2371206",
      "網址": "http://www.tnfsh.tn.edu.tw"
    },
    {
      "代碼": "210306",
      "學校名稱": "國立臺南女中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[700]臺南市中西區大埔街97號",
      "電話": "(06)2131928",
      "網址": "http://www.tngs.tn.edu.tw"
    },
    {
      "代碼": "210309",
      "學校名稱": "國立家齊高中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[700]臺南市中西區健康路一段342號",
      "電話": "(06)2133265 ",
      "網址": "http://www.ccgsh.tn.edu.tw"
    },
    {
      "代碼": "210408",
      "學校名稱": "國立臺南高商",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[702]臺南市南區健康路一段327號",
      "電話": "(06)2617123",
      "網址": "http://www.tncvs.tn.edu.tw"
    },
    {
      "代碼": "210416",
      "學校名稱": "國立臺南海事",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[708]臺南市安平區世平路1號",
      "電話": "(06)3910772",
      "網址": "http://www.tnvs.tn.edu.tw"
    },
    {
      "代碼": "211301",
      "學校名稱": "私立長榮高中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區林森路二段79號",
      "電話": "(06)2381711",
      "網址": "http://www.cjshs.tn.edu.tw"
    },
    {
      "代碼": "211302",
      "學校名稱": "私立長榮女中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區長榮路二段135號",
      "電話": "(06)2740381",
      "網址": "http://www.ckgsh.tn.edu.tw"
    },
    {
      "代碼": "211304",
      "學校名稱": "財團法人聖功女中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[704]臺南市北區北園街87巷64號",
      "電話": "(06)2740126",
      "網址": "http://www.skgsh.tn.edu.tw"
    },
    {
      "代碼": "211310",
      "學校名稱": "臺南市光華高中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區勝利路41號",
      "電話": "(06)2386501",
      "網址": "http://www.khgs.tn.edu.tw"
    },
    {
      "代碼": "211314",
      "學校名稱": "私立六信高中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[702]臺南市南區新都路20號",
      "電話": "(06)2619885",
      "網址": "http://www.lhvs.tn.edu.tw"
    },
    {
      "代碼": "211315",
      "學校名稱": "私立瀛海高中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[709]臺南市安南區長溪路一段76號",
      "電話": "(06)2568582",
      "網址": "http://www.yhsh.tn.edu.tw"
    },
    {
      "代碼": "211317",
      "學校名稱": "私立崑山高中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[704]臺南市北區開元路444號",
      "電話": "(06)2364408",
      "網址": "http://www.kssh.tn.edu.tw/bin/home.php"
    },
    {
      "代碼": "211318",
      "學校名稱": "私立德光高中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區德光街106號",
      "電話": "(06)2894560",
      "網址": "http://www.tkgsh.tn.edu.tw"
    },
    {
      "代碼": "211320",
      "學校名稱": "財團法人慈濟高中",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[708]臺南市安平區建平五街111號",
      "電話": "(06)2932323",
      "網址": "http://www.tcsh.tn.edu.tw"
    },
    {
      "代碼": "211407",
      "學校名稱": "私立南英商工",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[700]臺南市中西區永福路一段149號",
      "電話": "(06)2132222",
      "網址": "http://www.nyvs.tn.edu.tw"
    },
    {
      "代碼": "211412",
      "學校名稱": "私立亞洲餐旅",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[702]臺南市南區大成路一段5號",
      "電話": "(06)2640175",
      "網址": "http://www.asvs.tn.edu.tw"
    },
    {
      "代碼": "211419",
      "學校名稱": "私立慈幼工商",
      "公/私立": "私立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區裕農路801號",
      "電話": "(06)2362106",
      "網址": "http://www.ssvs.tn.edu.tw"
    },
    {
      "代碼": "213303",
      "學校名稱": "市立南寧高中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[702]臺南市南區萬年路167號",
      "電話": "(06)2622458",
      "網址": "http://www.nnjh.tn.edu.tw"
    },
    {
      "代碼": "213316",
      "學校名稱": "市立土城高中",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[709]臺南市安南區安中路五段265號",
      "電話": "(06)2577014",
      "網址": "http://www.tcjh.tn.edu.tw"
    },
    {
      "代碼": "120303",
      "學校名稱": "國立鳳山高中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區光復路二段130號",
      "電話": "(07)7463150",
      "網址": "http://www.fssh.khc.edu.tw"
    },
    {
      "代碼": "120304",
      "學校名稱": "國立岡山高中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[820]高雄市岡山區公園路52號",
      "電話": "(07)6212033",
      "網址": "http://www.kssh.khc.edu.tw"
    },
    {
      "代碼": "120311",
      "學校名稱": "國立旗美高中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[842]高雄市旗山區東平里樹人路21號",
      "電話": "(07)6612502",
      "網址": "http://www.cmsh.khc.edu.tw"
    },
    {
      "代碼": "120319",
      "學校名稱": "國立鳳新高中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區新富路257號",
      "電話": "(07)7658288",
      "網址": "http://www.fhsh.khc.edu.tw"
    },
    {
      "代碼": "120401",
      "學校名稱": "國立旗山農工",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[842]高雄市旗山區旗甲路一段195號",
      "電話": "(07)6612501",
      "網址": "http://www.csvs.khc.edu.tw"
    },
    {
      "代碼": "120402",
      "學校名稱": "國立岡山農工",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[820]高雄市岡山區壽天里岡山路533號",
      "電話": "(07)6217129",
      "網址": "http://www.ksvs.khc.edu.tw"
    },
    {
      "代碼": "120409",
      "學校名稱": "國立鳳山商工",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區文衡路51號",
      "電話": "(07)7462602",
      "網址": "http://www.fsvs.ks.edu.tw"
    },
    {
      "代碼": "121306",
      "學校名稱": "財團法人新光高中",
      "公/私立": "私立",
      "縣市名稱": "[12]高雄市",
      "地址": "[831]高雄市大寮區鳳屏一路217號",
      "電話": "(07)7019888",
      "網址": "http://210.60.76.7/xoops/?if=end"
    },
    {
      "代碼": "121307",
      "學校名稱": "財團法人普門中學",
      "公/私立": "私立",
      "縣市名稱": "[12]高雄市",
      "地址": "[840]高雄市大樹區大坑路140-11號",
      "電話": "(07)6562676",
      "網址": "http://www.pmsh.khc.edu.tw"
    },
    {
      "代碼": "121318",
      "學校名稱": "私立正義高中",
      "公/私立": "私立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區南江街183號",
      "電話": "(07)7225529",
      "網址": "http://www.cysh.khc.edu.tw"
    },
    {
      "代碼": "121320",
      "學校名稱": "私立義大國際高中",
      "公/私立": "私立",
      "縣市名稱": "[12]高雄市",
      "地址": "[840]高雄市大樹區學城路1段6號",
      "電話": "(07)6577115 ",
      "網址": "https://www.iis.kh.edu.tw/tw"
    },
    {
      "代碼": "121405",
      "學校名稱": "私立中山工商",
      "公/私立": "私立",
      "縣市名稱": "[12]高雄市",
      "地址": "[831]高雄市大寮區會社里正氣路79號",
      "電話": "(07)7815311",
      "網址": "http://www.csic.khc.edu.tw"
    },
    {
      "代碼": "121410",
      "學校名稱": "私立旗美商工",
      "公/私立": "私立",
      "縣市名稱": "[12]高雄市",
      "地址": "[843]高雄市美濃區中興路二段275號",
      "電話": "(07)6812152",
      "網址": "http://www.cmvs.ks.edu.tw"
    },
    {
      "代碼": "121413",
      "學校名稱": "私立高英工商",
      "公/私立": "私立",
      "縣市名稱": "[12]高雄市",
      "地址": "[831]高雄市大寮區鳳林三路19巷44號",
      "電話": "(07)7832991",
      "網址": "http://www.kyicvs.khc.edu.tw"
    },
    {
      "代碼": "121415",
      "學校名稱": "私立華德工家",
      "公/私立": "私立",
      "縣市名稱": "[12]高雄市",
      "地址": "[852]高雄市茄萣區濱海路四段66號",
      "電話": "(07)6921212",
      "網址": "http://www.hdvs.kh.edu.tw/"
    },
    {
      "代碼": "121417",
      "學校名稱": "私立高苑工商",
      "公/私立": "私立",
      "縣市名稱": "[12]高雄市",
      "地址": "[825]高雄市橋頭區東林里芋寮路1號",
      "電話": "(07)6111101",
      "網址": "http://www.kyvs.ks.edu.tw"
    },
    {
      "代碼": "124302",
      "學校名稱": "市立文山高中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[833]高雄市鳥松區大埤路31號",
      "電話": "(07)7777272",
      "網址": "http://www.wsm.ks.edu.tw"
    },
    {
      "代碼": "124311",
      "學校名稱": "市立林園高中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[832]高雄市林園區林園北路481號",
      "電話": "(07)6412059",
      "網址": "http://www.ly.ks.edu.tw"
    },
    {
      "代碼": "124313",
      "學校名稱": "市立仁武高中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[814]高雄市仁武區仁林路20號",
      "電話": "(07)3721640",
      "網址": "http://www.rwm.ks.edu.tw"
    },
    {
      "代碼": "124322",
      "學校名稱": "市立路竹高中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[821]高雄市路竹區中華路292號",
      "電話": "(07)6963008",
      "網址": "http://www.lchs.ks.edu.tw"
    },
    {
      "代碼": "124333",
      "學校名稱": "市立六龜高中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[844]高雄市六龜區義寶里光復路212號",
      "電話": "(07)6891023",
      "網址": "http://www.lgm.ks.edu.tw"
    },
    {
      "代碼": "124340",
      "學校名稱": "市立福誠高中",
      "公/私立": "公立",
      "縣市名稱": "[12]高雄市",
      "地址": "[830]高雄市鳳山區五甲三路176號",
      "電話": "(07)8224646",
      "網址": "http://www.ftm.ks.edu.tw"
    },
    {
      "代碼": "521301",
      "學校名稱": "私立明誠高中",
      "公/私立": "私立",
      "縣市名稱": "[52]高雄市",
      "地址": "[804]高雄市鼓山區中華一路97號",
      "電話": "(07)5521593",
      "網址": "http://www.mcsh.kh.edu.tw"
    },
    {
      "代碼": "521303",
      "學校名稱": "私立大榮高中",
      "公/私立": "私立",
      "縣市名稱": "[52]高雄市",
      "地址": "[804]高雄市鼓山區大榮街1號",
      "電話": "(07)5613281",
      "網址": "http://www.dystcs.kh.edu.tw"
    },
    {
      "代碼": "521401",
      "學校名稱": "私立中華藝校",
      "公/私立": "私立",
      "縣市名稱": "[52]高雄市",
      "地址": "[804]高雄市鼓山區美術館路89號",
      "電話": "(07)5549696",
      "網址": "http://www.charts.kh.edu.tw"
    },
    {
      "代碼": "523301",
      "學校名稱": "市立鼓山高中",
      "公/私立": "公立",
      "縣市名稱": "[52]高雄市",
      "地址": "[804]高雄市鼓山區明德路2號",
      "電話": "(07)5213258",
      "網址": "http://www.kusjh.kh.edu.tw"
    },
    {
      "代碼": "533301",
      "學校名稱": "市立左營高中",
      "公/私立": "公立",
      "縣市名稱": "[53]高雄市",
      "地址": "[813]高雄市左營區海功路55號",
      "電話": "(07)5822010",
      "網址": "http://www.tyhs.edu.tw"
    },
    {
      "代碼": "533302",
      "學校名稱": "市立新莊高中",
      "公/私立": "公立",
      "縣市名稱": "[53]高雄市",
      "地址": "[813]高雄市左營區文慈路99號",
      "電話": "(07)3420103",
      "網址": "http://www.hchs.kh.edu.tw"
    },
    {
      "代碼": "533401",
      "學校名稱": "市立海青工商",
      "公/私立": "公立",
      "縣市名稱": "[53]高雄市",
      "地址": "[813]高雄市左營區左營大路1號",
      "電話": "(07)5819155",
      "網址": "http://www.hcvs.kh.edu.tw"
    },
    {
      "代碼": "533402",
      "學校名稱": "市立三民家商",
      "公/私立": "公立",
      "縣市名稱": "[53]高雄市",
      "地址": "[813]高雄市左營區裕誠路1102號",
      "電話": "(07)5525887",
      "網址": "http://www.smvhs.kh.edu.tw"
    },
    {
      "代碼": "540301",
      "學校名稱": "國立中山大學附屬國光高中",
      "公/私立": "公立",
      "縣市名稱": "[54]高雄市",
      "地址": "[811]高雄市楠梓區後昌路512號",
      "電話": "(07)3603600",
      "網址": "http://www.kksh.kh.edu.tw"
    },
    {
      "代碼": "543301",
      "學校名稱": "市立中山高中",
      "公/私立": "公立",
      "縣市名稱": "[54]高雄市",
      "地址": "[811]高雄市楠梓區藍昌路416號",
      "電話": "(07)3641116",
      "網址": "http://www.cshs.kh.edu.tw"
    },
    {
      "代碼": "543302",
      "學校名稱": "市立楠梓高中",
      "公/私立": "公立",
      "縣市名稱": "[54]高雄市",
      "地址": "[811]高雄市楠梓區清豐路19號",
      "電話": "(07)3550571",
      "網址": "http://www.nths.kh.edu.tw"
    },
    {
      "代碼": "551301",
      "學校名稱": "私立立志高中",
      "公/私立": "私立",
      "縣市名稱": "[55]高雄市",
      "地址": "[807]高雄市三民區立志街42號",
      "電話": "(07)3922601",
      "網址": "http://www.lcvs.kh.edu.tw"
    },
    {
      "代碼": "551303",
      "學校名稱": "南海月光實驗學校",
      "公/私立": "私立",
      "縣市名稱": "[55]高雄市",
      "地址": "[807]高雄市三民區大中一路110巷52弄15號",
      "電話": "(07)3458068",
      "網址": "https://rise-education2018.webnode.tw/"
    },
    {
      "代碼": "551402",
      "學校名稱": "私立樹德家商",
      "公/私立": "私立",
      "縣市名稱": "[55]高雄市",
      "地址": "[807]高雄市三民區建興路116號",
      "電話": "(07)3848622",
      "網址": "http://www.shute.kh.edu.tw"
    },
    {
      "代碼": "553301",
      "學校名稱": "市立高雄中學",
      "公/私立": "公立",
      "縣市名稱": "[55]高雄市",
      "地址": "[807]高雄市三民區建國三路50號",
      "電話": "(07)2862550",
      "網址": "http://www.kshs.kh.edu.tw"
    },
    {
      "代碼": "553302",
      "學校名稱": "市立三民高中",
      "公/私立": "公立",
      "縣市名稱": "[55]高雄市",
      "地址": "[807]高雄市三民區金鼎路81號",
      "電話": "(07)3475181",
      "網址": "http://www.smhs.kh.edu.tw"
    },
    {
      "代碼": "553401",
      "學校名稱": "市立高雄高工",
      "公/私立": "公立",
      "縣市名稱": "[55]高雄市",
      "地址": "[807]高雄市三民區建工路419號",
      "電話": "(07)3815366",
      "網址": "http://www.ksvs.kh.edu.tw"
    },
    {
      "代碼": "563301",
      "學校名稱": "市立新興高中",
      "公/私立": "公立",
      "縣市名稱": "[56]高雄市",
      "地址": "[800]高雄市新興區五福二路218號",
      "電話": "(07)2727127",
      "網址": "http://www.hhhs.kh.edu.tw"
    },
    {
      "代碼": "563401",
      "學校名稱": "市立高雄高商",
      "公/私立": "公立",
      "縣市名稱": "[56]高雄市",
      "地址": "[800]高雄市新興區五福二路3號",
      "電話": "(07)2269975",
      "網址": "http://www.ksvcs.kh.edu.tw"
    },
    {
      "代碼": "573301",
      "學校名稱": "市立高雄女中",
      "公/私立": "公立",
      "縣市名稱": "[57]高雄市",
      "地址": "[801]高雄市前金區五福三路122號",
      "電話": "(07)2115418",
      "網址": "http://www.kghs.kh.edu.tw"
    },
    {
      "代碼": "580301",
      "學校名稱": "國立高師大附中",
      "公/私立": "公立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區凱旋二路89號",
      "電話": "(07)7613875",
      "網址": "http://www.nknush.kh.edu.tw"
    },
    {
      "代碼": "581301",
      "學校名稱": "私立復華高中",
      "公/私立": "私立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區民權一路42號",
      "電話": "(07)3344168   ",
      "網址": "http://www.fhhs.kh.edu.tw"
    },
    {
      "代碼": "581302",
      "學校名稱": "天主教道明中學",
      "公/私立": "私立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區建國一路354號",
      "電話": "(07)2240711",
      "網址": "http://www.dmhs.kh.edu.tw"
    },
    {
      "代碼": "581401",
      "學校名稱": "私立國際商工",
      "公/私立": "私立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區三多二路84號",
      "電話": "(07)7228665",
      "網址": "http://www.kcvs.kh.edu.tw"
    },
    {
      "代碼": "581402",
      "學校名稱": "私立三信家商",
      "公/私立": "私立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區三多一路186號",
      "電話": "(07)7517171",
      "網址": "http://www.sanhsin.edu.tw"
    },
    {
      "代碼": "583301",
      "學校名稱": "市立中正高中",
      "公/私立": "公立",
      "縣市名稱": "[58]高雄市",
      "地址": "[802]高雄市苓雅區中正一路8號",
      "電話": "(07)7491992",
      "網址": "http://www.cchs.kh.edu.tw"
    },
    {
      "代碼": "593301",
      "學校名稱": "市立前鎮高中",
      "公/私立": "公立",
      "縣市名稱": "[59]高雄市",
      "地址": "[806]高雄市前鎮區鎮中路132號",
      "電話": "(07)8226841",
      "網址": "http://www.cjhs.kh.edu.tw"
    },
    {
      "代碼": "593302",
      "學校名稱": "市立瑞祥高中",
      "公/私立": "公立",
      "縣市名稱": "[59]高雄市",
      "地址": "[806]高雄市前鎮區班超路63號",
      "電話": "(07)8152271",
      "網址": "http://www.rssh.kh.edu.tw"
    },
    {
      "代碼": "593401",
      "學校名稱": "市立中正高工",
      "公/私立": "公立",
      "縣市名稱": "[59]高雄市",
      "地址": "[806]高雄市前鎮區光華二路80號",
      "電話": "(07)7232301",
      "網址": "http://www.ccvs.kh.edu.tw"
    },
    {
      "代碼": "610405",
      "學校名稱": "國立高餐大附屬餐旅中學",
      "公/私立": "公立",
      "縣市名稱": "[61]高雄市",
      "地址": "[812]高雄市小港區松和路40號",
      "電話": "(07)8060705",
      "網址": "http://nkhhs.nkuht.edu.tw/"
    },
    {
      "代碼": "611401",
      "學校名稱": "私立高鳳工家",
      "公/私立": "私立",
      "縣市名稱": "[61]高雄市",
      "地址": "[812]高雄市小港區高松里松崗路119號",
      "電話": "(07)8010857",
      "網址": "http://www.kfvhs.kh.edu.tw"
    },
    {
      "代碼": "613301",
      "學校名稱": "市立小港高中",
      "公/私立": "公立",
      "縣市名稱": "[61]高雄市",
      "地址": "[812]高雄市小港區學府路117號",
      "電話": "(07)8062627",
      "網址": "http://www.hkhs.kh.edu.tw"
    },
    {
      "代碼": "020301",
      "學校名稱": "國立蘭陽女中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[260]宜蘭縣宜蘭市女中路二段355號",
      "電話": "(03)9333819",
      "網址": "http://www.lygsh.ilc.edu.tw"
    },
    {
      "代碼": "020302",
      "學校名稱": "國立宜蘭高中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[260]宜蘭縣宜蘭市復興路三段8號",
      "電話": "(03)9324153",
      "網址": "http://www.ylsh.ilc.edu.tw"
    },
    {
      "代碼": "020308",
      "學校名稱": "國立羅東高中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[265]宜蘭縣羅東鎮公正路324號",
      "電話": "(03)9567645 ",
      "網址": "http://www.ltsh.ilc.edu.tw"
    },
    {
      "代碼": "020403",
      "學校名稱": "國立宜蘭高商",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[260]宜蘭縣宜蘭市延平路50號",
      "電話": "(039)384147",
      "網址": "http://www.ilvs.ilc.edu.tw"
    },
    {
      "代碼": "020404",
      "學校名稱": "國立羅東高商",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[265]宜蘭縣羅東鎮中山路4段360號",
      "電話": "(039)512875",
      "網址": "http://www.ltcvs.ilc.edu.tw"
    },
    {
      "代碼": "020405",
      "學校名稱": "國立蘇澳海事",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[270]宜蘭縣蘇澳鎮蘇港路213號",
      "電話": "(039)951661",
      "網址": "http://www.savs.ilc.edu.tw"
    },
    {
      "代碼": "020407",
      "學校名稱": "國立羅東高工",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[269]宜蘭縣冬山鄉廣興路117號",
      "電話": "(039)514196",
      "網址": "http://web.ltivs.ilc.edu.tw"
    },
    {
      "代碼": "020409",
      "學校名稱": "國立頭城家商",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[261]宜蘭縣頭城鎮新興路111號",
      "電話": "(039)771131",
      "網址": "http://www.tcvs.ilc.edu.tw"
    },
    {
      "代碼": "021301",
      "學校名稱": "私立慧燈高中",
      "公/私立": "私立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[264]宜蘭縣員山鄉枕山村枕山路117-60號",
      "電話": "(03)9229968",
      "網址": "http://www.hdsh.ilc.edu.tw"
    },
    {
      "代碼": "021310",
      "學校名稱": "私立中道高中",
      "公/私立": "私立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[263]宜蘭縣壯圍鄉功勞村中央路三段312巷7號",
      "電話": "(03)9306696",
      "網址": "http://www.cdsh.ilc.edu.tw"
    },
    {
      "代碼": "024322",
      "學校名稱": "縣立南澳高中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[272]宜蘭縣南澳鄉蘇花路二段301號",
      "電話": "(03)9981024",
      "網址": "http://www.nojh.ilc.edu.tw"
    },
    {
      "代碼": "024325",
      "學校名稱": "縣立慈心華德福實中",
      "公/私立": "公立",
      "縣市名稱": "[02]宜蘭縣",
      "地址": "[269]宜蘭縣冬山鄉照安路257號",
      "電話": "(039)596222",
      "網址": "http://blog.ilc.edu.tw/blog/blog/25783"
    },
    {
      "代碼": "040302",
      "學校名稱": "國立竹東高中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[310]新竹縣竹東鎮大林路2號",
      "電話": "(03)5962024",
      "網址": "http://www.ctsh.hcc.edu.tw"
    },
    {
      "代碼": "040304",
      "學校名稱": "國立關西高中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[306]新竹縣關西鎮東安里中山東路2號",
      "電話": "(03)5872049",
      "網址": "http://www.khvs.hcc.edu.tw"
    },
    {
      "代碼": "040308",
      "學校名稱": "國立竹北高中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[302]新竹縣竹北市中央路3號",
      "電話": "(03)5517330",
      "網址": "http://www.cpshs.hcc.edu.tw"
    },
    {
      "代碼": "041303",
      "學校名稱": "私立義民高中",
      "公/私立": "私立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[302]新竹縣竹北市中正西路15號",
      "電話": "(03)5552020",
      "網址": "http://www.ymsh.hcc.edu.tw"
    },
    {
      "代碼": "041305",
      "學校名稱": "私立忠信高中",
      "公/私立": "私立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[304]新竹縣新豐鄉忠信街178號",
      "電話": "(03)5595775 ",
      "網址": "http://www.chvs.hcc.edu.tw"
    },
    {
      "代碼": "041306",
      "學校名稱": "私立東泰高中",
      "公/私立": "私立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[310]新竹縣竹東鎮東峰路343號",
      "電話": "(03)5961232",
      "網址": "http://www.ttsh.hcc.edu.tw"
    },
    {
      "代碼": "041307",
      "學校名稱": "私立仰德高中",
      "公/私立": "私立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[304]新竹縣新豐鄉員山村133號",
      "電話": "(03)5592158",
      "網址": "http://www.ydvs.hcc.edu.tw"
    },
    {
      "代碼": "041401",
      "學校名稱": "私立內思高工",
      "公/私立": "私立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[305]新竹縣新埔鎮楊新路一段40號",
      "電話": "(03)5882520",
      "網址": "http://www.savs.hcc.edu.tw"
    },
    {
      "代碼": "044311",
      "學校名稱": "縣立六家高級中學",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[302]新竹縣竹北市嘉興路356號",
      "電話": "(03)5503824",
      "網址": "http://www.ljjh.hcc.edu.tw/bin/home.php"
    },
    {
      "代碼": "044320",
      "學校名稱": "縣立湖口高中",
      "公/私立": "公立",
      "縣市名稱": "[04]新竹縣",
      "地址": "[303]新竹縣湖口鄉中正路二段263巷21號",
      "電話": "(03)5690772",
      "網址": "http://www.hkhs.hcc.edu.tw"
    },
    {
      "代碼": "050303",
      "學校名稱": "國立苗栗高中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[360]苗栗縣苗栗市至公路183號",
      "電話": "(037)320072",
      "網址": "http://www.mlsh.mlc.edu.tw"
    },
    {
      "代碼": "050310",
      "學校名稱": "國立竹南高中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[350]苗栗縣竹南鎮中正路98號",
      "電話": "(037)476855",
      "網址": "http://www.cnsh.mlc.edu.tw"
    },
    {
      "代碼": "050314",
      "學校名稱": "國立卓蘭高中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[369]苗栗縣卓蘭鎮老庄里161號",
      "電話": "(04)25892007",
      "網址": "http://w2.cles.mlc.edu.tw/"
    },
    {
      "代碼": "050315",
      "學校名稱": "國立苑裡高中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[358]苗栗縣苑裡鎮育才街100號",
      "電話": "(037)868680",
      "網址": "http://www.ylsh.mlc.edu.tw"
    },
    {
      "代碼": "050401",
      "學校名稱": "國立大湖農工",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[364]苗栗縣大湖鄉大寮村竹高屋68號",
      "電話": "(037)992216",
      "網址": "http://www.thvs.mlc.edu.tw"
    },
    {
      "代碼": "050404",
      "學校名稱": "國立苗栗農工",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[360]苗栗縣苗栗市經國路二段491號",
      "電話": "(037)329281",
      "網址": "http://www.mlaivs.mlc.edu.tw"
    },
    {
      "代碼": "050407",
      "學校名稱": "國立苗栗高商",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[360]苗栗縣苗栗市電台街7號",
      "電話": "(037)356001",
      "網址": "http://www.mlvs.mlc.edu.tw"
    },
    {
      "代碼": "051302",
      "學校名稱": "私立君毅高中",
      "公/私立": "私立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[350]苗栗縣竹南鎮公義路245號",
      "電話": "(037)622009",
      "網址": "http://www.cish.mlc.edu.tw"
    },
    {
      "代碼": "051305",
      "學校名稱": "私立大成高中",
      "公/私立": "私立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[351]苗栗縣頭份市新華里下新店65號",
      "電話": "(037)663371",
      "網址": "http://www.tcsh.mlc.edu.tw"
    },
    {
      "代碼": "051306",
      "學校名稱": "私立建臺高中",
      "公/私立": "私立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[360]苗栗縣苗栗市福麗里至公路251號",
      "電話": "(037)353270",
      "網址": "http://www.ctsh.mlc.edu.tw"
    },
    {
      "代碼": "051307",
      "學校名稱": "私立全人實驗高中",
      "公/私立": "私立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[369]苗栗縣卓蘭鎮內灣里內灣141-3號",
      "電話": "(04)25896909",
      "網址": "http://holistic.org.tw/"
    },
    {
      "代碼": "051408",
      "學校名稱": "私立中興商工",
      "公/私立": "私立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[350]苗栗縣竹南鎮大營路211號",
      "電話": "(037)467360",
      "網址": "http://www.csvs.mlc.edu.tw"
    },
    {
      "代碼": "051411",
      "學校名稱": "私立育民工家",
      "公/私立": "私立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[360]苗栗縣苗栗市水源里育民街37號",
      "電話": "(037)353888",
      "網址": "http://www.ymvs.mlc.edu.tw"
    },
    {
      "代碼": "051412",
      "學校名稱": "私立賢德工商",
      "公/私立": "私立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[357]苗栗縣通霄鎮五北里74之1號",
      "電話": "(037)751011",
      "網址": "http://www.sdvs.mlc.edu.tw"
    },
    {
      "代碼": "051413",
      "學校名稱": "私立龍德家商",
      "公/私立": "私立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[358]苗栗縣苑裡鎮房裡里南房75之1號",
      "電話": "(037)851277",
      "網址": "http://www.ldvs.mlc.edu.tw/"
    },
    {
      "代碼": "054308",
      "學校名稱": "縣立三義高中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[367]苗栗縣三義鄉廣盛村11鄰122號",
      "電話": "(037)872015",
      "網址": "http://web.sjh.mlc.edu.tw"
    },
    {
      "代碼": "054309",
      "學校名稱": "縣立苑裡高中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[358]苗栗縣苑裡鎮客庄里1鄰初中路2號",
      "電話": "(037)861042",
      "網址": "http://www.yljh.mlc.edu.tw"
    },
    {
      "代碼": "054317",
      "學校名稱": "縣立興華高中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[351]苗栗縣頭份市中正一路401號",
      "電話": "(037)663403",
      "網址": "http://www.shhs.mlc.edu.tw"
    },
    {
      "代碼": "054333",
      "學校名稱": "縣立大同高中",
      "公/私立": "公立",
      "縣市名稱": "[05]苗栗縣",
      "地址": "[350]苗栗縣竹南鎮大埔里六鄰公義路890號",
      "電話": "(037)580566",
      "網址": "http://www.dtjh.mlc.edu.tw"
    },
    {
      "代碼": "070301",
      "學校名稱": "國立彰化女中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市光復路62號",
      "電話": "(04)7240042",
      "網址": "http://www.chgsh.chc.edu.tw"
    },
    {
      "代碼": "070304",
      "學校名稱": "國立員林高中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[510]彰化縣員林市靜修路79號",
      "電話": "(04)8320364",
      "網址": "http://www.ylsh.chc.edu.tw"
    },
    {
      "代碼": "070307",
      "學校名稱": "國立彰化高中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市介壽里中興路78號",
      "電話": "(04)7222121",
      "網址": "http://www.chsh.chc.edu.tw"
    },
    {
      "代碼": "070316",
      "學校名稱": "國立鹿港高中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[505]彰化縣鹿港鎮東石里中山路661號",
      "電話": "(04)7772403",
      "網址": "http://www.lksh.chc.edu.tw"
    },
    {
      "代碼": "070319",
      "學校名稱": "國立溪湖高中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[514]彰化縣溪湖鎮湖東里大溪路二段86號",
      "電話": "(04)8826436",
      "網址": "http://www.hhsh.chc.edu.tw"
    },
    {
      "代碼": "070401",
      "學校名稱": "國立彰師附工",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市和調里工校街1號",
      "電話": "(04)7252541",
      "網址": "http://www.sivs.chc.edu.tw"
    },
    {
      "代碼": "070402",
      "學校名稱": "國立永靖高工",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[512]彰化縣永靖鄉永北村永坡路101號",
      "電話": "(04)8221810",
      "網址": "http://www.yjvs.chc.edu.tw"
    },
    {
      "代碼": "070403",
      "學校名稱": "國立二林工商",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[526]彰化縣二林鎮豐田里斗苑路四段500號",
      "電話": "(04)8962132",
      "網址": "http://www.elvs.chc.edu.tw"
    },
    {
      "代碼": "070405",
      "學校名稱": "國立秀水高工",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[504]彰化縣秀水鄉福安村中山路364號",
      "電話": "(04)7697021",
      "網址": "http://www.ssivs.chc.edu.tw"
    },
    {
      "代碼": "070406",
      "學校名稱": "國立彰化高商",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市華陽里南郭路一段326號",
      "電話": "(04)7262623",
      "網址": "http://w2.chsc.tw/"
    },
    {
      "代碼": "070408",
      "學校名稱": "國立員林農工",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[510]彰化縣員林市員水路二段313號",
      "電話": "(04)8360105",
      "網址": "http://www.ylvs.chc.edu.tw"
    },
    {
      "代碼": "070409",
      "學校名稱": "國立員林崇實高工",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[510]彰化縣員林市育英路103號",
      "電話": "(04)8347106",
      "網址": "http://www.csvs.chc.edu.tw"
    },
    {
      "代碼": "070410",
      "學校名稱": "國立員林家商",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[510]彰化縣員林市中正路56號",
      "電話": "(04)8320260",
      "網址": "http://www.ylhcvs.chc.edu.tw"
    },
    {
      "代碼": "070415",
      "學校名稱": "國立北斗家商",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[521]彰化縣北斗鎮大道里學府路50號",
      "電話": "(04)8882224",
      "網址": "http://www.pthc.chc.edu.tw"
    },
    {
      "代碼": "071311",
      "學校名稱": "私立精誠高中",
      "公/私立": "私立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市林森路200號",
      "電話": "(04)7622790",
      "網址": "http://www.cchs.chc.edu.tw"
    },
    {
      "代碼": "071317",
      "學校名稱": "私立文興高中",
      "公/私立": "私立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[520]彰化縣田中鎮員集路三段93號",
      "電話": "(04)8753889 ",
      "網址": "http://web1.hshs.chc.edu.tw/bin/home.php"
    },
    {
      "代碼": "071318",
      "學校名稱": "財團法人正德高中",
      "公/私立": "私立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市莿桐里彰水路145號",
      "電話": "(04)7524109",
      "網址": "http://www.zdvs.chc.edu.tw"
    },
    {
      "代碼": "071413",
      "學校名稱": "私立大慶商工",
      "公/私立": "私立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[510]彰化縣員林市山腳路二段206號",
      "電話": "(04)8311005",
      "網址": "http://www.dcvs.chc.edu.tw"
    },
    {
      "代碼": "071414",
      "學校名稱": "私立達德商工",
      "公/私立": "私立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[520]彰化縣田中鎮中南路二段277號",
      "電話": "(04)8753929",
      "網址": "http://www.tdvs.chc.edu.tw"
    },
    {
      "代碼": "074308",
      "學校名稱": "縣立彰化藝術高中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[500]彰化縣彰化市卦山路13號",
      "電話": "(04)7222844",
      "網址": "http://www.chash.chc.edu.tw"
    },
    {
      "代碼": "074313",
      "學校名稱": "縣立二林高中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[526]彰化縣二林鎮中西里二城路6號",
      "電話": "(04)8960121",
      "網址": "http://www.elsh.chc.edu.tw"
    },
    {
      "代碼": "074323",
      "學校名稱": "縣立和美高中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[508]彰化縣和美鎮西園路三十一號",
      "電話": "(04)7552043",
      "網址": "http://www.hmjh.chc.edu.tw/"
    },
    {
      "代碼": "074328",
      "學校名稱": "縣立田中高中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[520]彰化縣田中鎮文化街23號",
      "電話": "(04)8745820",
      "網址": "http://www.tcjh.chc.edu.tw/ischool/publish_page/0/"
    },
    {
      "代碼": "074339",
      "學校名稱": "縣立成功高中",
      "公/私立": "公立",
      "縣市名稱": "[07]彰化縣",
      "地址": "[514]彰化縣溪湖鎮福德路310號",
      "電話": "(04)8819676",
      "網址": "http://www.ckjh.chc.edu.tw/"
    },
    {
      "代碼": "080302",
      "學校名稱": "國立南投高中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[540]南投縣南投市建國路137號",
      "電話": "(049)2231175",
      "網址": "http://www.ntsh.ntct.edu.tw"
    },
    {
      "代碼": "080305",
      "學校名稱": "國立中興高中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[540]南投縣南投市中興新村中學路2號",
      "電話": "(049)2331014",
      "網址": "http://www.chsh.ntct.edu.tw"
    },
    {
      "代碼": "080307",
      "學校名稱": "國立竹山高中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[557]南投縣竹山鎮下橫街253號",
      "電話": "(049)2643344",
      "網址": "http://www.cshs.ntct.edu.tw"
    },
    {
      "代碼": "080308",
      "學校名稱": "國立暨大附中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[545]南投縣埔里鎮鐵山路1-6號",
      "電話": "(049)2913483",
      "網址": "http://www.pshs.ntct.edu.tw"
    },
    {
      "代碼": "080401",
      "學校名稱": "國立仁愛高農",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[546]南投縣仁愛鄉大同村山農巷27號",
      "電話": "(049)2802619",
      "網址": "http://www.ravs.ntct.edu.tw"
    },
    {
      "代碼": "080403",
      "學校名稱": "國立埔里高工",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[545]南投縣埔里鎮中山路一段435號",
      "電話": "(049)2982225",
      "網址": "http://www.plvs.ntct.edu.tw"
    },
    {
      "代碼": "080404",
      "學校名稱": "國立南投高商",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[540]南投縣南投市三興里彰南路一段993號",
      "電話": "(049)2222269",
      "網址": "http://www.pntcv.ntct.edu.tw"
    },
    {
      "代碼": "080406",
      "學校名稱": "國立草屯商工",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[542]南投縣草屯鎮芬草路二段736號",
      "電話": "(049)2362082",
      "網址": "http://www.ttvs.ntct.edu.tw"
    },
    {
      "代碼": "080410",
      "學校名稱": "國立水里商工",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[553]南投縣水里鄉南湖路1號",
      "電話": "(049)2870666",
      "網址": "http://www.slvs.ntct.edu.tw"
    },
    {
      "代碼": "081311",
      "學校名稱": "私立五育高中",
      "公/私立": "私立",
      "縣市名稱": "[08]南投縣",
      "地址": "[540]南投縣南投市樂利路200號",
      "電話": "(049)2246346",
      "網址": "http://www.wu-yu.ntct.edu.tw"
    },
    {
      "代碼": "081312",
      "學校名稱": "私立三育高中",
      "公/私立": "私立",
      "縣市名稱": "[08]南投縣",
      "地址": "[555]南投縣魚池鄉瓊文巷39號",
      "電話": "(049)2897212",
      "網址": "http://www.taa.ntct.edu.tw"
    },
    {
      "代碼": "081313",
      "學校名稱": "私立弘明實驗高中",
      "公/私立": "私立",
      "縣市名稱": "[08]南投縣",
      "地址": "[551]南投縣名間鄉東湖村大老巷102號",
      "電話": "(049)2731799",
      "網址": "http://www.holdmean.org.tw"
    },
    {
      "代碼": "081314",
      "學校名稱": "私立普台高中",
      "公/私立": "私立",
      "縣市名稱": "[08]南投縣",
      "地址": "[545]南投縣埔里鎮一新里中台路5號",
      "電話": "(049)2932899",
      "網址": "http://www.ctworld.org.tw/ptshs/index.htm"
    },
    {
      "代碼": "081409",
      "學校名稱": "私立同德家商",
      "公/私立": "私立",
      "縣市名稱": "[08]南投縣",
      "地址": "[542]南投縣草屯鎮中正路培英巷8號",
      "電話": "(049)2553109",
      "網址": "http://www.tdvs.ntct.edu.tw"
    },
    {
      "代碼": "084309",
      "學校名稱": "縣立旭光高中",
      "公/私立": "公立",
      "縣市名稱": "[08]南投縣",
      "地址": "[542]南投縣草屯鎮中正路568-23號",
      "電話": "(049)2563472",
      "網址": "http://www.skjhs.ntct.edu.tw"
    },
    {
      "代碼": "090305",
      "學校名稱": "國立斗六高中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[640]雲林縣斗六市民生路224號",
      "電話": "(05)5322039",
      "網址": "http://www.tlsh.ylc.edu.tw"
    },
    {
      "代碼": "090306",
      "學校名稱": "國立北港高中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[651]雲林縣北港鎮成功路26號",
      "電話": "(05)7821411",
      "網址": "http://www.pksh.ylc.edu.tw"
    },
    {
      "代碼": "090315",
      "學校名稱": "國立虎尾高中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[632]雲林縣虎尾鎮光復路222號",
      "電話": "(05)6322121",
      "網址": "http://www.hwsh.ylc.edu.tw"
    },
    {
      "代碼": "090401",
      "學校名稱": "國立虎尾農工",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[632]雲林縣虎尾鎮公安里博愛路65號",
      "電話": "(05)6322767",
      "網址": "http://www.hwaivs.ylc.edu.tw"
    },
    {
      "代碼": "090402",
      "學校名稱": "國立西螺農工",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[648]雲林縣西螺鎮大同路4號",
      "電話": "(05)5862024",
      "網址": "http://www.hlvs.ylc.edu.tw"
    },
    {
      "代碼": "090403",
      "學校名稱": "國立斗六家商",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[640]雲林縣斗六市成功路120號",
      "電話": "(05)5322147",
      "網址": "http://www.tlhc.ylc.edu.tw"
    },
    {
      "代碼": "090404",
      "學校名稱": "國立北港農工",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[651]雲林縣北港鎮太平路80號",
      "電話": "(05)7832246",
      "網址": "http://www.pkvs.ylc.edu.tw"
    },
    {
      "代碼": "090413",
      "學校名稱": "國立土庫商工",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[633]雲林縣土庫鎮中央路2號",
      "電話": "(05)6622538",
      "網址": "http://www.tkvs.ylc.edu.tw"
    },
    {
      "代碼": "091307",
      "學校名稱": "私立永年高中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[633]雲林縣土庫鎮建國路13號",
      "電話": "(05)6622540",
      "網址": "http://www.ynhs.ylc.edu.tw"
    },
    {
      "代碼": "091308",
      "學校名稱": "私立正心高中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[640]雲林縣斗六市虎溪里正心路1號",
      "電話": "(05)5512502",
      "網址": "http://www.shsh.ylc.edu.tw"
    },
    {
      "代碼": "091311",
      "學校名稱": "私立文生高中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[654]雲林縣四湖鄉中正路509號",
      "電話": "(05)7872024",
      "網址": "http://www.svsh.ylc.edu.tw"
    },
    {
      "代碼": "091312",
      "學校名稱": "私立巨人高中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[651]雲林縣北港鎮大同路647號",
      "電話": "(05)7835937",
      "網址": "http://www.tssh.ylc.edu.tw"
    },
    {
      "代碼": "091316",
      "學校名稱": "私立揚子高中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[632]雲林縣虎尾鎮潁川里林森路二段541號",
      "電話": "(05)6330181",
      "網址": "http://www.ytjh.ylc.edu.tw"
    },
    {
      "代碼": "091318",
      "學校名稱": "財團法人義峰高中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[643]雲林縣林內鄉烏麻村長源201號",
      "電話": "(05)5800099",
      "網址": "http://www.yfsh.ylc.edu.tw"
    },
    {
      "代碼": "091319",
      "學校名稱": "福智高中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[646]雲林縣古坑鄉麻園村平和23號",
      "電話": "(05)5828223",
      "網址": "http://bwsh.ylc.edu.tw"
    },
    {
      "代碼": "091320",
      "學校名稱": "雲林縣維多利亞實驗高中",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[640]雲林縣斗六市鎮南路1110號",
      "電話": "(05)5378899",
      "網址": "http://www.victoria.ylc.edu.tw"
    },
    {
      "代碼": "091410",
      "學校名稱": "私立大成商工",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[632]雲林縣虎尾鎮成平街21號",
      "電話": "(05)6322534",
      "網址": "http://www.tcvhs.ylc.edu.tw"
    },
    {
      "代碼": "091414",
      "學校名稱": "私立大德工商",
      "公/私立": "私立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[630]雲林縣斗南鎮大同路400號",
      "電話": "(05)5970977",
      "網址": "http://www.ddvs.ylc.edu.tw"
    },
    {
      "代碼": "094301",
      "學校名稱": "縣立斗南高中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[630]雲林縣斗南鎮中山路212號",
      "電話": "(05)5972059",
      "網址": "http://www.tnjh.ylc.edu.tw"
    },
    {
      "代碼": "094307",
      "學校名稱": "縣立麥寮高中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[638]雲林縣麥寮鄉中興路310號",
      "電話": "(05)6932050",
      "網址": "http://www.mljh.ylc.edu.tw"
    },
    {
      "代碼": "094308",
      "學校名稱": "縣立古坑華德福實驗高級中學",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[646]雲林縣古坑鄉荷苞村山峰17號",
      "電話": "(05)5822075",
      "網址": "http://tw.school.uschoolnet.com/?id=es00003779"
    },
    {
      "代碼": "094326",
      "學校名稱": "縣立蔦松藝術高中",
      "公/私立": "公立",
      "縣市名稱": "[09]雲林縣",
      "地址": "[652]雲林縣水林鄉松北村1之1號",
      "電話": "(05)7841801",
      "網址": "http://www.nsjh.ylc.edu.tw/"
    },
    {
      "代碼": "100301",
      "學校名稱": "國立東石高中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[613]嘉義縣朴子市大鄉里253號",
      "電話": "(05)3794180",
      "網址": "http://www.tssh.cyc.edu.tw"
    },
    {
      "代碼": "100302",
      "學校名稱": "國立新港藝術高中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[616]嘉義縣新港鄉宮後村藝高路1號",
      "電話": "(05)3747060",
      "網址": "http://140.130.169.1"
    },
    {
      "代碼": "100402",
      "學校名稱": "國立民雄農工",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[621]嘉義縣民雄鄉文隆村81號",
      "電話": "(05)2267120",
      "網址": "http://www.mhvs.cyc.edu.tw"
    },
    {
      "代碼": "101303",
      "學校名稱": "私立同濟高中",
      "公/私立": "私立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[622]嘉義縣大林鎮中興路二段303號",
      "電話": "(05)2652248",
      "網址": "http://www.tjsh.cyc.edu.tw"
    },
    {
      "代碼": "101304",
      "學校名稱": "私立協同高中",
      "公/私立": "私立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[621]嘉義縣民雄鄉建國路二段31號",
      "電話": "(05)2213045",
      "網址": "http://www.cmsh.cyc.edu.tw"
    },
    {
      "代碼": "101405",
      "學校名稱": "私立協志工商",
      "公/私立": "私立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[621]嘉義縣民雄鄉東榮村建國路2段285巷11號",
      "電話": "(05)2264264",
      "網址": "http://www.ccivs.cyc.edu.tw"
    },
    {
      "代碼": "101406",
      "學校名稱": "私立萬能工商",
      "公/私立": "私立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[608]嘉義縣水上鄉萬能路1號",
      "電話": "(05)2687777",
      "網址": "http://www.wnvs.cyc.edu.tw"
    },
    {
      "代碼": "101407",
      "學校名稱": "私立弘德工商",
      "公/私立": "私立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[613]嘉義縣朴子市崁後里54之2號",
      "電話": "(05)3691473",
      "網址": "http://www.cdvs.cyc.edu.tw"
    },
    {
      "代碼": "104319",
      "學校名稱": "縣立竹崎高中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[604]嘉義縣竹崎鄉竹崎村文化路23號",
      "電話": "(05)2611006",
      "網址": "http://www.ccjh.cyc.edu.tw"
    },
    {
      "代碼": "104326",
      "學校名稱": "縣立永慶高中",
      "公/私立": "公立",
      "縣市名稱": "[10]嘉義縣",
      "地址": "[612]嘉義縣太保市信義二路1號",
      "電話": "(05)3627226",
      "網址": "http://www.ycsh.cyc.edu.tw"
    },
    {
      "代碼": "130302",
      "學校名稱": "國立屏東女中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市仁愛路94號",
      "電話": "(08)7362204",
      "網址": "http://www.ptgsh.ptc.edu.tw"
    },
    {
      "代碼": "130305",
      "學校名稱": "國立屏東高中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市忠孝路231號",
      "電話": "(08)7667053",
      "網址": "http://www.pths.ptc.edu.tw"
    },
    {
      "代碼": "130306",
      "學校名稱": "國立潮州高中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[920]屏東縣潮州鎮中山路11號",
      "電話": "(08)7882017",
      "網址": "http://www.ccsh.ptc.edu.tw"
    },
    {
      "代碼": "130322",
      "學校名稱": "國立屏北高中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[907]屏東縣鹽埔鄉彭厝村莒光路168號",
      "電話": "(08)7937493",
      "網址": "http://www.ppsh.ptc.edu.tw"
    },
    {
      "代碼": "130401",
      "學校名稱": "國立內埔農工",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[912]屏東縣內埔鄉水門村成功路83號",
      "電話": "(08)7991103",
      "網址": "http://www.npvs.ptc.edu.tw"
    },
    {
      "代碼": "130403",
      "學校名稱": "國立屏東高工",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市建國路25號",
      "電話": "(08)7523781",
      "網址": "http://www.ptivs.ptc.edu.tw"
    },
    {
      "代碼": "130404",
      "學校名稱": "國立佳冬高農",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[931]屏東縣佳冬鄉佳冬村佳農街67號",
      "電話": "(08)8662726",
      "網址": "http://www.ctvs.ptc.edu.tw"
    },
    {
      "代碼": "130410",
      "學校名稱": "國立東港海事",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[928]屏東縣東港鎮豐漁街66號",
      "電話": "(08)8333131",
      "網址": "http://www.tkms.ptc.edu.tw"
    },
    {
      "代碼": "130417",
      "學校名稱": "國立恆春工商",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[946]屏東縣恆春鎮恆南路38號",
      "電話": "(08)8892010",
      "網址": "http://www.hcvs.ptc.edu.tw"
    },
    {
      "代碼": "131307",
      "學校名稱": "財團法人屏榮高中",
      "公/私立": "私立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市豐田里民學路100號",
      "電話": "(08)7223409",
      "網址": "http://www.prhs.ptc.edu.tw"
    },
    {
      "代碼": "131308",
      "學校名稱": "私立陸興高中",
      "公/私立": "私立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市豐田里廣東路150號",
      "電話": "(08)7225837",
      "網址": "http://www.lssh.ptc.edu.tw"
    },
    {
      "代碼": "131311",
      "學校名稱": "私立美和高中",
      "公/私立": "私立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[912]屏東縣內埔鄉美和村學人路323號",
      "電話": "(08)7792045",
      "網址": "http://www.mhsh.ptc.edu.tw"
    },
    {
      "代碼": "131409",
      "學校名稱": "私立民生家商",
      "公/私立": "私立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市民生東路12號",
      "電話": "(08)7223029",
      "網址": "http://www.msvs.ptc.edu.tw"
    },
    {
      "代碼": "131418",
      "學校名稱": "私立日新工商",
      "公/私立": "私立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[920]屏東縣潮州鎮太平路224號",
      "電話": "(08)7882343",
      "網址": "http://www.jhvs.ptc.edu.tw"
    },
    {
      "代碼": "134304",
      "學校名稱": "縣立大同高中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[900]屏東縣屏東市和平路429號",
      "電話": "(08)7663916",
      "網址": "http://www.dtjh.ptc.edu.tw"
    },
    {
      "代碼": "134321",
      "學校名稱": "縣立枋寮高中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[940]屏東縣枋寮鄉新龍村義民路3號",
      "電話": "(08)8782095",
      "網址": "http://www.flhs.ptc.edu.tw"
    },
    {
      "代碼": "134324",
      "學校名稱": "縣立東港高中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[928]屏東縣東港鎮船頭路1號",
      "電話": "(08)8322014",
      "網址": "http://www.dkjh.ptc.edu.tw"
    },
    {
      "代碼": "134334",
      "學校名稱": "縣立來義高中",
      "公/私立": "公立",
      "縣市名稱": "[13]屏東縣",
      "地址": "[922]屏東縣來義鄉古樓村中正路147號",
      "電話": "(08)7850086",
      "網址": "http://www.lyhs.ptc.edu.tw"
    },
    {
      "代碼": "140301",
      "學校名稱": "國立臺東大學附屬體育高中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市體中路1號",
      "電話": "(089)383629",
      "網址": "http://www.ntpehs.ttct.edu.tw"
    },
    {
      "代碼": "140302",
      "學校名稱": "國立臺東女中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市四維路一段690號",
      "電話": "(08)9321268",
      "網址": "http://www.tgsh.ttct.edu.tw"
    },
    {
      "代碼": "140303",
      "學校名稱": "國立臺東高中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市中華路一段721號",
      "電話": "(08)9322070",
      "網址": "https://www.pttsh.ttct.edu.tw"
    },
    {
      "代碼": "140404",
      "學校名稱": "國立關山工商",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[956]臺東縣關山鎮民權路58號",
      "電話": "(089)811006",
      "網址": "http://www.ksvs.ttct.edu.tw"
    },
    {
      "代碼": "140405",
      "學校名稱": "國立臺東高商",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市正氣路440號",
      "電話": "(089)350575",
      "網址": "http://www.tscvs.ttct.edu.tw"
    },
    {
      "代碼": "140408",
      "學校名稱": "國立成功商水",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[961]臺東縣成功鎮太平路52號",
      "電話": "(089)850011",
      "網址": "http://www.ckvs.ttct.edu.tw"
    },
    {
      "代碼": "141301",
      "學校名稱": "臺東縣均一高中",
      "公/私立": "私立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市中興路二段366巷36號",
      "電話": "(089)223301",
      "網址": "http://junyi.tw"
    },
    {
      "代碼": "141307",
      "學校名稱": "私立育仁高中",
      "公/私立": "私立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市中興路四段485號",
      "電話": "(08)9382839",
      "網址": "http://www.lotus.ttct.edu.tw"
    },
    {
      "代碼": "141406",
      "學校名稱": "私立公東高工",
      "公/私立": "私立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市中興路一段560號",
      "電話": "(089)222877",
      "網址": "http://www.ktus.ttct.edu.tw"
    },
    {
      "代碼": "144322",
      "學校名稱": "縣立蘭嶼高中",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[952]臺東縣蘭嶼鄉椰油村37號",
      "電話": "(089)732016",
      "網址": "http://www.layjh.ttct.edu.tw"
    },
    {
      "代碼": "150302",
      "學校名稱": "國立花蓮女中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[970]花蓮縣花蓮市菁華街2號",
      "電話": "(03)8321202",
      "網址": "http://www.hlgs.hlc.edu.tw"
    },
    {
      "代碼": "150303",
      "學校名稱": "國立花蓮高中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[970]花蓮縣花蓮市民權路42號",
      "電話": "(03)8242236",
      "網址": "http://www.hlhs.hlc.edu.tw"
    },
    {
      "代碼": "150309",
      "學校名稱": "國立玉里高中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[981]花蓮縣玉里鎮中華路424號",
      "電話": "(03)8886171",
      "網址": "http://www.ylsh.hlc.edu.tw"
    },
    {
      "代碼": "150401",
      "學校名稱": "國立花蓮高農",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[970]花蓮縣花蓮市建國路161號",
      "電話": "(03)8312301",
      "網址": "http://www.hla.hlc.edu.tw"
    },
    {
      "代碼": "150404",
      "學校名稱": "國立花蓮高工",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[970]花蓮縣花蓮市府前路27號",
      "電話": "(03)8226108",
      "網址": "http://www.hlis.hlc.edu.tw"
    },
    {
      "代碼": "150405",
      "學校名稱": "國立花蓮高商",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[970]花蓮縣花蓮市中山路418號",
      "電話": "(03)8322532",
      "網址": "http://www.hlbh.hlc.edu.tw"
    },
    {
      "代碼": "150411",
      "學校名稱": "國立光復商工",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[976]花蓮縣光復鄉林森路100號",
      "電話": "(03)8700245",
      "網址": "http://www.kfcivs.hlc.edu.tw"
    },
    {
      "代碼": "151306",
      "學校名稱": "私立海星高中",
      "公/私立": "私立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[971]花蓮縣新城鄉嘉新村嘉新路36號",
      "電話": "(03)8223116",
      "網址": "http://www.smhs.hlc.edu.tw"
    },
    {
      "代碼": "151307",
      "學校名稱": "私立四維高中",
      "公/私立": "私立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[970]花蓮縣花蓮市中山路一段200號",
      "電話": "(03)8561455",
      "網址": "http://www.swsh.hlc.edu.tw"
    },
    {
      "代碼": "151312",
      "學校名稱": "財團法人慈濟大學附中",
      "公/私立": "私立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[970]花蓮縣花蓮市介仁街178號",
      "電話": "(03)8572823",
      "網址": "http://www.tcsh.hlc.edu.tw"
    },
    {
      "代碼": "151410",
      "學校名稱": "花蓮縣上騰工商",
      "公/私立": "私立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[973]花蓮縣吉安鄉吉安村吉興四街101號",
      "電話": "(03)8538565",
      "網址": "http://www.chvs.hlc.edu.tw"
    },
    {
      "代碼": "154301",
      "學校名稱": "花蓮縣立體育高中",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[970]花蓮縣花蓮市達固湖灣大路21號",
      "電話": "(03)8462610",
      "網址": "http://www.hpehs.hlc.edu.tw"
    },
    {
      "代碼": "154399",
      "學校名稱": "縣立南平中學",
      "公/私立": "公立",
      "縣市名稱": "[15]花蓮縣",
      "地址": "[975]花蓮縣鳳林鎮八德路62號",
      "電話": "(03)8772586",
      "網址": "http://www.nphs.hlc.edu.tw"
    },
    {
      "代碼": "160302",
      "學校名稱": "國立馬公高中",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[880]澎湖縣馬公市中華路369號",
      "電話": "(06)9272867",
      "網址": "http://www.mksh.phc.edu.tw"
    },
    {
      "代碼": "160401",
      "學校名稱": "國立澎湖海事水產",
      "公/私立": "公立",
      "縣市名稱": "[16]澎湖縣",
      "地址": "[880]澎湖縣馬公市中興里民族路63號",
      "電話": "(06)9261101",
      "網址": "http://www.phmhs.phc.edu.tw"
    },
    {
      "代碼": "170301",
      "學校名稱": "國立基隆女中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[201]基隆市信義區東信路324號",
      "電話": "(02)24278274     ",
      "網址": "http://www.klgsh.kl.edu.tw"
    },
    {
      "代碼": "170302",
      "學校名稱": "國立基隆高中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[205]基隆市暖暖區源遠路20號",
      "電話": "(02)24582051",
      "網址": "http://www.klsh.kl.edu.tw"
    },
    {
      "代碼": "170403",
      "學校名稱": "國立基隆海事",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[202]基隆市中正區祥豐街246號",
      "電話": "(02)24633655",
      "網址": "http://www.klvs.kl.edu.tw"
    },
    {
      "代碼": "170404",
      "學校名稱": "國立基隆商工",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[206]基隆市七堵區東新街22號",
      "電話": "(02)24567126",
      "網址": "http://www.klcivs.kl.edu.tw"
    },
    {
      "代碼": "171306",
      "學校名稱": "私立二信高中",
      "公/私立": "私立",
      "縣市名稱": "[17]基隆市",
      "地址": "[202]基隆市中正區立德路243號",
      "電話": "(02)24623131",
      "網址": "http://www.essh.kl.edu.tw/essh/index.asp"
    },
    {
      "代碼": "171308",
      "學校名稱": "輔大聖心高中",
      "公/私立": "私立",
      "縣市名稱": "[17]基隆市",
      "地址": "[203]基隆市中山區西定路166號",
      "電話": "(02)24282454",
      "網址": "http://www.shsh.kl.edu.tw"
    },
    {
      "代碼": "171405",
      "學校名稱": "私立光隆家商",
      "公/私立": "私立",
      "縣市名稱": "[17]基隆市",
      "地址": "[201]基隆市信義區信二路264號",
      "電話": "(02)24222237",
      "網址": "http://www.klhcvs.kl.edu.tw"
    },
    {
      "代碼": "171407",
      "學校名稱": "私立培德工家",
      "公/私立": "私立",
      "縣市名稱": "[17]基隆市",
      "地址": "[201]基隆市信義區培德路73號",
      "電話": "(02)24652121",
      "網址": "http://www.ptvs.kl.edu.tw"
    },
    {
      "代碼": "173304",
      "學校名稱": "市立中山高中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[203]基隆市中山區文化路162號",
      "電話": "(02)24248191",
      "網址": "http://www.csjh.kl.edu.tw"
    },
    {
      "代碼": "173306",
      "學校名稱": "市立安樂高中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[204]基隆市安樂區安一路360號",
      "電話": "(02)24236600",
      "網址": "http://www.aljh.kl.edu.tw"
    },
    {
      "代碼": "173307",
      "學校名稱": "市立暖暖高中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[205]基隆市暖暖區暖中路112號",
      "電話": "(02)24575534",
      "網址": "http://www.nnjh.kl.edu.tw"
    },
    {
      "代碼": "173314",
      "學校名稱": "市立八斗高中",
      "公/私立": "公立",
      "縣市名稱": "[17]基隆市",
      "地址": "[202]基隆市中正區新豐街100號",
      "電話": "(02)24692366",
      "網址": "http://www.bdjh.kl.edu.tw"
    },
    {
      "代碼": "180301",
      "學校名稱": "國立科學工業園區實驗高中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區介壽路300號",
      "電話": "(03)5777011",
      "網址": "http://www.nehs.hc.edu.tw"
    },
    {
      "代碼": "180302",
      "學校名稱": "國立新竹女中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區中華路二段270號",
      "電話": "(03)5456611",
      "網址": "http://www.hgsh.hc.edu.tw"
    },
    {
      "代碼": "180309",
      "學校名稱": "國立新竹高中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區學府路36號",
      "電話": "(03)5736666  ",
      "網址": "http://www.hchs.hc.edu.tw"
    },
    {
      "代碼": "180403",
      "學校名稱": "國立新竹高商",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區學府路128號",
      "電話": "(03)5722150",
      "網址": "http://www.hccvs.hc.edu.tw"
    },
    {
      "代碼": "180404",
      "學校名稱": "國立新竹高工",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區中華路二段2號",
      "電話": "(03)5322175",
      "網址": "http://web.hcvs.hc.edu.tw"
    },
    {
      "代碼": "181305",
      "學校名稱": "私立光復高中",
      "公/私立": "私立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區光復路二段153號",
      "電話": "(03)5753698",
      "網址": "http://www.kfsh.hc.edu.tw"
    },
    {
      "代碼": "181306",
      "學校名稱": "私立曙光女中",
      "公/私立": "私立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區北大路61號",
      "電話": "(03)5325709",
      "網址": "http://www.sggs.hc.edu.tw"
    },
    {
      "代碼": "181307",
      "學校名稱": "私立磐石高中",
      "公/私立": "私立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市北區西大路683號",
      "電話": "(03)5223946",
      "網址": "http://www.sphs.hc.edu.tw"
    },
    {
      "代碼": "181308",
      "學校名稱": "私立世界高中",
      "公/私立": "私立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區光復路一段257號",
      "電話": "(03)5783271",
      "網址": "http://www.wvs.hc.edu.tw"
    },
    {
      "代碼": "183306",
      "學校名稱": "市立成德高中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市北區崧嶺路128巷38號",
      "電話": "(03)5258748",
      "網址": "http://www.cdjh.hc.edu.tw"
    },
    {
      "代碼": "183307",
      "學校名稱": "市立香山高中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市香山區元培街124號",
      "電話": "(03)5384332",
      "網址": "http://www.hhjh.hc.edu.tw"
    },
    {
      "代碼": "183313",
      "學校名稱": "市立建功高中",
      "公/私立": "公立",
      "縣市名稱": "[18]新竹市",
      "地址": "[300]新竹市東區建功二路17號",
      "電話": "(03)5745892",
      "網址": "http://www.ckjh.hc.edu.tw"
    },
    {
      "代碼": "200302",
      "學校名稱": "國立嘉義女中",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市西區垂楊路243號",
      "電話": "(05)2254603",
      "網址": "http://www.cygsh.cy.edu.tw"
    },
    {
      "代碼": "200303",
      "學校名稱": "國立嘉義高中",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區大雅路二段738號",
      "電話": "(05)2762804",
      "網址": "http://www.cysh.cy.edu.tw"
    },
    {
      "代碼": "200401",
      "學校名稱": "國立華南高商",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區光彩街69號",
      "電話": "(05)2787140",
      "網址": "http://www.phnvs.cy.edu.tw"
    },
    {
      "代碼": "200405",
      "學校名稱": "國立嘉義高工",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區彌陀路174號",
      "電話": "(05)2763060",
      "網址": "http://www.cyivs.cy.edu.tw"
    },
    {
      "代碼": "200406",
      "學校名稱": "國立嘉義高商",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區中山路7號",
      "電話": "(05)2782421",
      "網址": "http://www.cyvs.cy.edu.tw"
    },
    {
      "代碼": "200407",
      "學校名稱": "國立嘉義家職",
      "公/私立": "公立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區市宅街57號",
      "電話": "(05)2259640",
      "網址": "http://www.cyhvs.cy.edu.tw"
    },
    {
      "代碼": "201304",
      "學校名稱": "私立興華高中",
      "公/私立": "私立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區林森東路239號",
      "電話": "(05)2764317",
      "網址": "http://www.hhsh.cy.edu.tw"
    },
    {
      "代碼": "201309",
      "學校名稱": "私立仁義高中",
      "公/私立": "私立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區鹿寮里紅毛埤125之1號",
      "電話": "(05)2765555",
      "網址": "http://www.zysh.cy.edu.tw"
    },
    {
      "代碼": "201310",
      "學校名稱": "私立嘉華高中",
      "公/私立": "私立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區民權東路45號",
      "電話": "(05)2761716",
      "網址": "http://www.chsh.cy.edu.tw"
    },
    {
      "代碼": "201312",
      "學校名稱": "私立輔仁高中",
      "公/私立": "私立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區吳鳳南路270號",
      "電話": "(05)2281001",
      "網址": "http://www.fjsh.cy.edu.tw"
    },
    {
      "代碼": "201313",
      "學校名稱": "私立宏仁女中",
      "公/私立": "私立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區忠孝路667號",
      "電話": "(05)2322802",
      "網址": "http://www.hjgs.cy.edu.tw"
    },
    {
      "代碼": "201314",
      "學校名稱": "私立立仁高中",
      "公/私立": "私立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區立仁路235號",
      "電話": "(05)2226420",
      "網址": "http://www.ligvs.cy.edu.tw"
    },
    {
      "代碼": "201408",
      "學校名稱": "私立東吳工家",
      "公/私立": "私立",
      "縣市名稱": "[20]嘉義市",
      "地址": "[600]嘉義市東區宣信街252號",
      "電話": "(05)2246161",
      "網址": "http://www.dwvs.cy.edu.tw"
    },
    {
      "代碼": "710301",
      "學校名稱": "國立金門高中",
      "公/私立": "公立",
      "縣市名稱": "[71]金門縣",
      "地址": "[893]金門縣金城鎮光前路94號",
      "電話": "(082)326582",
      "網址": "http://www.kmsh.km.edu.tw"
    },
    {
      "代碼": "710401",
      "學校名稱": "國立金門農工",
      "公/私立": "公立",
      "縣市名稱": "[71]金門縣",
      "地址": "[891]金門縣金湖鎮新市里復興路1之11號",
      "電話": "(082)333508",
      "網址": "http://www.kmvs.km.edu.tw"
    },
    {
      "代碼": "720301",
      "學校名稱": "國立馬祖高中",
      "公/私立": "公立",
      "縣市名稱": "[72]連江縣",
      "地址": "[209]連江縣南竿鄉介壽村374號",
      "電話": "(0836)25668",
      "網址": "http://www.mssh.matsu.edu.tw"
    },
    {
      "代碼": "011C71",
      "學校名稱": "私立光華高商進修學校",
      "公/私立": "私立",
      "縣市名稱": "[01]新北市",
      "地址": "[220]新北市板橋區南雅南路二段40號",
      "電話": "(02)89522255",
      "網址": "http://www.khvs.ntpc.edu.tw"
    },
    {
      "代碼": "351B09",
      "學校名稱": "私立南華高中進修學校",
      "公/私立": "私立",
      "縣市名稱": "[35]臺北市",
      "地址": "[100]臺北市中正區富水里汀州路3段58號",
      "電話": "(02)23686818",
      "網址": "http://www.nhwsh.tp.edu.tw"
    },
    {
      "代碼": "361B09",
      "學校名稱": "私立志仁中學進修學校",
      "公/私立": "私立",
      "縣市名稱": "[36]臺北市",
      "地址": "[103]臺北市大同區建泰里南京西路64巷17號",
      "電話": "(02)25551656",
      "網址": "http://www.cjvs.tp.edu.tw"
    },
    {
      "代碼": "210C01",
      "學校名稱": "國立成功大學附設高工進修學校",
      "公/私立": "公立",
      "縣市名稱": "[21]臺南市",
      "地址": "[701]臺南市東區大學路1號",
      "電話": "(06)2757575",
      "網址": "http://www.ncku.edu.tw"
    },
    {
      "代碼": "140222",
      "學校名稱": "國立臺東專科學校附設進修學校",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": "[950]臺東縣臺東市正氣北路889號",
      "電話": "(089)226389",
      "網址": "http://ce.ntc.edu.tw"
    },
    {
      "代碼": "140401",
      "學校名稱": "國立臺東專科學校附設高職部",
      "公/私立": "公立",
      "縣市名稱": "[14]臺東縣",
      "地址": " [950]臺東縣臺東市正氣北路889號",
      "電話": "(089)226389",
      "網址": "http://ttaivs.ntc.edu.tw/"
    },
    {
      "代碼": "400419",
      "學校名稱": "國立臺灣戲曲學院附設高職部",
      "公/私立": "公立",
      "縣市名稱": "[30]臺北市",
      "地址": "[114]臺北市內湖路二段177號",
      "電話": "(02)27962666",
      "網址": "www.tcpa.edu.tw"
    }
  ]


export default {
  school,
}