import React, { useCallback, useState, useEffect,useRef } from "react";
import styles from "./index.module.scss";
import { StoreTypes, useStore } from "context";
import LeftPannel2 from "components/ClassView/LeftPannel2";
import YunlinLeftPannel from "components/ClassView/YunlinLeftPannel";
import VideoContent from "components/VideoContent";
import Header from "components/Header";
import YunlinHeader from "components/YunlinMainPage/Header";
import { Beforeunload } from "components/Beforeunload";
import StudyNode from "components/StudyNode";
import { useLocation, useParams } from "react-router-dom";
import OnlineTest from "components/OnlineTest";
import { API } from "api";
import * as types from "constants/actionTypes";
import Loading from "components/Loading";
import StudyNodeLoading from "components/Loading/StudyNodeLoading";
import OpenLinkContent from "components/OpenLinkContent";
import { getMainPage, getHeader } from "util/getMainPage"

const ClassView = () => {
	const router = useStore(StoreTypes.router);
	const location = useLocation();
	const query = new URLSearchParams(location.search);

	const [{ productInfo, videoList, testList }, globalDispatch] = useStore(
		StoreTypes.global
	);

	let productCode = productInfo ? (productInfo.productcode ? productInfo.productcode : productInfo.productCode) : query.get("productCode");
	let volume = productInfo ? productInfo.course : query.get("volume");
	let subject = productInfo ? productInfo.subject : query.get("subject");
	let purchaseTime = productInfo && productInfo.purchaseTime ? productInfo.purchaseTime : query.get("expired")
	let examId = query.get("examId");
	
	const [{ userId, token }] = useStore(StoreTypes.user);
	const [doUnload, setDoUnload] = useState(true);
	const [paramsInfo, setParamsInfo] = useState({
		productCode,
		volume,
		subject,
		purchaseTime
	});

	const [isloading, setIsLoading] = useState(true);
	const [products, setProductInfo] = useState(productInfo);
	const videoContentRef = useRef()

	const getExamRecodeList = useCallback(async ({ userId, token, volume, subject }) => {
		var data = { userId, token, volume, subject };
		const result = await API.post(
			`${process.env.REACT_APP_API_DOMAIN}/exam/get`,
			data
		);
		return result;
	}, [productInfo]);

	

	useEffect(() => {
		if (!userId) return;
		(async () => {
			setIsLoading(true);
			let result;

			if (examId) {
				const examInfo = await API.get(
					`${process.env.REACT_APP_API_DOMAIN}/exam/${examId}`
				);

				if (examInfo.status === "success") {
					const { productCode, volume, subjectName } = examInfo.data;
					setParamsInfo({
						productCode,
						volume,
						subject: subjectName,
					});
					result = await getExamRecodeList({
						userId,
						token,
						volume,
						subject: subjectName,
					});
				} else {
					router.history.push(getMainPage());
					return;
				}
			} else {
				setParamsInfo({
					productCode,
					volume,
					subject,
				});
				result = await getExamRecodeList({ userId, token, volume, subject });
			}

			if (result.status === "success") {
				setIsLoading(false);
				globalDispatch({
					type: types.SET_EXAM_LIST,
					examRecodeList: result.data,
				});
			}
		})();
	}, [userId, examId, productCode, volume, subject, productInfo]);

	const switchVideoOrTestContent = useCallback(() => {
		const { productCode, volume, subject,product } = paramsInfo;

		if (isloading) {
			return <StudyNodeLoading />;
		}
		if (videoList.length === 0 && testList.length === 0) {
			return (
				<StudyNode
					productCode={productCode}
					volume={volume}
					subject={subject}
					product={product}
					isloading={isloading}
					setIsLoading={setIsLoading}
				/>
			);
		} else if (testList.length > 0) {
			return (
				<OnlineTest
					productCode={productCode}
					setDoUnload={setDoUnload}
					volume={volume}
					subjectName={subject}
					isloading={isloading}
					setIsLoading={setIsLoading}
					purchaseTime={purchaseTime}
				/>
			);
		} else if (videoList.length > 0) {
			if (videoList.find((v) => v.app_item === "外開連結")) {
				return <OpenLinkContent productCode={productCode} purchaseTime={purchaseTime} />;
			} else {
				return <VideoContent productCode={productCode} purchaseTime={purchaseTime} onScrollVideoContent={() => {videoContentRef.current.scrollTop = 0}}/>;
			}
		}
	}, [paramsInfo, isloading, videoList, testList.length, purchaseTime]);

	return (
		<Beforeunload doUnload={doUnload}>
			<div className={styles.classView}>
				{getHeader()}
				<div className={styles.classContent}>
					<div className={styles.leftPannel}>
						{
							process.env.REACT_APP_PROJECT === 'onestudy' || process.env.REACT_APP_PROJECT === 'nanistudy' ?
								(<LeftPannel2
									productCode={productCode}
									volume={volume}
									subject={subject}
									isloading={isloading}
									setIsLoading={setIsLoading}
									purchaseTime={purchaseTime}
								/>) : (
									<YunlinLeftPannel
										productCode={productCode}
										volume={volume}
										subject={subject}
										isloading={isloading}
										setIsLoading={setIsLoading}
									/>
								)
						}
					</div>
					<div className={styles.videoContent} ref={videoContentRef}>
						{switchVideoOrTestContent()}
					</div>
				</div>
			</div>
		</Beforeunload>
	);
};

export default ClassView;
