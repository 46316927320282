export const searchInfo = (info, searchType, searchKey) => {
    if (!searchKey) return info
    switch (searchType) {
        case '縣市':
            return info.filter(data => data.schoolCounty && data.schoolCounty.includes(searchKey))
        case '鄉鎮市區':
            return info.filter(data => data.schoolDistrict && data.schoolDistrict.includes(searchKey))
        case '學校名稱':
            return info.filter(data => data.schoolName && data.schoolName.includes(searchKey))
        case '學制班級':
            return info.filter(data => {
                const classInfo = `${data.grade}${data.classno < 10 ? `0${data.classno}` : data.classno}`;
                return classInfo.includes(searchKey)
            })
        case '座號':
            return info.filter(data => data.seatNumber && data.seatNumber.includes(searchKey))
        case '學生名字':
            return info.filter(data => {
                const name = data.name ? data.name : data.inputName1 + data.inputName2
                return name ? name.includes(searchKey) : false
            })
        case '學生帳號':
            return info.filter(data => data.username && data.username.includes(searchKey))
        case '影片名稱':
            return info.filter(data => {
                const name = data.video && data.video.name ? data.video.name : false
                return name ? name.includes(searchKey) : false
            })
        case '測驗名稱':
            return info.filter(data => {
                const examName = data.exam ? `${data.exam.attribute.name}${data.exam.attribute.bookNames[0]}${data.exam.attribute.examName}`.replace("學年度", "") : ''
                return examName.includes(searchKey)
            })
        case '年級':
            return info.filter(data => data.grade && data.grade.includes(searchKey))
        case '產品名稱':
            return info.filter(data => data.productName && data.productName.includes(searchKey))
        default:
            return info;
    }
};

