import * as React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import { StoreTypes, useStore } from "context";
import styles from "./index.module.scss";
import OrderLoading from 'components/Loading/OrderLoading';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { API } from "api";
import short_uuid from "util/uuid"
import { timeFormatTommss } from 'util/timeFormater';

const VideoExamSearch = () => {
	const keyProductCode = "productcode";
	const keyProductName = "product";
	const params = (new URL(document.location)).searchParams;
	const [{ userId, token }] = useStore(StoreTypes.user);
	const [loading, setLoading] = useState(false)
	const [loadingMessage, setLoadingMessage] = useState('查詢中...')
	const [accounts, setAccounts] = useState('')
	const [examInfo, setExamInfo] = useState([])
	const [videoInfo, setVideoInfo] = useState([])
	const [consumedProductList, setConsumedProductList] = useState([])
	const [codes, setCodes] = useState('')
	const [startTime, setStartTime] = useState(dayjs());
	const [endTime, setEndTime] = useState(dayjs());
	const uploadInputRef = useRef();
	const [naniProducts, setNaniProducts] = useState(null);

	useEffect(() => {
		const curTime = new Date();
		var year = curTime.getFullYear();
		if (curTime.getMonth() < 8) year -= 1;
		year = year - 1911;
		const products = [];

		// proceed calling API orderPage
		fetch(`${process.env.REACT_APP_API_DOMAIN}/apis/getProducts`, {
			method: "POST",
			body: JSON.stringify({ year: year.toString() }),
			headers: { "Content-type": "application/json; charset=UTF-8" }
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (response) {
				if (response.status == "success") {
					var productList = response.data;
					var codeList = [];
					var tmp = {};
					for (var i = 0; i < productList.length; i++) {
						var product = productList[i];
						var code = product[keyProductCode];
						var name = product[keyProductName];
						if (tmp.hasOwnProperty(code) == false) {
							tmp[code] = name;
							codeList.push(code);
						}
					}
					// sort by keyProductCode
					codeList.sort(function (a, b) {
						const strParams1 = a.split('_');
						const strParams2 = b.split('_');
						// check year
						for (var i = 0; i < 3; i++) {
							const value1 = parseInt(strParams1[i]);
							const value2 = parseInt(strParams2[i]);
							if (value1 == value2) continue;
							return value1 < value2 ? -1 : 1;
						}
						// check school
						if (strParams1[3] != strParams2[3]) {
							var school1 = 0;
							var school2 = 0;
							switch (strParams1[3].toUpperCase()) {
								case 'E': school1 = 1; break;
								case 'J': school1 = 2; break;
								case 'H': school1 = 3; break;
							}
							switch (strParams2[3].toUpperCase()) {
								case 'E': school2 = 1; break;
								case 'J': school2 = 2; break;
								case 'H': school2 = 3; break;
							}
							return school1 < school2 ? -1 : 1;
						}
						// check class
						if (strParams1[4] != strParams2[4]) {
							var class1 = 0;
							var class2 = 0;
							switch (strParams1[4].toUpperCase()) {
								case 'C': class1 = 1; break;
								case 'E': class1 = 2; break;
								case 'M': class1 = 3; break;
								case 'S': class1 = 4; break;
								case 'N': class1 = 5; break;
								case 'L': class1 = 6; break;
								default: class1 = 7; break;
							}
							switch (strParams2[4].toUpperCase()) {
								case 'C': class2 = 1; break;
								case 'E': class2 = 2; break;
								case 'M': class2 = 3; break;
								case 'S': class2 = 4; break;
								case 'N': class2 = 5; break;
								case 'L': class2 = 6; break;
								default: class2 = 7; break;
							}
							return class1 < class2 ? -1 : 1;
						}
						return 0;
					});
					//codeList.reverse();
					for (var i = 0; i < codeList.length; i++) {
						var code = codeList[i];
						products.push({ code, product: tmp[code] });
					}

					setNaniProducts(products)
				}
				else {
					console.log(`getProducts got ${response.error}`);
				}
			})
			.catch(function (err) {
				console.log(`getProducts got ${err}`);
			});
	}, [setNaniProducts])

	const getExamRecodeList = useCallback(() => {
		const userIds = accounts.toString().split(",")
		const results = []
		userIds.forEach((id) => {
			results.push(new Promise(async (resolve, reject) => {
				var data = {
					userId: id,
					token,
					startDate: startTime,
					endDate: endTime
				};
				const result = await API.post(
					`${process.env.REACT_APP_API_DOMAIN}/exam/getUseExamsInfo`,
					data
				);
				console.log(result);
				if (result.status === 'success') {
					resolve(result);
				}
			}))
		})
		return results;
	}, [startTime, endTime, accounts, token]);

	const getVideoRecodeList = useCallback(() => {
		const userIds = accounts.toString().split(",")
		const results = []
		userIds.forEach((id) => {
			results.push(new Promise(async (resolve, reject) => {
				var data = {
					userId: id,
					token,
					startDate: startTime,
					endDate: endTime
				};
				const result = await API.post(
					`${process.env.REACT_APP_API_DOMAIN}/video/getUserVideoInfo`,
					data
				);
				if (result.status === 'success') {
					resolve(result);
				}
			}))
		})

		return results;
	}, [startTime, endTime, accounts, token]);

	const searchInfo = async () => {
		setLoading(true)
		const examResult = getExamRecodeList();
		await Promise.all(examResult).then(function (results) {
			let datas = []
			results.forEach((data) => {
				if (data.status === 'success') {
					if (data.data.length > 0) {
						datas = ([...datas, ...data.data]);
					} else {
						alert(`沒有測驗紀錄`)
					}
				}
			})
			setExamInfo(datas)
		}).catch(function (err) {
			console.log(err);
		});

		const videoResult = getVideoRecodeList();
		await Promise.all(videoResult).then(function (results) {
			const datas = []
			results.forEach((data) => {
				if (data.status === 'success') {
					if (data.data.log.length > 0) {
						datas.push(data.data);
					} else {
						alert(`${data.data.userId} 沒有觀看紀錄`)
					}
				}
			})
			setVideoInfo(datas)
		}).catch(function (err) {
			console.log(err);
		});
		setLoading(false)
	}

	const getRightCount = (arr) => {
		return arr.filter((info) => info.isCorrect).length;
	}

	const exportVideoRecord = (videoInfo) => {
		const elem = []
		const userList = []
		videoInfo.forEach((info, index) => {
			if (!userList[info.userId]) {
				userList[info.userId] = []
			}
			userList[info.userId] = info
		})

		for (let key in userList) {
			userList[key].log && userList[key].log.length > 0 && elem.push(<div key={short_uuid()} className={styles.listName}>
				<h5 className={styles.listId}>{key}/</h5>
				<div>
					{
						userList[key].log && userList[key].log.map((log, index) => {
							const { name, time, timestamp, duration, productCode } = log
							const timeStr = time !== 0 ? Math.round(time) : time;
							const updateDate = window
								.moment(timestamp)
								.format("YYYY/MM/DD HH:mm");;
							const durationStr = duration
								? duration !== 0
									? Math.round(duration)
									: duration
								: 0;
							const products = naniProducts.find((product) => product.code === productCode)
							return products && <div key={short_uuid()}>{`${products.product}/${updateDate}/${name}/${timeFormatTommss(time)}/${timeFormatTommss(durationStr)}`}</div>
						})
					}
				</div>
			</div>)
		}
		return elem
	}

	const exportExamRecord = (examInfo) => {
		const elem = []
		const userList = []
		examInfo.forEach((info, index) => {
			if (!userList[info.userId]) {
				userList[info.userId] = []
			}
			userList[info.userId].push(info)
		})

		for (let key in userList) {
			elem.push(<div key={short_uuid()} className={styles.listName}>
				<h5 className={styles.listId}>{key}/</h5>
				<div>
					{
						userList[key].map((info, index) => {
							const { volume, subjectName, userAnswerDate, examName, productCode } = info
							const date = (new Date(userAnswerDate))
							const dateInfo = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
							const rightCount = getRightCount(info.answerData);
							const percent = (rightCount / info.answerData.length) * 100;

							const products = naniProducts.find((product) => product.code === productCode)
							return products && <div key={short_uuid()}>{`${products.product}/${dateInfo}/${examName}/${percent}%`}</div>
						})
					}
				</div>
			</div>)
		}
		return elem
	}

	const uploadInputClick = useCallback(() => {
		if (!uploadInputRef.current) return;
		uploadInputRef.current.value = ""
		uploadInputRef.current.click();
	}, [uploadInputRef])

	const Upload = (e) => {
		var input = e.target;
		if (!input) return;
		var meta = input.files[0];
		var filename = meta.name;
		if (meta && window.FileReader) {
			var reader = new FileReader();
			reader.onload = strToJson;
			//reader.onerror = errorHandler;    
			reader.readAsText(meta);
		} else {
			alert('FileReader are not supported in this browser.');
		}
	}

	const strToJson = (event) => {
		// Get file from drop or select method
		var result;
		if ("result" in event.target) {
			result = event.target.result;
		} else {
			result = event.dataTransfer.files;
		}
		// Split string into rows then columns
		var json = [], str, arrX, o, q, arr;
		var rows = result.split(/\r\n|\n/);
		for (var i = 0, r; r = rows[i]; i++) {
			// Only basic double quotes
			str = r.replace(/“|”/g, "\"");
			// Allow for commas if enclosed with double quotes
			arr = [];
			arrX = str.split('');
			o = 0;	// Offset of string
			q = false;	// Monitor if between quotes
			for (var j = 0, k; k = arrX[j]; j++) {
				if (k == "," && q === false) {
					arr.push(str.substring(o, j));
					o = j + 1;
				}
				if (q === false && k == '"') {
					q = true;
					continue;
				}
				if (q === true && k == '"') {
					q = false;
				}
			}
			// String after last comma
			arr.push(str.substring(o, j));
			// Remove double quotes after checking for enclosed commas
			for (var j = 0, k; k = arr[j]; j++) {
				arr[j] = k.replace(/"/g, "");
			}
			// Add row to JSON array
			json.push(arr);
		}
		setAccounts(json)
	}

	const exportVideoExcel = useCallback(() => {
		const userList = []
		const row = []
		videoInfo.forEach((info, index) => {
			if (!userList[info.userId]) {
				userList[info.userId] = []
			}
			userList[info.userId] = info
		})

		for (let key in userList) {
			userList[key].log && userList[key].log.length > 0 && userList[key].log.forEach((log, index) => {
				const { name, time, timestamp, duration, productCode } = log
				const id = key.substr(0, 1) === '0' ? `*${key}` : key
				const timeStr = time !== 0 ? Math.round(time) : time;
				const updateDate = window
					.moment(timestamp)
					.format("YYYY/MM/DD HH:mm");;
				const durationStr = duration
					? duration !== 0
						? Math.round(duration)
						: duration
					: 0;
				const products = naniProducts.find((product) => product.code === productCode)
				products && row.push([id, products.product, updateDate, name, timeFormatTommss(time) + "/" + timeFormatTommss(durationStr)])
			});
		}

		let csvContent = "data:text/csv;charset=utf-8,\uFEFF"
			+ row.map(e => e.join(",")).join("\n");
		var encodedUri = encodeURI(csvContent);
		console.log("encodedUri", encodedUri);
		window.open(encodedUri);

	}, [videoInfo])


	const exportExamExcel = useCallback(() => {
		const userList = []
		const row = []
		examInfo.forEach((info, index) => {
			if (!userList[info.userId]) {
				userList[info.userId] = []
			}
			userList[info.userId].push(info)
		})

		for (let key in userList) {
			userList[key].forEach((info, index) => {
				console.log("info", info);
				const { volume, subjectName, userAnswerDate, examName, productCode } = info
				const id = key.substr(0, 1) === '0' ? `*${key}` : key
				const date = (new Date(userAnswerDate))
				const dateInfo = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
				const rightCount = getRightCount(info.answerData);
				const percent = (rightCount / info.answerData.length) * 100;

				const products = naniProducts.find((product) => product.code === productCode)
				products && row.push([id, products.product, dateInfo, examName, `${percent}%`])
			});
		}

		console.log("row", row);
		let csvContent = "data:text/csv;charset=utf-8,\uFEFF"
			+ row.map(e => e.join(",")).join("\n");
		var encodedUri = encodeURI(csvContent);
		console.log("encodedUri", encodedUri);
		window.open(encodedUri);

	}, [examInfo])

	return (
		<div className={styles.videoExamSearch}>
			{loading && <OrderLoading message={loadingMessage} />}
			<div className={styles.tabs}>
				<div
					className={styles.topContent}
				>
					<TextareaAutosize
						maxRows={4}
						aria-label="maximum height"
						placeholder=""
						defaultValue=""
						style={{ width: '70%', height: 220 }}
						value={accounts}
						onChange={(e) => setAccounts(e.target.value)}
					/>
					<div className={styles.dateTimePicker}>
						<div className={styles.sendBtn}>
							<input type="button" className={styles.uploadBtn} value="匯入帳號" onClick={() => uploadInputClick()} />
							<input ref={uploadInputRef} type="file" className={styles.uploadInput} onChange={(e) => Upload(e)} />
						</div>
						<div className={styles.sendBtn}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateTimePicker
									label="開始時間"
									value={startTime}
									onChange={(value) => {
										setStartTime(value)
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</div>
						<div className={styles.sendBtn}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateTimePicker
									label="結束時間"
									value={endTime}
									onChange={(value) => {
										setEndTime(value)
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</div>
						<div className={styles.sendBtn}>
							<Button onClick={() => searchInfo()}>查詢</Button>
						</div>
					</div>
				</div>
				<div
					id="mainCenter"
					className={styles.bottomContent}
				>
					<div className={styles.videoContent}>
						<h5>影片觀看紀錄</h5>
						<div className={styles.videoList}>
							{
								videoInfo && exportVideoRecord(videoInfo)
							}
						</div>
						<div className={styles.sendBtn}>
							<Button onClick={() => exportVideoExcel()}>匯出影片觀看紀錄</Button>
						</div>
					</div>
					<div className={styles.examContent}>
						<h5>測驗完成紀錄</h5>
						<div className={styles.examList}>
							{
								examInfo && exportExamRecord(examInfo)
							}
						</div>
						<div className={styles.sendBtn}>
							<Button onClick={() => exportExamExcel()}>匯出測驗完成紀錄</Button>
						</div>
					</div>
				</div>

			</div>
		</div >
	);
}

export default VideoExamSearch;