import { useCallback, useEffect } from "react";
import { useStore, StoreTypes } from "context";
import * as actionTypes from "constants/actionTypes";

// import { UserEvent } from 'events/EventTypes';
// import { useEvent } from 'events/EventBus';
// import { Roles } from 'constants/role';
// import { API } from 'api';
// import { useDeleteAnnotation } from 'customHooks/db';
// import { logoutNaniOneClass, checkNaniOneClass ,getThirdLoginToken} from 'components/Login/Popup/login.js';

import { useLogin } from "util/login";

export const useUserEventHandler = () => {
  const [{ isLoginm, name, token, userId }, userDispatch] = useStore(
    StoreTypes.user
  );
  const { sign, getUser, getToken } = useLogin();
  useEffect(() => {
    fetchToken();
  }, []);

  const fetchToken = useCallback(async () => {
    let cookieToken = getToken();
    const source = process.env.REACT_APP_PROJECT.includes('yunlin') ? 'yunlin' : 'general';
    if (cookieToken) {
      const jwt = require("jsonwebtoken");
      const jwtInfo = jwt.decode(cookieToken);
      await getUser({ token: cookieToken, getExpiredProduct: true, source })
        .then((response) => {
          userDispatch({
            type: actionTypes.SET_USER_INFO,
            userId: response.username,
            token: cookieToken,
            name: response.name,
            login: true,
            isLogin: true,
            product: response.product,
            isOneLiveMember: response.isOneLiveMember
              ? response.isOneLiveMember
              : false,
            oneLiveMemberCloseTime: response.oneLiveMemberCloseTime
              ? response.oneLiveMemberCloseTime
              : null,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  // const fetchToken = useCallback(async () => {
  //     //第一步 確認目前cookie 是否有值

  //     //第二部
  //     console.log("fetchToken");
  // })
};
//  check token

// let token = await new Promise((resolve, reject) => {
//     checkNaniOneClass((result) => {
//         resolve(result)
//     });//
// });

// if (!token && code) {
//     token = getThirdLoginToken({code})
// }

// if (!token && window.getNani_token) {
//     token = window.getNani_token() && JSON.parse(window.getNani_token()).jwt
// }

// }), [initUserInfo]);

// const [, canvasDispatch] = useStore(StoreTypes.canvas);
// const { deleteAllAnnotation } = useDeleteAnnotation();

// const setRoleToLocaleUpperCase = (identity) => {
//     let role = null;
//     switch (identity) {
//         case '教師':
//             role = Roles.TEACHER;
//             break;
//         case '編輯':
//             role = Roles.EDITOR;
//             break;
//         case '家長':
//             role = Roles.PARENT;
//             break;
//         case '學生':
//             role = Roles.STUDENT;
//             break;
//         case 'tutor':
//             role = Roles.TUTOR;
//             break;
//         case 'user':
//             role = Roles.TUTOR_USER;
//             break;
//         case 'guest':
//         default:
//             role = Roles.GUEST;
//             break;
//     }
//     return role;
// }

// const getOneTutorUserInfo = (async ({ studentId, token }) => {
//     const options = { headers: { Authorization: token } }
//     let userId;
//     let name;
//     let userName;
//     let role;
//     const userResult = await API.cloudFuncGet("https://us-central1-onetutor-dev.cloudfunctions.net/users/me", options)
//     if (userResult.status === "success") {
//         role = setRoleToLocaleUpperCase(userResult.data.role)
//         if (studentId) {
//             const memberResult = await API.cloudFuncGet("https://us-central1-onetutor-dev.cloudfunctions.net/users/members", options)
//             const studentInfo = memberResult.data.members.find((menber) => studentId === menber.id);
//             if (studentInfo) {
//                 userId = studentInfo.id;//studentInfo.nickname;
//                 userName = name = studentInfo.nickname;//studentInfo.nickname;
//             } else {
//                 role = setRoleToLocaleUpperCase("guest")
//             }
//         } else {
//             if (role === Roles.TUTOR) {
//                 userId = userResult.data.id;//userResult.data.lastName + userResult.data.firstName;
//                 userName = name = userResult.data.nickname;
//             }
//         }
//         return { userId, name, userName, role, isLogin: true }
//     }
// })

// const getOneClubUserInfo = (async ({ token }) => {
//     let userId;
//     let userName;
//     let role;
//     const options = { headers: { Authorization: token } }
//     const result = await API.cloudFuncGet("https://asia-northeast1-oneclass-onemall.cloudfunctions.net/users/profile", options)
//     const { identity, name } = result.data.profile
//     userId = result.data.id;
//     userName = name;
//     role = setRoleToLocaleUpperCase(identity)
//     return { userId, name: userName, userName, role, isLogin: true }
// })

// const isOneClassStudent = ({ users, userId }) => {
//     return users.find((name) => name === userId) ? Roles.ONECLASS_STUDENT : Roles.GUEST;
// }

// const getOneClassUserInfo = (async ({ userId, roomId, timeSpanId, token }) => {
//     let role = Roles.GUEST;
//     let userName;
//     const options = { headers: { Authorization: token } }
//     const userResult = await API.cloudFuncGet(`${process.env.REACT_APP_ONECLASS_API_DOMAIN}/users/me`, options)
//     console.log("userResult", userResult)
//     if (userResult.status === "success") {
//         userName = userResult.data.nickname;
//         const result = await API.cloudFuncGet(`${process.env.REACT_APP_ONECLASS_API_DOMAIN}/sessions/${roomId}?timeSpanId=${timeSpanId}`, options)
//         if (result.status === "success") {
//             const { hostId, name, users } = result.data
//             role = hostId === userId ? Roles.ONECLASS_TEACHER : users ? isOneClassStudent({ users, userId }) : Roles.GUEST;
//             return { userId, name: userName, userName, role, isLogin: true, courseInfo: result.data }
//         }
//     }
//     return { role }
// })

// const initUserInfo = useCallback(async ({ roomId, studentId, timeSpanId, token }) => {
//     const jwt = require('jsonwebtoken');
//     const jwtInfo = jwt.decode(token);

//     let userInfo = {
//         type: types.UPDATE_USER_INFO,
//         name: "guest",
//         role: Roles.GUEST,
//         token,
//         userId: jwtInfo ? jwtInfo.username : "",
//         isLogin: false,
//         isInitialized: true
//     }

//     try {
//         if (token) {
//             //deleteAllAnnotation();
//             if (roomId) {
//                 if (timeSpanId) {
//                     userInfo = { ...userInfo, ...await getOneClassUserInfo({ token, roomId, timeSpanId, userId: userInfo.userId }) }
//                 } else {
//                     userInfo = { ...userInfo, ...await getOneTutorUserInfo({ token, studentId }) }
//                 }
//             } else {
//                 userInfo = { ...userInfo, ...await getOneClubUserInfo({ token }) }
//             }

//             if (userInfo.role === Roles.EDITOR) {
//                 canvasDispatch({ type: types.CANVAS_TURN_ON_EXTRA_FIELDS_FOR_EDITOR });
//             }
//         }
//     } catch (e) {

//     }

//     userDispatcher(userInfo);
//     return userInfo;
//     // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [canvasDispatch, userDispatcher]);

// const getUserIdentityHandler = useCallback(({ roomId, studentId, timeSpanId, code, callback }) => {
//     fetchToken(roomId, studentId, timeSpanId, code, callback);
// }, [fetchToken])

// const loginHandler = useCallback(async () => {
//     if (isLogin) {
//         try {
//             logoutNaniOneClass();
//         } catch (e) {
//             deleteAllAnnotation();
//             window.nanilogout();
//             window.NanilCoollogout();
//         }
//         //userDispatcher({ type: types.RESET_USER_INFO });
//     } else {
//         await checkNaniOneClass(token => {
//             if (!token) {
//                 token = new Promise((resolve, reject) => {
//                     window.getLoginNaniCool((result) => {
//                         resolve(result)
//                     });
//                 });
//             }
//             initUserInfo({ token })
//         });
//     }
// }, [deleteAllAnnotation, initUserInfo, isLogin]);

// useEvent({ event: UserEvent.LoginEvent }, loginHandler);
// useEvent({ event: UserEvent.GetUserIdentityEvent }, getUserIdentityHandler);
// };
