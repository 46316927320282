import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import Loading from "components/Loading";
import DialogModule from "components/Dialogs/";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  InputAdornment,
  Box,
  TextField,
} from "@mui/material/";
import List from '@mui/material/List';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: {
    border: "1px solid",
    p: "8px",
    m: "20px",
    "&:hover": {
      width: '100%',
      backgroundColor: "red"
    },
    span: {
      color: '#EC7963',
    }
  }
});

const ExportAllReport = (props) => {
  const classes = useStyles();
  const { currentTab, info } = props;
  const [isOpen, setIsOpen] = useState(false)
  const [startTime, setStartTime] = useState(dayjs().subtract(5, "month"));
  const [endTime, setEndTime] = useState(dayjs());
  const [popupStep, setPopupStep] = useState(1)

  const loadReportFile = <div>
    <div>正在讀取中...</div>
    <Loading />
  </div>

  const selecteDate = () => {
    return <div>
      <div style={{
        display: "flex",
        padding: "4px 2px",
        width: "100%"
      }}>
        <div
          style={{
            width: "95%",
            lineHeight: "27px",
            fontFamily: 'Noto Sans CJK TC',
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            color: "#2F3649"
          }}>
          請選擇起始日期與結束日期
        </div>
        <div
          style={{
            cursor: "pointer",
            lineHeight: "27px",
            fontFamily: 'Noto Sans CJK TC',
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            color: "#A1A4B1"
          }}
          onClick={() => setIsOpen(false)}
        >X</div>
      </div>
      <div style={{
        display: "grid",
        paddingBottom: "16px"
      }}>
        <div style={{
          padding: "16px 0px"
        }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="起始日期"
              value={startTime || null}
              sx={{
                width: "320px",
                overflow: "hidden",
                fontFamily: 'Public Sans',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#919EAB"
              }}
              onChange={(value) => {
                console.log("value", value);
                console.log("value", new Date(value).toLocaleDateString());
                setStartTime(value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <img src={`./assets/img/calender.svg`} alt="date" />
                  </InputAdornment>
                ),
              }}
              renderInput={(params) => (
                <TextField size="small" {...params} sx={{ width: "320px" }} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="結束日期"
              value={endTime || null}
              sx={{ width: "320px" }}
              onChange={(value) => {
                setEndTime(value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <img src={`./assets/img/calender.svg`} alt="date" />
                  </InputAdornment>
                ),
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" sx={{ width: "320px" }} />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 22px",

          width: "320px",
          height: "40px",

          background: "#FF6065",
          borderRadius: "8px",
          color: "#FFFFFF",
          marginBottom: "16px"
        }}
        onClick={() => setPopupStep(2)}
      >
        <div>
          確認
        </div>
      </div>
    </div >
  }

  const selecteRecode = () => {
    return <div>
      <div style={{
        display: "flex",
        padding: "4px 2px",
        width: "100%"
      }}>
        <div
          style={{
            width: "95%",
            lineHeight: "27px",
            fontFamily: 'Noto Sans CJK TC',
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            color: "#2F3649"
          }}>
          請選擇欲匯出之項目
        </div>
        <div
          style={{
            cursor: "pointer",
            lineHeight: "27px",
            fontFamily: 'Noto Sans CJK TC',
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            color: "#A1A4B1"
          }}
          onClick={() => setIsOpen(false)}
        >X</div>
      </div>
      <div style={{
        display: "grid",
        paddingBottom: "16px"
      }}>
        <List sx={{ 'label:hover': { backgroundColor: 'rgba(145, 158, 171, 0.08)' }, display: 'grid' }}>
          <FormControlLabel control={<Checkbox style={{ color: '#EC7963' }} defaultChecked />} label="學生個人紀錄" />
          <FormControlLabel control={<Checkbox style={{ color: '#EC7963' }} />} label="學校使用紀錄" />
          <FormControlLabel control={<Checkbox style={{ color: '#EC7963' }} />} label="產品使用紀錄" />
        </List>
      </div>
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 22px",

          width: "320px",
          height: "40px",

          background: "#FF6065",
          borderRadius: "8px",
          color: "#FFFFFF",
          marginBottom: "16px"
        }}
        onClick={() => setPopupStep(3)}
      >
        <div>
          確認
        </div>
      </div>
    </div >
  }

  const setStepContent = (popupStep) => {
    switch (popupStep) {
      case 1:
        return selecteDate()
      case 2:
        return selecteRecode()
      case 3:
        return loadReportFile
      default:
        break;
    }
  }

  return (
    <div className={styles.exportAllReport}>
      <DialogModule
        open={isOpen}
        content={setStepContent(popupStep)}
      />
      <div className={styles.content} onClick={() => setIsOpen(true)}>
        <img src="./assets/img/exportReportBtn.svg" alt="" />
        <div>
          匯出所有項目表單
        </div>
      </div>
    </div>
  );
};

export default ExportAllReport;
