import MainPage from 'components/MainPage';
import YunlinMainPage from 'components/YunlinMainPage';
import ChiayiMainPage from 'components/ChiayiMainPage';
import NaniMainPage from 'components/NaniMainPage';
import YunlinHeader from 'components/YunlinMainPage/Header';
import ChiayiHeader from 'components/ChiayiMainPage/Header';
import Header from 'components/Header';

export const getMainPage = () => {
    switch (process.env.REACT_APP_PROJECT) {
        case "yunlinstudy":
            return "/YunlinMainPage";
        case "chiayistudy":
            return "/ChiayiMainPage";
        case "nanistudy":
            return "/NaniMainPage";
        default:
            return "/MainPage";
    }
}

export const getMainComponent = () => {
    switch (process.env.REACT_APP_PROJECT) {
        case "yunlinstudy":
            return YunlinMainPage;
        case "chiayistudy":
            return ChiayiMainPage;
        case "nanistudy":
            return NaniMainPage;
        default:
            return MainPage;
    }
}

export const getHeader = () => {
    switch (process.env.REACT_APP_PROJECT) {
        case "yunlinstudy":
            return <YunlinHeader />;
        case "chiayistudy":
            return <ChiayiHeader />;
        default:
            return <Header />;
    }
}