// const data = {
//   一上: [
//     { VolumeName: "一上", product: "111國小首冊國語", productcode: "...." },
//     { VolumeName: "一上", product: "....", productcode: "...." },
//   ],
//   一下: [
//     { VolumeName: "一下", product: "111國小首冊國語", productcode: "...." },
//     { VolumeName: "一下", product: "....", productcode: "...." },
//   ],
// };

// 透過 productcode 依照 unicode 排序
export const sortByProductCode = (data) => {
  const orderlyArray = [];
  Object.entries(data).forEach((item) => {
    const sortData = item[1][0].id.split("_");
    const sort = Number(
      sortData[1] +
        sortData[2] +
        sortData[3].charCodeAt(0) +
        (sortData[3][1] === undefined ? "00" : sortData[3].charCodeAt(1)) +
        sortData[4].charCodeAt(0) +
        (sortData[4][1] === undefined ? "00" : sortData[4].charCodeAt(1))
    );
    orderlyArray.push({
      title: item[0],
      volumes: item[1],
      sort,
    });
  });

  return orderlyArray.sort((a, b) => {
    return a.sort - b.sort;
  });
};

// 透過 Object 的 key 值（一上/一下......）依照 unicode 排序
export const sortByKey = (data) => {
  const orderlyArray = [];
  Object.entries(data)
    .sort()
    .forEach((item) => {
      orderlyArray.push({ title: item[0], volumes: item[1] });
    });

  return orderlyArray;
};

// 按照「一上一下二上二下」方式排序
const gradeOrder = [
  '一上',
  '一下',
  '二上',
  '二下',
  '三上',
  '三下',
  '四上',
  '四下',
  '五上',
  '五下',
  '六上',
  '六下',
  '七上',
  '七下',
  '八上',
  '八下',
  '九上',
  '九下',
]
export const sortByDefault = (data) => {
  let orderlyArray = [];
  const temp = [];
  Object.keys(data).forEach((grade) => {
    const arrangedData = {
      title: grade,
      volumes: data[grade]
    }
    if (gradeOrder.includes(grade)) {
      orderlyArray[gradeOrder.indexOf(grade)] = arrangedData;
    } else {
      temp.push(arrangedData);
    }
  })

  if (temp.length !== 0) {
    orderlyArray = [...orderlyArray, ...temp];
  }


  return orderlyArray;
};

// (暫) 課程內的各單元
export const sortByConvertCharToInt = (section) => {
  let sectionArr = [];
  for (var key in section) {
    if (key) {
      sectionArr.push({
        data: section[key],
        key,
      });
    }
  }
  const convertCharToInt = (strVerCodes) => {
    const codeNum = {
      0: 0,
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      a: 10,
      b: 11,
      c: 12,
      d: 13,
      e: 14,
      f: 15,
      g: 16,
      h: 17,
      i: 18,
      j: 19,
      k: 20,
      l: 21,
      m: 22,
      n: 23,
      o: 24,
      p: 25,
      q: 26,
      r: 27,
      s: 28,
      t: 29,
      u: 30,
      v: 31,
      w: 32,
      x: 33,
      y: 34,
      z: 35,
    };
    const codeNumCnt = Object.keys(codeNum).length;
    var verCodes = [];
    // convert char to number if found char
    strVerCodes = strVerCodes.toLowerCase();
    for (var i = 0; i < strVerCodes.length; i++) {
      const ch = strVerCodes[i];
      verCodes[i] = codeNum.hasOwnProperty(ch) ? codeNum[ch] : codeNumCnt;
    }
    return verCodes;
  };
  const listSort = (strArray) => {
    strArray.sort((a, b) => {
      const verCodes1 = convertCharToInt(a.key);
      const verCodes2 = convertCharToInt(b.key);
      for (var i = 0; i < verCodes1.length && i < verCodes2.length; i++) {
        if (verCodes1[i] != verCodes2[i])
          return verCodes1[i] < verCodes2[i] ? -1 : 1;
      }
      return 0;
    });
    return strArray;
  };
  sectionArr = listSort(sectionArr);
  return sectionArr
};
