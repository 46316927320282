import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import dayjs, { Dayjs } from 'dayjs';
import { StoreTypes, useStore } from "context";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import styles from "./index.module.scss";
import OrderLoading from 'components/Loading/OrderLoading';
import { useState, useCallback, useEffect } from 'react';
import short_uuid from "util/uuid"

const SearchOrder = (props) => {
	const { naniProducts } = props
	const [{ token }] = useStore(StoreTypes.user);
	const params = (new URL(document.location)).searchParams;
	const agentId = params.get('id');
	const agentName = params.get('name');
	const [productsList, setProducts] = useState(naniProducts)
	const [loading, setLoading] = useState(false)
	const [loadingMessage, setLoadingMessage] = useState('查詢中...')
	const [accounts, setAccounts] = useState('')
	const [userProductInfoList, setUserProductInfoList] = useState([])
	const [consumedProductList, setConsumedProductList] = useState([])
	const [codes, setCodes] = useState('')
	const [startTime, setStartTime] = useState(dayjs());
	const [endTime, setEndTime] = useState(dayjs().add(1, 'year'));

	useEffect(() => {
		if (naniProducts) {
			setProducts(naniProducts)
		}
	}, [naniProducts])

	const getUserProducts = () => {
		return fetch(`${process.env.REACT_APP_API_DOMAIN}/apis/getUserProducts`, {
			method: "POST",
			headers: { "Content-type": "application/json; charset=UTF-8" },
		}).then(function (response) {
			return response.json();
		}).then(function (response) {
			return response;
		})
	}

	const getSingleUserProducts = useCallback((id) => {
		return new Promise(async(r,e)=>{
			const res = await fetch(`${process.env.REACT_APP_API_DOMAIN}/apis/users/${id}/products`, {
				method: "GET",
				headers: { 
					"Content-type": "application/json; charset=UTF-8",
					"Authorization": token,
				},
			}).then(function (response) {
				return response.json();
			}).then(function (response) {
				return response;
			})
			if(res.status==='success'){
				r({id,product:res.data});
			}else{
				e(res)
			}
		})
	},[token])

	const getProductCodesInfo =useCallback(({ startTime, endTime }) => {
		var data = {
			startDate: startTime,
			endDate: endTime
		};
		return fetch(`${process.env.REACT_APP_API_DOMAIN}/apis/getProductCodesInfo`, {
			method: "POST",
			headers: { 
				"Content-type": "application/json; charset=UTF-8",
				"Authorization": token,
			},
			body: JSON.stringify(data),
		}).then(function (response) {
			return response.json();
		}).then(function (response) {
			return response;
		})
	},[token])

	const showUsersProducts = useCallback(async () => {
		setLoading(true)
		const users = accounts.split(";")
		const userProductsPromise=[]
		users.forEach((user)=>{
			userProductsPromise.push(getSingleUserProducts(user))
		})

		await Promise.all(userProductsPromise).then((res)=>{
			setUserProductInfoList(res)
			setLoading(false)
		}).catch(function (err) {
			console.log('get codes err', JSON.stringify(err));
		});
		// if (result.status === 'success') {
		// 	arr = result.data.filter((info) => {
		// 		return users.some((id) => id === info.id)
		// 	})
		// 	console.log("arr", arr);
		// 	setUserProductInfoList(arr)
		// }
	}, [accounts])

	const createUserProductsList = useCallback(() => {
		const elem = []
		userProductInfoList.forEach((info, index) => {
			elem.push(<div key={short_uuid()} className={styles.listName}>
				<h5 className={styles.listId}>{info.id}/</h5>
				<div>
					{
						info.product.map((product, index) => {
							const productInfo = getProductName(product.productCode)
							return productInfo ? <div key={short_uuid()}>{productInfo}</div> : false
						})
					}
				</div>
			</div>)
		})
		return elem
	}, [userProductInfoList])

	const createConsumedProductList = useCallback(() => {
		const elem = []
		Object.keys(consumedProductList).forEach((key)=>{
			elem.push(<div key={short_uuid()} className={styles.list}>
				{`${key} :`}
			</div>)
			consumedProductList[key].forEach((info, index) => {
				elem.push(<div key={short_uuid()} className={styles.listName}>
					{`　${info}`}
				</div>)
			})
		})
		
		return elem
	}, [consumedProductList])

	const getConsumedExchangeCodes = useCallback(() => {
		if (!codes) return;
		setLoading(true)
		fetch(`${process.env.REACT_APP_API_DOMAIN}/order/getCodeConsumers`, {
			method: "POST",
			headers: { 
				"Content-type": "application/json; charset=UTF-8",
				"Authorization": token,
			},
			body: JSON.stringify({ codes: codes.split(";") })
		}).then(function (response) {
			return response.json();
		}).then(function (response) {
			setLoading(false)
			if (response.status === "success") {
				Object.keys(response.data).length>0&&setConsumedProductList(response.data)
			}
		})
	}, [codes,token])

	const exportAllUserProductsInfo = useCallback(async () => {
		setLoading(true)
		const result = await getUserProducts();
		setLoading(false)
		if (result.status === 'success') {
			console.log(Date.parse(startTime), Date.parse(endTime));
			const start = Date.parse(startTime)
			const end = Date.parse(endTime)
			const arr = result.data.filter((info) => {
				const { product } = info
				let dateRangeProducts = []
				if (product.length > 0) {
					dateRangeProducts = product.filter((p) => {
						const { timestamp } = p
						let time = timestamp * 1000 - 31536000000
						// console.log(parseInt(timestampStr) >= parseInt(startStr), parseInt(timestampStr) <= parseInt(endStr));
						if (time >= start && time <= end) {
							return p
						}
					})
					return dateRangeProducts.length > 0 ? { ...info, product: dateRangeProducts } : false
				}
				return false
			})
			if (arr.length > 0) {
				exportUsersProducts(arr)
			} else {
				alert("該日期範圍無資料")
			}
		}
	}, [startTime, endTime])

	const exportAllProductCodesInfo = useCallback(async () => {
		setLoading(true)
		const result = await getProductCodesInfo({ startTime, endTime });
		setLoading(false)
		if (result.status === 'success') {
			const start = Date.parse(startTime)
			const end = Date.parse(endTime)
			console.log(start, end);

			// result.data.map((data) => {
			// 	console.log(data.createDate._seconds);
			// })

			const arr = result.data.filter((info) => {
				const { createDate } = info
				let time = createDate._seconds * 1000 //- 31536000000
				console.log(time);
				console.log(start);
				console.log(end);
				console.log(parseInt(time) >= start, parseInt(time) <= end);
				if (time >= start && time <= end) {
					return info
				}
			})
			console.log(arr);
			if (arr.length > 0) {
				exportProductCodesCSV(arr)
			} else {
				alert("該日期範圍無資料")
			}
		}
	}, [startTime, endTime])

	const exportUsersProducts = (userProducts) => {
		const row = userProducts.map((info, index) => {
			const { id, product } = info
			const arr = [id.substr(0, 1) === '0' ? `*${id}` : id]
			product.forEach((p) => {
				const productInfo = getProductName(p.productCode)
				if (productInfo) {
					arr.push(productInfo)
				}
			})
			return arr
		})

		console.log("row", row);
		let csvContent = "data:text/csv;charset=utf-8,\uFEFF"
			+ row.map(e => e.join(",")).join("\n");
		var encodedUri = encodeURI(csvContent);
		console.log("encodedUri", encodedUri);
		window.open(encodedUri);
	}

	const exportProductCodesCSV = (productCodes) => {
		const row = productCodes.map((info, index) => {
			const { agentId, createDate, id } = info
			const arr = []
			const date = (new Date(createDate._seconds * 1000))
			const dateInfo = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
			arr.push(id, agentId, dateInfo)
			return arr
		})

		let csvContent = "data:text/csv;charset=utf-8,\uFEFF"
			+ row.map(e => e.join(",")).join("\n");
		var encodedUri = encodeURI(csvContent);
		console.log("encodedUri", encodedUri);
		window.open(encodedUri);
	}

	const getProductName = (code) => {
		const productInfo = productsList.find((list) => {
			return (list.code === code)
		})
		return productInfo ? productInfo.product : null
	}

	const exportProductCodes = useCallback(() => {
		const row = consumedProductList.map((info, index) => {
			const { id, consumerId } = info
			const newId = consumerId.substr(0, 1) === '0' ? `*${consumerId}` : consumerId
			return [id, newId]
		})
		let csvContent = "data:text/csv;charset=utf-8,\uFEFF"
			+ row.map(e => e.join(",")).join("\n");
		var encodedUri = encodeURI(csvContent);
		console.log("encodedUri", encodedUri);
		window.open(encodedUri);
	}, [consumedProductList])

	return (
		<div className={styles.SearchOrder}>
			{loading && <OrderLoading message={loadingMessage} />}
			<div className={styles.tabs}>
				<div
					id="mainLeft"
					className={styles.leftSelect}
				>
					<h5>帳號查詢:(分號;分隔)</h5>
					<TextareaAutosize
						maxRows={4}
						aria-label="maximum height"
						placeholder=""
						defaultValue=""
						style={{ width: '100%', height: 100 }}
						value={accounts}
						onChange={(e) => setAccounts(e.target.value)}
					/>
					<h5>帳號/已兌換產品</h5>
					<div className={styles.accountsProductList}>
						<div>
							{
								createUserProductsList()
							}
						</div>
					</div>
					<div className={styles.sendBtn}>
						<Button onClick={() => exportUsersProducts(userProductInfoList)}>匯出表單</Button>
						<Button onClick={() => showUsersProducts()}>查詢</Button>
					</div>
				</div>
				<div
					id="mainCenter"
					className={styles.centerSelect}
				>
					<h5>兌換碼查詢:(分號;分隔)</h5>
					<TextareaAutosize
						maxRows={4}
						aria-label="maximum height"
						placeholder=""
						defaultValue=""
						style={{ width: '100%', height: 100 }}
						value={codes}
						onChange={(e) => setCodes(e.target.value)}
					/>

					<h5>兌換碼/已兌換帳號</h5>
					<div className={styles.consumedProductList}>
						<div>
							{
								createConsumedProductList()
							}
						</div>
					</div>

					<div className={styles.sendBtn}>
						<Button onClick={() => exportProductCodes()}>匯出表單</Button>
						<Button onClick={() => getConsumedExchangeCodes()}>查詢</Button>
					</div>
				</div>
				<div id="mainRight" className={styles.rightSelect}>
					<div id="purchasedProducts" className={styles.textContent}>
						<h5>兌換碼資訊查詢</h5>
						<div className={styles.sendBtn}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateTimePicker
									label="開始時間"
									value={startTime}
									onChange={(value) => {
										setStartTime(value)
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</div>
						<div className={styles.sendBtn}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateTimePicker
									label="結束時間"
									value={endTime}
									onChange={(value) => {
										setEndTime(value)
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</div>
						<div className={styles.sendBtn}>
							<Button onClick={() => exportAllUserProductsInfo()}>帳號兌換表單匯出</Button>
						</div>
						<div className={styles.sendBtn}>
							<Button onClick={() => exportAllProductCodesInfo()}>兌換碼產出紀錄匯出</Button>
						</div>
					</div>
				</div>
			</div>
		</div >
	);
}

export default SearchOrder;