import React, { useState, useCallback, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import * as actionTypes from 'constants/actionTypes';
import { useLogin } from "util/login"
import { useOutsideAlerter } from "customHooks/outsideAlerter";
import Login from "components/Login";

const OrderLogin = () => {
	const ref = useRef();
	const [{ name, isLogin }, userDispatch] = useStore(StoreTypes.user);
	const [, setIsUserList] = useState(false);
	const [{ nowHeadSelectListName },] = useStore(StoreTypes.global);
	const { logoutNaniOneClass } = useLogin();
	const [open, setOpen] = useState(true);

	useEffect(() => {
		setOpen(!isLogin)
	}, [isLogin])

	const loginBtn = useCallback(() => {
		if (isLogin) {
			userDispatch({
				type: actionTypes.SET_USER_INFO,
				name: "",
				isLogin: false,
				token: "",
				userId: "",
				product: null
			});
			logoutNaniOneClass();
			setOpen(true);
		} else {
			setOpen(true);
		}
	}, [name, userDispatch, logoutNaniOneClass, open, setOpen, isLogin]);

	const setClose = () => {
		setIsUserList(false);
	}

	useOutsideAlerter(ref, setClose);

	return (
		<div ref={ref} className={styles.userInfoArea}>
			<Login open={open} setOpen={setOpen} lockClose={true} disableCloseBtn={false} historyPath="UserSearchInfoPage" />
			{isLogin ? (
				<div className={styles.userInfoContent}>
					<div className={styles.userIcon}>
						<div className={styles.nowListText}>
							<div>{nowHeadSelectListName}</div>
						</div>
						<div
							className={styles.userBtn}
							onClick={() => loginBtn()}
						>
							{name}
						</div>
					</div>
				</div>
			) : (
				<div className={styles.userInfoContent}>
					<div className={styles.userIcon}>
						<div className={styles.nowListText}>
							<div>{nowHeadSelectListName}</div>
						</div>
						<div
							className={styles.userBtn}
							onClick={() => loginBtn()}
						>
							{'登入'}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
export default OrderLogin;
