import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from "./index.module.scss";
import OrderLoading from 'components/Loading/OrderLoading';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useStore, StoreTypes } from "context";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import dayjs, { Dayjs } from 'dayjs';


let durationValue = 366
let account = []

const BatchCreateOrderCode = (props) => {
	const { naniProducts } = props
	const [{ token, userId, name, isLogin }, userDispatch] = useStore(StoreTypes.user);
	const params = (new URL(document.location)).searchParams;
	const agentId = params.get('id');
	const agentName = params.get('name');
	const [productsList, setProducts] = useState(naniProducts)
	const [purchasedProducts, setPurchasedProducts] = useState([]);
	const [enterOrderId, setEnterOrderId] = useState('');
	const [enterPhone, setEnterPhone] = useState('');
	const [accounts, setAccounts] = useState('');
	const [productCodes, setProductCode] = useState([]);
	const [loading, setLoading] = useState(false)
	const uploadInputRef = useRef();
	const [usageDurationValue, setUsageDurationValue] = useState(366);
	let productCodeNum = 1;
	let getProductCodeCount = 1;
	let getProductCodes = [];

	useEffect(() => {
		if (naniProducts) {
			setProducts(naniProducts)
		}
	}, [naniProducts])

	const change = useCallback((e) => {
		if (e.target.value) {
			if (e.target.value.length > 0) {
				var keyWords = e.target.value.split(';');
				const list = []
				naniProducts.forEach((info) => {
					for (var j = 0; j < keyWords.length; j++) {
						if (info.product.indexOf(keyWords[j]) >= 0) {
							list.push(info)
						}
					}

				})
				setProducts(list)
			}
		} else {
			setProducts(naniProducts)
		}
	}, [naniProducts])

	const addProductsHandler = useCallback((e, products) => {
		const hasProduct = purchasedProducts.some((info) => info.product === products.product)
		!hasProduct && setPurchasedProducts([...purchasedProducts, products])
	}, [purchasedProducts])

	const removeProductsHandler = useCallback((e, products) => {
		const hasProduct = purchasedProducts.some((info) => info.product === products.product)
		let arr = []
		arr = arr.concat(purchasedProducts)
		if (hasProduct) {
			arr = arr.filter((info) => info.product !== products.product)
		} else {
			arr.push(products)
		}
		arr.sort()
		setPurchasedProducts(arr)
	}, [purchasedProducts])

	const selectAllProduct = useCallback(() => {
		const arr = productsList.filter((info) => {
			return !purchasedProducts.find((products) => products.product === info.product)
		})
		console.log(arr);
		const newList = [...purchasedProducts, ...arr]
		newList.sort((a, b) => a.codes > b.codes)
		setPurchasedProducts([...purchasedProducts, ...arr])
	}, [purchasedProducts, productsList])

	const createExchangeCode = useCallback(({ agentId, agentName, userOrderId, userPhone, items }) => {
		return new Promise((resolve, reject) => {
			// proceed calling API createVideoOrder
			fetch(`${process.env.REACT_APP_API_DOMAIN}/order/createCode`, {
				method: "POST",
				body: JSON.stringify({
					agentId: agentId,
					agentName: agentName,
					userOrderId: userOrderId,
					userPhone: userPhone,
					items: items,
					enableProduct: true,
					codeUsageStart:dayjs().subtract(1, 'day'),
					codeUsageEnd:dayjs().add(1, 'year'),
					usageDurationValue: durationValue
				}),
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					"Authorization": token
				}
			})
				.then(function (response) {
					return response.json();
				})
				.then(function (response) {
					if (response.status == "success") {
						resolve(response.data);
					}
					else {
						reject(`response error:${JSON.stringify(response.error)}`);
					}
				})
				.catch(function (err) {
					reject(`錯誤:${err.message ? err.message : err}`);
				});
		});
	}, [token])

	const createOrders = useCallback(async () => {
		if (purchasedProducts.length <= 0) {
			alert("請選擇產品列表！！")
			return;
		};
		setLoading(true)
		createProductCodes();
	}, [enterOrderId, enterPhone, purchasedProducts])

	const createProductCodes = useCallback(() => {
		var userPhone = enterPhone ? enterPhone : 'O';
		var items = [];
		var codeNum = 1;

		purchasedProducts.forEach((productInfo) => {
			const { code, product } = productInfo
			items.push({
				code: code,
				product: product
			});
		})

		var myPromise = [];
		for (var i = 0; i < account.length; i++) {
			myPromise.push(createExchangeCode({ agentId: userId, agentName: name, userOrderId: account[i][0], userPhone, items }));
		}

		Promise.all(myPromise)
			.then((result) => {
				console.log("result", result);
				setLoading(false)
				setEnterOrderId("")
				setEnterPhone("")
				setPurchasedProducts([])
			}).catch(function (err) {
				console.log('get codes err', JSON.stringify(err));
			});
	}, [enterPhone, userId, name, accounts, purchasedProducts])

	const clearSelectProductList = useCallback(() => {
		setPurchasedProducts([])
	}, [setPurchasedProducts])

	const Upload = (e) => {
		var input = e.target;
		if (!input) return;
		var meta = input.files[0];
		var filename = meta.name;
		if (meta && window.FileReader) {
			var reader = new FileReader();
			reader.onload = strToJson;
			//reader.onerror = errorHandler;    
			reader.readAsText(meta);
		} else {
			alert('FileReader are not supported in this browser.');
		}
	}

	const uploadInputClick = useCallback(() => {
		if (!uploadInputRef.current) return;
		uploadInputRef.current.value = ""
		uploadInputRef.current.click();
		//document.getElementById('uploadInput').click();
	}, [uploadInputRef])

	const setAccountsHandler = (value) => {
		const arr = value.split(",")
		const accounts = arr.map((v) => {
			return [v]
		})
		account=accounts
		setAccounts(accounts)
	}

	const strToJson = (event) => {
		// Get file from drop or select method
		var result;
		if ("result" in event.target) {
			result = event.target.result;
		} else {
			result = event.dataTransfer.files;
		}
		// Split string into rows then columns
		var json = [], str, arrX, o, q, arr;
		var rows = result.split(/\r\n|\n/);
		for (var i = 0, r; r = rows[i]; i++) {
			// Only basic double quotes
			str = r.replace(/“|”/g, "\"");
			// Allow for commas if enclosed with double quotes
			arr = [];
			arrX = str.split('');
			o = 0;	// Offset of string
			q = false;	// Monitor if between quotes
			for (var j = 0, k; k = arrX[j]; j++) {
				if (k == "," && q === false) {
					arr.push(str.substring(o, j));
					o = j + 1;
				}
				if (q === false && k == '"') {
					q = true;
					continue;
				}
				if (q === true && k == '"') {
					q = false;
				}
			}
			// String after last comma
			arr.push(str.substring(o, j));
			// Remove double quotes after checking for enclosed commas
			for (var j = 0, k; k = arr[j]; j++) {
				arr[j] = k.replace(/"/g, "");
			}
			// Add row to JSON array
			json.push(arr);
		}
		console.log("json", json);
		account=json
		setAccounts(json)
	}

	return (
		<div className={styles.BatchCreateOrderCode}>
			{loading && <OrderLoading message={"帳號兌換碼開通中..."} />}
			<div className={styles.tabs}>
				<div
					id="mainLeft"
					className={styles.leftSelect}
				>
					<h5>所有產品列表</h5>
					<TextField
						id="outlined-helperText"
						style={{ width: '95%' }}
						label=""
						defaultValue=""
						helperText="關鍵字 (以半形分號 ; 查詢關鍵字聯集)"
						variant="standard"
						onChange={(e) => change(e)}
					/>
					<div
						id="naniVideos"
						className={styles.productList}
					>
						<div>
							{productsList && productsList.map((products, index) => {
								return <div className={styles.listBtn} key={index} onClick={(e) => addProductsHandler(e, products)} >{products.product}</div>
							})}
						</div>
					</div>
					<div className={styles.sendBtn}>
						<Button onClick={(e) => selectAllProduct()}>全選</Button>
					</div>
				</div>
				<div
					id="mainCenter"
					className={styles.centerSelect}
				>
					<h5>已選擇列表</h5>
					<div
						id="naniVideos"
						className={styles.productList}
					>
						<div>
							{purchasedProducts && purchasedProducts.map((products, index) => {
								return <div className={styles.listBtn} key={index} onClick={(e) => removeProductsHandler(e, products)} >{products.product}</div>
							})}
						</div>
					</div>

					<div className={styles.sendBtn}>
						<Button onClick={(e) => clearSelectProductList()}>清除</Button>
					</div>
				</div>
				<div className={styles.rightSelect}>
					<div className={styles.sendBtn}>
						<input type="button" className={styles.uploadBtn} value="匯入帳號(.csv)" onClick={() => uploadInputClick()} />
						<input ref={uploadInputRef} type="file" className={styles.uploadInput} onChange={(e) => Upload(e)} />
					</div>
					<div className={styles.deadline}>
						<div>產品使用期限(不輸入預設366日)</div>
						<div>
							<TextField
								id="outlined-number"
								label=""
								type="number"
								InputLabelProps={{
									shrink: true,
								}}
								value={usageDurationValue}
								onChange={(e) => {
									durationValue = e.target.value
									setUsageDurationValue(e.target.value)
								}}
							/>
						</div>

					</div>
					<TextareaAutosize
						type={"text"}
						placeholder=""
						defaultValue=""
						style={{
							width: 236, height: '66%', marginTop: '5%', paddingBottom: 15
						}}
						value={accounts}
						onChange={(e) => setAccountsHandler(e.target.value)}
					/>
					<div className={styles.sendBtn}>
						<Button onClick={(e) => createOrders()}>執行</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BatchCreateOrderCode;