import { useEffect, useCallback } from "react";

export const useOutsideAlerter = (ref,callback) => {

  const handleClickOutside = useCallback((event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  },[ref,callback])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  },[ref,callback,handleClickOutside]);


}