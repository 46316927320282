import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import { IMAGES } from "constants/imagePath";
import { SUBJECT_TYPE } from "constants/subjectType";
import { API } from "api";
import VideoRecord from "./VideoRecord";
import TestRecord from "./TestRecord";
import { timeFormatTommss } from 'util/timeFormater';
import { checkProjectType } from "constants/projectsType";

const StudyNode = ({ productCode, volume, subject, product }) => {
	const [{ productInfo, educationInfo }, globalDispatch] = useStore(StoreTypes.global);
	const [paramsInfo, setParamsInfo] = useState({
		productCode: productInfo ? productInfo.productCode : productCode,
		course: productInfo ? productInfo.course : volume,
		subject: productInfo ? productInfo.subject : subject,
		product: productInfo ? productInfo.product : product,
	});
	// console.log('productInfo', productInfo)
	const [{ token, userId }] = useStore(StoreTypes.user);
	const [videoRecodeList, setVideoRecodeList] = useState(null);
	const [playCount, setPlayCount] = useState(0);
	const [totalMin, setTotalMin] = useState(0);

	useEffect(() => {
		(async () => {
			if (!token) return;
			const result = await API.get(
				`${process.env.REACT_APP_API_DOMAIN}/video/getUserVideoLog/${productCode}`,
				null,
				token
			);

			if (result.data) {
				let totalTime = result.data.log.reduce((acc, cur) => acc + cur.time, 0)
				setPlayCount(result.data.count);
				setTotalMin(timeFormatTommss(totalTime));

				result.data.log.sort(function (a, b) {
					return b.timestamp - a.timestamp;
				});
				setVideoRecodeList(result.data.log);

			}
		})();
	}, [token, productCode, productInfo]);

	const getImgSrc = useCallback(() => {
		if (!paramsInfo) return;
		const { productCode, course, subject } = paramsInfo;
		let imgSrc = `${course}_${subject}`;
		if (checkProjectType()) {
			if (course.indexOf('國中') > -1 || course.indexOf('國小') > -1 || course.indexOf('高中') > -1) {
				imgSrc = `${course.slice(3)}_${SUBJECT_TYPE[subject]}`;
			}
			return IMAGES.VOLUME[imgSrc];
		} else {
			console.log(imgSrc)
			return IMAGES.NANI_VOLUME[imgSrc];
		}


	}, [paramsInfo]);

	return (
		<div className={styles.studyNode}>
			<div className={styles.classImage}>
				{<img src={getImgSrc()} alt={volume} />}
			</div>
			<div className={styles.courseText}>
				{productInfo && productInfo.product}
			</div>
			<div className={styles.titleText}>
				<div className={styles.studyText}>
					<div>
						<div className={styles.circle}></div>
						<div>學習報告</div>
					</div>
				</div>
				<div className={styles.searchBtn}>
					<div className={styles.left}>最近七天</div>
					<div className={styles.right}>全部</div>
				</div>
			</div>
			<div className={styles.total}>
				<div className={styles.totalArea}>
					<div className={styles.viewText}>
						<img src="./assets/img/eye_solid.svg" alt="" />
						<div>概覽</div>
					</div>
					<div className={styles.video}>
						<div className={styles.text}>
							<div>
								<div>影片</div>
								<img src="./assets/img/Play.png" alt="" />
							</div>
						</div>
						<div className={styles.playCountContent}>
							<div>
								<div className={styles.playCountText}>
									已觀看部數
								</div>
								<div>{playCount}</div>
							</div>
						</div>
						<div className={styles.minuteCountContent}>
							<div>
								<div className={styles.minuteCountText}>
									已觀看分鐘數
								</div>
								<div>{totalMin}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<VideoRecord videoRecodeList={videoRecodeList} />
			<TestRecord
				testList={videoRecodeList}
				subject={subject}
				userId={userId}
			/>
		</div>
	);
};
export default StudyNode;
