import React, { useState, useCallback, useEffect } from "react";
// import styles from "./index.module.scss";
import useSetState from "customHooks/setState";
import { useStore, StoreTypes } from "context";
import * as actionTypes from "constants/actionTypes";
import md5 from "md5";
import { faDigitalTachograph } from "@fortawesome/free-solid-svg-icons";
import ParamsStorer from "../util/paramsStorer";

export const useLogin = () => {
  const [{ token, userId, name }, userDispatch] = useStore(StoreTypes.user);
  var cookieLoginName = "nani_oneclass_login_token";
  //var dburl = "https://zib0bugold.execute-api.ap-northeast-1.amazonaws.com/prod/users/";
  var dburl = "https://api.nani.cool/dev/members/";//上面因為新的帳號會無法登入

  //"REACT_APP_API_DOMAIN": "http://localhost:5001/onestudy-dev-ce9b0/asia-northeast1/"
  // var apisUrl = `http://localhost:5001/onestudy-dev-ce9b0/asia-northeast1/apis/`;
  var apisUrl = `${process.env.REACT_APP_API_DOMAIN}/apis/`;

  var getUserUrl = apisUrl + "GetUser";

  //var loginUrl = dburl + "login";
  var loginUrl = dburl + "signinforthirdparty";//上面因為新的帳號會無法登入

  var renewProductUrl = apisUrl + "users/renewOneLiveProducts"

  function setNaniOneClassCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    var hostname = window.location.hostname;
    if (hostname.indexOf("oneclass.com.tw") > 0) {
      document.cookie =
        cname + "=" + cvalue + ";" + expires + ";path=/;domain=oneclass.com.tw";
    } else {
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
  }

  const logoutNaniOneClass = () => {
    try {
      ParamsStorer.Instance().setData("userProfile", null);
      deleteNaniOneClassCookie(cookieLoginName);
      return true;
    } catch (error) {
      return false;
    }
  };

  var deleteNaniOneClassCookie = function (name) {
    var hostname = window.location.hostname;
    if (hostname.indexOf("oneclass.com.tw") > 0) {
      document.cookie =
        name +
        "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=oneclass.com.tw";
    } else {
      document.cookie =
        name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
    }
  };

  const saveTokenData = (token) => {
    let saveToken = {
      code: "SUCCESS",
      jwt: token,
    };
    setNaniOneClassCookie(cookieLoginName, JSON.stringify(saveToken));
    //使用不同登入api 寫法不一樣
    // if (typeof (token) == "object") {
    //     setNaniOneClassCookie(cookieLoginName, JSON.stringify(token));
    // }
    // else if (typeof (token) == "string") {
    //     setNaniOneClassCookie(cookieLoginName, token);
    // }
  };

  const getToken = () => {
    let cookie = document.cookie.match(
      "(^|;) ?nani_oneclass_login_token=([^;]*)(;|$)"
    );
    let token = cookie ? JSON.parse(cookie[2]).jwt : null;
    return token;
  };

  const renewOneLiveProducts = ({ token }) => {
    return new Promise(async (resolve, reject) => {
      fetch(renewProductUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization"
: token,
        },
      })
        .then((response) => {
          resolve(response.json());
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getUser = useCallback(
    async ({ token, getExpiredProduct, source = 'general' }) => {
      return new Promise(async (resolve, reject) => {
        fetch(getUserUrl + `?getExpiredProduct=${getExpiredProduct}&source=${source}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            let name = "";
            let data = {};
            if (response.status === "success") {
              name = response.data.name;
              data = response.data;
              if (!name) {
                name = response.data.lastName + response.data.firstName;
                data["name"] = name;
              }
            } else {
              if (
                response.error &&
                (response.error.errorCode == "20106" ||
                  response.error.errorCode == "20112" ||
                  response.error.errorCode == "20113")
              ) {
                name = "無個人資料";
              }
              data["name"] = name;
              reject("無個人資料");
            }
            ParamsStorer.Instance().setData("userProfile", data);
            // userDispatch({ type: actionTypes.SET_USER_INFO, name: "無個人資料", isLogin: true });
            resolve(data);
            return;
          })
          .catch(function (err) {
            reject("取得個人資料失敗");
          });
      });
    },
    [userDispatch]
  );

  const sign = useCallback(
    async ({ username, password }) => {
      return new Promise(async (resolve, reject) => {
        let dataJson = {
          username: username,
          password: password,
          from: "Nani",
        };

        fetch(loginUrl, {
          method: "POST",
          body: JSON.stringify(dataJson),
          headers: { "Content-type": "application/json" },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            if (response.code === "SUCCESS") {
              // userDispatch({ type: actionTypes.SET_USER_INFO, token: response.data.token, userId: username });
              // await  getUser(response.data.token)
              resolve({token:response.jwt});
            } else {
              reject(response.error.message);
            }
          })
          .catch(function (err) {
            reject("登入失敗");
          });
      });
    },
    [userDispatch]
  );

  return {
    sign,
    getUser,
    getToken,
    saveTokenData,
    logoutNaniOneClass,
    renewOneLiveProducts,
  };
};

// let cookie = document.cookie.match('(^|;) ?nani_oneclass_login_token=([^;]*)(;|$)')
// let token = cookie ? JSON.parse(cookie[2]).jwt : null;

// if (!otp && token) {
//     saveTokenData(JSON.stringify({ code: "SUCCESS", jwt: token }))
// }
