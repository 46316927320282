import React, { useEffect, useCallback, useState, useRef } from "react";
import styles from "./index.module.scss";
import * as types from "constants/actionTypes";
import { StoreTypes, useStore } from "context";
import VideoProgress from "components/VideoContent/VideoProgress";
import Loading from "components/Loading";
import { API } from "api";
import classnames from "classnames";
import { useUpdateVideoTime } from "customHooks/updateVideoTime";
import { timeFormatTommss } from "util/timeFormater";

let videoInterval;
let playInfo;

const VideoContent = ({ productCode, onScrollVideoContent }) => {
	const [
		{ videoList, recordData, videoSrc, isTryOut },
		globalDispatch,
	] = useStore(StoreTypes.global);
	const [{ token, product }] = useStore(StoreTypes.user);
	const [videoInfo, setVideoInfo] = useState(null);
	const [progressInfo, setProgressInfo] = useState(null);
	const videoRef = useRef();
	const { updateTime } = useUpdateVideoTime();
	const [videosTime, setVideosTime] = useState([]);
	const [test, setTest] = useState([]);
	const videoContentRef = useRef()

	useEffect(() => {
		(async () => {
			if (videoList && videoList.length <= 0) return;
			const ids = [];
			for (var key in videoList) {
				ids.push(videoList[key]["code"]);
			}
			const result = await API.post(
				`${process.env.REACT_APP_API_DOMAIN}/video/getUserVideoWatchTime`,
				{
					id: ids,
				},
				token
			);
			if (result.status === "success") {
				setProgressInfo(result.data);
			} else {
				setProgressInfo([]);
			}
		})();
		return () => {
			setVideosTime([])
		}
	}, [videoList, token]);

	const getProgressInfo = (data, progressInfo) => {
		const info = progressInfo.find((info) => data["code"] === info.id);
		return info;
	};

	useEffect(() => {
		if (!progressInfo) return;
		setTest(videosTime)
		videosTime.length > 0 && videosTime.forEach((info, index) => {
			const { time, id } = info
			const timeEle = document.getElementById(`videoTime_${id.split("_")[1]}`)
			if (timeEle) {
				timeEle.textContent = `時間:${time}`
			}
		})
	}, [videosTime, progressInfo])

	const setVideoDuraction = useCallback((e) => {
		if (!videosTime && videosTime.length <= 0) return;
		setVideosTime([...videosTime, { time: timeFormatTommss(e.target.duration), id: e.target.id }])
	}, [videosTime, test])

	const createVideoList = useCallback((section, progressInfo) => {
		let elem = [];
		let keyCount = 0;
		for (var key in section) {
			const data = section[key];
			const isDemo = data.demo;
			console.log(isDemo)
			elem.push(
				<div key={keyCount} className={styles.list}>
					<div
						className={classnames(styles.content, {
							[styles.show]: !isTryOut || isDemo === "Y",
						})}
						onClick={() =>{
							(!isTryOut || isDemo === "Y") && openVideo(data)
						}
						}
					>
						<div className={styles.number}>
							<div>{keyCount + 1}</div>
						</div>
						<div className={styles.videoView}>
							<div>
								<video
									id={`videoIndex_${keyCount}`}
									className={styles.video}
									src={section[key]["videoUrl"]}
									disablePictureInPicture
									controlsList="nodownload"
									onLoadedMetadata={(e) => setVideoDuraction(e)}
								/>
							</div>
							{isTryOut && (isDemo === "" || isDemo === 'N') ? (
								<div className={styles.videoLock}>
									<img
										src="./assets/img/video_lock2.png"
										alt=""
									/>
								</div>
							) : (
								<div className={styles.progressBG}></div>
							)}
							{!isTryOut && (
								<VideoProgress
									progress={getProgressInfo(
										data,
										progressInfo
									)}
								/>
							)}
						</div>
						<div className={styles.textContent}>
							<div>{section[key]["name"]}</div>
							<div></div>
							<div className={styles.time}>
								<div id={`videoTime_${keyCount}`}></div>
							</div>
						</div>
					</div>
				</div>
			);
			keyCount++;
		}
		return elem;
	}, [isTryOut, videosTime, test]);

	const openVideo = useCallback(
		(data) => {
			const id = data["code"];
			setVideoInfo(data);
			globalDispatch({
				type: types.SET_VIDEO_SRC,
				videoSrc: data["videoUrl"],
			});
		},
		[globalDispatch]
	);

	const initPlayInfo = useCallback(() => {
		if (!videoRef.current || !videoInfo || !productCode) return;
		const videoCurrent = videoRef.current;
		const id = videoInfo["code"];
		const name = videoInfo["name"];

		//{"id":"BBBBB","time":1.203944,"duration":160.751746 ,"name":"BB"}
		let currentTime = videoCurrent.currentTime
		const info = { id, time: currentTime, duration: 0, name, productCode };
		info.duration = videoCurrent.duration;
		info.time = videoCurrent.currentTime;
		playInfo = info

		clearInterval(videoInterval);
		videoInterval = setInterval(() => {
			info.duration = videoCurrent.duration;
			info.time = videoCurrent.currentTime;
			playInfo = info
		}, 200);
	}, [videoRef, videoInfo, productCode])

	const savePartInfo = useCallback(async () => {
		clearInterval(videoInterval);
		productCode && await updateTime(playInfo);
	}, [])

	useEffect(() => {
		if (!isTryOut && !videoRef.current) return;
		const videoCurrent = videoRef.current;
		videoCurrent && videoCurrent.addEventListener('pause', savePartInfo);
		videoCurrent && videoCurrent.addEventListener('play', initPlayInfo);

		return async () => {
			videoCurrent && videoCurrent.removeEventListener('pause', savePartInfo);
			videoCurrent && videoCurrent.removeEventListener('play', initPlayInfo);
			clearInterval(videoInterval);
			if (playInfo) {
				const { time, duration } = playInfo
				if (Math.round(time) < Math.round(duration)) {
					savePartInfo()
				}
			}
			playInfo = null;
		};
	}, [videoRef, videoSrc]);

	return (
		<div className={styles.videoListContent} onClick={onScrollVideoContent}>
			{videoSrc && (
				<div className={styles.videoPlayContent}>
					<div className={styles.videoPlay}>
						<video
							ref={videoRef}
							className={styles.videoPlay}
							src={videoSrc}
							autoPlay
							controls
							controlsList="nodownload"
							disablePictureInPicture
						/>
					</div>
				</div>
			)}

			<div>
				{videoList.length > 0 && (
					<div className={styles.videoContentTitle}>影片列表</div>
				)}
				<div className={styles.listContent}>
					{progressInfo ? (
						createVideoList(videoList, progressInfo)
					) : (
						<Loading />
					)}
				</div>
			</div>
		</div>
	);
};

export default VideoContent;
