import React, { useState, useCallback, useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';


export const useGetEducationInfo = () => {
	const [, globalDispatch] = useStore(StoreTypes.global);

	useEffect(() => {
		const curTime = new Date();
		var year = curTime.getFullYear();
		if (curTime.getMonth() < 8) year -= 1;
		year = year - 1911;

		fetch(`${process.env.REACT_APP_API_DOMAIN}/apis/getProducts`, {
			method: "POST",
			body: JSON.stringify({ year: year.toString() }),
			headers: { "Content-type": "application/json; charset=UTF-8" },
		})
			.then((res) => {
				return res.json();
			})
			.then((result) => {
				let data = [];
				//let education = "國小";
				result.data.forEach((info) => {
					if (info["product"]) {
						const education = info["education"];
						const VolumeName = info["VolumeName"];
						// if (product.indexOf("主題式") > -1) {
						// 	education = "主題式";
						// }
						// if (product.indexOf("國小") > -1) {
						// 	education = "國小";
						// }
						// if (product.indexOf("國中") > -1) {
						// 	education = "國中";
						// }
						// if (product.indexOf("高中") > -1) {
						// 	education = "高中";
						// }
						if (!data[education]) {
							data[education] = [];
						}
						if (!data[education][VolumeName]) {
							data[education][VolumeName] = [];
						}
						data[education][VolumeName].push(info);
					}
				});

				globalDispatch({
					type: types.SET_EDUCATION_INFO,
					educationInfo: data,
				});
			});
	}, [globalDispatch])
}
