import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";

import { useStore, StoreTypes } from "context";
import * as actionTypes from "constants/actionTypes";
import DialogModule from "components/Dialogs/";
import classnames from "classnames";
import { useLogin } from "util/login";
import { translateGradeToChinese } from "util/translateGradetToChinese";
import { useLocation } from "react-router-dom";

const Login = ({ open, setOpen, lockClose, disableCloseBtn = true }) => {
  const location = useLocation();
  const { sign, getUser, saveTokenData, getToken, logoutNaniOneClass } =
    useLogin();
  const [errorMessage, setErrorMessage] = useState("");
  const [moeUrl, setMoeUrl] = useState("");
  const [isMoeUrlLoading, setIsMoeUrlLoading] = useState(false);
  const [{ token, userId, name, isLogin }, userDispatch] = useStore(
    StoreTypes.user
  );
  const [btnLoad, setBtnLoad] = useState(false);
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const { username, password } = loginData;

  useEffect(() => {
    document.cookie = "redirect=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }, []);

  const signCheck = useCallback(async () => {
    if (loginData.username.length === 0) {
      setErrorMessage("帳號未輸入");
      setBtnLoad(false);
    }
    if (loginData.password.length === 0) {
      setErrorMessage("密碼未輸入");
      setBtnLoad(false);
    }
    var isAccount = checkAccount(loginData.username);
    var isPass = checkPass(loginData.password);
    if (isAccount && isPass) {
      signClick();
    } else {
      setErrorMessage("帳號密碼錯誤字元");
      setBtnLoad(false);
    }
  });

  const signClick = useCallback(async () => {
    setBtnLoad(true);
    let isSuccess = false;
    let nowToken = "";
    await sign(loginData)
      .then((response) => {
        userDispatch({
          type: actionTypes.SET_USER_INFO,
          token: response.token,
        });
        nowToken = response.token;
        isSuccess = true;
      })
      .catch((err) => {
        setErrorMessage(err);
        setBtnLoad(false);
      });

    await saveTokenData(nowToken);

    if (isSuccess) {
      const source = process.env.REACT_APP_PROJECT.includes('yunlin') ? 'yunlin' : 'general';
      await getUser({ token: nowToken, getExpiredProduct: true, source })
        .then((response) => {
          // userDispatch({ type: actionTypes.SET_USER_INFO, token: response.data.token, userId: username });
          // userDispatch({ type: actionTypes.SET_USER_INFO, name: response.name ,login:true});
          if (response.identity !== "教師" && response.identity !== "管理者") {
            userDispatch({
              type: actionTypes.SET_USER_INFO,
              name: "",
              isLogin: false,
              token: "",
              userId: "",
              product: null,
              schoolName: null,
              isAdmin: null,
            });
            logoutNaniOneClass();
            setErrorMessage("無法登入");
            setBtnLoad(false);
            return;
          }
          userDispatch({
            type: actionTypes.SET_USER_INFO,
            token: nowToken,
            name: response.name,
            isLogin: true,
            userId: response.username,
            product: response.product,
            isOneLiveMember: response.isOneLiveMember
              ? response.isOneLiveMember
              : false,
            oneLiveMemberCloseTime: response.oneLiveMemberCloseTime
              ? response.oneLiveMemberCloseTime
              : null,
            schoolName: response.schoolInfo?response.schoolInfo.schoolName:null,
            isAdmin: response.isAdmin || null,
          });
          setOpen(false);
          setBtnLoad(false);
        })
        .catch((err) => {
          setErrorMessage(err);
          setBtnLoad(false);
        });
    }

    // }
  }, [getUser, loginData, setOpen, sign, userDispatch]);

  function checkAccount(message) {
    if (message.replace(/(^\s*)|(\s*$)/g, "").length == 0) {
      return false;
    }
    return true;
  }

  function checkPass(message) {
    if (message.replace(/(^\s*)|(\s*$)/g, "").length == 0) {
      return false;
    }
    return true;
  }

  // produce moe url for login
  useEffect(() => {
    const createOrgLoginForMoeApi = async () => {
      setIsMoeUrlLoading(true);
      try {
        const res = await fetch(
          `https://zib0bugold.execute-api.ap-northeast-1.amazonaws.com/prod/users/createOrgLoginForMoe`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              clientid: process.env.REACT_APP_CLIENT_ID,
              params: "",
            }),
          }
        );
        const result = await res.json();
        if (!result.status) {
          return;
        }
        setIsMoeUrlLoading(false);
        setMoeUrl(result.data.url);
      } catch (error) {
        console.error(error);
      }
    };
    createOrgLoginForMoeApi();
  }, []);

  // 教育雲成功登入後會得到 token，再把這個 token 打這個 api
  useEffect(() => {
    const token = getToken();
    const source = process.env.REACT_APP_PROJECT.includes('yunlin') ? 'yunlin' : 'general';
    const teachingCloudLoginGetTokenApi = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/apis/GetUser?source=${source}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const userLoginResult = await res.json();
        if (userLoginResult.status !== "success") {
          return;
        }
        const schoolid = userLoginResult.data.schoolid ? userLoginResult.data.schoolid : userLoginResult.data.schoolID;
        const schoolName = userLoginResult.data.schoolInfo && userLoginResult.data.schoolInfo.schoolName || userLoginResult.data.schoolName
        if (userLoginResult.data.identity !== "教師" || !schoolid) {
          //logoutNaniOneClass();
          userDispatch({
            type: actionTypes.SET_USER_INFO,
            name: "",
            isLogin: false,
            token: "",
            userId: "",
            product: null,
            schoolName: null,
            isAdmin: null,
          });
          setErrorMessage("無法登入");
          setBtnLoad(false);
          return;
        }
        const education = translateGradeToChinese(userLoginResult.data.grade);
        userDispatch({
          type: actionTypes.SET_USER_INFO,
          userId: userLoginResult.data.username,
          token: getToken(),
          name: userLoginResult.data.name,
          login: true,
          isLogin: true,
          isTeacher: userLoginResult.data.isTeacher,
          schoolName,
          isAdmin: userLoginResult.data.isAdmin || null,
          education,
        });
        setOpen(false);
        setBtnLoad(false);
        await fetch(`${process.env.REACT_APP_API_DOMAIN}/apis/updateUser`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: getToken(),
          },
          body: JSON.stringify(userLoginResult.data),
        });
      } catch (error) {
        console.error(error);
      }
    };
    if (!getToken()) {
      return;
    }
    teachingCloudLoginGetTokenApi();
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "username") {
    }

    if (name === "password") {
    }

    setLoginData({ ...loginData, [name]: value });
  }
  const [isRemember, setIsRemember] = useState(true);
  const isRememberChangeHandler = () => {
    setIsRemember(!isRemember);
  };

  const ListNameTextField = (
    <div className={styles.container}>
      <a
        href={moeUrl}
        className={`${styles.btnLogin} ${styles.btnMoe} ${isMoeUrlLoading && styles.isLoading
          }`}
        onClick={() => {
          document.cookie = "redirect=/StudentsSearchInfoPage;";
        }}
      >
        教育雲端帳號登入
      </a>
      <h4>學習報告查詢系統</h4>
      <input
        className={styles.textInput}
        type={"text"}
        id={"username"}
        placeholder={"帳號"}
        required
        value={username}
        name="username"
        onChange={handleChange}
      />
      <input
        className={styles.textPassword}
        type={"password"}
        id={"password"}
        placeholder={"密碼"}
        required
        name="password"
        value={password}
        onChange={handleChange}
      />
      <p>{errorMessage}</p>
      <div className={styles.checkboxInput}>
        <input
          id="isRemember"
          type="checkbox"
          onChange={isRememberChangeHandler}
          checked={isRemember}
        />
        <label id="isRemember">記得我</label>
      </div>
      <button
        onClick={() => signCheck()}
        id={"startBtn"}
        className={classnames(styles.btnLogin, {
          [styles.disabled]: btnLoad,
        })}
        disabled={btnLoad}
      >
        登入
      </button>
    </div>
  );

  return (
    <div className={styles.loginArea}>
      <DialogModule
        type="userInfo"
        open={open}
        setOpen={lockClose ? () => { } : setOpen}
        content={ListNameTextField}
      />
    </div>
  );
};
export default Login;
