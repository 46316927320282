import React, { useEffect, useCallback, useState } from "react";
import styles from "./index.module.scss";
import { Accordion, AccordionItem } from "react-sanfona";
import { StoreTypes, useStore } from "context";
import * as types from "constants/actionTypes";
import { useUpdateVideoTime } from "customHooks/updateVideoTime";
import { API } from "api";
import LeftPannelLoading from "components/Loading/LeftPannelLoading";
import Button from "react-bootstrap/Button";
import classnames from 'classnames';
import short_uuid from "util/uuid";
import { sortByConvertCharToInt } from "util/listSort";

const dataType = {
	nani: { type: "nani", name: "南版", version: "南一" },
	knsh: { type: "knsh", name: "康版", version: "康軒" },
	hanlin: { type: "hanlin", name: "翰版", version: "翰林" },
}

const LeftPannel2 = ({
	productCode,
	volume,
	subject,
	isloading,
	setIsLoading,
}) => {
	const router = useStore(StoreTypes.router);
	const [{ playTimeInfo, goBackUrl }, globalDispatch] = useStore(
		StoreTypes.global
	);
	const [{ token, product, userId, education, isTeacher }] = useStore(StoreTypes.user);
	const [treeType, setTreeType] = useState("1");
	const [section, setSection] = useState({});
	const [productName, setProductName] = useState("");
	const [isPurchase, setIsPurchase] = useState(false);
	const [dataTypeArr, setDataTypeArr] = useState([]);
	const [datalist, setDataList] = useState([]);
	const { updateTime } = useUpdateVideoTime();

	const checkoutProductcode = (purchaseCode, code) => {
		return purchaseCode.find((info) => info.productCode === code);
	};

	const getDataType = (typeName) => {
		for (var key in dataType) {
			if (typeName === dataType[key].version) {
				return dataType[key]
			}
		}
	}

	const createVideoUrl = (info) => {
		let { bookid, item, videoname, VolumeName, year, education, subject, url, app_item } = info
		// bookid = '0078ee972c0c87eb'
		// item = '教學動畫'
		// videoname = '2上ch1科舉制度始於哪一朝代'
		const domain = bookid ? 'https://storage.googleapis.com/onebook-dev.appspot.com' : 'https://storage.googleapis.com/onestudy-dev-ce9b0.appspot.com'
		let videoUrl = url
		const volume = VolumeName.split('')
		if (videoname) {
			if (app_item === "深度學習") {
				videoUrl = `${domain}/media/${videoname}.mp4`
			} else {
				if (bookid) {
					//onebook-dev.appspot.com/media/0078ee972c0c87eb/video/教學動畫
					videoUrl = `${domain}/media/${bookid}/video/${item}/${videoname}.mp4`
				} else {
					//onestudy-dev-ce9b0.appspot.com/media/111上/國中/國文/七年級
					videoUrl = `${domain}/media/${year}${volume[volume.length - 1]}/${education}/${subject}/${volume[0]}年級/${videoname}.mp4`
				}
			}
		}

		return videoUrl;
	}

	const createSection = useCallback((datalist, typeName) => {

		const section = [];
		let chapter;
		let pName = "";
		let treeType = "1"
		datalist.forEach((info) => {
			info.videoUrl = createVideoUrl(info)
			if (info.version === typeName) {
				pName = info["product"];
				chapter = info["chapter"];
				if (chapter) {
					treeType = "1"
					if (!section[chapter]) {
						section[chapter] = [];
					}
					if (!section[chapter][info["section"]]) {
						section[chapter][info["section"]] = [];
					}
					if (!section[chapter][info["section"]][info["item"]]) {
						section[chapter][info["section"]][
							info["item"]
						] = [];
					}
					section[chapter][info["section"]][info["item"]].push(
						info
					);
				} else {
					treeType = "2";
					if (!section[info["section"]]) {
						section[info["section"]] = [];
					}
					if (!section[info["section"]][info["item"]]) {
						section[info["section"]][info["item"]] = [];
					}
					section[info["section"]][info["item"]].push(info);
				}
			}
		})
		// console.log("section", section);

		setTreeType(treeType)
		setProductName(pName);
		setSection(section);
	}, []);

	useEffect(() => {
		(async () => {

			if (!productCode) return;
			setDataList([])
			const curTime = new Date();
			var year = curTime.getFullYear();
			if (curTime.getMonth() < 8) year -= 1;
			year = year - 1911;
			setIsLoading(true);
			const result = await API.post(
				`${process.env.REACT_APP_API_DOMAIN}/apis/getVideos`,
				{
					year: year.toString(),
					filters: {
						productcode: productCode,
					},
				}
			);

				


			if (result.status === "success") {
				setIsLoading(false);
				const list = []
				let typeArr = []
				result.data.forEach((info) => {
					if (!info.version) {
						info.version = dataType.nani.version
					}
					const dataKind = getDataType(info.version)
					if (dataKind) {
						typeArr[dataKind.type] = { ...dataKind, disabled: false }
					}
					list.push(info)
				});
				typeArr = Object.values(typeArr).sort((a, b) => {
					return a.type < b.type ? 1 : -1;
				})
				if(list.length>0){
					if (isTeacher || education === list[0].education) {
						setIsPurchase(true);
					} else {
						setIsPurchase(false);
					}
				}
				
				if (typeArr.length > 0) {
					typeArr[0].disabled = true;
					setDataTypeArr(typeArr)
					createSection(list, Object.values(typeArr)[0].version)
				} else {
					setDataTypeArr([])
					createSection(list, '主題式')
				}

				setDataList(list)
			}
		})();
	}, [createSection, setIsLoading, setDataTypeArr, setDataList, productCode, product, isTeacher, education]);

	const checkHasExam = (info) => {
		let hasExam = false;
		for (var key in info) {
			if (info[key] && info[key][0] && info[key][0].hasExam) {
				hasExam = true;
			}
		}
		return hasExam;
	};

	const checkIsTryout = (info) => {
		let isTryout = false;
		for (var key in info) {
			if (info[key][0] && info[key][0].demo === "Y") {
				isTryout = true;
			}
			for (var key2 in info[key]) {
				if (
					info[key][key2] &&
					info[key][key2][0] &&
					info[key][key2][0].demo === "Y"
				) {
					isTryout = true;
				}
			}
		}
		return isTryout;
	};

	const accordionClose = (e) => {
		console.log("accordionClose", e);
	};
	const accordionExpand = (e) => {
		console.log("accordionExpand", e);
	};

	const creatTree = (section) => {
		let elem = [];
		let keyCount = 0;
		for (var key in section) {
			if (key) {
				elem.push(
					<Accordion key={keyCount} className={styles.tree1}>
						<AccordionItem
							title={
								!isPurchase && checkIsTryout(section[key]) ? (
									<div>
										<div>
											<img
												src="./assets/img/tryOut.svg"
												alt=""
											/>
											{key}
										</div>
									</div>
								) : (
									<div>
										<div>{key}</div>
									</div>
								)
							}
							onExpand={() => accordionExpand()}
							onClose={() => accordionClose()}
						>
							{creatTree2(section[key])}
						</AccordionItem>
					</Accordion>
				);
			}

			keyCount++;
		}
		return elem;
	};

	const creatTree2 = (section) => {
		let elem = [];
		const sectionArr = sortByConvertCharToInt(section)
		sectionArr.forEach((obj, index) => {
			const name = obj.key;
			const keyName = Object.keys(obj.data)[0];
			elem.push(
				<Accordion key={index} className={styles.tree2}>
					{name !== "線上測驗" ? (
						<AccordionItem
							title={
								!isPurchase && checkIsTryout(obj.data)
									? `(試用) ${name}`
									: name
							}
							className={styles.item2}
						>
							{creatTree3(obj.data)}
						</AccordionItem>
					) : (
						<div key={short_uuid()} className={styles.item2}>
							{
								//checkHasExam(obj.data) &&
								<div
									className={styles.test}
									onClick={() => sendTestList(obj.data)}
								>
									{"線上測驗"}
								</div>
							}
						</div>
					)}
				</Accordion>
			);
		});
		// if (volume.indexOf("國小") > -1 && subject.indexOf("自然")> -1) {
		// 	console.log(">>>>>")
		// 	for (var key in lastSection) {
		// 		let data = lastSection[key];
		// 		lastData = data;
		// 		console.log(lastData);
		// 	}
		// 	elem.push(
		// 		<Accordion key={keyCount} className={styles.tree2}>
		// 			<div
		// 				className={styles.item2}
		// 				onClick={() => sendTestList(lastData)}
		// 			>
		// 				{!isPurchase && checkIsTryout(lastData)
		// 					? `(試用) 線上測驗`
		// 					: "線上測驗"}
		// 			</div>
		// 		</Accordion>
		// 	);
		// }
		return elem;
	};

	const sendVideoList = useCallback(
		async (videoList, isPurchase) => {
			globalDispatch({ type: types.SET_VIDEO_SRC, videoSrc: null });
			//updateTime(productCode);

			globalDispatch({ type: types.SET_TEST_LIST, testList: [] });
			globalDispatch({
				type: types.SET_VIDEO_LIST,
				videoList,
				isTryOut: isPurchase ? false : true,
			});
		},
		[token, playTimeInfo, globalDispatch, productCode, updateTime]
	);

	const sendTestList = useCallback(
		async (section) => {
			globalDispatch({
				type: types.SET_VIDEO_SRC,
				videoSrc: null,
			});

			globalDispatch({
				type: types.SET_VIDEO_LIST,
				videoList: [],
			});
			globalDispatch({
				type: types.SET_TEST_LIST,
				testList: section,
				productName: productName,
			});
		},
		[globalDispatch]
	);

	const creatTree3 = useCallback(
		(section) => {
			let elem = [];
			let keyCount = 0;
			let lastData;
			for (var key in section) {
				let data = section[key];
				lastData = data;
				elem.push(
					<div
						key={keyCount}
						className={styles.tree3}
						onClick={() => sendVideoList(data, isPurchase)}
					>
						<div>{key}</div>
					</div>
				);
				keyCount++;
			}

			checkHasExam(section) &&
				elem.push(
					<div
						key={keyCount}
						className={styles.tree3}
						onClick={() => sendTestList(lastData)}
					>
						<div>{"線上測驗"}</div>
					</div>
				);
			return elem;
		},
		[isPurchase, sendVideoList, sendTestList]
	);

	const goBack = useCallback(() => {
		router.history.goBack();
	}, [router]);

	const goStudyNode = () => {
		globalDispatch({ type: types.SET_VIDEO_LIST, videoList: [] });
		globalDispatch({ type: types.SET_TEST_LIST, testList: [] });
	};

	const setTypeText = (type) => {
		let text = dataType[type].name
		console.log()
		if (window.innerWidth <= 1160) {
			return text.substr(0, 1)
		}
		return text;
	}

	const changeData = useCallback((version) => {
		dataTypeArr.map((info) => {
			if (info.version === version) {
				info.disabled = true;
			} else {
				info.disabled = false;
			}
			return info
		})

		setDataTypeArr(dataTypeArr)
		createSection(datalist, version)
	}, [dataTypeArr, createSection, datalist])

	const createDataTypeBtn = useCallback(() => {
		const btns = []
		dataTypeArr.forEach((info, index) => {
			const data = info
			const disabled = info.disabled
			btns.push(<Button key={index} className={classnames(styles.naniBtn, { [styles.disabled]: disabled })} onClick={() => changeData(data.version)} variant="secondary" disabled={disabled}>{setTypeText(data.type)}</Button>)
		})
		return btns
	}, [dataTypeArr, setTypeText, changeData])

	return (
		<div>
			{isloading ? (
				<LeftPannelLoading />
			) : (
				<div className={styles.leftPannelContent}>
					<div className={styles.btnsContent}>
						{
							createDataTypeBtn()
						}
						{/* <Button className={styles.naniBtn} onClick={() => changeData(dataType.nani.type)} variant="secondary" disabled>{setTypeText(dataType.nani.type)}</Button>
						<Button className={styles.naniBtn} onClick={() => changeData(dataType.knsh.type)} variant="secondary">{setTypeText(dataType.knsh.type)}</Button>
						<Button className={styles.naniBtn} onClick={() => changeData(dataType.hanlin.type)} variant="secondary">{setTypeText(dataType.hanlin.type)}</Button> */}
					</div>
					<div className={styles.leftPannelTitle}>
						{/* <div className={styles.back} onClick={() => goBack()}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6 12C6 12.3946 6.14656 12.7328 6.46223 13.0259L15.2334 21.6167C15.4814 21.8647 15.7971 22 16.1691 22C16.9132 22 17.5107 21.4138 17.5107 20.6584C17.5107 20.2864 17.3529 19.9594 17.1048 19.7001L9.2018 12L17.1048 4.29989C17.3529 4.04059 17.5107 3.70237 17.5107 3.3416C17.5107 2.58625 16.9132 2 16.1691 2C15.7971 2 15.4814 2.13529 15.2334 2.38331L6.46223 10.9628C6.14656 11.2672 6 11.6054 6 12Z"
							fill="#8b90a0"
						/>
					</svg>
					<div>返回上一頁</div>
				</div> */}

						<div className={styles.title}>{productName}</div>
						<div
							className={styles.content}
							onClick={() => goStudyNode()}
						>
							<div className={styles.vector}>
								<img src="./assets/img/Vector2.svg" alt="" />
							</div>
							<div className={styles.text}>學習報告</div>
						</div>
						<div className={styles.line}></div>
					</div>
					{section && treeType === "1"
						? creatTree(section)
						: creatTree2(section)}
				</div>
			)}
		</div>
	);
};

export default LeftPannel2;
