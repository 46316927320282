import React, { useState, useCallback, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Provider from './context';
import ClassView from 'components/ClassView';
import ProductView from 'components/ProductView';
import PurchaseListView from 'components/PurchaseListView';
import MyOrders from 'components/MyOrders';
import MyOrderDetails from 'components/MyOrderDetails';
import PurchaseFillInfos from 'components/PurchaseFillInfos';
import PurchaseSuccess from 'components/PurchaseSuccess';
import PurchaseFailed from 'components/PurchaseFailed';
import PurchaseRouter from 'components/PurchaseRouter';
import ServiceView from 'components/ServiceView';
import ReportView from 'components/ReportView';
import Order from 'components/Order';
import UserSearchInfoPage from 'components/UserSearchInfoPage';
import StudentsSearchInfoPage from 'components/StudentsSearchInfoPage';
import { AppService } from 'components/AppService';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getMainPage, getMainComponent } from "util/getMainPage"


const HookedBrowserRouter = ({ children }) => (
	<BrowserRouter basename={process.env.REACT_APP_BASENAME}>
		<Route>
			{(routeProps) => (
				<Provider router={routeProps}>
					{children}
				</Provider>
			)}
		</Route>
	</BrowserRouter>
);

const App = () => {
	useEffect(() => {
		if (process.env.REACT_APP_PROJECT === 'yunlinstudy') {
			document.title = '雲林縣onestudy';
		} else if (process.env.REACT_APP_PROJECT === 'chiayistudy') {
			document.title = '嘉義縣onestudy';
		} else {
			document.title = process.env.REACT_APP_PROJECT;
		}
	});

	return (
		<HookedBrowserRouter>
			<AppService />
			<Switch>
				<Route exact path={getMainPage()} component={getMainComponent()} />
				<Route exact path="/ClassView" component={ClassView} />
				<Route exact path="/ProductView" component={ProductView} />
				<Route exact path="/PurchaseListView" component={PurchaseListView} />
				<Route exact path="/MyOrders" component={MyOrders} />
				<Route exact path="/MyOrderDetails" component={MyOrderDetails} />
				<Route exact path="/PurchaseFillInfos" component={PurchaseFillInfos} />
				<Route exact path="/PurchaseSuccess" component={PurchaseSuccess} />
				<Route exact path="/PurchaseFailed" component={PurchaseFailed} />
				<Route exact path="/PurchaseRouter" component={PurchaseRouter} />
				<Route exact path="/ServiceView" component={ServiceView} />
				<Route exact path="/ReportView" component={ReportView} />
				<Route exact path="/Order" component={Order} />
				<Route exact path="/UserSearchInfoPage" component={UserSearchInfoPage} />
				<Route exact path="/StudentsSearchInfoPage" component={StudentsSearchInfoPage} />
				<Redirect path="*" to={{ pathname: getMainPage(), state: window.location.href }} />
			</Switch>
		</HookedBrowserRouter>
	);
};

export default App;

// {"code":"SUCCESS","jwt":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbXlhY2NvdW50Lm5hbmkuY29vbC8iLCJzdWIiOiJ1c2Vycy9lZTNjMTY1Zi04NjE1LTQzOWUtYWEyNC1mOGMyYjYxNWJkZDEiLCJmcm9tIjoiTU9FIiwidXNlcm5hbWUiOiJlZTNjMTY1Zi04NjE1LTQzOWUtYWEyNC1mOGMyYjYxNWJkZDEiLCJlbWFpbCI6InZjcjIuZmVycmV0QG1haWwuZWR1LnR3IiwidWlkIjoiNDkwNDIxZjBhYjdlMTFlZDgwNzMwZmFiYTQ3YjQ1YmIiLCJlbWFpbHZhbGlkIjp0cnVlLCJqdGkiOiI2MjkwNGNjZi1lM2JjLTQyZjUtODU3Ny02ODc5NDY5YzgzMWYiLCJpYXQiOjE2NzYyNzk0MzksImV4cCI6MTcwNzM4MzQzOX0.ylyupaChQrvzAzTRZ3sCRt0pNXqaFwZqUiCoykVnxRM"}
