import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from "./index.module.scss";
import { Accordion, AccordionItem } from "react-sanfona";
import { StoreTypes, useStore } from "context";
import * as types from "constants/actionTypes";
import { useOutsideAlerter } from "customHooks/outsideAlerter";
import { sortByKey, sortByDefault } from '../../util/listSort'
import { dataIdSort } from 'util/array'
import { checkProjectType } from "constants/projectsType";
import { productExpiredTime } from "util/productExpiredTime";

const ClassSelect = () => {
  const ref = useRef();
  const router = useStore(StoreTypes.router);
  const [{ product, isOneLiveMember, oneLiveMemberCloseTime }] = useStore(
    StoreTypes.user
  );
  const [{ educationInfo: data }, globalDispatch] = useStore(StoreTypes.global);
  const [items, setItems] = useState(false);
  const setClose = () => {
    globalDispatch({
      type: types.SET_CLASS_SELECT_PANNEL,
      isClassSeletPannel: false,
    });
  };
  useOutsideAlerter(ref, setClose);

  const setNaniStyle = () => {
    if (!checkProjectType() && router.location.pathname === "/NaniMainPage") {
      return "nanimainpage";
    } else if (
      !checkProjectType() &&
      router.location.pathname === "/ClassView"
    ) {
      return "naniclassview";
    }
  };

  useEffect(() => {
    for (var key in data) {
      for (var key2 in data[key]) {
        //console.log(key2);
        for (var key3 in data[key][key2]) {
          //console.log(data[key][key2][key3]["產品"]);
        }
      }
    }
  }, [data]);

  const setSubjectItem = (data) => {
    const yearGroup = [];
    data.forEach((info) => {
      const year = info.productcode.split("_")[0];
      if (!yearGroup[year]) {
        yearGroup[year] = [];
      }
      yearGroup[year].push(info);
    });
    setItems(yearGroup);
  };

  const createEducation = (education, data) => {
    const tags = [];

    if (education.includes("主題式")) {
      sortByKey(data).forEach((data) => {
        tags.push(
          <div
            key={data.title}
            className={styles.list}
            onClick={() => setSubjectItem(data.volumes)}
          >
            {`${data.title}`}
          </div>
        );
      });
    } else {
      sortByDefault(data).forEach((item) => {
        tags.push(
          <div
            key={item.title}
            className={styles.list}
            onClick={() => setSubjectItem(item.volumes)}
          >
            {`${item.title}`}
          </div>
        );
      })

    }
    return tags;
  };

  const getThemeIcon = (volume) => {
    switch (volume) {
      case "國語":
      case "國語文":
      case "國文":
        return "./assets/img/ch.png";
      case "數學":
        return "./assets/img/ma.png";
      case "英語":
      case "英文":
        return "./assets/img/en.png";
      case "自然":
        return "./assets/img/na.png";
      case "生活":
        return "./assets/img/live.png";
      case "社會":
        return "./assets/img/so.png";
      case "地理":
        return "./assets/img/ge.png";
      case "歷史":
        return "./assets/img/hi.png";
      case "公民與社會":
        return "./assets/img/ci.png";
      case "生物":
        return "./assets/img/na.png";
      case "物理":
        return "./assets/img/phy.png";
      case "化學":
        return "./assets/img/che.png";
      case "地球科學":
        return "./assets/img/live.png";
      default:
        return "./assets/img/ch.png";
    }
  };

  const getIcon = (data) => {
    switch (data) {
      case "國語":
      case "國文":
        return "./assets/img/ch.png";
      case "數學":
      case "數學A":
      case "數學B":
      case "數學甲上":
      case "數學甲下":
      case "數學乙上":
      case "數學乙下":
        return "./assets/img/ma.png";
      case "英語":
      case "英文":
        return "./assets/img/en.png";
      case "自然":
        return "./assets/img/na.png";
      case "生活":
        return "./assets/img/live.png";
      case "社會":
        return "./assets/img/so.png";
      case "地理":
        return "./assets/img/ge.png";
      case "歷史":
        return "./assets/img/hi.png";
      case "公民與社會":
        return "./assets/img/ci.png";
      case "生物":
        return "./assets/img/na.png";
      case "物理":
        return "./assets/img/phy.png";
      case "化學":
        return "./assets/img/che.png";
      case "地球科學":
        return "./assets/img/live.png";
      default:
        return "./assets/img/ch.png";
    }
  };

  const goSubjectPage = useCallback(
    (data) => {
      const productCode = data["productcode"];
      const volume = data["course"];
      const subject = data["subject"];
      const education = data['education']
      const expiredTime = productExpiredTime({ product, productCode, oneLiveMemberCloseTime, isOneLiveMember, education })
      const url = `/ClassView?productCode=${productCode}&volume=${volume}&subject=${subject}&expired=${expiredTime}`;

      globalDispatch({ type: types.SET_VIDEO_LIST, videoList: [] });
      globalDispatch({ type: types.SET_TEST_LIST, testList: [] });
      globalDispatch({
        type: types.SET_CLASS_SELECT_PANNEL,
        isClassSeletPannel: false,
      });
      globalDispatch({ type: types.SET_PRODUCT_INFO, productInfo: data });
      globalDispatch({ type: types.SET_GO_BACK_URL, goBackUrl: url });
      router.history.push(url);
    },
    [product, oneLiveMemberCloseTime, isOneLiveMember, globalDispatch, router.history]
  );

  const createEducationItem = (items) => {
    return items.map((data, index) => {
      const itemName = data["productcode"];
      const education = data["education"];
      const product = data["product"];
      const VolumeName = data["VolumeName"];
      const educationName = data["subject"]; //itemName.substr(itemName.length-2,itemName.length)
      return (
        <div
          key={index}
          className={styles.item}
          onClick={() => goSubjectPage(data)}
        >
          <img
            src={
              education === "主題式"
                ? getThemeIcon(VolumeName)
                : getIcon(educationName)
            }
            alt={educationName}
          />
          <div>{product.indexOf("首冊") > -1 ? "國語首冊" : educationName}</div>
        </div>
      );
    });
  };



  const createItem = (item) => {
    item.sort(dataIdSort);
    const lists = [];
    for (let key in item) {
      lists.push(
        <div key={key} className={styles.itemGroup}>
          <h3 className={styles.year}>{item[key][0].id.split("_")[0]}</h3>
          <div>{createEducationItem(item[key])}</div>
        </div>
      );
    }
    return lists;
  };

  const createEducationTags = (data) => {
    const lists = [];
    const elements = [];
    if (!data)
      return <p className={styles.warning}>請重整頁面已獲得最新資訊</p>;

    Object.keys(data).forEach((index) => {
      const trees = data[index];
      const { sort } = trees[Object.keys(trees)[0]][0];
      lists.push({ index, sort });
    });

    // 根據 sort 去排列國小學程/國中學程/...大區塊
    lists.sort((a, b) => {
      return parseInt(a.sort) - parseInt(b.sort)
    });

    lists.forEach((list) => {
      if (list.index === "app") return
      elements.push(
        <div className={styles.educationT}>
          {
            <div>
              <div className={styles.educationEText}>{list.index}</div>
              {createEducation(list.index, data[list.index])}
            </div>
          }
        </div>
      );
    });
    return elements;
  };

  return (
    <div ref={ref} className={`${styles.classSelect} ${setNaniStyle() === "nanimainpage" && styles.naniStyle}`}>
      <div className={styles.left}>
        <div className={styles.education}>{createEducationTags(data)}</div>
      </div>
      <div className={styles.centerLine}></div>
      <div className={styles.right}>
        <div className={styles.itemArea}>{items && createItem(items)}</div>
      </div>
    </div>
  );
};

export default ClassSelect;
