import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import * as actionTypes from "constants/actionTypes";
import DialogModule from "components/Dialogs/";
import classnames from "classnames";
import { useLogin } from "util/login";
import { checkProjectType } from "constants/projectsType";

const Login = ({ open, setOpen, lockClose, disableCloseBtn = true, historyPath }) => {
  const { sign, getUser, saveTokenData, renewOneLiveProducts } = useLogin();
  const [errorMessage, setErrorMessage] = useState("");
  const router = useStore(StoreTypes.router);

  const [{ token, userId, name, isLogin }, userDispatch] = useStore(
    StoreTypes.user
  );
  const [btnLoad, setBtnLoad] = useState(false);

  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const { username, password } = loginData;

  const [moeLoginUrl, setMoeLoginUrl] = useState(null);

  const signCheck = async () => {
    if (loginData.username.length === 0) {
      setErrorMessage("帳號未輸入");
      setBtnLoad(false);
    }
    if (loginData.password.length === 0) {
      setErrorMessage("密碼未輸入");
      setBtnLoad(false);
    }
    var isAccount = checkAccount(loginData.username);
    var isPass = checkPass(loginData.password);
    if (isAccount && isPass) {
      signClick();
    } else {
      setErrorMessage("帳號密碼錯誤字元");
      setBtnLoad(false);
    }
  }

  // const signClick = (async () => {
  // async function signClick(){
  const signClick = useCallback(async () => {
    setBtnLoad(true);
    let isSuccess = false;
    let nowToken = "";
    await sign(loginData)
      .then((response) => {
        userDispatch({
          type: actionTypes.SET_USER_INFO,
          token: response.token,
        });
        nowToken = response.token;
        isSuccess = true;
      })
      .catch((err) => {
        setErrorMessage(err);
        setBtnLoad(false);
      });

    await saveTokenData(nowToken);

    if (isSuccess) {
      const source = process.env.REACT_APP_PROJECT.includes('yunlin') ? 'yunlin' : 'general';
      await getUser({ token: nowToken, getExpiredProduct: true, source })
        .then((response) => {
          // userDispatch({ type: actionTypes.SET_USER_INFO, token: response.data.token, userId: username });
          // userDispatch({ type: actionTypes.SET_USER_INFO, name: response.name ,login:true});
          userDispatch({
            type: actionTypes.SET_USER_INFO,
            token: nowToken,
            name: response.name,
            isLogin: true,
            userId: response.username,
            product: response.product,
            isOneLiveMember: response.isOneLiveMember
              ? response.isOneLiveMember
              : false,
            oneLiveMemberCloseTime: response.oneLiveMemberCloseTime
              ? response.oneLiveMemberCloseTime
              : null,
          });
          return response
        })
        .then(async (getUserRes) => {
          if (getUserRes.isOneLiveMember) {
            const renewRes = await renewOneLiveProducts({ token: nowToken })
            userDispatch({
              type: actionTypes.SET_USER_INFO,
              product: renewRes.product,
            });
          }
          if (router.history.location.pathname === "/order") {
            const { isAdmin } = getUserRes;
            if (isAdmin) {
              setOpen(false);
            }
            return;
          }
          setOpen(false);
          setBtnLoad(false);
        })
        .catch((err) => {
          setErrorMessage(err);
          setBtnLoad(false);
        });
    }

    // }
  }, [getUser, loginData, setOpen, sign, userDispatch, router]);

  const closeModal = async () => {
    setOpen(false);
  }

  function checkAccount(message) {
    if (message.replace(/(^\s*)|(\s*$)/g, "").length == 0) {
      return false;
    }
    return true;
  }

  function checkPass(message) {
    if (message.replace(/(^\s*)|(\s*$)/g, "").length == 0) {
      return false;
    }
    return true;
  }

  useEffect(() => { // 教育雲登入
    const getMoeLoginUrl = async () => {
      try {
        const res = await fetch(
          "https://zib0bugold.execute-api.ap-northeast-1.amazonaws.com/prod/users/createOrgLoginForMoe",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              clientid: process.env.REACT_APP_CLIENT_ID,
              params: "",
            }),
          }
        );
        const result = await res.json();
        if (result.status === 'success') {
          setMoeLoginUrl(result.data.url)
        }
      } catch (error) {
        console.error(error);
      }
    };
    getMoeLoginUrl()
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "username") {
    }

    if (name === "password") {
    }

    setLoginData({ ...loginData, [name]: value });
  }

  const moeLogin = useCallback(() => {
    if (historyPath) {
      document.cookie = `redirect=/${historyPath}`;
    }
    window.open(moeLoginUrl, "_self")
  }, [historyPath, moeLoginUrl])

  const ListNameTextField = (
    <div className={styles.modalContent}>
      <div className={styles.imgcontainer}>
        {disableCloseBtn && (
          <span
            className={styles.close}
            title={"Close Modal"}
            onClick={() => closeModal()}
          >
            x
          </span>
        )}
        <img src={checkProjectType() ? './assets/img/Logo.svg' : './assets/img/nanistudy/nani_newlogo.svg'} alt="logo" />
      </div>
      <div className={styles.container}>
        <div
          className={classnames(styles.btnLogin, styles.btnLoginMoe, {
            [styles.disable]: moeLoginUrl,
          })}
          onClick={() => moeLogin()}
        //href={moeLoginUrl}
        >
          <img src={"./assets/img/yunlinstudy/moe.png"} alt="moelogin" />
          教育雲端帳號登入
        </div>
        <input
          className={styles.textInput}
          type={"text"}
          id={"username"}
          placeholder={"帳號"}
          required
          value={username}
          name="username"
          onChange={handleChange}
        />
        <input
          className={styles.textPassword}
          type={"password"}
          id={"password"}
          placeholder={"密碼"}
          required
          name="password"
          value={password}
          onChange={handleChange}
        />
        <p>{errorMessage}</p>
        <button
          onClick={() => signCheck()}
          id={"startBtn"}
          className={classnames(styles.btnLogin, {
            [styles.disabled]: btnLoad,
          })}
          disabled={btnLoad}
        >
          開始使用
        </button>
      </div>
      <div className={styles.imgcontainer}>
        <span>
          還沒有帳號
          <a href={"https://oneclub.oneclass.com.tw/signup/"}>
            點此加入OneClub
          </a>
        </span>
      </div>
    </div>
  );

  return (
    <div className={styles.loginArea}>
      <DialogModule
        open={open}
        setOpen={lockClose ? () => { } : setOpen}
        content={ListNameTextField}
      />
    </div>
  );
};
export default Login;