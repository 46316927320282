import { useReducer, useRef, useMemo, useEffect } from 'react';
export const useEnhanceReducer = (reducer, initialState, enableLogger = process.env.REACT_APP_LOG === 'true' || false) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const prevState = useRef(initialState);
    const currentAction = useRef();
    const dispatchWithLogger = useMemo(() => {
        return action => {
            currentAction.current = action;

            return dispatch(action);
        }
    }, [dispatch]);

    const enhanceDispatch = enableLogger ? dispatchWithLogger : dispatch;

    useEffect(() => {
        if (state !== prevState.current && enableLogger) {
            console.groupCollapsed(`Action Type: ${currentAction.current.type}`);
            console.log("%c Previous", "color: #9E9E9E; font-weight: 700;", prevState.current);
            console.log("%c Action  ", "color: #00A7F7; font-weight: 700;", currentAction.current);
            console.log("%c Current ", "color: #47B04B; font-weight: 700;", state);
            console.groupEnd();
        }
        prevState.current = { ...prevState.current, ...state };
    }, [enableLogger, state]);

    return [state, enhanceDispatch];
}
