import * as React from 'react';
import { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SelectOrderCode from './SelectOrderCode'
import SearchOrder from './SearchOrder'
import SearchAllOrder from './SearchAllOrder'
import OrderLogin from './OrderLogin'
import CreateDiscount from './CreateDiscount';
import BatchCreateOrderCode from './BatchCreateOrderCode';
import SelectCustomOrderCode from './SelectCustomOrderCode';

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const Order = () => {
	const keyProductCode = "productcode";
	const keyProductName = "product";
	const [value, setValue] = useState(0);
	const [naniProducts, setNaniProducts] = useState(null);
	const [open, setOpen] = useState(true);

	useEffect(() => {
		const curTime = new Date();
		var year = curTime.getFullYear();
		if (curTime.getMonth() < 8) year -= 1;
		year = year - 1911;
		const products = [];

		// proceed calling API orderPage
		fetch(`${process.env.REACT_APP_API_DOMAIN}/apis/getProducts`, {
			method: "POST",
			body: JSON.stringify({ year: year.toString() }),
			headers: { "Content-type": "application/json; charset=UTF-8" }
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (response) {
				if (response.status == "success") {
					var productList = response.data;
					var codeList = [];
					var tmp = {};
					for (var i = 0; i < productList.length; i++) {
						var product = productList[i];
						var code = product[keyProductCode];
						var name = product[keyProductName];
						if (tmp.hasOwnProperty(code) == false) {
							tmp[code] = name;
							codeList.push(code);
						}
					}
					// sort by keyProductCode
					codeList.sort(function (a, b) {
						const strParams1 = a.split('_');
						const strParams2 = b.split('_');
						// check year
						for (var i = 0; i < 3; i++) {
							const value1 = parseInt(strParams1[i]);
							const value2 = parseInt(strParams2[i]);
							if (value1 == value2) continue;
							return value1 < value2 ? -1 : 1;
						}
						// check school
						if (strParams1[3] != strParams2[3]) {
							var school1 = 0;
							var school2 = 0;
							switch (strParams1[3].toUpperCase()) {
								case 'E': school1 = 1; break;
								case 'J': school1 = 2; break;
								case 'H': school1 = 3; break;
							}
							switch (strParams2[3].toUpperCase()) {
								case 'E': school2 = 1; break;
								case 'J': school2 = 2; break;
								case 'H': school2 = 3; break;
							}
							return school1 < school2 ? -1 : 1;
						}
						// check class
						if (strParams1[4] != strParams2[4]) {
							var class1 = 0;
							var class2 = 0;
							switch (strParams1[4].toUpperCase()) {
								case 'C': class1 = 1; break;
								case 'E': class1 = 2; break;
								case 'M': class1 = 3; break;
								case 'S': class1 = 4; break;
								case 'N': class1 = 5; break;
								case 'L': class1 = 6; break;
								default: class1 = 7; break;
							}
							switch (strParams2[4].toUpperCase()) {
								case 'C': class2 = 1; break;
								case 'E': class2 = 2; break;
								case 'M': class2 = 3; break;
								case 'S': class2 = 4; break;
								case 'N': class2 = 5; break;
								case 'L': class2 = 6; break;
								default: class2 = 7; break;
							}
							return class1 < class2 ? -1 : 1;
						}
						return 0;
					});
					//codeList.reverse();
					for (var i = 0; i < codeList.length; i++) {
						var code = codeList[i];
						products.push({ code, product: tmp[code] });
					}

					console.log("products", products);
					setNaniProducts(products)
				}
				else {
					console.log(`getProducts got ${response.error}`);
				}
			})
			.catch(function (err) {
				console.log(`getProducts got ${err}`);
			});
	}, [setNaniProducts])

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%', padding: 20 }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', width: '100%' }}>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{ width: '80%' }}>
					<Tab label="兌換碼產出" {...a11yProps(0)} />
					<Tab label="兌換狀況查詢" {...a11yProps(1)} />
					<Tab label="折扣碼產出" {...a11yProps(2)} />
					<Tab label="兌換碼批次開通" {...a11yProps(3)} />
					<Tab label="固定兌換碼" {...a11yProps(4)} />
					<Tab label="查詢訂單" {...a11yProps(5)} />
				</Tabs>
				<OrderLogin open={open} setOpen={setOpen} />
			</Box>
			<TabPanel value={value} index={0} >
				<SelectOrderCode naniProducts={naniProducts} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<SearchOrder naniProducts={naniProducts} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<CreateDiscount naniProducts={naniProducts} />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<BatchCreateOrderCode naniProducts={naniProducts} />
			</TabPanel>
			<TabPanel value={value} index={4}>
				<SelectCustomOrderCode naniProducts={naniProducts} />
			</TabPanel>
			<TabPanel value={value} index={5}>
				<SearchAllOrder naniProducts={naniProducts} />
			</TabPanel>
		</Box>
	);
}

export default Order;