import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import * as actionTypes from "constants/actionTypes";
import ChiayiClassSelect from "components/ChiayiMainPage/ClassSelect";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classnames from "classnames";
import { useLogin } from "util/login";
// import BigLearnerCampaign from "components/BigLearnerCampaign";
import { translateGradeToChinese } from "util/translateGradetToChinese"

const Header = () => {
	const router = useStore(StoreTypes.router);
	const [
		{ isClassSeletPannel, nowHeadSelectListName },
		globalDispatch,
	] = useStore(StoreTypes.global);
	const [{ userId, name, isLogin }, userDispatch] = useStore(StoreTypes.user);
	const [logoutDropdownOpen, setLogoutDropdownOpen] = useState(false);
	const { logoutNaniOneClass, getToken } = useLogin();

	const classSelect = useCallback(() => {
		if (!isClassSeletPannel) {
			globalDispatch({
				type: actionTypes.SET_CLASS_SELECT_PANNEL,
				isClassSeletPannel: true,
			});
		} else {
			globalDispatch({
				type: actionTypes.SET_CLASS_SELECT_PANNEL,
				isClassSeletPannel: false,
			});
		}
	}, [globalDispatch, isClassSeletPannel]);

	const goMainView = () => {
		router.history.push('/ChiayiMainPage');
	};

	// 教育雲成功登入後會得到 token，再把這個 token 打這個 api
	useEffect(() => {
		if(userId.length > 0) return;
		fetch(`https://zib0bugold.execute-api.ap-northeast-1.amazonaws.com/prod/users/GetUserProfile`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': getToken(),
			}
		})
			.then(res => res.json())
			.then(res => {
				// console.log("getUserProfile", res)
				// if (res.status !== "success" && !getToken()) {
				// 	// alert("請登入教育雲端帳號");
				// 	// router.history.push('/YunlinMainPage');
				// 	return;
				// };
				const education = translateGradeToChinese(res.data.grade);
				// if (res.data.grade > 9) {
				// 	education = "高中";
				// } else if (res.data.grade < 7) {
				// 	education = "國小";
				// } else {
				// 	education = "國中";
				// }

				userDispatch({
					type: actionTypes.SET_USER_INFO,
					userId: res.data.username,
					token: getToken(),
					name: res.data.name,
					login: true,
					isLogin: true,
					isTeacher: res.data.isTeacher,
					education,
					// product: response.product,
				});
				fetch(`${process.env.REACT_APP_API_DOMAIN}/apis/updateUser`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': getToken(),
					},
					body:JSON.stringify(res.data)
				}).then(res => res.json())
				.then(res => console.log('save user to DB'))
			})
			.catch(err => console.log(err))
	}, [userId, userDispatch])

	const logout = () => {
		if (getToken()) {
			userDispatch({
				type: actionTypes.SET_USER_INFO,
				name: "",
				isLogin: false,
				token: "",
				userId: "",
				product: null,
				isTeacher: null,
				education: null
			});
			logoutNaniOneClass();
			router.history.push('/ChiayiMainPage');
		}
	}

	// 大勤學家活動頁面相關
	// const [campaignOpen, setCampaignOpen] = useState(false);

	return (
		<>
		<div
			className={styles.chiayiHead}
		>
			<Col></Col>
			<Col>
				<img
					className={styles.logoBox}
					onClick={() => goMainView()}
					src={`./assets/img/chiayistudy/Logo.svg`}
					alt="onestudy"
				/>
			</Col>
			<Col style={{height: '100%',display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
				<div
					className={classnames(styles.selectSubject, {
						[styles.show]: isClassSeletPannel,
					})}
					onClick={() => classSelect()}
				>
					<div className={styles.selectButton}>選擇科目</div>
					<img
						className={styles.logo}
						// eslint-disable-next-line react/style-prop-object

						src="./assets/img/yunlinstudy/selectButton.png"
						alt="chiayistudy"
					/>
				</div>
			</Col>
			<Col></Col>
			<Col style={{display: 'flex', minWidth: '300px'}}>
				{/* {isLogin && <button type="button" className={styles.bigLearnerBtn} style={{background: 'url("./assets/img/yunlinstudy/bigLearnerCampaignBtn.png")'}} alt="大勤學家活動" onClick={() => setCampaignOpen(true)} />} */}
				{
				name ? (<div
					className={classnames(styles.login, {
						[styles.show]: isClassSeletPannel,
					})}
					onClick={() => setLogoutDropdownOpen(!logoutDropdownOpen)}
				>
					<img src="./assets/img/yunlinstudy/loginAvatar.png" alt="chiayistudy"></img>
					<div className={styles.selectButton}>{name}</div>
					<img
						className={styles.logo}
						// eslint-disable-next-line react/style-prop-object

						src="./assets/img/yunlinstudy/selectButton.png"
						alt="chiayistudy"
					/>
					<div className={classnames(styles.logoutDropdown, {
						[styles.show]: logoutDropdownOpen,
					})} onClick={logout}>
						<div>登出</div>
					</div>
				</div>) : (
					<div
					className={styles.login}
					onClick={() => router.history.push('/ChiayiMainPage')}
					title="回首頁登入"
				>
					<img src="./assets/img/yunlinstudy/loginAvatar.png" alt="chiayistudy"></img>
					<div className={styles.selectButton}>未登入</div>
				</div>
				)
				}
			</Col>
			<Col className={styles.versionNum}>
				<div className={styles.versionNumText}>{`v${process.env.REACT_APP_VERSION}`}</div>
			</Col>
			{isClassSeletPannel && <ChiayiClassSelect />}
		</div>
		{router.location.pathname === "/ChiayiMainPage" && <div className={styles.launchTimetable}>
		<div className={styles.launchTimetableText}>
			<svg width="42" height="20" viewBox="0 0 42 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width="42" height="20" rx="4" fill="#EC7963" />
				<path d="M7.36757 15H9.18757V11.139C9.18757 10.034 9.04457 8.825 8.95357 7.785H9.01857L9.99357 9.852L12.7756 15H14.7256V5.38H12.9056V9.215C12.9056 10.307 13.0486 11.581 13.1526 12.582H13.0876L12.1256 10.502L9.33057 5.38H7.36757V15ZM17.1049 15H23.1499V13.388H19.0289V10.814H22.4089V9.202H19.0289V6.979H23.0069V5.38H17.1049V15ZM26.0129 15H28.3659L29.4189 10.242C29.5749 9.488 29.7049 8.747 29.8349 8.019H29.8869C30.0039 8.747 30.1469 9.488 30.3029 10.242L31.3819 15H33.7739L35.5549 5.38H33.7219L32.9679 10.073C32.8249 11.074 32.6819 12.101 32.5389 13.141H32.4739C32.2659 12.101 32.0709 11.061 31.8629 10.073L30.7449 5.38H29.1069L27.9889 10.073C27.7809 11.074 27.5729 12.114 27.3779 13.141H27.3259C27.1829 12.114 27.0269 11.087 26.8839 10.073L26.1169 5.38H24.1539L26.0129 15Z" fill="white" />
			</svg>
			<span>113上學年度</span>
			<span>配合學校進度，影音及測驗資源將陸續上架！</span>
		</div>
		<img src="./assets/img/launchTimetable.jpg" alt="上架時程" />
		<a className={styles.timetableLink} href="https://tutor.oneclass.com.tw/onestudyupdate_oc/" target="_blank" rel="noreferrer">查看時間表</a>
		<div className={styles.closeBtn} onClick={(e) => e.target.parentNode.style.display = 'none'}></div>
	</div>}
	{/* {isLogin && <BigLearnerCampaign campaignOpen={campaignOpen} setCampaignOpen={setCampaignOpen} userId={userId} token={getToken()} />} */}
	</>
	);
};
export default Header;
