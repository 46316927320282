import React, { createContext, useContext } from 'react';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import useDBReducer from 'reducers/dbReducer';
import useGlobalReducer from 'reducers/globalReducer';
import useUserReducer from 'reducers/userReducer';
const Context = createContext();

export const StoreTypes = {
  global: 'global',
  user: 'user',
  db: 'db',
  router: 'router'
};

export const useStore = store => {
  const reducers = useContext(Context);
  if (!store) return reducers;
  if (!Object.keys(StoreTypes).includes(store)) {
    throw new Error(`Store doesn't contain ${store}`);
  }
  return reducers[store];
};

const Provider = ({ children, router }) => {
  const reducers = {
    [StoreTypes.global]: useGlobalReducer(),
    [StoreTypes.user]: useUserReducer(),
    [StoreTypes.db]: useDBReducer(),
    [StoreTypes.router]: router
  };

  return (
    <DndProvider options={HTML5toTouch}>
      <Context.Provider value={{ ...reducers }}>{children}</Context.Provider>
    </DndProvider>
  );
};

export default Provider;
