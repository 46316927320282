import React, { useEffect, useCallback, useState, useRef } from 'react';
import styles from './index.module.scss';

const VideoProgress = ({ progress }) => {
    const progressRef = useRef();

    useEffect(() => {
        if (!progressRef.current) return;
        if(progress){
            let percent=parseInt(progress.time)/parseInt(progress.duration)*100
            if(percent>=100){
                percent=100
            }
            progressRef.current.style.width=percent+"%"
        }else{
            progressRef.current.style.width="0px"
        }
    },[progress])

    return (
        <div ref={progressRef} className={styles.progress}>

        </div>
    )
}

export default VideoProgress;