import React from "react";
import styles from "./index.module.scss";
import { Skeleton, Space, Divider, Switch, Form, Radio } from "antd";
import "antd/dist/antd.css";

const StudyNodeLoading = ({ message = "" }) => {
	return (
		<div className={styles.loadWrapper}>
			<Skeleton.Button
				style={{ width: 120, height: 25, marginBottom: 35 }}
				active={true}
			/>
			<Skeleton.Button
				style={{ width: "100%", height: 100, marginBottom: 50 }}
				active={true}
			/>
			<div className={styles.recodeList}>
				<div className={styles.list}>
					<div className={styles.left}>
						<Skeleton.Button
							style={{ width: 300, height: 25 }}
							active={true}
						/>
					</div>
					<div className={styles.right}>
						<Skeleton.Button
							style={{ width: 80, height: 40 }}
							active={true}
						/>
					</div>
				</div>
			</div>
			<div className={styles.recodeList}>
				<div className={styles.list}>
					<div className={styles.left}>
						<Skeleton.Button
							style={{ width: 300, height: 25 }}
							active={true}
						/>
					</div>
					<div className={styles.right}>
						<Skeleton.Button
							style={{ width: 80, height: 40 }}
							active={true}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StudyNodeLoading;
