import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import Header from "components/Header";
import Footer from "components/Footer";
import ParamsStorer from "../../util/paramsStorer";
import { Beforeunload } from 'components/Beforeunload';
import { useStore, StoreTypes } from "context";

const PurchaseFailed = () => {
  const router = useStore(StoreTypes.router);

  //===========================================================================
  useEffect(() => {
    componentDidMount();
    return componentWillUnmount;
  }, []);
  //===========================================================================

  function componentDidMount() {
    window.addEventListener('resize', onResize);
    onResize();
  }

  function componentWillUnmount() {
    window.removeEventListener('resize', onResize);
  }

  function onResize() {
    const deviceWidth = window.innerWidth;
    const deviceHeight = window.innerHeight;
    const headerHeight = 80;
    const footerHeight = 150;
    const infoWidth = 300;
    const infoHeight = 300;
    var mainAreaHeight = deviceHeight - headerHeight - footerHeight;
    if (mainAreaHeight < infoHeight) mainAreaHeight = infoHeight;

    const uiMainArea = document.getElementById('mainArea');
    if (uiMainArea) {
      uiMainArea.style.height = `${mainAreaHeight}px`;
    }

    const uiInfos = document.getElementById('infos');
    if (uiInfos) {
      uiInfos.style.margin = `0 ${(deviceWidth - infoWidth) / 2}px`
      uiInfos.style.padding = `${(mainAreaHeight - infoHeight) / 2}px 0`;
    }
  }

  const gotoMyOrders = () => {
    router.history.push('/MyOrders');
  }

  return (
    <Beforeunload>
      <div className={styles.PurchaseFailed}>
        <Header />
        <div id="mainArea" className={styles.mainArea}>
          <div id="infos">
            <div>
              <img src="./assets/img/payFailed.svg" />
            </div>
            <div style={{ color: '#F44336' }}>付款失敗</div>
            <br></br>
            <div>
              <div style={{ textAlign: "left" }}>唉呀，您的付款尚未完成付款成功前，您可先使用試讀課程，期待您再回來唷！</div>
            </div>
            <br></br>
            <button className={styles.btnBack} onClick={() => gotoMyOrders()}>返回我的訂單</button>
          </div>
        </div>
        <Footer />
      </div>
    </Beforeunload>
  )
}

export default PurchaseFailed;