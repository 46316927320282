import * as React from 'react';
import { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import OrderLogin from './OrderLogin';
import VideoExamSearch from './VideoExamSearch';

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const UserSearchInfoPage = () => {
	const keyProductCode = "productcode";
	const keyProductName = "product";
	const [value, setValue] = useState(0);
	const [naniProducts, setNaniProducts] = useState(null);
	const [open, setOpen] = useState(true);

	useEffect(() => {
		document.cookie = "redirect=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
	}, [setNaniProducts])

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%', padding: 20 }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', width: '100%' }}>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{ width: '80%' }}>
					<Tab label="紀錄查詢" {...a11yProps(0)} />
				</Tabs>
				<OrderLogin open={open} setOpen={setOpen} checkCustomAccount={false} />
			</Box>
			<TabPanel value={value} index={0} >
				<VideoExamSearch naniProducts={naniProducts} />
			</TabPanel>
		</Box>
	);
}

export default UserSearchInfoPage;