import React from "react";
import styles from "./index.module.scss";
import { Skeleton, Space, Divider, Switch, Form, Radio } from "antd";
import "antd/dist/antd.css";

const LeftPannelLoading = ({ message = "" }) => {
	return (
		<div className={styles.loadWrapper}>
			<div>
				<Skeleton.Button
					style={{ width: 200, height: 40, marginBottom: 30 }}
					active={true}
				/>
			</div>
			<div>
				<Skeleton.Button
					style={{ width: 120, height: 25, marginBottom: 20 }}
					active={true}
				/>
			</div>
			<div>
				<Skeleton.Button
					style={{ width: 120, height: 25, marginBottom: 20 }}
					active={true}
				/>
			</div>
			<div>
				<Skeleton.Button
					style={{ width: 120, height: 25, marginBottom: 20 }}
					active={true}
				/>
			</div>
			<div>
				<Skeleton.Button
					style={{ width: 120, height: 25, marginBottom: 20 }}
					active={true}
				/>
			</div>
			<div>
				<Skeleton.Button
					style={{ width: 120, height: 25, marginBottom: 20 }}
					active={true}
				/>
			</div>
			<div>
				<Skeleton.Button
					style={{ width: 120, height: 25, marginBottom: 20 }}
					active={true}
				/>
			</div>
		</div>
	);
};

export default LeftPannelLoading;
