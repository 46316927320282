import React, { useState, useCallback, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import * as actionTypes from 'constants/actionTypes';
import { useLogin } from "util/login"
import { useOutsideAlerter } from "customHooks/outsideAlerter";
import Login from "components/Login";

const OrderLogin = ({ checkCustomAccount = true }) => {
	const ref = useRef();
	const router = useStore(StoreTypes.router);
	const [{ token, userId, name, isLogin, isAdmin }, userDispatch] = useStore(StoreTypes.user);
	const [isUserList, setIsUserList] = useState(false);
	const [
		{ isClassSeletPannel, nowHeadSelectListName },
		globalDispatch,
	] = useStore(StoreTypes.global);
	const { logoutNaniOneClass, getToken } = useLogin();
	const [isExchange, setIsExchange] = useState(false);
	const [open, setOpen] = useState(true);

	const loginBtn = useCallback(() => {
		if (isLogin) {
			userDispatch({
				type: actionTypes.SET_USER_INFO,
				name: "",
				isLogin: false,
				token: "",
				userId: "",
				product: null,
				schoolName: null,
				isAdmin: null,
			});
			logoutNaniOneClass();
			setOpen(true);
		} else {
			setOpen(true);
		}
	}, [name, userDispatch, logoutNaniOneClass, open, setOpen, isLogin]);

	useEffect(() => {
		(async () => {
			if (getToken()) {
				const source = process.env.REACT_APP_PROJECT.includes('yunlin') ? 'yunlin' : 'general';
				const res = await fetch(
					`${process.env.REACT_APP_API_DOMAIN}/apis/GetUser?source=${source}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: getToken(),
						},
					}
				).then(function (response) {
					return response.json();
				})

				if (res.status == "success") {
					const { isAdmin } = res.data;
					if (checkCustomAccount && !isAdmin) {
						loginBtn();
					} else {
						setOpen(false)
					}
				}
			}
		})()
	}, [isAdmin])

	const setClose = () => {
		setIsUserList(false);
	}

	useOutsideAlerter(ref, setClose);

	return (
		<div ref={ref} className={styles.userInfoArea}>
			<Login open={open} setOpen={setOpen} lockClose={true} disableCloseBtn={false} />
			{isLogin ? (
				<div className={styles.userInfoContent}>
					<div className={styles.userIcon}>
						<div className={styles.nowListText}>
							<div>{nowHeadSelectListName}</div>
						</div>
						<div
							className={styles.userBtn}
							onClick={() => loginBtn()}
						>
							{name}
						</div>
					</div>
				</div>
			) : (
				<div className={styles.userInfoContent}>
					<div className={styles.userIcon}>
						<div className={styles.nowListText}>
							<div>{nowHeadSelectListName}</div>
						</div>
						<div
							className={styles.userBtn}
							onClick={() => loginBtn()}
						>
							{'登入'}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
export default OrderLogin;
