export const PRODUCT_MESSAGES = {
    NANI: {
        "國小主題式課程互動-國語":{
            A: "提供9項互動學習",
            B: "能解析注音、筆順",
            C: "提供唐詩、弟子規、成語知識"
        },
        "國小主題式課程互動-三視圖":{
            A: "能清楚立體圖形的三視圖",
            B: "解析上、前、後、左、右視圖",
            C: "能用三視圖來描述立體圖形"
        },
        "國小主題式課程互動-自然":{
            A: "提供36項互動學習",
            B: "包含動植物常識",
            C: "提供地球科學相關知識"
        },
        "國小主題式課程互動-生活":{
            A: "提供10項互動學習",
            B: "了解生活習慣",
            C: "解析生活中的自然現象"
        },
        國小主題式課程數位閱讀1: {
            A: "精選6大主題文章",
            B: "標示段落重點說明段意",
            C: "搭配Pirls閱讀理解"
        },
        國小主題式課程數位閱讀2: {
            A: "精選6大主題文章",
            B: "標示段落重點說明段意",
            C: "搭配Pirls閱讀理解"
        },
        國小主題式課程數位閱讀3: {
            A: "精選5大主題文章",
            B: "標示段落重點說明段意",
            C: "搭配Pirls閱讀理解"
        },
        國小主題式課程數位閱讀4: {
            A: "精選5大主題文章",
            B: "標示段落重點說明段意",
            C: "搭配Pirls閱讀理解"
        },
        國小主題式課程數位閱讀5: {
            A: "精選5大主題文章",
            B: "標示段落重點說明段意",
            C: "搭配Pirls閱讀理解"
        },
        國小主題式課程數位閱讀6: {
            A: "精選5大主題文章",
            B: "標示段落重點說明段意",
            C: "搭配Pirls閱讀理解"
        },
        國小主題式課程社會動畫3: {
            A: "精選三年級課程知識點",
            B: "12份圖像式課程重點",
            C: "附有12份圖像式學習單"
        },
        國小主題式課程社會動畫4: {
            A: "精選四年級課程知識點",
            B: "12份圖像式課程重點",
            C: "附有12份圖像式學習單"
        },
        國小主題式課程社會動畫5: {
            A: "精選五年級課程知識點",
            B: "附12份學霸筆記複習重點 ",
            C: "搭配12份素養學習單"
        },
        國小主題式課程社會動畫6: {
            A: "精選六年級課程知識點",
            B: "附10份學霸筆記複習重點",
            C: "搭配10份素養學習單"
        },
        國中主題式課程國文閱讀: {
            A: "六大文體分類",
            B: "文章動畫搭配作家影片",
            C: "含提問單便於自學"
        },
        "國中主題式課程互動-三視圖":{
            A: "能清楚立體圖形的三視圖",
            B: "解析上、前、後、左、右視圖",
            C: "能用三視圖來描述立體圖形"
        },
        國中主題式課程FUN文法: {
            A: "國中必學文法主題分類",
            B: "中外師講解及中英字幕",
            C: "線上試題檢測學習成效"
        },
        國中主題式課程地科補救: {
            A: "多個主題詳細概念說明",
            B: "搭配測驗即時檢測",
            C: "課前預習、課後加強"
        },
        國中主題式課程生物補救: {
            A: "九大主題詳細概念說明",
            B: "搭配測驗即時檢測",
            C: "課前預習、課後加強"
        },
        國中主題式課程國文寫型: {
            A: "四大常見文體",
            B: "搭配詳盡範例說明",
            C: ""
        },
        國中主題式課程語文常識: {
            A: "詳述標點符號使用時機",
            B: "搭配測驗即時檢測",
            C: ""
        },
        國中主題式課程地理概念: {
            A: "依區域分類",
            B: "動畫講解重要地理概念",
            C: "搭配測驗即時檢測"
        },
        國中主題式課程歷史概念: {
            A: "依時期分類",
            B: "動畫講解重點歷史事件",
            C: "搭配測驗即時檢測"
        },
        國中主題式課程公民概念: {
            A: "精選四大主題",
            B: "動畫講解重要公民概念",
            C: "搭配測驗即時檢測",
        },
        國中主題式課程數學補救7: {
            A: "九大主題詳細概念說明",
            B: "搭配測驗即時檢測",
            C: "課前預習、課後加強",
        },
        國中主題式課程數學補救8: {
            A: "八大主題詳細概念說明",
            B: "搭配測驗即時檢測",
            C: "課前預習、課後加強",
        },
        國中主題式課程理化補救8: {
            A: "多個主題詳細概念說明",
            B: "搭配測驗即時檢測",
            C: "課前預習、課後加強",
        },
        國中主題式課程數學補救9: {
            A: "六大主題詳細概念說明",
            B: "搭配測驗即時檢測",
            C: "課前預習、課後加強",
        },
        國中主題式課程理化補救9: {
            A: "六大主題詳細概念說明",
            B: "搭配測驗即時檢測",
            C: "課前預習、課後加強",
        },
        高中主題式課程地科測驗: {
            A: "主題式學測解題 + 練習",
            B: "不分版本皆適用",
            C: "即時回饋測驗結果",
        },
        高中主題式課程生物測驗: {
            A: "主題式學測解題 + 練習",
            B: "不分版本皆適用",
            C: "即時回饋測驗結果",
        },
        高中主題式課程地理測驗: {
            A: "主題式學測解題 + 練習",
            B: "不分版本皆適用",
            C: "即時回饋測驗結果",
        },
        高中主題式課程歷史測驗: {
            A: "主題式學測解題 + 練習",
            B: "不分版本皆適用",
            C: "即時回饋測驗結果",
        },
        高中主題式課程化學測驗: {
            A: "主題式學測解題 + 練習",
            B: "不分版本皆適用",
            C: "即時回饋測驗結果",
        },
        高中主題式課程數學測驗: {
            A: "主題式學測解題 + 練習",
            B: "不分版本皆適用",
            C: "即時回饋測驗結果",
        },
        高中主題式課程物理測驗: {
            A: "主題式學測解題 + 練習",
            B: "不分版本皆適用",
            C: "即時回饋測驗結果",
        },
        高中主題式課程公社測驗: {
            A: "主題式學測解題 + 練習",
            B: "不分版本皆適用",
            C: "即時回饋測驗結果",
        },
        主題式教師專區地理MR: {
            A: "包含地理精選主題題材",
            B: "主題式3D影音數位課程",
            C: "擴增實境教學清晰明確",
        },
        主題式教師專區數學雙語: {
            A: "中英對照，理解最輕鬆",
            B: "備有教案與雙語學習單",
            C: "隨點即播有聲音檔",
        },
        主題式教師專區高中數學雙語: {
            A: "中英對照，理解最輕鬆",
            B: "備有教案與雙語學習單",
            C: "隨點即播有聲音檔",
        },
        主題式教師專區地科MR: {
            A: "主題涵蓋月相及日月食",
            B: "精緻MR教學影片",
            C: "搭配精美教案學習單",
        },
        主題式教師專區高中地科MR: {
            A: "天文及聖嬰等題材",
            B: "首創沉浸式教學教材",
            C: "擴增MR實境細膩講解",
        },
        主題式教師專區地科探究: {
            A: "趣味操作課程選擇多",
            B: "備有教材與學習單",
            C: "適合探究及多元選修",
        },
        主題式教師專區生物MR: {
            A: "精選五大主題",
            B: "精緻MR教學影片",
            C: "搭配精美教案學習單",
        },
        主題式教師專區生物雙語: {
            A: "生物全實驗雙語教材",
            B: "雙語設計切合實際需求",
            C: "備有教案與雙語學習單",
        },
        主題式教師專區生物探究: {
            A: "收錄多種課程供選擇",
            B: "備有教材與學習單",
            C: "適合探究及多元選修",
        },
        主題式教師專區健康雙語: {
            A: "雙語單字附音檔",
            B: "跨版本雙語健康單字庫",
            C: "搭配教案示例和學習單",
        },
        主題式教師專區體育雙語: {
            A: "雙語單字附音檔",
            B: "跨版本雙語體育單字庫",
            C: "搭配教案示例和學習單",
        },
        主題式教師專區綜合雙語: {
            A: "雙語單字附音檔",
            B: "跨版本雙語綜合單字庫",
            C: "搭配教案示例和學習單",
        },
        主題式教師專區童軍雙語: {
            A: "雙語單字附音檔",
            B: "跨版本雙語童軍單字庫",
            C: "搭配教案示例和學習單",
        },
        主題式教師專區化學雙語: {
            A: "課綱必修實驗雙語教材",
            B: "多元選修課程設計適用",
            C: "備有教案與雙語學習單",
        },
        主題式教師專區化學探究: {
            A: "生活教材 & 課本延伸",
            B: "實驗設計 & 學習單齊全",
            C: "探究實作多元選修適用",
        },
        主題式教師專區遊戲程式: {
            A: "精選十大好玩主題",
            B: "螢幕操作影片說明詳實",
            C: "玩遊戲學Scratch",
        },
        主題式教師專區物理雙語: {
            A: "必修示範實驗雙語教材",
            B: "提供教案與雙語學習單",
            C: "教學素材備有英文音檔",
        },
        主題式教師專區物理探究: {
            A: "趣味活動融入生活情境",
            B: "實驗與學習單設計完善",
            C: "探究實作多元選修適用",
        },
        主題式教師專區律動學習1: {
            A: "精選6大主題",
            B: "主題有律動兒歌及動畫",
            C: "附學習單強化閱讀力",
        },
        主題式教師專區律動學習2: {
            A: "精選7大主題",
            B: "主題有律動兒歌及動畫",
            C: "附學習單強化閱讀力",
        },
        主題式教師專區自然雙語: {
            A: "雙語教學附音檔",
            B: "搭配精美教案學習單",
            C: "附贈中英詞彙表",
        },
        國小學程一上: {
            A: "3 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國小學程二上: {
            A: "3 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國小學程三上: {
            A: "4 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國小學程四上: {
            A: "4 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國小學程五上: {
            A: "4 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國小學程六上: {
            A: "4 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國中學程七上: {
            A: "5 大科目", 
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國中學程八上: {
            A: "5 大科目", 
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國中學程九上: {
            A: "5 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國小學程一下: {
            A: "3 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國小學程二下: {
            A: "3 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國小學程三下: {
            A: "4 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國小學程四下: {
            A: "4 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國小學程五下: {
            A: "4 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國小學程六下: {
            A: "4 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國中學程七下: {
            A: "5 大科目", 
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國中學程八下: {
            A: "5 大科目", 
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
        國中學程九下: {
            A: "5 大科目",
            B: "不限時間觀看影片",
            C: "無限制測驗次數",
        },
    }
}