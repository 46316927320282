import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import { StoreTypes, useStore } from "context";
import { dateFormat } from "util/dateFormat";

const TestRecord = ({ subject, userId }) => {
  const [{ examRecodeList }] = useStore(StoreTypes.global);
  const [list, setList] = useState(examRecodeList);
  const [listLength, setListLength] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!examRecodeList || examRecodeList === "沒有資料") return;
    const arr = examRecodeList.filter(
      (info) => info.status === "done" && info.subjectName === subject
    );
    setList(arr);
    setListLength(examRecodeList.length);
  }, [examRecodeList, subject]);

  const goExamRecode = (examId, userId) => {
    window.open(
      `${process.env.REACT_APP_ONE_EXAM_DOMAIN}/user/report/${examId}/${userId}`
    );
  };

  const getRightCount = (arr) => {
    return arr.filter((info) => info.isCorrect).length;
  };

  const getVideoRecode = useCallback(
    (list) => {
      if (!list.length > 0 || list === "沒有資料") return;

      const lists = [];
      for (let i = count; i < count + 5; i++) {
        const info = list[i];
        if (info) {
          const data = list[i];
          const time = window
            .moment(data.createDate)
            .format("YYYY/MM/DD HH:mm");
          const rightCount = getRightCount(data.answerData);
          const percent = (rightCount / data.answerData.length) * 100;
          lists.push(
            <div key={i} className={styles.list}>
              <div className={styles.timestamp}>{time}</div>
              <div className={styles.name}>{data.examName}</div>
              <div className={styles.right}>{`${percent}%`}</div>
              <div
                className={styles.result}
                onClick={() => goExamRecode(data.examId, userId)}
              >
                <div className={styles.resultBtn}>查看結果</div>
              </div>
            </div>
          );
        } else {
          lists.push(
            <div key={i} className={styles.list}>
              <div className={styles.timestamp}></div>
              <div className={styles.name}></div>
              <div className={styles.right}>{"　"}</div>
              <div className={styles.result}>{"　"}</div>
            </div>
          );
        }
      }

      return lists;
    },
    [count, userId]
  );

  const prevBtn = useCallback(() => {
    const prevCount = count - 5 <= 0 ? 0 : count - 5;
    setCount(prevCount);
  }, [count]);

  const nextBtn = useCallback(() => {
    const nextCount = count + 5 > listLength ? count : count + 5;
    setCount(nextCount);
  }, [count, listLength]);

  return (
    <div className={styles.recode}>
      <div className={styles.control}>
        <div className={styles.title}>
          <div>
            <img src="./assets/img/Vector.svg" alt="" />
          </div>
          <div>測驗完成紀錄</div>
        </div>
        <div className={styles.searchBtn}>
          <div
            className={classnames(styles.left, {
              [styles.hide]: count === 0,
            })}
            onClick={() => prevBtn()}
          >
            {"<"}
          </div>
          <div
            className={classnames(styles.right, {
              [styles.hide]: count + 5 > listLength,
            })}
            onClick={() => nextBtn()}
          >
            {">"}
          </div>
        </div>
      </div>

      <div className={styles.videoRecodList}>
        <div className={styles.tableText}>
          <div className={styles.timestamp}>測驗時間</div>
          <div className={styles.name}>測驗名稱</div>
          <div className={styles.right}>正確率</div>
          <div className={styles.result}>測驗結果</div>
        </div>
        {list && getVideoRecode(list)}
      </div>
    </div>
  );
};
export default TestRecord;
