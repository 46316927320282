
import { useState, useEffect } from "react";
import { timeFormatTohhmmss } from "util/timeFormater";
import { searchInfo } from "util/studentsSearchFilter";
import {
  SECOND_TAB_NAME,
  exportUsersExcelHeaders,
} from "constants/studentUserInfoTypes";
import StudentSearchLoading from "components/Loading/StudentsSearchLoading"
import { useStore, StoreTypes } from "context";
import { Paper, Table, Box, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

const tableTitleCellStyle = {
  color: '#637381',
  borderBottom: 'none'
}
const tableCellStyle = {
  borderBottom: 'none'
}

const pageStyle = {
  '.css-levciy-MuiTablePagination-displayedRows': {
    margin: '0 auto'
  }
}

const UserTable = (props) => {
  const [{ educationInfo: allEducationInfo }] = useStore(StoreTypes.global);
  const { secondTab, info, sort, setSort } = props;
  const BASE_FRONT_HEADERS = [
    { title: info.county, property: "schoolCounty" },
    { title: info.district, property: "schoolDistrict" },
    { title: info.school, property: "schoolName" },
  ]
  const BASE_AFTER_HEADERS = [
    { title: info.studentCount, property: "studentCount" },
    { title: info.realCount, property: "realCount" },
    { title: info.videoNum, property: "videoNum" },
    { title: info.watchMinute, property: "watchMinute" },
    { title: info.testNum, property: "testNum" },
    { title: info.score, property: "score" },
  ]
  const HEADERS = {
    [SECOND_TAB_NAME.SCHOOL.name]: [
      ...BASE_FRONT_HEADERS,
      ...BASE_AFTER_HEADERS
    ],
    [SECOND_TAB_NAME.GRADE.name]: [
      ...BASE_FRONT_HEADERS,
      { title: info.grade, property: "grade" },
      ...BASE_AFTER_HEADERS
    ]
  }
  return <TableRow>
    {HEADERS[secondTab].map(item =>
      <TableCell sx={tableTitleCellStyle} key={item.title}>
        <Box sx={{ display: "flex", minWidth: "fit-content", whiteSpace: 'nowrap' }}>{item.title}
          <Box sx={{ cursor: 'pointer', display: "flex", alignItems: "center" }}
            onClick={() => {
              if (sort.property === item.property) {
                setSort(prev => ({ ...prev, order: prev.order === '+' ? '-' : '+' }))
              } else {
                setSort({ order: '+', property: item.property })
              }
            }}>
            {item.property && ((sort.property === item.property) ? sort.order === '+' ?
              <ArrowDownward sx={{ fontSize: "1rem" }} /> :
              <ArrowUpward sx={{ fontSize: "1rem" }} /> :
              <ArrowUpward sx={{ color: "#DCE0E4", fontSize: "1rem" }} />)}
          </Box>
        </Box>
      </TableCell>)}
  </TableRow>
};

const productCodeTrans = (productCode, secondTab, allEducationInfo) => {
  if (secondTab === SECOND_TAB_NAME.VIDEO_RECORD.name) {
    for (const key in allEducationInfo) {
      for (const key2 in allEducationInfo[key]) {
        const obj = allEducationInfo[key][key2].find(info => info.productcode === productCode)
        return obj && obj.product
      }
    }
  }
}

const useBrowserUsers = ({ userInfo, key }) => {
  const realInfo = userInfo.filter(info => {
    if (info.schoolInfo) {
      const { schoolCounty, schoolDistrict, schoolName } = info.schoolInfo
      if (schoolCounty + schoolDistrict + schoolName === key) {
        return info
      }
    }
  })
  return realInfo.length;
}

const findRealCountFromSchool = ({ userInfo, key }) => {
  const realInfo = userInfo.filter(info => {
    if (info.schoolInfo && info.hadUsedProduct) {
      const { schoolCounty, schoolDistrict, schoolName } = info.schoolInfo
      if (schoolCounty + schoolDistrict + schoolName === key) {
        return info
      }
    }
  })
  return realInfo.length;
}

const findRealCountFromGrade = ({ userInfo, key }) => {
  const realInfo = userInfo.filter(info => {
    if (info.schoolInfo && info.hadUsedProduct) {
      const { schoolCounty, schoolDistrict, schoolName } = info.schoolInfo
      if (schoolCounty + schoolDistrict + schoolName + info.grade === key) {
        return info
      }
    }
  })
  return realInfo.length;
}

const setTableContent = ({ studentsInfo, userInfo, secondTab, allEducationInfo }) => {
  //schoolCounty
  //schoolDistrict
  //schoolName
  //studentCount 瀏覽網站人數:歷年來登入網站人數
  //info.schoolCounty + info.schoolDistrict + info.schoolName  使用人數:歷年來使用過產品人數(看過影片，做過測驗)
  //videoNum
  //watchMinute
  //testNum
  //score

  return studentsInfo.map((info) => (
    <TableRow
      key={`${info.schoolName}${Math.random()}`}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell sx={tableCellStyle}>{info.schoolCounty || "-"}</TableCell>
      <TableCell sx={tableCellStyle}>{info.schoolDistrict || "-"}</TableCell>
      <TableCell sx={tableCellStyle} >{(info.schoolName && info.schoolName !== 'undefined') ? info.schoolName : "-"}</TableCell>
      {secondTab === SECOND_TAB_NAME.GRADE.name && <TableCell sx={tableCellStyle} >{(info.grade && info.grade !== "undefined") ? info.grade : "-"}</TableCell>}
      <TableCell sx={tableCellStyle} >{useBrowserUsers({ userInfo, key: info.schoolCounty + info.schoolDistrict + info.schoolName })}</TableCell>
      <TableCell sx={tableCellStyle} >{
        secondTab === SECOND_TAB_NAME.SCHOOL.name ?
          findRealCountFromSchool({ userInfo, key: info.schoolCounty + info.schoolDistrict + info.schoolName }) :
          findRealCountFromGrade({ userInfo, key: info.schoolCounty + info.schoolDistrict + info.schoolName + info.grade })
      }</TableCell>
      <TableCell sx={tableCellStyle} >{info.videoNum ? info.videoNum : "-"}</TableCell>
      <TableCell sx={tableCellStyle} >
        {`${timeFormatTohhmmss(info.watchMinute ? info.watchMinute : 0)}`}
      </TableCell>
      <TableCell sx={tableCellStyle} >{info.testNum ? info.testNum : "0"}</TableCell>
      <TableCell sx={tableCellStyle}>{info.score ? `${info.score.toFixed(1)}分` : "0"}</TableCell>
    </TableRow>
  ))
}

const OrganizationTable = ({ searchType, searchKey, currentTab, secondTab, studentsInfo, schoolName, userInfo = null, loading, loadingMessage }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState({ order: "+", property: "schoolCounty" })
  let tableHeaders = {};
  const targetKey = Object.keys(exportUsersExcelHeaders).find(key => exportUsersExcelHeaders[key].tabType === secondTab);
  tableHeaders = exportUsersExcelHeaders[targetKey].headers

  let userInfos = []
  if (studentsInfo.users) {
    userInfos = userInfos.concat(studentsInfo.users)
    userInfos = searchInfo(userInfos, searchType, searchKey)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setSort({ order: '+', property: "schoolCounty" })
  }, [secondTab])

  return (
    <>
      {
        loading ? <StudentSearchLoading message={loadingMessage} /> :
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ background: "#F4F6F8", borderRadius: '8px' }}>
                {
                  tableHeaders && UserTable({ info: tableHeaders, secondTab, setSort, sort })
                }
              </TableHead>
              <TableBody>
                {
                  userInfos && setTableContent({
                    studentsInfo: userInfos.sort((a, b) => {
                      if (sort.property === 'realCount') {
                        const realCountA = findRealCountFromSchool({ userInfo, key: a.schoolCounty + a.schoolDistrict + a.schoolName })
                        const realCountB = findRealCountFromSchool({ userInfo, key: b.schoolCounty + b.schoolDistrict + b.schoolName })
                        return sort.order === '+' ? realCountA - realCountB : realCountB - realCountA
                      }
                      const propertyA = a[`${sort.property}`]
                      const propertyB = b[`${sort.property}`]
                      if (typeof propertyA === 'number' && typeof propertyB === 'number') {
                        return sort.order === '+' ? propertyA - propertyB : propertyB - propertyA
                      } else {
                        if (propertyA && propertyB) {
                          return sort.order === '+' ? propertyA.localeCompare(propertyB) : propertyB.localeCompare(propertyA)
                        } else if (propertyA && !propertyB) {
                          return sort.order === '+' ? -1 : 1
                        } else if (!propertyA && propertyB) {
                          return sort.order === '+' ? 1 : -1
                        } else {
                          return 0
                        }
                      }
                    })
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
                    secondTab,
                    userInfo,
                    productCodeTrans
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
      }
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={userInfos.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={""}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={pageStyle}
      />
    </>

  );
};

export default OrganizationTable;
