import styles from "./index.module.scss";
import { formatTime } from "util/timeFormater";
import dayjs from "dayjs";
import { timeFormatTommss } from "util/timeFormater";
import { searchInfo } from "util/studentsSearchFilter";
import {
  SECOND_TAB_NAME,
  exportUsersExcelHeaders
} from "constants/studentUserInfoTypes";
import { useStore, StoreTypes } from "context";
import { useState,useEffect } from "react";
import StudentSearchLoading from "components/Loading/StudentsSearchLoading"
import {Paper,Table,Box,TableBody,TableCell,TableContainer,TablePagination,TableHead,TableRow} from '@mui/material';
import {ArrowDownward,ArrowUpward} from '@mui/icons-material';


const tableTitleCellStyle = {
  color: '#637381',
  borderBottom: 'none',
}
const tableCellStyle = {
  borderBottom: 'none'
}
const pageStyle = {
  '.css-levciy-MuiTablePagination-displayedRows': {
    margin: '0 auto'
  }
}

const UserTable = (props) => {
  const [{ educationInfo: allEducationInfo }] = useStore(StoreTypes.global);
  const { secondTab, info,setSort,sort } = props;

const BASE_HEADERS = [
  {title:info.county,property:"schoolCounty"},
  {title:info.district,property:"schoolDistrict"},
  {title:info.school,property:"schoolName"},
  {title:info.system,property:"grade"},
  {title:info.userClass,property:"classno"},
  {title:info.account,property:"username"},
  {title:info.name,property:"name"},
]

  const HEADERS = {
[SECOND_TAB_NAME.ALL.name]:[
  ...BASE_HEADERS,
  {title:info.videoNum,property:"videoNum"},
  {title:info.watchMinute,property:"watchMinute"},
  {title:info.testNum,property:"testNum"},
  {title:info.score,property:"score"},
],[SECOND_TAB_NAME.VIDEO_RECORD.name]:[
  ...BASE_HEADERS,
  {title:info.watchTime,property:"video.timestamp"},
  {title:info.videoName,property:"video.volume"},
  {title:info.watchProgress,property:"video.time"},
],
[SECOND_TAB_NAME.EXAM_RECORD.name]:[
...BASE_HEADERS,
  {title:info.examDate,property:"exam.examCreateDate"},
  {title:info.sumTime,property:"exam.sumTime"},
  {title:info.examName,property:"exam.attribute"},
  {title:info.correctPercent,property:"exam.score"},
  {title:info.result},
]
  }
  return <TableRow>
          {HEADERS[secondTab].map(item => 
          <TableCell sx={tableTitleCellStyle} key={item.title}>
            <Box sx={{display:"flex",minWidth:"fit-content",whiteSpace:'nowrap'}}>{item.title}
            <Box sx={{cursor:'pointer',display:"flex",alignItems:"center"}}
              onClick={()=>{if (sort.property === item.property){
                setSort(prev => ({...prev, order: prev.order === '+' ? '-':'+'}))
              } else {
                setSort({order:'+',property:item.property})
              }
            }}>
              {item.property &&(( sort.property === item.property) ? sort.order === '+' ? 
              <ArrowDownward sx={{fontSize:"1rem"}}/>:
              <ArrowUpward sx={{fontSize:"1rem"}}/> :
              <ArrowUpward sx={{color:"#DCE0E4",fontSize:"1rem"}}/>)}
            </Box>
            </Box>
          </TableCell>)}
        </TableRow>
};

const productCodeTrans = (productCode, secondTab, allEducationInfo) => {
  if (secondTab === SECOND_TAB_NAME.VIDEO_RECORD.name) {
    for (const key in allEducationInfo) {
      for (const key2 in allEducationInfo[key]) {
        const obj = allEducationInfo[key][key2].find(info => info.productcode === productCode)
        return obj && obj.product
      }
    }
  }
}

const setTableContent = ({ userInfo, secondTab, allEducationInfo }) => {
  return userInfo.map((info) => {

    const CELLS = {
      [SECOND_TAB_NAME.ALL.name]:[
        {content:info.schoolCounty ||"-"},
        {content:info.schoolDistrict||"-"},
        {content:info.schoolName||"-"},
        {content:info.grade || "-"},
        {content:info.classno || "-"},
        {content:info.username || "-"},
        {content:info.name || info.inputName1 + info.inputName2},
        {content:info.videoNum ? `${info.videoNum}部` : 0},
        {content:formatTime(info.watchMinute)},
        {content:info.testNum ? `${info.testNum}次` : 0,},
        {content:info.score ? `${info.score.toFixed(1)}分` : 0,}
      ],
      [SECOND_TAB_NAME.VIDEO_RECORD.name]:[
        {content:info.schoolCounty ||"-"},
        {content:info.schoolDistrict||"-"},
        {content:info.schoolName||"-"},
        {content:info.grade || "-"},
        {content:info.classno || "-"},
        {content:info.username || "-"},
        {content:info.name || info.inputName1 + info.inputName2},
        {content:info.video ?
          dayjs(info.video.timestamp).format("YYYY/MM/DD HH:mm") : '00:00:00'},
        {content:info.video && (info.video.volume||"")+info.video.name,},
        {content:info.video &&
          `${timeFormatTommss(info.video.time ? info.video.time : 0)}/${timeFormatTommss(
            info.video.duration ? info.video.duration : 0
          )}`,}
      ],
      [SECOND_TAB_NAME.EXAM_RECORD.name]:[
        {content:info.schoolCounty ||"-"},
        {content:info.schoolDistrict||"-"},
        {content:info.schoolName||"-"},
        {content:info.grade || "-"},
        {content:info.classno || "-"},
        {content:info.username || "-"},
        {content:info.name || info.inputName1 + info.inputName2},
        {content:info.exam ? dayjs(info.exam.examCreateDate).format("YYYY/MM/DD HH:mm") : "-"},
        {content:info.exam ? timeFormatTommss(info.exam.sumTime / 1000) : "-"},
        {content:info.exam
          ? `${info.exam.attribute.name}${info.exam.attribute.bookNames[0]}${info.exam.attribute.examName}`.replace(
            "學年度",
            ""
          )
          : "-",className:styles.large},
          {content:info.exam ? `${info.exam.score||0}分` : 0},
          {content:info.exam ? (
            <img
              src="./assets/img/examResult.svg"
              alt="result"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_ONE_EXAM_DOMAIN}/user/report/${info.exam.id}/${info.username}`,
                  "_blank"
                );
              }}
            />
          ) : (
            "-"
          )},
      ]
    }
    return <TableRow
          key={`${info.schoolName}${Math.random()}`}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          {CELLS[secondTab].map(item =><TableCell sx={tableCellStyle} key={Math.random()} className={item.className || ""}><Box sx={{display:"flex",minWidth:"fit-content",wordBreak: 'break-all',maxWidth:"150px"}}>{item.content}</Box></TableCell> )}
        </TableRow>
  })
}

const StudentsInfoTable = ({ searchType, searchKey, currentTab, secondTab, studentsInfo, schoolName, userInfo = null ,loading,loadingMessage}) => {
  const [page, setPage] = useState(0);
  const [sort,setSort] = useState({order:'+',property:"schoolCounty"})
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let userInfos = userInfo ? studentsInfo.users && studentsInfo.users.map((data) => {
    return { ...data, ...userInfo.find(user => user.username === data.username) }
  }) : studentsInfo.users;

  let tableHeaders = {};
  const targetKey = Object.keys(exportUsersExcelHeaders).find(key => exportUsersExcelHeaders[key].tabType === secondTab);
  tableHeaders = exportUsersExcelHeaders[targetKey].headers

  let userInfoTemp = []
  if (userInfos) {
    userInfoTemp = userInfoTemp.concat(userInfos)
    userInfoTemp = searchInfo(userInfoTemp, searchType, searchKey)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(()=>{
    setSort({order:'+',property:"schoolCounty"})
  },[secondTab])


  return (
    <>
      {
        loading ? <StudentSearchLoading message={loadingMessage}/> : <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ background: "#F4F6F8", borderRadius: '8px' }}>
              {
                tableHeaders && UserTable({ info: tableHeaders, secondTab,setSort,sort })
              }
            </TableHead>
            <TableBody>
              {
                userInfoTemp && setTableContent({
                  userInfo: userInfoTemp
                  .sort((a,b) => {
                    if (sort.property.includes(".")){
                      const nestedProperty = sort.property.split(".")
                        const nestedPropertyA = a[`${nestedProperty[0]}`][`${nestedProperty[1]}`]
                        const nestedPropertyB = b[`${nestedProperty[0]}`][`${nestedProperty[1]}`]
                        if (sort.property === 'exam.attribute'){
                          const examNameA = nestedPropertyA.name + nestedPropertyA.bookNames[0] + nestedPropertyA.examName
                          const examNameB = nestedPropertyB.name + nestedPropertyB.bookNames[0] + nestedPropertyB.examName
                          if (a.exam && b.exam){
                           return sort.order === '+' ? examNameA.localeCompare(examNameB) : examNameB.localeCompare(examNameA)
                          } else {
                            return 0
                          }
                        }
                        if (typeof nestedPropertyA === 'number' && typeof nestedPropertyB === 'number'){
                          return sort.order === '+' ? nestedPropertyA- nestedPropertyB : nestedPropertyB -nestedPropertyA
                        }else {
                          if (nestedPropertyA && nestedPropertyB){
                            return sort.order === '+' ? nestedPropertyA.localeCompare(nestedPropertyB) : nestedPropertyB.localeCompare(nestedPropertyA)
                          } else if (nestedPropertyA && !nestedPropertyB){
                            return sort.order === '+' ? -1: 1
                          } else if (!nestedPropertyA && nestedPropertyB){
                            return sort.order === '+' ? 1: -1
                          } else {
                            return 0
                          }
                        }

                    } else {
                      const propertyA = a[`${sort.property}`]
                      const propertyB = b[`${sort.property}`]
                      if (typeof propertyA === 'number' && typeof propertyB === 'number'){
                        return sort.order === '+' ? propertyA - propertyB : propertyB -propertyA
                      }else {
                        if (propertyA && propertyB){
                          return sort.order === '+' ? propertyA.localeCompare(propertyB) : propertyB.localeCompare(propertyA)
                        } else if (propertyA && !propertyB){
                          return sort.order === '+' ? -1: 1
                        } else if (!propertyA && propertyB){
                          return sort.order === '+' ? 1: -1
                        } else {
                          return 0
                        }
                      }
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
                  secondTab,
                  productCodeTrans
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      }
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={userInfoTemp.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={""}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={pageStyle}
      />
    </>

  );
};

export default StudentsInfoTable;
