import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import Header from "components/Header";
import Footer from "components/Footer";
import { useLocation } from "react-router-dom";
import { useStore, StoreTypes } from "context";
import { userExams } from "../../util/exams";
import { getMainPage } from "util/getMainPage";


const ReportView = () => {
  const router = useStore(StoreTypes.router);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const examId = query.get("examId");
  const [{ userId }] = useStore(StoreTypes.user);

  useEffect(() => {
    // get user's exams
    if (userId) {
      userExams(userId)
        .then(function (exams) {
          var examData = null;
          // get exam data with examId
          for (var i = 0; i < exams.length; i++) {
            if (exams[i].data.examId == examId) {
              examData = exams[i].data;
              break;
            }
          }
          if (examData) {
            const productCode = examData.productCode;
            const volume = examData.volume;
            const subject = examData.subjectName;
            // goto main page
            router.history.push(`/ClassView?productCode=${productCode}&volume=${volume}&subject=${subject}`);
          }
          else {
            console.log(`found no examId of oneExam:${examId}`);
            // goto main page
            router.history.push(getMainPage());
          }
        })
        .catch(function (err) {
          console.log(`ReportView useEffect got ${err.message ? err.message : err}`);
          // goto main page
          router.history.push(getMainPage());
        });
    }

  }, [userId]);

  return (
    <div></div>
  )
}

export default ReportView;