export const timeFormatTommss = (time) => {
  const minute = Math.floor(parseInt(time) / 60)
    .toString()
    .padStart(2, "0");
  const second = parseInt(time % 60)
    .toString()
    .padStart(2, "0");
  return `${minute}:${second}`;
};

export const timeFormatTohhmmss = (time) => {
  let hours = Math.floor(time / 3600);
  let minutes = Math.floor((time % 3600) / 60);
  let seconds = parseInt(time % 60);

  return time?`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`:'00:00:00';
};

export const formatTime=(seconds)=> {
  seconds = Math.floor(seconds); // 去除小数部分
  
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
