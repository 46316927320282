import moment from "moment";

export const productExpiredTime = ({
  productCode,
  product,
  isOneLiveMember,
  oneLiveMemberCloseTime,
  education
}) => {
  const findPurchaseTime = (product || []).find(
    (data) => data.productCode === productCode
  );
  const purchaseTime =
    findPurchaseTime && findPurchaseTime.timestamp
      ? findPurchaseTime.timestamp
      : null;

  const closedTimePurchaseTimeComparision = moment // OneStudy 過期時間是否比 Live 關閉時間晚
    .unix(purchaseTime)
    .isAfter(moment.unix(oneLiveMemberCloseTime));

    if (process.env.REACT_APP_PROJECT === 'onestudy'){
      if (!findPurchaseTime) return
      if (!purchaseTime) {
        return isOneLiveMember ? 9999999999 : oneLiveMemberCloseTime
      } else {
        if (education === '主題式'){
          return purchaseTime
        } else {
    return closedTimePurchaseTimeComparision
            ? purchaseTime
            : oneLiveMemberCloseTime
        }
      }
    } else {
      return purchaseTime
    }
};
