import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import Header from "components/Header";
import Footer from "components/Footer";
import { Beforeunload } from 'components/Beforeunload';
import { useStore, StoreTypes } from "context";
import ParamsStorer from "../../util/paramsStorer";
import { userOrders, renew, pay } from "../../util/orders";
import Loading from "components/Loading";
import DialogModule from "components/Dialogs/";

const MyOrders = () => {
    const router = useStore(StoreTypes.router);
    const [isLoading, setIsLoading] = useState(false);
    const [{ token, userId, product }, userDispatch] = useStore(StoreTypes.user);
    const OrderStatus = {
        paydone: '已付款',
        unpay: '待付款',
        expired: '已逾期'
    };

    var userOrderInfos = null;

    //===========================================================================
    useEffect(() => {
        componentDidMount();
        return componentWillUnmount;
    }, []);

    useEffect(() => {
        updateOrders(userId);
    }, [userId, product]);
    //===========================================================================

    function componentDidMount() {
        window.addEventListener('resize', onResize);
        onResize();
    }

    function componentWillUnmount() {
        window.removeEventListener('resize', onResize);
    }

    function onResize() {
        const deviceWidth = window.innerWidth;
        const deviceHeight = window.innerHeight;

        const uiMainArea = document.getElementById('mainArea');
        if (uiMainArea) {
            const headerHeight = 80;
            const footerHeight = 150;
            const minHeight = 50 * (3 + (userOrderInfos ? userOrderInfos.length : 0)) + 40;
            var mainAreaHeight = deviceHeight - headerHeight - footerHeight;
            mainAreaHeight = mainAreaHeight > minHeight ? mainAreaHeight : minHeight;
            uiMainArea.style.height = `${mainAreaHeight}px`;
            console.log(`mainAreaHeight:${mainAreaHeight}px`);
        }
    }

    const formatDate = (dateTime) => {
        const time = new Date(dateTime);
        const year = time.getFullYear();
        const month = time.getMonth() + 1 < 10 ? `0${time.getMonth() + 1}` : time.getMonth() + 1;
        const date = time.getDate() < 10 ? `0${time.getDate()}` : time.getDate();
        const hours = time.getHours() < 10 ? `0${time.getHours()}` : time.getHours();
        const minutes = time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes();
        //const seconds = time.getSeconds() < 10 ? `0${time.getSeconds()}` : time.getSeconds();
        return `${year}/${month}/${date} ${hours}:${minutes}`;
    }

    const getOrderStatus = (orderData) => {
        if (orderData.status === "purchased") {
            return OrderStatus.paydone;
        }
        else {
            const curTime = new Date();
            const expireDate = new Date(orderData.expireDate);
            if (expireDate.getTime() - curTime.getTime() > 0)
                return OrderStatus.unpay;
            else
                return OrderStatus.expired;
        }
    }

    const payOrder = async (orderId) => {
        setIsLoading(true);
        const paySuccessUrl = `${document.location.origin}/PurchaseSuccess?userId=${userId}&orderId=${orderId}`;
        pay(orderId, paySuccessUrl)
            .then(function (result) {
                console.log("result", result);
                setIsLoading(false);
                switch (result.content.type) {
                    case "刷卡單次":
                    case "刷卡分期":
                        ParamsStorer.Instance().setData("PurchaseRouter", result.content.html);
                        router.history.push(`/PurchaseRouter`);
                        break;
                    case "免費":
                        // purchasing done, maybe redirect to courses list
                        break;
                    default:
                        break;
                }
            })
            .catch(function (err) {
                setIsLoading(false);
                console.log(err.message ? err.message : err);
                router.history.push('/PurchaseFailed');
            });
    }

    const onBtnClick = (e) => {
        const btn = e.target;
        const orderId = btn.id;
        const status = btn.value;
        switch (status) {
            case OrderStatus.paydone:
                ParamsStorer.Instance().setData("userOrderInfos", userOrderInfos);
                router.history.push(`/MyOrderDetails?orderId=${orderId}`);
                break;
            case OrderStatus.unpay:
                payOrder(orderId);
                break;
            case OrderStatus.expired:
                var orderInfo = null;
                for (var i = 0; i < userOrderInfos.length; i++) {
                    if (userOrderInfos[i].orderId === orderId) {
                        orderInfo = userOrderInfos[i];
                        break;
                    }
                }
                if (orderInfo) {
                    // assgin productData
                    const productData = orderInfo.items;
                    // add course
                    for (var i = 0; i < productData.length; i++) {
                        var itemData = productData[i];
                        itemData.course = orderInfo.course;
                        itemData["preferential price"] = (i === 0) ? orderInfo.price : 0;
                    }
                    ParamsStorer.Instance().setData(
                        "PurchaseFillInfos",
                        productData
                    );
                    router.history.push(`/PurchaseFillInfos`);
                }
                break;
        }
    }

    const addOrderBtnEvent = () => {
        setTimeout(function () {
            const uiTableBody = document.getElementById('tbodyRecords');
            if (!uiTableBody) return
            if (uiTableBody.children.length === 0) {
                // trigger addOrderBtnEvent again
                addOrderBtnEvent();
            }
            else {
                for (var i = 0; i < userOrderInfos.length; i++) {
                    const uiBtn = document.getElementById(userOrderInfos[i].orderId);
                    if (uiBtn) uiBtn.addEventListener('click', onBtnClick);
                }
                onResize();
            }
        }, 100);
    }

    const updateOrders = async (userId) => {
        try {
            const uiTbodyRecords = document.getElementById('tbodyRecords');
            if (uiTbodyRecords) uiTbodyRecords.innerHTML = "";
            // update order details for userId
            if (userId) {
                userOrderInfos = await userOrders(userId);
                userOrderInfos.sort(function (a, b) {
                    const dateA = new Date(a.createDate);
                    const dateB = new Date(b.createDate);
                    var ts = dateA.getTime() - dateB.getTime();
                    return ts > 0 ? -1 : 1;
                });

                var content = "";
                for (var i = 0; i < userOrderInfos.length; i++) {
                    const createDate = formatDate(userOrderInfos[i].createDate)
                    const status = getOrderStatus(userOrderInfos[i]);
                    var btnStyle = 'width:80%;height:28px;line-height:normal;border-radius:4px';
                    var strRow = "<tr>";
                    switch (status) {
                        case OrderStatus.paydone:
                            strRow += `<td>${userOrderInfos[i].orderId}</td>`;
                            strRow += `<td>${createDate}</td>`;
                            strRow += `<td style='color:#394F8A'>${userOrderInfos[i].course}全科</td>`;
                            strRow += `<td>NT $${userOrderInfos[i].price}</td>`;
                            strRow += `<td>${status}</td>`;
                            strRow += `<td><button id='${userOrderInfos[i].orderId}' value='${status}' style='${btnStyle};color:#121232;background:#FAFAFC;border: 1px solid #D5D7DE;box-sizing: border-box'>查看明細</button></td>`;
                            break;
                        case OrderStatus.unpay:
                            strRow += `<td>${userOrderInfos[i].orderId}</td>`;
                            strRow += `<td>${createDate}</td>`;
                            strRow += `<td style='color:#394F8A'>${userOrderInfos[i].course}全科</td>`;
                            strRow += `<td>NT $${userOrderInfos[i].price}</td>`;
                            strRow += `<td style='color:#F44336'>${status}</td>`;
                            strRow += `<td><button id='${userOrderInfos[i].orderId}' value='${status}' style='${btnStyle};color:#FFFFFF;background:#121232;margin: 0px 0px;flex: none;flex-grow: 0;border-width:0'>前往付款</button></td>`;
                            break;
                        case OrderStatus.expired:
                            strRow += `<td style='color:#D5D7DE'>${userOrderInfos[i].orderId}</td>`;
                            strRow += `<td style='color:#D5D7DE'>${createDate}</td>`;
                            strRow += `<td style='color:#D5D7DE'>${userOrderInfos[i].course}全科</td>`;
                            strRow += `<td style='color:#D5D7DE'>NT $${userOrderInfos[i].price}</td>`;
                            strRow += `<td style='color:#242C3F'>${status}</td>`;
                            strRow += `<td><button id='${userOrderInfos[i].orderId}' value='${status}' style='${btnStyle};color:#121232;background:#FAFAFC;border: 1px solid #D5D7DE;box-sizing: border-box'>重新購買</button></td>`;
                            break;
                        default:
                            break;
                    }
                    strRow += "</tr>";

                    content += strRow
                }
                uiTbodyRecords.innerHTML = content;

                addOrderBtnEvent();
            }
        }
        catch (err) {
            alert(err.message ? err.message : err);
        }
    };

    const backClick = useCallback(() => {
        router.history.goBack();
    }, [router]);

    const loadingText = <div>
        <div>正在進入付款</div>
        <Loading />
    </div>

    return (
        <Beforeunload>
            <div className={styles.myOrders}>
                <DialogModule
                    open={isLoading}
                    content={loadingText}
                />
                <Header />
                <div id="mainArea" className={styles.mainArea}>
                    <div className={styles.back} onClick={() => backClick()}>
                        <img src="./assets/img/chevron_left_outline.svg" alt="return" /><span>返回上一頁</span>
                    </div>
                    <div className={styles.infos}>
                        <div>
                            <div className={styles.title}><b>購買紀錄</b></div>
                            <br></br>
                        </div>
                        <table>
                            <thead className={styles.header}>
                                <th style={{ width: "20%", textAlign: "center", fontSize: "16px" }}>訂單編號</th>
                                <th style={{ width: "20%", textAlign: "center", fontSize: "16px" }}>訂單成立時間</th>
                                <th style={{ width: "15%", textAlign: "center", fontSize: "16px" }}>課程名稱</th>
                                <th style={{ width: "14%", textAlign: "center", fontSize: "16px" }}>金額</th>
                                <th style={{ width: "15%", textAlign: "center", fontSize: "16px" }}>訂單狀態</th>
                                <th style={{ width: "15%", textAlign: "center", fontSize: "16px" }}>付款狀態</th>
                            </thead>
                            <tbody id="tbodyRecords">
                            </tbody>
                        </table>
                    </div>
                    <br></br>
                    <hr className={styles.hr}></hr>
                </div>
                <Footer />
            </div>
        </Beforeunload>
    )
}

export default MyOrders;