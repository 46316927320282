
import React, { useState, useCallback, useEffect } from "react";
import ParamsStorer from "../../util/paramsStorer";

const PurchaseRouter = () => {

    useEffect(() => {
        const html = ParamsStorer.Instance().getData("PurchaseRouter");
        if (html) {
            ParamsStorer.Instance().setData("PurchaseRouter", null);
            const uiPayPanel = document.getElementById('payPanel');
            if (uiPayPanel) {
                uiPayPanel.innerHTML = html;
                tryReouting();
            }
        }
        else {
            // maybe back to PurchaseFillInfos page
        }
    }, []);

    const tryReouting = () => {
        const ui = document.getElementById('_form_aiochk');
        if (ui) {
            ui.submit();
        }
        else {
            setTimeout(function() { tryReouting(); }, 100);
        }
    }

	return (
        <div id="payPanel">
        </div>
    )
};

export default PurchaseRouter;