import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const Button = ({ className, children, onClick, title = '', inlineStyle = {}, disabled = false }) => {
  return (
    <button
      title={title}
      type="button"
      style={{ ...inlineStyle }}
      className={classnames(styles.button, className)}
      onClick={disabled ? null : onClick}
    >
      {children}
    </button>
  )
};
export default Button;
