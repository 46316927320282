
function getAPIDomain() {
    var apiDomain = document.location.origin;
    if (apiDomain.indexOf("localhost") > 0) {
        apiDomain = "http://localhost:5001/onestudy-dev-ce9b0/asia-northeast1";
    }
    if (apiDomain.indexOf("yunlin") >-1) {
        if (apiDomain.indexOf("yunlin.onestudy-dev") >-1) {
            apiDomain = "https://asia-northeast1-yunlin-onestudy-dev-383bf.cloudfunctions.net";
        }
        else if (apiDomain.indexOf("yunlin.onestudy-uat") >-1) {
            apiDomain = "https://asia-northeast1-yunlin-onestudy-uat-59420.cloudfunctions.net";
        }
        else {
            apiDomain = "https://asia-northeast1-yunlin-onestudy-release.cloudfunctions.net";
        }
    } else if (apiDomain.indexOf("chiayi") >-1) {
        if (apiDomain.indexOf("chiayi.onestudy-dev") >-1) {
            apiDomain = "https://asia-northeast1-chiayi-onestudy-dev.cloudfunctions.net";
        }
        else if (apiDomain.indexOf("chiayi.onestudy-uat") >-1) {
            apiDomain = "https://asia-northeast1-chiayi-onestudy-uat-97d49.cloudfunctions.net";
        }
        else {
            apiDomain = "https://asia-northeast1-chiayi-onestudy-release.cloudfunctions.net";
        }
    } else if (apiDomain.indexOf("onestudy") > -1) {
        if (apiDomain.indexOf("dev") >-1) {
            apiDomain = "https://asia-northeast1-onestudy-dev-ce9b0.cloudfunctions.net";
        }
        else if (apiDomain.indexOf("uat") >-1) {
            apiDomain = "https://asia-northeast1-onestudy-uat.cloudfunctions.net";
        }
        else {
            apiDomain = "https://asia-northeast1-onestudy-release.cloudfunctions.net";
        }
    } else if (apiDomain.indexOf("nanistudy") >-1) {
        if (apiDomain.indexOf("nanistudy-dev") >-1) {
            apiDomain = "https://asia-east1-onestudyedu-dev.cloudfunctions.net";
        }
        else if (apiDomain.indexOf("nanistudy-uat") >-1) {
            apiDomain = "https://asia-east1-nanistudy-uat-babd2.cloudfunctions.net";
        }
        else {
            apiDomain = "https://asia-east1-nanistudy-release.cloudfunctions.net";
        }
    } 

    return apiDomain;
}

function getProducts(course) {
    // courseId is like "國小一上", "國中七上"...
    return new Promise((resolve, reject) => {
        const curTime = new Date();
        var year = curTime.getFullYear();
        if (curTime.getMonth() < 8) year -= 1;
        year = year - 1911;

        var apiDomain = getAPIDomain();
        var data = {
            year: year.toString(),
            filters: {
                course: course
            }
        };

        fetch(`${apiDomain}/apis/getProducts`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-type": "application/json; charset=UTF-8" }
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                console.log("------response-----", response);
                if (response.status == "success") {
                    resolve(response.data);
                }
                else {
                    reject(response.error.message);
                }
            })
            .catch(function (err) {
                reject(err.message ? err.message : err);
            });
    });
}

function findOrders(userId, course) {
    return new Promise((resolve, reject) => {
        var apiDomain = getAPIDomain();
        var filters = {};
        if (userId) filters.userId = userId;
        if (course) filters.course = course;
        var data = {
            filters: filters
        };

        fetch(`${apiDomain}/order/get`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-type": "application/json; charset=UTF-8" }
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                if (response.status == "success") {
                    resolve(response.data);
                }
                else {
                    reject(response.error.message);
                }
            })
            .catch(function (err) {
                reject(err.message ? err.message : err);
            });
    });
}

function getOrder(orderId, filters) {
    return new Promise((resolve, reject) => {
        var apiDomain = getAPIDomain();
        var data = {
            orderId: orderId,
            filters: filters
        };

        fetch(`${apiDomain}/order/get`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-type": "application/json; charset=UTF-8" }
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                console.log("getOrder", response);
                if (response.status == "success") {
                    var orders = response.data;
                    if (orders.length > 0)
                        resolve(orders[0]);
                    else
                        reject(`order ${orderId} doesn't exist`);
                }
                else {
                    reject(response.error.message);
                }
            })
            .catch(function (err) {
                reject(err.message ? err.message : err);
            });
    });
}

function createOrder(userId, course, education, city, town, school, options, expireDate, price) {
    console.log("---price---", price);
    return new Promise(async (resolve, reject) => {
        try {
            var productItems = await getProducts(course);
            // the struct of items should be
            // [
            //  {name:productName, code:productCode, price:productPrice},
            //  {name:name1, code:code1, price:800},
            //  {name:name2, code:code2, price:6500},
            // ]
            var items = [];
            var subjects = "";
            var totalPrice = 0;
            for (var i = 0; i < productItems.length; i++) {
                var product = productItems[i];
                // fill content of items, description & totalPrice
                items.push({
                    name: product["product"],
                    code: product["productcode"],
                    price: product.hasOwnProperty("price") ? parseFloat(product["price"].toString()) : 0,
                    preferentialPrice: product.hasOwnProperty("preferential price") ? parseFloat(product["preferential price"].toString()) : 0,
                    course: product["course"],
                    education: product["education"],
                    volumeName: product["VolumeName"],
                    subject: product["subject"]
                });
                subjects += subjects.length > 0 ? `,${product["product"]}` : product["product"];
                // add total price
                totalPrice += product["preferential price"] ? parseFloat(product["preferential price"]) : 0;
            }
            var description = `感謝您購買OneStudy${course}全套課程共${items.length}個科目，包含${subjects}。email:${options["電子信箱"]}`;
            // the struct of options should be
            // {
            //     "學制":"",
            //     "縣市":"",
            //     "鄉鎮":"",
            //     "學校":""
            // }
            options["學制"] = education;
            options["縣市"] = city;
            options["鄉鎮"] = town;
            options["學校"] = school;

            // create order...
            var apiDomain = getAPIDomain();
            var data = {
                userId: userId,
                course: course,
                items: items,
                description: description,
                totalPrice: totalPrice,
                expireDate: expireDate,
                options: options,
                price
            };

            fetch(`${apiDomain}/order/create`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { "Content-type": "application/json; charset=UTF-8" }
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (response) {
                    console.log("------createOrder-----", response);
                    if (response.status == "success") {
                        resolve(response.data);
                    }
                    else {
                        reject(response.error.message);
                    }
                })
                .catch(function (err) {
                    reject(err.message ? err.message : err);
                });
        }
        catch (err) {
            reject(err.message ? err.message : err);
        }
    });
}

function renewOrder({ orderId, education, city, town, school, options, expireDate, price }) {
    return new Promise(async (resolve, reject) => {
        try {
            options = options || {};
            if (education) options["學制"] = education;
            if (city) options["縣市"] = city;
            if (town) options["鄉鎮"] = town;
            if (school) options["學校"] = school;

            // create order...
            var apiDomain = getAPIDomain();
            var data = {
                orderId: orderId,
                expireDate: expireDate,
                options: options,
                price
            }

            fetch(`${apiDomain}/order/renew`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { "Content-type": "application/json; charset=UTF-8" }
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (response) {
                    if (response.status == "success") {
                        resolve(response.data);
                    }
                    else {
                        reject(response.error.message);
                    }
                })
                .catch(function (err) {
                    reject(err.message ? err.message : err);
                });
        }
        catch (err) {
            reject(err.message ? err.message : err);
        }
    });

}

function payOrder(orderData, responseUrl, discountCode) {
    return new Promise((resolve, reject) => {
        const userId = orderData.userId;
        const orderId = orderData.orderId;

        if (!responseUrl) responseUrl = `${document.location.origin}/PurchaseSuccess?userId=${userId}&orderId=${orderId}`; // assign url of web page and redirect to after pay done
        var apiDomain = getAPIDomain();
        var data = {
            orderId: orderId,
            payType: "刷卡單次",
            payConfig: {
                divideNum: 1,
                responseUrl: responseUrl
            },
            discountCode
        };

        fetch(`${apiDomain}/order/pay`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-type": "application/json; charset=UTF-8" }
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                if (response.status == "success") {
                    resolve(response.data);
                }
                else {
                    reject(response.error.message);
                }
            })
            .catch(function (err) {
                reject(err.message ? err.message : err);
            });
    });
}

export const consume = (userId, code) => {
    return new Promise((resolve, reject) => {
        try {
            if (!userId || !code) {
                var errMsg = "";
                if (!userId) errMsg += `未指定 userId\n`;
                if (!code) errMsg += `未指定 code\n`;
                reject(errMsg);
            }
            else {
                var apiDomain = getAPIDomain();
                var data = {
                    userId: userId,
                    code: code
                };

                fetch(`${apiDomain}/order/consume`, {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: { "Content-type": "application/json; charset=UTF-8" }
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        if (response.status == "success") {
                            resolve(response.data);
                        }
                        else {
                            reject(response.error.message);
                        }
                    })
                    .catch(function (err) {
                        reject(err.message ? err.message : err);
                    });
            }
        }
        catch (err) {
            reject(err.message ? err.message : err);
        }
    });
}

export const create = (userId, course, education, city, town, school, expireDate, payMoney) => {
    console.log("--create--", userId, course, education, city, town, school, expireDate, payMoney);
    return new Promise(async (resolve, reject) => {
        try {
            if (!userId || !course || !education || !city || !town || !school) {
                var errMsg = "";
                if (!userId) errMsg += `未指定 userId\n`;
                if (!course) errMsg += `未指定 course\n`;
                if (!education) errMsg += `未指定 education\n`;
                if (!city) errMsg += `未指定 city\n`;
                if (!town) errMsg += `未指定 town\n`;
                if (!school) errMsg += `未指定 school\n`;
                reject(errMsg);
            }
            else {
                var orderData = null;
                var orderList = await findOrders(userId, course);
                // if user never buy specified course, create order
                if (!orderList || orderList.length == 0) {
                    var orderId = await createOrder(userId, course, education, city, town, school, expireDate, null, payMoney);
                    resolve(orderId);
                }
                else {
                    // should be only one
                    orderData = orderList[0];
                    // var curTime = new Date();
                    // expireDate = new Date(orderData.expireDate);
                    // if (curTime.getTime() - expireDate.getTime() > 0) {
                    // var orderId = await renewOrder(orderData.orderId, education, city, town, school, {}, expireDate, payMoney);
                    var orderId = await renewOrder({
                        orderId: orderData.orderId,
                        education,
                        city,
                        town,
                        school,
                        options: null,
                        expireDate: orderData.expireDate,
                        price: payMoney
                    });

                    resolve(orderId);
                    // }
                    // else {
                    //     resolve(orderData.orderId);
                    // }
                }
            }
        }
        catch (err) {
            reject(err.message ? err.message : err);
        }
    });
}

export const renew = (orderId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await renewOrder(orderId);
            resolve(result);
        }
        catch (err) {
            reject(err.message ? err.message : err);
        }
    });
}

export const pay = (orderId, responseUrl, discountCode) => {
    return new Promise(async (resolve, reject) => {
        try {
            // find order
            const orderData = await getOrder(orderId);
            console.log("orderData", orderData);
            if (orderData) {
                var result = await payOrder(orderData, responseUrl, discountCode);
                var { platform, type, html } = result;
                if (platform == "OneStudy" && type == "免費") {
                    // allow use to use product directly...
                    resolve({
                        status: "success",
                        content: {
                            type: "免費"
                        }
                    });
                }
                else if (html) {
                    // append html to page and redirect to pay client...
                    resolve({
                        status: "success",
                        content: {
                            type: type,
                            html: html
                        }
                    });
                }
                else {
                    reject('no pay cient html');
                }
            }
            else {
                reject(`order ${orderId} doesn't exist`);
            }
        }
        catch (err) {
            reject(err.message ? err.message : err);
        }
    });
}

export const userOrders = (userId) => {
    return new Promise(async (resolve, reject) => {
        try {
            // find order
            const orderData = await findOrders(userId);
            resolve(orderData);
        }
        catch (err) {
            reject(err.message ? err.message : err);
        }
    });
}