import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from "./index.module.scss";
import { Accordion, AccordionItem } from "react-sanfona";
import { StoreTypes, useStore } from "context";
import * as types from "constants/actionTypes";
import { useOutsideAlerter } from "customHooks/outsideAlerter";
import { dataIdSort } from 'util/array'

const ClassSelect = () => {
  const ref = useRef();
  const router = useStore(StoreTypes.router);
  const [{ educationInfo: data }, globalDispatch] = useStore(StoreTypes.global);
  const [items, setItems] = useState(false);
  const setClose = () => {
    globalDispatch({
      type: types.SET_CLASS_SELECT_PANNEL,
      isClassSeletPannel: false,
    });
  };
  useOutsideAlerter(ref, setClose);

  useEffect(() => {
    for (var key in data) {
      for (var key2 in data[key]) {
        //console.log(key2);
        for (var key3 in data[key][key2]) {
          //console.log(data[key][key2][key3]["產品"]);
        }
      }
    }
  }, [data]);

  const setSubjectItem = (data) => {
    const yearGroup = []
    data.forEach((info) => {
      const year = info.id.split("_")[0]
      if (!yearGroup[year]) {
        yearGroup[year] = []
      }
      yearGroup[year].push(info)
    })
    setItems(yearGroup);
  };

  const createEducation = (education, data) => {
    const tags = [];
    let count = 0;
    for (var key in data) {
      const item = data[key];
      tags.push(
        <div
          key={count}
          className={styles.list}
          onClick={() => setSubjectItem(item)}
        >
          {`${education + key}`}
        </div>
      );

      count++;
    }
    return tags;
  };


  const getThemeIcon = (volume) => {
    switch (volume) {
      case "國語":
      case "國語文":
      case "國文":
        return "./assets/img/ch.png";
      case "數學":
        return "./assets/img/ma.png";
      case "英語":
      case "英文":
        return "./assets/img/en.png";
      case "自然":
        return "./assets/img/na.png";
      case "生活":
        return "./assets/img/live.png";
      case "社會":
        return "./assets/img/so.png";
      case "地理":
        return "./assets/img/ge.png";
      case "歷史":
        return "./assets/img/hi.png";
      case "公民與社會":
        return "./assets/img/ci.png";
      case "生物":
        return "./assets/img/na.png";
      case "物理":
        return "./assets/img/phy.png";
      case "化學":
        return "./assets/img/che.png";
      case "地球科學":
        return "./assets/img/live.png";
      default:
        return "./assets/img/ch.png";
    }
  };

  const getIcon = (data) => {
    switch (data) {
      case "國語":
      case "國文":
        return "./assets/img/ch.png";
      case "數學":
      case "數學A":
      case "數學B":
      case "數學甲上":
      case "數學甲下":
      case "數學乙上":
      case "數學乙下":
        return "./assets/img/ma.png";
      case "英語":
      case "英文":
        return "./assets/img/en.png";
      case "自然":
        return "./assets/img/na.png";
      case "生活":
        return "./assets/img/live.png";
      case "社會":
        return "./assets/img/so.png";
      case "地理":
        return "./assets/img/ge.png";
      case "歷史":
        return "./assets/img/hi.png";
      case "公民與社會":
        return "./assets/img/ci.png";
      case "生物":
        return "./assets/img/na.png";
      case "物理":
        return "./assets/img/phy.png";
      case "化學":
        return "./assets/img/che.png";
      case "地球科學":
        return "./assets/img/live.png";
      default:
        return "./assets/img/ch.png";
    }
  };

  const goSubjectPage = useCallback((data) => {
    const productCode = data["productcode"];
    const volume = data["course"];
    const subject = data["subject"];
    const url = `/ClassView?productCode=${productCode}&volume=${volume}&subject=${subject}`;

    globalDispatch({ type: types.SET_VIDEO_LIST, videoList: [] });
    globalDispatch({ type: types.SET_TEST_LIST, testList: [] });
    globalDispatch({
      type: types.SET_CLASS_SELECT_PANNEL,
      isClassSeletPannel: false,
    });
    globalDispatch({ type: types.SET_PRODUCT_INFO, productInfo: data });
    globalDispatch({ type: types.SET_GO_BACK_URL, goBackUrl: url });
    router.history.push(url);
  }, [router, globalDispatch]);

  const createEducationItem = (items) => {
    return items.map((data, index) => {
      const itemName = data["productcode"];
      const education = data["education"];
      const product = data["product"];
      const VolumeName = data["VolumeName"];
      const educationName = data["subject"]; //itemName.substr(itemName.length-2,itemName.length)
      return (
        <div
          key={index}
          className={styles.item}
          onClick={() => goSubjectPage(data)}
        >
          <img src={education === '主題式' ? getThemeIcon(VolumeName) : getIcon(educationName)} alt={educationName} />
          <div>{product.indexOf("首冊") > -1 ? "國語首冊" : educationName}</div>
        </div>
      );
    });
  }

  const createItem = (item) => {
    const lists = []
    item.sort(dataIdSort);
    for (let key in item) {
      lists.push(<div
        key={key}
        className={styles.itemGroup}
      >
        <h3 className={styles.year}>{item[key][0].id.split("_")[0]}</h3>
        <div>
          {createEducationItem(item[key])}
        </div>

      </div>)
    }
    return lists;
  };

  return (
    <div ref={ref} className={styles.classSelect}>
      <div className={styles.left}>
        <div className={styles.education}>
          <div className={styles.educationT}>
            {data && data["主題式"] && (
              <div>
                <div className={styles.educationEText}>{"主題式"}</div>
                {createEducation("", data["主題式"])}
              </div>
            )}
          </div>
          <div className={styles.educationE}>
            {data && data["國小"] && (
              <div>
                <div className={styles.educationEText}>{"國小"}</div>
                {createEducation("國小", data["國小"])}
              </div>
            )}
          </div>
          <div className={styles.educationS}>
            {data && data["國中"] && (
              <div>
                <div className={styles.educationSText}>{"國中"}</div>
                {createEducation("國中", data["國中"])}
              </div>
            )}
          </div>
          <div className={styles.educationS}>
            {data && data["高中"] && (
              <div>
                <div className={styles.educationSText}>{"高中"}</div>
                {createEducation("高中", data["高中"])}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.centerLine}></div>
      <div className={styles.right}>
        <div className={styles.itemArea}>{items && createItem(items)}</div>
      </div>
    </div>
  );
};

export default ClassSelect;
