export const SUBJECT_TYPE = {
  國語: "CH",
  國語首冊: "CH",
  數學: "MA",
  數學A: "M1",
  數學B: "M2",
  數學甲上: "MA1",
  數學甲下: "MA2",
  數學乙上: "MA3",
  數學乙下: "MA4",
  生活: "LI",
  社會: "SO",
  自然: "SC",
  英語: "EN",
  英文: "EN",
  國文: "CH",
  歷史: "HI",
  地理: "GE",
  公民與社會: "CI",
  生物: "BIO",
  化學: "CHE",
  物理: "PHY",
  地球科學: "EAR",
  超越盃: "超越盃",
  測驗訓練: "測驗訓練",
  閱讀動畫: "閱讀動畫",
  會話: "會話",
};


export const checkGrade = ['一', '二', '三', '四', '五', '六', '七', '八', '九'];
export const themeGrade = [
  '國語',
  '國語文',
  '國文',
  '數學',
  '主題數學',
  '英語',
  '主題英語',
  '英文',
  '生活',
  '社會',
  '自然',
  '地理',
  '歷史',
  '公民與社會',
  '地球科學',
  '生物',
  '化學',
  '物理',
  '國語',
];
