export const translateGradeToChinese = (grade) => {
    let education;
    switch(grade) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
            education = '國小';
            break;
        case '7':
        case '8':
        case '9':
            education = '國中';
            break;
        case '10':
        case '11':
        case '12':
            education = '高中';
            break;
        default:
            education = '國小';
    }
    return education;
}