import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import Header from "components/Header";
import Footer from "components/Footer";
import { StoreTypes, useStore } from "context";
import { checkProjectType } from "constants/projectsType";

const ServiceView = () => {
	const router = useStore(StoreTypes.router);

	useEffect(() => {
		var elmnt = document.getElementById("root");
		elmnt.scrollTop = 0;
	}, []);

	const goBack = useCallback(() => {
		router.history.goBack();
	}, [router]);

	const setProjectName = () => {
		return checkProjectType() ? "OneStudy" : "NaniStudy"
	}

	return (
		<div>
			<Header />
			<div className={styles.backContent}>
				<div className={styles.back} onClick={() => goBack()}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6 12C6 12.3946 6.14656 12.7328 6.46223 13.0259L15.2334 21.6167C15.4814 21.8647 15.7971 22 16.1691 22C16.9132 22 17.5107 21.4138 17.5107 20.6584C17.5107 20.2864 17.3529 19.9594 17.1048 19.7001L9.2018 12L17.1048 4.29989C17.3529 4.04059 17.5107 3.70237 17.5107 3.3416C17.5107 2.58625 16.9132 2 16.1691 2C15.7971 2 15.4814 2.13529 15.2334 2.38331L6.46223 10.9628C6.14656 11.2672 6 11.6054 6 12Z"
							fill="#8b90a0"
						/>
					</svg>
					<div>返回上一頁</div>
				</div>
			</div>

			<div className={styles.serviceView}>
				<div>
					<div className={styles.title}>服務條款</div>
					<div className={styles.line}></div>
					<div className={styles.content}>
						<div className={styles.caption}>
							{`${setProjectName()}課程交易相關說明`}
						</div>
						<div>
							{`本課程交易相關說明（以下簡稱「本說明」），指萬通教育股份有限公司（以下簡稱「本公司」）授權您於
							${setProjectName()}.oneclass.com.tw 網域之網站及${setProjectName()}
							App（以下簡稱「本平台」），使用 ${setProjectName()}
							透過網路提供之課程教學、測驗、記錄與相關服務（以下簡稱「本服務」、「${setProjectName()}」）。爰此，關於本服務之權利義務，雙方同意以本說明約定之。`}
						</div>
						<div className={styles.caption}>第1條 服務定義</div>
						<div>
							{`${setProjectName()}授權您於${setProjectName()}.oneclass.com.tw網站(以下簡稱「網頁服務」)及${setProjectName()}
							App，使用${setProjectName()}
							透過網路提供之課程教學、測驗、記錄與相關服務。關於本服務之雙方權利義務，依本說明訂定之。`}
						</div>
						<div className={styles.caption}>
							第2條 說明之成立生效
						</div>
						<div>
							<ol>
								<li>您已審閱本服務全部條款內容超過3日以上。</li>
								<li>
									本服務使用者須經網際網路申請OneClub會員作為使用本服務之會員資格，經完成OneClub會員註冊申請，及表示同意遵守其會員服務及相關說明文件全部內容。
								</li>
								<li>
									所有使用者一經由網際網路在本平台上申請服務並勾選「若辦理課程退費，本人同意由萬通教育股份有限公司代為處理發票及退費折讓行政流程」、「我已閱讀並同意課程交易相關說明」者，表示同意使用本服務並同意以本說明之文件全部內作為表示方式後，本公司應立即以網站系統，回覆通知使用者利用再次確認機制加以確認電子文件內容，經使用者再次確認後，本契約成立。
								</li>
							</ol>
						</div>
						<div className={styles.caption}>第3條 服務內容</div>
						<div>本服務內容包括以下內容：</div>
						<div>
							<ol>
								<li>
									{`${setProjectName()}提供${setProjectName()}.oneclass.com.tw網站及${setProjectName()}`}
									App
								</li>
								<li>
									{`${setProjectName()}提供本服務之適用對象：依OneClub會員所綁定登入之帳號`}
								</li>
								<li>
									{`${setProjectName()}提供本服務之教學內容：依個人所領取或購買的課程、測驗內容，詳見網頁上「我的課程」內所載內容。`}
								</li>
							</ol>
						</div>
						<div className={styles.caption}>第4條 設備規格</div>
						<div>
							<ol>
								<li>
									為了提供您良好的使用經驗，建議使用桌上型或筆記型電腦進入網頁服務之使用，並建議您的電腦及平板軟硬體設備達到以下建議之規格。也請您知悉若您使用之部分或全部設備未達以下規格，將會影響您的使用體驗：
								</li>
							</ol>
							<div className={styles.systemRuleContent}>
								<div className={styles.top}>
									<div className={styles.left}>
										<div>2. 作業系統</div>
									</div>
									<div className={styles.right}>
										<div>電腦</div>
										<div>Windows 10</div>
										<div>Mac OS 10.13</div>
										<div>平板</div>
										<div>iOS 15</div>
										<div>Android 11</div>
									</div>
								</div>
								<div className={styles.center}>
									<div className={styles.left}>
										<div>3. 瀏覽器</div>
									</div>
									<div className={styles.right}>
										<div>Chrome 官方發布之最新版本</div>
										<div>Edge 官方發布之最新版本</div>
									</div>
								</div>
								<div className={styles.bottom}>
									<div className={styles.left}>
										<div>4. 網路速度</div>
									</div>
									<div className={styles.right}>
										<div>
											光纖網路 15
											Mbps，家庭共享網路建議60Mbps
										</div>
										<div>行動網路 4G</div>
									</div>
								</div>
							</div>
							<ol start="5">
								<li>
									{`您連結${setProjectName()}指定網站系統之機房設備之接取網路，除經雙方約定由${setProjectName()}所提供者外，應由學員自行向合法經營之電信事業申請租用，您租用該接取網路所生之權利義務，依您與該電信事業間契約約定之。`}
								</li>
								<li>
									若因您所使用的設備未達以上部分或全部規格，導致您無法使用本服務或影響本服務之品質者，您不會以此主張本說明服務不生效力。
								</li>
							</ol>
						</div>
						<div className={styles.caption}>第5條 使用期間</div>
						<div>
							<ol>
								<li>
									各課程使用期間均載於該課程購買頁面以及您的會員中心我的課程資料中。
								</li>
								<li>
									您於各課程之使用期間內得不限次數，每次不計時間使用本服務。
								</li>
								<li>
									當課程處於可供使用狀態，您藉由裝置開始瀏覽課程內容，例如影片、測驗等內容，即視為課程已交付。此外，當您於依本說明合法購買課程後，於未違反本說明或相關法規規定之前提下，本平台將於各課程使用期間持續提供本平台之服務。
								</li>
							</ol>
						</div>
						<div className={styles.caption}>第6條 使用者義務</div>
						<div>
							<ol>
								<li>
									您對個人使用的帳號與個人密碼有妥善保管以避免第三人知悉之義務。
								</li>
								<li>
									您對於使用本服務所產生之授權使用費，有按時給付之義務，並對產品使用期限終止前已產生之授權使用費，有繳納之義務。
								</li>
								<li>
									您在使用本服務時，有遵守本說明所約定授權使用原則之義務。
								</li>
								<li>
									您依本說明約定所註冊之個人資料應確保各該資料之真實性及完整性，所有的個人資料皆被視為註冊必備要素，您依本契約之約定所註冊之個人資料有錯誤或已變更者，應儘速通知本平台請求更正。如因您怠於通知而致其權益受損者，應由您自行負責。
								</li>
							</ol>
						</div>
						<div className={styles.caption}>
							第7條 帳號密碼非法使用之處理
						</div>
						<div>
							<ol>
								<li>
									使用您個人帳號與密碼，登入本服務網站系統之行為，推定為您之行為。
								</li>
								<li>
									若您發現您的會員帳號或個人密碼遭第三人不法或不當之使用者，應立即通知本平台。經您確認有遭第三人不法或不當之使用情事者，本平台將立即暫停該會員帳號或個人密碼之使用，並接受您更換個人密碼以維護使用安全。
								</li>
							</ol>
						</div>
						<div className={styles.caption}>第8條 法定代理人</div>
						<div>
							<ol>
								<li>
									若您為「限制行為能力人」者，同意本說明時，應經由您的法定代理人同意，本說明始生效力；若您為「無行為能力人」者，應經由法定代理人代理訂定本契約，本說明始生效力。
								</li>
								<li>
									違反前項之約定者，除有民法第八十三條之情形者外，本說明不生效力，本平台不得據以要求您之法定代理人負擔契約履行或賠償責任。
								</li>
								<li>
									限制行為能力人於同意本說明前，應主動告知其為限制行為能力人。
								</li>
							</ol>
						</div>
						<div className={styles.caption}>第9條 服務品質</div>
						<div>
							<ol>
								<li>
									本平台應提供具有合理期待安全性之服務，並應確保本服務無發生錯誤、畫面暫停、遲滯、中斷或不能進行連線之情形。
								</li>
								<li>
									本平台因可歸責於自己之事由，違反前項之約定者，在接到通知後有義務立即更正或修復。
								</li>
								<li>
									服務期間若由您所租用之電信業者提供網路接取服務衍生之體驗問題，請依造您與所簽訂服務之電信業者相關服務約定反映業者處理，以維護您的權利義務。
								</li>
								<li>
									本平台不保證本服務將符合您的特定要求，於本平台取得之任何產品、服務、資訊或其它課程將不保證符合您的期望，對此是否使用本服務應由您自行考慮且自負風險。
								</li>
							</ol>
						</div>
						<div className={styles.caption}>第9條 服務品質</div>
						<div>
							<ol>
								<li>
									本平台應提供具有合理期待安全性之服務，並應確保本服務無發生錯誤、畫面暫停、遲滯、中斷或不能進行連線之情形。
								</li>
								<li>
									本平台因可歸責於自己之事由，違反前項之約定者，在接到通知後有義務立即更正或修復。
								</li>
								<li>
									服務期間若由您所租用之電信業者提供網路接取服務衍生之體驗問題，請依造您與所簽訂服務之電信業者相關服務約定反映業者處理，以維護您的權利義務。
								</li>
								<li>
									本平台不保證本服務將符合您的特定要求，於本平台取得之任何產品、服務、資訊或其它課程將不保證符合您的期望，對此是否使用本服務應由您自行考慮且自負風險。
								</li>
							</ol>
						</div>
						<div className={styles.caption}>
							第10條 履約保證責任
						</div>
						<div>
							如本服務授權使用費預付期間逾一年且預付金額超過新臺幣五萬元者，本平台就超過金額部分，將依信託法規定開立信託專戶管理或由銀行提供履約保證，作為履約保證之內容。
						</div>
						<div className={styles.caption}>第11條 智慧財產權</div>
						<div>
							您應尊重與本服務有關之所有智慧財產權，非經智慧財產權人事前書面同意，您不得以本說明授權以外之其他方式加以利用，包括但不限於重製、公開傳輸、轉售、公開播放、公開上映、分享、以本平台以外之方式下載或使用本服務。
							您違反前項之約定者，本平台得依法賠償智慧財產權人因此所受之損害。
						</div>
						<div className={styles.caption}>
							第12條 雙方的保密義務
						</div>
						<div>
							我們因提供本服務而知悉或持有您之學習紀錄或其他個人資料，我們負有保密義務，除您請求查詢或第三人依據您所同意之隱私權聲明或個人資料保護相關法令請求查詢者外，我們將不得對任何第三人以具名方式揭露。
						</div>
						<div className={styles.caption}>
							第13條 我們的免責事由
						</div>
						<div>
							除本說明或本平台之廣告另有保證使用本服務之學習成效外，我們不保證您使用本服務之學習成效。
						</div>
						<div className={styles.caption}>
							第14條 暫停服務之處理
						</div>
						<div>
							我們對於本服務相關軟硬體設備，進行營運上必要之搬遷、更換、升級、保養或維修時，得暫停本服務之全部或部分。
							我們因前項事由而暫停本服務之全部或部分，應於暫停本服務七日前，於本服務網站首頁上及本服務進行中公告。但因臨時性、急迫性或不可歸責於我們之事由者，不在此限。
						</div>
						<div className={styles.caption}>第15條 擔保授權</div>
						<div>
							本平台授權您使用本服務之期間、次數及權利依下列之約定：
						</div>
						<div>
							<ol>
								<li>
									本平台應確保其就本約定所授權您使用之服務內容，為合法權利人。
								</li>
								<li>
									本平台有違反前項之情事致您無法繼續使用者，本平台應補償您無法使用之損失，即以類似課程替換或全額退還您已支付該課程之授權使用費金額。
								</li>
								<li>
									未經本平台事前之書面同意，您不得將其使用權讓與他人使用，包括但不限於與他人共用您的會員帳號、分享串流或公開傳輸、播送、放映等。
								</li>
							</ol>
						</div>
						<div className={styles.caption}>第16條 授權使用費</div>
						<div>
							本平台授權您使用本服務之期間、次數及權利依下列之約定：
						</div>
						<div>
							<ol>
								<li>
									本服務授權各課程的使用期間，依本平台中「我的課程」頁面所列。
								</li>
								<li>
									本平台得以優惠價格提供您自由決定是否加價購買商品、教學課程或服務。
								</li>
								<li>
									您依前項優惠方式加購或領取之服務，終止時依照第19條第2項之規定。
								</li>
							</ol>
						</div>
						<div className={styles.caption}>第17條 付費方式</div>
						<div>
							雙方同意本服務授權使用費之給付方式，依照各課程購買說明頁面所示之交易機制繳納。
						</div>
						<div className={styles.caption}>
							第18條 積欠費用之處理
						</div>
						<div>
							您逾期未繳納分期付款應繳納之費用，如遲延給付之金額已達分期付款總金額五分之二者，本平台得定10日以上之催告期間請求您給付，逾期您仍未完全給付者，本平台得立即終止本產品使用權限，不再另行通知。
						</div>
						<div className={styles.caption}>第19條 終止與退費</div>
						<div>
							您於各課程使用期間屆滿時，本說明即告終止。
							除前項所約定之情形者外，您或您購買課程時所指定之人，未開始瀏覽及觀看課程內容者，得通知本平台終止課程，除有正當理由外，本平台不得拒絕終止，本平台將依以下方式，結算、撥付或返還本服務之授權使用費：
						</div>
						<div>
							<ol>
								<li>
									開課日前終止合約，應全額退還該課程您所支付之授權使用費金額。
								</li>
								<li>
									開課日後，自您購買課程之日起 7
									日內終止合約，應全額退還該課程您所支付之授權使用費金額。
								</li>
								<li>
									開課日後，自您購買課程之日起第 8 日至第 14
									日以內終止合約，應返還該課程您所支付之授權使用費金額
									30％。
								</li>
								<li>
									開課日後，自您購買課程之日起第 15
									日終止合約者，該課程您所支付之授權使用費金額不予退還。
								</li>
							</ol>
						</div>
						<div>
							您或您購買課程時所指定之人，已由裝置開始瀏覽及觀看課程內容者，視同我們已提供服務，該課程您所支付之授權使用費金額將不予退還。
						</div>
						<div className={styles.caption}>
							第20條 授權使用原則
						</div>
						<div>
							您有下列情形之一者，由您自負一切法律責任，本平台並得通知您終止使用本平台，您不得拒絕：
						</div>
						<div>
							<ol>
								<li>
									您蓄意散布干擾本平台系統正常運作之電腦程式，包括但不限於修改、重製、對於任何功能、程式進行還原工程
									（reverse
									engineering）、反向組譯（disassemble）或任何企圖取得原始碼或內容之行為等。
								</li>
								<li>
									您在本平台系統上散布恐嚇、毀謗、侵害他人隱私、色情或其他違反強制或禁止規定、公序良俗之文字、圖片或影像。
								</li>
							</ol>
						</div>
						<div>
							於前項情形，本平台有權自行認定並立即停止傳輸任何前述內容並採取相應行動，包括但不限於暫停您使用本服務之全部或部分、移除該內容或保存有關記錄等。
							您利用本服務從事其他不法或違反本說明約定事項之行為，其情節重大，且經本平台通知您限期3日改正而屆期未改正者，本平台得通知您並立即終止使用，您不得拒絕。
							我們依前項之約定終止使用者，不予退款，並得依前述行為導致之損失向您提出合理範圍之求償。
						</div>
						<div className={styles.caption}>
							第21條 不可抗力事件
						</div>
						<div>
							因不可抗力事件，致本平台無法履行服務者，雙方當事人之任何一方均得通知對方終止本約定。本約定終止後，本平台應按已使用服務時間之比例返還您相當於終止日前尚未使用之本服務授權使用費金額。
						</div>
						<div className={styles.caption}>第22條 申訴權利</div>
						<div>
							{`對於${setProjectName()}提供之本服務，您得以於本平台之官方Line@、Facebook聯繫提出申訴，亦可撥打本平台電話(客戶服務專線：02-8226-3699)、寄送電子郵件(客戶服務電子郵件：support@oneclass.tw)之方式提出申訴，我們將於受理申訴起兩週內妥善處理申訴案件。`}
						</div>
						<div className={styles.caption}>第23條 準據法</div>
						<div>
							因本約定所生之一切爭議，雙方當事人約定適用中華民國法律。
						</div>
						<div className={styles.caption}>第24條 管轄法院</div>
						<div>
							<ol>
								<li>
									若本服務條款中有任何條款經法院或主管機關宣告無效或無法執行，但不影響其他條款者，其餘條款仍為繼續有效。
								</li>
								<li>
									您知悉您使用本網站或本服務之行為，並不成立您與本公司間有任何建立任何合資、合夥、僱傭或代理關係。
								</li>
								<li>
									非經本公司書面同意或法律明文規定，您將不得轉讓您的權利或義務予第三人。
								</li>
								<li>
									您同意若有任何因本服務條款所生或相關之爭議，應以中華民國法律為準據法，並以臺灣臺北地方法院為第一審管轄法院。
								</li>
								<li>
									如依法或其他相關規定須為通知時，本網站得以包括但不限於：電子郵件、一般信件、簡訊、多媒體簡訊、文字訊息、張貼於本服務網頁，或其他現在或未來合理之方式通知您，包括本服務條款之變更。但若您違反本服務條款，以未經授權的方式存取本服務，您將不會收到前述通知。當您經由授權的方式存取本服務，而同意本服務條款時，您即同意本網站所為之任何及所有給您的通知，都視為送達。
								</li>
							</ol>
						</div>
						<div className={styles.caption}>當事人條款</div>
						<div>
							<div>立約定人：</div>
							<div>
								您在本平台登入、使用與購買本服務成功者（以下簡稱「會員」），您的個人會員資料與課程購買清單皆詳細皆紀錄於本系統中。
							</div>
							<div>
								{`公司：萬通教育股份有限公司（以下簡稱「${setProjectName()}」）`}
							</div>
							<div>電話：02-8226-3699</div>
							<div>
								客戶服務電子郵件：support@oneclass.tw
							</div>
							<div>
								營業所地址：100台北市中正區館前路28號4樓
							</div>
							<div>統一編號：90765548</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
export default ServiceView;
