import React, { useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const DialogModule = ({ open, setOpen, content, action, style = {} || null , ...otherProps }) => {
  useEffect(() => {
    //document.getElementsByClassName("MuiDialog-paper")[0].style.width="100%"
  }, [])
  return (
		<Dialog
			style={style}
			open={open}
			onClose={() => {
				setOpen && setOpen(false);
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			{...otherProps}
		>
			<DialogContent style={style}>{content}</DialogContent>
			{action && <DialogActions>{action}</DialogActions>}
		</Dialog>
  );
};

export default DialogModule;
