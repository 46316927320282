import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import Login from "components/Login";
import useSetState from "customHooks/setState";
import { useStore, StoreTypes } from "context";
import { checkProjectType } from "constants/projectsType";

const Footer = ({ path }) => {
	const [open, setOpen] = useState(false);
	const router = useStore(StoreTypes.router);

	const fb = () => {
		window.open("https://facebook.com/OneClass.live", "_blank");
	}
	const line = () => {
		window.open("https://line.me/R/ti/p/%40722gpywx", "_blank");
	}

	const serviceClick = useCallback(() => {
		router.history.push(`/ServiceView`);
	}, [router]);

	const aboutClick = useCallback(() => {
		window.open("https://www.oneclass.com.tw/", "_blank");
	}, []);

	const teacherServiceClick = useCallback(() => {
		window.open(
			"https://tutor.oneclass.com.tw/?utm_source=OneStudy_footer&utm_medium=landing_page&utm_campaign=OneStudy_%E9%80%B2%E5%85%A5%E9%A6%96%E9%A0%81",
			"_blank"
		);
	}, []);

	return (
		<footer>
			<div>
				{checkProjectType() && <div className={styles.linkTextContent}>
					<div
						className={styles.linkText}
						onClick={() => aboutClick()}
					>
						{`關於${checkProjectType() ? "OneClass" : "NaniStudy"}`}
					</div>
					<div
						className={styles.linkText}
						onClick={() => teacherServiceClick()}
					>
						家教服務
					</div>
					<div
						className={styles.linkText}
						onClick={() => serviceClick()}
					>
						服務條款
					</div>
				</div>}

				<div className={styles.copyright}>
					{`Copyright © 2022 ${checkProjectType() ? "ONECLASS" : "NANI"}`}
				</div>
				{checkProjectType() && <div className={styles.fblineIcons}>
					<div onClick={() => fb()}>
						<img src={"./assets/img/fb.png"} alt="fb"></img>
					</div>
					<div onClick={() => line()}>
						<img src={"./assets/img/line.png"} alt="line"></img>
					</div>
				</div>}
			</div>
		</footer>
	);
};
export default Footer;
