
function getAPIDomain() {
    var apiDomain = document.location.origin;
    if (apiDomain.indexOf("localhost") > 0) {
        apiDomain = "http://localhost:3000";
    }
    if (apiDomain.indexOf("yunlin") >-1) {
        if (apiDomain.indexOf("yunlin.onestudy-dev") >-1) {
            apiDomain = "https://asia-northeast1-yunlin-onestudy-dev-383bf.cloudfunctions.net";
        }
        else if (apiDomain.indexOf("yunlin.onestudy-uat") >-1) {
            apiDomain = "https://asia-northeast1-yunlin-onestudy-uat-59420.cloudfunctions.net";
        }
        else {
            apiDomain = "https://asia-northeast1-yunlin-onestudy-release.cloudfunctions.net";
        }
    } else if (apiDomain.indexOf("chiayi") >-1) {
        if (apiDomain.indexOf("chiayi.onestudy-dev") >-1) {
            apiDomain = "https://asia-northeast1-chiayi-onestudy-dev.cloudfunctions.net";
        }
        else if (apiDomain.indexOf("chiayi.onestudy-uat") >-1) {
            apiDomain = "https://asia-northeast1-chiayi-onestudy-uat-97d49.cloudfunctions.net";
        }
        else {
            apiDomain = "https://asia-northeast1-chiayi-onestudy-release.cloudfunctions.net";
        }
    } else if (apiDomain.indexOf("onestudy") > -1) {
        if (apiDomain.indexOf("dev") >-1) {
            apiDomain = "https://asia-northeast1-onestudy-dev-ce9b0.cloudfunctions.net";
        }
        else if (apiDomain.indexOf("uat") >-1) {
            apiDomain = "https://asia-northeast1-onestudy-uat.cloudfunctions.net";
        }
        else {
            apiDomain = "https://asia-northeast1-onestudy-release.cloudfunctions.net";
        }
    } else if (apiDomain.indexOf("nanistudy") >-1) {
        if (apiDomain.indexOf("nanistudy-dev") >-1) {
            apiDomain = "https://asia-east1-onestudyedu-dev.cloudfunctions.net";
        }
        else if (apiDomain.indexOf("nanistudy-uat") >-1) {
            apiDomain = "https://asia-east1-nanistudy-uat-babd2.cloudfunctions.net";
        }
        else {
            apiDomain = "https://asia-east1-nanistudy-release.cloudfunctions.net";
        }
    } 
    return apiDomain;
}

function findExams(userId) {
    return new Promise((resolve, reject) => {
        var apiDomain = getAPIDomain();
        var data = {
            userId: userId
        };

        fetch(`${apiDomain}/exam/get`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-type": "application/json; charset=UTF-8" }
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                if (response.status == "success") {
                    resolve(response.data);
                }
                else {
                    reject(response.error.message);
                }
            })
            .catch(function (err) {
                reject(err.message ? err.message : err);
            });
    });
}

export const userExams = (userId) => {
    return new Promise(async (resolve, reject) => {
        try {
            // find exam
            const examData = await findExams(userId);
            resolve(examData);
        }
        catch (err) {
            reject(err.message ? err.message : err);
        }
    });
}