import React, { useState, useCallback, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import useSetState from "customHooks/setState";
import { useStore, StoreTypes } from "context";
import * as actionTypes from "constants/actionTypes";
import DialogModule from "components/Dialogs/";
import classnames from "classnames";
import { useLogin } from "util/login";
import { checkProjectType } from "constants/projectsType";
import { ExchangeContent } from "components/ExchangeContent";
import { useOutsideAlerter } from "customHooks/outsideAlerter";
import { width } from "styled-system";

const Login = ({ open, setOpen }) => {
  const ref = useRef();
  const router = useStore(StoreTypes.router);
  const [{ token, userId, name, isLogin }, userDispatch] = useStore(
    StoreTypes.user
  );
  const [isUserList, setIsUserList] = useState(false);
  const [{ isClassSeletPannel, nowHeadSelectListName }, globalDispatch] =
    useStore(StoreTypes.global);
  const { logoutNaniOneClass } = useLogin();
  const [isExchange, setIsExchange] = useState(false);

  const login = useCallback(() => {
    // console.log("----name---", name, isLogin);
    if (isLogin) {
      router.history.push("/")
      userDispatch({
        type: actionTypes.SET_USER_INFO,
        name: "",
        isLogin: false,
        token: "",
        userId: "",
        product: null,
        isOneLiveMember: null,
        oneLiveMemberCloseTime: null,
      });
      logoutNaniOneClass();
    } else {
      setOpen(!open);
    }
  }, [name, userDispatch, logoutNaniOneClass, open, setOpen, isLogin]);

  const setClose = () => {
    setIsUserList(false);
  };
  useOutsideAlerter(ref, setClose);

  useEffect(() => {});
  const showList = useCallback(() => {
    setIsUserList(!isUserList);
  }, [isUserList]);

  const goClass = () => {
    globalDispatch({ type: actionTypes.SET_VIDEO_LIST, videoList: [] });
    globalDispatch({
      type: actionTypes.SET_NOW_HEAD_LIST_SELECT_NAME,
      nowHeadSelectListName: "我的課程",
    });
    router.history.push(`/PurchaseListView`);
  };
  const goPurchse = () => {
    globalDispatch({
      type: actionTypes.SET_NOW_HEAD_LIST_SELECT_NAME,
      nowHeadSelectListName: "我的訂單",
    });
    router.history.push(`/MyOrders`);
  };
  const goExchange = useCallback(() => {
    setIsExchange(true);
  }, [setIsExchange]);

  const setNaniStyle = () => {
    if (!checkProjectType() && router.location.pathname === "/NaniMainPage") {
      return "nanimainpage";
    } else if (
      !checkProjectType() &&
      router.location.pathname === "/ClassView"
    ) {
      return "naniclassview";
    } else if (!checkProjectType() &&
    router.location.pathname === "/ProductView"){
      return "naniproductview"
    }
  };
  
  return (
    <div ref={ref} className={styles.userInfoArea}>
      <DialogModule
        style={{ "&<width": "100%" }}
        DialogModule
        open={isExchange}
        setOpen={setIsExchange}
        content={<ExchangeContent setIsExchange={setIsExchange} />}
      />
      {isLogin ? (
        <div className={styles.userInfoContent}>
          <div className={styles.userIcon}>
            <div className={styles.nowListText}>
              <div>{nowHeadSelectListName}</div>
            </div>
            <div className={styles.userBtn} onClick={() => showList()}>
              <img src="./assets/img/Avator.png" alt="" />
            </div>
          </div>
          {isUserList && (
            <div className={styles.userList}>
              <div>
                <a className={styles.name}> {name? name: userId}</a>
              </div>
              <div onClick={() => goClass()}>
                <a>我的課程</a>
              </div>
              <div onClick={() => goPurchse()}>
                <a>我的訂單</a>
              </div>
              <div onClick={() => goExchange()}>
                <a>兌換課程</a>
              </div>
              <div onClick={() => login()}>
                <a>登出</a>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={classnames(styles.loginBtn, styles[setNaniStyle()])}
          onClick={() => login()}
        >
          {
            // console.log(!checkProjectType())
          }
          <div>
            {setNaniStyle() ? (
              "登入/註冊"
            ) : (
              <img src="./assets/img/Enabled.png" alt="" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Login;
