import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import { timeFormatTommss } from 'util/timeFormater';

const VideoRecord = ({ videoRecodeList }) => {
	const [listLength, setListLength] = useState(0);
	const [count, setCount] = useState(0);

	useEffect(() => {
		if (!videoRecodeList) return;
		setListLength(videoRecodeList.length);
	}, [videoRecodeList]);

	const getVideoRecode = useCallback(
		(list) => {
			const lists = [];
			for (let i = count; i < count + 5; i++) {
				const info = list[i];
				if (info) {
					const time = info.time !== 0 ? Math.round(info.time) : info.time;
					const updateDate = window
						.moment(info.timestamp)
						.format("YYYY/MM/DD HH:mm");;
					const duration = info.duration
						? info.duration !== 0
							? Math.round(info.duration)
							: info.duration
						: 0;
					lists.push(
						<div key={i} className={styles.list}>
							<div className={styles.timestamp}>{updateDate}</div>
							<div className={styles.name}>{info.name}</div>
							<div
								className={styles.time}
							>{`${timeFormatTommss(time)}/${timeFormatTommss(duration)}`}</div>
						</div>
					);
				} else {
					lists.push(
						<div key={i} className={styles.list}>
							<div className={styles.timestamp}></div>
							<div className={styles.name}></div>
							<div className={styles.time}>{"　"}</div>
						</div>
					);
				}
			}

			return lists;
		},
		[count]
	);

	const prevBtn = useCallback(() => {
		const prevCount = count - 5 <= 0 ? 0 : count - 5;
		setCount(prevCount);
	}, [count]);

	const nextBtn = useCallback(() => {
		const nextCount = count + 5 > listLength ? count : count + 5;
		setCount(nextCount);
	}, [count, listLength]);

	return (
		<div className={styles.recode}>
			<div className={styles.control}>
				<div className={styles.title}>
					<div>
						<img src="./assets/img/Vector.svg" alt="" />
					</div>
					<div>影片觀看紀錄</div>
				</div>
				<div className={styles.searchBtn}>
					<div
						className={classnames(styles.left, {
							[styles.hide]: count === 0,
						})}
						onClick={() => prevBtn()}
					>
						{"<"}
					</div>
					<div
						className={classnames(styles.right, {
							[styles.hide]: count + 5 > listLength,
						})}
						onClick={() => nextBtn()}
					>
						{">"}
					</div>
				</div>
			</div>

			<div className={styles.videoRecodList}>
				<div className={styles.tableText}>
					<div className={styles.timestamp}>觀看時間</div>
					<div className={styles.name}>影片名稱</div>
					<div className={styles.time}>觀看進度</div>
				</div>
				{videoRecodeList && getVideoRecode(videoRecodeList)}
			</div>
		</div>
	);
};
export default VideoRecord;
