import React, { useState, useCallback, useEffect } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import { API } from "../../api/index";
import { useStore, StoreTypes } from "context";
import { useLogin } from "util/login";
import * as actionTypes from "constants/actionTypes";
import Loading from "components/Loading";

export const ExchangeContent = ({ setIsExchange }) => {
	const { sign, getUser, getToken } = useLogin();
	const [{ token, userId }, userDispatch] = useStore(StoreTypes.user);
	const router = useStore(StoreTypes.router);
	const [titelText, setTitleText] = useState("領取VIP專屬優惠課程");
	const [onClass, setOnClass] = useState(false);
	const [isExchangeClick, setIsExchangeClick] = useState(true);
	const [codeIsRight, setCodeIsRight] = useState(true);
	const [productList, setProductList] = useState(null);
	const [inputType, setInputType] = useState("NOMAL");

	useEffect(() => {
		const code0 = document.getElementById("code0");
		if (!code0) return;
		code0.focus();

		code0.addEventListener("paste", (event) => {
			let paste = (event.clipboardData || window.clipboardData).getData(
				"text"
			);
			let pasteArr = paste.split("");
			for (var i = 0; i < 5; i++) {
				const codeInput = document.getElementById(`code${i}`);
				const value = pasteArr[i]
				codeInput.value = value
			}
			// const selection = window.getSelection();
			// if (!selection.rangeCount) return false;
			// selection.deleteFromDocument();
			// selection.getRangeAt(0).insertNode(document.createTextNode(paste));

			// event.preventDefault();
		});
	}, []);

	const onCancel = useCallback(() => {
		setIsExchange(false);
	}, [setIsExchange]);

	const onConsume = useCallback(async () => {
		try {
			if (!userId) {
				//alert("need to login first");
			} else {
				var orderId = "";
				for (var i = 0; i < 5; i++) {
					const uiCode = document.getElementById(`code${i}`);
					orderId += uiCode.value;
				}
				if (orderId.length == 5) {
					setIsExchangeClick(false)
					const url = `${process.env.REACT_APP_API_DOMAIN}/order/consumeCode`;
					const data = {
						orderId: orderId, //code of exchanging product,
						userId: userId, // userId
					};
					const result = await API.post(url, data,token);
					const productList = [];
					if (result.status === "success") {
						for (var i = 0; i < result.data.items.length; i++) {
							productList.push({
								code: result.data.items[i].code,
								name: result.data.items[i].product,
							});
						}
						const source = process.env.REACT_APP_PROJECT.includes('yunlin') ? 'yunlin' : 'general';
						await getUser({token, getExpiredProduct: true, source})
							.then((response) => {
								userDispatch({
									type: actionTypes.SET_USER_INFO,
									product: response.product,
								});
								setProductList(productList);
								setTitleText("您的VIP專屬優惠課程領取成功！");
								setOnClass(true);
								setCodeIsRight(true);
								setInputType("RIGHT");
							})
							.catch((err) => {
								console.log(err);
							});
					} else {
						//alert(result.error.message);
						setCodeIsRight(false);
						setInputType("WRONG");
						setIsExchangeClick(true);
					}
				} else {
					//alert("invalid code");
				}
			}
		} catch (err) {
			//alert(err.message ? err.message : err);
		}
	}, [userId, isExchangeClick,token]);

	const goPurchaseListView = useCallback(() => {
		if (router.location.pathname === '/PurchaseListView') {
			setIsExchange(false);
			router.history.go(0)
		} else {
			router.history.push(`/PurchaseListView`);
		}
	}, [router]);

	const getExchangeClass = useCallback(() => {
		if (!productList) return;
		const arr = [];
		productList.map((info, index) => {
			arr.push(
				<div className={styles.list}>
					{index + 1 + "." + info.name}
				</div>
			);
			return null;
		});
		return arr;
	}, [productList]);

	const setInputTypeFunc = useCallback(() => {
		if (inputType === "NOMAL") {
			return isExchangeClick ? <div>請輸入5位數課程領取碼</div> : <div></div>;
		} else if (inputType === "WRONG") {
			return isExchangeClick ? <div>該序號不存在，請提供您此序號的學習顧問聯絡</div> : <div></div>;
		} else if (inputType === "RIGHT") {
			return (
				<div className={styles.hint}>
					<img src="./assets/img/Vector6.svg" alt="" />
					<div>
						未來請前往「我的課程」中努力學習，期待您的成績大幅進步唷！
					</div>
				</div>
			);
		}
	}, [inputType, isExchangeClick]);

	const inputChange = (e) => {
		const nextId = parseInt(e.target.id.substr(4, 5)) + 1;
		const inputTarget = document.getElementById("code" + nextId);
		inputTarget.focus();
	}

	return (
		<div className={styles.exchangeContent}>
			<div className={styles.close} onClick={() => onCancel()}>
				<div>X</div>
			</div>
			<div className={styles.titleText}>
				<div>{titelText}</div>
			</div>
			{!onClass ? (
				isExchangeClick ? (
					<div className={styles.inputContent}>
						<div
							className={classnames(styles.inputs, {
								[styles.wrong]: !codeIsRight,
							})}
						>
							<input
								id="code0"
								maxlength="1"
								minlength="1"
								autofocus
								onChange={(e) => inputChange(e)}
							></input>
						</div>
						<div
							className={classnames(styles.inputs, {
								[styles.wrong]: !codeIsRight,
							})}
						>
							<input
								id="code1"
								maxlength="1"
								minlength="1"
								onChange={(e) => inputChange(e)}
							></input>
						</div>
						<div
							className={classnames(styles.inputs, {
								[styles.wrong]: !codeIsRight,
							})}
						>
							<input
								id="code2"
								maxlength="1"
								minlength="1"
								onChange={(e) => inputChange(e)}
							></input>
						</div>
						<div
							className={classnames(styles.inputs, {
								[styles.wrong]: !codeIsRight,
							})}
						>
							<input
								id="code3"
								maxlength="1"
								minlength="1"
								onChange={(e) => inputChange(e)}
							></input>
						</div>
						<div
							className={classnames(styles.inputs, {
								[styles.wrong]: !codeIsRight,
							})}
						>
							<input
								id="code4"
								maxlength="1"
								minlength="1"
							></input>
						</div>
					</div>
				) : (
					<Loading />
				)
			) : (
				<div className={styles.productList}>
					<div>本次領取課程：</div>
					{getExchangeClass()}
				</div>
			)}
			<div
				className={classnames(styles.inputMessage, {
					[styles.wrong]: !codeIsRight,
				})}
			>
				{setInputTypeFunc()}
			</div>
			<div></div>
			<div className={styles.exchangeBtnsContent}>
				{onClass ? (
					<div
						className={styles.onClass}
						onClick={() => goPurchaseListView()}
					>
						開始上課
					</div>
				) : (
					<div className={styles.exchangeBtns}>
						<div
							className={styles.cancel}
							onClick={() => onCancel()}
						>
							取消
						</div>
						{isExchangeClick ? (
							<div
								className={styles.ok}
								onClick={() => onConsume()}
							>
								領取
							</div>
						) : (
							<div className={styles.ok2}>領取</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
