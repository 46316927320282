import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import Header from "components/Header";
import Footer from "components/Footer";
import { useLocation } from "react-router-dom";
import { Beforeunload } from 'components/Beforeunload';
import { useStore, StoreTypes } from "context";
import { userOrders } from '../../util/orders';
import ParamsStorer from "../../util/paramsStorer";

var productCode = null; //110_00_01_E_C
var volume = null; //國小一上
var subject = null; //國語首冊

const PurchaseSuccess = () => {
  const router = useStore(StoreTypes.router);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const userId = query.get("userId");
  const orderId = query.get("orderId");

  //===========================================================================
  useEffect(() => {
    componentDidMount();
    return componentWillUnmount;
  }, []);
  //===========================================================================

  function componentDidMount() {
    window.addEventListener('resize', onResize);
    onResize();

    // hide info
    const uiInfos = document.getElementById('infos');
    uiInfos.style.display = "none";
    // delay for firestore finish updating order status
    setTimeout(function () {
      userOrders(userId)
        .then(function (orders) {
          var orderData = null;
          for (var i = 0; i < orders.length; i++) {
            if (orders[i].orderId == orderId) {
              orderData = orders[i];
              break;
            }
          }

          if (orderData && orderData.status == "purchased") {
            const items = orderData.items;
            if (items.length > 0) {
              productCode = items[0].code;
              volume = orderData.course;

              for (var j = 0; j < items.length; j++) {
                const itemName = items[j].name
                const index = itemName.indexOf(volume);
                if (index > 0) {
                  subject = itemName.substr(index + volume.length);
                  break;
                }
              }
              // assign course
              const uiCourse = document.getElementById('course');
              if (uiCourse) uiCourse.innerHTML = volume;
            }
            // show info
            uiInfos.style.display = "";
          }
          else {
            // goto failed page
            router.history.push('/PurchaseFailed');
          }
        })
        .catch(function (err) {
          console.log(`PurchaseSuccess gotoMyCourses got ${err.message ? err.message : err}`);
          // goto failed page
          router.history.push('/PurchaseFailed');
        });
    }, 200);
  }

  function componentWillUnmount() {
    window.removeEventListener('resize', onResize);
  }

  function onResize() {
    const deviceWidth = window.innerWidth;
    const deviceHeight = window.innerHeight;
    const headerHeight = 80;
    const footerHeight = 150;
    const infoWidth = 300;
    const infoHeight = 300;
    var mainAreaHeight = deviceHeight - headerHeight - footerHeight;
    if (mainAreaHeight < infoHeight) mainAreaHeight = infoHeight;

    const uiMainArea = document.getElementById('mainArea');
    if (uiMainArea) {
      uiMainArea.style.height = `${mainAreaHeight}px`;
    }

    const uiInfos = document.getElementById('infos');
    if (uiInfos) {
      uiInfos.style.margin = `0 ${(deviceWidth - infoWidth) / 2}px`
      uiInfos.style.padding = `${(mainAreaHeight - infoHeight) / 2}px 0`;
    }
  }

  const gotoMyCourses = () => {
    if (productCode && volume && subject)
      router.history.push(`/ClassView?productCode=${productCode}&volume=${volume}&subject=${subject}`);
    else
      router.history.push('/PurchaseListView');
  }

  return (
    <Beforeunload>
      <div className={styles.PurchaseSuccess}>
        <Header />
        <div id="mainArea" className={styles.mainArea}>
          <div id="infos">
            <div>
              <img src="./assets/img/paySuccess.svg" />
            </div>
            <div style={{ color: '#4CAF50' }}>付款成功</div>
            <br></br>
            <div>
              <div style={{ textAlign: "left" }}>您已完成 <span id='course'></span>全科課程 付款，請前往「我的課程」中努力學習，期待您的成績大幅進度唷！</div>
            </div>
            <br></br>
            <button className={styles.btnBack} onClick={() => gotoMyCourses()}>前往我的課程</button>
          </div>
        </div>
        <Footer />
      </div>
    </Beforeunload>
  )
}

export default PurchaseSuccess;