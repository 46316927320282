export const API = {
	get: (url, data, token) => {
		const headers = {
			Authorization: token ? token : "",
		};
		return fetch(url, {
			method: "GET",
			headers
		})
			.then((response) => response.json())
			.catch((err) => console.error(err));
	},
	post: (url, data, token) => {
		const body = data ? JSON.stringify(data) : {};
		const headers = {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: token ? token : "",
		};
		return fetch(url, {
			method: "POST",
			headers,
			body,
		})
			.then((response) => response.json())
			.catch((err) => console.error(err));
	},
	domain: () => {
		var apiDomain = document.location.origin;

		if (apiDomain.indexOf("yunlin") >-1) {
            if (apiDomain.indexOf("yunlin.onestudy-dev") >-1) {
                apiDomain = "https://asia-northeast1-yunlin-onestudy-dev-383bf.cloudfunctions.net";
            }
            else if (apiDomain.indexOf("yunlin.onestudy-uat") >-1) {
                apiDomain = "https://asia-northeast1-yunlin-onestudy-uat-59420.cloudfunctions.net";
            }
            else {
                apiDomain = "https://asia-northeast1-yunlin-onestudy-release.cloudfunctions.net";
            }
        } else if (apiDomain.indexOf("chiayi") >-1) {
            if (apiDomain.indexOf("chiayi.onestudy-dev") >-1) {
                apiDomain = "https://asia-northeast1-chiayi-onestudy-dev.cloudfunctions.net";
            }
            else if (apiDomain.indexOf("chiayi.onestudy-uat") >-1) {
                apiDomain = "https://asia-northeast1-chiayi-onestudy-uat-97d49.cloudfunctions.net";
            }
            else {
                apiDomain = "https://asia-northeast1-chiayi-onestudy-release.cloudfunctions.net";
            }
        } else if (apiDomain.indexOf("onestudy") > -1) {
            if (apiDomain.indexOf("dev") >-1) {
                apiDomain = "https://asia-northeast1-onestudy-dev-ce9b0.cloudfunctions.net";
            }
            else if (apiDomain.indexOf("uat") >-1) {
                apiDomain = "https://asia-northeast1-onestudy-uat.cloudfunctions.net";
            }
            else {
                apiDomain = "https://asia-northeast1-onestudy-release.cloudfunctions.net";
            }
        } else if (apiDomain.indexOf("nanistudy") >-1) {
            if (apiDomain.indexOf("nanistudy-dev") >-1) {
                apiDomain = "https://asia-east1-onestudyedu-dev.cloudfunctions.net";
            }
            else if (apiDomain.indexOf("nanistudy-uat") >-1) {
                apiDomain = "https://asia-east1-nanistudy-uat-babd2.cloudfunctions.net";
            }
            else {
                apiDomain = "https://asia-east1-nanistudy-release.cloudfunctions.net";
            }
        } 
		return apiDomain;
	}
};
