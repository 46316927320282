export const toHalfWidthChars = (chars) => {
    let halfWidthChars = '';
    for(let i=0, l=chars.length; i<l; i++) {
        let c = chars[i].charCodeAt(0);
        if (c >= 0xFF00 && c <= 0xFFEF) {
            c = 0xFF & (c + 0x20);
        }
        halfWidthChars += String.fromCharCode(c);
    }
    return halfWidthChars;
  }
  