import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import Dexie from 'dexie';

const db = new Dexie('annotation');
const readerToolSettingsDB = new Dexie('readerToolSettings');
const usersDB = new Dexie('users');

db.version(1).stores({
    annotation: '&id, bookId, type, isDirty',
});

readerToolSettingsDB.version(1).stores({
    readerToolSettings: '&id'
});

usersDB.version(1).stores({
    userSettings: '&id'
});

const initState = {
    db,
    readerToolSettingsDB,
    usersDB
};

const DBReducer = (state, action) => {
    return state;
};

const useDBReducer = () => useEnhanceReducer(DBReducer, initState);

export default useDBReducer;
