import { useEnhanceReducer } from "customHooks/enhanceReducer";
import * as types from "constants/actionTypes";
// import { constants } from 'original-fs';

export const initState = {
  userId: "",
  name: "",
  isLogin: false,
  //role: Roles.GUEST,
  token: null,
  isInitialized: false,
  product: null,
  education: null,
  isTeacher: null,
  isOneLiveMember: null,
};

const updateUserInfo = (state, action) => {
  const {
    userId = state.userId,
    name = state.name,
    role = state.role,
    isLogin = state.isLogin,
    token = state.token,
    isInitialized = state.isInitialized,
    product = state.product,
    isTeacher = state.isTeacher || null,
    education = state.education || null,
    isOneLiveMember = state.isOneLiveMember || false,
    oneLiveMemberCloseTime = state.oneLiveMemberCloseTime || null,
    schoolName = state.schoolName || null,
    isAdmin = state.isAdmin || null,
  } = action;
  return {
    ...state,
    userId,
    name,
    role,
    isLogin,
    token,
    isInitialized,
    product,
    isTeacher,
    education,
    isOneLiveMember,
    oneLiveMemberCloseTime,
    schoolName,
    isAdmin,
  };
};

const userReducer = (state, action) => {
  switch (action.type) {
    case types.SET_USER_INFO:
      return updateUserInfo(state, action);
    default:
      return state;
  }
};

const useUserReducer = () => useEnhanceReducer(userReducer, initState);

export default useUserReducer;
