import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import Header from "components/Header";
import { Beforeunload } from "components/Beforeunload";
import { useStore, StoreTypes } from "context";
import { Accordion, AccordionItem } from "react-sanfona";
import { IMAGES } from "constants/imagePath";
import { SUBJECT_TYPE } from "constants/subjectType";
import { dateFormat } from "util/dateFormat";
import Loading from "components/Loading";
import * as types from "constants/actionTypes";
import moment from "moment";

const PURCHASE_STATE = {
  NOT_PURCHASE: "NOT_PURCHASE",
  PURCHASE_MMS: "PURCHASE_MMS",
  PURCHASE_ONESTUDY: "PURCHASE_ONESTUDY",
  EXPIRED_MMS: "EXPIRED_MMS",
  EXPIRED_ONESTUDY: "EXPIRED_ONESTUDY"
}

const checkTime = ({isOneLiveMember, oneLiveMemberCloseTime, purchaseTime,education}) => {

  const isOneStudyExpired = moment.unix(purchaseTime).isBefore(moment());
  if (process.env.REACT_APP_PROJECT === 'onestudy'){
  if (education === '主題式') {
    return isOneStudyExpired ? {state: PURCHASE_STATE.EXPIRED_ONESTUDY, expiredTime: purchaseTime} : {state: PURCHASE_STATE.PURCHASE_ONESTUDY, expiredTime: purchaseTime}
  } else {
    if (!purchaseTime){
      if (isOneLiveMember){
        return {state: PURCHASE_STATE.PURCHASE_MMS, expiredTime: 9999999999}
      } else {
        return {state: PURCHASE_STATE.EXPIRED_MMS, expiredTime: oneLiveMemberCloseTime}
      }
    }else {
      if (!isOneLiveMember && oneLiveMemberCloseTime){
  const closedTimePurchaseTimeComparision = moment // OneStudy 過期時間是否比 Live 關閉時間晚
    .unix(purchaseTime)
    .isAfter(moment.unix(oneLiveMemberCloseTime));
      return closedTimePurchaseTimeComparision ? {state: PURCHASE_STATE.EXPIRED_ONESTUDY, expiredTime: purchaseTime} : {state: PURCHASE_STATE.EXPIRED_MMS, expired: oneLiveMemberCloseTime}
      } else if (!isOneLiveMember && !oneLiveMemberCloseTime){
        return isOneStudyExpired ? {state: PURCHASE_STATE.EXPIRED_ONESTUDY, expiredTime: purchaseTime} : {state: PURCHASE_STATE.PURCHASE_ONESTUDY, expiredTime: purchaseTime}
      } else if (isOneLiveMember) {
        return {state: PURCHASE_STATE.PURCHASE_MMS, expiredTime: 9999999999}
      }
  }
    }
  } else {
    if (!purchaseTime) return {state: PURCHASE_STATE.NOT_PURCHASE, expiredTime: null}
    return isOneStudyExpired ? {state: PURCHASE_STATE.EXPIRED_ONESTUDY, expired: purchaseTime} : {state: PURCHASE_STATE.PURCHASE_ONESTUDY, expiredTime: purchaseTime}
  }
}

const checkImage = (data) => {
  const imgType = SUBJECT_TYPE[data.subject];
  if (
    data.education === "國中" ||
    data.education === "國小" ||
    data.education === "高中"
  ) {
    const pathContent = `${data.education}${data.VolumeName}_${imgType}`.trim()
    return IMAGES.PRODUCT_ITEM[pathContent];
  } else {
    const pathContent = `${data.course}_${data.subject}`.trim()
    if (process.env.REACT_APP_PROJECT === 'nanistudy') {
      return IMAGES.NANI_PRODUCT_C[pathContent];
    } else {
      return IMAGES.PRODUCT_ITEM[pathContent];
    }
  }
}

export const ProductAccordionCard = (props) => {
  const [{isOneLiveMember, oneLiveMemberCloseTime }] = useStore(StoreTypes.user);
  const [{},globalDispatch] = useStore(StoreTypes.global);
  const router = useStore(StoreTypes.router);
  const {product, purchaseTime,subject,id,VolumeName,education} = props.info
  const purchaseState = checkTime({isOneLiveMember,oneLiveMemberCloseTime, purchaseTime,education})
  if (purchaseState.state === PURCHASE_STATE.NOT_PURCHASE) return null
  const expiredTime = () => {
    switch (purchaseState.state){
      case PURCHASE_STATE.PURCHASE_MMS:{
        return "家教會員專用"
      }
      case PURCHASE_STATE.PURCHASE_ONESTUDY:{
        return `使用期限：${dateFormat(purchaseState.expiredTime, "yyyy/MM/DD hh:mm:ss")}`
      }
      case PURCHASE_STATE.EXPIRED_MMS:{
        return `使用期限：${dateFormat(purchaseState.expiredTime, "yyyy/MM/DD hh:mm:ss")}`
      }
      case PURCHASE_STATE.EXPIRED_ONESTUDY:{
        return `使用期限：${dateFormat(purchaseState.expiredTime, "yyyy/MM/DD hh:mm:ss")}`
      }
      default:
      return ""
    }
  }
  const goClassViewHandler = () => {
      globalDispatch({ type: types.SET_PRODUCT_INFO, productInfo: props.info });
    router.history.push(
      `/ClassView?productCode=${id}&volume=${VolumeName}&subject=${subject}&expired=${purchaseState.expiredTime}`
    );
  };
  checkImage(props.info)
  return <div
  className={styles.item}
  onClick={goClassViewHandler}
>
  <div className={styles.itemImg}>
    <img src={checkImage(props.info)} alt="" />
  </div>
  <div className={styles.text}>{product}</div>
  <div className={styles.purchaseTime}>{expiredTime()}</div>
</div>
}

export const ProductAccordion = (props) => {
  const [{product }] = useStore(StoreTypes.user);
  const content = props.data.map((item) => {
    const purchaseInfo = product.find((product) =>  product.productCode === item.id)
    if(purchaseInfo){
      return {...item, purchaseTime:  purchaseInfo.timestamp}
    }
return ""
  })

  const gradeCheck = () => {
    if (content.length === 0) return 
    const grade = content[0] && content[0].id.split("_")[2]
    const notGradeList = ["主題式教師專區數學雙語","主題式教師專區自然雙語"]
    return grade === "00" || notGradeList.find(item => item === props.course) ? "": "年級"
  }

  if (content.every(item => item === "")) return null
  return <Accordion className={styles.tree1}>
  <AccordionItem
    title={
      <div className={styles.itemTitle}>
        {props.course}{gradeCheck()}
      </div>
    }
    className={styles.classContent}
  >
    {content.filter(Boolean).map(item => <ProductAccordionCard info={item} key={item.id}/>)}
  </AccordionItem>
</Accordion>
}



const PurchaseListView = () => {
  const [{  product }] =
    useStore(StoreTypes.user);
  const [{ educationInfo }] = useStore(StoreTypes.global);
  const [treeInfo, setTreeInfo] = useState(null);
  const [allProduct, setAllProduct] = useState(null)

  useEffect(() => {
    (async () => {
      if (!product) return;
      if (!educationInfo) return;
      const target = Object.values(educationInfo)
      const target2 = target.map(item => Object.entries(item))
      const target3 = target2.flat()
      const target4 = target3.map(item => item[1])

      const allCourseName = target4.flat().sort((a, b) => {
        const yearAndSemesterA = `${a.education === '主題式' ? 1 : 2}${a.id.split('_')[0]}${a.id.split('_')[2]}`
        const yearAndSemesterB = `${b.education === '主題式' ? 1 : 2}${b.id.split('_')[0]}${b.id.split('_')[2]}`
        return yearAndSemesterB - yearAndSemesterA
      }).map(item => item.course)

      const orderedProductList = Array.from(new Set(allCourseName))
      setAllProduct(target4.flat())
      setTreeInfo(orderedProductList);
    })();
  }, [product, educationInfo]);

  return (
    <Beforeunload>
      <div className={styles.purchaseListView}>
        <div className={styles.header}>
          <Header />
        </div>
        <div className={styles.treeContent}>
          <div className={styles.myClass}>我的課程</div>
          <div>{treeInfo && allProduct ? treeInfo.map(item => {
            return <ProductAccordion course={item} data={allProduct.filter(data => data.course === item)} key={item}/>
          }
          ) 
          : <Loading />}</div> 
        </div>
      </div>
    </Beforeunload>
  );
};
export default PurchaseListView;
