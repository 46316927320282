import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from "./index.module.scss";
import OrderLoading from 'components/Loading/OrderLoading';
import { useState, useCallback, useEffect } from 'react';
import { useStore, StoreTypes } from "context";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';

import {
	Select,
	MenuItem,
} from "@mui/material/";

let codeUsageStart = null
let codeUsageEnd = null
let durationValue = 366

const limitType={
	"是":true,
	"否":false
}

const SelectOrderCode = (props) => {
	const { naniProducts } = props
	const [{ token, userId, name, isLogin }, userDispatch] = useStore(StoreTypes.user);
	const params = (new URL(document.location)).searchParams;
	const agentId = params.get('id');
	const agentName = params.get('name');
	const [productsList, setProducts] = useState(naniProducts)
	const [purchasedProducts, setPurchasedProducts] = useState([]);
	const [enterOrderId, setEnterOrderId] = useState('');
	const [enterPhone, setEnterPhone] = useState('');
	const [productCodes, setProductCode] = useState([]);
	const [loading, setLoading] = useState(false)
	const [startTime, setStartTime] = useState(dayjs());
	const [endTime, setEndTime] = useState(dayjs().add(1, 'year'));
	const [usageDurationValue, setUsageDurationValue] = useState(366);

	const [remain, setRemain] = useState(1)
	const [personalLimit, setPersonalLimit] = useState(1)
	const [isLimitPersonal, setIsLimitPersonal] = useState("是")
	const [isLimitTotal, setIsLimitTotal] = useState("是")

	codeUsageStart = startTime
	codeUsageEnd = endTime
	let productCodeNum = 1;
	let getProductCodeCount = 1;
	let getProductCodes = [];

	useEffect(() => {
		if (naniProducts) {
			setProducts(naniProducts)
		}
	}, [naniProducts])

	const change = useCallback((e) => {
		if (e.target.value) {
			if (e.target.value.length > 0) {
				var keyWords = e.target.value.split(';');
				const list = []
				naniProducts.forEach((info) => {
					for (var j = 0; j < keyWords.length; j++) {
						if (info.product.indexOf(keyWords[j]) >= 0) {
							list.push(info)
						}
					}

				})
				setProducts(list)
			}
		} else {
			setProducts(naniProducts)
		}
	}, [naniProducts])

	const addProductsHandler = useCallback((e, products) => {
		const hasProduct = purchasedProducts.some((info) => info.product === products.product)
		!hasProduct && setPurchasedProducts([...purchasedProducts, products])
	}, [purchasedProducts])

	const removeProductsHandler = useCallback((e, products) => {
		const hasProduct = purchasedProducts.some((info) => info.product === products.product)
		let arr = []
		arr = arr.concat(purchasedProducts)
		if (hasProduct) {
			arr = arr.filter((info) => info.product !== products.product)
		} else {
			arr.push(products)
		}
		arr.sort()
		setPurchasedProducts(arr)
	}, [purchasedProducts])

	const selectAllProduct = useCallback(() => {
		const arr = productsList.filter((info) => {
			return !purchasedProducts.find((products) => products.product === info.product)
		})
		console.log(arr);
		const newList = [...purchasedProducts, ...arr]
		newList.sort((a, b) => a.codes > b.codes)
		setPurchasedProducts([...purchasedProducts, ...arr])
	}, [purchasedProducts, productsList])

	const createExchangeCode = useCallback(({ agentId, agentName, userOrderId, userPhone, items,remain,personalLimit,isLimitPersonal,isLimitTotal }) => {
		return new Promise((resolve, reject) => {
			// proceed calling API createVideoOrder

			fetch(`${process.env.REACT_APP_API_DOMAIN}/order/createCode`, {
				method: "POST",
				body: JSON.stringify({
					agentId: agentId,
					agentName: agentName,
					userOrderId: userOrderId,
					userPhone: userPhone,
					items: items,
					codeUsageStart: codeUsageStart,
					codeUsageEnd: codeUsageEnd,
					usageDurationValue: durationValue,
					available:{
						remain,
						personalLimit,
						isLimitPersonal:limitType[isLimitPersonal],
						isLimitTotal:limitType[isLimitTotal],
					}
				}),
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					"Authorization": token
				}
			})
				.then(function (response) {
					return response.json();
				})
				.then(function (response) {
					if (response.status == "success") {
						resolve(response.data);
					}
					else {
						reject(`錯誤:${response.error}`);
					}
				})
				.catch(function (err) {
					reject(`錯誤:${err.message ? err.message : err}`);
				});
		});
	}, [token])

	const sendOrder = useCallback(async () => {
		var userOrderId = enterOrderId ? enterOrderId : null;
		var userPhone = enterPhone ? enterPhone : null;
		var purchasedCnt = 0;

		for (var key in purchasedProducts) { purchasedCnt++; }
		if (purchasedCnt == 0) {
			//uiMsgResult.innerHTML = "點擊左側教材選取品項";
		}
		else if (!userOrderId) {
			//uiMsgResult.innerHTML = "請輸入客戶訂單編號";
		}
		else if (!userPhone) {
			//uiMsgResult.innerHTML = "請輸入客戶手機號碼";
		}
		else {
			setLoading(true)
			getProductCodes = []
			setProductCode(getProductCodes)
			getProductCodeCount = productCodeNum
			createProductCodes();
		}
	}, [enterOrderId, enterPhone, purchasedProducts])

	const createProductCodes = useCallback(() => {
		var userOrderId = enterOrderId ? enterOrderId : null;
		var userPhone = enterPhone ? enterPhone : null;
		var items = [];
		var codeNum = 1;

		if (document.location.origin.indexOf("oneclass.com.tw") < 0 &&
			document.location.origin.indexOf("release") < 0 &&
			userOrderId == "12345" &&
			agentName == "全通代碼" &&
			userPhone.toString() == "0978978978") {
			for (var code in naniProducts) {
				var product = naniProducts[code];
				items.push({
					code: code,
					product: product
				});
			}
			codeNum = 10;
		}
		else {
			purchasedProducts.forEach((productInfo) => {
				const { code, product } = productInfo
				items.push({
					code: code,
					product: product
				});
			})
		}


		var myPromise = [];
		for (var i = 0; i < codeNum; i++) {
			myPromise.push(createExchangeCode({ agentId: userId, agentName: name, userOrderId, userPhone, items,remain,personalLimit,isLimitPersonal,isLimitTotal }));
		}
		Promise.all(myPromise)
			.then((result) => {
				getProductCodes = [...getProductCodes, ...result]

				getProductCodeCount--
				if (getProductCodeCount > 0) {
					createProductCodes();
				} else {
					setLoading(false)
					setEnterOrderId("")
					setEnterPhone("")
					setPurchasedProducts([])
					setProductCode(getProductCodes)
					getProductCodeCount = 1
				}
			}).catch(function (err) {
				console.log('get codes err', JSON.stringify(err));
			});
	}, [enterOrderId, enterPhone, setProductCode, userId, name, purchasedProducts,remain,personalLimit,isLimitPersonal,isLimitTotal])

	const clearSelectProductList = useCallback(() => {
		setPurchasedProducts([])
	}, [setPurchasedProducts])

	const exportCodesExcel = useCallback(() => {
		const row = productCodes.map((info, index) => {
			return [index, info]
		})

		let csvContent = "data:text/csv;charset=utf-8,\uFEFF"
			+ row.map(e => e.join(",")).join("\n");
		var encodedUri = encodeURI(csvContent);
		console.log("encodedUri", encodedUri);
		window.open(encodedUri);

	}, [productCodes])

	return (
		<div className={styles.SelectOrderCode}>
			{loading && <OrderLoading message={"兌換碼創建中..."} />}
			<div className={styles.tabs}>
				<div
					id="mainLeft"
					className={styles.leftSelect}
				>
					<h5>所有產品列表</h5>
					<TextField
						id="outlined-helperText"
						style={{ width: '95%' }}
						label=""
						defaultValue=""
						helperText="關鍵字 (以半形分號 ; 查詢關鍵字聯集)"
						variant="standard"
						onChange={(e) => change(e)}
					/>
					<div
						id="naniVideos"
						className={styles.productList}
					>
						<div>
							{productsList && productsList.map((products, index) => {
								return <div className={styles.listBtn} key={index} onClick={(e) => addProductsHandler(e, products)} >{products.product}</div>
							})}
						</div>
					</div>
					<div className={styles.sendBtn}>
						<Button onClick={(e) => selectAllProduct()}>全選</Button>
					</div>
				</div>
				<div
					id="mainCenter"
					className={styles.centerSelect}
				>
					<h5>已選擇列表</h5>
					<div
						id="naniVideos"
						className={styles.productList}
					>
						<div>
							{purchasedProducts && purchasedProducts.map((products, index) => {
								return <div className={styles.listBtn} key={index} onClick={(e) => removeProductsHandler(e, products)} >{products.product}</div>
							})}
						</div>
					</div>

					<div className={styles.sendBtn}>
						<Button onClick={(e) => clearSelectProductList()}>清除列表</Button>
					</div>
				</div>
				<div id="mainRight" className={styles.rightSelect}>
					<div id="purchasedProducts" className={styles.textContent}>
						<div>
							<TextField
								id="outlined-number"
								label="訂單編號:"
								type="text"
								InputLabelProps={{
									shrink: true,
								}}
								value={enterOrderId}
								onChange={(e) => setEnterOrderId(e.target.value)}
							/>
						</div>
						<div>
							<TextField
								id="outlined-number"
								label="學生帳號:"
								type="text"
								InputLabelProps={{
									shrink: true,
								}}
								value={enterPhone}
								onChange={(e) => setEnterPhone(e.target.value)}
							/>
						</div>
						<div>
							<TextField
								id="outlined-number"
								label="數量:"
								type="number"
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(e) => productCodeNum = e.target.value}
							/>
						</div>
						<div className={styles.sendBtn}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateTimePicker
									label="開始時間"
									value={startTime}
									onChange={(value) => {
										codeUsageStart = value
										setStartTime(value)
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</div>
						<div className={styles.sendBtn}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateTimePicker
									label="結束時間"
									value={endTime}
									onChange={(value) => {

										codeUsageEnd = value
										setEndTime(value)
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</div>
						<div className={styles.deadline}>
							<div>產品使用期限(不輸入預設366日)</div>
							<div>
								<TextField
									id="outlined-number"
									label=""
									type="number"
									InputLabelProps={{
										shrink: true,
									}}
									value={usageDurationValue}
									onChange={(e) => {
										durationValue = e.target.value
										setUsageDurationValue(e.target.value)
									}}
								/>
							</div>

						</div>
						<div className={styles.totalUse}>
							<div>總使用次數:</div>
							<div>
								<div className={styles.input}>
									<input
										type="radio"
										id="isLimitPersonal"
										value='是'
										name="isLimitPersonal"
										checked={isLimitPersonal === '是'} 
										disableUnderline={true}
										onChange={(e)=>setIsLimitPersonal(e.target.value)}
									></input>
									<div>使用次數</div>
									<TextField
										id="outlined-number"
										type="number"
										border={true}
										style={{ paddingLeft: '20px' }}
										InputLabelProps={{
											shrink: true,
										}}
										disabled={isLimitPersonal === '否'}
										value={remain}
										onChange={(e) => setRemain(e.target.value)}
									/>
								</div>
								<div className={styles.input}>
									<input
										type="radio"
										id="isLimitPersonal"
										value='否'
										name="isLimitPersonal"
										checked={isLimitPersonal === '否'} 
										onChange={(e)=>setIsLimitPersonal(e.target.value)}
										disableUnderline={true}
									></input>
									<div>不限次數</div>
								</div>
							</div>

						</div>
						<div className={styles.totalUse}>
							<div>每帳號可用次數:</div>
							<div>
								<div className={styles.input}>
									<input
										type="radio"
										id="isLimitTotal"
										value='是'
										name="isLimitTotal"
										checked={isLimitTotal === '是'} 
										onChange={(e)=>setIsLimitTotal(e.target.value)}
										disableUnderline={true}
									></input>
									<div>使用次數</div>
									<TextField
										id="outlined-number"
										type="number"
										style={{ paddingLeft: '20px' }}
										InputLabelProps={{
											shrink: true,
										}}
										disabled={isLimitTotal === '否'}
										value={personalLimit}
										onChange={(e) => setPersonalLimit(e.target.value)}
									/>
								</div>
								<div className={styles.input}>
									<input
										type="radio"
										id="isLimitTotal"
										name="isLimitTotal"
										value='否'
										checked={isLimitTotal === '否'} 
										onChange={(e)=>setIsLimitTotal(e.target.value)}
										disableUnderline={true}
									></input>
									<div>不限次數</div>
								</div>
							</div>

						</div>
						<div className={styles.sendBtn2}>
							<Button onClick={() => sendOrder()}>送出</Button>
							<div id="msgResult"></div>
						</div>
					</div>
				</div>
				<div className={styles.rightSelect2}>
					<h5>兌換碼</h5>
					<div className={styles.productArea}>
						<div className={styles.areaContent}>
							{
								productCodes.map((codes, index) => {
									return index >= productCodes.length - 1 ? codes : `${codes},`
								})
							}
						</div>
					</div>
					<div className={styles.sendBtn}>
						<Button onClick={() => exportCodesExcel()} >匯出EXCEL</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SelectOrderCode;