import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import dayjs, { Dayjs } from 'dayjs';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import styles from "./index.module.scss";
import OrderLoading from 'components/Loading/OrderLoading';
import { useState, useCallback, useEffect } from 'react';
import short_uuid from "util/uuid"


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const sortOrderByECPay = (orders) => {
	return orders.sort((a, b ) => {
		let dateA;
		if (!a.ecPayOrderId) {
			dateA = 0;
		} else {
			dateA = a.ecPayOrderId.slice(0, 8);
		}
		let dateB;
		if (!b.ecPayOrderId) {
			dateB = 0;
		} else {
			dateB = b.ecPayOrderId.slice(0, 8)
		}
		return dateB - dateA;
	})
}

const SearchOrder = (props) => {
	const { naniProducts } = props
	const params = (new URL(document.location)).searchParams;
	const [loadingMessage, setLoadingMessage] = useState('查詢中...')
	const [loading, setLoading] = useState(false)
	const [allOrders, setAllOrders] = useState([]);

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_DOMAIN}/order/getAllOrders`, {
			method: "POST",
			headers: { "Content-type": "application/json; charset=UTF-8" }
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (response) {
				// console.log(response);
				if (response.status === "success") {
					// console.log('order: ', response.data);
					const orders = sortOrderByECPay(response.data);
					setAllOrders(orders);
				}
			})
			.catch(function (err) {
				console.log(err);
			});
	}, [])

	return (
		<div className={styles.SearchOrder}>
			{loading && <OrderLoading message={loadingMessage} />}
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>

							<TableCell>OneStudy訂單編號</TableCell>
							<TableCell align="right">綠界訂單編號</TableCell>
							<TableCell align="right">使用者</TableCell>
							<TableCell align="right">價格</TableCell>
							<TableCell align="right">購買狀態</TableCell>
							<TableCell align="right">購買產品</TableCell>
							<TableCell align="right">建立訂單日期</TableCell>
							<TableCell align="right">購買資訊</TableCell>
							<TableCell align="right">購買者訊息</TableCell>
							<TableCell align="right">刷卡紀錄</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{allOrders.map((row) => (
							<TableRow
								key={row.orderId}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell component="th" scope="row">
									{row.orderId}
								</TableCell>
								<TableCell align="right">{row.ecPayOrderId}</TableCell>
								<TableCell align="right">{row.userId}</TableCell>
								<TableCell align="right">{row.price}</TableCell>
								<TableCell align="right">{row.status}</TableCell>
								<TableCell align="right">{row.course}</TableCell>
								<TableCell align="right">{row.createDate}</TableCell>
								<TableCell align="right">{row.description}</TableCell>
								{/* {
									console.log(JSON.stringify(row.options))
								} */}
								<TableCell align="right">{JSON.stringify(row.options)}</TableCell>
								<TableCell align="right">{JSON.stringify(row.payFlowInfo)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div >
	);
}

export default SearchOrder;