import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import Header from "components/Header";
import Footer from "components/Footer";
import { Beforeunload } from 'components/Beforeunload';
import { useLocation, useParams, Link } from "react-router-dom";
import * as types from "constants/actionTypes";
import { useStore, StoreTypes } from "context";
import { IMAGES } from "constants/imagePath";
import { PRODUCT_MESSAGES } from "constants/productMessage";
import DialogModule from "components/Dialogs";
import { SUBJECT_TYPE } from "constants/subjectType";
import {
	ExchangeContent,
	ExchangeContentBtn,
} from "components/ExchangeContent";
import { Container, Row, Col, Card } from "react-bootstrap";
import Button from "components/Button";
import ParamsStorer from "../../util/paramsStorer";
import classnames from "classnames";
import { checkGrade, themeGrade } from "constants/subjectType";
import { checkProjectType } from "constants/projectsType";
import { productExpiredTime } from "util/productExpiredTime";

const PURCHASE_TYPE = {
	active: {
		status: 'active',
		message: '購買'
	},
	preorder: {
		status: 'preorder',
		message: '課程陸續更新中，可購買搶先看'
	},
	prepare: {
		status: 'prepare',
		message: '課程準備中，尚未開賣'
	},
}

const ProductView = () => {
	const router = useStore(StoreTypes.router);
	const [{ token, userId, product, oneLiveMemberCloseTime, isOneLiveMember }, userDispatch] = useStore(StoreTypes.user);
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	let education = query.get("education");
	let volume = query.get("volume");
	let course = query.get("course");
	const [{ educationInfo }, globalDispatch] = useStore(StoreTypes.global);
	const [productData, setProductData] = useState(null);
	const [isExchange, setIsExchange] = useState(false);
	const [isOwned, setOwned] = useState(false);
	const [year, setYear] = useState(false);
	const [plusCode,setPlusCode] = useState(null);
	const [canPurchase, setCanPurchase] = useState(PURCHASE_TYPE.active.status);
	const [yearGroupData, setYearGroupData] = useState([]);

	useEffect(() => {
		const uiActionPanel = document.getElementById("actionPanel");
		if (uiActionPanel) uiActionPanel.style.display = 'none';
		var bOwned = false;
		const productsPromiseAll = []
		const isTheme = education === "主題式"
		let grade = `${education}${volume.slice(0, isTheme ? volume.length : 1)}`
		// console.log(grade, education, volume);
		// console.log(themeGrade);
		const isGrade = (!isTheme ? checkGrade : themeGrade).find((str) => str === volume.slice(0, isTheme ? volume.length : 1))


		productsPromiseAll.push(getProducts(`${course}`))

		Promise.all(productsPromiseAll).then(function (dataArr) {
			const datas = dataArr.length > 1 ? [...dataArr[0], ...dataArr[1]] : dataArr[0]

			// console.log("datas", datas);
			// // change price on page
			var originalPrice = 0; //原價v
			var totalPrice = 0; //優惠價

			for (var i = 0; i < datas.length; i++) {
				const tmp = datas[i];
				originalPrice += parseInt(tmp["price"]);
				totalPrice += parseInt(tmp["preferential price"]);
			}

			// update price on page
			const uiTotalPrice = document.getElementById('totalPrice');
			if (uiTotalPrice) uiTotalPrice.innerText = `$${totalPrice}`;
			const uiOriginalPrice = document.getElementById('originalPrice');
			if (uiOriginalPrice) uiOriginalPrice.innerText = `$${originalPrice}`;
			const uiDiscount = document.getElementById('discount');
			if (uiDiscount) {
				var discount = (1 - totalPrice / originalPrice) * 10;
				if (discount > 1) discount = Math.round(discount) * 10;
				else discount = Math.round(discount * 10);
				uiDiscount.innerText = `${discount}% OFF`;
			}

			// assgin productData
			ParamsStorer.Instance().setData(
				"PurchaseFillInfos",
				datas
			);

			if (product) {
				for (var i = 0; i < product.length; i++) {
					const code = product[i].productCode;
					for (var j = 0; j < datas.length; j++) {
						if (datas[j].productcode === code) {
							bOwned = true;
							break;
						}
					}
				}
			}

			const years = []
			let yearName;
			datas.forEach((data, index) => {
				const year = data.id.split("_")[0]
				if (index === 0) {
					yearName = year
				}
				if (!years[year]) {
					years[year] = []
				}
				years[year].push(data)
			})

			setCanPurchase(datas[0].purchase ? PURCHASE_TYPE[datas[0].purchase].status : PURCHASE_TYPE.active.status)
			setOwned(bOwned);
			setProductData(datas)
			setYearGroupData(years)
			setYear(yearName)
			if (uiActionPanel) uiActionPanel.style.display = '';
		}).catch(function (err) { });
	}, [product]);

	const yearBtns = (yearGroupData, year) => {
		const yearElementArr = []
		for (let key in yearGroupData) {
			yearElementArr.push(< Button
				key={key}
				className={classnames(styles.naniBtn, { [styles.disabled]: key === year })}
				onClick={() => setYear(key)}
				variant="secondary"
				disabled={key === year} > {key}
			</Button >)
		}
		return yearElementArr
	}

	const getProducts = (course) => {
		// courseId is like "國小一上", "國中七上"...
		return new Promise((resolve, reject) => {
			const curTime = new Date();
			var year = curTime.getFullYear();

			if (curTime.getMonth() < 8) year -= 1;
			year = year - 1911;

			var apiDomain = `${process.env.REACT_APP_API_DOMAIN}`;//getAPIDomain();
			var data = {
				year: year.toString(),
				filters: {
					course
				}
			};

			fetch(`${apiDomain}/apis/getProducts`, {
				method: "POST",
				body: JSON.stringify(data),
				headers: { "Content-type": "application/json; charset=UTF-8" }
			})
				.then(function (response) {
					return response.json();
				})
				.then(function (response) {
					if (response.status == "success") {
						resolve(response.data);
					}
					else {
						reject(response.error.message);
					}
				})
				.catch(function (err) {
					reject(err.message ? err.message : err);
				});
		});
	}

	const goSubjectPage = (data) => {
		// productCode=110_01_01_E_C&volume=國小一上&subject=國語
		const productCode = data["productCode"];
		const volume = data["course"];
		const subject = data["subject"];
		const education = data['education']
		const expiredTime = productExpiredTime({ product, productCode, oneLiveMemberCloseTime, isOneLiveMember, education })
		const url = `/ClassView?productCode=${productCode}&volume=${volume}&subject=${subject}&expired=${expiredTime}`;

		globalDispatch({ type: types.SET_VIDEO_LIST, videoList: [] });
		globalDispatch({
			type: types.SET_CLASS_SELECT_PANNEL,
			isClassSeletPannel: false,
		});
		globalDispatch({ type: types.SET_PRODUCT_INFO, productInfo: data });
		router.history.push(url);
	};

	const createItem = (item) => {
		return item.map((data, index) => {
			// VolumeName: "一上"
			// course: "國小一上"
			// education: "國小"
			// preferential price: null
			// price: null
			// product: "110國小首冊國語"
			// productcode: "110_00_01_E_C"
			//subject: "國語"
			let itemProduct = data["product"];
			let productCode = data["productcode"];
			let itemEducation = data["education"];
			let course = data["course"];
			let subject = data["subject"];
			let version = data["version"];
			if (subject === "國語首冊") {
				subject = "國語"
			}
			if(productCode.split('_')[1]!=='0'){
				let lastIndex = productCode.lastIndexOf(productCode.slice(-1));
				let replacedString = productCode.slice(0, lastIndex) + "00_SET";
				!plusCode&&setPlusCode(replacedString)
			}
			// console.log(course);

			// 連結課程名稱與圖片路徑 20230106
			const imgType = SUBJECT_TYPE[subject];
			let itemImage = '';
			if (checkProjectType()) {
				if (itemEducation === '國中' || itemEducation === '國小' || itemEducation === '高中') {
					itemImage = IMAGES.PRODUCT_ITEM[`${itemEducation}${volume}_${imgType}`];
				} else {
					itemImage = IMAGES.PRODUCT_ITEM[`${course}_${subject}`];
				}
			} else {
				itemImage = IMAGES.NANI_PRODUCT_C[`${course}_${subject}`];
			}

			let itemData = {
				productCode,
				course,
				subject,
				product: itemProduct
			};
			return (
				<Col xs={6} sm={4} className="mb-3">
					<Card className={styles.card} style={{ cursor: "pointer" }} onClick={() => goSubjectPage(itemData)}>
						<Card.Img variant="top" src={itemImage} />
						<Card.Body className={"d-flex justify-content-between"}>
							<a>{`${itemProduct}${version ? '(' + version + ')' : ''}`}</a>

							<div
								className={styles.readBtn}
							>
								{
									isOwned ? "已可使用" : "提供試讀"
								}
							</div>
						</Card.Body>
					</Card>
				</Col>
			);
		});
	};

	const buyClick = useCallback(() => {
		if (userId) {
			router.history.push(`/PurchaseFillInfos`);
		}
		else {
			alert("need to login first");
		}
	}, [router, userId]);

	const exchangeClick = useCallback(() => {
		setIsExchange(true);
	}, [setIsExchange]);

	const getSubjectNum = useCallback(() => {
		switch (volume.substr(0, 1)) {
			case "一":
			case "二":
				return 3;
			case "三":
			case "四":
			case "五":
			case "六":
				return 4;

			default:
				return 5;
		}
	}, [volume]);

	const getTitle = (education, volume) => {
		const volumeNum = volume.substr(0, 1)
		const filterVolume = volume.substr(0, volume.length - 1)
		const isGrade = checkGrade.find((str) => str === volumeNum)
		return `${education}${filterVolume}${isGrade ? '年級' : ''}科目組合`
	}

	const getBuyInfoImgSrc = () => {
		if (education === '國小' || education === '國中' || education === '高中') {
			const newCourse = course.slice(3);
			return checkProjectType() ? IMAGES.PRODUCT[`${newCourse}_B`] : IMAGES.NANI_PRODUCT[`${newCourse}`]
		} else {
			return checkProjectType() ? IMAGES.PRODUCT[`${course}_B`] : IMAGES.NANI_PRODUCT[`${course}`]
		}
	}

	const courseNoteCheck = (type) => {
		switch (type) {
			case "主題式溫美玉ｘ魏瑛娟ㄅㄆㄇ": {
				return ["人氣教師溫美玉和魏瑛娟聯合打造", "ㄅㄆㄇ注音學習樂園", "趣味動畫、魔力唸謠、有效學習"]
			}
			case '主題式溫美玉ｘ魏瑛娟寫作課': {
				return ["人氣教師溫美玉和魏瑛娟聯合打造", "中高年級人物寫作課", "破除迷思，建立寫作素養"]
			}
			default:
				return
		}
	}
	return (
		<Beforeunload>
			<div className={styles.productView}>
				<DialogModule
					DialogModule
					open={isExchange}
					setOpen={setIsExchange}
					content={<ExchangeContent setIsExchange={setIsExchange} />}
				/>
				<div className={styles.header}>
					<Header />
				</div>

				<div className={styles.buyInfo}>
					<div className={styles.buyInfoContent}>
						<div className={styles.picture}>
							<img
								src={getBuyInfoImgSrc()}
								alt={getBuyInfoImgSrc()}
							/>
						</div>
						<div className={styles.priceInfo}>
							<div className={styles.text}>精選主打</div>
							<div
								className={styles.title}
							>
								{
									course//getTitle(education, volume)
								}
							</div>
							{
								!isOwned && <div id="actionPanel">
									<div className={styles.priceContent}>
										<div id="totalPrice" className={styles.price}>＄8888</div>
										<div className={styles.space}></div>
										<div id="originalPrice" className={styles.originPrice}>＄16888</div>
										<div id="discount" className={styles.priceOff}>50% OFF</div>
									</div>

									<div
										className={classnames(styles.buyBtn, { [styles.disabled]: canPurchase === PURCHASE_TYPE.prepare.status })}
										onClick={() => buyClick()}
									>
										{
											canPurchase === PURCHASE_TYPE.preorder.status ? "購買" : PURCHASE_TYPE[canPurchase].message
										}
									</div>
									{
										canPurchase === PURCHASE_TYPE.preorder.status && <div className={styles.buyMessage}>
											{
												PURCHASE_TYPE.preorder.message
											}
										</div>
									}
									<div
										className={styles.exchangeBtn}
										onClick={() => exchangeClick()}
									>
										<img src="./assets/img/Vector3.svg" alt="" />
										<div>已有序號，兌換課程</div>
									</div>
								</div>
							}

							<div className={styles.noteContent}>
								<div className={styles.note1}>
									<img src="./assets/img/book.svg" alt="" />
									{
										checkProjectType() ? <div>{courseNoteCheck(course) ? courseNoteCheck(course)[0] : `${getSubjectNum()} 大科目`}</div> : <div>{`${PRODUCT_MESSAGES.NANI[course] && PRODUCT_MESSAGES.NANI[course].A}`}</div>
									}
								</div>
								<div className={styles.note1}>
									<img src="./assets/img/Vector4.svg" alt="" />
									{
										checkProjectType() ? <div>{courseNoteCheck(course) ? courseNoteCheck(course)[1] : "不限時間觀看影片"}</div> : <div>{`${PRODUCT_MESSAGES.NANI[course] && PRODUCT_MESSAGES.NANI[course].B}`}</div>
									}
								</div>
								<div className={styles.note1}>
									<img src="./assets/img/Vector5.svg" alt="" />
									{
										checkProjectType() ? <div>{courseNoteCheck(course) ? courseNoteCheck(course)[2] : "無限制測驗次數"}</div> : <div>{`${PRODUCT_MESSAGES.NANI[course] && PRODUCT_MESSAGES.NANI[course].C}`}</div>
									}
								</div>
								{course.includes('111') && course.includes('下') ? <div className={classnames(styles.note1, styles.launchTimetable)}>
									<img src="./assets/img/productViewTimetable.svg" alt="" />
									<div>學習資源陸續上架
										<a href="https://tutor.oneclass.com.tw/onestudyupdate_oc/
											" target="_blank" rel="noreferrer" >查看時間表
										</a>
										<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9.5 8C9.5 7.70406 9.39008 7.45039 9.15333 7.23055L2.57497 0.787486C2.38895 0.601467 2.1522 0.499999 1.87317 0.499999C1.31511 0.499999 0.866968 0.939685 0.866968 1.5062C0.866968 1.78523 0.985344 2.03044 1.17136 2.22492L7.09865 8L1.17136 13.7751C0.985343 13.9696 0.866967 14.2232 0.866967 14.4938C0.866967 15.0603 1.31511 15.5 1.87317 15.5C2.1522 15.5 2.38895 15.3985 2.57497 15.2125L9.15333 8.7779C9.39008 8.54961 9.5 8.29594 9.5 8Z" fill="#EC7963" />
										</svg>
									</div>
								</div> : null}
							</div>
						</div>
					</div>
					<br />
				</div>
				<div className={styles.bannel}>
					<button onClick={()=>window.open(`${process.env.REACT_APP_STUDY_PLUS_DOMAIN}/product/${plusCode}`)}>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</button>
				</div>
				<div className={styles.kinds}>
					<div className={styles.subjectCombination}>
						<h1>科目組合</h1>
					</div>
				</div>
				<div className={styles.yearSelect}>
					<div className={styles.yearContent}>
						{year && yearBtns(yearGroupData, year)}
					</div>

				</div>
				<div className={styles.buyInfo}>
					<div className={styles.productItem}>
						<Container>
							<Row >{yearGroupData[year] && createItem(yearGroupData[year])}</Row>
						</Container>
					</div>
				</div>
				<div></div>
				<Footer />
			</div>
		</Beforeunload>
	);
};
export default ProductView;
