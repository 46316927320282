import React, { useState, useCallback, useEffect, useRef,useMemo } from "react";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import * as actionTypes from "constants/actionTypes";
import { useLogin } from "util/login";
import { useOutsideAlerter } from "customHooks/outsideAlerter";
import Login from "./Login";
import { red, grey } from "@mui/material/colors";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const OrderLogin = () => {
  const ref = useRef();
  const router = useStore(StoreTypes.router);
  const [{ token, userId, name, isLogin }, userDispatch] = useStore(
    StoreTypes.user
  );
  const [isUserList, setIsUserList] = useState(false);
  const [{ isClassSeletPannel, nowHeadSelectListName }, globalDispatch] =
    useStore(StoreTypes.global);
  const { logoutNaniOneClass, getToken } = useLogin();
  const [open, setOpen] = useState(true);
  const [isLogoutShown, setIsLogoutShown] = useState(false);

  useEffect(() => {
    setOpen(!isLogin);
    const token = getToken();
    if (token) {
      setOpen(false);
    }
  }, [isLogin, getToken]);

  const loginBtn = useCallback(() => {
    if (isLogin) {
      userDispatch({
        type: actionTypes.SET_USER_INFO,
        name: "",
        isLogin: false,
        token: "",
        userId: "",
        product: null,
      });
      logoutNaniOneClass();
      setOpen(true);
    } else {
      setOpen(true);
    }
  }, [userDispatch, logoutNaniOneClass, isLogin]);

  const setClose = () => {
    setIsUserList(false);
  };

  useOutsideAlerter(ref, setClose);

  const logoUrl = useMemo(()=>{
    switch(process.env.REACT_APP_PROJECT){
      case 'onestudy':{
        return "./assets/img/Logo.svg"
      }
      case 'yunlinstudy':{
        return "./assets/img/yunlinstudy/Logo.svg"
      }
      case 'chiayistudy':{
        return "./assets/img/chiayistudy/Logo.svg"
      }
      case 'nanistudy':{
        return "./assets/img/nanistudy/Logo_black.svg"
      }
      default:{
        break
      }
    }
  },[])

  return (
    <div ref={ref} className={styles.userInfoArea}>
      <Login
        open={open}
        setOpen={setOpen}
        lockClose={true}
        disableCloseBtn={false}
      />
      {isLogin && (
        <>
          <div className={styles.userInfoContent}>
            <div className={styles.logoContainer}>
            <img src={logoUrl} alt="logo" width="228px" height="72px" />
            Hi,{name}
            </div>
            <div className={styles.userIcon}>
              <div className={styles.nowListText} style={{ background: "red" }}>
                <div>{nowHeadSelectListName}</div>
              </div>
              <div className={styles.userBtn}>
                <AccountCircleIcon
                  sx={{ color: red[100], width: "32px", height: "32px" }}
                  onClick={() => setIsLogoutShown(!isLogoutShown)}
                />
                {isLogoutShown && <p onClick={() => loginBtn()}>登出</p>}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default OrderLogin;
