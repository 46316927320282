export const school = [{
    "代碼": "011601",
    "學校名稱": "私立育才國小",
    "公/私立": "私立",
    "縣市名稱": "[01]新北市",
    "地址": "[234]新北市永和區福和路125巷20號",
    "電話": "(02)29214630",
    "網址": "http://www.ytes.ntpc.edu.tw"
  },
  {
    "代碼": "011602",
    "學校名稱": "私立聖心國小",
    "公/私立": "私立",
    "縣市名稱": "[01]新北市",
    "地址": "[249]新北市八里區龍米路一段261號",
    "電話": "(02)26182330",
    "網址": "http://lshes.com/school/"
  },
  {
    "代碼": "011603",
    "學校名稱": "私立及人國小",
    "公/私立": "私立",
    "縣市名稱": "[01]新北市",
    "地址": "[234]新北市永和區文化路172號",
    "電話": "(02)29212145",
    "網址": "http://www.cjps.ntpc.edu.tw"
  },
  {
    "代碼": "011604",
    "學校名稱": "私立竹林國小",
    "公/私立": "私立",
    "縣市名稱": "[01]新北市",
    "地址": "[234]新北市永和區竹林路34號",
    "電話": "(02)29243919",
    "網址": "http://www.clse.ntpc.edu.tw"
  },
  {
    "代碼": "011606",
    "學校名稱": "私立信賢種籽親子實小",
    "公/私立": "私立",
    "縣市名稱": "[01]新北市",
    "地址": "[233]新北市烏來區信賢里娃娃谷41號",
    "電話": "(02)26616648",
    "網址": "http://www.seedling.tw"
  },
  {
    "代碼": "013601",
    "學校名稱": "市立北大國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區國光街100號",
    "電話": "(02)86716836",
    "網址": "http://www.bdes.ntpc.edu.tw/"
  },
  {
    "代碼": "013602",
    "學校名稱": "市立新林國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[244]新北市林口區三民路101號",
    "電話": "(02)26086521",
    "網址": "http://www.xles.ntpc.edu.tw/"
  },
  {
    "代碼": "014601",
    "學校名稱": "市立板橋國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區黃石里文化路一段23號",
    "電話": "(02)29686834",
    "網址": "http://www.pcps.ntpc.edu.tw"
  },
  {
    "代碼": "014602",
    "學校名稱": "市立國光國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區中正路325巷30號",
    "電話": "(02)29680611",
    "網址": "http://www.kkes.ntpc.edu.tw"
  },
  {
    "代碼": "014603",
    "學校名稱": "市立新埔國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區新翠里陽明街206號",
    "電話": "(02)22571830",
    "網址": "http://www.spes.ntpc.edu.tw"
  },
  {
    "代碼": "014604",
    "學校名稱": "市立埔墘國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區永豐街42之8號",
    "電話": "(02)29616690",
    "網址": "http://www.pces.ntpc.edu.tw"
  },
  {
    "代碼": "014605",
    "學校名稱": "市立莒光國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區華翠里莒光路163號",
    "電話": "(02)22517272",
    "網址": "http://www.jges.ntpc.edu.tw"
  },
  {
    "代碼": "014606",
    "學校名稱": "市立後埔國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區重慶路157號",
    "電話": "(02)29614142",
    "網址": "http://www.hpes.ntpc.edu.tw"
  },
  {
    "代碼": "014607",
    "學校名稱": "市立海山國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區漢生東路280號",
    "電話": "(02)29545725",
    "網址": "http://www.hsps.ntpc.edu.tw"
  },
  {
    "代碼": "014608",
    "學校名稱": "市立江翠國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區松翠里文化路二段413號",
    "電話": "(02)22526880",
    "網址": "http://www.ctes.ntpc.edu.tw"
  },
  {
    "代碼": "014610",
    "學校名稱": "市立文聖國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區文聖街86號",
    "電話": "(02)22530782",
    "網址": "http://www.wses.ntpc.edu.tw"
  },
  {
    "代碼": "014611",
    "學校名稱": "市立沙崙國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區篤行路二段132號",
    "電話": "(02)26812764",
    "網址": "http://www.sles.ntpc.edu.tw"
  },
  {
    "代碼": "014612",
    "學校名稱": "市立文德國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區新翠里英士路179號",
    "電話": "(02)22577193",
    "網址": "http://www.wdps.ntpc.edu.tw"
  },
  {
    "代碼": "014613",
    "學校名稱": "市立樹林國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[238]新北市樹林區樹西里育英街176號",
    "電話": "(02)26812014",
    "網址": "http://www.slps.ntpc.edu.tw"
  },
  {
    "代碼": "014614",
    "學校名稱": "市立文林國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[238]新北市樹林區潭底里千歲街59號",
    "電話": "(02)26812625",
    "網址": "http://www.twles.ntpc.edu.tw"
  },
  {
    "代碼": "014615",
    "學校名稱": "市立大同國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[238]新北市樹林區大同里大同街43號",
    "電話": "(02)86865486",
    "網址": "http://www.ttps.ntpc.edu.tw"
  },
  {
    "代碼": "014616",
    "學校名稱": "市立武林國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[238]新北市樹林區保安街二段151號",
    "電話": "(02)26812475",
    "網址": "http://www.wles.ntpc.edu.tw"
  },
  {
    "代碼": "014617",
    "學校名稱": "市立山佳國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[238]新北市樹林區中山路三段5號",
    "電話": "(02)26806673",
    "網址": "http://www.sjes.ntpc.edu.tw"
  },
  {
    "代碼": "014618",
    "學校名稱": "市立育德國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[238]新北市樹林區中山里佳園路一段34號",
    "電話": "(02)26805557",
    "網址": "http://www.ytps.ntpc.edu.tw"
  },
  {
    "代碼": "014619",
    "學校名稱": "市立柑園國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[238]新北市樹林區柑園街一段353號",
    "電話": "(02)26802507",
    "網址": "http://www.gyes.ntpc.edu.tw"
  },
  {
    "代碼": "014620",
    "學校名稱": "市立鶯歌國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[239]新北市鶯歌區同慶里尖山埔路106號",
    "電話": "(02)26792038",
    "網址": "http://www.ykes.ntpc.edu.tw"
  },
  {
    "代碼": "014621",
    "學校名稱": "市立二橋國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[239]新北市鶯歌區二橋里中正三路106號",
    "電話": "(02)26792364",
    "網址": "http://www.eqes.ntpc.edu.tw"
  },
  {
    "代碼": "014622",
    "學校名稱": "市立中湖國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[239]新北市鶯歌區中湖里中湖街25號",
    "電話": "(02)86775640",
    "網址": "http://www.chues.ntpc.edu.tw"
  },
  {
    "代碼": "014623",
    "學校名稱": "市立鳳鳴國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[239]新北市鶯歌區鳳鳴里永和街120號",
    "電話": "(02)26797554",
    "網址": "http://www.fmes.ntpc.edu.tw"
  },
  {
    "代碼": "014624",
    "學校名稱": "市立三峽國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區中山路16號",
    "電話": "(02)26711018",
    "網址": "http://www.shps.ntpc.edu.tw"
  },
  {
    "代碼": "014625",
    "學校名稱": "市立大埔國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區大埔路130號",
    "電話": "(02)26712523",
    "網址": "http://www.tpps.ntpc.edu.tw"
  },
  {
    "代碼": "014626",
    "學校名稱": "市立民義國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區嘉添里白雞路40號",
    "電話": "(02)26711895",
    "網址": "http://www.myes.ntpc.edu.tw/default.asp"
  },
  {
    "代碼": "014627",
    "學校名稱": "市立成福國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區溪東里溪東路213號",
    "電話": "(02)86764945",
    "網址": "http://www.chenfu.ntpc.edu.tw"
  },
  {
    "代碼": "014628",
    "學校名稱": "市立大成國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區成福路251巷二號",
    "電話": "(02)26726521",
    "網址": "http://www.dces.ntpc.edu.tw"
  },
  {
    "代碼": "014629",
    "學校名稱": "市立建安國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區安坑里建安路67號",
    "電話": "(02)26726783",
    "網址": "http://www.caes.ntpc.edu.tw"
  },
  {
    "代碼": "014630",
    "學校名稱": "市立插角國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區插角里插角路39號",
    "電話": "(02)26720230",
    "網址": "http://www.cgps.ntpc.edu.tw"
  },
  {
    "代碼": "014631",
    "學校名稱": "市立有木國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區有木里131號",
    "電話": "(02)26720067",
    "網址": "http://www.ymps.ntpc.edu.tw"
  },
  {
    "代碼": "014632",
    "學校名稱": "市立五寮國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區五寮里69號",
    "電話": "(02)26720298",
    "網址": "http://www.wres.ntpc.edu.tw"
  },
  {
    "代碼": "014633",
    "學校名稱": "市立中和國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[235]新北市中和區中和路100號",
    "電話": "(02)22492550",
    "網址": "http://www.jhes.ntpc.edu.tw"
  },
  {
    "代碼": "014634",
    "學校名稱": "市立復興國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[235]新北市中和區復興路301巷6號",
    "電話": "(02)22472993",
    "網址": "http://www.fsps.ntpc.edu.tw"
  },
  {
    "代碼": "014635",
    "學校名稱": "市立興南國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[235]新北市中和區興南路一段135巷24號",
    "電話": "(02)29422349",
    "網址": "http://www.hnps.ntpc.edu.tw"
  },
  {
    "代碼": "014636",
    "學校名稱": "市立秀山國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[235]新北市中和區立人街2號",
    "電話": "(02)29434353",
    "網址": "http://www.ssps.ntpc.edu.tw"
  },
  {
    "代碼": "014637",
    "學校名稱": "市立積穗國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[235]新北市中和區員山路154號",
    "電話": "(02)22225533",
    "網址": "http://www.tjsps.ntpc.edu.tw"
  },
  {
    "代碼": "014638",
    "學校名稱": "市立自強國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[235]新北市中和區莒光路200號",
    "電話": "(02)29557936",
    "網址": "http://www.jcps.ntpc.edu.tw"
  },
  {
    "代碼": "014639",
    "學校名稱": "市立錦和國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[235]新北市中和區圓通路292號",
    "電話": "(02)22479495",
    "網址": "http://www.chps.ntpc.edu.tw"
  },
  {
    "代碼": "014640",
    "學校名稱": "市立景新國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[235]新北市中和區景新街467巷37號",
    "電話": "(02)29448021",
    "網址": "http://www.jsps.ntpc.edu.tw"
  },
  {
    "代碼": "014641",
    "學校名稱": "市立永和國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[234]新北市永和區秀朗路一段120號",
    "電話": "(02)29214615",
    "網址": "http://www.yhes.ntpc.edu.tw"
  },
  {
    "代碼": "014642",
    "學校名稱": "市立秀朗國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[234]新北市永和區得和路202號",
    "電話": "(02)29420451",
    "網址": "http://www.hles.ntpc.edu.tw"
  },
  {
    "代碼": "014643",
    "學校名稱": "市立頂溪國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[234]新北市永和區文化路133號",
    "電話": "(02)29212058",
    "網址": "http://web2.thps.ntpc.edu.tw/"
  },
  {
    "代碼": "014644",
    "學校名稱": "市立網溪國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[234]新北市永和區竹林路79號",
    "電話": "(02)89264470",
    "網址": "http://www.whps.ntpc.edu.tw"
  },
  {
    "代碼": "014645",
    "學校名稱": "市立永平國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[234]新北市永和區保生路25號",
    "電話": "(02)29259879",
    "網址": "http://www.ypes.ntpc.edu.tw"
  },
  {
    "代碼": "014646",
    "學校名稱": "市立土城國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[236]新北市土城區興城路17號",
    "電話": "(02)22700177",
    "網址": "http://www.ttcps.ntpc.edu.tw"
  },
  {
    "代碼": "014647",
    "學校名稱": "市立清水國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[236]新北市土城區金城路二段356號",
    "電話": "(02)22618316",
    "網址": "http://www.cses.ntpc.edu.tw"
  },
  {
    "代碼": "014648",
    "學校名稱": "市立頂埔國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[236]新北市土城區頂福里中央路四段205號",
    "電話": "(02)22686800",
    "網址": "http://www.dpes.ntpc.edu.tw"
  },
  {
    "代碼": "014649",
    "學校名稱": "市立廣福國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[236]新北市土城區學府路一段127號",
    "電話": "(02)22626782",
    "網址": "http://www.gfes.ntpc.edu.tw"
  },
  {
    "代碼": "014650",
    "學校名稱": "市立汐止國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[221]新北市汐止區大同路二段313號",
    "電話": "(02)26477271",
    "網址": "http://www.hjes.ntpc.edu.tw"
  },
  {
    "代碼": "014651",
    "學校名稱": "市立長安國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[221]新北市汐止區長興街一段80號",
    "電話": "(02)86480944",
    "網址": "http://www.chanes.ntpc.edu.tw"
  },
  {
    "代碼": "014652",
    "學校名稱": "市立保長國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[221]新北市汐止區大同路三段553號",
    "電話": "(02)86482502",
    "網址": "http://www.bjes.ntpc.edu.tw"
  },
  {
    "代碼": "014653",
    "學校名稱": "市立崇德國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[221]新北市汐止區茄苳里茄苳路158號",
    "電話": "(02)86482052",
    "網址": "http://www.cdps.ntpc.edu.tw"
  },
  {
    "代碼": "014654",
    "學校名稱": "市立北港國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[221]新北市汐止區汐萬路二段279號",
    "電話": "(02)26462832",
    "網址": "http://www.bkes.ntpc.edu.tw"
  },
  {
    "代碼": "014655",
    "學校名稱": "市立北峰國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[221]新北市汐止區中興里環河街1號",
    "電話": "(02)26941817",
    "網址": "http://www.pfes.ntpc.edu.tw"
  },
  {
    "代碼": "014656",
    "學校名稱": "市立東山國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[221]新北市汐止區東山里汐平路二段76號",
    "電話": "(02)26416170",
    "網址": "http://www.dsps.ntpc.edu.tw"
  },
  {
    "代碼": "014657",
    "學校名稱": "市立白雲國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[221]新北市汐止區福山里民權街二段90號",
    "電話": "(02)26403909",
    "網址": "http://www.pyps.ntpc.edu.tw"
  },
  {
    "代碼": "014658",
    "學校名稱": "市立萬里國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[207]新北市萬里區瑪鋉路18號",
    "電話": "(02)24922034",
    "網址": "http://www.wlps.ntpc.edu.tw"
  },
  {
    "代碼": "014659",
    "學校名稱": "市立野柳國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[207]新北市萬里區野柳里港東路167號",
    "電話": "(02)24922512",
    "網址": "http://www.ylps.ntpc.edu.tw"
  },
  {
    "代碼": "014660",
    "學校名稱": "市立大鵬國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[207]新北市萬里區大鵬里加投路14號",
    "電話": "(02)24988131",
    "網址": "http://www.tpes.ntpc.edu.tw"
  },
  {
    "代碼": "014661",
    "學校名稱": "市立大坪國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[207]新北市萬里區雙興里大坪路1號",
    "電話": "(02)24929461",
    "網址": "http://www.dpps.ntpc.edu.tw"
  },
  {
    "代碼": "014662",
    "學校名稱": "市立崁腳國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[207]新北市萬里區崁脚里崁脚路59號",
    "電話": "(02)24922037",
    "網址": "http://www.kjps.ntpc.edu.tw"
  },
  {
    "代碼": "014663",
    "學校名稱": "市立金山國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[208]新北市金山區中山路234號",
    "電話": "(02)24981125",
    "網址": "http://www.tpcses.ntpc.edu.tw"
  },
  {
    "代碼": "014664",
    "學校名稱": "市立中角國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[208]新北市金山區萬壽里海興路49號",
    "電話": "(02)24982413",
    "網址": "http://www.jjps.ntpc.edu.tw"
  },
  {
    "代碼": "014665",
    "學校名稱": "市立三和國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[208]新北市金山區重和里十一鄰六股林口38號",
    "電話": "(02)24080417",
    "網址": "http://www.sanho.ntpc.edu.tw"
  },
  {
    "代碼": "014666",
    "學校名稱": "市立新店國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區新店路2號",
    "電話": "(02)29103483",
    "網址": "http://www.xdes.ntpc.edu.tw/"
  },
  {
    "代碼": "014667",
    "學校名稱": "市立直潭國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區直潭路92號",
    "電話": "(02)26667278",
    "網址": "http://www.jtes.ntpc.edu.tw"
  },
  {
    "代碼": "014668",
    "學校名稱": "市立青潭國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區北宜路二段80號",
    "電話": "(02)22173146",
    "網址": "http://www.chtes.ntpc.edu.tw"
  },
  {
    "代碼": "014669",
    "學校名稱": "市立雙峰國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區北宜路三段66號",
    "電話": "(02)22174543",
    "網址": "http://www.sfes.ntpc.edu.tw"
  },
  {
    "代碼": "014670",
    "學校名稱": "市立大豐國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區民族路108號",
    "電話": "(02)22192619",
    "網址": "http://www.dfes.ntpc.edu.tw"
  },
  {
    "代碼": "014671",
    "學校名稱": "市立中正國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區三民路36號",
    "電話": "(02)29125432",
    "網址": "http://www.ccps.ntpc.edu.tw"
  },
  {
    "代碼": "014672",
    "學校名稱": "市立安坑國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區安忠路36號",
    "電話": "(02)22110560",
    "網址": "http://www.takes.ntpc.edu.tw"
  },
  {
    "代碼": "014673",
    "學校名稱": "市立雙城國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區安康路三段322號",
    "電話": "(02)22156511",
    "網址": "http://www.tcsps.ntpc.edu.tw"
  },
  {
    "代碼": "014674",
    "學校名稱": "市立屈尺國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區屈尺路55號",
    "電話": "(02)26667490",
    "網址": "http://www.ccyes.ntpc.edu.tw"
  },
  {
    "代碼": "014675",
    "學校名稱": "市立龜山國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區新烏路三段99巷10號",
    "電話": "(02)26667317",
    "網址": "http://www.gses.ntpc.edu.tw"
  },
  {
    "代碼": "014676",
    "學校名稱": "市立深坑國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[222]新北市深坑區深坑里文化街45號",
    "電話": "(02)26624675",
    "網址": "http://www.tskes.ntpc.edu.tw"
  },
  {
    "代碼": "014677",
    "學校名稱": "市立石碇國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[223]新北市石碇區石碇里石碇西街15號",
    "電話": "(02)26631244",
    "網址": "http://www.stes.ntpc.edu.tw"
  },
  {
    "代碼": "014678",
    "學校名稱": "市立和平國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[223]新北市石碇區隆盛里八分寮5號",
    "電話": "(02)26631405",
    "網址": "http://www.hpps.ntpc.edu.tw"
  },
  {
    "代碼": "014679",
    "學校名稱": "市立永定國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[223]新北市石碇區永定里靜安路一段245號",
    "電話": "(02)26631288",
    "網址": "http://www.tydes.ntpc.edu.tw"
  },
  {
    "代碼": "014680",
    "學校名稱": "市立雲海國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[223]新北市石碇區北宜路五段坑內巷1號",
    "電話": "(02)26651715",
    "網址": "http://www.whies.ntpc.edu.tw"
  },
  {
    "代碼": "014682",
    "學校名稱": "市立坪林國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[232]新北市坪林區坪林里坪林街114號",
    "電話": "(02)26656213",
    "網址": "http://www.plnes.ntpc.edu.tw"
  },
  {
    "代碼": "014684",
    "學校名稱": "市立烏來國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[233]新北市烏來區啦卡路5號",
    "電話": "(02)26616482",
    "網址": "http://www.wups.ntpc.edu.tw"
  },
  {
    "代碼": "014685",
    "學校名稱": "市立福山國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[233]新北市烏來區福山里李茂岸56號",
    "電話": "(02)26616124",
    "網址": "http://www.fses.ntpc.edu.tw"
  },
  {
    "代碼": "014686",
    "學校名稱": "市立瑞芳國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[224]新北市瑞芳區龍川里中山路2號",
    "電話": "(02)24972058",
    "網址": "http://www.rfes.ntpc.edu.tw"
  },
  {
    "代碼": "014687",
    "學校名稱": "市立義方國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[224]新北市瑞芳區龍山里逢甲路360號",
    "電話": "(02)24970961",
    "網址": "http://www.jfps.ntpc.edu.tw"
  },
  {
    "代碼": "014688",
    "學校名稱": "市立瑞柑國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[224]新北市瑞芳區明燈路一段18巷13號",
    "電話": "(02)24971009",
    "網址": "http://www.rges.ntpc.edu.tw"
  },
  {
    "代碼": "014689",
    "學校名稱": "市立瑞濱國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[224]新北市瑞芳區瑞濱路34號",
    "電話": "(02)24978184",
    "網址": "http://www.jpes.ntpc.edu.tw"
  },
  {
    "代碼": "014690",
    "學校名稱": "市立九份國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[224]新北市瑞芳區永慶里崙頂路145號",
    "電話": "(02)24972263",
    "網址": "http://www.jfes.ntpc.edu.tw"
  },
  {
    "代碼": "014691",
    "學校名稱": "市立瓜山國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[224]新北市瑞芳區石山里五號路306號",
    "電話": "(02)24962036",
    "網址": "http://www.gsps.ntpc.edu.tw"
  },
  {
    "代碼": "014692",
    "學校名稱": "市立濂洞國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[224]新北市瑞芳區濓洞里洞頂路101巷80號",
    "電話": "(02)24961330",
    "網址": "http://163.20.89.1"
  },
  {
    "代碼": "014693",
    "學校名稱": "市立猴硐國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[224]新北市瑞芳區弓橋里九芎橋路69-1號",
    "電話": "(02)24960450",
    "網址": "http://www.htes.ntpc.edu.tw"
  },
  {
    "代碼": "014694",
    "學校名稱": "市立瑞亭國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[224]新北市瑞芳區四腳亭埔路23號",
    "電話": "(02)24971726",
    "網址": "http://www.rtes.ntpc.edu.tw"
  },
  {
    "代碼": "014695",
    "學校名稱": "市立吉慶國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[224]新北市瑞芳區吉安里大埔路95號",
    "電話": "(02)24576405",
    "網址": "http://www.cces.ntpc.edu.tw"
  },
  {
    "代碼": "014696",
    "學校名稱": "市立鼻頭國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[224]新北市瑞芳區鼻頭里鼻頭路99號",
    "電話": "(02)24911571",
    "網址": "http://www.pitoues.ntpc.edu.tw"
  },
  {
    "代碼": "014697",
    "學校名稱": "市立雙溪國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[227]新北市雙溪區共和里東榮街54號",
    "電話": "(02)24931005",
    "網址": "http://www.sses.ntpc.edu.tw"
  },
  {
    "代碼": "014698",
    "學校名稱": "市立柑林國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[227]新北市雙溪區長源里柑腳3號",
    "電話": "(02)24931624",
    "網址": "http://www.gles.ntpc.edu.tw"
  },
  {
    "代碼": "014699",
    "學校名稱": "市立上林國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[227]新北市雙溪區上林里內平林60號",
    "電話": "(02)24931671",
    "網址": "http://www.ulps.ntpc.edu.tw"
  },
  {
    "代碼": "014700",
    "學校名稱": "市立牡丹國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[227]新北市雙溪區三貂里政光路3號",
    "電話": "(02)24931240",
    "網址": "http://www.mdps.ntpc.edu.tw"
  },
  {
    "代碼": "014702",
    "學校名稱": "市立貢寮國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[228]新北市貢寮區雙玉里學苑街7號",
    "電話": "(02)24941274",
    "網址": "http://www.kles.ntpc.edu.tw"
  },
  {
    "代碼": "014703",
    "學校名稱": "市立福隆國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[228]新北市貢寮區福隆里東興街35號",
    "電話": "(02)24991449",
    "網址": "http://www.flps.ntpc.edu.tw"
  },
  {
    "代碼": "014704",
    "學校名稱": "市立澳底國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[228]新北市貢寮區真理里延平街10號",
    "電話": "(02)24901432",
    "網址": "http://www.aodi.ntpc.edu.tw/"
  },
  {
    "代碼": "014706",
    "學校名稱": "市立和美國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[228]新北市貢寮區和美里龍洞街1之9號",
    "電話": "(02)24909431",
    "網址": "http://www.hmps.ntpc.edu.tw"
  },
  {
    "代碼": "014708",
    "學校名稱": "市立福連國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[228]新北市貢寮區福連里福連街1號",
    "電話": "(02)24991194",
    "網址": "http://www.fulps.ntpc.edu.tw"
  },
  {
    "代碼": "014709",
    "學校名稱": "市立平溪國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[226]新北市平溪區平溪街56號",
    "電話": "(02)24951038",
    "網址": "http://www.pses.ntpc.edu.tw"
  },
  {
    "代碼": "014710",
    "學校名稱": "市立菁桐國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[226]新北市平溪區菁桐街45號",
    "電話": "(02)24951009",
    "網址": "http://www.tgtes.ntpc.edu.tw/"
  },
  {
    "代碼": "014711",
    "學校名稱": "市立十分國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[226]新北市平溪區十分里十分街157號",
    "電話": "(02)24958305",
    "網址": "http://www.tsfes.ntpc.edu.tw"
  },
  {
    "代碼": "014712",
    "學校名稱": "市立淡水國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區協元里中山路160號",
    "電話": "(02)26212755",
    "網址": "http://www.tsps.ntpc.edu.tw"
  },
  {
    "代碼": "014713",
    "學校名稱": "市立育英國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區埤島里14號",
    "電話": "(02)26214390",
    "網址": "http://www.yies.ntpc.edu.tw"
  },
  {
    "代碼": "014714",
    "學校名稱": "市立文化國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區文化里真理街6號",
    "電話": "(02)26212031",
    "網址": "http://www.twhps.ntpc.edu.tw"
  },
  {
    "代碼": "014715",
    "學校名稱": "市立天生國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區淡海路72巷26號",
    "電話": "(02)28052695",
    "網址": "http://www.tisnes.ntpc.edu.tw"
  },
  {
    "代碼": "014716",
    "學校名稱": "市立水源國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區水源里北新路二段15號",
    "電話": "(02)26211347",
    "網址": "http://www.hyes.ntpc.edu.tw"
  },
  {
    "代碼": "014717",
    "學校名稱": "市立興仁國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區興仁路101巷10號",
    "電話": "(02)26213783",
    "網址": "http://www.shrens.ntpc.edu.tw"
  },
  {
    "代碼": "014718",
    "學校名稱": "市立忠山實驗小學",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區忠山里行忠路899號",
    "電話": "(02)26216139",
    "網址": "http://www.tcses.ntpc.edu.tw"
  },
  {
    "代碼": "014719",
    "學校名稱": "市立屯山國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區淡金路五段111號",
    "電話": "(02)28012591",
    "網址": "http://www.ttses.ntpc.edu.tw"
  },
  {
    "代碼": "014720",
    "學校名稱": "市立中泰國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區中和里北勢子5號",
    "電話": "(02)28012033",
    "網址": "http://www.ctps.ntpc.edu.tw"
  },
  {
    "代碼": "014721",
    "學校名稱": "市立坪頂國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區坪頂路757號",
    "電話": "(02)86263105",
    "網址": "http://www.pdes.ntpc.edu.tw"
  },
  {
    "代碼": "014722",
    "學校名稱": "市立竹圍國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區中正東路二段145號",
    "電話": "(02)28091475",
    "網址": "http://www.cwps.ntpc.edu.tw"
  },
  {
    "代碼": "014723",
    "學校名稱": "市立石門國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[253]新北市石門區尖鹿里中央路9號",
    "電話": "(02)26381263",
    "網址": "http://www.smes.ntpc.edu.tw"
  },
  {
    "代碼": "014724",
    "學校名稱": "市立乾華國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[253]新北市石門區茂林里茂林社區74號",
    "電話": "(02)26381269",
    "網址": "http://www.cwes.ntpc.edu.tw/"
  },
  {
    "代碼": "014725",
    "學校名稱": "市立老梅國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[253]新北市石門區老梅里老梅路10號",
    "電話": "(02)26381258",
    "網址": "http://www.lmes.ntpc.edu.tw"
  },
  {
    "代碼": "014726",
    "學校名稱": "市立三芝國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[252]新北市三芝區埔頭里育英街22號",
    "電話": "(02)26362005",
    "網址": "http://www.sces.ntpc.edu.tw"
  },
  {
    "代碼": "014727",
    "學校名稱": "市立橫山國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[252]新北市三芝區橫山里86號",
    "電話": "(02)26362473",
    "網址": "http://www.hses.ntpc.edu.tw"
  },
  {
    "代碼": "014728",
    "學校名稱": "市立興華國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[252]新北市三芝區興華里5號",
    "電話": "(02)26371366",
    "網址": "http://www.hhes.ntpc.edu.tw"
  },
  {
    "代碼": "014729",
    "學校名稱": "市立新莊國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區立人里中正路86號",
    "電話": "(02)29931201",
    "網址": "http://www.scps.ntpc.edu.tw"
  },
  {
    "代碼": "014730",
    "學校名稱": "市立中港國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區立志里中港一街142號",
    "電話": "(02)29925550",
    "網址": "http://www.jgps.ntpc.edu.tw"
  },
  {
    "代碼": "014731",
    "學校名稱": "市立思賢國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區自立街229號",
    "電話": "(02)29980443",
    "網址": "http://www.sies.ntpc.edu.tw"
  },
  {
    "代碼": "014732",
    "學校名稱": "市立頭前國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區頭前里化成路231號",
    "電話": "(02)29920238",
    "網址": "http://www.tcps.ntpc.edu.tw"
  },
  {
    "代碼": "014733",
    "學校名稱": "市立國泰國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區中正路386號",
    "電話": "(02)29043693",
    "網址": "http://www.gtps.ntpc.edu.tw"
  },
  {
    "代碼": "014734",
    "學校名稱": "市立豐年國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區國泰里瓊泰路116號",
    "電話": "(02)22017102",
    "網址": "http://www.fnes.ntpc.edu.tw"
  },
  {
    "代碼": "014735",
    "學校名稱": "市立丹鳳國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區新北大道七段437號",
    "電話": "(02)29035355",
    "網址": "http://163.20.170.180/"
  },
  {
    "代碼": "014736",
    "學校名稱": "市立光華國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區龍安路452號",
    "電話": "(02)22058866",
    "網址": "http://www.ghes.ntpc.edu.tw"
  },
  {
    "代碼": "014737",
    "學校名稱": "市立民安國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區民全里民安路二六一號",
    "電話": "(02)22056777",
    "網址": "http://www.maes.ntpc.edu.tw"
  },
  {
    "代碼": "014738",
    "學校名稱": "市立昌隆國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區昌隆街63號",
    "電話": "(02)29962351",
    "網址": "http://www.clps.ntpc.edu.tw"
  },
  {
    "代碼": "014739",
    "學校名稱": "市立泰山國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[243]新北市泰山區泰林路二段255號",
    "電話": "(02)29098842",
    "網址": "http://www.tses.ntpc.edu.tw"
  },
  {
    "代碼": "014740",
    "學校名稱": "市立明志國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[243]新北市泰山區新生路2號",
    "電話": "(02)29061133",
    "網址": "http://www.mjes.ntpc.edu.tw"
  },
  {
    "代碼": "014741",
    "學校名稱": "市立成州國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[248]新北市五股區成泰路三段493號",
    "電話": "(02)22933613",
    "網址": "http://www.cjoes.ntpc.edu.tw"
  },
  {
    "代碼": "014742",
    "學校名稱": "市立更寮國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[248]新北市五股區四維路2號",
    "電話": "(02)29824591",
    "網址": "http://www.glps.ntpc.edu.tw"
  },
  {
    "代碼": "014743",
    "學校名稱": "市立五股國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[248]新北市五股區五股里成泰路二段49號",
    "電話": "(02)22917191",
    "網址": "http://www.wkes.ntpc.edu.tw"
  },
  {
    "代碼": "014744",
    "學校名稱": "市立蘆洲國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[247]新北市蘆洲區中正路100號",
    "電話": "(02)22816202",
    "網址": "http://www.lces.ntpc.edu.tw"
  },
  {
    "代碼": "014745",
    "學校名稱": "市立鷺江國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[247]新北市蘆洲區民族路7號",
    "電話": "(02)22819980",
    "網址": "http://www.lcps.ntpc.edu.tw"
  },
  {
    "代碼": "014746",
    "學校名稱": "市立八里國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[249]新北市八里區舊城里中山路二段338號",
    "電話": "(02)26102217",
    "網址": "http://www.ples.ntpc.edu.tw"
  },
  {
    "代碼": "014747",
    "學校名稱": "市立長坑國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[249]新北市八里區中華路三段236號",
    "電話": "(02)26102037",
    "網址": "http://www.ckes.ntpc.edu.tw"
  },
  {
    "代碼": "014748",
    "學校名稱": "市立米倉國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[249]新北市八里區米倉里龍米路2段129巷1號",
    "電話": "(02)26182202",
    "網址": "http://www.mtes.ntpc.edu.tw"
  },
  {
    "代碼": "014749",
    "學校名稱": "市立林口國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[244]新北市林口區菁湖里林口路76號",
    "電話": "(02)26011010",
    "網址": "http://www.lkes.ntpc.edu.tw"
  },
  {
    "代碼": "014750",
    "學校名稱": "市立南勢國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[244]新北市林口區南勢五街2號",
    "電話": "(02)26091020",
    "網址": "http://www.nses.ntpc.edu.tw"
  },
  {
    "代碼": "014751",
    "學校名稱": "市立嘉寶國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[244]新北市林口區嘉寶里22號",
    "電話": "(02)26052716",
    "網址": "http://www.jbes.ntpc.edu.tw"
  },
  {
    "代碼": "014752",
    "學校名稱": "市立瑞平國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[244]新北市林口區太平里後坑路34之1號",
    "電話": "(02)26052779",
    "網址": "http://www.rpes.ntpc.edu.tw"
  },
  {
    "代碼": "014753",
    "學校名稱": "市立興福國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[244]新北市林口區下福里20鄰一○之一號",
    "電話": "(02)26062895",
    "網址": "http://www.hfps.ntpc.edu.tw"
  },
  {
    "代碼": "014754",
    "學校名稱": "市立三重國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區三和路三段1號",
    "電話": "(02)29722095",
    "網址": "http://www.tsces.ntpc.edu.tw"
  },
  {
    "代碼": "014755",
    "學校名稱": "市立永福國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區永福里永福街66號",
    "電話": "(02)22876716",
    "網址": "http://www.yfes.ntpc.edu.tw/"
  },
  {
    "代碼": "014756",
    "學校名稱": "市立光榮國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區介壽路32號",
    "電話": "(02)29730392",
    "網址": "http://www.kjpps.ntpc.edu.tw"
  },
  {
    "代碼": "014757",
    "學校名稱": "市立厚德國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區忠孝路一段70號",
    "電話": "(02)29868825",
    "網址": "http://www.hdes.ntpc.edu.tw"
  },
  {
    "代碼": "014758",
    "學校名稱": "市立碧華國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區五華街160號",
    "電話": "(02)28577792",
    "網址": "http://www.bhes.ntpc.edu.tw"
  },
  {
    "代碼": "014759",
    "學校名稱": "市立三光國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區同安里大同南路157號",
    "電話": "(02)29753308",
    "網址": "http://web.skes.ntpc.edu.tw"
  },
  {
    "代碼": "014760",
    "學校名稱": "市立光興國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區正義南路62號",
    "電話": "(02)29755352",
    "網址": "http://www.kses.ntpc.edu.tw"
  },
  {
    "代碼": "014761",
    "學校名稱": "市立正義國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區信義西街31號",
    "電話": "(02)29853138",
    "網址": "http://www.jyes.ntpc.edu.tw"
  },
  {
    "代碼": "014762",
    "學校名稱": "市立修德國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區重陽路三段3號",
    "電話": "(02)29800495",
    "網址": "http://www.sude.ntpc.edu.tw"
  },
  {
    "代碼": "014763",
    "學校名稱": "市立二重國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區二重里大有街10號",
    "電話": "(02)29846446",
    "網址": "http://www.eces.ntpc.edu.tw"
  },
  {
    "代碼": "014764",
    "學校名稱": "市立興穀國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區五谷王北街46號",
    "電話": "(02)29990725",
    "網址": "http://www.skps.ntpc.edu.tw"
  },
  {
    "代碼": "014765",
    "學校名稱": "市立興化國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[248]新北市新莊區福興里五工二路50巷2號",
    "電話": "(02)22991001",
    "網址": "http://www.tshes.ntpc.edu.tw"
  },
  {
    "代碼": "014766",
    "學校名稱": "市立中山國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區大觀路二段59巷31號",
    "電話": "(02)22755313",
    "網址": "http://www.jshps.ntpc.edu.tw"
  },
  {
    "代碼": "014767",
    "學校名稱": "市立三多國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[238]新北市樹林區三福街52號",
    "電話": "(02)26880698",
    "網址": "http://www.sdes.ntpc.edu.tw"
  },
  {
    "代碼": "014768",
    "學校名稱": "市立實踐國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區實踐路93巷51號",
    "電話": "(02)29531233",
    "網址": "http://www.sjps.ntpc.edu.tw"
  },
  {
    "代碼": "014769",
    "學校名稱": "市立大觀國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區大觀路一段30號",
    "電話": "(02)29603373",
    "網址": "http://www.tgps.ntpc.edu.tw"
  },
  {
    "代碼": "014770",
    "學校名稱": "市立溪洲國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區金門街289號",
    "電話": "(02)26867705",
    "網址": "http://www.sips.ntpc.edu.tw"
  },
  {
    "代碼": "014771",
    "學校名稱": "市立信義國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區四川路二段245巷60號",
    "電話": "(02)89667817",
    "網址": "http://www.thyes.ntpc.edu.tw"
  },
  {
    "代碼": "014772",
    "學校名稱": "市立重慶國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[220]新北市板橋區廣和街31號",
    "電話": "(02)29565592",
    "網址": "http://www.cges.ntpc.edu.tw"
  },
  {
    "代碼": "014773",
    "學校名稱": "市立樂利國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[236]新北市土城區裕生路65號",
    "電話": "(02)22662568",
    "網址": "http://www.llps.ntpc.edu.tw"
  },
  {
    "代碼": "014774",
    "學校名稱": "市立安和國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[236]新北市土城區延和路23號",
    "電話": "(02)22603451",
    "網址": "http://www.anhoes.ntpc.edu.tw"
  },
  {
    "代碼": "014775",
    "學校名稱": "市立彭福國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[238]新北市樹林區忠孝街30號",
    "電話": "(02)86866589",
    "網址": "http://www.pfps.ntpc.edu.tw"
  },
  {
    "代碼": "014776",
    "學校名稱": "市立育林國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[238]新北市樹林區復興路395號",
    "電話": "(02)26832112",
    "網址": "http://www.yles.ntpc.edu.tw"
  },
  {
    "代碼": "014777",
    "學校名稱": "市立建國國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[239]新北市鶯歌區育英街2號",
    "電話": "(02)26709228",
    "網址": "http://www.ckps.ntpc.edu.tw"
  },
  {
    "代碼": "014778",
    "學校名稱": "市立安溪國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區中華路42號",
    "電話": "(02)26731488",
    "網址": "http://www.ases.ntpc.edu.tw"
  },
  {
    "代碼": "014779",
    "學校名稱": "市立樟樹國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[221]新北市汐止區樟樹一路141巷2號",
    "電話": "(02)26421407",
    "網址": "http://www.tctes.ntpc.edu.tw"
  },
  {
    "代碼": "014780",
    "學校名稱": "市立金美國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[208]新北市金山區忠孝一路111號",
    "電話": "(02)24986503",
    "網址": "http://www.gmes.ntpc.edu.tw"
  },
  {
    "代碼": "014781",
    "學校名稱": "市立新和國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區安和路三段100號",
    "電話": "(02)29400170",
    "網址": "http://www.hhps.ntpc.edu.tw"
  },
  {
    "代碼": "014782",
    "學校名稱": "市立鄧公國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區學府里學府路99號",
    "電話": "(02)26297121",
    "網址": "http://www.dkes.ntpc.edu.tw"
  },
  {
    "代碼": "014783",
    "學校名稱": "市立新興國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區新興街123號",
    "電話": "(02)26203645",
    "網址": "http://www.shes.ntpc.edu.tw"
  },
  {
    "代碼": "014784",
    "學校名稱": "市立重陽國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區中正北路113號",
    "電話": "(02)29826272",
    "網址": "http://www.cyps.ntpc.edu.tw"
  },
  {
    "代碼": "014785",
    "學校名稱": "市立五華國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區集賢路89號",
    "電話": "(02)28578087",
    "網址": "http://www.whes.ntpc.edu.tw"
  },
  {
    "代碼": "014786",
    "學校名稱": "市立成功國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[247]新北市蘆洲區長安街311號",
    "電話": "(02)22858998",
    "網址": "http://www.tces.ntpc.edu.tw"
  },
  {
    "代碼": "014787",
    "學校名稱": "市立仁愛國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[247]新北市蘆洲區民族路488號",
    "電話": "(02)22838815",
    "網址": "http://www.jaes.ntpc.edu.tw"
  },
  {
    "代碼": "014788",
    "學校名稱": "市立榮富國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區中和街193號",
    "電話": "(02)22769020",
    "網址": "http://www.lfes.ntpc.edu.tw"
  },
  {
    "代碼": "014789",
    "學校名稱": "市立裕民國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區裕民街123號",
    "電話": "(02)29062407",
    "網址": "http://www.ymes.ntpc.edu.tw"
  },
  {
    "代碼": "014790",
    "學校名稱": "市立新泰國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區公園一路91號",
    "電話": "(02)29971005",
    "網址": "http://www.stps.ntpc.edu.tw"
  },
  {
    "代碼": "014791",
    "學校名稱": "市立中信國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區中信街168號",
    "電話": "(02)85211131",
    "網址": "http://www.ches.ntpc.edu.tw"
  },
  {
    "代碼": "014792",
    "學校名稱": "市立德音國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[248]新北市五股區明德路2號",
    "電話": "(02)22924152",
    "網址": "http://www.tyes.ntpc.edu.tw"
  },
  {
    "代碼": "014793",
    "學校名稱": "市立麗園國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[244]新北市林口區忠孝路591號",
    "電話": "(02)26091061",
    "網址": "http://www.lyaes.ntpc.edu.tw"
  },
  {
    "代碼": "014794",
    "學校名稱": "市立北新國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區寶橋路8號",
    "電話": "(02)29189300",
    "網址": "http://www.tspes.ntpc.edu.tw"
  },
  {
    "代碼": "014795",
    "學校名稱": "市立同榮國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[243]新北市泰山區公園路33號",
    "電話": "(02)22968551",
    "網址": "http://www.tres.ntpc.edu.tw"
  },
  {
    "代碼": "014796",
    "學校名稱": "市立光復國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[235]新北市中和區光環路二段1號",
    "電話": "(02)32348654",
    "網址": "http://www.kfes.ntpc.edu.tw"
  },
  {
    "代碼": "014797",
    "學校名稱": "市立秀峰國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[221]新北市汐止區仁愛路90號",
    "電話": "(02)26480611",
    "網址": "http://www.sfps.ntpc.edu.tw"
  },
  {
    "代碼": "014798",
    "學校名稱": "市立金龍國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[221]新北市汐止區明峰街201號",
    "電話": "(02)26959941",
    "網址": "http://www.gdps.ntpc.edu.tw"
  },
  {
    "代碼": "014799",
    "學校名稱": "市立介壽國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區大同路1號",
    "電話": "(02)26712392",
    "網址": "http://www.jses.ntpc.edu.tw"
  },
  {
    "代碼": "014801",
    "學校名稱": "市立昌平國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[242]新北市新莊區昌平街200號",
    "電話": "(02)85215492",
    "網址": "http://www.cpps.ntpc.edu.tw"
  },
  {
    "代碼": "014802",
    "學校名稱": "市立麗林國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[244]新北市林口區公園路46號",
    "電話": "(02)26008457",
    "網址": "http://www.lles.ntpc.edu.tw"
  },
  {
    "代碼": "014803",
    "學校名稱": "市立集美國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[241]新北市三重區集美街10號",
    "電話": "(02)89725390",
    "網址": "http://www.jmes.ntpc.edu.tw"
  },
  {
    "代碼": "014804",
    "學校名稱": "市立永吉國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[239]新北市鶯歌區德昌二街2號",
    "電話": "(02)26704177",
    "網址": "http://www.yces.ntpc.edu.tw"
  },
  {
    "代碼": "014805",
    "學校名稱": "市立大崁國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[249]新北市八里區忠八街2號",
    "電話": "(02)86305678",
    "網址": "http://www.tkes.ntpc.edu.tw"
  },
  {
    "代碼": "014806",
    "學校名稱": "市立中園國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區弘園街22號",
    "電話": "(02)86712590",
    "網址": "http://www.jyps.ntpc.edu.tw"
  },
  {
    "代碼": "014807",
    "學校名稱": "市立昌福國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[239]新北市鶯歌區永明街22號",
    "電話": "(02)86773322",
    "網址": "http://www.cfps.ntpc.edu.tw"
  },
  {
    "代碼": "014808",
    "學校名稱": "市立忠義國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[247]新北市蘆洲區光榮路99號",
    "電話": "(02)22899591",
    "網址": "http://www.cyes.ntpc.edu.tw"
  },
  {
    "代碼": "014811",
    "學校名稱": "市立青山國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[221]新北市汐止區莊敬街33號",
    "電話": "(02)26917875",
    "網址": "http://www.csjhs.ntpc.edu.tw"
  },
  {
    "代碼": "014812",
    "學校名稱": "市立義學國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[243]新北市泰山區民生路30號",
    "電話": "(02)22972048",
    "網址": "http://www.yses.ntpc.edu.tw"
  },
  {
    "代碼": "014813",
    "學校名稱": "市立達觀國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區僑信路1號",
    "電話": "(02)22159013",
    "網址": "http://www.dgejh.ntpc.edu.tw/default.asp"
  },
  {
    "代碼": "014814",
    "學校名稱": "市立桃子腳國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[238]新北市樹林區學勤路555號",
    "電話": "(02)89703225",
    "網址": "http://www.tykjh.ntpc.edu.tw"
  },
  {
    "代碼": "014815",
    "學校名稱": "市立龍埔國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[237]新北市三峽區大學路6號",
    "電話": "(02)26745666",
    "網址": "http://www.lpes.ntpc.edu.tw/default.asp"
  },
  {
    "代碼": "014816",
    "學校名稱": "市立頭湖國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[244]新北市林口區民權路101號",
    "電話": "(02)26080766",
    "網址": "http://www.jles.ntpc.edu.tw/"
  },
  {
    "代碼": "014817",
    "學校名稱": "市立新市國小",
    "公/私立": "公立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區中山北路二段200號",
    "電話": "(02)26262141",
    "網址": "http://www.xses.ntpc.edu.tw/default.asp"
  },
  {
    "代碼": "313601",
    "學校名稱": "市立松山國小",
    "公/私立": "公立",
    "縣市名稱": "[31]臺北市",
    "地址": "[105]臺北市松山區八德路四段746號",
    "電話": "(02)27672907",
    "網址": "http://web.ssps.tp.edu.tw"
  },
  {
    "代碼": "313602",
    "學校名稱": "市立西松國小",
    "公/私立": "公立",
    "縣市名稱": "[31]臺北市",
    "地址": "[105]臺北市松山區東光里三民路5號",
    "電話": "(02)27609221",
    "網址": "http://www.sups.tp.edu.tw"
  },
  {
    "代碼": "313604",
    "學校名稱": "市立敦化國小",
    "公/私立": "公立",
    "縣市名稱": "[31]臺北市",
    "地址": "[105]臺北市松山區中正里敦化北路2號",
    "電話": "(02)27414065",
    "網址": "http://www.thps.tp.edu.tw"
  },
  {
    "代碼": "313605",
    "學校名稱": "市立民生國小",
    "公/私立": "公立",
    "縣市名稱": "[31]臺北市",
    "地址": "[105]臺北市松山區敦化北路199巷18號",
    "電話": "(02)27122452",
    "網址": "http://www.msps.tp.edu.tw"
  },
  {
    "代碼": "313606",
    "學校名稱": "市立民權國小",
    "公/私立": "公立",
    "縣市名稱": "[31]臺北市",
    "地址": "[105]臺北市松山區民權東路四段200號",
    "電話": "(02)27652327",
    "網址": "http://www.mcps.tp.edu.tw"
  },
  {
    "代碼": "313607",
    "學校名稱": "市立民族國小",
    "公/私立": "公立",
    "縣市名稱": "[31]臺北市",
    "地址": "[105]臺北市松山區民生東路4段97巷7號",
    "電話": "(02)27124872",
    "網址": "http://epage.mces.tp.edu.tw/bin/home.php"
  },
  {
    "代碼": "313608",
    "學校名稱": "市立三民國小",
    "公/私立": "公立",
    "縣市名稱": "[31]臺北市",
    "地址": "[105]臺北市松山區莊敬里民權東路五段1號",
    "電話": "(02)27646080",
    "網址": "http://www.smps.tp.edu.tw"
  },
  {
    "代碼": "313609",
    "學校名稱": "市立健康國小",
    "公/私立": "公立",
    "縣市名稱": "[31]臺北市",
    "地址": "[105]臺北市松山區延壽街168號",
    "電話": "(02)25282814",
    "網址": "http://www.jkes.tp.edu.tw"
  },
  {
    "代碼": "323601",
    "學校名稱": "市立興雅國小",
    "公/私立": "公立",
    "縣市名稱": "[32]臺北市",
    "地址": "[110]臺北市信義區雅祥里基隆路1段83巷9號",
    "電話": "(02)27618156",
    "網址": "http://www.hyps.tp.edu.tw"
  },
  {
    "代碼": "323602",
    "學校名稱": "市立永春國小",
    "公/私立": "公立",
    "縣市名稱": "[32]臺北市",
    "地址": "[110]臺北市信義區永春里松山路225巷48號",
    "電話": "(02)27641314",
    "網址": "http://www.ycps.tp.edu.tw"
  },
  {
    "代碼": "323603",
    "學校名稱": "市立光復國小",
    "公/私立": "公立",
    "縣市名稱": "[32]臺北市",
    "地址": "[110]臺北市信義區光復南路271號",
    "電話": "(02)27585076",
    "網址": "http://www.kfps.tp.edu.tw"
  },
  {
    "代碼": "323604",
    "學校名稱": "市立三興國小",
    "公/私立": "公立",
    "縣市名稱": "[32]臺北市",
    "地址": "[110]臺北市信義區景聯里基隆路2段99號",
    "電話": "(02)27385488",
    "網址": "http://www.shps.tp.edu.tw"
  },
  {
    "代碼": "323605",
    "學校名稱": "市立信義國小",
    "公/私立": "公立",
    "縣市名稱": "[32]臺北市",
    "地址": "[110]臺北市信義區松勤街60號",
    "電話": "(02)27204005",
    "網址": "http://www.syes.tp.edu.tw"
  },
  {
    "代碼": "323606",
    "學校名稱": "市立吳興國小",
    "公/私立": "公立",
    "縣市名稱": "[32]臺北市",
    "地址": "[110]臺北市信義區松仁路226號",
    "電話": "(02)27200226",
    "網址": "http://www.wsps.tp.edu.tw/"
  },
  {
    "代碼": "323607",
    "學校名稱": "市立福德國小",
    "公/私立": "公立",
    "縣市名稱": "[32]臺北市",
    "地址": "[110]臺北市信義區中行里褔德街253號",
    "電話": "(02)27277992",
    "網址": "http://www.fdps.tp.edu.tw"
  },
  {
    "代碼": "323608",
    "學校名稱": "市立永吉國小",
    "公/私立": "公立",
    "縣市名稱": "[32]臺北市",
    "地址": "[110]臺北市信義區永春里松山路287巷5號",
    "電話": "(02)87858111",
    "網址": "http://www.yhps.tp.edu.tw"
  },
  {
    "代碼": "323609",
    "學校名稱": "市立博愛國小",
    "公/私立": "公立",
    "縣市名稱": "[32]臺北市",
    "地址": "[110]臺北市信義區松仁路22鄰95巷20號",
    "電話": "(02)23450616",
    "網址": "http://www.baps.tp.edu.tw"
  },
  {
    "代碼": "330601",
    "學校名稱": "國立臺北教大實小",
    "公/私立": "公立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區龍淵里和平東路2段94號",
    "電話": "(02)27356186",
    "網址": "http://www.ntueees.tp.edu.tw/"
  },
  {
    "代碼": "331601",
    "學校名稱": "私立復興國小",
    "公/私立": "私立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區仁愛里敦化南路1段262號",
    "電話": "(02)27715859",
    "網址": "http://www.fhjh.tp.edu.tw"
  },
  {
    "代碼": "331602",
    "學校名稱": "私立立人國(中)小",
    "公/私立": "私立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區義榮里安和路二段99號",
    "電話": "(02)27361388",
    "網址": "http://www.ljjhps.tp.edu.tw"
  },
  {
    "代碼": "331603",
    "學校名稱": "私立新民小學",
    "公/私立": "私立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區龍坡里辛亥路1段113號",
    "電話": "(02)23630450",
    "網址": "http://www.hmps.tp.edu.tw"
  },
  {
    "代碼": "333601",
    "學校名稱": "市立龍安國小",
    "公/私立": "公立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區龍門里新生南路3段33號",
    "電話": "(02)23632077",
    "網址": "http://www.laes.tp.edu.tw"
  },
  {
    "代碼": "333602",
    "學校名稱": "市立大安國小",
    "公/私立": "公立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區芳和里臥龍街129號",
    "電話": "(02)27322332",
    "網址": "http://www.taes.tp.edu.tw"
  },
  {
    "代碼": "333603",
    "學校名稱": "市立幸安國小",
    "公/私立": "公立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區民炤里仁愛路3段22號",
    "電話": "(02)27074191",
    "網址": "http://www.haps.tp.edu.tw"
  },
  {
    "代碼": "333604",
    "學校名稱": "市立建安國小",
    "公/私立": "公立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區龍雲里大安路2段99號",
    "電話": "(02)27077119",
    "網址": "http://www.jnps.tp.edu.tw"
  },
  {
    "代碼": "333605",
    "學校名稱": "市立仁愛國小",
    "公/私立": "公立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區敦安里安和路1段60號",
    "電話": "(02)27095010",
    "網址": "http://www.japs.tp.edu.tw"
  },
  {
    "代碼": "333606",
    "學校名稱": "市立金華國小",
    "公/私立": "公立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區永康里愛國東路79巷11號",
    "電話": "(02)23917402",
    "網址": "http://www.cups.tp.edu.tw/./"
  },
  {
    "代碼": "333607",
    "學校名稱": "市立古亭國小",
    "公/私立": "公立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區古風里羅斯福路3段201號",
    "電話": "(02)23639795",
    "網址": "http://www.gtes.tp.edu.tw"
  },
  {
    "代碼": "333608",
    "學校名稱": "市立銘傳國小",
    "公/私立": "公立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區農場里羅斯褔路四段21號",
    "電話": "(02)23639815",
    "網址": "http://www.mhps.tp.edu.tw"
  },
  {
    "代碼": "333609",
    "學校名稱": "市立公館國小",
    "公/私立": "公立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區基隆路4段41巷68弄2號",
    "電話": "(02)27351734",
    "網址": "http://www.gges.tp.edu.tw"
  },
  {
    "代碼": "333610",
    "學校名稱": "市立新生國小",
    "公/私立": "公立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區新生南路二段36號",
    "電話": "(02)23913122",
    "網址": "http://www.snes.tp.edu.tw"
  },
  {
    "代碼": "333611",
    "學校名稱": "市立和平實驗國小",
    "公/私立": "公立",
    "縣市名稱": "[33]臺北市",
    "地址": "[106]臺北市大安區敦南街76巷28號",
    "電話": "(02)27335900",
    "網址": "http://sites.google.com/site/hopingelschool/"
  },
  {
    "代碼": "343601",
    "學校名稱": "市立中山國小",
    "公/私立": "公立",
    "縣市名稱": "[34]臺北市",
    "地址": "[104]臺北市中山區恆安里民權東路1段69號",
    "電話": "(02)25914085",
    "網址": "http://www.csps.tp.edu.tw"
  },
  {
    "代碼": "343602",
    "學校名稱": "市立中正國小",
    "公/私立": "公立",
    "縣市名稱": "[34]臺北市",
    "地址": "[104]臺北市中山區力行里龍江路62號",
    "電話": "(02)25070932",
    "網址": "http://web.jjes.tp.edu.tw"
  },
  {
    "代碼": "343603",
    "學校名稱": "市立長安國小",
    "公/私立": "公立",
    "縣市名稱": "[34]臺北市",
    "地址": "[104]臺北市中山區興亞里吉林路15號",
    "電話": "(02)25617600",
    "網址": "http://www.caps.tp.edu.tw"
  },
  {
    "代碼": "343604",
    "學校名稱": "市立長春國小",
    "公/私立": "公立",
    "縣市名稱": "[34]臺北市",
    "地址": "[104]臺北市中山區中央里長春路165號",
    "電話": "(02)25024366",
    "網址": "http://www.ccps.tp.edu.tw"
  },
  {
    "代碼": "343605",
    "學校名稱": "市立大直國小",
    "公/私立": "公立",
    "縣市名稱": "[34]臺北市",
    "地址": "[104]臺北市中山區大直里大直街2號",
    "電話": "(02)25333953",
    "網址": "http://www.tzes.tp.edu.tw"
  },
  {
    "代碼": "343606",
    "學校名稱": "市立大佳國小",
    "公/私立": "公立",
    "縣市名稱": "[34]臺北市",
    "地址": "[104]臺北市中山區大佳里6鄰濱江街107號",
    "電話": "(02)25035816",
    "網址": "http://www.djes.tp.edu.tw"
  },
  {
    "代碼": "343607",
    "學校名稱": "市立五常國小",
    "公/私立": "公立",
    "縣市名稱": "[34]臺北市",
    "地址": "[104]臺北市中山區下埤里五常街16號",
    "電話": "(02)25023416",
    "網址": "http://www.wcps.tp.edu.tw/enable01/index.php"
  },
  {
    "代碼": "343608",
    "學校名稱": "市立吉林國小",
    "公/私立": "公立",
    "縣市名稱": "[34]臺北市",
    "地址": "[104]臺北市中山區中原里長春路116號",
    "電話": "(02)25219196",
    "網址": "http://www.clps.tp.edu.tw"
  },
  {
    "代碼": "343609",
    "學校名稱": "市立懷生國小",
    "公/私立": "公立",
    "縣市名稱": "[34]臺北市",
    "地址": "[104]臺北市中山區埤頭里安東街16巷2號",
    "電話": "(02)27710846",
    "網址": "http://www.wses.tp.edu.tw"
  },
  {
    "代碼": "343610",
    "學校名稱": "市立永安國小",
    "公/私立": "公立",
    "縣市名稱": "[34]臺北市",
    "地址": "[104]臺北市中山區明水路397巷19弄1號",
    "電話": "(02)25335672",
    "網址": "http://www.yaes.tp.edu.tw"
  },
  {
    "代碼": "343611",
    "學校名稱": "市立濱江國小",
    "公/私立": "公立",
    "縣市名稱": "[34]臺北市",
    "地址": "[104]臺北市中山區樂群二路266巷99號",
    "電話": "(02)85021571",
    "網址": "http://www.bjes.tp.edu.tw"
  },
  {
    "代碼": "353601",
    "學校名稱": "市立螢橋國小",
    "公/私立": "公立",
    "縣市名稱": "[35]臺北市",
    "地址": "[100]臺北市中正區螢雪里詔安街29號",
    "電話": "(02)23054620",
    "網址": "http://www.yces.tp.edu.tw"
  },
  {
    "代碼": "353602",
    "學校名稱": "市立河堤國小",
    "公/私立": "公立",
    "縣市名稱": "[35]臺北市",
    "地址": "[100]臺北市中正區汀州路二段180號",
    "電話": "(02)23677144",
    "網址": "http://www.htps.tp.edu.tw"
  },
  {
    "代碼": "353603",
    "學校名稱": "市立忠義國小",
    "公/私立": "公立",
    "縣市名稱": "[35]臺北市",
    "地址": "[100]臺北市中正區中華路二段307巷17號",
    "電話": "(02)23038752",
    "網址": "http://www.cips.tp.edu.tw"
  },
  {
    "代碼": "353604",
    "學校名稱": "市立國語實小",
    "公/私立": "公立",
    "縣市名稱": "[35]臺北市",
    "地址": "[100]臺北市中正區龍光里南海路58號",
    "電話": "(02)23033555",
    "網址": "http://www.meps.tp.edu.tw"
  },
  {
    "代碼": "353605",
    "學校名稱": "市立南門國小",
    "公/私立": "公立",
    "縣市名稱": "[35]臺北市",
    "地址": "[100]臺北市中正區南門里廣州街6號",
    "電話": "(02)23715052",
    "網址": "http://www.nmes.tp.edu.tw"
  },
  {
    "代碼": "353606",
    "學校名稱": "市立東門國小",
    "公/私立": "公立",
    "縣市名稱": "[35]臺北市",
    "地址": "[100]臺北市中正區東門里仁愛路一段2-4號",
    "電話": "(02)23412822",
    "網址": "http://www.tmps.tp.edu.tw"
  },
  {
    "代碼": "353607",
    "學校名稱": "市立忠孝國小",
    "公/私立": "公立",
    "縣市名稱": "[35]臺北市",
    "地址": "[100]臺北市中正區梅花里忠孝東路2段101號",
    "電話": "(02)23918170",
    "網址": "http://www.ches.tp.edu.tw"
  },
  {
    "代碼": "353608",
    "學校名稱": "臺北市立大學附小",
    "公/私立": "公立",
    "縣市名稱": "[35]臺北市",
    "地址": "[100]臺北市中正區黎明里公園路29號",
    "電話": "(02)23110395",
    "網址": "http://new.estmue.tp.edu.tw/index.php"
  },
  {
    "代碼": "363601",
    "學校名稱": "市立蓬萊國小",
    "公/私立": "公立",
    "縣市名稱": "[36]臺北市",
    "地址": "[103]臺北市大同區星明里寧夏路35號",
    "電話": "(02)25569835",
    "網址": "http://www.plps.tp.edu.tw"
  },
  {
    "代碼": "363602",
    "學校名稱": "市立日新國小",
    "公/私立": "公立",
    "縣市名稱": "[36]臺北市",
    "地址": "[103]臺北市大同區星明里太原路151號",
    "電話": "(02)25584819",
    "網址": "http://www.zhps.tp.edu.tw"
  },
  {
    "代碼": "363603",
    "學校名稱": "市立太平國小",
    "公/私立": "公立",
    "縣市名稱": "[36]臺北市",
    "地址": "[103]臺北市大同區南芳里延平北路2段239號",
    "電話": "(02)25532229",
    "網址": "http://www.tpes.tp.edu.tw"
  },
  {
    "代碼": "363604",
    "學校名稱": "市立永樂國小",
    "公/私立": "公立",
    "縣市名稱": "[36]臺北市",
    "地址": "[103]臺北市大同區南芳里延平北路2段266號",
    "電話": "(02)25534882",
    "網址": "http://www.ylps.tp.edu.tw"
  },
  {
    "代碼": "363605",
    "學校名稱": "市立雙蓮國小",
    "公/私立": "公立",
    "縣市名稱": "[36]臺北市",
    "地址": "[103]臺北市大同區民權里錦西街51號",
    "電話": "(02)25570309",
    "網址": "http://www.sles.tp.edu.tw"
  },
  {
    "代碼": "363606",
    "學校名稱": "市立大同國小",
    "公/私立": "公立",
    "縣市名稱": "[36]臺北市",
    "地址": "[103]臺北市大同區蓬萊里大龍街51號",
    "電話": "(02)25965407",
    "網址": "http://www.ttps.tp.edu.tw"
  },
  {
    "代碼": "363607",
    "學校名稱": "市立大龍國小",
    "公/私立": "公立",
    "縣市名稱": "[36]臺北市",
    "地址": "[103]臺北市大同區保安里哈密街47號",
    "電話": "(02)25942635",
    "網址": "http://www.dlps.tp.edu.tw"
  },
  {
    "代碼": "363608",
    "學校名稱": "市立延平國小",
    "公/私立": "公立",
    "縣市名稱": "[36]臺北市",
    "地址": "[103]臺北市大同區大龍街187巷1號(蘭州國中)",
    "電話": "(02)25942439",
    "網址": "http://www.ypps.tp.edu.tw"
  },
  {
    "代碼": "363609",
    "學校名稱": "市立大橋國小",
    "公/私立": "公立",
    "縣市名稱": "[36]臺北市",
    "地址": "[103]臺北市大同區隆和里重慶北路3段2號",
    "電話": "(02)25944413",
    "網址": "http://www.tjps.tp.edu.tw"
  },
  {
    "代碼": "371601",
    "學校名稱": "私立光仁小學",
    "公/私立": "私立",
    "縣市名稱": "[37]臺北市",
    "地址": "[108]臺北市萬華區壽德里萬大路423巷15號",
    "電話": "(02)23032874",
    "網址": "http://www.kjes.tp.edu.tw"
  },
  {
    "代碼": "373601",
    "學校名稱": "市立新和國小",
    "公/私立": "公立",
    "縣市名稱": "[37]臺北市",
    "地址": "[108]臺北市萬華區西藏路125巷31號",
    "電話": "(02)23038298",
    "網址": "http://www.soes.tp.edu.tw"
  },
  {
    "代碼": "373602",
    "學校名稱": "市立雙園國小",
    "公/私立": "公立",
    "縣市名稱": "[37]臺北市",
    "地址": "[108]臺北市萬華區雙園里莒光路315號",
    "電話": "(02)23061893",
    "網址": "http://www.syps.tp.edu.tw"
  },
  {
    "代碼": "373603",
    "學校名稱": "市立東園國小",
    "公/私立": "公立",
    "縣市名稱": "[37]臺北市",
    "地址": "[108]臺北市萬華區全德里東園街195號",
    "電話": "(02)23034803",
    "網址": "http://www.tyes.tp.edu.tw"
  },
  {
    "代碼": "373604",
    "學校名稱": "市立大理國小",
    "公/私立": "公立",
    "縣市名稱": "[37]臺北市",
    "地址": "[108]臺北市萬華區綠堤里艋舺大道389號",
    "電話": "(02)23064311",
    "網址": "http://www.tlps.tp.edu.tw"
  },
  {
    "代碼": "373605",
    "學校名稱": "市立西園國小",
    "公/私立": "公立",
    "縣市名稱": "[37]臺北市",
    "地址": "[108]臺北市萬華區日善里東園街73巷65號",
    "電話": "(02)23030257",
    "網址": "http://www.hups.tp.edu.tw"
  },
  {
    "代碼": "373606",
    "學校名稱": "市立萬大國小",
    "公/私立": "公立",
    "縣市名稱": "[37]臺北市",
    "地址": "[108]臺北市萬華區全德里萬大路346號",
    "電話": "(02)23037654",
    "網址": "http://www.wtps.tp.edu.tw"
  },
  {
    "代碼": "373607",
    "學校名稱": "市立華江國小",
    "公/私立": "公立",
    "縣市名稱": "[37]臺北市",
    "地址": "[108]臺北市萬華區環河南路2段250巷42弄2號",
    "電話": "(02)23064352",
    "網址": "http://web.hces.tp.edu.tw/front/bin/home.phtml"
  },
  {
    "代碼": "373608",
    "學校名稱": "市立西門國小",
    "公/私立": "公立",
    "縣市名稱": "[37]臺北市",
    "地址": "[108]臺北市萬華區西門里成都路98號",
    "電話": "(02)23113519",
    "網址": "http://www.hmes.tp.edu.tw"
  },
  {
    "代碼": "373609",
    "學校名稱": "市立老松國小",
    "公/私立": "公立",
    "縣市名稱": "[37]臺北市",
    "地址": "[108]臺北市萬華區福音里桂林路64號",
    "電話": "(02)23361266",
    "網址": "http://www.tlsps.tp.edu.tw"
  },
  {
    "代碼": "373610",
    "學校名稱": "市立龍山國小",
    "公/私立": "公立",
    "縣市名稱": "[37]臺北市",
    "地址": "[108]臺北市萬華區青山里和平西路三段235號",
    "電話": "(02)23082977",
    "網址": "http://www.lses.tp.edu.tw"
  },
  {
    "代碼": "373611",
    "學校名稱": "市立福星國小",
    "公/私立": "公立",
    "縣市名稱": "[37]臺北市",
    "地址": "[108]臺北市萬華區福星里中華路一段66號",
    "電話": "(02)23144668",
    "網址": "http://www.fhps.tp.edu.tw"
  },
  {
    "代碼": "380601",
    "學校名稱": "國立政大實小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區指南路三段12號",
    "電話": "(02)29393610",
    "網址": "http://www.es.nccu.edu.tw"
  },
  {
    "代碼": "381601",
    "學校名稱": "私立靜心小學",
    "公/私立": "私立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區興福里興隆路2段46號",
    "電話": "(02)29323118",
    "網址": "http://www.chjhs.tp.edu.tw"
  },
  {
    "代碼": "381602",
    "學校名稱": "私立中山小學",
    "公/私立": "私立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區木柵路1段292號",
    "電話": "(02)29360935",
    "網址": "http://www.mjcskg.tp.edu.tw"
  },
  {
    "代碼": "381603",
    "學校名稱": "私立再興小學",
    "公/私立": "私立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區明義里興隆路4段2號",
    "電話": "(02)29371118",
    "網址": "http://www.thsh.tp.edu.tw/web/TSPS"
  },
  {
    "代碼": "383601",
    "學校名稱": "市立景美國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區景行里景文街108號",
    "電話": "(02)29322151",
    "網址": "http://www.cmes.tp.edu.tw"
  },
  {
    "代碼": "383602",
    "學校名稱": "市立武功國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區萬祥里興隆路1段68號",
    "電話": "(02)29314360",
    "網址": "http://www.wkps.tp.edu.tw"
  },
  {
    "代碼": "383603",
    "學校名稱": "市立興德國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區興邦里興隆路2段235號",
    "電話": "(02)29329431",
    "網址": "http://www.hdps.tp.edu.tw"
  },
  {
    "代碼": "383604",
    "學校名稱": "市立溪口國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區景慶里景福街225號",
    "電話": "(02)29350955",
    "網址": "http://web.skps.tp.edu.tw/enableweb/"
  },
  {
    "代碼": "383605",
    "學校名稱": "市立興隆國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區興望里福興路2號",
    "電話": "(02)29323131",
    "網址": "http://www.hlps.tp.edu.tw"
  },
  {
    "代碼": "383606",
    "學校名稱": "市立志清國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區景仁里景福街21巷5號",
    "電話": "(02)29323875",
    "網址": "http://www.jcps.tp.edu.tw"
  },
  {
    "代碼": "383607",
    "學校名稱": "市立景興國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區興安里景華街150巷21號",
    "電話": "(02)29329439",
    "網址": "http://www.chps.tp.edu.tw"
  },
  {
    "代碼": "383608",
    "學校名稱": "市立木柵國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區木柵里木柵路3段191號",
    "電話": "(02)29391234",
    "網址": "http://www.mjes.tp.edu.tw"
  },
  {
    "代碼": "383609",
    "學校名稱": "市立永建國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區木柵路1段311巷1號",
    "電話": "(02)29377199",
    "網址": "http://www.yjps.tp.edu.tw"
  },
  {
    "代碼": "383610",
    "學校名稱": "市立實踐國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區樟文里忠順街1段4號",
    "電話": "(02)29360725",
    "網址": "http://www.zjps.tp.edu.tw"
  },
  {
    "代碼": "383611",
    "學校名稱": "市立博嘉實驗國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區木柵路4段159巷14-1號",
    "電話": "(02)22302585",
    "網址": "http://www.bjps.tp.edu.tw"
  },
  {
    "代碼": "383612",
    "學校名稱": "市立指南國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區指南路三段38巷5-2號",
    "電話": "(02)29393546",
    "網址": "http://www.cnps.tp.edu.tw"
  },
  {
    "代碼": "383613",
    "學校名稱": "市立明道國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區明興里木柵路2段138巷61號",
    "電話": "(02)29392821",
    "網址": "http://www.mdps.tp.edu.tw"
  },
  {
    "代碼": "383614",
    "學校名稱": "市立萬芳國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區萬芳里萬和街1號",
    "電話": "(02)22301232",
    "網址": "http://www.wfes.tp.edu.tw"
  },
  {
    "代碼": "383615",
    "學校名稱": "市立力行國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區樟腳里木新路3段155巷7號",
    "電話": "(02)29363995",
    "網址": "http://www.lsps.tp.edu.tw"
  },
  {
    "代碼": "383616",
    "學校名稱": "市立萬興國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區萬興里秀明路2段114號",
    "電話": "(02)29381721",
    "網址": "http://www.wxes.tp.edu.tw/"
  },
  {
    "代碼": "383617",
    "學校名稱": "市立萬福國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區羅斯福路5段170巷32號",
    "電話": "(02)29353123",
    "網址": "http://www.wfps.tp.edu.tw"
  },
  {
    "代碼": "383618",
    "學校名稱": "市立興華國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區興光里興隆路3段125巷6號",
    "電話": "(02)22393070",
    "網址": "http://www.hhps.tp.edu.tw"
  },
  {
    "代碼": "383619",
    "學校名稱": "市立辛亥國小",
    "公/私立": "公立",
    "縣市名稱": "[38]臺北市",
    "地址": "[116]臺北市文山區興昌里辛亥路4段103號",
    "電話": "(02)29357282",
    "網址": "http://www.saes.tp.edu.tw"
  },
  {
    "代碼": "393601",
    "學校名稱": "市立南港國小",
    "公/私立": "公立",
    "縣市名稱": "[39]臺北市",
    "地址": "[115]臺北市南港區三重里惠民街67號",
    "電話": "(02)27834678",
    "網址": "http://www.nkps.tp.edu.tw"
  },
  {
    "代碼": "393602",
    "學校名稱": "市立舊莊國小",
    "公/私立": "公立",
    "縣市名稱": "[39]臺北市",
    "地址": "[115]臺北市南港區舊莊里舊莊街1段100號",
    "電話": "(02)27821418",
    "網址": "http://www.zzes.tp.edu.tw"
  },
  {
    "代碼": "393603",
    "學校名稱": "市立玉成國小",
    "公/私立": "公立",
    "縣市名稱": "[39]臺北市",
    "地址": "[115]臺北市南港區西新里向陽路31號",
    "電話": "(02)27836049",
    "網址": "http://www.yhes.tp.edu.tw"
  },
  {
    "代碼": "393604",
    "學校名稱": "市立成德國小",
    "公/私立": "公立",
    "縣市名稱": "[39]臺北市",
    "地址": "[115]臺北市南港區聯成里東新街65號",
    "電話": "(02)27851376",
    "網址": "http://www.ctps.tp.edu.tw"
  },
  {
    "代碼": "393605",
    "學校名稱": "市立胡適國小",
    "公/私立": "公立",
    "縣市名稱": "[39]臺北市",
    "地址": "[115]臺北市南港區中研里舊莊街1段1號",
    "電話": "(02)27824949",
    "網址": "http://www.hsps.tp.edu.tw"
  },
  {
    "代碼": "393606",
    "學校名稱": "市立東新國小",
    "公/私立": "公立",
    "縣市名稱": "[39]臺北市",
    "地址": "[115]臺北市南港區東新里興南街62號",
    "電話": "(02)27837577",
    "網址": "http://www.thes.tp.edu.tw"
  },
  {
    "代碼": "393607",
    "學校名稱": "市立修德國小",
    "公/私立": "公立",
    "縣市名稱": "[39]臺北市",
    "地址": "[115]臺北市南港區東新街118巷86號",
    "電話": "(02)27880500",
    "網址": "http://www.sdps.tp.edu.tw"
  },
  {
    "代碼": "403601",
    "學校名稱": "市立內湖國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖區港富里內湖路二段41號",
    "電話": "(02)27998085",
    "網址": "http://www.nhes.tp.edu.tw"
  },
  {
    "代碼": "403602",
    "學校名稱": "市立碧湖國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖區內湖里金龍路100號",
    "電話": "(02)27907161",
    "網址": "http://www.bhps.tp.edu.tw"
  },
  {
    "代碼": "403603",
    "學校名稱": "市立潭美國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖區行善路179號",
    "電話": "(02)27917334",
    "網址": "http://www.tmes.tp.edu.tw"
  },
  {
    "代碼": "403604",
    "學校名稱": "市立東湖國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖區東湖里東湖路115號",
    "電話": "(02)26339984",
    "網址": "http://w3.dfps.tp.edu.tw"
  },
  {
    "代碼": "403605",
    "學校名稱": "市立西湖國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖區西湖里環山路1段25號",
    "電話": "(02)27971267",
    "網址": "http://www.hhups.tp.edu.tw"
  },
  {
    "代碼": "403606",
    "學校名稱": "市立康寧國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖區清白里星雲街121號",
    "電話": "(02)27901237",
    "網址": "http://www.klps.tp.edu.tw"
  },
  {
    "代碼": "403607",
    "學校名稱": "市立明湖國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖區東湖里康寧路3段105號",
    "電話": "(02)26323477",
    "網址": "http://www.mhups.tp.edu.tw"
  },
  {
    "代碼": "403608",
    "學校名稱": "市立麗山國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖區港都里港華街100號",
    "電話": "(02)26574158",
    "網址": "http://www.lnps.tp.edu.tw"
  },
  {
    "代碼": "403609",
    "學校名稱": "市立新湖國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖區湖興里民權東路6段138號",
    "電話": "(02)27963721",
    "網址": "http://www.shes.tp.edu.tw"
  },
  {
    "代碼": "403610",
    "學校名稱": "市立文湖國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖區西康里文湖街15號",
    "電話": "(02)26583515",
    "網址": "http://www.whups.tp.edu.tw"
  },
  {
    "代碼": "403611",
    "學校名稱": "市立大湖國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖區大湖里大湖山莊街170號",
    "電話": "(02)27915870",
    "網址": "http://www.dhps.tp.edu.tw"
  },
  {
    "代碼": "403612",
    "學校名稱": "市立南湖國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖區葫洲里康寧路3段200號",
    "電話": "(02)26321296",
    "網址": "http://www.nhps.tp.edu.tw"
  },
  {
    "代碼": "403613",
    "學校名稱": "市立麗湖國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖區金湖路363巷8號",
    "電話": "(02)26343888",
    "網址": "http://www.lhes.tp.edu.tw/"
  },
  {
    "代碼": "411601",
    "學校名稱": "私立華興小學",
    "公/私立": "私立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區芝山里仰德大道1段101號",
    "電話": "(02)28316834",
    "網址": "http://www.hhhs.tp.edu.tw"
  },
  {
    "代碼": "413601",
    "學校名稱": "市立士林國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區福德里大東路165號",
    "電話": "(02)28812231",
    "網址": "http://www.slps.tp.edu.tw"
  },
  {
    "代碼": "413602",
    "學校名稱": "市立士東國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區蘭雅里中山北路6段392號",
    "電話": "(02)28710064",
    "網址": "http://www.stes.tp.edu.tw"
  },
  {
    "代碼": "413603",
    "學校名稱": "市立福林國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區福志里福志路75號",
    "電話": "(02)28316293",
    "網址": "http://www.flps.tp.edu.tw"
  },
  {
    "代碼": "413604",
    "學校名稱": "市立陽明山國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區新安里仰德大道3段61號",
    "電話": "(02)28616366",
    "網址": "http://www.ymps.tp.edu.tw"
  },
  {
    "代碼": "413605",
    "學校名稱": "市立社子國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區永倫里延平北路六段308號",
    "電話": "(02)28126195",
    "網址": "http://www.stps.tp.edu.tw"
  },
  {
    "代碼": "413606",
    "學校名稱": "市立雨聲國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區岩山里至誠路1段62巷70號",
    "電話": "(02)28311004",
    "網址": "http://www.yses.tp.edu.tw"
  },
  {
    "代碼": "413607",
    "學校名稱": "市立富安國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區富洲里延平北路8段135號",
    "電話": "(02)28103192",
    "網址": "http://www.fuanps.tp.edu.tw"
  },
  {
    "代碼": "413608",
    "學校名稱": "市立劍潭國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區明勝里通河街16號",
    "電話": "(02)28855491",
    "網址": "http://www.jtes.tp.edu.tw"
  },
  {
    "代碼": "413609",
    "學校名稱": "市立溪山實驗國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區溪山里至善路3段199號",
    "電話": "(02)28411010",
    "網址": "http://www.hops.tp.edu.tw"
  },
  {
    "代碼": "413610",
    "學校名稱": "市立平等國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區平等里平菁街101號",
    "電話": "(02)28610503",
    "網址": "http://www.pdps.tp.edu.tw"
  },
  {
    "代碼": "413611",
    "學校名稱": "市立百齡國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區福中里福港街205號",
    "電話": "(02)28817683",
    "網址": "http://www.bles.tp.edu.tw"
  },
  {
    "代碼": "413612",
    "學校名稱": "市立雙溪國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區翠山里中社路2段66號",
    "電話": "(02)28411038",
    "網址": "http://www.sses.tp.edu.tw"
  },
  {
    "代碼": "413613",
    "學校名稱": "市立葫蘆國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區環河北路3段95號",
    "電話": "(02)28129586",
    "網址": "http://www.hrps.tp.edu.tw"
  },
  {
    "代碼": "413614",
    "學校名稱": "市立雨農國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區名山里忠義街1號",
    "電話": "(02)28329700",
    "網址": "http://www.ynes.tp.edu.tw/enable2007/"
  },
  {
    "代碼": "413615",
    "學校名稱": "市立天母國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區天玉里天玉街12號",
    "電話": "(02)28723336",
    "網址": "http://www.tmups.tp.edu.tw"
  },
  {
    "代碼": "413616",
    "學校名稱": "市立文昌國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區文林路615巷20號",
    "電話": "(02)28365411",
    "網址": "http://www.wces.tp.edu.tw"
  },
  {
    "代碼": "413617",
    "學校名稱": "市立芝山國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區芝山里德行東路285號",
    "電話": "(02)28316115",
    "網址": "http://www.zsps.tp.edu.tw"
  },
  {
    "代碼": "413618",
    "學校名稱": "市立蘭雅國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區蘭興里磺溪街57號",
    "電話": "(02)28366052",
    "網址": "http://www.lyps.tp.edu.tw"
  },
  {
    "代碼": "413619",
    "學校名稱": "市立三玉國小",
    "公/私立": "公立",
    "縣市名稱": "[41]臺北市",
    "地址": "[111]臺北市士林區三玉里天母東路116號",
    "電話": "(02)28751369",
    "網址": "http://www.syups.tp.edu.tw"
  },
  {
    "代碼": "421601",
    "學校名稱": "私立薇閣小學",
    "公/私立": "私立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區長安里育仁路106號",
    "電話": "(02)28912668",
    "網址": "http://www.wgps.tp.edu.tw"
  },
  {
    "代碼": "423601",
    "學校名稱": "市立北投國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區長安里中央北路1段73號",
    "電話": "(02)28912052",
    "網址": "http://www.ptes.tp.edu.tw"
  },
  {
    "代碼": "423602",
    "學校名稱": "市立逸仙國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區中心里新民路2號",
    "電話": "(02)28914537",
    "網址": "http://www.ysps.tp.edu.tw"
  },
  {
    "代碼": "423603",
    "學校名稱": "市立石牌國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區吉利里致遠二路80號",
    "電話": "(02)28227484",
    "網址": "http://web.spps.tp.edu.tw"
  },
  {
    "代碼": "423604",
    "學校名稱": "市立關渡國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區一德里中央北路4段581號",
    "電話": "(02)28912847",
    "網址": "http://www.kdps.tp.edu.tw"
  },
  {
    "代碼": "423605",
    "學校名稱": "市立湖田實驗國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區湖田里竹子湖路17之2號",
    "電話": "(02)28616963",
    "網址": "http://www.htes.tp.edu.tw"
  },
  {
    "代碼": "423606",
    "學校名稱": "市立清江國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區奇岩里公館路220號",
    "電話": "(02)28912764",
    "網址": "http://www.cjps.tp.edu.tw"
  },
  {
    "代碼": "423607",
    "學校名稱": "市立泉源實驗國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區泉源里東昇路34號",
    "電話": "(02)28951258",
    "網址": "http://www.cyps.tp.edu.tw"
  },
  {
    "代碼": "423608",
    "學校名稱": "市立大屯國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區大屯里復興三路312號",
    "電話": "(02)28914353",
    "網址": "http://www.dtps.tp.edu.tw"
  },
  {
    "代碼": "423609",
    "學校名稱": "市立湖山國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區湖山里湖底路11號",
    "電話": "(02)28610148",
    "網址": "http://www.hses.tp.edu.tw"
  },
  {
    "代碼": "423610",
    "學校名稱": "市立桃源國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區中央北路3段40巷45號",
    "電話": "(02)28941208",
    "網址": "http://www.tyues.tp.edu.tw"
  },
  {
    "代碼": "423611",
    "學校名稱": "市立文林國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區建民里文林北路155號",
    "電話": "(02)28234212",
    "網址": "http://www.wles.tp.edu.tw"
  },
  {
    "代碼": "423612",
    "學校名稱": "市立義方國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區珠海路155號",
    "電話": "(02)28917433",
    "網址": "http://www.yfes.tp.edu.tw"
  },
  {
    "代碼": "423613",
    "學校名稱": "市立立農國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區立農里立農街1段250號",
    "電話": "(02)28210702",
    "網址": "http://www.lnes.tp.edu.tw"
  },
  {
    "代碼": "423614",
    "學校名稱": "市立明德國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區榮華里明德路190號",
    "電話": "(02)28229651",
    "網址": "http://www.mdes.tp.edu.tw"
  },
  {
    "代碼": "423616",
    "學校名稱": "市立文化國小",
    "公/私立": "公立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區文化里文化三路1號",
    "電話": "(02)28933828",
    "網址": "http://www.whps.tp.edu.tw"
  },
  {
    "代碼": "031601",
    "學校名稱": "私立福祿貝爾國小",
    "公/私立": "私立",
    "縣市名稱": "[03]桃園市",
    "地址": "[325]桃園市龍潭區金龍路223號",
    "電話": "(03)4899899",
    "網址": "http://www.fies.tyc.edu.tw"
  },
  {
    "代碼": "031602",
    "學校名稱": "私立諾瓦國小",
    "公/私立": "私立",
    "縣市名稱": "[03]桃園市",
    "地址": "[325]桃園市龍潭區渴望園區渴望路660巷50弄1號",
    "電話": "(03)4072882",
    "網址": "http://www.renoir.com.tw"
  },
  {
    "代碼": "031603",
    "學校名稱": "私立有得國(中)小",
    "公/私立": "私立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區內壢長春一路288號",
    "電話": "(03)4522789",
    "網址": "http://www.yoderedu.org/"
  },
  {
    "代碼": "031604",
    "學校名稱": "私立康萊爾國(中)小",
    "公/私立": "私立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區力行路288巷8號",
    "電話": "(03)3026889",
    "網址": "http://www.cornell.tyc.edu.tw/"
  },
  {
    "代碼": "034601",
    "學校名稱": "市立桃園國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區民權路67號",
    "電話": "(03)3322268",
    "網址": "http://163.30.44.1/"
  },
  {
    "代碼": "034602",
    "學校名稱": "市立東門國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區東門里東國街14號",
    "電話": "(03)3322057",
    "網址": "http://www.tmps.tyc.edu.tw"
  },
  {
    "代碼": "034603",
    "學校名稱": "市立中埔國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區永安路1054號",
    "電話": "(03)3013028",
    "網址": "http://www.jbes.tyc.edu.tw"
  },
  {
    "代碼": "034604",
    "學校名稱": "市立成功國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區三民路三段22號",
    "電話": "(03)3322772",
    "網址": "http://www.cges.tyc.edu.tw"
  },
  {
    "代碼": "034605",
    "學校名稱": "市立會稽國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區春日路1080號",
    "電話": "(03)3252982",
    "網址": "http://www.gjes.tyc.edu.tw"
  },
  {
    "代碼": "034606",
    "學校名稱": "市立建國國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區昆明路95號",
    "電話": "(03)3636660",
    "網址": "http://www.jkes.tyc.edu.tw"
  },
  {
    "代碼": "034607",
    "學校名稱": "市立中山國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區國際路一段1070號",
    "電話": "(03)3603012",
    "網址": "http://www.chses.tyc.edu.tw"
  },
  {
    "代碼": "034608",
    "學校名稱": "市立文山國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區文中路120號",
    "電話": "(03)3602013",
    "網址": "http://www.wsps.tyc.edu.tw"
  },
  {
    "代碼": "034609",
    "學校名稱": "市立南門國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區復興路303號",
    "電話": "(03)3370576",
    "網址": "http://www.nmes.tyc.edu.tw"
  },
  {
    "代碼": "034610",
    "學校名稱": "市立西門國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區莒光街15號",
    "電話": "(03)3342351",
    "網址": "http://www.simes.tyc.edu.tw"
  },
  {
    "代碼": "034611",
    "學校名稱": "市立龍山國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區龍泉二街36號",
    "電話": "(03)3691253",
    "網址": "http://www.loses.tyc.edu.tw"
  },
  {
    "代碼": "034612",
    "學校名稱": "市立北門國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區正康三街139號",
    "電話": "(03)3250959",
    "網址": "http://www.pmes.tyc.edu.tw"
  },
  {
    "代碼": "034613",
    "學校名稱": "市立南崁國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區羊稠里吉林路160號",
    "電話": "(03)3115578",
    "網址": "http://www.nkes.tyc.edu.tw"
  },
  {
    "代碼": "034614",
    "學校名稱": "市立公埔國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區南山路二段448號",
    "電話": "(03)3243852",
    "網址": "http://www.gpes.tyc.edu.tw"
  },
  {
    "代碼": "034615",
    "學校名稱": "市立蘆竹國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區富國路二段850號",
    "電話": "(03)3221731",
    "網址": "http://www.lces.tyc.edu.tw"
  },
  {
    "代碼": "034616",
    "學校名稱": "市立大竹國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區大竹路556號",
    "電話": "(03)3232917",
    "網址": "http://www.dces.tyc.edu.tw"
  },
  {
    "代碼": "034617",
    "學校名稱": "市立新興國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區新興里新興街355號",
    "電話": "(03)3602448",
    "網址": "http://www.ssps.tyc.edu.tw"
  },
  {
    "代碼": "034618",
    "學校名稱": "市立外社國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區山林路二段525號",
    "電話": "(03)3241874",
    "網址": "http://www.wses.tyc.edu.tw"
  },
  {
    "代碼": "034619",
    "學校名稱": "市立頂社國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區山林路三段232號",
    "電話": "(03)3241182",
    "網址": "http://www.dises.tyc.edu.tw"
  },
  {
    "代碼": "034620",
    "學校名稱": "市立海湖國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區海湖東路191巷15號",
    "電話": "(03)3542181",
    "網址": "http://www.hwes.tyc.edu.tw"
  },
  {
    "代碼": "034621",
    "學校名稱": "市立山腳國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區南山路三段440號",
    "電話": "(03)3241884",
    "網址": "http://www.sajes.tyc.edu.tw"
  },
  {
    "代碼": "034622",
    "學校名稱": "市立大華國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區宏竹里大華街98號",
    "電話": "(03)3232664",
    "網址": "http://www.thps.tyc.edu.tw"
  },
  {
    "代碼": "034623",
    "學校名稱": "市立新莊國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區大新路380巷121號",
    "電話": "(03)3231264",
    "網址": "http://www.sjps.tyc.edu.tw"
  },
  {
    "代碼": "034624",
    "學校名稱": "市立大園國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[337]桃園市大園區橫峰里中正東路160號",
    "電話": "(03)3862030",
    "網址": "http://www.dyps.tyc.edu.tw"
  },
  {
    "代碼": "034625",
    "學校名稱": "市立圳頭國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[337]桃園市大園區圳頭路850巷30號",
    "電話": "(03)3862504",
    "網址": "http://www.jtes.tyc.edu.tw"
  },
  {
    "代碼": "034626",
    "學校名稱": "市立內海國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[337]桃園市大園區內海里2鄰學府路76巷32號",
    "電話": "(03)3862844",
    "網址": "http://www.nhes.tyc.edu.tw"
  },
  {
    "代碼": "034627",
    "學校名稱": "市立溪海國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[337]桃園市大園區溪海里十一鄰和平西路一段420號",
    "電話": "(03)3862174",
    "網址": "http://www.hhes.tyc.edu.tw"
  },
  {
    "代碼": "034628",
    "學校名稱": "市立潮音國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[337]桃園市大園區潮音路一段188號",
    "電話": "(03)3862834",
    "網址": "http://www.cies.tyc.edu.tw"
  },
  {
    "代碼": "034629",
    "學校名稱": "市立竹圍國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[337]桃園市大園區竹圍里二鄰竹圍街3號",
    "電話": "(03)3835079",
    "網址": "http://www.jwes.tyc.edu.tw"
  },
  {
    "代碼": "034630",
    "學校名稱": "市立果林國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[337]桃園市大園區菓林里十二鄰崁下41號",
    "電話": "(03)3835096",
    "網址": "http://www.kles.tyc.edu.tw"
  },
  {
    "代碼": "034631",
    "學校名稱": "市立后厝國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[337]桃園市大園區國際路二段147號",
    "電話": "(03)3869583",
    "網址": "http://www.htes.tyc.edu.tw"
  },
  {
    "代碼": "034632",
    "學校名稱": "市立沙崙國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[337]桃園市大園區沙崙里九鄰75號之2",
    "電話": "(03)3835072",
    "網址": "http://www.sales.tyc.edu.tw"
  },
  {
    "代碼": "034633",
    "學校名稱": "市立埔心國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[337]桃園市大園區中正東路一段189巷35號",
    "電話": "(03)3812304",
    "網址": "http://www.puses.tyc.edu.tw"
  },
  {
    "代碼": "034634",
    "學校名稱": "市立五權國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[337]桃園市大園區中正東路二段539號",
    "電話": "(03)3812803",
    "網址": "http://www.wqes.tyc.edu.tw"
  },
  {
    "代碼": "034635",
    "學校名稱": "市立陳康國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[337]桃園市大園區建國八村151號",
    "電話": "(03)3811092",
    "網址": "http://www.ckps.tyc.edu.tw"
  },
  {
    "代碼": "034636",
    "學校名稱": "市立龜山國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區萬壽路二段933巷14號",
    "電話": "(03)3203571",
    "網址": "http://www.kses.tyc.edu.tw"
  },
  {
    "代碼": "034637",
    "學校名稱": "市立壽山國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區萬壽路二段6巷61號",
    "電話": "(03)3291782",
    "網址": "http://www.sses.tyc.edu.tw"
  },
  {
    "代碼": "034638",
    "學校名稱": "市立福源國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區兔坑里大同路916號",
    "電話": "(03)3297276",
    "網址": "http://www.fyes.tyc.edu.tw"
  },
  {
    "代碼": "034639",
    "學校名稱": "市立大崗國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區大崗里20鄰大湖一路175號",
    "電話": "(03)3282457",
    "網址": "http://www.dges.tyc.edu.tw"
  },
  {
    "代碼": "034640",
    "學校名稱": "市立大埔國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區振興路1169號 ",
    "電話": "(03)3298329",
    "網址": "http://www.dpps.tyc.edu.tw"
  },
  {
    "代碼": "034641",
    "學校名稱": "市立大坑國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區大坑路一段850號",
    "電話": "(03)3252430",
    "網址": "http://www.dkes.tyc.edu.tw"
  },
  {
    "代碼": "034642",
    "學校名稱": "市立山頂國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區山德里頂興路2號",
    "電話": "(03)3327244",
    "網址": "http://www.sdps.tyc.edu.tw"
  },
  {
    "代碼": "034643",
    "學校名稱": "市立龍壽國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區龍壽里龍校街30號",
    "電話": "(03)3296554",
    "網址": "http://www.lnses.tyc.edu.tw"
  },
  {
    "代碼": "034644",
    "學校名稱": "市立新路國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區新路里永和街12號",
    "電話": "(03)3203890",
    "網址": "http://www.slps.tyc.edu.tw"
  },
  {
    "代碼": "034645",
    "學校名稱": "市立樂善國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區樂善里11鄰樂安街71號",
    "電話": "(03)3281002",
    "網址": "http://www.leses.tyc.edu.tw"
  },
  {
    "代碼": "034646",
    "學校名稱": "市立大成國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[334]桃園市八德區廣福路31號",
    "電話": "(03)3661155",
    "網址": "http://www.dches.tyc.edu.tw"
  },
  {
    "代碼": "034647",
    "學校名稱": "市立大勇國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[334]桃園市八德區大勇里自強街60號",
    "電話": "(03)3622017",
    "網址": "http://www.typs.tyc.edu.tw"
  },
  {
    "代碼": "034648",
    "學校名稱": "市立八德國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[334]桃園市八德區興豐路222號",
    "電話": "(03)3682943",
    "網址": "http://www.bdes.tyc.edu.tw"
  },
  {
    "代碼": "034649",
    "學校名稱": "市立瑞豐國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[334]桃園市八德區介壽路二段933巷40號",
    "電話": "(03)3682787",
    "網址": "http://www.rfes.tyc.edu.tw"
  },
  {
    "代碼": "034650",
    "學校名稱": "市立霄裡國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[334]桃園市八德區山下街115號",
    "電話": "(03)3651101",
    "網址": "http://www.slies.tyc.edu.tw"
  },
  {
    "代碼": "034651",
    "學校名稱": "市立大安國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[334]桃園市八德區大安里和平路638號",
    "電話": "(03)3661419",
    "網址": "http://www.daes.tyc.edu.tw"
  },
  {
    "代碼": "034652",
    "學校名稱": "市立茄苳國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[334]桃園市八德區永豐路155號",
    "電話": "(03)3611425",
    "網址": "http://www.jdes.tyc.edu.tw"
  },
  {
    "代碼": "034653",
    "學校名稱": "市立廣興國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[334]桃園市八德區廣興路601巷25號",
    "電話": "(03)3666007",
    "網址": "http://www.kuhes.tyc.edu.tw/"
  },
  {
    "代碼": "034654",
    "學校名稱": "市立大溪國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區登龍路19號",
    "電話": "(03)3882040",
    "網址": "http://www.dsps.tyc.edu.tw"
  },
  {
    "代碼": "034655",
    "學校名稱": "市立美華國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區金山路50號 ",
    "電話": "(03)3882403",
    "網址": "http://www.mhes.tyc.edu.tw"
  },
  {
    "代碼": "034656",
    "學校名稱": "市立內柵國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區義和里三鄰安和路38號",
    "電話": "(03)3882461",
    "網址": "http://www.njes.tyc.edu.tw"
  },
  {
    "代碼": "034657",
    "學校名稱": "市立福安國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區福安里十六鄰復興路一段755號",
    "電話": "(03)3871414",
    "網址": "http://www.faes.tyc.edu.tw/xoops/"
  },
  {
    "代碼": "034658",
    "學校名稱": "市立百吉國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區環湖路二段845號",
    "電話": "(03)3883956",
    "網址": "http://www.pces.tyc.edu.tw"
  },
  {
    "代碼": "034659",
    "學校名稱": "市立瑞祥國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區瑞源里石園路760巷120號",
    "電話": "(03)3801197",
    "網址": "http://www.rhes.tyc.edu.tw"
  },
  {
    "代碼": "034660",
    "學校名稱": "市立中興國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區瑞興里一鄰大鶯路1125號",
    "電話": "(03)3801394",
    "網址": "http://www.ches.tyc.edu.tw"
  },
  {
    "代碼": "034661",
    "學校名稱": "市立員樹林國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區員林路二段450號",
    "電話": "(03)3801502",
    "網址": "http://www.ysles.tyc.edu.tw"
  },
  {
    "代碼": "034662",
    "學校名稱": "市立仁善國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區仁善里埔頂路二段109號",
    "電話": "(03)3801710",
    "網址": "http://www.rses.tyc.edu.tw"
  },
  {
    "代碼": "034663",
    "學校名稱": "市立僑愛國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區仁義里介壽路214號",
    "電話": "(03)3801896",
    "網址": "http://www.caes.tyc.edu.tw"
  },
  {
    "代碼": "034664",
    "學校名稱": "市立南興國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區仁和路二段135號",
    "電話": "(03)3801721",
    "網址": "http://www.nses.tyc.edu.tw"
  },
  {
    "代碼": "034665",
    "學校名稱": "市立永福國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區永福里信義路十二鄰1165號",
    "電話": "(03)3883709",
    "網址": "http://www.yfes.tyc.edu.tw"
  },
  {
    "代碼": "034666",
    "學校名稱": "市立中壢國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區中榮里延平路622號",
    "電話": "(03)4255216",
    "網址": "https://clps.ischool.site/"
  },
  {
    "代碼": "034667",
    "學校名稱": "市立中平國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區過嶺里雙福路12號",
    "電話": "(03)4902025",
    "網址": "http://www.cpes.tyc.edu.tw"
  },
  {
    "代碼": "034668",
    "學校名稱": "市立新明國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區中央西路二段97號",
    "電話": "(03)4933262",
    "網址": "http://www.simps.tyc.edu.tw"
  },
  {
    "代碼": "034669",
    "學校名稱": "市立芭里國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區啟文路233號",
    "電話": "(03)4228086",
    "網址": "http://www.blps.tyc.edu.tw"
  },
  {
    "代碼": "034670",
    "學校名稱": "市立新街國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區新街里延平路176號",
    "電話": "(03)4523202",
    "網址": "http://www.sjes.tyc.edu.tw"
  },
  {
    "代碼": "034671",
    "學校名稱": "市立信義國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區信義里成都路55號",
    "電話": "(03)4573780",
    "網址": "http://www.hyes.tyc.edu.tw"
  },
  {
    "代碼": "034672",
    "學校名稱": "市立普仁國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區中山東路二段425號",
    "電話": "(03)4563830",
    "網址": "http://www.pzps.tyc.edu.tw"
  },
  {
    "代碼": "034673",
    "學校名稱": "市立富台國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區中山東路三段369號",
    "電話": "(03)4563335",
    "網址": "http://www.ftes.tyc.edu.tw"
  },
  {
    "代碼": "034674",
    "學校名稱": "市立青埔國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區新生路4段112號",
    "電話": "(03)4531626",
    "網址": "http://www.cpps.tyc.edu.tw"
  },
  {
    "代碼": "034675",
    "學校名稱": "市立內壢國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區福德路20號",
    "電話": "(03)4635888",
    "網址": "http://www.nlps.tyc.edu.tw"
  },
  {
    "代碼": "034676",
    "學校名稱": "市立大崙國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區崇德路139號",
    "電話": "(03)4983424",
    "網址": "http://www.dles.tyc.edu.tw"
  },
  {
    "代碼": "034677",
    "學校名稱": "市立山東國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區山東里十三鄰山東一路39號",
    "電話": "(03)4981323",
    "網址": "http://www.sdes.tyc.edu.tw"
  },
  {
    "代碼": "034678",
    "學校名稱": "市立中正國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區榮民路329號",
    "電話": "(03)4559313",
    "網址": "http://www.ccps.tyc.edu.tw"
  },
  {
    "代碼": "034679",
    "學校名稱": "市立自立國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區永福路1200號",
    "電話": "(03)4559130",
    "網址": "http://www.zles.tyc.edu.tw"
  },
  {
    "代碼": "034680",
    "學校名稱": "市立龍岡國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區龍岡路二段232號",
    "電話": "(03)4588582",
    "網址": "http://www.lges.tyc.edu.tw"
  },
  {
    "代碼": "034681",
    "學校名稱": "市立內定國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區內定里定寧路31號",
    "電話": "(03)4524624",
    "網址": "http://www.ndes.tyc.edu.tw"
  },
  {
    "代碼": "034682",
    "學校名稱": "市立南勢國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區中豐路南勢二段223號",
    "電話": "(03)4393724",
    "網址": "http://www.nsps.tyc.edu.tw"
  },
  {
    "代碼": "034683",
    "學校名稱": "市立宋屋國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區延平路二段389號",
    "電話": "(03)4933654",
    "網址": "http://www.swes.tyc.edu.tw"
  },
  {
    "代碼": "034684",
    "學校名稱": "市立新勢國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區延平路一段181號",
    "電話": "(03)4937563",
    "網址": "http://163.30.133.1/"
  },
  {
    "代碼": "034685",
    "學校名稱": "市立忠貞國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區龍興里龍南路315號",
    "電話": "(03)4501450",
    "網址": "http://www.jjes.tyc.edu.tw"
  },
  {
    "代碼": "034686",
    "學校名稱": "市立東勢國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區平東路一段185號",
    "電話": "(03)4504034",
    "網址": "http://www.dses.tyc.edu.tw"
  },
  {
    "代碼": "034687",
    "學校名稱": "市立山豐國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區中豐路山頂段375巷45號",
    "電話": "(03)4691784",
    "網址": "http://www.sfes.tyc.edu.tw"
  },
  {
    "代碼": "034688",
    "學校名稱": "市立復旦國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區廣平街1號",
    "電話": "(03)4917491",
    "網址": "http://www.fdes.tyc.edu.tw"
  },
  {
    "代碼": "034689",
    "學校名稱": "市立北勢國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區金陵路二段330號",
    "電話": "(03)4586472",
    "網址": "http://163.30.119.1/xoops/index.php"
  },
  {
    "代碼": "034690",
    "學校名稱": "市立楊梅國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區校前路1號",
    "電話": "(03)4782016",
    "網址": "http://www.ymes.tyc.edu.tw"
  },
  {
    "代碼": "034691",
    "學校名稱": "市立上田國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區上田里九鄰98號",
    "電話": "(03)4782544",
    "網址": "http://www.stps.tyc.edu.tw"
  },
  {
    "代碼": "034692",
    "學校名稱": "市立大同國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區新農街85號",
    "電話": "(03)4782249",
    "網址": "http://www.ttps.tyc.edu.tw"
  },
  {
    "代碼": "034693",
    "學校名稱": "市立富岡國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區豐野里中正路147號",
    "電話": "(03)4721193",
    "網址": "http://www.fges.tyc.edu.tw"
  },
  {
    "代碼": "034694",
    "學校名稱": "市立瑞原國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區民安路1號",
    "電話": "(03)4723280",
    "網址": "http://163.30.193.130/xoops/"
  },
  {
    "代碼": "034695",
    "學校名稱": "市立上湖國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區上湖里上湖二路88號",
    "電話": "(03)4728925",
    "網址": "http://www.shps.tyc.edu.tw"
  },
  {
    "代碼": "034696",
    "學校名稱": "市立水美國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區水美里楊新路一段410號",
    "電話": "(03)4782770",
    "網址": "http://www.smps.tyc.edu.tw"
  },
  {
    "代碼": "034697",
    "學校名稱": "市立瑞埔國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區埔心里中興路133號",
    "電話": "(03)4822018",
    "網址": "http://www.rpes.tyc.edu.tw"
  },
  {
    "代碼": "034698",
    "學校名稱": "市立高榮國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區高山里高上路一段1號",
    "電話": "(03)4782314",
    "網址": "http://www.kjes.tyc.edu.tw"
  },
  {
    "代碼": "034699",
    "學校名稱": "市立四維國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區金龍三路95號",
    "電話": "(03)4822704",
    "網址": "http://www.swps.tyc.edu.tw"
  },
  {
    "代碼": "034700",
    "學校名稱": "市立瑞梅國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區梅溪里19鄰三民北路26巷41號",
    "電話": "(03)4825284",
    "網址": "http://www.rmes.tyc.edu.tw/xoops/"
  },
  {
    "代碼": "034701",
    "學校名稱": "市立新屋國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[327]桃園市新屋區新生里中正路196號",
    "電話": "(03)4772016",
    "網址": "http://www.snwes.tyc.edu.tw"
  },
  {
    "代碼": "034702",
    "學校名稱": "市立啟文國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[327]桃園市新屋區清華里新文路762號",
    "電話": "(03)4772077",
    "網址": "http://www.kves.tyc.edu.tw"
  },
  {
    "代碼": "034703",
    "學校名稱": "市立東明國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[327]桃園市新屋區中山西路一段474號",
    "電話": "(03)4772500",
    "網址": "http://www.tmes.tyc.edu.tw"
  },
  {
    "代碼": "034704",
    "學校名稱": "市立頭洲國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[327]桃園市新屋區中山東路二段668巷64號",
    "電話": "(03)4901204",
    "網址": "http://www.tjes.tyc.edu.tw"
  },
  {
    "代碼": "034705",
    "學校名稱": "市立永安國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[327]桃園市新屋區中山西路二段1320號",
    "電話": "(03)4862224",
    "網址": "http://www.yaes.tyc.edu.tw"
  },
  {
    "代碼": "034706",
    "學校名稱": "市立笨港國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[327]桃園市新屋區笨港里2鄰文學路22號",
    "電話": "(03)4769113",
    "網址": "http://www.bges.tyc.edu.tw"
  },
  {
    "代碼": "034707",
    "學校名稱": "市立北湖國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[327]桃園市新屋區石牌里東興路780號  ",
    "電話": "(03)4862415",
    "網址": "http://www.phps.tyc.edu.tw"
  },
  {
    "代碼": "034708",
    "學校名稱": "市立大坡國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[327]桃園市新屋區大坡里五鄰5號",
    "電話": "(03)4768311",
    "網址": "http://www.dpes.tyc.edu.tw"
  },
  {
    "代碼": "034709",
    "學校名稱": "市立蚵間國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[327]桃園市新屋區後庄村三鄰文化路一段636號",
    "電話": "(03)4768413",
    "網址": "http://www.kces.tyc.edu.tw"
  },
  {
    "代碼": "034710",
    "學校名稱": "市立社子國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[327]桃園市新屋區社子里社福路235號",
    "電話": "(03)4772444",
    "網址": "http://www.szes.tyc.edu.tw"
  },
  {
    "代碼": "034711",
    "學校名稱": "市立埔頂國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[327]桃園市新屋區中華南路二段50號",
    "電話": "(03)4772240",
    "網址": "http://www.ptes.tyc.edu.tw"
  },
  {
    "代碼": "034712",
    "學校名稱": "市立觀音國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[328]桃園市觀音區觀音里四鄰文化路2號",
    "電話": "(03)4732009",
    "網址": "http://www.gies.tyc.edu.tw"
  },
  {
    "代碼": "034713",
    "學校名稱": "市立大潭國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[328]桃園市觀音區大潭里濱海路大潭段687號",
    "電話": "(03)4732087",
    "網址": "http://www.tates.tyc.edu.tw"
  },
  {
    "代碼": "034714",
    "學校名稱": "市立保生國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[328]桃園市觀音區保生里十四鄰5號",
    "電話": "(03)4732054",
    "網址": "http://www.bses.tyc.edu.tw"
  },
  {
    "代碼": "034715",
    "學校名稱": "市立新坡國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[328]桃園市觀音區新坡里中山路二段717號",
    "電話": "(03)4981534",
    "網址": "http://www.spes.tyc.edu.tw"
  },
  {
    "代碼": "034716",
    "學校名稱": "市立崙坪國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[328]桃園市觀音區崙坪里297之1號",
    "電話": "(03)4981286",
    "網址": "http://www.lpes.tyc.edu.tw"
  },
  {
    "代碼": "034717",
    "學校名稱": "市立上大國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[328]桃園市觀音區上大里大湖路一段540號",
    "電話": "(03)4901174",
    "網址": "http://www.stes.tyc.edu.tw"
  },
  {
    "代碼": "034718",
    "學校名稱": "市立育仁國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[328]桃園市觀音區坑尾里四鄰23之1號",
    "電話": "(03)4732720",
    "網址": "http://www.yres.tyc.edu.tw"
  },
  {
    "代碼": "034719",
    "學校名稱": "市立草漯國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[328]桃園市觀音區草漯里新生路1462號",
    "電話": "(03)4830160",
    "網址": "http://www.ttes.tyc.edu.tw"
  },
  {
    "代碼": "034720",
    "學校名稱": "市立富林國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[328]桃園市觀音區富林里五鄰8號之2",
    "電話": "(03)4839049",
    "網址": "http://www.fles.tyc.edu.tw"
  },
  {
    "代碼": "034721",
    "學校名稱": "市立樹林國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[328]桃園市觀音區樹林里新里路二段12號",
    "電話": "(03)4830414",
    "網址": "http://www.sles.tyc.edu.tw/xoops/"
  },
  {
    "代碼": "034722",
    "學校名稱": "市立龍潭國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[325]桃園市龍潭區龍潭里南龍路13號",
    "電話": "(03)4792014",
    "網址": "http://163.30.181.1"
  },
  {
    "代碼": "034723",
    "學校名稱": "市立德龍國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[325]桃園市龍潭區聖亭路八德段451巷140號",
    "電話": "(03)4792524",
    "網址": "http://www.tles.tyc.edu.tw"
  },
  {
    "代碼": "034724",
    "學校名稱": "市立潛龍國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[325]桃園市龍潭區烏林里中豐路401號",
    "電話": "(03)4792153",
    "網址": "http://www.cles.tyc.edu.tw"
  },
  {
    "代碼": "034725",
    "學校名稱": "市立石門國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[325]桃園市龍潭區佳安里文化路188號",
    "電話": "(03)4711752",
    "網址": "http://www.smes.tyc.edu.tw"
  },
  {
    "代碼": "034726",
    "學校名稱": "市立高原國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[325]桃園市龍潭區高原里高原路568號",
    "電話": "(03)4717009",
    "網址": "http://www.gyps.tyc.edu.tw"
  },
  {
    "代碼": "034727",
    "學校名稱": "市立龍源國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[325]桃園市龍潭區龍源路121巷110弄39號",
    "電話": "(03)4717149",
    "網址": "http://www.lyes.tyc.edu.tw"
  },
  {
    "代碼": "034728",
    "學校名稱": "市立三和國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[325]桃園市龍潭區龍新路三和段1356號",
    "電話": "(03)4792154",
    "網址": "http://163.30.184.1"
  },
  {
    "代碼": "034729",
    "學校名稱": "市立武漢國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[325]桃園市龍潭區武漢路100號",
    "電話": "(03)4792873",
    "網址": "http://www.whps.tyc.edu.tw"
  },
  {
    "代碼": "034730",
    "學校名稱": "市立介壽國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[336]桃園市復興區澤仁里中正路33號",
    "電話": "(03)3822364",
    "網址": "http://www.jses.tyc.edu.tw"
  },
  {
    "代碼": "034731",
    "學校名稱": "市立三民國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[336]桃園市復興區三民里十一鄰57號",
    "電話": "(03)3825294",
    "網址": "http://www.sames.tyc.edu.tw"
  },
  {
    "代碼": "034732",
    "學校名稱": "市立義盛國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[336]桃園市復興區義盛里一鄰11號",
    "電話": "(03)3822787",
    "網址": "http://www.ysps.tyc.edu.tw"
  },
  {
    "代碼": "034733",
    "學校名稱": "市立霞雲國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[336]桃園市復興區霞雲里六鄰14號",
    "電話": "(03)3822224",
    "網址": "http://www.hyps.tyc.edu.tw"
  },
  {
    "代碼": "034734",
    "學校名稱": "市立奎輝國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[336]桃園市復興區奎輝里三鄰25號",
    "電話": "(03)3822177",
    "網址": "http://163.30.109.57"
  },
  {
    "代碼": "034735",
    "學校名稱": "市立光華國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[336]桃園市復興區華陵里四鄰12號",
    "電話": "(03)3912325",
    "網址": "http://www.khes.tyc.edu.tw"
  },
  {
    "代碼": "034736",
    "學校名稱": "市立高義國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[336]桃園市復興區高義里三鄰28號",
    "電話": "(03)3912251",
    "網址": "http://www.gyes.tyc.edu.tw"
  },
  {
    "代碼": "034737",
    "學校名稱": "市立長興國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[336]桃園市復興區長興里十六鄰頭角43號",
    "電話": "(03)3822178",
    "網址": "http://www.cses.tyc.edu.tw/"
  },
  {
    "代碼": "034738",
    "學校名稱": "市立三光國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[336]桃園市復興區三光里四鄰8號",
    "電話": "(03)3912230",
    "網址": "http://www.skes.tyc.edu.tw"
  },
  {
    "代碼": "034740",
    "學校名稱": "市立羅浮國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[336]桃園市復興區羅浮里三鄰16號",
    "電話": "(03)3822269",
    "網址": "http://163.30.110.180"
  },
  {
    "代碼": "034741",
    "學校名稱": "市立巴崚國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[336]桃園市復興區華陵里九鄰75號",
    "電話": "(03)3912131",
    "網址": "http://www.bles.tyc.edu.tw/"
  },
  {
    "代碼": "034742",
    "學校名稱": "市立東安國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區平東路136號",
    "電話": "(03)4509571",
    "網址": "http://www.taps.tyc.edu.tw"
  },
  {
    "代碼": "034743",
    "學校名稱": "市立青溪國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區自強路80號",
    "電話": "(03)3347883",
    "網址": "http://www.csps.tyc.edu.tw"
  },
  {
    "代碼": "034744",
    "學校名稱": "市立錦興國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區南竹路一段100號",
    "電話": "(03)3228487",
    "網址": "http://www.gses.tyc.edu.tw"
  },
  {
    "代碼": "034745",
    "學校名稱": "市立興國國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區元化路二段62號",
    "電話": "(03)4258158",
    "網址": "http://www.sgps.tyc.edu.tw/xoop/xoops/html/"
  },
  {
    "代碼": "034746",
    "學校名稱": "市立華勛國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區榮民南路205號",
    "電話": "(03)4661587",
    "網址": "http://www.hses.tyc.edu.tw"
  },
  {
    "代碼": "034747",
    "學校名稱": "市立同安國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區同德十一街48號",
    "電話": "(03)3269251",
    "網址": "http://www.taes.tyc.edu.tw"
  },
  {
    "代碼": "034748",
    "學校名稱": "市立大忠國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[334]桃園市八德區忠誠街18號",
    "電話": "(03)3635206",
    "網址": "http://www.djes.tyc.edu.tw"
  },
  {
    "代碼": "034749",
    "學校名稱": "市立楊明國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區中山北路一段390巷50號",
    "電話": "(03)4754929",
    "網址": "http://www.ymps.tyc.edu.tw"
  },
  {
    "代碼": "034750",
    "學校名稱": "市立祥安國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區湧安路45號",
    "電話": "(03)4192135",
    "網址": "http://www.saes.tyc.edu.tw"
  },
  {
    "代碼": "034751",
    "學校名稱": "市立迴龍國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區萬壽路一段168號",
    "電話": "(02)82096088",
    "網址": "http://www.hles.tyc.edu.tw"
  },
  {
    "代碼": "034752",
    "學校名稱": "市立建德國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區延平路265號",
    "電話": "(03)3660688",
    "網址": "http://www.jdps.tyc.edu.tw"
  },
  {
    "代碼": "034753",
    "學校名稱": "市立林森國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區林森路95號",
    "電話": "(03)4579213",
    "網址": "http://www.lses.tyc.edu.tw"
  },
  {
    "代碼": "034754",
    "學校名稱": "市立文化國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區文化街189號",
    "電話": "(03)4921750",
    "網址": "http://www.whes.tyc.edu.tw"
  },
  {
    "代碼": "034755",
    "學校名稱": "市立龍星國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[325]桃園市龍潭區中正路269號",
    "電話": "(03)4797299",
    "網址": "http://www.lsps.tyc.edu.tw"
  },
  {
    "代碼": "034756",
    "學校名稱": "市立大有國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區大有路220號",
    "電話": "(03)3577715",
    "網址": "http://www.dyes.tyc.edu.tw"
  },
  {
    "代碼": "034757",
    "學校名稱": "市立幸福國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區頂興路115巷20號",
    "電話": "(03)3194072",
    "網址": "http://www.hfps.tyc.edu.tw"
  },
  {
    "代碼": "034758",
    "學校名稱": "市立慈文國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區新埔六街2號",
    "電話": "(03)3175755",
    "網址": "http://www.twes.tyc.edu.tw"
  },
  {
    "代碼": "034759",
    "學校名稱": "市立大業國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區大業路一段135號",
    "電話": "(03)3337771",
    "網址": "http://www.dayes.tyc.edu.tw"
  },
  {
    "代碼": "034760",
    "學校名稱": "市立同德國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區同德六街175號",
    "電話": "(03)3176403",
    "網址": "http://www.tdes.tyc.edu.tw"
  },
  {
    "代碼": "034761",
    "學校名稱": "市立光明國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區南興里三鄰南昌路255號",
    "電話": "(03)3127066",
    "網址": "http://www.gmes.tyc.edu.tw"
  },
  {
    "代碼": "034762",
    "學校名稱": "市立文華國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區大華里文化七路116號",
    "電話": "(03)3279015",
    "網址": "http://www.sshes.tyc.edu.tw"
  },
  {
    "代碼": "034763",
    "學校名稱": "市立田心國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區文化路120號",
    "電話": "(03)3872008",
    "網址": "http://www.thes.tyc.edu.tw"
  },
  {
    "代碼": "034764",
    "學校名稱": "市立忠福國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區西園路57號",
    "電話": "(03)4340196",
    "網址": "http://www.jfes.tyc.edu.tw"
  },
  {
    "代碼": "034765",
    "學校名稱": "市立興仁國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區遠東路210號",
    "電話": "(03)4355753",
    "網址": "http://www.sres.tyc.edu.tw"
  },
  {
    "代碼": "034766",
    "學校名稱": "市立平興國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區廣泰路168號",
    "電話": "(03)4029323",
    "網址": "http://www.psees.tyc.edu.tw"
  },
  {
    "代碼": "034767",
    "學校名稱": "市立義興國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區義興街55號",
    "電話": "(03)4913700",
    "網址": "http://www.yhes.tyc.edu.tw"
  },
  {
    "代碼": "034768",
    "學校名稱": "市立瑞塘國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區瑞溪路二段100號",
    "電話": "(03)4316360",
    "網址": "http://www.jtps.tyc.edu.tw"
  },
  {
    "代碼": "034769",
    "學校名稱": "市立三坑國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[325]桃園市龍潭區三坑里永昌路51巷85號",
    "電話": "(03)4713627",
    "網址": "http://www.skps.tyc.edu.tw"
  },
  {
    "代碼": "034770",
    "學校名稱": "市立楓樹國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區楓樹里光峰路277號",
    "電話": "(03)3206166",
    "網址": "http://www.fsps.tyc.edu.tw"
  },
  {
    "代碼": "034771",
    "學校名稱": "市立楊心國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區金華街100號",
    "電話": "(03)4758680",
    "網址": "http://yses.tyc.edu.tw/xoops/"
  },
  {
    "代碼": "034772",
    "學校名稱": "市立南美國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區南美里南上路99號",
    "電話": "(03)3126250",
    "網址": "http://www.nmps.tyc.edu.tw"
  },
  {
    "代碼": "034773",
    "學校名稱": "市立中原國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區中北路88號",
    "電話": "(03)4385257",
    "網址": "http://www.cyes.tyc.edu.tw"
  },
  {
    "代碼": "034774",
    "學校名稱": "市立元生國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[320]桃園市中壢區文化二路161號",
    "電話": "(03)4625566",
    "網址": "http://www.yushes.tyc.edu.tw"
  },
  {
    "代碼": "034775",
    "學校名稱": "市立莊敬國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區莊一街107號",
    "電話": "(03)3020784",
    "網址": "http://www.zjes.tyc.edu.tw"
  },
  {
    "代碼": "034776",
    "學校名稱": "市立自強國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區自強東路269號",
    "電話": "(03)3590758",
    "網址": "http://www.zqes.tyc.edu.tw"
  },
  {
    "代碼": "034778",
    "學校名稱": "市立新榮國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[324]桃園市平鎮區中原路88號",
    "電話": "(03)2806200",
    "網址": "http://www.szps.tyc.edu.tw"
  },
  {
    "代碼": "034779",
    "學校名稱": "市立楊光國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區瑞溪路1 段88號",
    "電話": "(03)4827987",
    "網址": "http://www.ygjps.tyc.edu.tw"
  },
  {
    "代碼": "034780",
    "學校名稱": "市立快樂國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區大有路789號",
    "電話": "(03)3580001",
    "網址": "http://www.happy.tyc.edu.tw"
  },
  {
    "代碼": "034781",
    "學校名稱": "市立永順國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區永順街100號",
    "電話": "(03)3024221",
    "網址": "http://www.yes.tyc.edu.tw/"
  },
  {
    "代碼": "034782",
    "學校名稱": "市立新埔國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[330]桃園市桃園區經國路208巷36號",
    "電話": "(03)3162972",
    "網址": "http://www.spps.tyc.edu.tw"
  },
  {
    "代碼": "034784",
    "學校名稱": "市立文欣國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區文昌五街95號",
    "電話": "(03)3974893",
    "網址": "http://www.weses.tyc.edu.tw"
  },
  {
    "代碼": "034785",
    "學校名稱": "市立雙龍國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[325]桃園市龍潭區神龍路346號",
    "電話": "(03)4991888",
    "網址": "http://163.30.183.142/shlps/"
  },
  {
    "代碼": "034786",
    "學校名稱": "市立龍安國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[338]桃園市蘆竹區文中路一段35號",
    "電話": "(03)3922797",
    "網址": "http://www.laps.tyc.edu.tw"
  },
  {
    "代碼": "034787",
    "學校名稱": "市立長庚國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區長庚醫護新村425號",
    "電話": "(03)3182643",
    "網址": "http://www.cgps.tyc.edu.tw"
  },
  {
    "代碼": "034788",
    "學校名稱": "市立仁和國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[335]桃園市大溪區仁和二街50號",
    "電話": "(03)3076626",
    "網址": "http://www.rhps.tyc.edu.tw"
  },
  {
    "代碼": "034789",
    "學校名稱": "市立大湖國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[333]桃園市龜山區大湖里文三二街80號",
    "電話": "(03)3270501",
    "網址": "http://www.dhes.tyc.edu.tw"
  },
  {
    "代碼": "061601",
    "學校名稱": "私立華盛頓國小",
    "公/私立": "私立",
    "縣市名稱": "[06]臺中市",
    "地址": "[427]臺中市潭子區潭興路一段165巷300號",
    "電話": "(04)25393299",
    "網址": "http://www.wes.tc.edu.tw"
  },
  {
    "代碼": "063601",
    "學校名稱": "市立善水國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[435]臺中市梧棲區梧南路50號",
    "電話": "(04)26396160",
    "網址": "http://www.ssjhs.tc.edu.tw"
  },
  {
    "代碼": "064601",
    "學校名稱": "市立豐原國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[420]臺中市豐原區新生北路155號",
    "電話": "(04)25222066",
    "網址": "http://www.fyes.tc.edu.tw"
  },
  {
    "代碼": "064602",
    "學校名稱": "市立瑞穗國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[420]臺中市豐原區西安街72號",
    "電話": "(04)25262064",
    "網址": "http://www.rses.tc.edu.tw"
  },
  {
    "代碼": "064603",
    "學校名稱": "市立南陽國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[420]臺中市豐原區中陽里南陽路440號",
    "電話": "(04)25222521",
    "網址": "http://www.nyes.tc.edu.tw"
  },
  {
    "代碼": "064604",
    "學校名稱": "市立富春國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[420]臺中市豐原區豐西里中山路455號",
    "電話": "(04)25222542",
    "網址": "http://www.fcps.tc.edu.tw"
  },
  {
    "代碼": "064605",
    "學校名稱": "市立豐村國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[420]臺中市豐原區水源路341號",
    "電話": "(04)25222468",
    "網址": "http://www.fces.tc.edu.tw"
  },
  {
    "代碼": "064606",
    "學校名稱": "市立翁子國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[420]臺中市豐原區翁子里豐勢路2段290巷3號",
    "電話": "(04)25223369",
    "網址": "http://www.wtps.tc.edu.tw"
  },
  {
    "代碼": "064607",
    "學校名稱": "市立豐田國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[420]臺中市豐原區田心路二段290號",
    "電話": "(04)25262891",
    "網址": "http://www.ftes.tc.edu.tw"
  },
  {
    "代碼": "064608",
    "學校名稱": "市立合作國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[420]臺中市豐原區三村里西勢路410號",
    "電話": "(04)25323241",
    "網址": "http://www.htes.tc.edu.tw/"
  },
  {
    "代碼": "064609",
    "學校名稱": "市立內埔國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[421]臺中市后里區文化路30號",
    "電話": "(04)25562003",
    "網址": "http://www.npps.tc.edu.tw"
  },
  {
    "代碼": "064610",
    "學校名稱": "市立后里國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[421]臺中市后里區義里里甲后路一段168號",
    "電話": "(04)25562294",
    "網址": "http://163.17.62.7"
  },
  {
    "代碼": "064611",
    "學校名稱": "市立月眉國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[421]臺中市后里區眉山里甲后路二段588號",
    "電話": "(04)25562342",
    "網址": "http://www.ymps.tc.edu.tw"
  },
  {
    "代碼": "064612",
    "學校名稱": "市立七星國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[421]臺中市后里區墩南里南村路389號",
    "電話": "(04)25562608",
    "網址": "http://www.cshes.tc.edu.tw"
  },
  {
    "代碼": "064613",
    "學校名稱": "市立育英國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[421]臺中市后里區太平里四月路23號",
    "電話": "(04)25562374",
    "網址": "http://163.17.154.3"
  },
  {
    "代碼": "064614",
    "學校名稱": "市立后里區泰安國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[421]臺中市后里區泰安里安眉路5號",
    "電話": "(04)25562364",
    "網址": "http://www.taps.tc.edu.tw"
  },
  {
    "代碼": "064615",
    "學校名稱": "市立神岡國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[429]臺中市神岡區神岡里神圳路1號",
    "電話": "(04)25622406",
    "網址": "http://www.skaes.tc.edu.tw"
  },
  {
    "代碼": "064616",
    "學校名稱": "市立豐洲國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[429]臺中市神岡區豐洲里豐洲路482號",
    "電話": "(04)25223351",
    "網址": "http://www.pjes.tc.edu.tw"
  },
  {
    "代碼": "064617",
    "學校名稱": "市立社口國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[429]臺中市神岡區社南里社南街136號",
    "電話": "(04)25626834",
    "網址": "http://www.skes.tc.edu.tw"
  },
  {
    "代碼": "064618",
    "學校名稱": "市立圳堵國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[429]臺中市神岡區圳堵里五鄰三民路639號",
    "電話": "(04)25623734",
    "網址": "http://www.zdes.tc.edu.tw/"
  },
  {
    "代碼": "064619",
    "學校名稱": "市立岸裡國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[429]臺中市神岡區中山路52號",
    "電話": "(04)25222215",
    "網址": "http://www2.olps.tc.edu.tw"
  },
  {
    "代碼": "064620",
    "學校名稱": "市立大雅國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[428]臺中市大雅區二和里學府路230號",
    "電話": "(04)25667766",
    "網址": "http://www.tyaes.tc.edu.tw"
  },
  {
    "代碼": "064621",
    "學校名稱": "市立三和國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[428]臺中市大雅區三和里春亭2街1號",
    "電話": "(04)25664494",
    "網址": "http://www.shes.tc.edu.tw"
  },
  {
    "代碼": "064622",
    "學校名稱": "市立大明國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[428]臺中市大雅區中清路四段158號",
    "電話": "(04)25651300",
    "網址": "http://www.tmes.tc.edu.tw"
  },
  {
    "代碼": "064623",
    "學校名稱": "市立上楓國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[428]臺中市大雅區上楓里楓林街232號",
    "電話": "(04)25663664",
    "網址": "http://www.sfes.tc.edu.tw"
  },
  {
    "代碼": "064624",
    "學校名稱": "市立汝鎏國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[428]臺中市大雅區秀山里平和路238號",
    "電話": "(04)25661454",
    "網址": "http://www.rles.tc.edu.tw"
  },
  {
    "代碼": "064625",
    "學校名稱": "市立陽明國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[428]臺中市大雅區秀山里秀山路365號",
    "電話": "(04)25661554",
    "網址": "http://163.17.167.3/xoops/"
  },
  {
    "代碼": "064626",
    "學校名稱": "市立潭子國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[427]臺中市潭子區中山路二段435號",
    "電話": "(04)25324610",
    "網址": "http://www.tzps.tc.edu.tw"
  },
  {
    "代碼": "064627",
    "學校名稱": "市立僑忠國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[427]臺中市潭子區中山路二段31號",
    "電話": "(04)25324740",
    "網址": "http://www.czes.tc.edu.tw"
  },
  {
    "代碼": "064628",
    "學校名稱": "市立東寶國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[427]臺中市潭子區雅潭路三段2號",
    "電話": "(04)25324564",
    "網址": "http://www.tbes.tc.edu.tw"
  },
  {
    "代碼": "064629",
    "學校名稱": "市立潭子區新興國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[427]臺中市潭子區潭興路一段25號",
    "電話": "(04)25364571",
    "網址": "http://www.hhes.tc.edu.tw"
  },
  {
    "代碼": "064630",
    "學校名稱": "市立外埔國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[438]臺中市外埔區外埔路878號",
    "電話": "(04)26832865",
    "網址": "http://www.wpes.tc.edu.tw"
  },
  {
    "代碼": "064631",
    "學校名稱": "市立安定國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[438]臺中市外埔區土城里土城西路55號",
    "電話": "(04)26831446",
    "網址": "http://tw.school.uschoolnet.com/?id=es00001337"
  },
  {
    "代碼": "064632",
    "學校名稱": "市立鐵山國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[438]臺中市外埔區鐵山里長生路576號",
    "電話": "(04)26835033",
    "網址": "http://www.tsps.tc.edu.tw"
  },
  {
    "代碼": "064633",
    "學校名稱": "市立馬鳴國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[438]臺中市外埔區中山里東西巷13號",
    "電話": "(04)26834114",
    "網址": "http://www.mmps.tc.edu.tw"
  },
  {
    "代碼": "064634",
    "學校名稱": "市立水美國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[438]臺中市外埔區水美里二崁路390巷63號",
    "電話": "(04)26876508",
    "網址": "http://www.smes.tc.edu.tw"
  },
  {
    "代碼": "064635",
    "學校名稱": "市立東勢國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[423]臺中市東勢區廣興里第五橫街1號",
    "電話": "(04)25873442",
    "網址": "http://www.tses.tc.edu.tw"
  },
  {
    "代碼": "064636",
    "學校名稱": "市立中山國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[423]臺中市東勢區泰昌里中泰街88號",
    "電話": "(04)25872471",
    "網址": "http://www.csps.tc.edu.tw"
  },
  {
    "代碼": "064637",
    "學校名稱": "市立石城國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[423]臺中市東勢區茂興里石城街182巷26號",
    "電話": "(04)25873152",
    "網址": "http://www.sches.tc.edu.tw"
  },
  {
    "代碼": "064638",
    "學校名稱": "市立東勢區成功國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[423]臺中市東勢區慶東里東關路五段600號",
    "電話": "(04)25852040",
    "網址": "http://www.ckaps.tc.edu.tw"
  },
  {
    "代碼": "064639",
    "學校名稱": "市立石角國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[423]臺中市東勢區東坑路西盛巷22號",
    "電話": "(04)25873011",
    "網址": "http://www.sjps.tc.edu.tw"
  },
  {
    "代碼": "064640",
    "學校名稱": "市立中科國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[423]臺中市東勢區東崎路4段92巷16號",
    "電話": "(04)25872534",
    "網址": "http://www.ckees.tc.edu.tw"
  },
  {
    "代碼": "064641",
    "學校名稱": "市立新成國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[423]臺中市東勢區上城街260號",
    "電話": "(04)25853447",
    "網址": "http://tw.school.uschoolnet.com/?id=es00001246"
  },
  {
    "代碼": "064642",
    "學校名稱": "市立明正國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[423]臺中市東勢區明正里東蘭路永盛巷57-1號",
    "電話": "(04)25874455",
    "網址": "http://tw.school.uschoolnet.com/?id=es00001243"
  },
  {
    "代碼": "064643",
    "學校名稱": "市立石岡國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[422]臺中市石岡區石岡里石岡街123號",
    "電話": "(04)25723006",
    "網址": "http://www.skaps.tc.edu.tw"
  },
  {
    "代碼": "064644",
    "學校名稱": "市立土牛國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[422]臺中市石岡區土牛里豐勢路308號",
    "電話": "(04)25816043",
    "網址": "http://www.tnps.tc.edu.tw"
  },
  {
    "代碼": "064645",
    "學校名稱": "市立新社國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[426]臺中市新社區新社里興社街4段1巷2號",
    "電話": "(04)25811204",
    "網址": "http://www.hsps.tc.edu.tw/"
  },
  {
    "代碼": "064646",
    "學校名稱": "市立新社區東興國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[426]臺中市新社區東興里興社街一段國校巷13號",
    "電話": "(04)25811467",
    "網址": "http://www.dxps.tc.edu.tw"
  },
  {
    "代碼": "064647",
    "學校名稱": "市立大南國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[426]臺中市新社區大南里興中街47號",
    "電話": "(04)25811574",
    "網址": "http://www.dnps.tc.edu.tw"
  },
  {
    "代碼": "064648",
    "學校名稱": "市立協成國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[426]臺中市新社區協成里興義街219號",
    "電話": "(04)25813437",
    "網址": "http://163.17.161.129"
  },
  {
    "代碼": "064649",
    "學校名稱": "市立大林國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[426]臺中市新社區福興里美林38號",
    "電話": "(04)25941995",
    "網址": "http://www.dles.tc.edu.tw"
  },
  {
    "代碼": "064650",
    "學校名稱": "市立崑山國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[426]臺中市新社區崑山里崑南街崑崙巷5-2號",
    "電話": "(04)25812496",
    "網址": "http://163.17.149.1/"
  },
  {
    "代碼": "064651",
    "學校名稱": "市立中和國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[426]臺中市新社區中和里中興街129號",
    "電話": "(04)25931001",
    "網址": "http://www.ches.tc.edu.tw"
  },
  {
    "代碼": "064652",
    "學校名稱": "市立清水國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[436]臺中市清水區光華路125號",
    "電話": "(04)26222004",
    "網址": "http://www.cses.tc.edu.tw"
  },
  {
    "代碼": "064653",
    "學校名稱": "市立西寧國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[436]臺中市清水區西寧里五權東路50號",
    "電話": "(04)26222430",
    "網址": "http://www.hnes.tc.edu.tw"
  },
  {
    "代碼": "064654",
    "學校名稱": "市立建國國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[436]臺中市清水區西社里建國路10號",
    "電話": "(04)26262334",
    "網址": "http://www.ggps.tc.edu.tw"
  },
  {
    "代碼": "064655",
    "學校名稱": "市立大秀國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[436]臺中市清水區武鹿里五權路336號",
    "電話": "(04)26263754",
    "網址": "http://www.dases.tc.edu.tw"
  },
  {
    "代碼": "064656",
    "學校名稱": "市立三田國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[436]臺中市清水區田寮里三田路4號",
    "電話": "(04)26262400",
    "網址": "http://www.stps.tc.edu.tw"
  },
  {
    "代碼": "064657",
    "學校名稱": "市立甲南國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[436]臺中市清水區菁埔里臨海路26號",
    "電話": "(04)26261834",
    "網址": "http://www.jnes.tc.edu.tw"
  },
  {
    "代碼": "064658",
    "學校名稱": "市立高美國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[436]臺中市清水區護岸路37號",
    "電話": "(04)26112505",
    "網址": "http://www.kmps.tc.edu.tw"
  },
  {
    "代碼": "064659",
    "學校名稱": "市立大楊國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[436]臺中市清水區楊厝里鱉海路325號",
    "電話": "(04)26200634",
    "網址": "http://web.dayes.tc.edu.tw/drupal"
  },
  {
    "代碼": "064660",
    "學校名稱": "市立東山國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[436]臺中市清水區東山里神清路6之1號",
    "電話": "(04)26200517",
    "網址": "http://163.17.161.1"
  },
  {
    "代碼": "064661",
    "學校名稱": "市立梧棲國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[435]臺中市梧棲區民生街45號",
    "電話": "(04)26562834",
    "網址": "http://www.wcies.tc.edu.tw"
  },
  {
    "代碼": "064662",
    "學校名稱": "市立梧南國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[435]臺中市梧棲區草湳里文化路一段6號",
    "電話": "(04)26567968",
    "網址": "http://www.wnps.tc.edu.tw"
  },
  {
    "代碼": "064663",
    "學校名稱": "市立梧棲區中正國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[435]臺中市梧棲區福德里中央路二段15號",
    "電話": "(04)26560844",
    "網址": "http://www.ccps.tc.edu.tw"
  },
  {
    "代碼": "064664",
    "學校名稱": "市立永寧國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[435]臺中市梧棲區中央路一段160號",
    "電話": "(04)26394234",
    "網址": "http://www.ynps.tc.edu.tw"
  },
  {
    "代碼": "064665",
    "學校名稱": "市立大甲國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[437]臺中市大甲區文武里育德路233號",
    "電話": "(04)26872048",
    "網址": "http://www.tcps.tc.edu.tw"
  },
  {
    "代碼": "064666",
    "學校名稱": "市立德化國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[437]臺中市大甲區和平路290號",
    "電話": "(04)26874602",
    "網址": "http://www.dwps.tc.edu.tw"
  },
  {
    "代碼": "064667",
    "學校名稱": "市立大甲區文昌國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[437]臺中市大甲區育德路113號",
    "電話": "(04)26872076",
    "網址": "http://www.wcps.tc.edu.tw"
  },
  {
    "代碼": "064668",
    "學校名稱": "市立順天國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[437]臺中市大甲區經國路168號",
    "電話": "(04)26872040",
    "網址": "http://www.stgs.tc.edu.tw"
  },
  {
    "代碼": "064669",
    "學校名稱": "市立文武國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[437]臺中市大甲區武陵里文曲路61號",
    "電話": "(04)26710928",
    "網址": "http://www.wwps.tc.edu.tw"
  },
  {
    "代碼": "064670",
    "學校名稱": "市立日南國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[437]臺中市大甲區中山路二段568號",
    "電話": "(04)26813704",
    "網址": "http://www.rnes.tc.edu.tw"
  },
  {
    "代碼": "064671",
    "學校名稱": "市立東明國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[437]臺中市大甲區幸福里東明路93號",
    "電話": "(04)26814404",
    "網址": "http://www.dmes.tc.edu.tw"
  },
  {
    "代碼": "064672",
    "學校名稱": "市立華龍國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[437]臺中市大甲區日南里工二路1號",
    "電話": "(04)26811270",
    "網址": "http://www.hlps.tc.edu.tw"
  },
  {
    "代碼": "064673",
    "學校名稱": "市立西岐國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[437]臺中市大甲區西岐里順帆路96號",
    "電話": "(04)26811747",
    "網址": "http://www.hcps.tc.edu.tw"
  },
  {
    "代碼": "064674",
    "學校名稱": "市立東陽國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[437]臺中市大甲區甲東路520號",
    "電話": "(04)26876823",
    "網址": "http://www.dyaes.tc.edu.tw"
  },
  {
    "代碼": "064675",
    "學校名稱": "市立沙鹿國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[433]臺中市沙鹿區美仁里中正街3號",
    "電話": "(04)26625014",
    "網址": "http://www.sles.tc.edu.tw"
  },
  {
    "代碼": "064676",
    "學校名稱": "市立文光國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[433]臺中市沙鹿區斗潭路文光巷12號",
    "電話": "(04)26352806",
    "網址": "http://163.17.210.1/index2.html"
  },
  {
    "代碼": "064677",
    "學校名稱": "市立竹林國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[433]臺中市沙鹿區臺灣大道7段821號",
    "電話": "(04)26620175",
    "網址": "http://www.cles.tc.edu.tw/chulin/"
  },
  {
    "代碼": "064678",
    "學校名稱": "市立北勢國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[433]臺中市沙鹿區六路里南陽路376號",
    "電話": "(04)26315032",
    "網址": "http://www.bses.tc.edu.tw"
  },
  {
    "代碼": "064679",
    "學校名稱": "市立公明國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[433]臺中市沙鹿區公明里忠貞路213號",
    "電話": "(04)26150963",
    "網址": "http://www.kmes.tc.edu.tw"
  },
  {
    "代碼": "064680",
    "學校名稱": "市立公舘國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[433]臺中市沙鹿區西勢里中航路2段1號",
    "電話": "(04)26154353",
    "網址": "http://www.kkps.tc.edu.tw/index.php"
  },
  {
    "代碼": "064681",
    "學校名稱": "市立鹿峰國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[433]臺中市沙鹿區鹿峰里星河路209號",
    "電話": "(04)26224210",
    "網址": "http://www.lfes.tc.edu.tw/"
  },
  {
    "代碼": "064682",
    "學校名稱": "市立大安國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[439]臺中市大安區中庄里中山南路296號",
    "電話": "(04)26713166",
    "網址": "http://www.daes.tc.edu.tw"
  },
  {
    "代碼": "064683",
    "學校名稱": "市立三光國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[439]臺中市大安區龜殼里3鄰中松路297號",
    "電話": "(04)26710148",
    "網址": "http://www.skps.tc.edu.tw"
  },
  {
    "代碼": "064684",
    "學校名稱": "市立海墘國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[439]臺中市大安區海墘里大安港路1100號",
    "電話": "(04)26876085",
    "網址": "http://www.hices.tc.edu.tw"
  },
  {
    "代碼": "064685",
    "學校名稱": "市立大安區永安國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[439]臺中市大安區永安里東西四路二段252號",
    "電話": "(04)26874931",
    "網址": "http://163.17.112.131"
  },
  {
    "代碼": "064686",
    "學校名稱": "市立龍山國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[434]臺中市龍井區中山一路一段6號",
    "電話": "(04)26352454",
    "網址": "http://www.lsps.tc.edu.tw"
  },
  {
    "代碼": "064687",
    "學校名稱": "市立龍井國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[434]臺中市龍井區龍西里龍門路51號",
    "電話": "(04)26397131",
    "網址": "http://www.ljes.tc.edu.tw"
  },
  {
    "代碼": "064688",
    "學校名稱": "市立龍津國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[434]臺中市龍井區龍津里中央路一段165巷50號",
    "電話": "(04)26393394",
    "網址": "http://www.lges.tc.edu.tw"
  },
  {
    "代碼": "064689",
    "學校名稱": "市立龍海國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[434]臺中市龍井區忠和里中央路3段206號",
    "電話": "(04)26393334",
    "網址": "http://www.lhes.tc.edu.tw"
  },
  {
    "代碼": "064690",
    "學校名稱": "市立龍港國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[434]臺中市龍井區麗水里三港路1號",
    "電話": "(04)26395586",
    "網址": "http://www.lgps.tc.edu.tw"
  },
  {
    "代碼": "064691",
    "學校名稱": "市立龍泉國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[434]臺中市龍井區龍泉里龍新路162號",
    "電話": "(04)26353340",
    "網址": "http://www.lces.tc.edu.tw"
  },
  {
    "代碼": "064692",
    "學校名稱": "市立龍峰國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[434]臺中市龍井區新庄里中沙路新庄仔巷2號",
    "電話": "(04)26314707",
    "網址": "http://www.lfps.tc.edu.tw"
  },
  {
    "代碼": "064693",
    "學校名稱": "市立烏日國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[414]臺中市烏日區中山路二段196號",
    "電話": "(04)23381242",
    "網址": "http://www.wres.tc.edu.tw/"
  },
  {
    "代碼": "064694",
    "學校名稱": "市立僑仁國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[414]臺中市烏日區中山路一段341號",
    "電話": "(04)23381241",
    "網址": "http://www.cjes.tc.edu.tw/"
  },
  {
    "代碼": "064695",
    "學校名稱": "市立喀哩國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[414]臺中市烏日區螺潭里溪南路二段370號",
    "電話": "(04)23351016",
    "網址": "http://www.kles.tc.edu.tw"
  },
  {
    "代碼": "064696",
    "學校名稱": "市立東園國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[414]臺中市烏日區溪南路一段238巷198號",
    "電話": "(04)23353092",
    "網址": "http://163.17.34.1"
  },
  {
    "代碼": "064697",
    "學校名稱": "市立溪尾國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[414]臺中市烏日區溪尾里溪岸路26號",
    "電話": "(049)2523020",
    "網址": "http://sfs.cwps.tc.edu.tw/school/web/index.php"
  },
  {
    "代碼": "064698",
    "學校名稱": "市立旭光國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[414]臺中市烏日區健行路501號",
    "電話": "(04)23381847",
    "網址": "http://www.xkes.tc.edu.tw/drupal/"
  },
  {
    "代碼": "064699",
    "學校名稱": "市立五光國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[414]臺中市烏日區光明里光明路40號",
    "電話": "(04)23362276",
    "網址": "http://www.wges.tc.edu.tw"
  },
  {
    "代碼": "064700",
    "學校名稱": "市立大肚國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[432]臺中市大肚區頂街里華山路77號",
    "電話": "(04)26992016",
    "網址": "http://www.ttes.tc.edu.tw"
  },
  {
    "代碼": "064701",
    "學校名稱": "市立瑞峰國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[432]臺中市大肚區太平路1號",
    "電話": "(04)26911291",
    "網址": "http://www.rfes.tc.edu.tw"
  },
  {
    "代碼": "064702",
    "學校名稱": "市立永順國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[432]臺中市大肚區永順里文昌路2段586號",
    "電話": "(04)26992461",
    "網址": "http://163.17.192.129/new/web/index.php"
  },
  {
    "代碼": "064703",
    "學校名稱": "市立追分國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[432]臺中市大肚區沙田路一段364號",
    "電話": "(04)26932604",
    "網址": "http://www.cfps.tc.edu.tw"
  },
  {
    "代碼": "064704",
    "學校名稱": "市立大忠國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[432]臺中市大肚區沙田路一段854巷24號",
    "電話": "(04)26993693",
    "網址": "http://www.dcps.tc.edu.tw/1index.htm"
  },
  {
    "代碼": "064705",
    "學校名稱": "市立大里國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[412]臺中市大里區新興路50號",
    "電話": "(04)24066002",
    "網址": "http://www.dlps.tc.edu.tw"
  },
  {
    "代碼": "064706",
    "學校名稱": "市立內新國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[412]臺中市大里區東昇里日新路8號",
    "電話": "(04)24852863",
    "網址": "http://www.nhps.tc.edu.tw"
  },
  {
    "代碼": "064707",
    "學校名稱": "市立崇光國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[412]臺中市大里區大明路181號",
    "電話": "(04)24818836",
    "網址": "http://www.cgps.tc.edu.tw/index.php"
  },
  {
    "代碼": "064708",
    "學校名稱": "市立塗城國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[412]臺中市大里區仁德里文化街120號",
    "電話": "(04)24922935",
    "網址": "http://www.tches.tc.edu.tw"
  },
  {
    "代碼": "064709",
    "學校名稱": "市立瑞城國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[412]臺中市大里區瑞城里美村街196號",
    "電話": "(04)24924622",
    "網址": "http://www.rces.tc.edu.tw"
  },
  {
    "代碼": "064710",
    "學校名稱": "市立健民國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[412]臺中市大里區健民里校前路26號",
    "電話": "(04)24912342",
    "網址": "http://www.jmps.tc.edu.tw"
  },
  {
    "代碼": "064711",
    "學校名稱": "市立草湖國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[412]臺中市大里區西湖里西湖路32號",
    "電話": "(04)24953078",
    "網址": "http://www.thes.tc.edu.tw"
  },
  {
    "代碼": "064712",
    "學校名稱": "市立霧峰國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[413]臺中市霧峰區中正路736號",
    "電話": "(04)23393069",
    "網址": "http://www.wfps.tc.edu.tw"
  },
  {
    "代碼": "064713",
    "學校名稱": "市立僑榮國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[413]臺中市霧峰區甲寅里民生路156號",
    "電話": "(04)23335553",
    "網址": "http://www.cres.tc.edu.tw"
  },
  {
    "代碼": "064714",
    "學校名稱": "市立四德國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[413]臺中市霧峰區四德路504號",
    "電話": "(04)23393374",
    "網址": "http://163.17.162.130"
  },
  {
    "代碼": "064715",
    "學校名稱": "市立五福國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[413]臺中市霧峰區五福里新埔路239號",
    "電話": "(04)23396119",
    "網址": "http://www.wfups.tc.edu.tw/index.php"
  },
  {
    "代碼": "064716",
    "學校名稱": "市立萬豐國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[413]臺中市霧峰區萬豐里中正路224號",
    "電話": "(04)23393417",
    "網址": "http://www.wfes.tc.edu.tw"
  },
  {
    "代碼": "064717",
    "學校名稱": "市立峰谷國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[413]臺中市霧峰區峰谷里峰谷路496號",
    "電話": "(04)23399271",
    "網址": "http://www.fgps.tc.edu.tw"
  },
  {
    "代碼": "064718",
    "學校名稱": "市立桐林國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[413]臺中市霧峰區民生路675號",
    "電話": "(04)23304311",
    "網址": "http://www.tles.tc.edu.tw"
  },
  {
    "代碼": "064719",
    "學校名稱": "市立復興國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[413]臺中市霧峰區坑口里信義路50號",
    "電話": "(04)23306075",
    "網址": "http://www.fses.tc.edu.tw"
  },
  {
    "代碼": "064720",
    "學校名稱": "市立霧峰區光正國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[413]臺中市霧峰區豐正路566號",
    "電話": "(04)23398687",
    "網址": "http://www.kces.tc.edu.tw"
  },
  {
    "代碼": "064721",
    "學校名稱": "市立太平區太平國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區中平里中興路35號",
    "電話": "(04)22783631",
    "網址": "http://www.tpes.tc.edu.tw"
  },
  {
    "代碼": "064722",
    "學校名稱": "市立宜欣國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區新平路二段100號",
    "電話": "(04)22701567",
    "網址": "http://www.ysps.tc.edu.tw"
  },
  {
    "代碼": "064723",
    "學校名稱": "市立新光國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區新興里新興路200號",
    "電話": "(04)23956005",
    "網址": "http://www.sges.tc.edu.tw"
  },
  {
    "代碼": "064724",
    "學校名稱": "市立坪林國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區坪林里坪林路45號",
    "電話": "(04)23927677",
    "網址": "http://www.ples.tc.edu.tw"
  },
  {
    "代碼": "064725",
    "學校名稱": "市立光隆國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區光隆里光興路487巷5號",
    "電話": "(04)22713626",
    "網址": "http://www.klps.tc.edu.tw"
  },
  {
    "代碼": "064726",
    "學校名稱": "市立黃竹國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區黃竹里竹村路41號",
    "電話": "(04)22715933",
    "網址": "http://www.hjes.tc.edu.tw"
  },
  {
    "代碼": "064727",
    "學校名稱": "市立頭汴國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區頭汴里北田路20號",
    "電話": "(04)22703129",
    "網址": "http://www.tbps.tc.edu.tw"
  },
  {
    "代碼": "064728",
    "學校名稱": "市立東汴國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區東汴里山田路100號",
    "電話": "(04)22702664",
    "網址": "http://www.dbes.tc.edu.tw"
  },
  {
    "代碼": "064729",
    "學校名稱": "市立和平區和平國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[424]臺中市和平區南勢里東關路三段54號",
    "電話": "(04)25941304",
    "網址": "http://www.hpps.tc.edu.tw"
  },
  {
    "代碼": "064730",
    "學校名稱": "市立福民國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[426]臺中市新社區福興里福民16-7號",
    "電話": "(04)25941147",
    "網址": "http://163.17.150.1"
  },
  {
    "代碼": "064731",
    "學校名稱": "市立白冷國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[424]臺中市和平區天輪里東關路二路天輪巷42號",
    "電話": "(04)25941184",
    "網址": "http://www.plps.tc.edu.tw"
  },
  {
    "代碼": "064732",
    "學校名稱": "市立博屋瑪國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[424]臺中市和平區達觀里育英巷6號",
    "電話": "(04)25911487",
    "網址": "http://www.tges.tc.edu.tw/"
  },
  {
    "代碼": "064733",
    "學校名稱": "市立中坑國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[424]臺中市和平區中坑里中坑巷41號",
    "電話": "(04)25874992",
    "網址": "http://tw.school.uschoolnet.com/?id=es00001253"
  },
  {
    "代碼": "064734",
    "學校名稱": "市立平等國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[424]臺中市和平區中興路三段環山3巷35號",
    "電話": "(04)25802204",
    "網址": "http://www.pdes.tc.edu.tw"
  },
  {
    "代碼": "064735",
    "學校名稱": "市立博愛國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[424]臺中市和平區博愛里東關路一段松鶴三巷10號",
    "電話": "(04)25943446",
    "網址": "http://www.baes.tc.edu.tw"
  },
  {
    "代碼": "064736",
    "學校名稱": "市立自由國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[424]臺中市和平區自由里東崎路二段49號",
    "電話": "(04)25911342",
    "網址": "http://www.yyps.tc.edu.tw"
  },
  {
    "代碼": "064737",
    "學校名稱": "市立梨山國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[424]臺中市和平區梨山里福壽路10號",
    "電話": "(04)25981510",
    "網址": "http://www.lses.tc.edu.tw"
  },
  {
    "代碼": "064738",
    "學校名稱": "市立益民國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[412]臺中市大里區益民路二段48號",
    "電話": "(04)24834970",
    "網址": "http://www.ymes.tc.edu.tw"
  },
  {
    "代碼": "064739",
    "學校名稱": "市立槺榔國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[436]臺中市清水區中央路23-12號",
    "電話": "(04)26562684",
    "網址": "http://www.klnes.tc.edu.tw"
  },
  {
    "代碼": "064740",
    "學校名稱": "市立建平國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區精美路201號",
    "電話": "(04)22779532",
    "網址": "http://www.jpps.tc.edu.tw"
  },
  {
    "代碼": "064741",
    "學校名稱": "市立潭陽國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[427]臺中市潭子區潭陽路19號",
    "電話": "(04)25382255",
    "網址": "http://www.tyes.tc.edu.tw"
  },
  {
    "代碼": "064742",
    "學校名稱": "市立太平區中華國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區大源路1-20號",
    "電話": "(04)23920870",
    "網址": "http://www.chps.tc.edu.tw"
  },
  {
    "代碼": "064743",
    "學校名稱": "市立九德國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[414]臺中市烏日區長春街300號",
    "電話": "(04)23366540",
    "網址": "http://www.jdps.tc.edu.tw"
  },
  {
    "代碼": "064744",
    "學校名稱": "市立中港國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[435]臺中市梧棲區大村里文明街100號",
    "電話": "(04)26655929",
    "網址": "http://www.ckps.tc.edu.tw"
  },
  {
    "代碼": "064745",
    "學校名稱": "市立東平國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區中興東路213號",
    "電話": "(04)22767834",
    "網址": "http://www.dpps.tc.edu.tw"
  },
  {
    "代碼": "064746",
    "學校名稱": "市立文雅國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[428]臺中市大雅區文雅里中山北路235號",
    "電話": "(04)25678823",
    "網址": "http://www.wyes.tc.edu.tw"
  },
  {
    "代碼": "064747",
    "學校名稱": "市立新盛國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[423]臺中市東勢區新盛里新盛街342號",
    "電話": "(04)25876642",
    "網址": "http://tw.school.uschoolnet.com/?id=es00001249"
  },
  {
    "代碼": "064748",
    "學校名稱": "市立大元國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[412]臺中市大里區現岱路60號",
    "電話": "(04)24834568",
    "網址": "http://163.17.162.1"
  },
  {
    "代碼": "064749",
    "學校名稱": "市立吉峰國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[413]臺中市霧峰區吉峰里民生路349號",
    "電話": "(04)23300893",
    "網址": "http://www.jfes.tc.edu.tw"
  },
  {
    "代碼": "064750",
    "學校名稱": "市立新平國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區樹孝路336巷9號",
    "電話": "(04)23914533",
    "網址": "http://www.spes.tc.edu.tw"
  },
  {
    "代碼": "064751",
    "學校名稱": "市立葫蘆墩國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[420]臺中市豐原區葫蘆墩一街250號",
    "電話": "(04)25205136",
    "網址": "http://www.hldes.tc.edu.tw"
  },
  {
    "代碼": "064752",
    "學校名稱": "市立永隆國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[412]臺中市大里區永隆五街2號",
    "電話": "(04)24066637",
    "網址": "http://www.yles.tc.edu.tw"
  },
  {
    "代碼": "064753",
    "學校名稱": "市立美群國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[412]臺中市大里區美群路99號",
    "電話": "(04)24912129",
    "網址": "http://www.mcps.tc.edu.tw"
  },
  {
    "代碼": "064754",
    "學校名稱": "市立吳厝國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[436]臺中市清水區吳厝里吳厝路35號",
    "電話": "(04)26200864",
    "網址": "http://www.wtes.tc.edu.tw"
  },
  {
    "代碼": "064755",
    "學校名稱": "市立山陽國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[432]臺中市大肚區榮華街630號",
    "電話": "(04)26983117",
    "網址": "http://teacher2.syps.tc.edu.tw/school/web/?home=syps"
  },
  {
    "代碼": "064756",
    "學校名稱": "市立立新國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[412]臺中市大里區立仁里立新街338號",
    "電話": "(04)22769178",
    "網址": "http://www.lishin.tc.edu.tw"
  },
  {
    "代碼": "064757",
    "學校名稱": "市立大德國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[435]臺中市梧棲區大村里文昌路343號",
    "電話": "(04)26568928",
    "網址": "http://www.ddps.tc.edu.tw"
  },
  {
    "代碼": "064758",
    "學校名稱": "市立車籠埔國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區光明路10-2 號",
    "電話": "(04)22763928",
    "網址": "http://www.clpes.tc.edu.tw"
  },
  {
    "代碼": "064759",
    "學校名稱": "市立福陽國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[420]臺中市豐原區南陽路綠山巷83號",
    "電話": "(04)25291101",
    "網址": "http://www.fuyaes.tc.edu.tw"
  },
  {
    "代碼": "064760",
    "學校名稱": "市立頭家國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[427]臺中市潭子區得福街185號",
    "電話": "(04)25325532",
    "網址": "http://www.tjes.tc.edu.tw"
  },
  {
    "代碼": "064761",
    "學校名稱": "市立瑞井國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[432]臺中市大肚區瑞井里華山路680號",
    "電話": "(04)26912550",
    "網址": "http://www.rjps.tc.edu.tw"
  },
  {
    "代碼": "064762",
    "學校名稱": "市立東新國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[423]臺中市東勢區東蘭路1之1號",
    "電話": "(04)25881083",
    "網址": "http://www.tshes.tc.edu.tw"
  },
  {
    "代碼": "064763",
    "學校名稱": "市立光復國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[413]臺中市霧峰區柳豐路535號",
    "電話": "(04)23398419",
    "網址": "http://www.kfjh.tc.edu.tw"
  },
  {
    "代碼": "064764",
    "學校名稱": "市立長億國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[411]臺中市太平區太平三街295號",
    "電話": "(04)22737653",
    "網址": "http://www.cyes.tc.edu.tw"
  },
  {
    "代碼": "064765",
    "學校名稱": "市立六寶國小",
    "公/私立": "公立",
    "縣市名稱": "[06]臺中市",
    "地址": "[428]臺中市大雅區光復路15之2號",
    "電話": "(04)25694858",
    "網址": "http://www.lbes.tc.edu.tw"
  },
  {
    "代碼": "190601",
    "學校名稱": "國立臺中教大附小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[404]臺中市北區民權路220號",
    "電話": "(04)22224269",
    "網址": "http://www.ntctcps.tc.edu.tw"
  },
  {
    "代碼": "191602",
    "學校名稱": "私立育仁國小",
    "公/私立": "私立",
    "縣市名稱": "[19]臺中市",
    "地址": "[404]臺中市北區錦祥里雙十路二段50號",
    "電話": "(04)22332133",
    "網址": "http://www.yjes.tc.edu.tw"
  },
  {
    "代碼": "191604",
    "學校名稱": "私立慎齋小學",
    "公/私立": "私立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區山西路二段270號",
    "電話": "(04)22928199",
    "網址": "http://www.sces.tc.edu.tw"
  },
  {
    "代碼": "191605",
    "學校名稱": "私立明道普霖斯頓小學",
    "公/私立": "私立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區河北路三段16號",
    "電話": "(04)22425588",
    "網址": "http://www.fhbes.tc.edu.tw"
  },
  {
    "代碼": "191606",
    "學校名稱": "私立麗喆國(中)小",
    "公/私立": "私立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區國安二路242巷199號",
    "電話": "(04)24613099",
    "網址": "http://www.korrnell.com.tw/litze/"
  },
  {
    "代碼": "193601",
    "學校名稱": "市立中區光復國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[400]臺中市中區三民路二段148號",
    "電話": "(04)22294174",
    "網址": "http://www.gfes.tc.edu.tw"
  },
  {
    "代碼": "193602",
    "學校名稱": "市立臺中國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[401]臺中市東區臺中路153號",
    "電話": "(04)22815103",
    "網址": "http://www.tces.tc.edu.tw"
  },
  {
    "代碼": "193603",
    "學校名稱": "市立大智國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[401]臺中市東區大智路359號",
    "電話": "(04)22825683",
    "網址": "http://www.dzes.tc.edu.tw"
  },
  {
    "代碼": "193604",
    "學校名稱": "市立東區成功國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[401]臺中市東區旱溪西路一段300號",
    "電話": "(04)22124224",
    "網址": "http://www.ckes.tc.edu.tw"
  },
  {
    "代碼": "193605",
    "學校名稱": "市立進德國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[401]臺中市東區進化路135號",
    "電話": "(04)22126834",
    "網址": "http://140.128.182.1"
  },
  {
    "代碼": "193606",
    "學校名稱": "市立力行國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[401]臺中市東區進化路223號",
    "電話": "(04)23604412",
    "網址": "http://www.lxes.tc.edu.tw"
  },
  {
    "代碼": "193607",
    "學校名稱": "市立樂業國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[401]臺中市東區樂業路60號",
    "電話": "(04)22121293",
    "網址": "http://www.lyes.tc.edu.tw"
  },
  {
    "代碼": "193608",
    "學校名稱": "市立忠孝國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[403]臺中市西區三民路一段171號",
    "電話": "(04)22242161",
    "網址": "http://www.jses.tc.edu.tw"
  },
  {
    "代碼": "193609",
    "學校名稱": "市立忠信國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[403]臺中市西區林森路155號",
    "電話": "(04)23722866",
    "網址": "http://www.zxes.tc.edu.tw/"
  },
  {
    "代碼": "193610",
    "學校名稱": "市立大同國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[403]臺中市西區自由路一段138號",
    "電話": "(04)22222311",
    "網址": "http://www.dtes.tc.edu.tw"
  },
  {
    "代碼": "193611",
    "學校名稱": "市立忠明國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[403]臺中市西區臺灣大道二段556號",
    "電話": "(04)23172860",
    "網址": "http://www.zmes.tc.edu.tw"
  },
  {
    "代碼": "193612",
    "學校名稱": "市立西區中正國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[403]臺中市西區英才路423號",
    "電話": "(04)23212041",
    "網址": "http://www.cces.tc.edu.tw"
  },
  {
    "代碼": "193613",
    "學校名稱": "市立南區和平國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[402]臺中市南區復興路二段57號",
    "電話": "(04)22613139",
    "網址": "http://www.hpes.tc.edu.tw"
  },
  {
    "代碼": "193614",
    "學校名稱": "市立國光國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[402]臺中市南區國光路261號",
    "電話": "(04)22872475",
    "網址": "http://www.kkes.tc.edu.tw"
  },
  {
    "代碼": "193615",
    "學校名稱": "市立信義國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[402]臺中市南區五權南路325號",
    "電話": "(04)22622005",
    "網址": "http://www.xyes.tc.edu.tw"
  },
  {
    "代碼": "193616",
    "學校名稱": "市立北區太平國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[404]臺中市北區太平路74號",
    "電話": "(04)22211101",
    "網址": "http://www.tpes.tc.edu.tw"
  },
  {
    "代碼": "193617",
    "學校名稱": "市立北區中華國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[404]臺中市北區漢口路三段2號",
    "電話": "(04)22979601",
    "網址": "http://www.zhes.tc.edu.tw"
  },
  {
    "代碼": "193618",
    "學校名稱": "市立篤行國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[404]臺中市北區篤行路321號",
    "電話": "(04)22013483",
    "網址": "http://www.dxes.tc.edu.tw"
  },
  {
    "代碼": "193619",
    "學校名稱": "市立健行國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[404]臺中市北區健行路666號",
    "電話": "(04)22038064",
    "網址": "http://www.jxes.tc.edu.tw"
  },
  {
    "代碼": "193620",
    "學校名稱": "市立省三國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[404]臺中市北區崇德路一段107號",
    "電話": "(04)22318092",
    "網址": "http://www.sses.tc.edu.tw"
  },
  {
    "代碼": "193621",
    "學校名稱": "市立西屯國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區西屯路二段300號",
    "電話": "(04)27013534",
    "網址": "http://www.stes.tc.edu.tw"
  },
  {
    "代碼": "193622",
    "學校名稱": "市立西屯區泰安國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區環中路二段368號",
    "電話": "(04)27017045",
    "網址": "http://www.taes.tc.edu.tw"
  },
  {
    "代碼": "193623",
    "學校名稱": "市立大鵬國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區中平路268號",
    "電話": "(04)22914655",
    "網址": "http://www.dpes.tc.edu.tw"
  },
  {
    "代碼": "193624",
    "學校名稱": "市立西屯區永安國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區西屯路三段133號",
    "電話": "(04)24624470",
    "網址": "http://www.yaes.tc.edu.tw"
  },
  {
    "代碼": "193625",
    "學校名稱": "市立協和國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區協和里安和路99號",
    "電話": "(04)23588022",
    "網址": "http://www.xhes.tc.edu.tw"
  },
  {
    "代碼": "193626",
    "學校名稱": "市立大仁國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區重慶路200號",
    "電話": "(04)23134545",
    "網址": "http://140.128.222.1"
  },
  {
    "代碼": "193627",
    "學校名稱": "市立南屯國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[408]臺中市南屯區黎明路一段968號",
    "電話": "(04)23894238",
    "網址": "http://www.ntes.tc.edu.tw"
  },
  {
    "代碼": "193628",
    "學校名稱": "市立鎮平國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[408]臺中市南屯區黎明路一段425號",
    "電話": "(04)24792122",
    "網址": "http://www.zpes.tc.edu.tw"
  },
  {
    "代碼": "193629",
    "學校名稱": "市立文山國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[408]臺中市南屯區忠勇路97號",
    "電話": "(04)23896934",
    "網址": "http://www.wses.tc.edu.tw"
  },
  {
    "代碼": "193630",
    "學校名稱": "市立春安國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[408]臺中市南屯區春安路109號",
    "電話": "(04)23894408",
    "網址": "http://www.caes.tc.edu.tw"
  },
  {
    "代碼": "193631",
    "學校名稱": "市立黎明國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[408]臺中市南屯區黎明路二段555號",
    "電話": "(04)22517363",
    "網址": "http://www.lmes.tc.edu.tw"
  },
  {
    "代碼": "193632",
    "學校名稱": "市立北屯國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區進化北路2號",
    "電話": "(04)22332110",
    "網址": "http://www.ptes.tc.edu.tw"
  },
  {
    "代碼": "193633",
    "學校名稱": "市立僑孝國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區北屯路435號",
    "電話": "(04)22447049",
    "網址": "http://www.qxes.tc.edu.tw"
  },
  {
    "代碼": "193634",
    "學校名稱": "市立四張犁國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區后庄路910號(四張犁)",
    "電話": "(04)24227046",
    "網址": "http://www.jres.tc.edu.tw"
  },
  {
    "代碼": "193635",
    "學校名稱": "市立松竹國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區昌平路二段12號",
    "電話": "(04)22447043",
    "網址": "http://www.szes.tc.edu.tw"
  },
  {
    "代碼": "193636",
    "學校名稱": "市立軍功國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區和平里軍福十三路300號",
    "電話": "(04)24370696",
    "網址": "http://www.jges.tc.edu.tw"
  },
  {
    "代碼": "193637",
    "學校名稱": "市立北屯區大坑國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區東山路二段112號",
    "電話": "(04)22390748",
    "網址": "http://www.dkes.tc.edu.tw"
  },
  {
    "代碼": "193638",
    "學校名稱": "市立逢甲國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區北坑巷60號",
    "電話": "(04)22390047",
    "網址": "http://www.fjes.tc.edu.tw"
  },
  {
    "代碼": "193639",
    "學校名稱": "市立建功國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區軍和街43號",
    "電話": "(04)24367042",
    "網址": "http://www.jkes.tc.edu.tw"
  },
  {
    "代碼": "193640",
    "學校名稱": "市立北屯區新興國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區新興路20號",
    "電話": "(04)24258514",
    "網址": "http://140.128.215.1"
  },
  {
    "代碼": "193641",
    "學校名稱": "市立仁愛國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區四平路71號",
    "電話": "(04)22923861",
    "網址": "http://www.raes.tc.edu.tw"
  },
  {
    "代碼": "193642",
    "學校名稱": "市立立人國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[404]臺中市北區北平路一段60號",
    "電話": "(04)22956975",
    "網址": "http://www.lres.tc.edu.tw"
  },
  {
    "代碼": "193643",
    "學校名稱": "市立北屯區文昌國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區昌平路一段91號",
    "電話": "(04)22361953",
    "網址": "http://www.wces.tc.edu.tw"
  },
  {
    "代碼": "193644",
    "學校名稱": "市立大勇國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[403]臺中市西區忠明南路515號",
    "電話": "(04)23755959",
    "網址": "http://www.dyes.tc.edu.tw"
  },
  {
    "代碼": "193645",
    "學校名稱": "市立重慶國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區重慶路358號",
    "電話": "(04)23123517",
    "網址": "http://www.cges.tc.edu.tw"
  },
  {
    "代碼": "193646",
    "學校名稱": "市立南屯區東興國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[408]臺中市南屯區大隆路71號",
    "電話": "(04)23276251",
    "網址": "http://www.txes.tc.edu.tw"
  },
  {
    "代碼": "193647",
    "學校名稱": "市立文心國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區文心路四段575號",
    "電話": "(04)22445906",
    "網址": "http://www.wxes.tc.edu.tw"
  },
  {
    "代碼": "193648",
    "學校名稱": "市立四維國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區文心路四段956號",
    "電話": "(04)22302319",
    "網址": "http://www.swes.tc.edu.tw"
  },
  {
    "代碼": "193649",
    "學校名稱": "市立何厝國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區重慶路1號",
    "電話": "(04)23166492",
    "網址": "http://www.hces.tc.edu.tw"
  },
  {
    "代碼": "193650",
    "學校名稱": "市立國安國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區國祥街1號",
    "電話": "(04)24621681",
    "網址": "http://www.gaes.tc.edu.tw"
  },
  {
    "代碼": "193651",
    "學校名稱": "市立上石國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區西屯路二段上石南6巷25號",
    "電話": "(04)27087412",
    "網址": "http://www.hses.tc.edu.tw"
  },
  {
    "代碼": "193652",
    "學校名稱": "市立大新國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[408]臺中市南屯區文心路一段280號",
    "電話": "(04)24716609",
    "網址": "http://www.dses.tc.edu.tw"
  },
  {
    "代碼": "193653",
    "學校名稱": "市立陳平國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區陳平路58號",
    "電話": "(04)22973558",
    "網址": "http://www.cpes.tc.edu.tw"
  },
  {
    "代碼": "193654",
    "學校名稱": "市立賴厝國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[404]臺中市北區漢口路四段168號",
    "電話": "(04)22302388",
    "網址": "http://www.ltes.tc.edu.tw"
  },
  {
    "代碼": "193655",
    "學校名稱": "市立永春國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[408]臺中市南屯區永春東路288號",
    "電話": "(04)24757468",
    "網址": "http://www.yces.tc.edu.tw"
  },
  {
    "代碼": "193656",
    "學校名稱": "市立惠文國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[408]臺中市南屯區公益路二段300號",
    "電話": "(04)22596907",
    "網址": "http://www.hwes.tc.edu.tw"
  },
  {
    "代碼": "193657",
    "學校名稱": "市立樹義國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[402]臺中市南區福田路11號",
    "電話": "(04)22657298",
    "網址": "http://www.syes.tc.edu.tw"
  },
  {
    "代碼": "193658",
    "學校名稱": "市立東光國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區東光路926號",
    "電話": "(04)24377215",
    "網址": "http://www.dges.tc.edu.tw"
  },
  {
    "代碼": "193659",
    "學校名稱": "市立上安國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區上安路156號",
    "電話": "(04)24527322",
    "網址": "http://www.saes.tc.edu.tw"
  },
  {
    "代碼": "193660",
    "學校名稱": "市立仁美國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區崇德路三段568號",
    "電話": "(04)24211136",
    "網址": "http://www.jmes.tc.edu.tw"
  },
  {
    "代碼": "193661",
    "學校名稱": "市立長安國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區櫻花路18號",
    "電話": "(04)23157600",
    "網址": "http://www.chanes.tc.edu.tw"
  },
  {
    "代碼": "193662",
    "學校名稱": "市立惠來國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區文中路168號",
    "電話": "(04)23176353",
    "網址": "http://140.128.233.2"
  },
  {
    "代碼": "193663",
    "學校名稱": "市立大墩國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[408]臺中市南屯區向上路二段201號",
    "電話": "(04)23816608",
    "網址": "http://web2.ddes.tc.edu.tw/ddesweb/"
  },
  {
    "代碼": "193664",
    "學校名稱": "市立東海國小",
    "公/私立": "公立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區國際街75號",
    "電話": "(04)23509797",
    "網址": "http://www.dhes.tc.edu.tw"
  },
  {
    "代碼": "114601",
    "學校名稱": "市立仁德國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[717]臺南市仁德區中正路二段806號",
    "電話": "(06)2794570",
    "網址": "http://www.rdes.tn.edu.tw"
  },
  {
    "代碼": "114602",
    "學校名稱": "市立文賢國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[717]臺南市仁德區保安里文賢路一段886號",
    "電話": "(06)2661260",
    "網址": "http://www.wsps.tn.edu.tw/~w/"
  },
  {
    "代碼": "114603",
    "學校名稱": "市立長興國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[717]臺南市仁德區土庫路6號",
    "電話": "(06)2723986",
    "網址": "http://www.cses.tn.edu.tw/"
  },
  {
    "代碼": "114604",
    "學校名稱": "市立依仁國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[717]臺南市仁德區中洲里中洲1之126號",
    "電話": "(06)2662108",
    "網址": "http://www.yres.tn.edu.tw"
  },
  {
    "代碼": "114605",
    "學校名稱": "市立大甲國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[717]臺南市仁德區大甲里中正西路360巷30號",
    "電話": "(06)2662492",
    "網址": "http://www.djes.tn.edu.tw/"
  },
  {
    "代碼": "114606",
    "學校名稱": "市立仁和國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[717]臺南市仁德區仁愛里保仁路68號",
    "電話": "(06)2681927",
    "網址": "http://www.rhes.tn.edu.tw"
  },
  {
    "代碼": "114607",
    "學校名稱": "市立德南國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[717]臺南市仁德區後壁里中正路二段209號",
    "電話": "(06)2794772",
    "網址": "http://www.dnps.tn.edu.tw"
  },
  {
    "代碼": "114608",
    "學校名稱": "市立虎山實驗小學",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[717]臺南市仁德區成功里虎山一街100號",
    "電話": "(06)2661490",
    "網址": "http://www.hsps.tn.edu.tw"
  },
  {
    "代碼": "114609",
    "學校名稱": "市立歸仁國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[711]臺南市歸仁區歸仁里文化街一段100號",
    "電話": "(06)2304740",
    "網址": "http://www.grps.tn.edu.tw"
  },
  {
    "代碼": "114610",
    "學校名稱": "市立歸南國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[711]臺南市歸仁區辜厝里民權南路171號",
    "電話": "(06)2304930",
    "網址": "http://www.gnes.tn.edu.tw/"
  },
  {
    "代碼": "114611",
    "學校名稱": "市立保西國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[711]臺南市歸仁區媽廟里中正北路三段60號",
    "電話": "(06)2309683",
    "網址": "http://www.bsps.tn.edu.tw"
  },
  {
    "代碼": "114612",
    "學校名稱": "市立大潭國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[711]臺南市歸仁區大潭里中正南路三段97號",
    "電話": "(06)2781953",
    "網址": "http://www.dtps.tn.edu.tw/"
  },
  {
    "代碼": "114613",
    "學校名稱": "市立關廟國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[718]臺南市關廟區山西里文衡路121號",
    "電話": "(06)5952209",
    "網址": "http://www.gmes.tn.edu.tw"
  },
  {
    "代碼": "114614",
    "學校名稱": "市立五甲國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[718]臺南市關廟區和平路246號",
    "電話": "(06)5952155",
    "網址": "http://www.wjes.tn.edu.tw"
  },
  {
    "代碼": "114615",
    "學校名稱": "市立保東國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[718]臺南市關廟區埤頭里關新路一段776號",
    "電話": "(06)5952344",
    "網址": "http://www.bdes.tn.edu.tw"
  },
  {
    "代碼": "114616",
    "學校名稱": "市立崇和國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[718]臺南市關廟區田中里南雄南路568號",
    "電話": "(06)5551040",
    "網址": "http://www.chees.tn.edu.tw"
  },
  {
    "代碼": "114617",
    "學校名稱": "市立文和實驗小學",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[718]臺南市關廟區布袋里長文街37號",
    "電話": "(06)5551937",
    "網址": "http://www.whps.tn.edu.tw"
  },
  {
    "代碼": "114618",
    "學校名稱": "市立深坑國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[718]臺南市關廟區南雄路一段487號",
    "電話": "(06)5952409",
    "網址": "http://www.skps.tn.edu.tw"
  },
  {
    "代碼": "114619",
    "學校名稱": "市立新光國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[718]臺南市關廟區新光街76號",
    "電話": "(06)5952419",
    "網址": "http://163.26.96.1"
  },
  {
    "代碼": "114620",
    "學校名稱": "市立龍崎國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[719]臺南市龍崎區崎頂里新市子41號",
    "電話": "(06)5941204",
    "網址": "http://www.lces.tn.edu.tw"
  },
  {
    "代碼": "114623",
    "學校名稱": "市立永康國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[710]臺南市永康區永康里中山南路637號",
    "電話": "(06)2324462",
    "網址": "http://www.ykes.tn.edu.tw"
  },
  {
    "代碼": "114624",
    "學校名稱": "市立大灣國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[710]臺南市永康區大灣路283號",
    "電話": "(06)2719024",
    "網址": "http://www.dwps.tn.edu.tw/"
  },
  {
    "代碼": "114625",
    "學校名稱": "市立三村國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[710]臺南市永康區塩洲里鹽行路2號",
    "電話": "(06)2531850",
    "網址": "http://stes.tn.edu.tw/"
  },
  {
    "代碼": "114626",
    "學校名稱": "市立西勢國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[710]臺南市永康區富強路一段278號",
    "電話": "(06)2712841",
    "網址": "http://sisesx.dcs.tn.edu.tw/"
  },
  {
    "代碼": "114627",
    "學校名稱": "市立永康區復興國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[710]臺南市永康區華興街2號",
    "電話": "(06)3111569",
    "網址": "http://www.fses.tn.edu.tw"
  },
  {
    "代碼": "114628",
    "學校名稱": "市立龍潭國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[710]臺南市永康區龍潭街214號",
    "電話": "(06)2324313",
    "網址": "http://www.ltps.tn.edu.tw"
  },
  {
    "代碼": "114629",
    "學校名稱": "市立大橋國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[710]臺南市永康區大橋三街173號",
    "電話": "(06)2033001",
    "網址": "http://www.dcps.tn.edu.tw"
  },
  {
    "代碼": "114630",
    "學校名稱": "市立新化國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[712]臺南市新化區中山路173號",
    "電話": "(06)5902035",
    "網址": "http://www.shps.tn.edu.tw"
  },
  {
    "代碼": "114631",
    "學校名稱": "市立那拔國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[712]臺南市新化區那拔里54號",
    "電話": "(06)5911591",
    "網址": "http://www.nbes.tn.edu.tw/"
  },
  {
    "代碼": "114632",
    "學校名稱": "市立口埤實驗小學",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[712]臺南市新化區知義里口埤88號",
    "電話": "(06)5901445",
    "網址": "http://www.kbes.tn.edu.tw"
  },
  {
    "代碼": "114633",
    "學校名稱": "市立大新國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[712]臺南市新化區太平街176號",
    "電話": "(06)5982953",
    "網址": "http://www.dases.tn.edu.tw"
  },
  {
    "代碼": "114635",
    "學校名稱": "市立山上國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[743]臺南市山上區南洲里42號",
    "電話": "(06)5781203",
    "網址": "http://www.sases.tn.edu.tw"
  },
  {
    "代碼": "114636",
    "學校名稱": "市立玉井國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[714]臺南市玉井區中正路1號",
    "電話": "(06)5742047",
    "網址": "http://www.yjes.tn.edu.tw"
  },
  {
    "代碼": "114637",
    "學校名稱": "市立層林國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[714]臺南市玉井區層林里115號",
    "電話": "(06)5743574",
    "網址": "http://www.tles.tn.edu.tw/"
  },
  {
    "代碼": "114638",
    "學校名稱": "市立楠西國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[715]臺南市楠西區楠西里四維路69號",
    "電話": "(06)5751062",
    "網址": "http://www.nsps.tn.edu.tw/"
  },
  {
    "代碼": "114639",
    "學校名稱": "市立南化國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[716]臺南市南化區南化里17號",
    "電話": "(06)5771118",
    "網址": "http://www.nhes.tn.edu.tw"
  },
  {
    "代碼": "114640",
    "學校名稱": "市立北寮國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[716]臺南市南化區北寮里110之27號",
    "電話": "(06)5772215",
    "網址": "http://www.bles.tn.edu.tw"
  },
  {
    "代碼": "114641",
    "學校名稱": "市立西埔國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[716]臺南市南化區西埔里117號",
    "電話": "(06)5771240",
    "網址": "http://www.spes.tn.edu.tw"
  },
  {
    "代碼": "114642",
    "學校名稱": "市立玉山國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[716]臺南市南化區玉山里48之1號",
    "電話": "(06)5772072",
    "網址": "http://www.ysps.tn.edu.tw"
  },
  {
    "代碼": "114643",
    "學校名稱": "市立瑞峰國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[716]臺南市南化區關山里96號",
    "電話": "(06)5770093",
    "網址": "http://www.rfes.tn.edu.tw"
  },
  {
    "代碼": "114644",
    "學校名稱": "市立左鎮國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[713]臺南市左鎮區中正里7號",
    "電話": "(06)5731019",
    "網址": "http://tjesweb.dcs.tn.edu.tw/"
  },
  {
    "代碼": "114646",
    "學校名稱": "市立光榮國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[713]臺南市左鎮區榮和里61之1號",
    "電話": "(06)5731658",
    "網址": "http://www.gres.tn.edu.tw"
  },
  {
    "代碼": "114647",
    "學校名稱": "市立善化國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[741]臺南市善化區進學路63號",
    "電話": "(06)5817020",
    "網址": "http://www.shes.tn.edu.tw"
  },
  {
    "代碼": "114648",
    "學校名稱": "市立茄拔國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[741]臺南市善化區嘉北里280號",
    "電話": "(06)5837031",
    "網址": "http://www.cbes.tn.edu.tw"
  },
  {
    "代碼": "114649",
    "學校名稱": "市立善化區大同國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[741]臺南市善化區六德里200之1號",
    "電話": "(06)5837352",
    "網址": "http://www.dtes.tn.edu.tw"
  },
  {
    "代碼": "114650",
    "學校名稱": "市立大成國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[741]臺南市善化區大成路385號",
    "電話": "(06)5837520",
    "網址": "http://www.dces.tn.edu.tw"
  },
  {
    "代碼": "114651",
    "學校名稱": "市立陽明國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[741]臺南市善化區胡家里300號",
    "電話": "(06)5817165",
    "網址": "http://www.ymes.tn.edu.tw/"
  },
  {
    "代碼": "114652",
    "學校名稱": "市立善糖國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[741]臺南市善化區溪美里245號",
    "電話": "(06)5817304",
    "網址": "http://www.staes.tn.edu.tw"
  },
  {
    "代碼": "114653",
    "學校名稱": "市立小新國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[741]臺南市善化區小新里97之3號",
    "電話": "(06)5837019",
    "網址": "http://www.ssees.tn.edu.tw"
  },
  {
    "代碼": "114654",
    "學校名稱": "市立新市國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[744]臺南市新市區中興街1號",
    "電話": "(06)5992895",
    "網址": "http://www.sinses.tn.edu.tw"
  },
  {
    "代碼": "114655",
    "學校名稱": "市立大社國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[744]臺南市新市區大社里39號",
    "電話": "(06)5991593",
    "網址": "http://www.dsps.tn.edu.tw"
  },
  {
    "代碼": "114656",
    "學校名稱": "市立安定國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[745]臺南市安定區安定里1號",
    "電話": "(06)5922024",
    "網址": "http://www.adps.tn.edu.tw"
  },
  {
    "代碼": "114657",
    "學校名稱": "市立南安國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[745]臺南市安定區南安里62號",
    "電話": "(06)5922023",
    "網址": "http://www.naes.tn.edu.tw"
  },
  {
    "代碼": "114658",
    "學校名稱": "市立安定區南興國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[745]臺南市安定區六嘉里30號",
    "電話": "(06)5931038",
    "網址": "http://www.anses.tn.edu.tw/"
  },
  {
    "代碼": "114659",
    "學校名稱": "市立麻豆國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[721]臺南市麻豆區東角里文昌路18號",
    "電話": "(06)5722145",
    "網址": "http://www.mdes.tn.edu.tw"
  },
  {
    "代碼": "114660",
    "學校名稱": "市立培文國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[721]臺南市麻豆區和平路11號",
    "電話": "(06)5722169",
    "網址": "http://www.pwes.tn.edu.tw"
  },
  {
    "代碼": "114661",
    "學校名稱": "市立文正國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[721]臺南市麻豆區南勢里86號",
    "電話": "(06)5722168",
    "網址": "http://www.wjps.tn.edu.tw"
  },
  {
    "代碼": "114662",
    "學校名稱": "市立大山國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[721]臺南市麻豆區海埔里大山腳1號",
    "電話": "(06)5701374",
    "網址": "http://www.dses.tn.edu.tw/"
  },
  {
    "代碼": "114663",
    "學校名稱": "市立安業國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[721]臺南市麻豆區安東里113號",
    "電話": "(06)5722261",
    "網址": "http://www.ayes.tn.edu.tw"
  },
  {
    "代碼": "114664",
    "學校名稱": "市立北勢國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[721]臺南市麻豆區北勢里15號",
    "電話": "(06)5722844",
    "網址": "http://www.bses.tn.edu.tw"
  },
  {
    "代碼": "114665",
    "學校名稱": "市立港尾國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[721]臺南市麻豆區港尾里港尾9號",
    "電話": "(06)5701341",
    "網址": "http://www.gwes.tn.edu.tw"
  },
  {
    "代碼": "114667",
    "學校名稱": "市立紀安國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[721]臺南市麻豆區謝安里231號",
    "電話": "(06)5722306",
    "網址": "http://www.jaes.tn.edu.tw"
  },
  {
    "代碼": "114668",
    "學校名稱": "市立佳里國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[722]臺南市佳里區安西里公園路445號",
    "電話": "(06)7222031",
    "網址": "http://jles.tn.edu.tw/xoops2/"
  },
  {
    "代碼": "114669",
    "學校名稱": "市立佳興國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[722]臺南市佳里區佳化里214號",
    "電話": "(06)7260311",
    "網址": "http://www.jsps.tn.edu.tw/"
  },
  {
    "代碼": "114670",
    "學校名稱": "市立延平國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[722]臺南市佳里區頂廍里136號",
    "電話": "(06)7222331",
    "網址": "http://www.ypes.tn.edu.tw"
  },
  {
    "代碼": "114671",
    "學校名稱": "市立塭內國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[722]臺南市佳里區塭內里塭子內40號",
    "電話": "(06)7891054",
    "網址": "http://www.wnes.tn.edu.tw/"
  },
  {
    "代碼": "114672",
    "學校名稱": "市立子龍國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[722]臺南市佳里區子龍里潭墘5號",
    "電話": "(06)7262913",
    "網址": "http://www.tlps.tn.edu.tw"
  },
  {
    "代碼": "114673",
    "學校名稱": "市立仁愛國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[722]臺南市佳里區仁愛路307號",
    "電話": "(06)7222227",
    "網址": "http://www.raes.tn.edu.tw"
  },
  {
    "代碼": "114674",
    "學校名稱": "市立通興國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[722]臺南市佳里區通興里埔頂5之1號",
    "電話": "(06)7873531",
    "網址": "http://www.tses.tn.edu.tw"
  },
  {
    "代碼": "114675",
    "學校名稱": "市立西港國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[723]臺南市西港區進學街60號",
    "電話": "(06)7952005",
    "網址": "http://www.siges.tn.edu.tw"
  },
  {
    "代碼": "114676",
    "學校名稱": "市立港東國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[723]臺南市西港區港東里雙張廍1之1號",
    "電話": "(06)7952231",
    "網址": "http://www.gdes.tn.edu.tw"
  },
  {
    "代碼": "114677",
    "學校名稱": "市立西港區成功國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[723]臺南市西港區竹林里11號",
    "電話": "(06)7952204",
    "網址": "http://www.cges.tn.edu.tw"
  },
  {
    "代碼": "114678",
    "學校名稱": "市立後營國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[723]臺南市西港區營西里3號",
    "電話": "(06)7952131",
    "網址": "http://www.hyes.tn.edu.tw"
  },
  {
    "代碼": "114680",
    "學校名稱": "市立松林國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[723]臺南市西港區檨林里39之1號",
    "電話": "(06)7952205",
    "網址": "http://www.soles.tn.edu.tw"
  },
  {
    "代碼": "114681",
    "學校名稱": "市立七股國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[724]臺南市七股區大埕里395號",
    "電話": "(06)7872076",
    "網址": "http://www.cgps.tn.edu.tw"
  },
  {
    "代碼": "114682",
    "學校名稱": "市立後港國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[724]臺南市七股區大潭里台潭1號",
    "電話": "(06)7941305",
    "網址": "http://www.hges.tn.edu.tw"
  },
  {
    "代碼": "114683",
    "學校名稱": "市立竹橋國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[724]臺南市七股區竹橋里130號",
    "電話": "(06)7891041",
    "網址": "http://www.jces.tn.edu.tw"
  },
  {
    "代碼": "114684",
    "學校名稱": "市立三股國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[724]臺南市七股區三股里100號",
    "電話": "(06)7880744",
    "網址": "http://www.sgps.tn.edu.tw"
  },
  {
    "代碼": "114685",
    "學校名稱": "市立光復生態實驗小學",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[724]臺南市七股區塩埕里塩埕1號",
    "電話": "(06)7800530",
    "網址": "http://www.gfes.tn.edu.tw"
  },
  {
    "代碼": "114686",
    "學校名稱": "市立篤加國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[724]臺南市七股區篤加里120號",
    "電話": "(06)7872536",
    "網址": "http://www.djaes.tn.edu.tw/"
  },
  {
    "代碼": "114688",
    "學校名稱": "市立龍山國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[724]臺南市七股區龍山里12號",
    "電話": "(06)7872108",
    "網址": "http://www.lses.tn.edu.tw"
  },
  {
    "代碼": "114689",
    "學校名稱": "市立建功國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[724]臺南市七股區十份里74之1號",
    "電話": "(06)7880114",
    "網址": "http://www.jgps.tn.edu.tw"
  },
  {
    "代碼": "114691",
    "學校名稱": "市立大文國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[724]臺南市七股區大埕里大寮36之2號",
    "電話": "(06)7872730",
    "網址": "http://www.dwes.tn.edu.tw"
  },
  {
    "代碼": "114692",
    "學校名稱": "市立樹林國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[724]臺南市七股區樹林里65之11號",
    "電話": "(06)7891031",
    "網址": "http://www.sules.tn.edu.tw"
  },
  {
    "代碼": "114693",
    "學校名稱": "市立將軍國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[725]臺南市將軍區將貴里58號",
    "電話": "(06)7942034",
    "網址": "http://www.jjes.tn.edu.tw"
  },
  {
    "代碼": "114694",
    "學校名稱": "市立漚汪國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[725]臺南市將軍區長榮里4號",
    "電話": "(06)7942012",
    "網址": "http://www.owes.tn.edu.tw"
  },
  {
    "代碼": "114695",
    "學校名稱": "市立苓和國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[725]臺南市將軍區苓和里132號",
    "電話": "(06)7942031",
    "網址": "http://www.lhes.tn.edu.tw"
  },
  {
    "代碼": "114696",
    "學校名稱": "市立鯤鯓國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[725]臺南市將軍區鯤溟里6號",
    "電話": "(06)7920185",
    "網址": "http://www.ksps.tn.edu.tw"
  },
  {
    "代碼": "114697",
    "學校名稱": "市立長平國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[725]臺南市將軍區長沙里132之2號",
    "電話": "(06)7931146",
    "網址": "http://www.cpes.tn.edu.tw"
  },
  {
    "代碼": "114699",
    "學校名稱": "市立北門國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[727]臺南市北門區北門里舊埕3號",
    "電話": "(06)7862035",
    "網址": "http://www.bmes.tn.edu.tw"
  },
  {
    "代碼": "114700",
    "學校名稱": "市立蚵寮國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[727]臺南市北門區鯤江里791號",
    "電話": "(06)7863250",
    "網址": "http://www.kles.tn.edu.tw"
  },
  {
    "代碼": "114701",
    "學校名稱": "市立文山國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[727]臺南市北門區文山里4鄰溪底寮50號",
    "電話": "(06)7862060",
    "網址": "http://www.wses.tn.edu.tw"
  },
  {
    "代碼": "114702",
    "學校名稱": "市立錦湖國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[727]臺南市北門區錦湖里75號",
    "電話": "(06)7863454",
    "網址": "http://www.jhes.tn.edu.tw"
  },
  {
    "代碼": "114703",
    "學校名稱": "市立雙春國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[727]臺南市北門區雙春里35之2號",
    "電話": "(06)7863141",
    "網址": "http://www.scps.tn.edu.tw/"
  },
  {
    "代碼": "114705",
    "學校名稱": "市立三慈國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[727]臺南市北門區慈安里381號",
    "電話": "(06)7850400",
    "網址": "http://www.stps.tn.edu.tw"
  },
  {
    "代碼": "114706",
    "學校名稱": "市立學甲國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[726]臺南市學甲區秀昌里3鄰一秀30號",
    "電話": "(06)7833220",
    "網址": "http://www.sjaes.tn.edu.tw"
  },
  {
    "代碼": "114707",
    "學校名稱": "市立中洲國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[726]臺南市學甲區光華里645號",
    "電話": "(06)7833214",
    "網址": "http://www.jjps.tn.edu.tw"
  },
  {
    "代碼": "114708",
    "學校名稱": "市立宅港國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[726]臺南市學甲區宅港里13號",
    "電話": "(06)7833227",
    "網址": "http://www.jges.tn.edu.tw"
  },
  {
    "代碼": "114709",
    "學校名稱": "市立頂洲國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[726]臺南市學甲區三慶里頂洲108號",
    "電話": "(06)7810231",
    "網址": "http://www.djues.tn.edu.tw"
  },
  {
    "代碼": "114710",
    "學校名稱": "市立東陽國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[726]臺南市學甲區華宗路260號",
    "電話": "(06)7833322",
    "網址": "http://www.dyps.tn.edu.tw"
  },
  {
    "代碼": "114711",
    "學校名稱": "市立下營國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[735]臺南市下營區仁里里13鄰中山路二段72號",
    "電話": "(06)6892181",
    "網址": "http://www.syes.tn.edu.tw"
  },
  {
    "代碼": "114712",
    "學校名稱": "市立中營國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[735]臺南市下營區開化里270號",
    "電話": "(06)6892600",
    "網址": "http://www.jyes.tn.edu.tw"
  },
  {
    "代碼": "114713",
    "學校名稱": "市立賀建國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[735]臺南市下營區賀建里麻豆寮49號",
    "電話": "(06)6891109",
    "網址": "http://www.hjes.tn.edu.tw"
  },
  {
    "代碼": "114714",
    "學校名稱": "市立甲中國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[735]臺南市下營區紅甲里71號",
    "電話": "(06)6897481",
    "網址": "http://www.cces.tn.edu.tw"
  },
  {
    "代碼": "114716",
    "學校名稱": "市立東興國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[735]臺南市下營區新興里東興一街112號",
    "電話": "(06)6892478",
    "網址": "http://www.thes.tn.edu.tw/"
  },
  {
    "代碼": "114717",
    "學校名稱": "市立六甲國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[734]臺南市六甲區中正路319號",
    "電話": "(06)6982041",
    "網址": "http://www.ljes.tn.edu.tw"
  },
  {
    "代碼": "114718",
    "學校名稱": "市立林鳳國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[734]臺南市六甲區中社里林鳳營240號",
    "電話": "(06)6983583",
    "網址": "http://www.lfes.tn.edu.tw"
  },
  {
    "代碼": "114720",
    "學校名稱": "市立官田國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[720]臺南市官田區官田里11號",
    "電話": "(06)6901195",
    "網址": "http://www.gtes.tn.edu.tw"
  },
  {
    "代碼": "114721",
    "學校名稱": "市立隆田國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[720]臺南市官田區隆田里中山路一段127號",
    "電話": "(06)5791047",
    "網址": "http://www.ltes.tn.edu.tw"
  },
  {
    "代碼": "114722",
    "學校名稱": "市立嘉南國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[734]臺南市六甲區龍湖里珊瑚路301號",
    "電話": "(06)6982491",
    "網址": "http://www.jnes.tn.edu.tw"
  },
  {
    "代碼": "114723",
    "學校名稱": "市立渡拔國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[720]臺南市官田區拔林里77之1號",
    "電話": "(06)6901908",
    "網址": "http://www.dbes.tn.edu.tw"
  },
  {
    "代碼": "114724",
    "學校名稱": "市立大內國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[742]臺南市大內區大內里63號",
    "電話": "(06)5761007",
    "網址": "http://www.dnes.tn.edu.tw"
  },
  {
    "代碼": "114726",
    "學校名稱": "市立二溪國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[742]臺南市大內區二溪里154號",
    "電話": "(06)5762001",
    "網址": "http://www.eses.tn.edu.tw"
  },
  {
    "代碼": "114728",
    "學校名稱": "市立新營國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[730]臺南市新營區中正路4號",
    "電話": "(06)6322136",
    "網址": "http://www.syps.tn.edu.tw"
  },
  {
    "代碼": "114729",
    "學校名稱": "市立新民國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[730]臺南市新營區公園路一段136號",
    "電話": "(06)6562152",
    "網址": "http://www.hmes.tn.edu.tw"
  },
  {
    "代碼": "114730",
    "學校名稱": "市立新橋國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[730]臺南市新營區鐵線里1號",
    "電話": "(06)6581343",
    "網址": "http://www.sces.tn.edu.tw"
  },
  {
    "代碼": "114731",
    "學校名稱": "市立新營區新興國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[730]臺南市新營區太南里316號",
    "電話": "(06)6523779",
    "網址": "http://hhes.dcs.tn.edu.tw"
  },
  {
    "代碼": "114732",
    "學校名稱": "市立新進國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[730]臺南市新營區中正路41號",
    "電話": "(06)6322378",
    "網址": "http://www.sjes.tn.edu.tw"
  },
  {
    "代碼": "114733",
    "學校名稱": "市立南梓實驗小學",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[730]臺南市新營區建業路191號",
    "電話": "(06)6562904",
    "網址": "http://163.26.133.1"
  },
  {
    "代碼": "114734",
    "學校名稱": "市立新生國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[730]臺南市新營區姑爺里52號",
    "電話": "(06)6552524",
    "網址": "http://www.sisps.tn.edu.tw"
  },
  {
    "代碼": "114735",
    "學校名稱": "市立土庫國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[730]臺南市新營區土庫里71之1號",
    "電話": "(06)6362323",
    "網址": "http://163.26.99.1"
  },
  {
    "代碼": "114736",
    "學校名稱": "市立公誠國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[730]臺南市新營區公誠街5號",
    "電話": "(06)6323071",
    "網址": "http://www.gces.tn.edu.tw"
  },
  {
    "代碼": "114737",
    "學校名稱": "市立鹽水國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[737]臺南市鹽水區朝琴路137號",
    "電話": "(06)6521046",
    "網址": "http://yses.tn.edu.tw"
  },
  {
    "代碼": "114738",
    "學校名稱": "市立歡雅國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[737]臺南市鹽水區歡雅里31號",
    "電話": "(06)6552558",
    "網址": "http://www.hyps.tn.edu.tw"
  },
  {
    "代碼": "114739",
    "學校名稱": "市立坔頭港國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[737]臺南市鹽水區坔頭港里202號",
    "電話": "(06)6892014",
    "網址": "http://www.hnes.tn.edu.tw"
  },
  {
    "代碼": "114740",
    "學校名稱": "市立月津國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[737]臺南市鹽水區月津路16號",
    "電話": "(06)6521113",
    "網址": "http://www.yjps.tn.edu.tw"
  },
  {
    "代碼": "114742",
    "學校名稱": "市立竹埔國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[737]臺南市鹽水區竹林里92-1號",
    "電話": "(06)6551001",
    "網址": "http://www.jpes.tn.edu.tw"
  },
  {
    "代碼": "114743",
    "學校名稱": "市立仁光國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[737]臺南市鹽水區三明里舊營117號",
    "電話": "(06)6523620",
    "網址": "http://www.rges.tn.edu.tw"
  },
  {
    "代碼": "114744",
    "學校名稱": "市立岸內國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[737]臺南市鹽水區岸內里新岸內96號",
    "電話": "(06)6524651",
    "網址": "http://www.anes.tn.edu.tw"
  },
  {
    "代碼": "114747",
    "學校名稱": "市立文昌國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[737]臺南市鹽水區文昌里10-3號",
    "電話": "(06)6522664",
    "網址": "http://www.wces.tn.edu.tw"
  },
  {
    "代碼": "114748",
    "學校名稱": "市立白河國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[732]臺南市白河區永安里三民路448號",
    "電話": "(06)6852177",
    "網址": "http://www.bhes.tn.edu.tw"
  },
  {
    "代碼": "114749",
    "學校名稱": "市立玉豐國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[732]臺南市白河區玉豐里85號",
    "電話": "(06)6852524",
    "網址": "http://www.bhyfes.tn.edu.tw"
  },
  {
    "代碼": "114750",
    "學校名稱": "市立竹門國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[732]臺南市白河區竹門里113號",
    "電話": "(06)6851290",
    "網址": "http://www.jmes.tn.edu.tw"
  },
  {
    "代碼": "114751",
    "學校名稱": "市立內角國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[732]臺南市白河區內角里1號",
    "電話": "(06)6817363",
    "網址": "http://www.njes.tn.edu.tw"
  },
  {
    "代碼": "114753",
    "學校名稱": "市立仙草國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[732]臺南市白河區仙草里22號",
    "電話": "(06)6854144",
    "網址": "http://www.setes.tn.edu.tw"
  },
  {
    "代碼": "114755",
    "學校名稱": "市立河東國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[732]臺南市白河區河東里83之1號",
    "電話": "(06)6852527",
    "網址": "http://www.hdps.tn.edu.tw"
  },
  {
    "代碼": "114756",
    "學校名稱": "市立大竹國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[732]臺南市白河區大竹里3號",
    "電話": "(06)6852508",
    "網址": "http://www.djps.tn.edu.tw"
  },
  {
    "代碼": "114758",
    "學校名稱": "市立柳營國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[736]臺南市柳營區士林里柳營路一段755號",
    "電話": "(06)6222124",
    "網址": "http://www.lyes.tn.edu.tw"
  },
  {
    "代碼": "114759",
    "學校名稱": "市立果毅國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[736]臺南市柳營區果毅里61號",
    "電話": "(06)6231310",
    "網址": "https://www.gyps.tn.edu.tw/"
  },
  {
    "代碼": "114760",
    "學校名稱": "市立重溪國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[736]臺南市柳營區重溪里義士路二段2號",
    "電話": "(06)6231824",
    "網址": "http://www.cxes.tn.edu.tw"
  },
  {
    "代碼": "114761",
    "學校名稱": "市立太康國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[736]臺南市柳營區太康里4之1號",
    "電話": "(06)6220684",
    "網址": "http://www.tkaes.tn.edu.tw"
  },
  {
    "代碼": "114762",
    "學校名稱": "市立新山國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[736]臺南市柳營區旭山里新吉庄28之1號",
    "電話": "(06)6231140",
    "網址": "http://www.ssaes.tn.edu.tw"
  },
  {
    "代碼": "114763",
    "學校名稱": "市立後壁國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[731]臺南市後壁區後壁里195號",
    "電話": "(06)6872915",
    "網址": "http://www.hbes.tn.edu.tw"
  },
  {
    "代碼": "114764",
    "學校名稱": "市立菁寮國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[731]臺南市後壁區墨林里282號",
    "電話": "(06)6621271",
    "網址": "http://www.jlps.tn.edu.tw"
  },
  {
    "代碼": "114765",
    "學校名稱": "市立安溪國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[731]臺南市後壁區長安里5號",
    "電話": "(06)6361535",
    "網址": "http://www.ases.tn.edu.tw/index.php"
  },
  {
    "代碼": "114766",
    "學校名稱": "市立新東國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[731]臺南市後壁區長短樹里下長81號",
    "電話": "(06)6320902",
    "網址": "http://www.sdes.tn.edu.tw"
  },
  {
    "代碼": "114767",
    "學校名稱": "市立永安國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[731]臺南市後壁區嘉田里48之5號",
    "電話": "(06)6881763",
    "網址": "http://163.26.121.1/index1.asp"
  },
  {
    "代碼": "114768",
    "學校名稱": "市立新嘉國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[731]臺南市後壁區新嘉里210之2號",
    "電話": "(06)6621981",
    "網址": "http://www.hces.tn.edu.tw"
  },
  {
    "代碼": "114769",
    "學校名稱": "市立樹人國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[731]臺南市後壁區烏樹里145號",
    "電話": "(06)6856445",
    "網址": "http://www.sres.tn.edu.tw"
  },
  {
    "代碼": "114770",
    "學校名稱": "市立東山國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[733]臺南市東山區東中里青葉路二段49號",
    "電話": "(06)6802274",
    "網址": "http://www.doses.tn.edu.tw"
  },
  {
    "代碼": "114771",
    "學校名稱": "市立聖賢國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[733]臺南市東山區聖賢里田尾29號",
    "電話": "(06)6353170",
    "網址": "http://120.116.98.1"
  },
  {
    "代碼": "114772",
    "學校名稱": "市立東原國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[733]臺南市東山區東原里23鄰8號",
    "電話": "(06)6861042",
    "網址": "http://www.dyes.tn.edu.tw"
  },
  {
    "代碼": "114774",
    "學校名稱": "市立青山國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[733]臺南市東山區青山里16號",
    "電話": "(06)6861041",
    "網址": "http://csps.dcs.tn.edu.tw"
  },
  {
    "代碼": "114775",
    "學校名稱": "市立吉貝耍國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[733]臺南市東山區東河里15號",
    "電話": "(06)6231149",
    "網址": "https://www.dhes.tn.edu.tw/"
  },
  {
    "代碼": "114776",
    "學校名稱": "市立崑山國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[710]臺南市永康區國光五街72號",
    "電話": "(06)2711640",
    "網址": "http://163.26.173.1"
  },
  {
    "代碼": "114777",
    "學校名稱": "市立五王國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[710]臺南市永康區中華二路150號",
    "電話": "(06)2336842",
    "網址": "http://web.wwes.tn.edu.tw/"
  },
  {
    "代碼": "114778",
    "學校名稱": "市立文化國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[711]臺南市歸仁區文化街二段136號",
    "電話": "(06)3301666",
    "網址": "http://www.whes.tn.edu.tw/"
  },
  {
    "代碼": "114779",
    "學校名稱": "市立正新國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[712]臺南市新化區豐榮里正新路97號",
    "電話": "(06)5973113",
    "網址": "http://jses.dcs.tn.edu.tw"
  },
  {
    "代碼": "114780",
    "學校名稱": "市立信義國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[722]臺南市佳里區忠仁里信義一街336號",
    "電話": "(06)7211918",
    "網址": "http://www.syies.tn.edu.tw/"
  },
  {
    "代碼": "114781",
    "學校名稱": "市立新泰國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[730]臺南市新營區東學路77號",
    "電話": "(06)6330496",
    "網址": "http://htaes.dcs.tn.edu.tw/"
  },
  {
    "代碼": "114782",
    "學校名稱": "市立永信國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[710]臺南市永康區復華7街166號",
    "電話": "(06)2320783",
    "網址": "http://www.yuses.tn.edu.tw"
  },
  {
    "代碼": "114784",
    "學校名稱": "市立永康區勝利國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[710]臺南市永康區勝學路111號",
    "電話": "(06)3130011",
    "網址": "http://www.sles.tn.edu.tw/"
  },
  {
    "代碼": "114785",
    "學校名稱": "市立紅瓦厝國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[711]臺南市歸仁區信義南路100號",
    "電話": "(06)2309012",
    "網址": "http://www.hwces.tn.edu.tw"
  },
  {
    "代碼": "210601",
    "學校名稱": "國立臺南大學附小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[700]臺南市中西區樹林街二段31號",
    "電話": "(06)2132566",
    "網址": "http://www.ps.nutn.edu.tw/"
  },
  {
    "代碼": "211601",
    "學校名稱": "私立寶仁小學",
    "公/私立": "私立",
    "縣市名稱": "[21]臺南市",
    "地址": "[704]臺南市北區重興里開南街211巷6號",
    "電話": "(06)2369369",
    "網址": "http://www.pjps.tn.edu.tw"
  },
  {
    "代碼": "213601",
    "學校名稱": "市立東區勝利國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[701]臺南市東區中西里勝利路10號",
    "電話": "(06)2372982",
    "網址": "http://www.slps.tn.edu.tw"
  },
  {
    "代碼": "213602",
    "學校名稱": "市立博愛國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[701]臺南市東區圍下里前鋒路100號",
    "電話": "(06)2377905",
    "網址": "http://www.paes.tn.edu.tw"
  },
  {
    "代碼": "213603",
    "學校名稱": "市立東區大同國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[701]臺南市東區榮譽街30號",
    "電話": "(06)2151761",
    "網址": "http://www.ttes.tn.edu.tw"
  },
  {
    "代碼": "213604",
    "學校名稱": "市立東光國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[701]臺南市東區東光路一段39號",
    "電話": "(06)2376534",
    "網址": "http://www.tkes.tn.edu.tw"
  },
  {
    "代碼": "213605",
    "學校名稱": "市立德高國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[701]臺南市東區崇善路1155號",
    "電話": "(06)2681891",
    "網址": "http://www.tkps.tn.edu.tw"
  },
  {
    "代碼": "213606",
    "學校名稱": "市立崇學國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[701]臺南市東區崇學路98號",
    "電話": "(06)2689951",
    "網址": "http://www.ches.tn.edu.tw"
  },
  {
    "代碼": "213607",
    "學校名稱": "市立志開實驗小學",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[702]臺南市南區南門路232號",
    "電話": "(06)2619431",
    "網址": "http://zkes.dcs.tn.edu.tw/"
  },
  {
    "代碼": "213608",
    "學校名稱": "市立南區新興國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[702]臺南市南區新興路22號",
    "電話": "(06)2617452",
    "網址": "http://www.sses.tn.edu.tw"
  },
  {
    "代碼": "213609",
    "學校名稱": "市立省躬國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[702]臺南市南區灣裡路70號",
    "電話": "(06)2622460",
    "網址": "http://www.sges.tn.edu.tw"
  },
  {
    "代碼": "213610",
    "學校名稱": "市立喜樹國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[702]臺南市南區喜東里喜樹路133號",
    "電話": "(06)2622462",
    "網址": "http://www.ssps.tn.edu.tw"
  },
  {
    "代碼": "213611",
    "學校名稱": "市立龍崗國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[702]臺南市南區鯤鯓里鯤鯓路147號",
    "電話": "(06)2620024",
    "網址": "http://www.lgps.tn.edu.tw"
  },
  {
    "代碼": "213612",
    "學校名稱": "市立日新國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[702]臺南市南區金華路一段473號",
    "電話": "(06)2912931",
    "網址": "http://www.zhes.tn.edu.tw"
  },
  {
    "代碼": "213613",
    "學校名稱": "市立永華國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[702]臺南市南區中華西路一段2巷17號",
    "電話": "(06)2641457",
    "網址": "http://www.yhps.tn.edu.tw"
  },
  {
    "代碼": "213614",
    "學校名稱": "市立協進國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[700]臺南市中西區金華路四段17號",
    "電話": "(06)2223369",
    "網址": "http://www.sjps.tn.edu.tw"
  },
  {
    "代碼": "213615",
    "學校名稱": "市立新南國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[708]臺南市安平區怡平路6號",
    "電話": "(06)2973363",
    "網址": "http://www.hnps.tn.edu.tw"
  },
  {
    "代碼": "213616",
    "學校名稱": "市立立人國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[704]臺南市北區西門路三段41號",
    "電話": "(06)2222054",
    "網址": "http://www.lines.tn.edu.tw"
  },
  {
    "代碼": "213617",
    "學校名稱": "市立公園國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[704]臺南市北區公園里公園路180號",
    "電話": "(06)2223291",
    "網址": "http://www.gyes.tn.edu.tw"
  },
  {
    "代碼": "213618",
    "學校名稱": "市立開元國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[704]臺南市北區勝安里開元路71巷32號",
    "電話": "(06)2375509",
    "網址": "http://www.kyes.tn.edu.tw"
  },
  {
    "代碼": "213619",
    "學校名稱": "市立大光國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[704]臺南市北區長榮路五段277號",
    "電話": "(06)2518465",
    "網址": "http://www.tykes.tn.edu.tw"
  },
  {
    "代碼": "213620",
    "學校名稱": "市立中西區成功國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[704]臺南市北區文賢ㄧ路2號(文賢國中)",
    "電話": "(06)3588635",
    "網址": "http://www.ckes.tn.edu.tw"
  },
  {
    "代碼": "213621",
    "學校名稱": "市立永福國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[700]臺南市中西區永福路二段86號",
    "電話": "(06)2223241",
    "網址": "http://www.yfes.tn.edu.tw"
  },
  {
    "代碼": "213622",
    "學校名稱": "市立忠義國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[700]臺南市中西區永華里忠義路二段2號",
    "電話": "(06)2222768",
    "網址": "http://www.cies.tn.edu.tw"
  },
  {
    "代碼": "213623",
    "學校名稱": "市立進學國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[700]臺南市中西區南寧街47號",
    "電話": "(06)2133007",
    "網址": "http://www.chps.tn.edu.tw"
  },
  {
    "代碼": "213624",
    "學校名稱": "市立石門國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[708]臺南市安平區石門里安平路700號",
    "電話": "(06)2223332",
    "網址": "http://www.smes.tn.edu.tw"
  },
  {
    "代碼": "213625",
    "學校名稱": "市立西門實驗小學",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[708]臺南市安平區安北路180號",
    "電話": "(06)3914141",
    "網址": "http://www.hmps.tn.edu.tw"
  },
  {
    "代碼": "213626",
    "學校名稱": "市立安順國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[709]臺南市安南區安和路三段193號",
    "電話": "(06)3559451",
    "網址": "http://www.asps.tn.edu.tw"
  },
  {
    "代碼": "213627",
    "學校名稱": "市立和順國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[709]臺南市安南區安和路五段178巷5號",
    "電話": "(06)3560691",
    "網址": "http://www.hses.tn.edu.tw"
  },
  {
    "代碼": "213628",
    "學校名稱": "市立海東國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[709]臺南市安南區安中路三段381號",
    "電話": "(06)2567146",
    "網址": "http://www.htps.tn.edu.tw"
  },
  {
    "代碼": "213629",
    "學校名稱": "市立安慶國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[709]臺南市安南區安中路一段703巷80號",
    "電話": "(06)2460334",
    "網址": "http://www.aces.tn.edu.tw"
  },
  {
    "代碼": "213630",
    "學校名稱": "市立土城國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[709]臺南市安南區城東里城北路195號",
    "電話": "(06)2577645",
    "網址": "http://www.tcps.tn.edu.tw"
  },
  {
    "代碼": "213631",
    "學校名稱": "市立青草國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[709]臺南市安南區青砂街二段105巷32號",
    "電話": "(06)2577631",
    "網址": "http://www.ctes.tn.edu.tw"
  },
  {
    "代碼": "213632",
    "學校名稱": "市立鎮海國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[709]臺南市安南區大眾路381號",
    "電話": "(06)2841700",
    "網址": "http://www.jhps.tn.edu.tw"
  },
  {
    "代碼": "213633",
    "學校名稱": "市立顯宮國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[709]臺南市安南區顯宮二街1號",
    "電話": "(06)2841723",
    "網址": "http://www.skes.tn.edu.tw"
  },
  {
    "代碼": "213634",
    "學校名稱": "市立長安國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[709]臺南市安南區長溪路三段249號",
    "電話": "(06)2569914",
    "網址": "http://www.caps.tn.edu.tw"
  },
  {
    "代碼": "213635",
    "學校名稱": "市立安南區南興國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[709]臺南市安南區公學路五段627號",
    "電話": "(06)2873204",
    "網址": "http://www.nses.tn.edu.tw"
  },
  {
    "代碼": "213636",
    "學校名稱": "市立安佃國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[709]臺南市安南區海佃路四段493號",
    "電話": "(06)2567642",
    "網址": "http://www.ades.tn.edu.tw"
  },
  {
    "代碼": "213637",
    "學校名稱": "市立大港國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[704]臺南市北區大港街146號",
    "電話": "(06)2591941",
    "網址": "http://www.takes.tn.edu.tw"
  },
  {
    "代碼": "213638",
    "學校名稱": "市立海佃國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[709]臺南市安南區郡安路五段56號",
    "電話": "(06)2505013",
    "網址": "http://www.hdes.tn.edu.tw"
  },
  {
    "代碼": "213639",
    "學校名稱": "市立東區復興國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[701]臺南市東區裕文路60號",
    "電話": "(06)3310457",
    "網址": "http://www.fhes.tn.edu.tw"
  },
  {
    "代碼": "213640",
    "學校名稱": "市立崇明國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[701]臺南市東區崇明路698號",
    "電話": "(06)2673330",
    "網址": "http://www.cmes.tn.edu.tw"
  },
  {
    "代碼": "213641",
    "學校名稱": "市立安平國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[708]臺南市安平區怡平路392號",
    "電話": "(06)2996735",
    "網址": "http://www.apps.tn.edu.tw"
  },
  {
    "代碼": "213642",
    "學校名稱": "市立文元國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[704]臺南市北區海安路三段815號",
    "電話": "(06)3584371",
    "網址": "http://www.wyes.tn.edu.tw"
  },
  {
    "代碼": "213643",
    "學校名稱": "市立學東國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[709]臺南市安南區公學路六段589號",
    "電話": "(06)2870017",
    "網址": "http://www.htes.tn.edu.tw"
  },
  {
    "代碼": "213644",
    "學校名稱": "市立億載國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[708]臺南市安平區郡平路310號",
    "電話": "(06)2932371",
    "網址": "http://www.yzes.tn.edu.tw"
  },
  {
    "代碼": "213645",
    "學校名稱": "市立賢北國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[704]臺南市北區大興街599號",
    "電話": "(06)3501433",
    "網址": "http://www.sbes.tn.edu.tw"
  },
  {
    "代碼": "213646",
    "學校名稱": "市立裕文國小",
    "公/私立": "公立",
    "縣市名稱": "[21]臺南市",
    "地址": "[701]臺南市東區裕文路301號",
    "電話": "(06)3317657",
    "網址": "http://www.ywes.tn.edu.tw"
  },
  {
    "代碼": "124601",
    "學校名稱": "市立鳳山國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區興仁里中山路231號",
    "電話": "(07)7419119",
    "網址": "http://www.fsp.ks.edu.tw"
  },
  {
    "代碼": "124602",
    "學校名稱": "市立大東國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區大東一路1號",
    "電話": "(07)7462344",
    "網址": "http://www.ddp.ks.edu.tw"
  },
  {
    "代碼": "124603",
    "學校名稱": "市立文山國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區鳳松路25巷20號",
    "電話": "(07)7460412",
    "網址": "http://www.wsp.ks.edu.tw"
  },
  {
    "代碼": "124604",
    "學校名稱": "市立鳳山區中正國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區勝利路2號",
    "電話": "(07)7012831",
    "網址": "http://www.zzp.ks.edu.tw"
  },
  {
    "代碼": "124605",
    "學校名稱": "市立五甲國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區五甲二路424號",
    "電話": "(07)7619494",
    "網址": "http://www.wzp.ks.edu.tw"
  },
  {
    "代碼": "124606",
    "學校名稱": "市立曹公國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區曹公路6號",
    "電話": "(07)7460449",
    "網址": "http://www.tgp.ks.edu.tw"
  },
  {
    "代碼": "124607",
    "學校名稱": "市立誠正國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區黃埔新村國校巷78號",
    "電話": "(07)7463417",
    "網址": "http://affairs.kh.edu.tw/2331/fpage/view/8"
  },
  {
    "代碼": "124608",
    "學校名稱": "市立南成國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區南成里南和街101號",
    "電話": "(07)8215354",
    "網址": "http://www.ntp.ks.edu.tw"
  },
  {
    "代碼": "124609",
    "學校名稱": "市立五福國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區五福里福德街145號",
    "電話": "(07)8211864",
    "網址": "http://www.wfp.ks.edu.tw"
  },
  {
    "代碼": "124610",
    "學校名稱": "市立鳳山區中山國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區光復路一段120巷8號",
    "電話": "(07)7465273",
    "網址": "http://www.zsp.ks.edu.tw"
  },
  {
    "代碼": "124611",
    "學校名稱": "市立新甲國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區新強里新富路255號",
    "電話": "(07)7668170",
    "網址": "http://www.xjp.ks.edu.tw"
  },
  {
    "代碼": "124612",
    "學校名稱": "市立鳳山區忠孝國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區國富里新富路630號",
    "電話": "(07)7632257",
    "網址": "http://www.zxp.ks.edu.tw"
  },
  {
    "代碼": "124613",
    "學校名稱": "市立林園國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[832]高雄市林園區林園里忠孝西路20號",
    "電話": "(07)6412125",
    "網址": "http://www.lyp.ks.edu.tw"
  },
  {
    "代碼": "124614",
    "學校名稱": "市立中芸國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[832]高雄市林園區中芸里中芸路1號",
    "電話": "(07)6412342",
    "網址": "http://www.zyp.ks.edu.tw"
  },
  {
    "代碼": "124615",
    "學校名稱": "市立港埔國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[832]高雄市林園區頂厝里校前路1號",
    "電話": "(07)6412282",
    "網址": "http://www.gpp.ks.edu.tw"
  },
  {
    "代碼": "124616",
    "學校名稱": "市立金潭國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[832]高雄市林園區中厝里金潭路40號",
    "電話": "(07)6412332",
    "網址": "http://www.jtp.ks.edu.tw"
  },
  {
    "代碼": "124617",
    "學校名稱": "市立汕尾國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[832]高雄市林園區北汕里58巷2號",
    "電話": "(07)6412334",
    "網址": "http://www.swp.ks.edu.tw"
  },
  {
    "代碼": "124618",
    "學校名稱": "市立永芳國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[831]高雄市大寮區永芳里鳳林三路499號",
    "電話": "(07)7812814",
    "網址": "http://www.yfp.ks.edu.tw"
  },
  {
    "代碼": "124619",
    "學校名稱": "市立翁園國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[831]高雄市大寮區翁園里翁園路79號",
    "電話": "(07)7811685",
    "網址": "http://www.wyp.ks.edu.tw"
  },
  {
    "代碼": "124620",
    "學校名稱": "市立忠義國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[831]高雄市大寮區忠義里忠義路1號",
    "電話": "(07)7812858",
    "網址": "http://www.zyi.ks.edu.tw"
  },
  {
    "代碼": "124621",
    "學校名稱": "市立昭明國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[831]高雄市大寮區鳳林一路300巷80號",
    "電話": "(07)6426428",
    "網址": "http://www.zmp.ks.edu.tw"
  },
  {
    "代碼": "124622",
    "學校名稱": "市立潮寮國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[831]高雄市大寮區潮寮里潮寮路61號",
    "電話": "(07)7875075",
    "網址": "http://www.tlp.ks.edu.tw"
  },
  {
    "代碼": "124623",
    "學校名稱": "市立中庄國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[831]高雄市大寮區中庄里中庄路59號",
    "電話": "(07)7032838",
    "網址": "http://www.zzn.ks.edu.tw"
  },
  {
    "代碼": "124624",
    "學校名稱": "市立溪寮國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[831]高雄市大寮區溪寮里溪寮路69號",
    "電話": "(07)6516256",
    "網址": "http://www.xlp.ks.edu.tw"
  },
  {
    "代碼": "124625",
    "學校名稱": "市立大寮國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[831]高雄市大寮區大寮路740號",
    "電話": "(07)7881652",
    "網址": "http://www.dlp.ks.edu.tw"
  },
  {
    "代碼": "124626",
    "學校名稱": "市立大樹國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[840]高雄市大樹區中正一路249號",
    "電話": "(07)6513752",
    "網址": "http://www.dsp.ks.edu.tw"
  },
  {
    "代碼": "124627",
    "學校名稱": "市立九曲國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[840]高雄市大樹區九曲里九曲路498號",
    "電話": "(07)6512541",
    "網址": "http://www.jqp.ks.edu.tw"
  },
  {
    "代碼": "124628",
    "學校名稱": "市立溪埔國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[840]高雄市大樹區溪埔路43號",
    "電話": "(07)6565242",
    "網址": "http://www.xpp.ks.edu.tw"
  },
  {
    "代碼": "124629",
    "學校名稱": "市立姑山國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[840]高雄市大樹區姑山里1號",
    "電話": "(07)6563509",
    "網址": "http://www.gsp.ks.edu.tw"
  },
  {
    "代碼": "124630",
    "學校名稱": "市立水寮國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[840]高雄市大樹區水寮里中華路國小巷1號",
    "電話": "(07)6515536",
    "網址": "http://www.slp.ks.edu.tw"
  },
  {
    "代碼": "124631",
    "學校名稱": "市立小坪國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[840]高雄市大樹區小坪路73號",
    "電話": "(07)6515232",
    "網址": "http://www.xpn.ks.edu.tw"
  },
  {
    "代碼": "124632",
    "學校名稱": "市立興田國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[840]高雄市大樹區興田里興田路59號",
    "電話": "(07)6561527",
    "網址": "http://www.xtp.ks.edu.tw"
  },
  {
    "代碼": "124633",
    "學校名稱": "市立龍目國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[840]高雄市大樹區龍目里56號",
    "電話": "(07)6514110",
    "網址": "http://www.lmp.ks.edu.tw"
  },
  {
    "代碼": "124634",
    "學校名稱": "市立仁武國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[814]高雄市仁武區中正路94號",
    "電話": "(07)3711126",
    "網址": "http://www.rwp.ks.edu.tw"
  },
  {
    "代碼": "124635",
    "學校名稱": "市立烏林國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[814]高雄市仁武區烏林里林森巷23號",
    "電話": "(07)3711431",
    "網址": "http://www.wlp.ks.edu.tw"
  },
  {
    "代碼": "124636",
    "學校名稱": "市立八卦國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[814]高雄市仁武區八卦里永仁街555號",
    "電話": "(07)3718964",
    "網址": "http://www.bgp.ks.edu.tw"
  },
  {
    "代碼": "124637",
    "學校名稱": "市立灣內國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[814]高雄市仁武區灣內里仁心路31號",
    "電話": "(07)3718228",
    "網址": "http://www.wnp.ks.edu.tw"
  },
  {
    "代碼": "124638",
    "學校名稱": "市立大社區大社國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[815]高雄市大社區大社里三民路336號",
    "電話": "(07)3511940",
    "網址": "http://www.dso.ks.edu.tw"
  },
  {
    "代碼": "124639",
    "學校名稱": "市立嘉誠國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[815]高雄市大社區嘉誠里嘉誠路22號",
    "電話": "(07)3560418",
    "網址": "http://www.jtn.ks.edu.tw"
  },
  {
    "代碼": "124640",
    "學校名稱": "市立鳥松國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[833]高雄市鳥松區鳥松里文前路32號",
    "電話": "(07)7321780",
    "網址": "http://www.nsp.ks.edu.tw"
  },
  {
    "代碼": "124641",
    "學校名稱": "市立仁美國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[833]高雄市鳥松區仁美里學堂路2號",
    "電話": "(07)7315633",
    "網址": "http://www.rmp.ks.edu.tw"
  },
  {
    "代碼": "124642",
    "學校名稱": "市立大華國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[833]高雄市鳥松區大華里大華路200號",
    "電話": "(07)3701359",
    "網址": "http://www.dhp.ks.edu.tw"
  },
  {
    "代碼": "124643",
    "學校名稱": "市立岡山國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[820]高雄市岡山區平安里柳橋東路36號",
    "電話": "(07)6212032",
    "網址": "http://www.gsn.ks.edu.tw"
  },
  {
    "代碼": "124644",
    "學校名稱": "市立前峰國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[820]高雄市岡山區仁壽里育英路35號",
    "電話": "(07)6262415",
    "網址": "http://www.qfp.ks.edu.tw"
  },
  {
    "代碼": "124645",
    "學校名稱": "市立嘉興國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[820]高雄市岡山區嘉興里嘉興路322號",
    "電話": "(07)6212414",
    "網址": "http://www.jsp.ks.edu.tw"
  },
  {
    "代碼": "124646",
    "學校名稱": "市立兆湘國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[820]高雄市岡山區介壽路60號",
    "電話": "(07)6252394",
    "網址": "http://www.zxn.ks.edu.tw"
  },
  {
    "代碼": "124647",
    "學校名稱": "市立後紅國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[820]高雄市岡山區碧紅里岡燕路2巷88號",
    "電話": "(07)6212815",
    "網址": "http://www.hfp.ks.edu.tw"
  },
  {
    "代碼": "124648",
    "學校名稱": "市立和平國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[820]高雄市岡山區和平里和平路1號",
    "電話": "(07)6254220",
    "網址": "http://www.hpp.ks.edu.tw"
  },
  {
    "代碼": "124650",
    "學校名稱": "市立仕隆國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[825]高雄市橋頭區仕隆路進校巷16號",
    "電話": "(07)6113517",
    "網址": "http://www.sln.ks.edu.tw"
  },
  {
    "代碼": "124651",
    "學校名稱": "市立五林國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[825]高雄市橋頭區東林里五林路160號",
    "電話": "(07)6113549",
    "網址": "http://www.wln.ks.edu.tw"
  },
  {
    "代碼": "124652",
    "學校名稱": "市立甲圍國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[825]高雄市橋頭區甲北里甲昌路250號",
    "電話": "(07)6113647",
    "網址": "http://www.jwp.ks.edu.tw"
  },
  {
    "代碼": "124653",
    "學校名稱": "市立興糖國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[825]高雄市橋頭區橋南里興糖路19巷1號",
    "電話": "(07)6113804",
    "網址": "http://www.xtn.ks.edu.tw"
  },
  {
    "代碼": "124654",
    "學校名稱": "市立燕巢國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[824]高雄市燕巢區東燕里中華路177號",
    "電話": "(07)6161495",
    "網址": "http://www.yqp.ks.edu.tw"
  },
  {
    "代碼": "124655",
    "學校名稱": "市立橫山國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[824]高雄市燕巢區橫山里橫山路24號",
    "電話": "(07)6152479",
    "網址": "http://www.hsp.ks.edu.tw"
  },
  {
    "代碼": "124656",
    "學校名稱": "市立深水國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[824]高雄市燕巢區深水里深中路2號",
    "電話": "(07)6152447",
    "網址": "http://www.ssp.ks.edu.tw"
  },
  {
    "代碼": "124657",
    "學校名稱": "市立安招國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[824]高雄市燕巢區瓊林里安招路170號",
    "電話": "(07)6162553",
    "網址": "http://www.azp.ks.edu.tw"
  },
  {
    "代碼": "124658",
    "學校名稱": "市立鳳雄國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[824]高雄市燕巢區鳳雄里鳳加路52號",
    "電話": "(07)6153782",
    "網址": "http://www.fxn.ks.edu.tw"
  },
  {
    "代碼": "124659",
    "學校名稱": "市立金山國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[824]高雄市燕巢區金山里番田路29號",
    "電話": "(07)6164328",
    "網址": "http://www.jsn.ks.edu.tw"
  },
  {
    "代碼": "124660",
    "學校名稱": "市立田寮區新興國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[823]高雄市田寮區新興里大新路2號",
    "電話": "(07)6381348",
    "網址": "http://www.xxp.ks.edu.tw"
  },
  {
    "代碼": "124661",
    "學校名稱": "市立崇德國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[823]高雄市田寮區崇德里崇德路101號",
    "電話": "(07)6367155",
    "網址": "http://www.tdp.ks.edu.tw"
  },
  {
    "代碼": "124664",
    "學校名稱": "市立阿蓮國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[822]高雄市阿蓮區阿蓮里民族路163號",
    "電話": "(07)6312049",
    "網址": "http://www.alp.ks.edu.tw"
  },
  {
    "代碼": "124665",
    "學校名稱": "市立中路國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[822]高雄市阿蓮區中路里272號",
    "電話": "(07)6312325",
    "網址": "http://www.zlp.ks.edu.tw"
  },
  {
    "代碼": "124666",
    "學校名稱": "市立復安國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[822]高雄市阿蓮區復安里復安路167號",
    "電話": "(07)6332358",
    "網址": "http://www.fap.ks.edu.tw"
  },
  {
    "代碼": "124667",
    "學校名稱": "市立路竹國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[821]高雄市路竹區竹南里中山路712巷16號",
    "電話": "(07)6969920",
    "網址": "http://www.lzp.ks.edu.tw"
  },
  {
    "代碼": "124668",
    "學校名稱": "市立路竹區大社國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[821]高雄市路竹區社西里大社路243號",
    "電話": "(07)6962254",
    "網址": "http://www.lds.ks.edu.tw"
  },
  {
    "代碼": "124669",
    "學校名稱": "市立下坑國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[821]高雄市路竹區下坑里太平路384號",
    "電話": "(07)6951015",
    "網址": "http://www.xkp.ks.edu.tw"
  },
  {
    "代碼": "124670",
    "學校名稱": "市立竹滬國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[821]高雄市路竹區竹滬里成功路2號",
    "電話": "(07)6981516",
    "網址": "http://www.zfp.ks.edu.tw"
  },
  {
    "代碼": "124671",
    "學校名稱": "市立三埤國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[821]高雄市路竹區三爺里民權路7號",
    "電話": "(07)6962688",
    "網址": "http://www.spi.ks.edu.tw"
  },
  {
    "代碼": "124672",
    "學校名稱": "市立北嶺國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[821]高雄市路竹區北嶺里民治路85號",
    "電話": "(07)6964786",
    "網址": "http://www.blp.ks.edu.tw"
  },
  {
    "代碼": "124673",
    "學校名稱": "市立一甲國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[821]高雄市路竹區甲南里大智路43號",
    "電話": "(07)6962454",
    "網址": "http://www.yjp.ks.edu.tw"
  },
  {
    "代碼": "124674",
    "學校名稱": "市立文賢國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[829]高雄市湖內區中賢里中正路二段39號",
    "電話": "(07)6993061",
    "網址": "http://www.wxp.ks.edu.tw"
  },
  {
    "代碼": "124675",
    "學校名稱": "市立明宗國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[829]高雄市湖內區保生路103號",
    "電話": "(07)6994682",
    "網址": "http://www.mzp.ks.edu.tw"
  },
  {
    "代碼": "124676",
    "學校名稱": "市立大湖國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[829]高雄市湖內區大湖里民權路2號",
    "電話": "(07)6933808",
    "網址": "http://www.dhu.ks.edu.tw"
  },
  {
    "代碼": "124677",
    "學校名稱": "市立海埔國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[829]高雄市湖內區海埔里忠孝街256號",
    "電話": "(07)6992346",
    "網址": "http://www.hpu.ks.edu.tw"
  },
  {
    "代碼": "124678",
    "學校名稱": "市立三侯國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[829]高雄市湖內區中正路二段544號",
    "電話": "(07)6909123",
    "網址": "http://www.shp.ks.edu.tw"
  },
  {
    "代碼": "124679",
    "學校名稱": "市立茄萣國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[852]高雄市茄萣區茄萣路二段307號",
    "電話": "(07)6900057",
    "網址": "http://www.qdp.ks.edu.tw"
  },
  {
    "代碼": "124680",
    "學校名稱": "市立茄萣區成功國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[852]高雄市茄萣區吉定里成功路1號",
    "電話": "(07)6902053",
    "網址": "http://www.tgn.ks.edu.tw"
  },
  {
    "代碼": "124681",
    "學校名稱": "市立砂崙國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[852]高雄市茄萣區萬福里文化路81號",
    "電話": "(07)6900037",
    "網址": "http://www.slu.ks.edu.tw"
  },
  {
    "代碼": "124682",
    "學校名稱": "市立永安國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[828]高雄市永安區永華里永華路49號",
    "電話": "(07)6912011",
    "網址": "http://www.yap.ks.edu.tw"
  },
  {
    "代碼": "124683",
    "學校名稱": "市立新港國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[828]高雄市永安區新港里新興路25號",
    "電話": "(07)6912040",
    "網址": "http://www.xgp.ks.edu.tw"
  },
  {
    "代碼": "124684",
    "學校名稱": "市立彌陀國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[827]高雄市彌陀區彌靖里中正路213號",
    "電話": "(07)6176300",
    "網址": "http://www.mtp.ks.edu.tw"
  },
  {
    "代碼": "124685",
    "學校名稱": "市立南安國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[827]高雄市彌陀區漯底里樂安路1號",
    "電話": "(07)6191810",
    "網址": "http://www.nap.ks.edu.tw"
  },
  {
    "代碼": "124686",
    "學校名稱": "市立壽齡國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[827]高雄市彌陀區文安里國校路1號",
    "電話": "(07)6196425",
    "網址": "http://www.sli.ks.edu.tw"
  },
  {
    "代碼": "124687",
    "學校名稱": "市立梓官國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[826]高雄市梓官區進學路61號",
    "電話": "(07)6193927",
    "網址": "http://www.zgp.ks.edu.tw"
  },
  {
    "代碼": "124688",
    "學校名稱": "市立蚵寮國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[826]高雄市梓官區禮蚵里光明路177號",
    "電話": "(07)6101044",
    "網址": "http://www.klp.ks.edu.tw"
  },
  {
    "代碼": "124689",
    "學校名稱": "市立旗山國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[842]高雄市旗山區湄洲里華中街44號",
    "電話": "(07)6612052",
    "網址": "http://www.gsp.ks.edu.tw"
  },
  {
    "代碼": "124690",
    "學校名稱": "市立溪洲國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[842]高雄市旗山區大山里旗南二路126號",
    "電話": "(07)6661408",
    "網址": "http://www.xzp.ks.edu.tw"
  },
  {
    "代碼": "124691",
    "學校名稱": "市立旗山區鼓山國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[842]高雄市旗山區延平一路111號",
    "電話": "(07)6612051",
    "網址": "http://www.gsa.ks.edu.tw"
  },
  {
    "代碼": "124692",
    "學校名稱": "市立圓潭國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[842]高雄市旗山區中正里旗甲路三段179號",
    "電話": "(07)6691476",
    "網址": "http://www.ytp.ks.edu.tw"
  },
  {
    "代碼": "124693",
    "學校名稱": "市立旗尾國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[842]高雄市旗山區東平里延平二路19號",
    "電話": "(07)6616708",
    "網址": "http://www.qwp.ks.edu.tw"
  },
  {
    "代碼": "124694",
    "學校名稱": "市立嶺口國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[842]高雄市旗山區南勝里龍文巷30號",
    "電話": "(07)6651216",
    "網址": "http://www.lkp.ks.edu.tw"
  },
  {
    "代碼": "124697",
    "學校名稱": "市立美濃國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[843]高雄市美濃區泰安里永安路190號",
    "電話": "(07)6810166",
    "網址": "http://www.mnp.ks.edu.tw"
  },
  {
    "代碼": "124698",
    "學校名稱": "市立東門國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[843]高雄市美濃區東門里民族路36巷2號",
    "電話": "(07)6812811",
    "網址": "http://www.dmp.ks.edu.tw"
  },
  {
    "代碼": "124699",
    "學校名稱": "市立吉洋國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[843]高雄市美濃區吉和里忠孝路二段59號",
    "電話": "(07)6831849",
    "網址": "http://www.jyp.ks.edu.tw"
  },
  {
    "代碼": "124700",
    "學校名稱": "市立龍肚國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[843]高雄市美濃區龍肚里龍東街43號",
    "電話": "(07)6851124",
    "網址": "http://www.ldp.ks.edu.tw"
  },
  {
    "代碼": "124701",
    "學校名稱": "市立中壇國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[843]高雄市美濃區中壇里忠孝路一段19號",
    "電話": "(07)6812534",
    "網址": "http://www.ztp.ks.edu.tw"
  },
  {
    "代碼": "124702",
    "學校名稱": "市立廣興國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[843]高雄市美濃區興隆里廣興街124號",
    "電話": "(07)6812857",
    "網址": "http://www.gxp.ks.edu.tw"
  },
  {
    "代碼": "124703",
    "學校名稱": "市立龍山國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[843]高雄市美濃區龍山里龍山街62號",
    "電話": "(07)6833654",
    "網址": "http://www.lsp.ks.edu.tw"
  },
  {
    "代碼": "124704",
    "學校名稱": "市立福安國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[843]高雄市美濃區中山路二段358號",
    "電話": "(07)6812514",
    "網址": "http://www.fap.ks.edu.tw"
  },
  {
    "代碼": "124705",
    "學校名稱": "市立吉東國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[843]高雄市美濃區吉東里吉頂路19號",
    "電話": "(07)6831144",
    "網址": "http://www.jdp.ks.edu.tw"
  },
  {
    "代碼": "124706",
    "學校名稱": "市立六龜國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[844]高雄市六龜區義寶里光復路40號",
    "電話": "(07)6891053",
    "網址": "http://www.lgp.ks.edu.tw"
  },
  {
    "代碼": "124707",
    "學校名稱": "市立荖濃國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[844]高雄市六龜區荖濃里南橫路72號",
    "電話": "(07)6881311",
    "網址": "http://www.lnp.ks.edu.tw"
  },
  {
    "代碼": "124708",
    "學校名稱": "市立新發國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[844]高雄市六龜區新發里和平路130號",
    "電話": "(07)6791387",
    "網址": "http://www.xfp.ks.edu.tw"
  },
  {
    "代碼": "124709",
    "學校名稱": "市立龍興國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[844]高雄市六龜區中興里中庄193號",
    "電話": "(07)6891140",
    "網址": "http://www.lxp.ks.edu.tw"
  },
  {
    "代碼": "124710",
    "學校名稱": "市立新威國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[844]高雄市六龜區新興里新威路212號",
    "電話": "(07)6871134",
    "網址": "http://www.xwp.ks.edu.tw"
  },
  {
    "代碼": "124711",
    "學校名稱": "市立寶來國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[844]高雄市六龜區寶來里中正路89號",
    "電話": "(07)6881009",
    "網址": "http://www.bla.ks.edu.tw"
  },
  {
    "代碼": "124712",
    "學校名稱": "市立月美國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[846]高雄市杉林區月眉里象寮巷39號",
    "電話": "(07)6771404",
    "網址": "http://www.ymp.ks.edu.tw"
  },
  {
    "代碼": "124713",
    "學校名稱": "市立上平國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[846]高雄市杉林區上平里山仙路212號",
    "電話": "(07)6771102",
    "網址": "http://www.spn.ks.edu.tw"
  },
  {
    "代碼": "124714",
    "學校名稱": "市立新庄國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[846]高雄市杉林區新庄里司馬路45巷5號",
    "電話": "(07)6771755",
    "網址": "http://www.szp.ks.edu.tw"
  },
  {
    "代碼": "124715",
    "學校名稱": "市立集來國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[846]高雄市杉林區集來里通仙巷333號",
    "電話": "(07)6779196",
    "網址": "http://www.jlp.ks.edu.tw"
  },
  {
    "代碼": "124716",
    "學校名稱": "市立杉林國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[846]高雄市杉林區杉林里合森巷41號",
    "電話": "(07)6772658",
    "網址": "http://www.sal.ks.edu.tw"
  },
  {
    "代碼": "124718",
    "學校名稱": "市立內門國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[845]高雄市內門區內豊里內埔24號",
    "電話": "(07)6672078",
    "網址": "http://www.nmp.ks.edu.tw"
  },
  {
    "代碼": "124719",
    "學校名稱": "市立觀亭國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[845]高雄市內門區觀亭里中正路203號",
    "電話": "(07)6671672",
    "網址": "http://www.ktp.ks.edu.tw"
  },
  {
    "代碼": "124720",
    "學校名稱": "市立溝坪國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[845]高雄市內門區溝坪里廣福巷47號",
    "電話": "(07)6601788",
    "網址": "http://www.gop.ks.edu.tw"
  },
  {
    "代碼": "124721",
    "學校名稱": "市立金竹國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[845]高雄市內門區金竹里吉民28號",
    "電話": "(07)6601106",
    "網址": "http://www.jz.ks.edu.tw"
  },
  {
    "代碼": "124722",
    "學校名稱": "市立木柵國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[845]高雄市內門區木柵里木柵3號",
    "電話": "(07)6681183",
    "網址": "http://www.mza.ks.edu.tw"
  },
  {
    "代碼": "124723",
    "學校名稱": "市立西門國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[845]高雄市內門區內東里柿子園24號",
    "電話": "(07)6672077",
    "網址": "http://www.xm.ks.edu.tw"
  },
  {
    "代碼": "124724",
    "學校名稱": "市立景義國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[845]高雄市內門區永富里萊坑8號",
    "電話": "(07)6601979",
    "網址": "http://www.jyi.ks.edu.tw"
  },
  {
    "代碼": "124725",
    "學校名稱": "市立甲仙國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[847]高雄市甲仙區西安里文化路45號",
    "電話": "(07)6751025",
    "網址": "http://163.16.192.146/index-1.htm"
  },
  {
    "代碼": "124726",
    "學校名稱": "市立小林國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[847]高雄市甲仙區小林里五里路24-2號",
    "電話": "(07)6761065",
    "網址": "http://www.xln.ks.edu.tw"
  },
  {
    "代碼": "124727",
    "學校名稱": "市立寶隆國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[847]高雄市甲仙區寶隆里光華路20號",
    "電話": "(07)6752998",
    "網址": "http://www.bln.ks.edu.tw"
  },
  {
    "代碼": "124730",
    "學校名稱": "市立巴楠花部落國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[846]高雄市杉林區大愛里和氣街15號",
    "電話": "(07)6776031",
    "網址": "http://www.mzu.ks.edu.tw"
  },
  {
    "代碼": "124731",
    "學校名稱": "市立民生國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[849]高雄市那瑪夏區達卡努瓦里大光巷159號",
    "電話": "(07)6701233",
    "網址": "http://www.msp.ks.edu.tw"
  },
  {
    "代碼": "124732",
    "學校名稱": "市立茂林國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[851]高雄市茂林區茂林里4之3號",
    "電話": "(07)6801043",
    "網址": "http://www.ml.ks.edu.tw"
  },
  {
    "代碼": "124733",
    "學校名稱": "市立多納國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[851]高雄市茂林區多納里1-2號",
    "電話": "(07)6801178",
    "網址": "http://www.dn.ks.edu.tw"
  },
  {
    "代碼": "124734",
    "學校名稱": "市立桃源國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[848]高雄市桃源區桃源里北進巷五十號",
    "電話": "(07)6861045",
    "網址": "http://www.ty.ks.edu.tw"
  },
  {
    "代碼": "124735",
    "學校名稱": "市立建山國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[848]高雄市桃源區建山里96號",
    "電話": "(07)6881134",
    "網址": "http://www.zs.ks.edu.tw"
  },
  {
    "代碼": "124736",
    "學校名稱": "市立興中國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[848]高雄市桃源區高中里興中巷44號",
    "電話": "(07)6881850",
    "網址": "http://www.szu.ks.edu.tw"
  },
  {
    "代碼": "124737",
    "學校名稱": "市立寶山國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[848]高雄市桃源區寶山里98號",
    "電話": "(07)6891728",
    "網址": "http://www.bs.ks.edu.tw"
  },
  {
    "代碼": "124738",
    "學校名稱": "市立樟山國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[848]高雄市桃源區拉芙蘭里南橫公路五段440號",
    "電話": "(07)6866118",
    "網址": "http://www.zas.ks.edu.tw"
  },
  {
    "代碼": "124739",
    "學校名稱": "市立鎮北國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區鎮北里鳳北路170號",
    "電話": "(07)7311709",
    "網址": "http://www.zbp.ks.edu.tw"
  },
  {
    "代碼": "124740",
    "學校名稱": "市立鳳西國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區光華東路100號",
    "電話": "(07)7400773",
    "網址": "http://www.fxp.ks.edu.tw/"
  },
  {
    "代碼": "124741",
    "學校名稱": "市立維新國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[828]高雄市永安區維新路光明九巷69之10號",
    "電話": "(07)6913816",
    "網址": "http://www.wxn.ks.edu.tw"
  },
  {
    "代碼": "124742",
    "學校名稱": "市立文德國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區文衡路356號",
    "電話": "(07)7768723",
    "網址": "http://163.16.31.253"
  },
  {
    "代碼": "124743",
    "學校名稱": "市立瑞興國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區博愛路271號",
    "電話": "(07)7429803",
    "網址": "http://www.rsp.ks.edu.tw/"
  },
  {
    "代碼": "124744",
    "學校名稱": "市立登發國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[814]高雄市仁武區仁和里仁忠路191號",
    "電話": "(07)3726033",
    "網址": "http://www.df.ks.edu.tw"
  },
  {
    "代碼": "124745",
    "學校名稱": "市立竹圍國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[820]高雄市岡山區竹圍里大仁北路1號",
    "電話": "(07)6233032",
    "網址": "http://www.zw.ks.edu.tw"
  },
  {
    "代碼": "124746",
    "學校名稱": "市立觀音國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[815]高雄市大社區觀音里文明路100號",
    "電話": "(07)3534710",
    "網址": "http://www.gi.ks.edu.tw"
  },
  {
    "代碼": "124747",
    "學校名稱": "市立竹後國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[814]高雄市仁武區竹後里竹楠路250號",
    "電話": "(07)3724460",
    "網址": "http://www.zh.ks.edu.tw"
  },
  {
    "代碼": "124748",
    "學校名稱": "市立正義國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區南昌街55號",
    "電話": "(07)7714440",
    "網址": "http://www.zen.ks.edu.tw"
  },
  {
    "代碼": "124749",
    "學校名稱": "市立福誠國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區忠誠路145號",
    "電話": "(07)8120152",
    "網址": "http://www.ftn.ks.edu.tw"
  },
  {
    "代碼": "124750",
    "學校名稱": "市立山頂國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[831]高雄市大寮區山頂里山頂路211號",
    "電話": "(07)7828205",
    "網址": "http://www.sd.ks.edu.tw"
  },
  {
    "代碼": "124751",
    "學校名稱": "市立過埤國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區過埤路176號",
    "電話": "(07)7920257",
    "網址": "http://www.guo.ks.edu.tw"
  },
  {
    "代碼": "124752",
    "學校名稱": "市立中崙國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區中崙四路25號",
    "電話": "(07)7533244",
    "網址": "http://www.zlu.ks.edu.tw"
  },
  {
    "代碼": "124753",
    "學校名稱": "市立橋頭國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[825]高雄市橋頭區橋頭里樹德路200號",
    "電話": "(07)6126011",
    "網址": "http://www.qtp.ks.edu.tw"
  },
  {
    "代碼": "124754",
    "學校名稱": "市立興達國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[852]高雄市茄萣區崎漏里民治路2號",
    "電話": "(07)6988099",
    "網址": "http://www.sda.ks.edu.tw"
  },
  {
    "代碼": "124755",
    "學校名稱": "市立那瑪夏區民權國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[849]高雄市那瑪夏區瑪雅里平和巷220號",
    "電話": "(07)6701129",
    "網址": "http://www.mcp.ks.edu.tw"
  },
  {
    "代碼": "124756",
    "學校名稱": "市立後庄國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[831]高雄市大寮區後庄里民揚路28號",
    "電話": "(07)7035278",
    "網址": "http://www.hzp.ks.edu.tw"
  },
  {
    "代碼": "124757",
    "學校名稱": "市立王公國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[832]高雄市林園區王公二路100號",
    "電話": "(07)6420503",
    "網址": "http://www.wgp.ks.edu.tw"
  },
  {
    "代碼": "124758",
    "學校名稱": "市立壽天國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[820]高雄市岡山區公園東路55號",
    "電話": "(07)6246040",
    "網址": "http://www.stp.ks.edu.tw"
  },
  {
    "代碼": "124760",
    "學校名稱": "市立蔡文國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[821]高雄市路竹區竹西里國昌路548號",
    "電話": "(07)6076268",
    "網址": "http://www.tsw.ks.edu.tw"
  },
  {
    "代碼": "124761",
    "學校名稱": "市立文華國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區文華里文化路52號",
    "電話": "(07)7450949",
    "網址": "http://www.whp.ks.edu.tw"
  },
  {
    "代碼": "124762",
    "學校名稱": "市立鳳翔國小",
    "公/私立": "公立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區鳳育路63號",
    "電話": "(07)7922751",
    "網址": "http://www.fly.ks.edu.tw"
  },
  {
    "代碼": "513601",
    "學校名稱": "市立鹽埕國小",
    "公/私立": "公立",
    "縣市名稱": "[51]高雄市",
    "地址": "[803]高雄市鹽埕區五福四路183號",
    "電話": "(07)5210626",
    "網址": "http://www.yacps.kh.edu.tw"
  },
  {
    "代碼": "513602",
    "學校名稱": "市立鹽埕區忠孝國小",
    "公/私立": "公立",
    "縣市名稱": "[51]高雄市",
    "地址": "[803]高雄市鹽埕區大智路71號",
    "電話": "(07)5514887",
    "網址": "http://www.chuhps.kh.edu.tw"
  },
  {
    "代碼": "513603",
    "學校名稱": "市立光榮國小",
    "公/私立": "公立",
    "縣市名稱": "[51]高雄市",
    "地址": "[803]高雄市鹽埕區大智路150號",
    "電話": "(07)5514549",
    "網址": "http://www.kjes.kh.edu.tw"
  },
  {
    "代碼": "523601",
    "學校名稱": "市立鼓山區鼓山國小",
    "公/私立": "公立",
    "縣市名稱": "[52]高雄市",
    "地址": "[804]高雄市鼓山區臨海二路50號",
    "電話": "(07)5217795",
    "網址": "http://www.kusps.kh.edu.tw"
  },
  {
    "代碼": "523602",
    "學校名稱": "市立鼓岩國小",
    "公/私立": "公立",
    "縣市名稱": "[52]高雄市",
    "地址": "[804]高雄市鼓山區河邊街155號",
    "電話": "(07)5515513",
    "網址": "http://www.kyps.kh.edu.tw"
  },
  {
    "代碼": "523603",
    "學校名稱": "市立內惟國小",
    "公/私立": "公立",
    "縣市名稱": "[52]高雄市",
    "地址": "[804]高雄市鼓山區內惟路73號",
    "電話": "(07)5515405",
    "網址": "http://www.nwps.kh.edu.tw"
  },
  {
    "代碼": "523604",
    "學校名稱": "市立鼓山區中山國小",
    "公/私立": "公立",
    "縣市名稱": "[52]高雄市",
    "地址": "[804]高雄市鼓山區美術東二路252號",
    "電話": "(07)5505850",
    "網址": "http://www.csps.kh.edu.tw"
  },
  {
    "代碼": "523605",
    "學校名稱": "市立壽山國小",
    "公/私立": "公立",
    "縣市名稱": "[52]高雄市",
    "地址": "[804]高雄市鼓山區鼓山二路37巷38弄24號",
    "電話": "(07)5514393",
    "網址": "http://www.ssps.kh.edu.tw"
  },
  {
    "代碼": "523606",
    "學校名稱": "市立龍華國小",
    "公/私立": "公立",
    "縣市名稱": "[52]高雄市",
    "地址": "[804]高雄市鼓山區大順一路858號",
    "電話": "(07)5553086",
    "網址": "http://www2.lhps.kh.edu.tw/lhps/"
  },
  {
    "代碼": "523607",
    "學校名稱": "市立九如國小",
    "公/私立": "公立",
    "縣市名稱": "[52]高雄市",
    "地址": "[804]高雄市鼓山區九如四路763號",
    "電話": "(07)5317186",
    "網址": "http://www.jrps.kh.edu.tw"
  },
  {
    "代碼": "533601",
    "學校名稱": "市立左營國小",
    "公/私立": "公立",
    "縣市名稱": "[53]高雄市",
    "地址": "[813]高雄市左營區實踐路42號",
    "電話": "(07)5820042",
    "網址": "http://www.typs.kh.edu.tw"
  },
  {
    "代碼": "533602",
    "學校名稱": "市立舊城國小",
    "公/私立": "公立",
    "縣市名稱": "[53]高雄市",
    "地址": "[813]高雄市左營區蓮潭路47號",
    "電話": "(07)5810017",
    "網址": "http://www.chiucps.kh.edu.tw"
  },
  {
    "代碼": "533603",
    "學校名稱": "市立新莊國小",
    "公/私立": "公立",
    "縣市名稱": "[53]高雄市",
    "地址": "[813]高雄市左營區新中里自由三路1號",
    "電話": "(07)3411373",
    "網址": "http://www.sjps.kh.edu.tw"
  },
  {
    "代碼": "533604",
    "學校名稱": "市立新民國小",
    "公/私立": "公立",
    "縣市名稱": "[53]高雄市",
    "地址": "[813]高雄市左營區自由三路478號",
    "電話": "(07)3411888",
    "網址": "http://www.shmps.kh.edu.tw"
  },
  {
    "代碼": "533605",
    "學校名稱": "市立明德國小",
    "公/私立": "公立",
    "縣市名稱": "[53]高雄市",
    "地址": "[813]高雄市左營區海富路2號",
    "電話": "(07)5811853",
    "網址": "http://www.mdps.kh.edu.tw"
  },
  {
    "代碼": "533606",
    "學校名稱": "市立勝利國小",
    "公/私立": "公立",
    "縣市名稱": "[53]高雄市",
    "地址": "[813]高雄市左營區南屏路1號",
    "電話": "(07)5522541",
    "網址": "http://www.slps.kh.edu.tw"
  },
  {
    "代碼": "533607",
    "學校名稱": "市立屏山國小",
    "公/私立": "公立",
    "縣市名稱": "[53]高雄市",
    "地址": "[813]高雄市左營區海功東路2號",
    "電話": "(07)5873410",
    "網址": "http://www.psps.kh.edu.tw"
  },
  {
    "代碼": "533608",
    "學校名稱": "市立永清國小",
    "公/私立": "公立",
    "縣市名稱": "[53]高雄市",
    "地址": "[813]高雄市左營區左營大路2之2號",
    "電話": "(07)5822830",
    "網址": "http://www.ycps.kh.edu.tw"
  },
  {
    "代碼": "533609",
    "學校名稱": "市立新上國小",
    "公/私立": "公立",
    "縣市名稱": "[53]高雄市",
    "地址": "[813]高雄市左營區大順一路100號",
    "電話": "(07)5565940",
    "網址": "http://www.shsps.kh.edu.tw"
  },
  {
    "代碼": "533610",
    "學校名稱": "市立福山國小",
    "公/私立": "公立",
    "縣市名稱": "[53]高雄市",
    "地址": "[813]高雄市左營區重愛路99號",
    "電話": "(07)3487633",
    "網址": "http://www.fsps.kh.edu.tw"
  },
  {
    "代碼": "533611",
    "學校名稱": "市立文府國小",
    "公/私立": "公立",
    "縣市名稱": "[53]高雄市",
    "地址": "[813]高雄市左營區文府路399號",
    "電話": "(07)3482070",
    "網址": "http://www.wfps.kh.edu.tw"
  },
  {
    "代碼": "533612",
    "學校名稱": "市立新光國小",
    "公/私立": "公立",
    "縣市名稱": "[53]高雄市",
    "地址": "[813]高雄市左營區華夏路800號",
    "電話": "(07)3438577",
    "網址": "http://www.skps.kh.edu.tw"
  },
  {
    "代碼": "543601",
    "學校名稱": "市立楠梓國小",
    "公/私立": "公立",
    "縣市名稱": "[54]高雄市",
    "地址": "[811]高雄市楠梓區楠梓路262號",
    "電話": "(07)3511140",
    "網址": "http://www.ntps.kh.edu.tw"
  },
  {
    "代碼": "543602",
    "學校名稱": "市立後勁國小",
    "公/私立": "公立",
    "縣市名稱": "[54]高雄市",
    "地址": "[811]高雄市楠梓區加昌路216號",
    "電話": "(07)3626342",
    "網址": "http://www.hjes.kh.edu.tw"
  },
  {
    "代碼": "543603",
    "學校名稱": "市立援中國小",
    "公/私立": "公立",
    "縣市名稱": "[54]高雄市",
    "地址": "[811]高雄市楠梓區中和里大學26街1150號",
    "電話": "(07)3612193",
    "網址": "http://www.yucps.kh.edu.tw"
  },
  {
    "代碼": "543604",
    "學校名稱": "市立右昌國小",
    "公/私立": "公立",
    "縣市名稱": "[54]高雄市",
    "地址": "[811]高雄市楠梓區加昌路910號",
    "電話": "(07)3612368",
    "網址": "http://www.yocps.kh.edu.tw"
  },
  {
    "代碼": "543605",
    "學校名稱": "市立莒光國小",
    "公/私立": "公立",
    "縣市名稱": "[54]高雄市",
    "地址": "[811]高雄市楠梓區後昌路842號",
    "電話": "(07)3612078",
    "網址": "http://www.chukps.kh.edu.tw"
  },
  {
    "代碼": "543606",
    "學校名稱": "市立加昌國小",
    "公/私立": "公立",
    "縣市名稱": "[54]高雄市",
    "地址": "[811]高雄市楠梓區樂群路220號",
    "電話": "(07)3627169",
    "網址": "http://www.jcps.kh.edu.tw"
  },
  {
    "代碼": "543607",
    "學校名稱": "市立楠陽國小",
    "公/私立": "公立",
    "縣市名稱": "[54]高雄市",
    "地址": "[811]高雄市楠梓區楠陽路100號",
    "電話": "(07)3520675",
    "網址": "http://www.nyps.kh.edu.tw"
  },
  {
    "代碼": "543608",
    "學校名稱": "市立翠屏國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[54]高雄市",
    "地址": "[811]高雄市楠梓區翠屏路135號",
    "電話": "(07)3683018",
    "網址": "http://www.tpjh.kh.edu.tw"
  },
  {
    "代碼": "543609",
    "學校名稱": "市立油廠國小",
    "公/私立": "公立",
    "縣市名稱": "[54]高雄市",
    "地址": "[811]高雄市楠梓區左楠路4號",
    "電話": "(07)5824141",
    "網址": "http://163.32.238.3/"
  },
  {
    "代碼": "553601",
    "學校名稱": "市立三民國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區建國三路216號",
    "電話": "(07)2810378",
    "網址": "http://www.samps.kh.edu.tw"
  },
  {
    "代碼": "553602",
    "學校名稱": "市立鼎金國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區鼎山街375號",
    "電話": "(07)3836330",
    "網址": "http://www.djps.kh.edu.tw"
  },
  {
    "代碼": "553603",
    "學校名稱": "市立愛國國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區十全一路1號",
    "電話": "(07)3161191",
    "網址": "http://www.akps.kh.edu.tw"
  },
  {
    "代碼": "553604",
    "學校名稱": "市立十全國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區十全二路162號",
    "電話": "(07)3214366",
    "網址": "http://www.scps.kh.edu.tw"
  },
  {
    "代碼": "553605",
    "學校名稱": "市立正興國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區大豊二路20號",
    "電話": "(07)3845206",
    "網址": "http://www.jsps.kh.edu.tw"
  },
  {
    "代碼": "553606",
    "學校名稱": "市立博愛國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區十全一路202號",
    "電話": "(07)3110708",
    "網址": "http://www.paps.kh.edu.tw"
  },
  {
    "代碼": "553607",
    "學校名稱": "市立獅湖國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區鼎金後路495號",
    "電話": "(07)3424782",
    "網址": "http://www.shps.kh.edu.tw"
  },
  {
    "代碼": "553608",
    "學校名稱": "市立三民區民族國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區平等路197號",
    "電話": "(07)3860526",
    "網址": "http://www.mtps.kh.edu.tw"
  },
  {
    "代碼": "553609",
    "學校名稱": "市立莊敬國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區大昌一路200號",
    "電話": "(07)3813210",
    "網址": "http://www.jjps.kh.edu.tw"
  },
  {
    "代碼": "553610",
    "學校名稱": "市立光武國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區光武路35號",
    "電話": "(07)3867458",
    "網址": "http://www.kwps.kh.edu.tw"
  },
  {
    "代碼": "553611",
    "學校名稱": "市立東光國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區黃興路206號",
    "電話": "(07)3898338",
    "網址": "http://www.dgps.kh.edu.tw"
  },
  {
    "代碼": "553612",
    "學校名稱": "市立河濱國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區市中一路339號",
    "電話": "(07)2211408",
    "網址": "http://www.xhpps.kh.edu.tw"
  },
  {
    "代碼": "553613",
    "學校名稱": "市立陽明國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區義德路52號",
    "電話": "(07)3851915",
    "網址": "http://www.ymps.kh.edu.tw"
  },
  {
    "代碼": "553614",
    "學校名稱": "市立河堤國小",
    "公/私立": "公立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區裕誠路3號",
    "電話": "(07)3450680",
    "網址": "http://www.htps.kh.edu.tw"
  },
  {
    "代碼": "563601",
    "學校名稱": "市立新興區新興國小",
    "公/私立": "公立",
    "縣市名稱": "[56]高雄市",
    "地址": "[800]高雄市新興區民生一路321號",
    "電話": "(07)2412119",
    "網址": "http://www.hhps.kh.edu.tw"
  },
  {
    "代碼": "563602",
    "學校名稱": "市立大同國小",
    "公/私立": "公立",
    "縣市名稱": "[56]高雄市",
    "地址": "[800]高雄市新興區大同一路231號",
    "電話": "(07)2823039",
    "網址": "http://www.ttps.kh.edu.tw"
  },
  {
    "代碼": "563603",
    "學校名稱": "市立信義國小",
    "公/私立": "公立",
    "縣市名稱": "[56]高雄市",
    "地址": "[800]高雄市新興區中正三路32號",
    "電話": "(07)2365163",
    "網址": "http://www.syps.kh.edu.tw"
  },
  {
    "代碼": "563604",
    "學校名稱": "市立七賢國小",
    "公/私立": "公立",
    "縣市名稱": "[56]高雄市",
    "地址": "[800]高雄市新興區七賢一路393號",
    "電話": "(07)2351150",
    "網址": "http://www.chihps.kh.edu.tw"
  },
  {
    "代碼": "573601",
    "學校名稱": "市立前金國小",
    "公/私立": "公立",
    "縣市名稱": "[57]高雄市",
    "地址": "[801]高雄市前金區大同二路61號",
    "電話": "(07)2829001",
    "網址": "http://www.chejps.kh.edu.tw"
  },
  {
    "代碼": "573602",
    "學校名稱": "市立建國國小",
    "公/私立": "公立",
    "縣市名稱": "[57]高雄市",
    "地址": "[801]高雄市前金區自立二路111號",
    "電話": "(07)2860128",
    "網址": "http://www.chkops.kh.edu.tw"
  },
  {
    "代碼": "583601",
    "學校名稱": "市立苓洲國小",
    "公/私立": "公立",
    "縣市名稱": "[58]高雄市",
    "地址": "[802]高雄市苓雅區四維四路61號",
    "電話": "(07)3351804",
    "網址": "http://www.ljps.kh.edu.tw"
  },
  {
    "代碼": "583602",
    "學校名稱": "市立苓雅區成功國小",
    "公/私立": "公立",
    "縣市名稱": "[58]高雄市",
    "地址": "[802]高雄市苓雅區華新街59號",
    "電話": "(07)3341882",
    "網址": "http://www.ckps.kh.edu.tw"
  },
  {
    "代碼": "583603",
    "學校名稱": "市立五權國小",
    "公/私立": "公立",
    "縣市名稱": "[58]高雄市",
    "地址": "[802]高雄市苓雅區三多二路100號",
    "電話": "(07)7511074",
    "網址": "http://www.wcps.kh.edu.tw"
  },
  {
    "代碼": "583604",
    "學校名稱": "市立凱旋國小",
    "公/私立": "公立",
    "縣市名稱": "[58]高雄市",
    "地址": "[802]高雄市苓雅區憲政路235號",
    "電話": "(07)2235181",
    "網址": "http://www.ksps.kh.edu.tw"
  },
  {
    "代碼": "583605",
    "學校名稱": "市立四維國小",
    "公/私立": "公立",
    "縣市名稱": "[58]高雄市",
    "地址": "[802]高雄市苓雅區青年一路103號",
    "電話": "(07)3348199",
    "網址": "http://www.swps.kh.edu.tw"
  },
  {
    "代碼": "583606",
    "學校名稱": "市立福東國小",
    "公/私立": "公立",
    "縣市名稱": "[58]高雄市",
    "地址": "[802]高雄市苓雅區福德三路96號",
    "電話": "(07)7510048",
    "網址": "http://www.ftps.kh.edu.tw"
  },
  {
    "代碼": "583607",
    "學校名稱": "市立苓雅區中正國小",
    "公/私立": "公立",
    "縣市名稱": "[58]高雄市",
    "地址": "[802]高雄市苓雅區輔仁路100號",
    "電話": "(07)7225936",
    "網址": "http://www.ccps.kh.edu.tw"
  },
  {
    "代碼": "583608",
    "學校名稱": "市立福康國小",
    "公/私立": "公立",
    "縣市名稱": "[58]高雄市",
    "地址": "[802]高雄市苓雅區漢陽街17號",
    "電話": "(07)7227134",
    "網址": "http://www.fkps.kh.edu.tw"
  },
  {
    "代碼": "593601",
    "學校名稱": "市立前鎮國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區新生路200號",
    "電話": "(07)8212448",
    "網址": "http://www.qzps.kh.edu.tw"
  },
  {
    "代碼": "593602",
    "學校名稱": "市立獅甲國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區中山三路45號",
    "電話": "(07)3311465",
    "網址": "http://www.sjiaps.kh.edu.tw"
  },
  {
    "代碼": "593603",
    "學校名稱": "市立仁愛國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區新衙路93號",
    "電話": "(07)8414911",
    "網址": "http://www.jips.kh.edu.tw"
  },
  {
    "代碼": "593604",
    "學校名稱": "市立樂群國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區育樂路61號",
    "電話": "(07)7225846",
    "網址": "http://www.lcps.kh.edu.tw"
  },
  {
    "代碼": "593605",
    "學校名稱": "市立愛群國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區二聖二路194號",
    "電話": "(07)3332701",
    "網址": "http://www.acps.kh.edu.tw"
  },
  {
    "代碼": "593606",
    "學校名稱": "市立復興國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區民權二路331號",
    "電話": "(07)3351784",
    "網址": "http://www.fhps.kh.edu.tw"
  },
  {
    "代碼": "593607",
    "學校名稱": "市立瑞豐國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區瑞隆路100號",
    "電話": "(07)7110846",
    "網址": "http://www.rfps.kh.edu.tw"
  },
  {
    "代碼": "593608",
    "學校名稱": "市立明正國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區明道路2號",
    "電話": "(07)8110965",
    "網址": "http://www.mcps.kh.edu.tw"
  },
  {
    "代碼": "593609",
    "學校名稱": "市立光華國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區廣西路57號",
    "電話": "(07)7222846",
    "網址": "http://www.khps.kh.edu.tw"
  },
  {
    "代碼": "593610",
    "學校名稱": "市立瑞祥國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區班超路20號",
    "電話": "(07)7221212",
    "網址": "http://www.rsps.kh.edu.tw"
  },
  {
    "代碼": "593611",
    "學校名稱": "市立鎮昌國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區樹人路261號",
    "電話": "(07)8116575",
    "網址": "http://www.jcmps.kh.edu.tw"
  },
  {
    "代碼": "593612",
    "學校名稱": "市立佛公國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區后平路135號",
    "電話": "(07)8229801",
    "網址": "http://www.fokps.kh.edu.tw"
  },
  {
    "代碼": "593613",
    "學校名稱": "市立前鎮區民權國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區沱江街200號",
    "電話": "(07)5367173",
    "網址": "http://www.mchps.kh.edu.tw"
  },
  {
    "代碼": "593614",
    "學校名稱": "市立紅毛港國小",
    "公/私立": "公立",
    "縣市名稱": "[59]高雄市",
    "地址": "[806]高雄市前鎮區明鳳7街1號",
    "電話": "(07)7917608",
    "網址": "http://www.hsps.kh.edu.tw"
  },
  {
    "代碼": "603601",
    "學校名稱": "市立旗津國小",
    "公/私立": "公立",
    "縣市名稱": "[60]高雄市",
    "地址": "[805]高雄市旗津區中洲三路623號",
    "電話": "(07)5715133",
    "網址": "http://www.chijps.kh.edu.tw"
  },
  {
    "代碼": "603602",
    "學校名稱": "市立大汕國小",
    "公/私立": "公立",
    "縣市名稱": "[60]高雄市",
    "地址": "[805]高雄市旗津區中洲二路203號",
    "電話": "(07)5712434",
    "網址": "http://www.dsps.kh.edu.tw"
  },
  {
    "代碼": "603603",
    "學校名稱": "市立中洲國小",
    "公/私立": "公立",
    "縣市名稱": "[60]高雄市",
    "地址": "[805]高雄市旗津區旗津二路275號",
    "電話": "(07)5712225",
    "網址": "http://www.chucps.kh.edu.tw"
  },
  {
    "代碼": "613601",
    "學校名稱": "市立小港國小",
    "公/私立": "公立",
    "縣市名稱": "[61]高雄市",
    "地址": "[812]高雄市小港區平和路1號",
    "電話": "(07)8215923",
    "網址": "http://www.hkps.kh.edu.tw"
  },
  {
    "代碼": "613602",
    "學校名稱": "市立鳳林國小",
    "公/私立": "公立",
    "縣市名稱": "[61]高雄市",
    "地址": "[812]高雄市小港區鳳源里鳳林路207號",
    "電話": "(07)8712367",
    "網址": "http://www.flps.kh.edu.tw"
  },
  {
    "代碼": "613604",
    "學校名稱": "市立青山國小",
    "公/私立": "公立",
    "縣市名稱": "[61]高雄市",
    "地址": "[812]高雄市小港區濟南里飛機路153號",
    "電話": "(07)8024872",
    "網址": "http://www.chsnps.kh.edu.tw"
  },
  {
    "代碼": "613605",
    "學校名稱": "市立太平國小",
    "公/私立": "公立",
    "縣市名稱": "[61]高雄市",
    "地址": "[812]高雄市小港區營口路一號",
    "電話": "(07)8019006",
    "網址": "http://www.tpps.kh.edu.tw"
  },
  {
    "代碼": "613606",
    "學校名稱": "市立鳳鳴國小",
    "公/私立": "公立",
    "縣市名稱": "[61]高雄市",
    "地址": "[812]高雄市小港區鳳鳴路191號",
    "電話": "(07)8714561",
    "網址": "http://www.fmps.kh.edu.tw"
  },
  {
    "代碼": "613607",
    "學校名稱": "市立坪頂國小",
    "公/私立": "公立",
    "縣市名稱": "[61]高雄市",
    "地址": "[812]高雄市小港區坪頂里大平路2號",
    "電話": "(07)8911410",
    "網址": "http://www.pdes.kh.edu.tw"
  },
  {
    "代碼": "613608",
    "學校名稱": "市立二苓國小",
    "公/私立": "公立",
    "縣市名稱": "[61]高雄市",
    "地址": "[812]高雄市小港區立群路6號",
    "電話": "(07)8016837",
    "網址": "http://www.zlps.kh.edu.tw"
  },
  {
    "代碼": "613609",
    "學校名稱": "市立桂林國小",
    "公/私立": "公立",
    "縣市名稱": "[61]高雄市",
    "地址": "[812]高雄市小港區桂林里桂陽路390號",
    "電話": "(07)7910766",
    "網址": "http://www.klps.kh.edu.tw"
  },
  {
    "代碼": "613610",
    "學校名稱": "市立漢民國小",
    "公/私立": "公立",
    "縣市名稱": "[61]高雄市",
    "地址": "[812]高雄市小港區漢民路500號",
    "電話": "(07)8036569",
    "網址": "http://www.hmes.kh.edu.tw"
  },
  {
    "代碼": "613611",
    "學校名稱": "市立華山國小",
    "公/私立": "公立",
    "縣市名稱": "[61]高雄市",
    "地址": "[812]高雄市小港區華仁街1號",
    "電話": "(07)8062280",
    "網址": "http://www.hshanps.kh.edu.tw"
  },
  {
    "代碼": "613612",
    "學校名稱": "市立港和國小",
    "公/私立": "公立",
    "縣市名稱": "[61]高雄市",
    "地址": "[812]高雄市小港區平和南路300號",
    "電話": "(07)8131506",
    "網址": "http://www.khops.kh.edu.tw"
  },
  {
    "代碼": "613613",
    "學校名稱": "市立鳳陽國小",
    "公/私立": "公立",
    "縣市名稱": "[61]高雄市",
    "地址": "[812]高雄市小港區鳳陽街2號",
    "電話": "(07)8010865",
    "網址": "http://www.fyps.kh.edu.tw"
  },
  {
    "代碼": "613614",
    "學校名稱": "市立明義國小",
    "公/私立": "公立",
    "縣市名稱": "[61]高雄市",
    "地址": "[812]高雄市小港區明義街77號",
    "電話": "(07)7917035",
    "網址": "http://www.myes.kh.edu.tw"
  },
  {
    "代碼": "024601",
    "學校名稱": "縣立中山國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[260]宜蘭縣宜蘭市崇聖街4號",
    "電話": "(03)9322064",
    "網址": "http://www.jses.ilc.edu.tw"
  },
  {
    "代碼": "024602",
    "學校名稱": "縣立宜蘭國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[260]宜蘭縣宜蘭市崇聖街2號",
    "電話": "(03)9322210",
    "網址": "http://www.iles.ilc.edu.tw"
  },
  {
    "代碼": "024603",
    "學校名稱": "縣立力行國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[260]宜蘭縣宜蘭市力行路152號",
    "電話": "(03)9322309",
    "網址": "http://www.lses.ilc.edu.tw"
  },
  {
    "代碼": "024604",
    "學校名稱": "縣立新生國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[260]宜蘭縣宜蘭市大坡路一段100號",
    "電話": "(03)9283791",
    "網址": "http://www.sses.ilc.edu.tw"
  },
  {
    "代碼": "024605",
    "學校名稱": "縣立光復國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[260]宜蘭縣宜蘭市泰山路60號",
    "電話": "(03)9322077",
    "網址": "http://www.kfps.ilc.edu.tw"
  },
  {
    "代碼": "024606",
    "學校名稱": "縣立育才國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[260]宜蘭縣宜蘭市進士路8號",
    "電話": "(03)9253794",
    "網址": "http://www.ytps.ilc.edu.tw"
  },
  {
    "代碼": "024607",
    "學校名稱": "縣立凱旋國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[260]宜蘭縣宜蘭市凱旋路130號",
    "電話": "(03)9253793",
    "網址": "http://www.kases.ilc.edu.tw"
  },
  {
    "代碼": "024608",
    "學校名稱": "縣立黎明國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[260]宜蘭縣宜蘭市校舍路1號",
    "電話": "(03)9383792",
    "網址": "http://www.lmes.ilc.edu.tw"
  },
  {
    "代碼": "024609",
    "學校名稱": "縣立南屏國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[260]宜蘭縣宜蘭市泰山路98號",
    "電話": "(03)9323795",
    "網址": "http://www.npes.ilc.edu.tw"
  },
  {
    "代碼": "024610",
    "學校名稱": "縣立羅東國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[265]宜蘭縣羅東鎮民族路1號",
    "電話": "(03)9542342",
    "網址": "http://www.lotes.ilc.edu.tw"
  },
  {
    "代碼": "024611",
    "學校名稱": "縣立成功國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[265]宜蘭縣羅東鎮興東南路100號",
    "電話": "(03)9542156",
    "網址": "http://www.cges.ilc.edu.tw"
  },
  {
    "代碼": "024612",
    "學校名稱": "縣立公正國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[265]宜蘭縣羅東鎮公正路199號",
    "電話": "(03)9566659",
    "網址": "http://www.gjes.ilc.edu.tw"
  },
  {
    "代碼": "024613",
    "學校名稱": "縣立北成國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[265]宜蘭縣羅東鎮北成路一段125號",
    "電話": "(03)9512626",
    "網址": "http://www.pces.ilc.edu.tw"
  },
  {
    "代碼": "024614",
    "學校名稱": "縣立竹林國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[265]宜蘭縣羅東鎮復興路二段128號",
    "電話": "(03)9542084",
    "網址": "http://www.jles.ilc.edu.tw"
  },
  {
    "代碼": "024615",
    "學校名稱": "縣立蘇澳國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[270]宜蘭縣蘇澳鎮中山路一段366號",
    "電話": "(03)9962312",
    "網址": "http://www.saes.ilc.edu.tw"
  },
  {
    "代碼": "024616",
    "學校名稱": "縣立馬賽國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[270]宜蘭縣蘇澳鎮永榮里大同路108號",
    "電話": "(03)9902694",
    "網址": "http://www.mses.ilc.edu.tw"
  },
  {
    "代碼": "024617",
    "學校名稱": "縣立蓬萊國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[270]宜蘭縣蘇澳鎮南澳路85號",
    "電話": "(03)9981062",
    "網址": "http://www.ples.ilc.edu.tw"
  },
  {
    "代碼": "024618",
    "學校名稱": "縣立士敏國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[270]宜蘭縣蘇澳鎮光明路8號",
    "電話": "(03)9962342",
    "網址": "http://www.smes.ilc.edu.tw"
  },
  {
    "代碼": "024619",
    "學校名稱": "縣立永樂國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[270]宜蘭縣蘇澳鎮永樂路1號",
    "電話": "(03)9962840",
    "網址": "http://www.yles.ilc.edu.tw"
  },
  {
    "代碼": "024620",
    "學校名稱": "縣立南安國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[270]宜蘭縣蘇澳鎮南建里學府路122號",
    "電話": "(03)9962664",
    "網址": "http://blog.ilc.edu.tw/blog/blog/29985  "
  },
  {
    "代碼": "024621",
    "學校名稱": "縣立岳明國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[270]宜蘭縣蘇澳鎮嶺腳路140號",
    "電話": "(03)9903044",
    "網址": "http://www.ymes.ilc.edu.tw"
  },
  {
    "代碼": "024622",
    "學校名稱": "縣立育英國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[270]宜蘭縣蘇澳鎮褔德路134號",
    "電話": "(03)9901294",
    "網址": "http://www.yies.ilc.edu.tw"
  },
  {
    "代碼": "024623",
    "學校名稱": "縣立頭城國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[261]宜蘭縣頭城鎮開蘭路282號",
    "電話": "(03)9771011",
    "網址": "http://www.tces.ilc.edu.tw"
  },
  {
    "代碼": "024624",
    "學校名稱": "縣立竹安國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[261]宜蘭縣頭城鎮竹安里頭濱路一段601號",
    "電話": "(03)9771178",
    "網址": "http://www.jaes.ilc.edu.tw"
  },
  {
    "代碼": "024625",
    "學校名稱": "縣立二城國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[261]宜蘭縣頭城鎮青雲路二段200號",
    "電話": "(03)9771024",
    "網址": "http://www.eces.ilc.edu.tw"
  },
  {
    "代碼": "024626",
    "學校名稱": "縣立大溪國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[261]宜蘭縣頭城鎮濱海路4段250號",
    "電話": "(03)9781044",
    "網址": "http://www.dses.ilc.edu.tw"
  },
  {
    "代碼": "024627",
    "學校名稱": "縣立大里國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[261]宜蘭縣頭城鎮濱海路七段65號",
    "電話": "(03)9781440",
    "網址": "http://www.tles.ilc.edu.tw"
  },
  {
    "代碼": "024628",
    "學校名稱": "縣立梗枋國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[261]宜蘭縣頭城鎮更新里59號",
    "電話": "(03)9771634",
    "網址": "http://www.gfes.ilc.edu.tw"
  },
  {
    "代碼": "024629",
    "學校名稱": "縣立礁溪國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[262]宜蘭縣礁溪鄉大忠村礁溪路四段135號",
    "電話": "(03)9882047",
    "網址": "http://www.jausips.ilc.edu.tw"
  },
  {
    "代碼": "024630",
    "學校名稱": "縣立四結國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[262]宜蘭縣礁溪鄉吳沙村育英路46號",
    "電話": "(03)9284751",
    "網址": "http://www.sjes.ilc.edu.tw"
  },
  {
    "代碼": "024631",
    "學校名稱": "縣立龍潭國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[262]宜蘭縣礁溪鄉龍潭村育龍路71號",
    "電話": "(03)9284764",
    "網址": "http://www.ltes.ilc.edu.tw"
  },
  {
    "代碼": "024632",
    "學校名稱": "縣立玉田國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[262]宜蘭縣礁溪鄉茅埔路24號",
    "電話": "(03)9882461",
    "網址": "http://www.ytes.ilc.edu.tw"
  },
  {
    "代碼": "024633",
    "學校名稱": "縣立三民國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[262]宜蘭縣礁溪鄉三民村十六結路100號",
    "電話": "(03)9882271",
    "網址": "http://www.smps.ilc.edu.tw"
  },
  {
    "代碼": "024634",
    "學校名稱": "縣立員山國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[264]宜蘭縣員山鄉員山村復興路39號",
    "電話": "(03)9224061",
    "網址": "http://www.yeses.ilc.edu.tw"
  },
  {
    "代碼": "024635",
    "學校名稱": "縣立深溝國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[264]宜蘭縣員山鄉深溝村惠民路266號",
    "電話": "(03)9224870",
    "網址": "http://www.sges.ilc.edu.tw"
  },
  {
    "代碼": "024636",
    "學校名稱": "縣立七賢國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[264]宜蘭縣員山鄉浮洲路30號",
    "電話": "(03)9221688",
    "網址": "http://www.csps.ilc.edu.tw"
  },
  {
    "代碼": "024637",
    "學校名稱": "縣立同樂國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[264]宜蘭縣員山鄉同樂村新城路84之4號",
    "電話": "(03)9223543",
    "網址": "http://www.tlps.ilc.edu.tw"
  },
  {
    "代碼": "024638",
    "學校名稱": "縣立湖山國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[264]宜蘭縣員山鄉枕山村坡城路54號",
    "電話": "(03)9221174",
    "網址": "http://www.hses.ilc.edu.tw"
  },
  {
    "代碼": "024639",
    "學校名稱": "縣立大湖國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[264]宜蘭縣員山鄉湖東村蜊埤路55號",
    "電話": "(03)9223584",
    "網址": "http://www.dhes.ilc.edu.tw"
  },
  {
    "代碼": "024640",
    "學校名稱": "縣立內城國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[264]宜蘭縣員山鄉內城村內城路545號",
    "電話": "(03)9221846",
    "網址": "http://www.nces.ilc.edu.tw"
  },
  {
    "代碼": "024643",
    "學校名稱": "縣立壯圍國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[263]宜蘭縣壯圍鄉吉祥村壯五路55號",
    "電話": "(03)9384442",
    "網址": "http://www.jwes.ilc.edu.tw"
  },
  {
    "代碼": "024644",
    "學校名稱": "縣立古亭國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[263]宜蘭縣壯圍鄉古亭村古亭路81號",
    "電話": "(03)9301022",
    "網址": "http://www.gtes.ilc.edu.tw"
  },
  {
    "代碼": "024645",
    "學校名稱": "縣立公館國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[263]宜蘭縣壯圍鄉紅葉村69號",
    "電話": "(03)9380522",
    "網址": "http://www.kkes.ilc.edu.tw"
  },
  {
    "代碼": "024646",
    "學校名稱": "縣立過嶺國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[263]宜蘭縣壯圍鄉過嶺村壯濱路三段261號",
    "電話": "(03)9301404",
    "網址": "http://www.qles.ilc.edu.tw"
  },
  {
    "代碼": "024647",
    "學校名稱": "縣立大福國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[263]宜蘭縣壯圍鄉大福村大福路一段62巷26號",
    "電話": "(03)9301239",
    "網址": "http://www.dfes.ilc.edu.tw"
  },
  {
    "代碼": "024648",
    "學校名稱": "縣立新南國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[263]宜蘭縣壯圍鄉新南村120之1號",
    "電話": "(03)9253337",
    "網址": "http://www.snes.ilc.edu.tw"
  },
  {
    "代碼": "024649",
    "學校名稱": "縣立五結國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[268]宜蘭縣五結鄉五結中路二段88號",
    "電話": "(03)9507272",
    "網址": "http://www.wjes.ilc.edu.tw"
  },
  {
    "代碼": "024650",
    "學校名稱": "縣立學進國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[268]宜蘭縣五結鄉中正路三段151號",
    "電話": "(03)9653801",
    "網址": "http://www.shjes.ilc.edu.tw"
  },
  {
    "代碼": "024651",
    "學校名稱": "縣立中興國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[268]宜蘭縣五結鄉四結村中興路三段67號",
    "電話": "(03)9544356",
    "網址": "http://www.cses.ilc.edu.tw"
  },
  {
    "代碼": "024652",
    "學校名稱": "縣立利澤國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[268]宜蘭縣五結鄉成興村利寶路101號",
    "電話": "(03)9503805",
    "網址": "http://www.ltps.ilc.edu.tw"
  },
  {
    "代碼": "024653",
    "學校名稱": "縣立孝威國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[268]宜蘭縣五結鄉孝威路328號",
    "電話": "(03)9503804",
    "網址": "http://www.swes.ilc.edu.tw"
  },
  {
    "代碼": "024654",
    "學校名稱": "縣立冬山國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[269]宜蘭縣冬山鄉安平村安中路35號",
    "電話": "(03)9593504",
    "網址": "http://www.tses.ilc.edu.tw"
  },
  {
    "代碼": "024655",
    "學校名稱": "縣立東興國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[269]宜蘭縣冬山鄉東城村東城路316號",
    "電話": "(03)9594047",
    "網址": "http://www.tshes.ilc.edu.tw"
  },
  {
    "代碼": "024656",
    "學校名稱": "縣立順安國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[269]宜蘭縣冬山鄉順安村永興路二段17號",
    "電話": "(03)9583445",
    "網址": "http://www.saps.ilc.edu.tw"
  },
  {
    "代碼": "024657",
    "學校名稱": "縣立武淵國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[269]宜蘭縣冬山鄉武淵村富農路二段350號",
    "電話": "(03)9505971",
    "網址": "http://www.waes.ilc.edu.tw"
  },
  {
    "代碼": "024658",
    "學校名稱": "縣立廣興國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[269]宜蘭縣冬山鄉廣興村梅花路303號",
    "電話": "(03)9513484",
    "網址": "http://www.kses.ilc.edu.tw"
  },
  {
    "代碼": "024659",
    "學校名稱": "縣立大進國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[269]宜蘭縣冬山鄉大進村大進七路51號",
    "電話": "(03)9512268",
    "網址": "http://www.djps.ilc.edu.tw"
  },
  {
    "代碼": "024660",
    "學校名稱": "縣立柯林國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[269]宜蘭縣冬山鄉柯林村光華路158號",
    "電話": "(03)9511355",
    "網址": "http://www.kles.ilc.edu.tw"
  },
  {
    "代碼": "024661",
    "學校名稱": "縣立三星國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[266]宜蘭縣三星鄉三星路五段86號",
    "電話": "(03)9892026",
    "網址": "http://www.ssps.ilc.edu.tw"
  },
  {
    "代碼": "024662",
    "學校名稱": "縣立大洲國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[266]宜蘭縣三星鄉大洲村上將路二段500號",
    "電話": "(03)9551547",
    "網址": "http://www.djes.ilc.edu.tw"
  },
  {
    "代碼": "024663",
    "學校名稱": "縣立憲明國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[266]宜蘭縣三星鄉天山村福山街119號",
    "電話": "(03)9892049",
    "網址": "http://www.shmes.ilc.edu.tw"
  },
  {
    "代碼": "024664",
    "學校名稱": "縣立萬富國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[266]宜蘭縣三星鄉萬富村健富路一段309號",
    "電話": "(03)9890564",
    "網址": "http://www.wfes.ilc.edu.tw"
  },
  {
    "代碼": "024665",
    "學校名稱": "縣立大隱國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[266]宜蘭縣三星鄉大隱中路347號",
    "電話": "(03)9890155",
    "網址": "http://www.dyes.ilc.edu.tw"
  },
  {
    "代碼": "024668",
    "學校名稱": "縣立四季國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[267]宜蘭縣大同鄉四季村和勳巷1號",
    "電話": "(03)9809312",
    "網址": "http://www.sches.ilc.edu.tw"
  },
  {
    "代碼": "024669",
    "學校名稱": "縣立南山國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[267]宜蘭縣大同鄉南山村四鄰埤南巷31之3號",
    "電話": "(03)9809113",
    "網址": "http://www.nses.ilc.edu.tw"
  },
  {
    "代碼": "024670",
    "學校名稱": "縣立大同國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[267]宜蘭縣大同鄉崙埤村10之9號",
    "電話": "(03)9801172",
    "網址": "http://www.dtes.ilc.edu.tw"
  },
  {
    "代碼": "024671",
    "學校名稱": "縣立寒溪國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[267]宜蘭縣大同鄉寒溪村寒溪巷16號",
    "電話": "(03)9517441",
    "網址": "http://www.hsies.ilc.edu.tw"
  },
  {
    "代碼": "024672",
    "學校名稱": "縣立南澳國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[272]宜蘭縣南澳鄉南澳村中正路15號",
    "電話": "(03)9981228",
    "網址": "http://www.naes.ilc.edu.tw"
  },
  {
    "代碼": "024673",
    "學校名稱": "縣立碧候國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[272]宜蘭縣南澳鄉碧候村自覺巷38號",
    "電話": "(03)9981634",
    "網址": "http://www.bhoes.ilc.edu.tw"
  },
  {
    "代碼": "024674",
    "學校名稱": "縣立武塔國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[272]宜蘭縣南澳鄉武塔村新溪路1號",
    "電話": "(03)9981632",
    "網址": "http://www.utaes.ilc.edu.tw"
  },
  {
    "代碼": "024675",
    "學校名稱": "縣立澳花國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[272]宜蘭縣南澳鄉澳花村中央路19號",
    "電話": "(03)9985141",
    "網址": "http://blog.ilc.edu.tw/blog/blog/28531"
  },
  {
    "代碼": "024676",
    "學校名稱": "縣立東澳國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[272]宜蘭縣南澳鄉東岳村蘇花路三段209號",
    "電話": "(03)9986217",
    "網址": "http://www.daes.ilc.edu.tw"
  },
  {
    "代碼": "024677",
    "學校名稱": "縣立金岳國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[272]宜蘭縣南澳鄉金岳村 (路) 二號",
    "電話": "(03)9981636",
    "網址": "http://blog.ilc.edu.tw/blog/blog/29956"
  },
  {
    "代碼": "024678",
    "學校名稱": "縣立金洋國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[272]宜蘭縣南澳鄉金洋村11號",
    "電話": "(03)9981486",
    "網址": "http://www.kyes.ilc.edu.tw"
  },
  {
    "代碼": "024698",
    "學校名稱": "縣立人文國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[261]宜蘭縣頭城鎮拔雅里文雅路150號",
    "電話": "(03)9773396",
    "網址": "http://www.jwps.ilc.edu.tw"
  },
  {
    "代碼": "024701",
    "學校名稱": "縣立清溝國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[269]宜蘭縣冬山鄉清溝村永清路369號",
    "電話": "(03)9586567",
    "網址": "http://blog.ilc.edu.tw/blog/blog/26395"
  },
  {
    "代碼": "041601",
    "學校名稱": "私立上智國小",
    "公/私立": "私立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[310]新竹縣竹東鎮東寧路三段205號",
    "電話": "(03)5962082",
    "網址": "http://www.scps2.hcc.edu.tw"
  },
  {
    "代碼": "041602",
    "學校名稱": "私立康乃薾國(中)小",
    "公/私立": "私立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市六家一路二段115號",
    "電話": "(03)6589188",
    "網址": "http://w1.korrnell.com.tw"
  },
  {
    "代碼": "044601",
    "學校名稱": "縣立關西國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[306]新竹縣關西鎮西安里中山路126號",
    "電話": "(03)5872028",
    "網址": "http://www.gses.hcc.edu.tw"
  },
  {
    "代碼": "044602",
    "學校名稱": "縣立東安國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[306]新竹縣關西鎮東安里中山東路40號",
    "電話": "(03)5872177",
    "網址": "http://www.taes.hcc.edu.tw"
  },
  {
    "代碼": "044603",
    "學校名稱": "縣立石光國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[306]新竹縣關西鎮石光里石岡子386號",
    "電話": "(03)5869311",
    "網址": "http://www.skes.hcc.edu.tw"
  },
  {
    "代碼": "044604",
    "學校名稱": "縣立坪林國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[306]新竹縣關西鎮上林里坪林11號",
    "電話": "(03)5869310",
    "網址": "http://www.ples.hcc.edu.tw"
  },
  {
    "代碼": "044605",
    "學校名稱": "縣立南和國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[306]新竹縣關西鎮南和里69號",
    "電話": "(03)5868952",
    "網址": "http://www.nhes.hcc.edu.tw"
  },
  {
    "代碼": "044606",
    "學校名稱": "縣立太平國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[306]新竹縣關西鎮東平里五鄰27號",
    "電話": "(03)5882634",
    "網址": "http://www.tpes.hcc.edu.tw"
  },
  {
    "代碼": "044607",
    "學校名稱": "縣立東光國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[306]新竹縣關西鎮東光里190號",
    "電話": "(03)5872243",
    "網址": "http://www.tkes.hcc.edu.tw"
  },
  {
    "代碼": "044608",
    "學校名稱": "縣立錦山國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[306]新竹縣關西鎮金山里107號",
    "電話": "(03)5478681",
    "網址": "http://www.jses.hcc.edu.tw"
  },
  {
    "代碼": "044609",
    "學校名稱": "縣立玉山國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[306]新竹縣關西鎮玉山里四鄰25號",
    "電話": "(03)5476349",
    "網址": "http://www.yses.hcc.edu.tw"
  },
  {
    "代碼": "044610",
    "學校名稱": "縣立新埔國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[305]新竹縣新埔鎮中正路366號",
    "電話": "(03)5882124",
    "網址": "http://www.hpes.hcc.edu.tw"
  },
  {
    "代碼": "044611",
    "學校名稱": "縣立新星國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[305]新竹縣新埔鎮新民里民生街28號",
    "電話": "(03)5882078",
    "網址": "http://www.hhes.hcc.edu.tw"
  },
  {
    "代碼": "044612",
    "學校名稱": "縣立照門國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[305]新竹縣新埔鎮照照門里九鄰39號",
    "電話": "(03)5899234",
    "網址": "http://www.jmes.hcc.edu.tw"
  },
  {
    "代碼": "044613",
    "學校名稱": "縣立清水國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[305]新竹縣新埔鎮清水里143號",
    "電話": "(03)5899235",
    "網址": "http://www.cies.hcc.edu.tw"
  },
  {
    "代碼": "044614",
    "學校名稱": "縣立照東國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[305]新竹縣新埔鎮鹿鳴里9鄰36號",
    "電話": "(03)5899807",
    "網址": "http://www.ctes.hcc.edu.tw"
  },
  {
    "代碼": "044615",
    "學校名稱": "縣立北平華德福實驗學校(國小)",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[305]新竹縣新埔鎮北平里62號",
    "電話": "(03)5882897",
    "網址": "http://www.ppes.hcc.edu.tw"
  },
  {
    "代碼": "044616",
    "學校名稱": "縣立枋寮國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[305]新竹縣新埔鎮上寮里義民路二段350號",
    "電話": "(03)5882081",
    "網址": "http://www.fles.hcc.edu.tw"
  },
  {
    "代碼": "044617",
    "學校名稱": "縣立寶石國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[305]新竹縣新埔鎮寶石里九鄰關埔路內立段672號",
    "電話": "(03)5882397",
    "網址": "http://www.pses.hcc.edu.tw"
  },
  {
    "代碼": "044618",
    "學校名稱": "縣立文山國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[305]新竹縣新埔鎮國校街160號",
    "電話": "(03)5882918",
    "網址": "http://www.wses.hcc.edu.tw"
  },
  {
    "代碼": "044619",
    "學校名稱": "縣立竹東國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[310]新竹縣竹東鎮康寧街1號",
    "電話": "(03)5962025",
    "網址": "http://www.cdes.hcc.edu.tw/front/bin/home.phtml"
  },
  {
    "代碼": "044620",
    "學校名稱": "縣立中山國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[310]新竹縣竹東鎮雞林里中山路70號",
    "電話": "(03)5962102",
    "網址": "http://www.cses.hcc.edu.tw"
  },
  {
    "代碼": "044621",
    "學校名稱": "縣立大同國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[310]新竹縣竹東鎮莊敬路111號",
    "電話": "(03)5961160",
    "網址": "http://www.dtes.hcc.edu.tw"
  },
  {
    "代碼": "044622",
    "學校名稱": "縣立二重國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[310]新竹縣竹東鎮二重里光明路32號",
    "電話": "(03)5822098",
    "網址": "http://www.eces.hcc.edu.tw"
  },
  {
    "代碼": "044623",
    "學校名稱": "縣立竹中國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[310]新竹縣竹東鎮頭重里竹中路104巷14號",
    "電話": "(03)5823822",
    "網址": "http://www.ccps.hcc.edu.tw"
  },
  {
    "代碼": "044624",
    "學校名稱": "縣立員崠國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[310]新竹縣竹東鎮東峰路281號",
    "電話": "(03)5962143",
    "網址": "http://www.rdes.hcc.edu.tw"
  },
  {
    "代碼": "044625",
    "學校名稱": "縣立陸豐國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[310]新竹縣竹東鎮陸豐里48號",
    "電話": "(03)5923697",
    "網址": "http://www.lfes.hcc.edu.tw"
  },
  {
    "代碼": "044626",
    "學校名稱": "縣立瑞峰國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[310]新竹縣竹東鎮上坪里43號",
    "電話": "(03)5949052",
    "網址": "http://www.rfes.hcc.edu.tw"
  },
  {
    "代碼": "044627",
    "學校名稱": "縣立竹北國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市中央路98號",
    "電話": "(03)5552047",
    "網址": "http://www.cbes.hcc.edu.tw"
  },
  {
    "代碼": "044628",
    "學校名稱": "縣立中正國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市竹北村中山路190號",
    "電話": "(03)5552464",
    "網址": "http://www.cces.hcc.edu.tw"
  },
  {
    "代碼": "044629",
    "學校名稱": "縣立竹仁國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市竹仁里仁和街1號",
    "電話": "(03)5552472",
    "網址": "http://www.cjes.hcc.edu.tw"
  },
  {
    "代碼": "044630",
    "學校名稱": "縣立新社國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市新社里40號",
    "電話": "(03)5552026",
    "網址": "http://www.hses.hcc.edu.tw"
  },
  {
    "代碼": "044631",
    "學校名稱": "縣立六家國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市嘉興路62號",
    "電話": "(03)5502624",
    "網址": "http://www.ljes.hcc.edu.tw"
  },
  {
    "代碼": "044632",
    "學校名稱": "縣立東海國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市東興路一段839號",
    "電話": "(03)5502961",
    "網址": "http://www.dhes.hcc.edu.tw"
  },
  {
    "代碼": "044633",
    "學校名稱": "縣立豐田國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市中正西路1377號",
    "電話": "(03)5563930",
    "網址": "http://www.ftes.hcc.edu.tw"
  },
  {
    "代碼": "044634",
    "學校名稱": "縣立麻園國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市麻園里長園一街236號",
    "電話": "(03)5563540",
    "網址": "http://www.myes.hcc.edu.tw"
  },
  {
    "代碼": "044635",
    "學校名稱": "縣立新港國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市長青路一段333巷135號",
    "電話": "(03)5563275",
    "網址": "http://www.hges.hcc.edu.tw"
  },
  {
    "代碼": "044636",
    "學校名稱": "縣立鳳岡國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市大義里鳳岡路3段200號",
    "電話": "(03)5561928",
    "網址": "http://www.fkes.hcc.edu.tw"
  },
  {
    "代碼": "044637",
    "學校名稱": "縣立新湖國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[303]新竹縣湖口鄉民族街222號",
    "電話": "(03)5992062",
    "網址": "http://www.shes.hcc.edu.tw"
  },
  {
    "代碼": "044638",
    "學校名稱": "縣立和興國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[303]新竹縣湖口鄉和興村德興路930號",
    "電話": "(03)5992094",
    "網址": "http://www.hres.hcc.edu.tw"
  },
  {
    "代碼": "044639",
    "學校名稱": "縣立信勢國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[303]新竹縣湖口鄉成功路360號",
    "電話": "(03)5992133",
    "網址": "http://www.sses.hcc.edu.tw"
  },
  {
    "代碼": "044640",
    "學校名稱": "縣立湖口國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[303]新竹縣湖口鄉湖口村3鄰八德路二段182號",
    "電話": "(03)5693108",
    "網址": "http://www.hkes.hcc.edu.tw"
  },
  {
    "代碼": "044641",
    "學校名稱": "縣立山崎國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[304]新竹縣新豐鄉新興路291號",
    "電話": "(03)5592714",
    "網址": "http://www.sces.hcc.edu.tw"
  },
  {
    "代碼": "044642",
    "學校名稱": "縣立長安國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[303]新竹縣湖口鄉長安村長春路50號",
    "電話": "(03)5690596",
    "網址": "http://www.caes.hcc.edu.tw"
  },
  {
    "代碼": "044643",
    "學校名稱": "縣立中興國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[303]新竹縣湖口鄉勝利村吉祥街43號",
    "電話": "(03)5982043",
    "網址": "http://www.csps.hcc.edu.tw"
  },
  {
    "代碼": "044644",
    "學校名稱": "縣立華興國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[303]新竹縣湖口鄉文化路171號",
    "電話": "(03)5981789",
    "網址": "http://www.hwes.hcc.edu.tw"
  },
  {
    "代碼": "044645",
    "學校名稱": "縣立橫山國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[312]新竹縣橫山鄉橫山村橫山100號",
    "電話": "(03)5933780",
    "網址": "http://www.hsps.hcc.edu.tw"
  },
  {
    "代碼": "044646",
    "學校名稱": "縣立田寮國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[312]新竹縣橫山鄉田寮村田洋街128號",
    "電話": "(03)5934164",
    "網址": "http://www.tles.hcc.edu.tw"
  },
  {
    "代碼": "044647",
    "學校名稱": "縣立大肚國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[312]新竹縣橫山鄉大肚村中豐路二段157號",
    "電話": "(03)5932380",
    "網址": "http://www.ddes.hcc.edu.tw"
  },
  {
    "代碼": "044648",
    "學校名稱": "縣立沙坑國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[312]新竹縣橫山鄉沙坑村三鄰沙坑街48號",
    "電話": "(03)5811034",
    "網址": "http://www.skps.hcc.edu.tw"
  },
  {
    "代碼": "044649",
    "學校名稱": "縣立內灣國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[312]新竹縣橫山鄉內灣村三鄰110號",
    "電話": "(03)5848019",
    "網址": "http://www.nwes.hcc.edu.tw"
  },
  {
    "代碼": "044650",
    "學校名稱": "縣立福興國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[304]新竹縣新豐鄉後湖村108號",
    "電話": "(03)5680964",
    "網址": "http://www.fses.hcc.edu.tw"
  },
  {
    "代碼": "044651",
    "學校名稱": "縣立新豐國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[304]新竹縣新豐鄉重興村明德巷13號",
    "電話": "(03)5592081",
    "網址": "http://www.hfes.hcc.edu.tw"
  },
  {
    "代碼": "044652",
    "學校名稱": "縣立瑞興國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[304]新竹縣新豐鄉瑞興村63號",
    "電話": "(03)5680490",
    "網址": "http://www.rses.hcc.edu.tw"
  },
  {
    "代碼": "044653",
    "學校名稱": "縣立福龍國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[304]新竹縣新豐鄉福興村113號",
    "電話": "(03)5993140",
    "網址": "http://www.flps.hcc.edu.tw"
  },
  {
    "代碼": "044654",
    "學校名稱": "縣立埔和國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[304]新竹縣新豐鄉埔和村466號",
    "電話": "(03)5680096",
    "網址": "http://www.phes.hcc.edu.tw"
  },
  {
    "代碼": "044655",
    "學校名稱": "縣立芎林國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[307]新竹縣芎林鄉文林村四鄰文山路288號",
    "電話": "(03)5923184",
    "網址": "http://www.cles.hcc.edu.tw"
  },
  {
    "代碼": "044656",
    "學校名稱": "縣立碧潭國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[307]新竹縣芎林鄉石潭村福昌街90號",
    "電話": "(03)5923191",
    "網址": "http://www.ptes.hcc.edu.tw"
  },
  {
    "代碼": "044657",
    "學校名稱": "縣立五龍國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[307]新竹縣芎林鄉五龍村105號",
    "電話": "(03)5932320",
    "網址": "http://www.wles.hcc.edu.tw"
  },
  {
    "代碼": "044658",
    "學校名稱": "縣立寶山國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[308]新竹縣寶山鄉寶山村寶山路二段130號",
    "電話": "(03)5761390",
    "網址": "http://www.bses.hcc.edu.tw"
  },
  {
    "代碼": "044659",
    "學校名稱": "縣立新城國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[308]新竹縣寶山鄉寶新路二段475號",
    "電話": "(03)5762707",
    "網址": "http://www.scps.hcc.edu.tw"
  },
  {
    "代碼": "044660",
    "學校名稱": "縣立雙溪國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[308]新竹縣寶山鄉雙園路二段310號",
    "電話": "(03)5202151",
    "網址": "http://www.ssps.hcc.edu.tw"
  },
  {
    "代碼": "044662",
    "學校名稱": "縣立北埔國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[314]新竹縣北埔鄉埔心街24號",
    "電話": "(03)5802215",
    "網址": "http://www.bpes.hcc.edu.tw"
  },
  {
    "代碼": "044663",
    "學校名稱": "縣立大坪國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[314]新竹縣北埔鄉外坪村一鄰8號",
    "電話": "(03)5802264",
    "網址": "http://www.dpes.hcc.edu.tw"
  },
  {
    "代碼": "044664",
    "學校名稱": "縣立峨眉國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[315]新竹縣峨眉鄉峨眉街4號",
    "電話": "(03)5800674",
    "網址": "http://www.omes.hcc.edu.tw"
  },
  {
    "代碼": "044665",
    "學校名稱": "縣立富興國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[315]新竹縣峨眉鄉富興村太平街8號",
    "電話": "(03)5806353",
    "網址": "http://www.fsps.hcc.edu.tw"
  },
  {
    "代碼": "044666",
    "學校名稱": "縣立尖石國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[313]新竹縣尖石鄉嘉樂村六鄰7號",
    "電話": "(03)5841563",
    "網址": "http://www.gsps.hcc.edu.tw"
  },
  {
    "代碼": "044667",
    "學校名稱": "縣立嘉興國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[313]新竹縣尖石鄉嘉樂村三鄰177號",
    "電話": "(03)5841004",
    "網址": "http://www.jsps.hcc.edu.tw"
  },
  {
    "代碼": "044668",
    "學校名稱": "縣立新樂國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[313]新竹縣尖石鄉新樂村一鄰10號",
    "電話": "(03)5841500",
    "網址": "http://www.hles.hcc.edu.tw"
  },
  {
    "代碼": "044669",
    "學校名稱": "縣立梅花國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[313]新竹縣尖石鄉梅花村1鄰25號",
    "電話": "(03)5841501",
    "網址": "http://www.mhes.hcc.edu.tw"
  },
  {
    "代碼": "044670",
    "學校名稱": "縣立錦屏國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[313]新竹縣尖石鄉錦屏村十鄰116號",
    "電話": "(03)5841633",
    "網址": "http://www.gpes.hcc.edu.tw"
  },
  {
    "代碼": "044671",
    "學校名稱": "縣立玉峰國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[313]新竹縣尖石鄉玉峰村五鄰59號",
    "電話": "(03)5847200",
    "網址": "http://www.yfes.hcc.edu.tw"
  },
  {
    "代碼": "044672",
    "學校名稱": "縣立石磊國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[313]新竹縣尖石鄉玉峰村十鄰49號",
    "電話": "(03)5847001",
    "網址": "http://www.sles.hcc.edu.tw"
  },
  {
    "代碼": "044673",
    "學校名稱": "縣立秀巒國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[313]新竹縣尖石鄉秀巒村四鄰19號",
    "電話": "(03)5847500",
    "網址": "http://www.slps.hcc.edu.tw"
  },
  {
    "代碼": "044674",
    "學校名稱": "縣立新光國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[313]新竹縣尖石鄉秀巒村八鄰17號",
    "電話": "(03)5847706",
    "網址": "http://www.hkps.hcc.edu.tw"
  },
  {
    "代碼": "044675",
    "學校名稱": "縣立五峰國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[311]新竹縣五峰鄉大隘村六鄰123號",
    "電話": "(03)5851007",
    "網址": "http://www.wfes.hcc.edu.tw"
  },
  {
    "代碼": "044676",
    "學校名稱": "縣立桃山國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[311]新竹縣五峰鄉桃山村15鄰243號",
    "電話": "(03)5856040",
    "網址": "http://www.tses.hcc.edu.tw"
  },
  {
    "代碼": "044677",
    "學校名稱": "縣立花園國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[311]新竹縣五峰鄉花園村八鄰174號",
    "電話": "(03)5851091",
    "網址": "http://www.hyes.hcc.edu.tw"
  },
  {
    "代碼": "044678",
    "學校名稱": "縣立博愛國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市光明六路72號",
    "電話": "(03)5519830",
    "網址": "http://www.paes.hcc.edu.tw"
  },
  {
    "代碼": "044679",
    "學校名稱": "縣立上館國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[310]新竹縣竹東鎮中豐路二段399號",
    "電話": "(03)5103777",
    "網址": "http://www.sqes.hcc.edu.tw"
  },
  {
    "代碼": "044680",
    "學校名稱": "縣立光明國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市光明九路65號",
    "電話": "(03)5538655",
    "網址": "http://www.kmes.hcc.edu.tw"
  },
  {
    "代碼": "044681",
    "學校名稱": "縣立松林國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[304]新竹縣新豐鄉松林街99號",
    "電話": "(03)5573601",
    "網址": "http://www.clps.hcc.edu.tw/front/bin/home.phtml"
  },
  {
    "代碼": "044682",
    "學校名稱": "縣立十興國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市莊敬北路66號",
    "電話": "(03)6583517",
    "網址": "http://www.shps.hcc.edu.tw/front/bin/home.phtml"
  },
  {
    "代碼": "044683",
    "學校名稱": "縣立興隆國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市文興路88號",
    "電話": "(03)6681799",
    "網址": "http://www.hlps.hcc.edu.tw/front/bin/home.phtml"
  },
  {
    "代碼": "044684",
    "學校名稱": "縣立東興國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市嘉豐十一路二段100號",
    "電話": "(03)5502120",
    "網址": "http://www.dxes.hcc.edu.tw/bin/home.php"
  },
  {
    "代碼": "044685",
    "學校名稱": "縣立安興國小",
    "公/私立": "公立",
    "縣市名稱": "[04]新竹縣",
    "地址": "[302]新竹縣竹北市十興路一段435號",
    "電話": "(03)6684105",
    "網址": "http://www.axes.hcc.edu.tw/bin/home.php"
  },
  {
    "代碼": "054601",
    "學校名稱": "縣立建功國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[360]苗栗縣苗栗市中正路241號",
    "電話": "(037)320043",
    "網址": "http://www.jges.mlc.edu.tw"
  },
  {
    "代碼": "054602",
    "學校名稱": "縣立大同國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[360]苗栗縣苗栗市高苗里大同路80號",
    "電話": "(037)320068",
    "網址": "http://www.datunges.mlc.edu.tw"
  },
  {
    "代碼": "054603",
    "學校名稱": "縣立僑育國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[360]苗栗縣苗栗市僑育街131號",
    "電話": "(037)320414",
    "網址": "http://web.chiauyues.mlc.edu.tw"
  },
  {
    "代碼": "054604",
    "學校名稱": "縣立文山國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[360]苗栗縣苗栗市文山里4鄰文山41號",
    "電話": "(037)320406",
    "網址": "http://web.wenshanes.mlc.edu.tw"
  },
  {
    "代碼": "054605",
    "學校名稱": "縣立啟文國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[360]苗栗縣苗栗市福星里啟賢街61號",
    "電話": "(037)260466",
    "網址": "http://www.chiw.mlc.edu.tw"
  },
  {
    "代碼": "054606",
    "學校名稱": "縣立新英國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[360]苗栗縣苗栗市南勢里新勝8號",
    "電話": "(037)320715",
    "網址": "http://web.sies.mlc.edu.tw"
  },
  {
    "代碼": "054607",
    "學校名稱": "縣立頭屋國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[362]苗栗縣頭屋鄉頭屋村中山街15號",
    "電話": "(037)251071",
    "網址": "http://web.twjh.mlc.edu.tw"
  },
  {
    "代碼": "054608",
    "學校名稱": "縣立明德國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[362]苗栗縣頭屋鄉明德村明德路4巷13號",
    "電話": "(037)252330",
    "網址": "http://web.mdes.mlc.edu.tw"
  },
  {
    "代碼": "054609",
    "學校名稱": "縣立公館國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[363]苗栗縣公館鄉館中村大同路15號",
    "電話": "(037)230103",
    "網址": "http://www.gges.mlc.edu.tw"
  },
  {
    "代碼": "054610",
    "學校名稱": "縣立五穀國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[363]苗栗縣公館鄉五谷村260號",
    "電話": "(037)226731",
    "網址": "http://www.wugues.mlc.edu.tw"
  },
  {
    "代碼": "054611",
    "學校名稱": "縣立福基國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[363]苗栗縣公館鄉福基村275號",
    "電話": "(037)224020",
    "網址": "http://web.fujies.mlc.edu.tw"
  },
  {
    "代碼": "054612",
    "學校名稱": "縣立鶴岡國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[363]苗栗縣公館鄉鶴山村197號",
    "電話": "(037)226205",
    "網址": "http://web.hges.mlc.edu.tw"
  },
  {
    "代碼": "054614",
    "學校名稱": "縣立開礦國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[363]苗栗縣公館鄉開礦村九鄰143號",
    "電話": "(037)224559",
    "網址": "http://www.kkes.mlc.edu.tw"
  },
  {
    "代碼": "054615",
    "學校名稱": "縣立南河國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[363]苗栗縣公館鄉南河村二鄰21號",
    "電話": "(037)226806",
    "網址": "http://www.nhps.mlc.edu.tw"
  },
  {
    "代碼": "054616",
    "學校名稱": "縣立銅鑼國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[366]苗栗縣銅鑼鄉福興村文化街1號",
    "電話": "(037)981013",
    "網址": "http://www.tunglo.mlc.edu.tw"
  },
  {
    "代碼": "054617",
    "學校名稱": "縣立中興國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[366]苗栗縣銅鑼鄉中平村150號",
    "電話": "(037)224458",
    "網址": "http://www.jss.mlc.edu.tw"
  },
  {
    "代碼": "054618",
    "學校名稱": "縣立九湖國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[366]苗栗縣銅鑼鄉九湖村10鄰91之1號",
    "電話": "(037)981149",
    "網址": "http://web.jhes.mlc.edu.tw"
  },
  {
    "代碼": "054619",
    "學校名稱": "縣立新隆國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[366]苗栗縣銅鑼鄉新隆村18號",
    "電話": "(037)971102",
    "網址": "http://web.xles.mlc.edu.tw"
  },
  {
    "代碼": "054620",
    "學校名稱": "縣立興隆國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[366]苗栗縣銅鑼鄉興隆村117號",
    "電話": "(037)982804",
    "網址": "http://web.sles.mlc.edu.tw"
  },
  {
    "代碼": "054621",
    "學校名稱": "縣立文峰國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[366]苗栗縣銅鑼鄉樟樹村12之1號",
    "電話": "(037)981143",
    "網址": "http://www.wfe.mlc.edu.tw"
  },
  {
    "代碼": "054622",
    "學校名稱": "縣立建中國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[367]苗栗縣三義鄉廣盛村廣盛80號",
    "電話": "(037)872007",
    "網址": "http://web.jjs.mlc.edu.tw"
  },
  {
    "代碼": "054623",
    "學校名稱": "縣立僑成國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[367]苗栗縣三義鄉勝興村廿份220號",
    "電話": "(037)872041",
    "網址": "http://web.cces.mlc.edu.tw"
  },
  {
    "代碼": "054624",
    "學校名稱": "縣立育英國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[367]苗栗縣三義鄉西湖村上湖63號",
    "電話": "(037)872934",
    "網址": "http://www.yues.mlc.edu.tw"
  },
  {
    "代碼": "054625",
    "學校名稱": "縣立鯉魚國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[367]苗栗縣三義鄉鯉魚潭村上山下24號",
    "電話": "(037)881154",
    "網址": "http://www.liyues.mlc.edu.tw"
  },
  {
    "代碼": "054627",
    "學校名稱": "縣立苑裡國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[358]苗栗縣苑裡鎮中山路307號",
    "電話": "(037)861013",
    "網址": "http://www.yles.mlc.edu.tw"
  },
  {
    "代碼": "054628",
    "學校名稱": "縣立文苑國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[358]苗栗縣苑裡鎮房裡里二鄰14號",
    "電話": "(037)861410",
    "網址": "http://www.wyes.mlc.edu.tw"
  },
  {
    "代碼": "054629",
    "學校名稱": "縣立山腳國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[358]苗栗縣苑裡鎮舊社里10鄰47號",
    "電話": "(037)745024",
    "網址": "http://web.sj.mlc.edu.tw"
  },
  {
    "代碼": "054630",
    "學校名稱": "縣立中正國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[358]苗栗縣苑裡鎮中正里111號",
    "電話": "(037)861244",
    "網址": "http://web.jjes.mlc.edu.tw"
  },
  {
    "代碼": "054631",
    "學校名稱": "縣立藍田國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[358]苗栗縣苑裡鎮泰田里3鄰62號",
    "電話": "(037)743504",
    "網址": "http://web.lt.mlc.edu.tw"
  },
  {
    "代碼": "054632",
    "學校名稱": "縣立中山國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[358]苗栗縣苑裡鎮社苓里四鄰51之1號",
    "電話": "(037)743564",
    "網址": "http://www.js.mlc.edu.tw"
  },
  {
    "代碼": "054633",
    "學校名稱": "縣立林森國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[358]苗栗縣苑裡鎮水坡里70號",
    "電話": "(037)861084",
    "網址": "http://web.linsenes.mlc.edu.tw"
  },
  {
    "代碼": "054634",
    "學校名稱": "縣立蕉埔國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[358]苗栗縣苑裡鎮蕉埔里八鄰87之2號",
    "電話": "(037)741574",
    "網址": "http://web.jpes.mlc.edu.tw"
  },
  {
    "代碼": "054635",
    "學校名稱": "縣立通霄國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[357]苗栗縣通霄鎮通東里中正路12號",
    "電話": "(037)752008",
    "網址": "http://www.te.mlc.edu.tw"
  },
  {
    "代碼": "054636",
    "學校名稱": "縣立五福國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[357]苗栗縣通霄鎮5北里30之1號",
    "電話": "(037)754814",
    "網址": "http://www.wufues.mlc.edu.tw"
  },
  {
    "代碼": "054637",
    "學校名稱": "縣立城中國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[357]苗栗縣通霄鎮城北里13號",
    "電話": "(037)754504",
    "網址": "http://web.cjes.mlc.edu.tw"
  },
  {
    "代碼": "054638",
    "學校名稱": "縣立啟明國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[357]苗栗縣通霄鎮內島里二鄰15號",
    "電話": "(037)792537",
    "網址": "http://www.cmes.mlc.edu.tw"
  },
  {
    "代碼": "054639",
    "學校名稱": "縣立新埔國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[357]苗栗縣通霄鎮新埔里55號",
    "電話": "(037)792094",
    "網址": "http://www.hpes.hcc.edu.tw/front/bin/home.phtml"
  },
  {
    "代碼": "054640",
    "學校名稱": "縣立烏眉國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[357]苗栗縣通霄鎮烏眉里3鄰39號",
    "電話": "(037)753084",
    "網址": "http://web.wumeies.mlc.edu.tw"
  },
  {
    "代碼": "054641",
    "學校名稱": "縣立南和國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[357]苗栗縣通霄鎮南和里117號",
    "電話": "(037)782304",
    "網址": "http://web.nhes.mlc.edu.tw"
  },
  {
    "代碼": "054642",
    "學校名稱": "縣立坪頂國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[357]苗栗縣通霄鎮坪頂里四鄰50號",
    "電話": "(037)782214",
    "網址": "http://web.pdes.mlc.edu.tw/"
  },
  {
    "代碼": "054643",
    "學校名稱": "縣立圳頭國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[357]苗栗縣通霄鎮圳頭里60號",
    "電話": "(037)754434",
    "網址": "http://www.toes.mlc.edu.tw"
  },
  {
    "代碼": "054645",
    "學校名稱": "縣立福興武術國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[357]苗栗縣通霄鎮福興里58之2號",
    "電話": "(037)782984",
    "網址": "http://web.fushing.mlc.edu.tw"
  },
  {
    "代碼": "054646",
    "學校名稱": "縣立西湖國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[368]苗栗縣西湖鄉龍洞村一鄰18號",
    "電話": "(037)921022",
    "網址": "http://web.shihues.mlc.edu.tw"
  },
  {
    "代碼": "054647",
    "學校名稱": "縣立五湖國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[368]苗栗縣西湖鄉五湖村上湖96號",
    "電話": "(037)911244",
    "網址": "http://www.wuhues.mlc.edu.tw"
  },
  {
    "代碼": "054648",
    "學校名稱": "縣立僑文國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[368]苗栗縣西湖鄉二湖村二鄰22號",
    "電話": "(037)921065",
    "網址": "http://web.cwes.mlc.edu.tw"
  },
  {
    "代碼": "054650",
    "學校名稱": "縣立頭份國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[351]苗栗縣頭份市頭份里中正路219號",
    "電話": "(037)663020",
    "網址": "http://www.tfes.mlc.edu.tw/index1.php"
  },
  {
    "代碼": "054651",
    "學校名稱": "縣立六合國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[351]苗栗縣頭份市東庄里民族路252號",
    "電話": "(037)663065",
    "網址": "http://www.lhes.mlc.edu.tw"
  },
  {
    "代碼": "054652",
    "學校名稱": "縣立永貞國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[351]苗栗縣頭份市田寮里永貞路一段335號",
    "電話": "(037)623290",
    "網址": "http://web.yes.mlc.edu.tw"
  },
  {
    "代碼": "054653",
    "學校名稱": "縣立尖山國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[351]苗栗縣頭份市尖下里尖豐路305號",
    "電話": "(037)624474",
    "網址": "http://web.jses.mlc.edu.tw"
  },
  {
    "代碼": "054654",
    "學校名稱": "縣立僑善國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[351]苗栗縣頭份市中正一路395號",
    "電話": "(037)663137",
    "網址": "http://www.cses.mlc.edu.tw"
  },
  {
    "代碼": "054655",
    "學校名稱": "縣立斗煥國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[351]苗栗縣頭份市斗煥里中正二路221號",
    "電話": "(037)663848",
    "網址": "http://web.dh.mlc.edu.tw"
  },
  {
    "代碼": "054656",
    "學校名稱": "縣立后庄國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[351]苗栗縣頭份市文化里文化街20號",
    "電話": "(037)663858",
    "網址": "http://www.hzes.mlc.edu.tw"
  },
  {
    "代碼": "054657",
    "學校名稱": "縣立新興國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[351]苗栗縣頭份市下興里水源路157號",
    "電話": "(037)663855",
    "網址": "http://www.sses.mlc.edu.tw"
  },
  {
    "代碼": "054658",
    "學校名稱": "縣立信德國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[351]苗栗縣頭份市流東里信德路58號",
    "電話": "(037)602880",
    "網址": "http://web.sd.mlc.edu.tw"
  },
  {
    "代碼": "054659",
    "學校名稱": "縣立竹南國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[350]苗栗縣竹南鎮中正路146號",
    "電話": "(037)462020",
    "網址": "http://www.junanes.mlc.edu.tw"
  },
  {
    "代碼": "054660",
    "學校名稱": "縣立照南國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[350]苗栗縣竹南鎮佳興里光復路331號",
    "電話": "(037)472633",
    "網址": "http://www.jnes.mlc.edu.tw"
  },
  {
    "代碼": "054661",
    "學校名稱": "縣立大埔國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[350]苗栗縣竹南鎮大埔里仁愛路1092號",
    "電話": "(037)584370",
    "網址": "http://www.dapues.mlc.edu.tw"
  },
  {
    "代碼": "054662",
    "學校名稱": "縣立頂埔國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[350]苗栗縣竹南鎮頂埔里十五鄰頂大埔110號",
    "電話": "(037)671785",
    "網址": "http://web.dpes.mlc.edu.tw"
  },
  {
    "代碼": "054663",
    "學校名稱": "縣立海口國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[350]苗栗縣竹南鎮海口里7鄰保福路20號",
    "電話": "(037)461354",
    "網址": "http://web.hkes.mlc.edu.tw"
  },
  {
    "代碼": "054664",
    "學校名稱": "縣立三灣國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[352]苗栗縣三灣鄉三灣村中山路49號",
    "電話": "(037)831006",
    "網址": "http://www.sanwanes.mlc.edu.tw"
  },
  {
    "代碼": "054668",
    "學校名稱": "縣立南庄國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[353]苗栗縣南庄鄉東村文化路3號",
    "電話": "(037)822007",
    "網址": "http://www.nanjuanges.mlc.edu.tw"
  },
  {
    "代碼": "054669",
    "學校名稱": "縣立田美國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[353]苗栗縣南庄鄉田美村九鄰191號",
    "電話": "(037)822445",
    "網址": "http://www.tmes.mlc.edu.tw"
  },
  {
    "代碼": "054670",
    "學校名稱": "縣立南埔國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[353]苗栗縣南庄鄉南富村134號",
    "電話": "(037)822324",
    "網址": "http://www.nanpues.mlc.edu.tw"
  },
  {
    "代碼": "054671",
    "學校名稱": "縣立東河國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[353]苗栗縣南庄鄉東河村三鄰132號",
    "電話": "(037)823423",
    "網址": "https://www.donghe.mlc.edu.tw"
  },
  {
    "代碼": "054672",
    "學校名稱": "縣立蓬萊國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[353]苗栗縣南庄鄉蓬萊村19鄰118號",
    "電話": "(037)823385",
    "網址": "http://www.penla.mlc.edu.tw"
  },
  {
    "代碼": "054673",
    "學校名稱": "縣立造橋國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[361]苗栗縣造橋鄉造橋村十四鄰3號",
    "電話": "(037)542686",
    "網址": "http://www.zcher.mlc.edu.tw"
  },
  {
    "代碼": "054674",
    "學校名稱": "縣立談文國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[361]苗栗縣造橋鄉談文村六鄰54號",
    "電話": "(037)624044",
    "網址": "http://web.twen.mlc.edu.tw"
  },
  {
    "代碼": "054675",
    "學校名稱": "縣立錦水國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[361]苗栗縣造橋鄉大西村13鄰大中街68號",
    "電話": "(037)541978",
    "網址": "http://www.jies.mlc.edu.tw"
  },
  {
    "代碼": "054676",
    "學校名稱": "縣立龍昇國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[361]苗栗縣造橋鄉龍昇村八鄰16之1號",
    "電話": "(037)651363",
    "網址": "http://web.lun.mlc.edu.tw"
  },
  {
    "代碼": "054677",
    "學校名稱": "縣立僑樂國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[361]苗栗縣造橋鄉錦水村十二鄰306號",
    "電話": "(037)541143",
    "網址": "http://www.chiaolo.mlc.edu.tw"
  },
  {
    "代碼": "054679",
    "學校名稱": "縣立後龍國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[356]苗栗縣後龍鎮大庄里成功路254號",
    "電話": "(037)722049",
    "網址": "http://www.hl.mlc.edu.tw"
  },
  {
    "代碼": "054680",
    "學校名稱": "縣立新港國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[356]苗栗縣後龍鎮校椅里校椅二路168號",
    "電話": "(037)722547",
    "網址": "http://www.skes.mlc.edu.tw"
  },
  {
    "代碼": "054681",
    "學校名稱": "縣立大山國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[356]苗栗縣後龍鎮灣寶里7鄰109號",
    "電話": "(037)432784",
    "網址": "http://www.ds.mlc.edu.tw"
  },
  {
    "代碼": "054683",
    "學校名稱": "縣立龍坑國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[356]苗栗縣後龍鎮龍坑里158號",
    "電話": "(037)722027",
    "網址": "http://www.lkes.mlc.edu.tw"
  },
  {
    "代碼": "054684",
    "學校名稱": "縣立溪洲國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[356]苗栗縣後龍鎮溪洲里87之3號",
    "電話": "(037)723853",
    "網址": "http://web.sjes.mlc.edu.tw"
  },
  {
    "代碼": "054685",
    "學校名稱": "縣立外埔國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[356]苗栗縣後龍鎮外埔里六鄰18-9號",
    "電話": "(037)431809",
    "網址": "http://web.wp.mlc.edu.tw"
  },
  {
    "代碼": "054686",
    "學校名稱": "縣立成功國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[356]苗栗縣後龍鎮水尾里12鄰104之1號",
    "電話": "(037)722211",
    "網址": "http://web.cges.mlc.edu.tw"
  },
  {
    "代碼": "054687",
    "學校名稱": "縣立中和國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[356]苗栗縣後龍鎮中和里十四鄰一五二號",
    "電話": "(037)921451",
    "網址": "http://www.jhps.mlc.edu.tw/"
  },
  {
    "代碼": "054688",
    "學校名稱": "縣立同光國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[356]苗栗縣後龍鎮龍津里94號",
    "電話": "(037)450738",
    "網址": "http://web.tges.mlc.edu.tw"
  },
  {
    "代碼": "054689",
    "學校名稱": "縣立海寶國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[356]苗栗縣後龍鎮海寶里五鄰52之2號",
    "電話": "(037)431235",
    "網址": "http://www.hbes.mlc.edu.tw/"
  },
  {
    "代碼": "054690",
    "學校名稱": "縣立大湖國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[364]苗栗縣大湖鄉大湖村中正路育英巷11號",
    "電話": "(037)991008",
    "網址": "http://www.dahues.mlc.edu.tw"
  },
  {
    "代碼": "054691",
    "學校名稱": "縣立南湖國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[364]苗栗縣大湖鄉義和村南昌路5號",
    "電話": "(037)991462",
    "網址": "http://www.nanhues.mlc.edu.tw"
  },
  {
    "代碼": "054692",
    "學校名稱": "縣立華興國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[364]苗栗縣大湖鄉富興村水尾2號",
    "電話": "(037)991326",
    "網址": "http://web.hs.mlc.edu.tw"
  },
  {
    "代碼": "054693",
    "學校名稱": "縣立大南國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[364]苗栗縣大湖鄉大南村7鄰大南勢15號",
    "電話": "(037)992813",
    "網址": "http://www.dnes.mlc.edu.tw"
  },
  {
    "代碼": "054694",
    "學校名稱": "縣立東興國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[364]苗栗縣大湖鄉東興村下湖37號",
    "電話": "(037)992815",
    "網址": "http://www.dss.mlc.edu.tw/"
  },
  {
    "代碼": "054695",
    "學校名稱": "縣立武榮國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[364]苗栗縣大湖鄉義和村淋漓坪77號",
    "電話": "(037)992816",
    "網址": "http://web.we.mlc.edu.tw"
  },
  {
    "代碼": "054696",
    "學校名稱": "縣立新開國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[364]苗栗縣大湖鄉新開村25號",
    "電話": "(037)951210",
    "網址": "http://web.shinkaies.mlc.edu.tw"
  },
  {
    "代碼": "054697",
    "學校名稱": "縣立栗林國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[364]苗栗縣大湖鄉栗林村四份61號",
    "電話": "(037)951335",
    "網址": "http://web.lilin.mlc.edu.tw"
  },
  {
    "代碼": "054698",
    "學校名稱": "縣立獅潭國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[354]苗栗縣獅潭鄉新店村129號",
    "電話": "(037)931305",
    "網址": "http://web.st.mlc.edu.tw"
  },
  {
    "代碼": "054699",
    "學校名稱": "縣立豐林國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[354]苗栗縣獅潭鄉新豐村四鄰56號",
    "電話": "(037)931664",
    "網址": "http://www.fl.mlc.edu.tw"
  },
  {
    "代碼": "054700",
    "學校名稱": "縣立永興國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[354]苗栗縣獅潭鄉永興村6鄰55-1號",
    "電話": "(037)931292",
    "網址": "http://www.yungshinges.mlc.edu.tw"
  },
  {
    "代碼": "054701",
    "學校名稱": "縣立卓蘭國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[369]苗栗縣卓蘭鎮新厝里中山路125號",
    "電話": "(04)25892008",
    "網址": "http://www.jles.mlc.edu.tw"
  },
  {
    "代碼": "054702",
    "學校名稱": "縣立內灣國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[369]苗栗縣卓蘭鎮內灣里120號",
    "電話": "(04)25892094",
    "網址": "http://www.nwes.mlc.edu.tw"
  },
  {
    "代碼": "054703",
    "學校名稱": "縣立豐田國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[369]苗栗縣卓蘭鎮豐田里12鄰豐田36號",
    "電話": "(04)25892164",
    "網址": "http://www.ftes.mlc.edu.tw"
  },
  {
    "代碼": "054704",
    "學校名稱": "縣立坪林國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[369]苗栗縣卓蘭鎮坪林里七鄰坪林86之2號",
    "電話": "(04)25921334",
    "網址": "http://web.ples.mlc.edu.tw"
  },
  {
    "代碼": "054705",
    "學校名稱": "縣立雙連國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[369]苗栗縣卓蘭鎮坪林里雙連16-1號",
    "電話": "(04)25892794",
    "網址": "http://www.2les.mlc.edu.tw/"
  },
  {
    "代碼": "054706",
    "學校名稱": "縣立景山國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[369]苗栗縣卓蘭鎮景山里5鄰88號",
    "電話": "(04)25921221",
    "網址": "http://web.gs.mlc.edu.tw"
  },
  {
    "代碼": "054707",
    "學校名稱": "縣立泰安國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[365]苗栗縣泰安鄉大興村32之1號",
    "電話": "(037)991224",
    "網址": "http://www.taian.mlc.edu.tw"
  },
  {
    "代碼": "054708",
    "學校名稱": "縣立泰興國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[365]苗栗縣泰安鄉中興村1鄰2號",
    "電話": "(037)992852",
    "網址": "http://www.tses.mlc.edu.tw"
  },
  {
    "代碼": "054709",
    "學校名稱": "縣立清安國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[365]苗栗縣泰安鄉清安村六鄰122號",
    "電話": "(037)941042",
    "網址": "http://web.ca.mlc.edu.tw"
  },
  {
    "代碼": "054711",
    "學校名稱": "縣立汶水國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[365]苗栗縣泰安鄉錦水村四鄰12號",
    "電話": "(037)941037",
    "網址": "https://www.ws.mlc.edu.tw/"
  },
  {
    "代碼": "054712",
    "學校名稱": "縣立象鼻國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[365]苗栗縣泰安鄉象鼻村一鄰4號",
    "電話": "(037)962270",
    "網址": "http://www.cnbe.mlc.edu.tw/"
  },
  {
    "代碼": "054714",
    "學校名稱": "縣立梅園國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[365]苗栗縣泰安鄉梅園村二鄰38號",
    "電話": "(037)962146",
    "網址": "http://www.my.mlc.edu.tw"
  },
  {
    "代碼": "054715",
    "學校名稱": "縣立建國國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[351]苗栗縣頭份市建國路80號",
    "電話": "(037)665905",
    "網址": "http://www.goes.mlc.edu.tw"
  },
  {
    "代碼": "054716",
    "學校名稱": "縣立竹興國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[350]苗栗縣竹南鎮竹興里竹圍街27號",
    "電話": "(037)465037",
    "網址": "http://www.jes.mlc.edu.tw"
  },
  {
    "代碼": "054717",
    "學校名稱": "縣立文華國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[360]苗栗縣苗栗市文山里正發路133號",
    "電話": "(037)354465",
    "網址": "http://www.whes.mlc.edu.tw/"
  },
  {
    "代碼": "054718",
    "學校名稱": "縣立福星國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[360]苗栗縣苗栗市中華東街62號",
    "電話": "(037)264724",
    "網址": "http://www.fses.mlc.edu.tw"
  },
  {
    "代碼": "054719",
    "學校名稱": "縣立新南國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[350]苗栗縣竹南鎮新南里五谷街2號",
    "電話": "(037)613843",
    "網址": "http://www.snes.mlc.edu.tw"
  },
  {
    "代碼": "054720",
    "學校名稱": "縣立蟠桃國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[351]苗栗縣頭份市信東路285號",
    "電話": "(037)685410",
    "網址": "http://www.pans.mlc.edu.tw"
  },
  {
    "代碼": "054721",
    "學校名稱": "縣立仁愛國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[363]苗栗縣公館鄉中村仁愛路二段123號",
    "電話": "(037)235328",
    "網址": "http://www.ries.mlc.edu.tw"
  },
  {
    "代碼": "054722",
    "學校名稱": "縣立客庄國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[358]苗栗縣苑裡鎮客庄里新興路21號",
    "電話": "(037)865646",
    "網址": "http://www.ktes.mlc.edu.tw"
  },
  {
    "代碼": "054723",
    "學校名稱": "縣立山佳國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[350]苗栗縣竹南鎮山佳里龍山路三段39號",
    "電話": "(037)612839",
    "網址": "http://www.sanja.mlc.edu.tw"
  },
  {
    "代碼": "054724",
    "學校名稱": "縣立信義國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[351]苗栗縣頭份市後庄里信中路36號",
    "電話": "(037)626818",
    "網址": "http://web.syes.mlc.edu.tw"
  },
  {
    "代碼": "054725",
    "學校名稱": "縣立士林國小",
    "公/私立": "公立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[365]苗栗縣泰安鄉士林村5鄰26號",
    "電話": "(037)962050",
    "網址": "http://web.shlines.mlc.edu.tw"
  },
  {
    "代碼": "074601",
    "學校名稱": "縣立中山國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市中山路二段678號",
    "電話": "(04)7222033",
    "網址": "http://www.cses.chc.edu.tw"
  },
  {
    "代碼": "074602",
    "學校名稱": "縣立民生國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市民生路338號",
    "電話": "(04)7224122",
    "網址": "http://www.mses.chc.edu.tw"
  },
  {
    "代碼": "074603",
    "學校名稱": "縣立平和國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市西安里中正路二段450號",
    "電話": "(04)7222355",
    "網址": "http://www.phes.chc.edu.tw"
  },
  {
    "代碼": "074604",
    "學校名稱": "縣立南郭國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市彰化市中興路98號",
    "電話": "(04)7280366",
    "網址": "http://www.nges.chc.edu.tw"
  },
  {
    "代碼": "074605",
    "學校名稱": "縣立南興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市南興里中山路一段213號",
    "電話": "(04)7622827",
    "網址": "http://www.nses.chc.edu.tw"
  },
  {
    "代碼": "074606",
    "學校名稱": "縣立東芳國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市東芳里彰馬路45號",
    "電話": "(04)7523250",
    "網址": "http://www.tfps.chc.edu.tw"
  },
  {
    "代碼": "074607",
    "學校名稱": "縣立泰和國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市泰和路二段145巷1號",
    "電話": "(04)7222433",
    "網址": "http://www.thps.chc.edu.tw"
  },
  {
    "代碼": "074608",
    "學校名稱": "縣立三民國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市田中里田中路125號",
    "電話": "(04)7384775",
    "網址": "http://www.smes.chc.edu.tw"
  },
  {
    "代碼": "074609",
    "學校名稱": "縣立聯興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市牛埔里一心南街208號",
    "電話": "(04)7384340",
    "網址": "http://www.lsps.chc.edu.tw"
  },
  {
    "代碼": "074610",
    "學校名稱": "縣立大竹國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市彰南路二段164巷41號",
    "電話": "(04)7381436",
    "網址": "http://www.tces.chc.edu.tw"
  },
  {
    "代碼": "074611",
    "學校名稱": "縣立國聖國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市國聖里中山路三段608號",
    "電話": "(04)7321093",
    "網址": "http://www.gses.chc.edu.tw"
  },
  {
    "代碼": "074612",
    "學校名稱": "縣立快官國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市快官里彰南路四段566號",
    "電話": "(04)7384814",
    "網址": "http://www.kges.chc.edu.tw"
  },
  {
    "代碼": "074613",
    "學校名稱": "縣立石牌國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市石牌里田坑路一段9號",
    "電話": "(04)7381672",
    "網址": "http://www.spes.chc.edu.tw"
  },
  {
    "代碼": "074614",
    "學校名稱": "縣立忠孝國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市忠誠路61號",
    "電話": "(04)7523944",
    "網址": "http://www.jsps.chc.edu.tw"
  },
  {
    "代碼": "074615",
    "學校名稱": "縣立芬園國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[502]彰化縣芬園鄉彰南路四段27巷28號",
    "電話": "(049)2522208",
    "網址": "http://www.fyps.chc.edu.tw"
  },
  {
    "代碼": "074616",
    "學校名稱": "縣立富山國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[502]彰化縣芬園鄉大埔村大埔路6號",
    "電話": "(049)2522084",
    "網址": "http://163.23.81.1"
  },
  {
    "代碼": "074617",
    "學校名稱": "縣立寶山國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[502]彰化縣芬園鄉圳墘村彰南路二段36號",
    "電話": "(049)2525010",
    "網址": "http://163.23.81.129/xoops3/"
  },
  {
    "代碼": "074618",
    "學校名稱": "縣立同安國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[502]彰化縣芬園鄉中崙村大彰路二段400巷36號",
    "電話": "(04)8590502",
    "網址": "http://www.taes.chc.edu.tw"
  },
  {
    "代碼": "074619",
    "學校名稱": "縣立文德國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[502]彰化縣芬園鄉大竹村大彰路一段617號",
    "電話": "(04)8590012",
    "網址": "http://www.wdes.chc.edu.tw"
  },
  {
    "代碼": "074620",
    "學校名稱": "縣立茄荖國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[502]彰化縣芬園鄉茄荖村嘉北街96號",
    "電話": "(049)2523018",
    "網址": "http://www.cles.chc.edu.tw"
  },
  {
    "代碼": "074621",
    "學校名稱": "縣立花壇國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[503]彰化縣花壇鄉長沙村學前路108號",
    "電話": "(04)7862029",
    "網址": "http://www.htes.chc.edu.tw"
  },
  {
    "代碼": "074622",
    "學校名稱": "縣立文祥國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[503]彰化縣花壇鄉劉厝村明雅街131號",
    "電話": "(04)7862255",
    "網址": "http://www.wses.chc.edu.tw"
  },
  {
    "代碼": "074623",
    "學校名稱": "縣立華南國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[503]彰化縣花壇鄉中庄村中橋街226號",
    "電話": "(04)7863225",
    "網址": "http://www.hnes.chc.edu.tw/"
  },
  {
    "代碼": "074624",
    "學校名稱": "縣立僑愛國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[503]彰化縣花壇鄉中口村中山路二段471號",
    "電話": "(04)7863227",
    "網址": "http://www.caps.chc.edu.tw"
  },
  {
    "代碼": "074625",
    "學校名稱": "縣立三春國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[503]彰化縣花壇鄉三春村三芬路47號",
    "電話": "(04)7862154",
    "網址": "http://www.sstps.chc.edu.tw"
  },
  {
    "代碼": "074626",
    "學校名稱": "縣立白沙國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[503]彰化縣花壇鄉白沙村彰員路三段225號",
    "電話": "(04)7863224",
    "網址": "http://www.bsps.chc.edu.tw"
  },
  {
    "代碼": "074627",
    "學校名稱": "縣立和美國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[508]彰化縣和美鎮和西里和平街19號",
    "電話": "(04)7552005",
    "網址": "http://www.hmps.chc.edu.tw"
  },
  {
    "代碼": "074628",
    "學校名稱": "縣立和東國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[508]彰化縣和美鎮和東里彰美路五段210號",
    "電話": "(04)7552724",
    "網址": "http://www.hdes.chc.edu.tw"
  },
  {
    "代碼": "074629",
    "學校名稱": "縣立大嘉國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[508]彰化縣和美鎮嘉犁里彰和路三段50號",
    "電話": "(04)7552524",
    "網址": "http://www.dces.chc.edu.tw"
  },
  {
    "代碼": "074630",
    "學校名稱": "縣立大榮國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[508]彰化縣和美鎮鎮平里嘉佃路296號",
    "電話": "(04)7636814",
    "網址": "http://www.dres.chc.edu.tw"
  },
  {
    "代碼": "074631",
    "學校名稱": "縣立新庄國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[508]彰化縣和美鎮犁盛里彰新路二段500號",
    "電話": "(04)7353267",
    "網址": "http://www.ssjes.chc.edu.tw"
  },
  {
    "代碼": "074632",
    "學校名稱": "縣立培英國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[508]彰化縣和美鎮塗厝里彰新路五段310號",
    "電話": "(04)7552430",
    "網址": "http://www.pyps.chc.edu.tw"
  },
  {
    "代碼": "074633",
    "學校名稱": "縣立線西國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[507]彰化縣線西鄉寓埔村中央路二段135號",
    "電話": "(04)7585075",
    "網址": "http://www.sces.chc.edu.tw"
  },
  {
    "代碼": "074634",
    "學校名稱": "縣立曉陽國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[507]彰化縣線西鄉頂犁村頂犁路158之1號",
    "電話": "(04)7585370",
    "網址": "http://163.23.93.194/~school/web/index.php"
  },
  {
    "代碼": "074635",
    "學校名稱": "縣立新港國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[509]彰化縣伸港鄉和平路60號",
    "電話": "(04)7982310",
    "網址": "http://www.sgps.chc.edu.tw"
  },
  {
    "代碼": "074636",
    "學校名稱": "縣立伸東國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[509]彰化縣伸港鄉七嘉村中華路565號",
    "電話": "(04)7982314",
    "網址": "http://www.sdes.chc.edu.tw"
  },
  {
    "代碼": "074637",
    "學校名稱": "縣立伸仁國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[509]彰化縣伸港鄉曾家村曾家路3之15號",
    "電話": "(04)7982324",
    "網址": "http://www.sres.chc.edu.tw"
  },
  {
    "代碼": "074638",
    "學校名稱": "縣立大同國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[509]彰化縣伸港鄉大同村彰新路七段724號",
    "電話": "(04)7982307",
    "網址": "http://www.dtes.chc.edu.tw"
  },
  {
    "代碼": "074639",
    "學校名稱": "縣立鹿港國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[505]彰化縣鹿港鎮菜園里三民路192號",
    "電話": "(04)7772038",
    "網址": "http://www.lges.chc.edu.tw"
  },
  {
    "代碼": "074640",
    "學校名稱": "縣立文開國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[505]彰化縣鹿港鎮新宮里文開路60號",
    "電話": "(04)7772042",
    "網址": "http://www.wkes.chc.edu.tw"
  },
  {
    "代碼": "074641",
    "學校名稱": "縣立洛津國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[505]彰化縣鹿港鎮洛津里公園三路51號",
    "電話": "(04)7772041",
    "網址": "http://163.23.116.197"
  },
  {
    "代碼": "074642",
    "學校名稱": "縣立海埔國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[505]彰化縣鹿港鎮海埔里鹿草路二段889號",
    "電話": "(04)7781316",
    "網址": "http://www.hpes.chc.edu.tw"
  },
  {
    "代碼": "074643",
    "學校名稱": "縣立新興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[505]彰化縣鹿港鎮詔安里某旦巷85號",
    "電話": "(04)7785911",
    "網址": "http://www.bsses.chc.edu.tw"
  },
  {
    "代碼": "074644",
    "學校名稱": "縣立草港國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[505]彰化縣鹿港鎮草中里頂草路四段251號",
    "電話": "(04)7712834",
    "網址": "http://www.tges.chc.edu.tw"
  },
  {
    "代碼": "074645",
    "學校名稱": "縣立頂番國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[505]彰化縣鹿港鎮頂草路一段100號",
    "電話": "(04)7711433",
    "網址": "http://www.dfes.chc.edu.tw"
  },
  {
    "代碼": "074646",
    "學校名稱": "縣立東興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[505]彰化縣鹿港鎮東崎里東崎7巷52號",
    "電話": "(04)7751591",
    "網址": "http://www.sdses.chc.edu.tw"
  },
  {
    "代碼": "074647",
    "學校名稱": "縣立管嶼國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[506]彰化縣福興鄉廈粘村沿海路三段100號",
    "電話": "(04)7702949",
    "網址": "http://www.gyes.chc.edu.tw"
  },
  {
    "代碼": "074648",
    "學校名稱": "縣立文昌國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[506]彰化縣福興鄉鎮平村鎮平街1號",
    "電話": "(04)7702812",
    "網址": "http://www.wces.chc.edu.tw"
  },
  {
    "代碼": "074649",
    "學校名稱": "縣立西勢國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[506]彰化縣福興鄉西勢村員鹿路二段412號",
    "電話": "(04)7772317",
    "網址": "http://www.ssses.chc.edu.tw"
  },
  {
    "代碼": "074650",
    "學校名稱": "縣立大興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[506]彰化縣福興鄉外埔村復興路15號",
    "電話": "(04)7792251",
    "網址": "http://www.bdsps.chc.edu.tw"
  },
  {
    "代碼": "074651",
    "學校名稱": "縣立永豐國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[506]彰化縣福興鄉番社村80號",
    "電話": "(04)7792748",
    "網址": "http://www.yfes.chc.edu.tw"
  },
  {
    "代碼": "074652",
    "學校名稱": "縣立日新國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[506]彰化縣福興鄉番婆村彰鹿路六段546號",
    "電話": "(04)7773861",
    "網址": "http://www.rses.chc.edu.tw"
  },
  {
    "代碼": "074653",
    "學校名稱": "縣立育新國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[506]彰化縣福興鄉二港村福正路4號",
    "電話": "(04)7802521",
    "網址": "http://www.yses.chc.edu.tw"
  },
  {
    "代碼": "074654",
    "學校名稱": "縣立秀水國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[504]彰化縣秀水鄉安東村中山路257號",
    "電話": "(04)7692756",
    "網址": "http://www.hses.chc.edu.tw"
  },
  {
    "代碼": "074655",
    "學校名稱": "縣立馬興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[504]彰化縣秀水鄉馬興村培英巷1號",
    "電話": "(04)7526247",
    "網址": "http://www.smses.chc.edu.tw"
  },
  {
    "代碼": "074656",
    "學校名稱": "縣立華龍國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[504]彰化縣秀水鄉秀水村花秀路51號",
    "電話": "(04)7692713",
    "網址": "http://www.hlps.chc.edu.tw"
  },
  {
    "代碼": "074657",
    "學校名稱": "縣立明正國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[504]彰化縣秀水鄉埔崙村彰水路二段341號",
    "電話": "(04)7692533",
    "網址": "http://www.mcps.chc.edu.tw"
  },
  {
    "代碼": "074658",
    "學校名稱": "縣立陝西國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[504]彰化縣秀水鄉金興村番花路261號",
    "電話": "(04)7692242",
    "網址": "http://www.ssps.chc.edu.tw"
  },
  {
    "代碼": "074659",
    "學校名稱": "縣立育民國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[504]彰化縣秀水鄉下崙村民生街262號",
    "電話": "(04)7694845",
    "網址": "http://www.ymes.chc.edu.tw"
  },
  {
    "代碼": "074660",
    "學校名稱": "縣立溪湖國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[514]彰化縣溪湖鎮光華里二溪路一段35號",
    "電話": "(04)8853126",
    "網址": "http://www.shps.chc.edu.tw"
  },
  {
    "代碼": "074661",
    "學校名稱": "縣立東溪國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[514]彰化縣溪湖鎮東溪里員鹿路一段538號",
    "電話": "(04)8852034",
    "網址": "http://163.23.98.1"
  },
  {
    "代碼": "074662",
    "學校名稱": "縣立湖西國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[514]彰化縣溪湖鎮大突里北聖路20號",
    "電話": "(04)8852234",
    "網址": "http://www.fses.chc.edu.tw"
  },
  {
    "代碼": "074663",
    "學校名稱": "縣立湖東國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[514]彰化縣溪湖鎮湖東里大溪路二段423號",
    "電話": "(04)8852062",
    "網址": "http://www.fdes.chc.edu.tw"
  },
  {
    "代碼": "074664",
    "學校名稱": "縣立湖南國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[514]彰化縣溪湖鎮大竹里彰水路二段725號",
    "電話": "(04)8816845",
    "網址": "http://www.hnps.chc.edu.tw"
  },
  {
    "代碼": "074665",
    "學校名稱": "縣立媽厝國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[514]彰化縣溪湖鎮媽厝里湳底路67號",
    "電話": "(04)8853225",
    "網址": "http://www.mtes.chc.edu.tw"
  },
  {
    "代碼": "074666",
    "學校名稱": "縣立埔鹽國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[516]彰化縣埔鹽鄉埔南村彰水路一段252號",
    "電話": "(04)8852882",
    "網址": "http://www.pyes.chc.edu.tw"
  },
  {
    "代碼": "074667",
    "學校名稱": "縣立大園國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[516]彰化縣埔鹽鄉豐澤村埔打路45之1號",
    "電話": "(04)8853760",
    "網址": "http://www.dyes.chc.edu.tw"
  },
  {
    "代碼": "074668",
    "學校名稱": "縣立南港國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[516]彰化縣埔鹽鄉南港村埔菜路29之2號",
    "電話": "(04)8850169",
    "網址": "http://163.23.72.193/ngps/"
  },
  {
    "代碼": "074669",
    "學校名稱": "縣立好修國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[516]彰化縣埔鹽鄉好修村員鹿路二段149號",
    "電話": "(04)8653555",
    "網址": "http://163.23.70.209/"
  },
  {
    "代碼": "074670",
    "學校名稱": "縣立永樂國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[516]彰化縣埔鹽鄉永樂村永樂路600號",
    "電話": "(04)8652263",
    "網址": "http://www.yles.chc.edu.tw"
  },
  {
    "代碼": "074671",
    "學校名稱": "縣立新水國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[516]彰化縣埔鹽鄉新水村大新路1巷15號",
    "電話": "(04)8651112",
    "網址": "http://163.23.72.11/eweb/sses"
  },
  {
    "代碼": "074672",
    "學校名稱": "縣立天盛國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[516]彰化縣埔鹽鄉天盛村番金路68之1號",
    "電話": "(04)8650842",
    "網址": "http://www.tses.chc.edu.tw"
  },
  {
    "代碼": "074673",
    "學校名稱": "縣立埔心國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[513]彰化縣埔心鄉義民村員鹿路二段254號",
    "電話": "(04)8291494",
    "網址": "http://www.pses.chc.edu.tw"
  },
  {
    "代碼": "074674",
    "學校名稱": "縣立太平國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[513]彰化縣埔心鄉太平村太平路289號",
    "電話": "(04)8226049",
    "網址": "http://www.tpes.chc.edu.tw"
  },
  {
    "代碼": "074675",
    "學校名稱": "縣立舊館國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[513]彰化縣埔心鄉舊館村員鹿路四段201號",
    "電話": "(04)8292025",
    "網址": "http://www.jges.chc.edu.tw"
  },
  {
    "代碼": "074676",
    "學校名稱": "縣立羅厝國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[513]彰化縣埔心鄉羅厝村文昌東路100號",
    "電話": "(04)8292861",
    "網址": "http://www.rtes.chc.edu.tw"
  },
  {
    "代碼": "074677",
    "學校名稱": "縣立鳳霞國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[513]彰化縣埔心鄉二重村五通南路82號",
    "電話": "(04)8294072",
    "網址": "http://www.sfsps.chc.edu.tw"
  },
  {
    "代碼": "074678",
    "學校名稱": "縣立梧鳳國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[513]彰化縣埔心鄉梧鳳村大溪路175號",
    "電話": "(04)8291964",
    "網址": "http://www.wfes.chc.edu.tw"
  },
  {
    "代碼": "074679",
    "學校名稱": "縣立明聖國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[513]彰化縣埔心鄉瓦北村西安南路178號",
    "電話": "(04)8295395",
    "網址": "http://www.msps.chc.edu.tw"
  },
  {
    "代碼": "074680",
    "學校名稱": "縣立員林國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[510]彰化縣員林市三和里三民東街221號",
    "電話": "(04)8320145",
    "網址": "http://www.ylps.chc.edu.tw"
  },
  {
    "代碼": "074681",
    "學校名稱": "縣立育英國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[510]彰化縣員林市黎明里光明街31號",
    "電話": "(04)8320130",
    "網址": "http://www.yyes.chc.edu.tw"
  },
  {
    "代碼": "074682",
    "學校名稱": "縣立靜修國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[510]彰化縣員林市南平里靜修路74號",
    "電話": "(04)8320341",
    "網址": "http://www.sjses.chc.edu.tw"
  },
  {
    "代碼": "074683",
    "學校名稱": "縣立僑信國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[510]彰化縣員林市三信里中山路二段280號",
    "電話": "(04)8320810",
    "網址": "http://www.csps.chc.edu.tw"
  },
  {
    "代碼": "074684",
    "學校名稱": "縣立員東國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[510]彰化縣員林市員東路二段12號",
    "電話": "(04)8311022",
    "網址": "http://www.ytes.chc.edu.tw"
  },
  {
    "代碼": "074685",
    "學校名稱": "縣立饒明國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[510]彰化縣員林市中央里員集路二段131號",
    "電話": "(04)8320059",
    "網址": "http://www.rmes.chc.edu.tw"
  },
  {
    "代碼": "074686",
    "學校名稱": "縣立東山國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[510]彰化縣員林市南東里山腳路四段212號",
    "電話": "(04)8310749",
    "網址": "http://www.dsps.chc.edu.tw"
  },
  {
    "代碼": "074687",
    "學校名稱": "縣立青山國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[510]彰化縣員林市出水里泉洲巷3號",
    "電話": "(04)8310401",
    "網址": "http://www.chcses.chc.edu.tw"
  },
  {
    "代碼": "074688",
    "學校名稱": "縣立明湖國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[510]彰化縣員林市湖水里湖水路277號",
    "電話": "(04)8310743",
    "網址": "http://www.mhes.chc.edu.tw"
  },
  {
    "代碼": "074689",
    "學校名稱": "縣立大村國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[515]彰化縣大村鄉大村村中正西路381號",
    "電話": "(04)8522794",
    "網址": "http://www.dtps.chc.edu.tw"
  },
  {
    "代碼": "074690",
    "學校名稱": "縣立大西國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[515]彰化縣大村鄉大崙村大崙路10之55號",
    "電話": "(04)8522677",
    "網址": "http://tw.school.uschoolnet.com/?id=es00001511"
  },
  {
    "代碼": "074691",
    "學校名稱": "縣立村上國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[515]彰化縣大村鄉村上村中山路二段242號",
    "電話": "(04)8533680",
    "網址": "http://www.tsps.chc.edu.tw"
  },
  {
    "代碼": "074692",
    "學校名稱": "縣立村東國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[515]彰化縣大村鄉福興村山腳路102之1號",
    "電話": "(04)8526328",
    "網址": "http://www.tdes.chc.edu.tw"
  },
  {
    "代碼": "074693",
    "學校名稱": "縣立永靖國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[512]彰化縣永靖鄉永東村中山路二段65號",
    "電話": "(04)8221812",
    "網址": "http://www.yces.chc.edu.tw"
  },
  {
    "代碼": "074694",
    "學校名稱": "縣立福德國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[512]彰化縣永靖鄉永南村福德巷36號",
    "電話": "(04)8224154",
    "網址": "http://www.fdps.chc.edu.tw"
  },
  {
    "代碼": "074695",
    "學校名稱": "縣立永興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[512]彰化縣永靖鄉東寧村永興路二段32號",
    "電話": "(04)8221714",
    "網址": "http://www.ysps.chc.edu.tw"
  },
  {
    "代碼": "074696",
    "學校名稱": "縣立福興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[512]彰化縣永靖鄉福興村永福路二段160號",
    "電話": "(04)8231023",
    "網址": "http://www.sfses.chc.edu.tw"
  },
  {
    "代碼": "074697",
    "學校名稱": "縣立德興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[512]彰化縣永靖鄉竹子村竹後巷47號",
    "電話": "(04)8222158",
    "網址": "http://www.sdsps.chc.edu.tw"
  },
  {
    "代碼": "074698",
    "學校名稱": "縣立田中國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[520]彰化縣田中鎮南路里中州路177號",
    "電話": "(04)8742013",
    "網址": "http://163.23.95.145/xoops/html/"
  },
  {
    "代碼": "074699",
    "學校名稱": "縣立三潭國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[520]彰化縣田中鎮北路里員集路二段715號",
    "電話": "(04)8742254",
    "網址": "http://www.stes.chc.edu.tw"
  },
  {
    "代碼": "074700",
    "學校名稱": "縣立大安國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[520]彰化縣田中鎮三安里大安路一段200號",
    "電話": "(04)8742327",
    "網址": "http://www.daes.chc.edu.tw"
  },
  {
    "代碼": "074701",
    "學校名稱": "縣立內安國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[520]彰化縣田中鎮新庄里中南路三段54號",
    "電話": "(04)8742252",
    "網址": "http://www.naes.chc.edu.tw"
  },
  {
    "代碼": "074702",
    "學校名稱": "縣立東和國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[520]彰化縣田中鎮復興里山腳路四段188巷109號",
    "電話": "(04)8742708",
    "網址": "http://www.dhps.chc.edu.tw"
  },
  {
    "代碼": "074703",
    "學校名稱": "縣立明禮國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[520]彰化縣田中鎮三光里民光路一段394號",
    "電話": "(04)8742344",
    "網址": "http://www.mles.chc.edu.tw"
  },
  {
    "代碼": "074704",
    "學校名稱": "縣立社頭國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[511]彰化縣社頭鄉社頭村社斗路一段258號",
    "電話": "(04)8732039",
    "網址": "http://www.stps.chc.edu.tw"
  },
  {
    "代碼": "074705",
    "學校名稱": "縣立橋頭國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[511]彰化縣社頭鄉僑頭村員集路三段528號",
    "電話": "(04)8731265",
    "網址": "http://www.ctps.chc.edu.tw"
  },
  {
    "代碼": "074706",
    "學校名稱": "縣立朝興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[511]彰化縣社頭鄉朝興村社石路91號",
    "電話": "(04)8732484",
    "網址": "http://163.23.95.65"
  },
  {
    "代碼": "074707",
    "學校名稱": "縣立清水國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[511]彰化縣社頭鄉清水村山腳路一段486號",
    "電話": "(04)8731285",
    "網址": "http://www.bcses.chc.edu.tw"
  },
  {
    "代碼": "074708",
    "學校名稱": "縣立湳雅國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[511]彰化縣社頭鄉湳雅村山腳路三段395號",
    "電話": "(04)8735007",
    "網址": "http://www.nyes.chc.edu.tw/nyes/index.htm"
  },
  {
    "代碼": "074709",
    "學校名稱": "縣立二水國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[530]彰化縣二水鄉文化村光文路119號",
    "電話": "(04)8792040",
    "網址": "http://www.eses.chc.edu.tw"
  },
  {
    "代碼": "074710",
    "學校名稱": "縣立復興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[530]彰化縣二水鄉復興村員集路五段472號",
    "電話": "(04)8745935",
    "網址": "http://www.fsses.chc.edu.tw"
  },
  {
    "代碼": "074711",
    "學校名稱": "縣立源泉國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[530]彰化縣二水鄉大園村員集路二段316號",
    "電話": "(04)8792304",
    "網址": "http://163.23.105.1"
  },
  {
    "代碼": "074712",
    "學校名稱": "縣立北斗國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[521]彰化縣北斗鎮新政里文苑路一段32號",
    "電話": "(04)8882008",
    "網址": "http://www.bdes.chc.edu.tw"
  },
  {
    "代碼": "074713",
    "學校名稱": "縣立萬來國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[521]彰化縣北斗鎮西德里文賢街35號",
    "電話": "(04)8882119",
    "網址": "http://www.wles.chc.edu.tw"
  },
  {
    "代碼": "074714",
    "學校名稱": "縣立螺青國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[521]彰化縣北斗鎮東光里斗中路712號",
    "電話": "(04)8882031",
    "網址": "http://www.rces.chc.edu.tw"
  },
  {
    "代碼": "074715",
    "學校名稱": "縣立大新國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[521]彰化縣北斗鎮大新里大新路556號",
    "電話": "(04)8882640",
    "網址": "http://tw.class.uschoolnet.com/class/?csid=css000000078487"
  },
  {
    "代碼": "074716",
    "學校名稱": "縣立螺陽國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[521]彰化縣北斗鎮西安里斗苑路二段150號",
    "電話": "(04)8882039",
    "網址": "http://www.ryes.chc.edu.tw"
  },
  {
    "代碼": "074717",
    "學校名稱": "縣立田尾國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[522]彰化縣田尾鄉饒平村中山路一段449號",
    "電話": "(04)8832745",
    "網址": "http://www.twps.chc.edu.tw"
  },
  {
    "代碼": "074718",
    "學校名稱": "縣立南鎮國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[522]彰化縣田尾鄉南鎮村光復路一段295號",
    "電話": "(04)8227962",
    "網址": "http://www.njes.chc.edu.tw"
  },
  {
    "代碼": "074719",
    "學校名稱": "縣立陸豐國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[522]彰化縣田尾鄉陸豐村民生路三段200號",
    "電話": "(04)8222563",
    "網址": "http://163.23.99.65/eweb/?home=lfes"
  },
  {
    "代碼": "074720",
    "學校名稱": "縣立仁豐國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[522]彰化縣田尾鄉新厝村中正路二段101號",
    "電話": "(04)8830347",
    "網址": "http://163.23.99.129/eweb/?home=ew00000000001"
  },
  {
    "代碼": "074721",
    "學校名稱": "縣立埤頭國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[523]彰化縣埤頭鄉埤頭村中南路145號",
    "電話": "(04)8922019",
    "網址": "http://163.23.105.139/xoops/"
  },
  {
    "代碼": "074722",
    "學校名稱": "縣立合興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[523]彰化縣埤頭鄉合興村文鄉路161號",
    "電話": "(04)8922030",
    "網址": "http://www.shses.chc.edu.tw"
  },
  {
    "代碼": "074723",
    "學校名稱": "縣立豐崙國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[523]彰化縣埤頭鄉豐崙村校前路50號",
    "電話": "(04)8922136",
    "網址": "http://www.fles.chc.edu.tw"
  },
  {
    "代碼": "074724",
    "學校名稱": "縣立芙朝國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[523]彰化縣埤頭鄉芙朝村芙朝路205號",
    "電話": "(04)8925057",
    "網址": "http://www.fces.chc.edu.tw"
  },
  {
    "代碼": "074725",
    "學校名稱": "縣立中和國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[523]彰化縣埤頭鄉中和村溪林路284號",
    "電話": "(04)8895768",
    "網址": "http://www.ches.chc.edu.tw"
  },
  {
    "代碼": "074726",
    "學校名稱": "縣立大湖國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[523]彰化縣埤頭鄉大湖村大湖路260號",
    "電話": "(04)8922009",
    "網址": "http://163.23.106.1/school/web/index.php"
  },
  {
    "代碼": "074727",
    "學校名稱": "縣立溪州國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[524]彰化縣溪州鄉尾厝村中山路三段451號",
    "電話": "(04)8895013",
    "網址": "http://www.sjps.chc.edu.tw"
  },
  {
    "代碼": "074728",
    "學校名稱": "縣立僑義國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[524]彰化縣溪州鄉溪厝村中山路二段326號",
    "電話": "(04)8892244",
    "網址": "http://www.cyes.chc.edu.tw"
  },
  {
    "代碼": "074729",
    "學校名稱": "縣立三條國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[524]彰化縣溪州鄉三條村中央路二段31號",
    "電話": "(04)8895080",
    "網址": "http://www.steps.chc.edu.tw"
  },
  {
    "代碼": "074730",
    "學校名稱": "縣立水尾國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[524]彰化縣溪州鄉水尾村太平路137號",
    "電話": "(04)8892442",
    "網址": "http://www.swes.chc.edu.tw"
  },
  {
    "代碼": "074731",
    "學校名稱": "縣立潮洋國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[524]彰化縣溪州鄉潮洋村陸軍路一段146號",
    "電話": "(04)8803059",
    "網址": "http://www.cyps.chc.edu.tw"
  },
  {
    "代碼": "074732",
    "學校名稱": "縣立成功國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[524]彰化縣溪州鄉成功村登山路二段365號",
    "電話": "(04)8802225",
    "網址": "http://www.cges.chc.edu.tw"
  },
  {
    "代碼": "074733",
    "學校名稱": "縣立圳寮國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[524]彰化縣溪州鄉圳寮村下樹巷29號",
    "電話": "(04)8802165",
    "網址": "http://www.jles.chc.edu.tw"
  },
  {
    "代碼": "074734",
    "學校名稱": "縣立大莊國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[524]彰化縣溪州鄉大庄村中橫巷1號",
    "電話": "(04)8802438",
    "網址": "http://www.djps.chc.edu.tw"
  },
  {
    "代碼": "074735",
    "學校名稱": "縣立南州國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[524]彰化縣溪州鄉溪州村公園路95號",
    "電話": "(04)8892224",
    "網址": "http://www.njps.chc.edu.tw"
  },
  {
    "代碼": "074736",
    "學校名稱": "縣立二林國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[526]彰化縣二林鎮東和里斗苑路五段22號",
    "電話": "(04)8960057",
    "網址": "http://www.elps.chc.edu.tw"
  },
  {
    "代碼": "074737",
    "學校名稱": "縣立興華國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[526]彰化縣二林鎮東興里竹林路三段393號",
    "電話": "(04)8962594",
    "網址": "http://www.shes.chc.edu.tw"
  },
  {
    "代碼": "074738",
    "學校名稱": "縣立中正國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[526]彰化縣二林鎮南光里建興街80號",
    "電話": "(04)8960145",
    "網址": "http://www.ccps.chc.edu.tw"
  },
  {
    "代碼": "074739",
    "學校名稱": "縣立育德國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[526]彰化縣二林鎮外竹里中南巷41之1號",
    "電話": "(04)8962574",
    "網址": "http://163.23.90.1"
  },
  {
    "代碼": "074740",
    "學校名稱": "縣立香田國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[526]彰化縣二林鎮香田里儒林路10之1號",
    "電話": "(04)8960654",
    "網址": "http://www.sstes.chc.edu.tw"
  },
  {
    "代碼": "074741",
    "學校名稱": "縣立廣興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[526]彰化縣二林鎮廣興里廣興巷2之1號",
    "電話": "(04)8962584",
    "網址": "http://163.23.119.1/"
  },
  {
    "代碼": "074742",
    "學校名稱": "縣立萬興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[526]彰化縣二林鎮永興里中央南街2號",
    "電話": "(04)8682759",
    "網址": "http://www.wsps.chc.edu.tw"
  },
  {
    "代碼": "074743",
    "學校名稱": "縣立新生國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[526]彰化縣二林鎮趙甲里鎮平巷59號",
    "電話": "(04)8964330",
    "網址": "http://www.sssps.chc.edu.tw"
  },
  {
    "代碼": "074744",
    "學校名稱": "縣立中興國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[526]彰化縣二林鎮華菕里光復路73號",
    "電話": "(04)8683458",
    "網址": "http://www.scses.chc.edu.tw"
  },
  {
    "代碼": "074745",
    "學校名稱": "縣立原斗國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[526]彰化縣二林鎮原斗里斗苑路一段865號",
    "電話": "(04)8902322",
    "網址": "http://www.ydps.chc.edu.tw"
  },
  {
    "代碼": "074746",
    "學校名稱": "縣立萬合國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[526]彰化縣二林鎮萬合里江山巷1號",
    "電話": "(04)8902164",
    "網址": "http://163.23.91.1"
  },
  {
    "代碼": "074747",
    "學校名稱": "縣立大城國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[527]彰化縣大城鄉大城村中平路176號",
    "電話": "(04)8941135",
    "網址": "http://www.dcps.chc.edu.tw"
  },
  {
    "代碼": "074748",
    "學校名稱": "縣立永光國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[527]彰化縣大城鄉公館村青埔路7之8號",
    "電話": "(04)8941640",
    "網址": "http://www.yges.chc.edu.tw"
  },
  {
    "代碼": "074749",
    "學校名稱": "縣立西港國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[527]彰化縣大城鄉東港村中央路2號",
    "電話": "(04)8941029",
    "網址": "http://www.sges.chc.edu.tw"
  },
  {
    "代碼": "074750",
    "學校名稱": "縣立美豐國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[527]彰化縣大城鄉三豐村東盛路1號",
    "電話": "(04)8941660",
    "網址": "http://www.mfes.chc.edu.tw"
  },
  {
    "代碼": "074751",
    "學校名稱": "縣立頂庄國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[527]彰化縣大城鄉頂庄村東厝路63之1號",
    "電話": "(04)8941659",
    "網址": "http://www.djes.chc.edu.tw"
  },
  {
    "代碼": "074752",
    "學校名稱": "縣立潭墘國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[527]彰化縣大城鄉潭墘村潭城路1號",
    "電話": "(04)8941009",
    "網址": "http://www.tcps.chc.edu.tw"
  },
  {
    "代碼": "074753",
    "學校名稱": "縣立竹塘國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[525]彰化縣竹塘鄉竹塘村文化街20號",
    "電話": "(04)8972039",
    "網址": "http://www.ctes.chc.edu.tw"
  },
  {
    "代碼": "074754",
    "學校名稱": "縣立田頭國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[525]彰化縣竹塘鄉田頭村光明路60號",
    "電話": "(04)8972189",
    "網址": "http://www.ttes.chc.edu.tw"
  },
  {
    "代碼": "074755",
    "學校名稱": "縣立民靖國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[525]彰化縣竹塘鄉民靖村文昌路152號",
    "電話": "(04)8972693",
    "網址": "http://tw.school.uschoolnet.com/?id=es00001553"
  },
  {
    "代碼": "074756",
    "學校名稱": "縣立長安國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[525]彰化縣竹塘鄉長安村東陽路二段123號",
    "電話": "(04)8972694",
    "網址": "http://www.caes.chc.edu.tw"
  },
  {
    "代碼": "074757",
    "學校名稱": "縣立土庫國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[525]彰化縣竹塘鄉土庫村東陽路一段362號",
    "電話": "(04)8972140",
    "網址": "http://www.tkes.chc.edu.tw"
  },
  {
    "代碼": "074758",
    "學校名稱": "縣立芳苑國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[528]彰化縣芳苑鄉芳苑村斗苑路芳苑段230號",
    "電話": "(04)8983993",
    "網址": "http://www.fyes.chc.edu.tw"
  },
  {
    "代碼": "074759",
    "學校名稱": "縣立後寮國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[528]彰化縣芳苑鄉後寮村芳寮路30號",
    "電話": "(04)8983184",
    "網址": "http://www.hles.chc.edu.tw"
  },
  {
    "代碼": "074760",
    "學校名稱": "縣立民權華德福實驗國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[528]彰化縣芳苑鄉五俊村新生巷5之1號",
    "電話": "(04)8932625",
    "網址": "http://www.mces.chc.edu.tw"
  },
  {
    "代碼": "074761",
    "學校名稱": "縣立育華國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[528]彰化縣芳苑鄉永興村太平路300號",
    "電話": "(04)8933261",
    "網址": "http://www.yhes.chc.edu.tw"
  },
  {
    "代碼": "074762",
    "學校名稱": "縣立草湖國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[528]彰化縣芳苑鄉文津村芳草路66號",
    "電話": "(04)8933327",
    "網址": "http://www.thes.chc.edu.tw"
  },
  {
    "代碼": "074763",
    "學校名稱": "縣立建新國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[528]彰化縣芳苑鄉新生村草漢路二段86號",
    "電話": "(04)8862328",
    "網址": "http://www.jses.chc.edu.tw"
  },
  {
    "代碼": "074764",
    "學校名稱": "縣立漢寶國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[528]彰化縣芳苑鄉漢寶村草漢路漢寶段541號",
    "電話": "(04)8991062",
    "網址": "http://www.hbes.chc.edu.tw"
  },
  {
    "代碼": "074765",
    "學校名稱": "縣立王功國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[528]彰化縣芳苑鄉王功村功湖路王功段481號",
    "電話": "(04)8932182",
    "網址": "https://sites.google.com/site/zhanghuawanggongguoxiao"
  },
  {
    "代碼": "074766",
    "學校名稱": "縣立新寶國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[528]彰化縣芳苑鄉芳漢路129巷100號",
    "電話": "(04)8932885",
    "網址": "http://www.sbes.chc.edu.tw"
  },
  {
    "代碼": "074767",
    "學校名稱": "縣立路上國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[528]彰化縣芳苑鄉路上村新上路12號",
    "電話": "(04)8982014",
    "網址": "http://www.lses.chc.edu.tw"
  },
  {
    "代碼": "074769",
    "學校名稱": "縣立和仁國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[508]彰化縣和美鎮山犁里15鄰仁愛路279號",
    "電話": "(04)7575407",
    "網址": "http://www.hres.chc.edu.tw"
  },
  {
    "代碼": "074771",
    "學校名稱": "縣立鹿東國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[505]彰化縣鹿港鎮長安路125號",
    "電話": "(04)7756521",
    "網址": "https://sites.google.com/a/st.ldes.chc.edu.tw/ldes2017"
  },
  {
    "代碼": "074772",
    "學校名稱": "縣立舊社國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[511]彰化縣社頭鄉社石路956號",
    "電話": "(04)8711001",
    "網址": "http://www.csnes.chc.edu.tw"
  },
  {
    "代碼": "074773",
    "學校名稱": "縣立崙雅國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[511]彰化縣社頭鄉美雅村員集路一段615號",
    "電話": "(04)8710945",
    "網址": "http://www.lyps.chc.edu.tw"
  },
  {
    "代碼": "074774",
    "學校名稱": "縣立信義國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市向陽里向陽街168號",
    "電話": "(04)7635888",
    "網址": "http://www.hyjhes.chc.edu.tw"
  },
  {
    "代碼": "074775",
    "學校名稱": "縣立大成國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[500]彰化縣彰化市自強路303號",
    "電話": "(04)7353457",
    "網址": "http://www.dches.chc.edu.tw"
  },
  {
    "代碼": "074776",
    "學校名稱": "縣立新民國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[520]彰化縣田中鎮新民里公館路320號",
    "電話": "(04)8756166",
    "網址": "http://www.smps.chc.edu.tw"
  },
  {
    "代碼": "074777",
    "學校名稱": "縣立湖北國小",
    "公/私立": "公立",
    "縣市名稱": "[07]彰化縣",
    "地址": "[514]彰化縣溪湖鎮太平里地政路二段201號",
    "電話": "(04)8613618",
    "網址": "http://www.hbps.chc.edu.tw"
  },
  {
    "代碼": "081601",
    "學校名稱": "私立普台國小",
    "公/私立": "私立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮中台路3號",
    "電話": "(049)2930199",
    "網址": "http://putai.org"
  },
  {
    "代碼": "081602",
    "學校名稱": "私立均頭國(中)小",
    "公/私立": "私立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮水頭里4鄰水頭路48號",
    "電話": "(049)2980723",
    "網址": "http://www.jtjhs.ntct.edu.tw"
  },
  {
    "代碼": "084601",
    "學校名稱": "縣立南投國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市彰南路一段1059號",
    "電話": "(049)2222038",
    "網址": "http://www.ntes.ntct.edu.tw"
  },
  {
    "代碼": "084602",
    "學校名稱": "縣立平和國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市平和里育樂路62號",
    "電話": "(049)2222028",
    "網址": "http://www.phes.ntct.edu.tw"
  },
  {
    "代碼": "084603",
    "學校名稱": "縣立新豐國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市彰南路三段221號",
    "電話": "(049)2251804",
    "網址": "http://www.sfes.ntct.edu.tw/"
  },
  {
    "代碼": "084604",
    "學校名稱": "縣立營盤國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市營盤路136號",
    "電話": "(049)2335882",
    "網址": "http://www.epps.ntct.edu.tw"
  },
  {
    "代碼": "084605",
    "學校名稱": "縣立西嶺國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市鳳鳴里八卦路894號",
    "電話": "(049)2291354",
    "網址": "http://www.selnps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084606",
    "學校名稱": "縣立德興國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市內興里中興路200號",
    "電話": "(049)2359050",
    "網址": "http://www.tses.ntct.edu.tw"
  },
  {
    "代碼": "084607",
    "學校名稱": "縣立光華國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市中興新村光華四路2號",
    "電話": "(049)2332549",
    "網址": "http://www.ghps.ntct.edu.tw"
  },
  {
    "代碼": "084608",
    "學校名稱": "縣立光榮國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市中興新村光榮北路19號",
    "電話": "(049)2332550",
    "網址": "http://www.kzps.ntct.edu.tw"
  },
  {
    "代碼": "084609",
    "學校名稱": "縣立文山國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市福山里八卦路349號",
    "電話": "(049)2291394",
    "網址": "http://www.wsps.ntct.edu.tw"
  },
  {
    "代碼": "084610",
    "學校名稱": "縣立僑建國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市彰南路三段847號",
    "電話": "(049)2253245",
    "網址": "http://www.chjps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084611",
    "學校名稱": "縣立漳和國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市文林路16號",
    "電話": "(049)2224087",
    "網址": "http://www.ntchps.ntct.edu.tw"
  },
  {
    "代碼": "084612",
    "學校名稱": "縣立嘉和國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市彰南路一段639巷80號",
    "電話": "(049)2222430",
    "網址": "http://www.jhops.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084613",
    "學校名稱": "縣立光復國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市光明里光明一路67號",
    "電話": "(049)2332587",
    "網址": "http://www.kfups.ntct.edu.tw"
  },
  {
    "代碼": "084614",
    "學校名稱": "縣立千秋國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市千秋里千秋路131巷1號",
    "電話": "(049)2229405",
    "網址": "http://www.ccps.ntct.edu.tw"
  },
  {
    "代碼": "084615",
    "學校名稱": "縣立埔里國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮西門里西康路127號",
    "電話": "(049)2982034",
    "網址": "http://www.ples.ntct.edu.tw"
  },
  {
    "代碼": "084616",
    "學校名稱": "縣立南光國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮中正路251號",
    "電話": "(049)2982025",
    "網址": "http://www.nkps.ntct.edu.tw"
  },
  {
    "代碼": "084617",
    "學校名稱": "縣立育英國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮清新里育英街20號",
    "電話": "(049)2982144",
    "網址": "http://www.yyes.ntct.edu.tw"
  },
  {
    "代碼": "084618",
    "學校名稱": "縣立史港國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮史港里獅子路9號",
    "電話": "(049)2931521",
    "網址": "http://www.skps.ntct.edu.tw"
  },
  {
    "代碼": "084619",
    "學校名稱": "縣立愛蘭國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮愛蘭里鐵山路7號",
    "電話": "(049)2912514",
    "網址": "http://www.alps.ntct.edu.tw"
  },
  {
    "代碼": "084620",
    "學校名稱": "縣立溪南國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮溪南里珠生路68號",
    "電話": "(049)2904517",
    "網址": "http://www.snps.ntct.edu.tw"
  },
  {
    "代碼": "084621",
    "學校名稱": "縣立水尾國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮一新里永豐路92號",
    "電話": "(049)2932506",
    "網址": "http://www.sups.ntct.edu.tw"
  },
  {
    "代碼": "084622",
    "學校名稱": "縣立桃源國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮桃米里桃米巷68號",
    "電話": "(049)2913450",
    "網址": "http://www.twps.ntct.edu.tw"
  },
  {
    "代碼": "084623",
    "學校名稱": "縣立麒麟國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮麒麟里武界路7號",
    "電話": "(049)2925941",
    "網址": "http://www.klps.ntct.edu.tw"
  },
  {
    "代碼": "084624",
    "學校名稱": "縣立太平國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮合成里西安路三段167巷35號",
    "電話": "(049)2931385",
    "網址": "http://163.22.96.133/school/"
  },
  {
    "代碼": "084625",
    "學校名稱": "縣立忠孝國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮牛眠里守城路28號",
    "電話": "(049)2931582",
    "網址": "http://www.csops.ntct.edu.tw"
  },
  {
    "代碼": "084626",
    "學校名稱": "縣立中峰國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮中山路一段228號",
    "電話": "(049)2982542",
    "網址": "http://www.cfops.ntct.edu.tw"
  },
  {
    "代碼": "084627",
    "學校名稱": "縣立大成國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[545]南投縣埔里鎮中山路三段565號",
    "電話": "(049)2916517",
    "網址": "http://163.22.51.1/mdpro/"
  },
  {
    "代碼": "084629",
    "學校名稱": "縣立草屯國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮玉峰里玉屏路210號",
    "電話": "(049)2362007",
    "網址": "http://www.ttes.ntct.edu.tw"
  },
  {
    "代碼": "084630",
    "學校名稱": "縣立敦和國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮敦和路72-6號",
    "電話": "(049)2365074",
    "網址": "http://www.dhps.ntct.edu.tw"
  },
  {
    "代碼": "084631",
    "學校名稱": "縣立新庄國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮新庄里新庄三路32號",
    "電話": "(049)2333104",
    "網址": "http://www.xzps.ntct.edu.tw"
  },
  {
    "代碼": "084632",
    "學校名稱": "縣立碧峰國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮碧峰里立人路439號",
    "電話": "(049)2334304",
    "網址": "http://www.pfps.ntct.edu.tw"
  },
  {
    "代碼": "084633",
    "學校名稱": "縣立土城國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮土城里中正路189號",
    "電話": "(049)2552024",
    "網址": "http://163.22.91.1"
  },
  {
    "代碼": "084634",
    "學校名稱": "縣立雙冬國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮雙冬里中正路34號",
    "電話": "(049)2571362",
    "網址": "http://www.stps.ntct.edu.tw"
  },
  {
    "代碼": "084635",
    "學校名稱": "縣立炎峰國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮中興路101號",
    "電話": "(049)2333221",
    "網址": "http://www.unfps.ntct.edu.tw"
  },
  {
    "代碼": "084636",
    "學校名稱": "縣立中原國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮中原里坪腳巷20號",
    "電話": "(049)2554631",
    "網址": "http://www.cyes.ntct.edu.tw"
  },
  {
    "代碼": "084637",
    "學校名稱": "縣立平林國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮健行路65巷90號",
    "電話": "(049)2571761",
    "網址": "http://www.plps.ntct.edu.tw"
  },
  {
    "代碼": "084638",
    "學校名稱": "縣立坪頂國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮坪頂里南坪路40號",
    "電話": "(049)2552074",
    "網址": "http://www.ptips.ntct.edu.tw"
  },
  {
    "代碼": "084639",
    "學校名稱": "縣立僑光國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮稻香路20-2號",
    "電話": "(049)2333684",
    "網址": "http://www.cges.ntct.edu.tw"
  },
  {
    "代碼": "084640",
    "學校名稱": "縣立北投國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮北投里文教巷43號",
    "電話": "(049)2333614",
    "網址": "http://www.ptps.ntct.edu.tw"
  },
  {
    "代碼": "084641",
    "學校名稱": "縣立富功國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮富寮里中正路567號",
    "電話": "(049)2569213",
    "網址": "http://www.fkps.ntct.edu.tw"
  },
  {
    "代碼": "084642",
    "學校名稱": "縣立竹山國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[557]南投縣竹山鎮延和里向學街32號",
    "電話": "(049)2642016",
    "網址": "http://www.cses.ntct.edu.tw"
  },
  {
    "代碼": "084643",
    "學校名稱": "縣立延平國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[557]南投縣竹山鎮集山路二段1161號",
    "電話": "(049)2642450",
    "網址": "http://www.wpps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084644",
    "學校名稱": "縣立社寮國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[557]南投縣竹山鎮集山路一段1723號",
    "電話": "(049)2622020",
    "網址": "http://www.silps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084645",
    "學校名稱": "縣立過溪國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[557]南投縣竹山鎮福興里鯉南路136號",
    "電話": "(049)2642573",
    "網址": "http://www.kosps.ntct.edu.tw"
  },
  {
    "代碼": "084646",
    "學校名稱": "縣立大鞍國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[557]南投縣竹山鎮大鞍里竹寮巷21號",
    "電話": "(049)2841009",
    "網址": "http://www.daps.ntct.edu.tw"
  },
  {
    "代碼": "084647",
    "學校名稱": "縣立瑞竹國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[557]南投縣竹山鎮瑞竹里鯉南里251號",
    "電話": "(049)2711024",
    "網址": "http://www.zcps.ntct.edu.tw"
  },
  {
    "代碼": "084648",
    "學校名稱": "縣立秀林國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[557]南投縣竹山鎮頂林路456號",
    "電話": "(049)2647285",
    "網址": "http://a95.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084649",
    "學校名稱": "縣立雲林國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[557]南投縣竹山鎮雲林里大明路666號",
    "電話": "(049)2643321",
    "網址": "http://ms1.ylps.ntct.edu.tw"
  },
  {
    "代碼": "084650",
    "學校名稱": "縣立鯉魚國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[557]南投縣竹山鎮鯉魚里鯉行路92號",
    "電話": "(049)2644984",
    "網址": "http://www.liwps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084651",
    "學校名稱": "縣立桶頭國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[557]南投縣竹山鎮鯉南路312之1號",
    "電話": "(049)2711034",
    "網址": "http://www.ttps.ntct.edu.tw"
  },
  {
    "代碼": "084652",
    "學校名稱": "縣立中州國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[557]南投縣竹山鎮集山路一段955號",
    "電話": "(049)2622030",
    "網址": "http://www.cchps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084653",
    "學校名稱": "縣立中和國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[557]南投縣竹山鎮集山路三段1394號",
    "電話": "(049)2642424",
    "網址": "http://www.chops.ntct.edu.tw"
  },
  {
    "代碼": "084655",
    "學校名稱": "縣立集集國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[552]南投縣集集鎮育才街147號",
    "電話": "(049)2762027",
    "網址": "http://www.jjps.ntct.edu.tw"
  },
  {
    "代碼": "084656",
    "學校名稱": "縣立隘寮國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[552]南投縣集集鎮田寮里田寮巷4號",
    "電話": "(049)2781154",
    "網址": "http://www.ailps.ntct.edu.tw"
  },
  {
    "代碼": "084657",
    "學校名稱": "縣立永昌國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[552]南投縣集集鎮永昌里東昌巷4號",
    "電話": "(049)2762524",
    "網址": "http://www.ycps.ntct.edu.tw/"
  },
  {
    "代碼": "084658",
    "學校名稱": "縣立和平國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[552]南投縣集集鎮和平里集集街178號",
    "電話": "(049)2762036",
    "網址": "http://www.hpps.ntct.edu.tw"
  },
  {
    "代碼": "084660",
    "學校名稱": "縣立名間國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[551]南投縣名間鄉中山村彰南路220號",
    "電話": "(049)2732024",
    "網址": "http://www.mces.ntct.edu.tw/"
  },
  {
    "代碼": "084661",
    "學校名稱": "縣立新街國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[551]南投縣名間鄉新街村彰南路375號",
    "電話": "(049)2223534",
    "網址": "http://www.sjps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084662",
    "學校名稱": "縣立名崗國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[551]南投縣名間鄉大坑村南田路222號",
    "電話": "(049)2271864",
    "網址": "http://163.22.109.1"
  },
  {
    "代碼": "084663",
    "學校名稱": "縣立中山國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[551]南投縣名間鄉名松路一段294號",
    "電話": "(049)2582335",
    "網址": "http://www.csps.ntct.edu.tw"
  },
  {
    "代碼": "084664",
    "學校名稱": "縣立弓鞋國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[551]南投縣名間鄉名松路二段580號",
    "電話": "(049)2581322",
    "網址": "http://www.khps.ntct.edu.tw"
  },
  {
    "代碼": "084665",
    "學校名稱": "縣立田豐國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[551]南投縣名間鄉田仔村田仔巷40號",
    "電話": "(049)2271509",
    "網址": "http://www.tfps.ntct.edu.tw"
  },
  {
    "代碼": "084666",
    "學校名稱": "縣立僑興國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[551]南投縣名間鄉東湖村彰南路259號",
    "電話": "(049)2732340",
    "網址": "http://www.chseps.ntct.edu.tw"
  },
  {
    "代碼": "084667",
    "學校名稱": "縣立新民國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[551]南投縣名間鄉新民村新民巷43號",
    "電話": "(049)2732514",
    "網址": "http://www.smmups.ntct.edu.tw"
  },
  {
    "代碼": "084668",
    "學校名稱": "縣立鹿谷國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[558]南投縣鹿谷鄉鹿谷村中正路2段174號",
    "電話": "(049)2752048",
    "網址": "http://www.lkps.ntct.edu.tw"
  },
  {
    "代碼": "084669",
    "學校名稱": "縣立秀峰國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[558]南投縣鹿谷鄉秀峰村仁愛路152號",
    "電話": "(049)2671953",
    "網址": "http://www.sfps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084670",
    "學校名稱": "縣立文昌國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[558]南投縣鹿谷鄉竹林村光復路96號",
    "電話": "(049)2676305",
    "網址": "http://www.wces.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084671",
    "學校名稱": "縣立鳳凰國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[558]南投縣鹿谷鄉鳳凰村仁義路35之1號",
    "電話": "(049)2752214",
    "網址": "http://www.fwps.ntct.edu.tw"
  },
  {
    "代碼": "084672",
    "學校名稱": "縣立內湖國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[558]南投縣鹿谷鄉內湖村興產路51號",
    "電話": "(049)2752437",
    "網址": "http://www.nwps.ntct.edu.tw"
  },
  {
    "代碼": "084673",
    "學校名稱": "縣立初鄉國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[558]南投縣鹿谷鄉初鄉村仁愛路259號",
    "電話": "(049)2752513",
    "網址": "http://www.ches.ntct.edu.tw"
  },
  {
    "代碼": "084674",
    "學校名稱": "縣立瑞田國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[558]南投縣鹿谷鄉瑞田村仁愛路80號",
    "電話": "(049)2671284",
    "網址": "http://www.ztps.ntct.edu.tw"
  },
  {
    "代碼": "084676",
    "學校名稱": "縣立廣興國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[558]南投縣鹿谷鄉廣興村興產路97號",
    "電話": "(049)2752014",
    "網址": "http://www.knsps.ntct.edu.tw"
  },
  {
    "代碼": "084677",
    "學校名稱": "縣立中寮國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[541]南投縣中寮鄉永平村永平路316號",
    "電話": "(049)2691322",
    "網址": "http://www.clinps.ntct.edu.tw"
  },
  {
    "代碼": "084678",
    "學校名稱": "縣立爽文國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[541]南投縣中寮鄉爽文村龍南路159號",
    "電話": "(049)2601003",
    "網址": "http://www.swps.ntct.edu.tw"
  },
  {
    "代碼": "084679",
    "學校名稱": "縣立永樂國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[541]南投縣中寮鄉福盛村永樂路88號",
    "電話": "(049)2691473",
    "網址": "http://www.ulps.ntct.edu.tw"
  },
  {
    "代碼": "084680",
    "學校名稱": "縣立永康國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[541]南投縣中寮鄉永福村仙峰巷44號",
    "電話": "(049)2691455",
    "網址": "http://www.ukps.ntct.edu.tw"
  },
  {
    "代碼": "084681",
    "學校名稱": "縣立清水國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[541]南投縣中寮鄉清水村瀧林巷3號",
    "電話": "(049)2601079",
    "網址": "http://www.chs.ntct.edu.tw"
  },
  {
    "代碼": "084682",
    "學校名稱": "縣立至誠國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[541]南投縣中寮鄉永平路489之1號",
    "電話": "(049)2227215",
    "網址": "http://www.zcu.ntct.edu.tw"
  },
  {
    "代碼": "084683",
    "學校名稱": "縣立永和國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[541]南投縣中寮鄉龍南路321之1號",
    "電話": "(049)2601068",
    "網址": "http://www.uhps.ntct.edu.tw"
  },
  {
    "代碼": "084684",
    "學校名稱": "縣立廣福國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[541]南投縣中寮鄉廣福村內城巷3之2號",
    "電話": "(049)2691464",
    "網址": "http://www.kfps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084687",
    "學校名稱": "縣立魚池國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[555]南投縣魚池鄉魚池村瓊文巷41號",
    "電話": "(049)2895537",
    "網址": "http://www.yces.ntct.edu.tw"
  },
  {
    "代碼": "084688",
    "學校名稱": "縣立頭社國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[555]南投縣魚池鄉頭社村平和巷105號",
    "電話": "(049)2861237",
    "網址": "http://www.tsps.ntct.edu.tw"
  },
  {
    "代碼": "084689",
    "學校名稱": "縣立東光國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[555]南投縣魚池鄉東光村慶隆巷36號",
    "電話": "(049)2880584",
    "網址": "http://www.tkps.ntct.edu.tw"
  },
  {
    "代碼": "084690",
    "學校名稱": "縣立五城國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[555]南投縣魚池鄉五城村華龍巷1之2號",
    "電話": "(049)2896455",
    "網址": "http://www.wcps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084691",
    "學校名稱": "縣立明潭國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[555]南投縣魚池鄉水社村中山路190號",
    "電話": "(049)2855045",
    "網址": "http://www.mtps.ntct.edu.tw"
  },
  {
    "代碼": "084693",
    "學校名稱": "縣立新城國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[555]南投縣魚池鄉新城村通文巷7之1號",
    "電話": "(049)2895724",
    "網址": "http://www.scps.ntct.edu.tw"
  },
  {
    "代碼": "084694",
    "學校名稱": "縣立伊達邵國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[555]南投縣魚池鄉日月村中正路211巷1號",
    "電話": "(049)2850441",
    "網址": "http://www.dchps.ntct.edu.tw"
  },
  {
    "代碼": "084695",
    "學校名稱": "縣立共和國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[555]南投縣魚池鄉共和村五馬巷57之1號",
    "電話": "(049)2880608",
    "網址": "http://www.khrps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084696",
    "學校名稱": "縣立國姓國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[544]南投縣國姓鄉石門村國姓路311號",
    "電話": "(049)2721018",
    "網址": "http://www.ksps.ntct.edu.tw"
  },
  {
    "代碼": "084697",
    "學校名稱": "縣立北山國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[544]南投縣國姓鄉中正路4段119-1號",
    "電話": "(049)2451935",
    "網址": "http://www.psps.ntct.edu.tw"
  },
  {
    "代碼": "084698",
    "學校名稱": "縣立北港國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[544]南投縣國姓鄉北港村國姓路63號",
    "電話": "(049)2461345",
    "網址": "http://www.bkps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084699",
    "學校名稱": "縣立福龜國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[544]南投縣國姓鄉福龜村長壽巷83號",
    "電話": "(049)2721019",
    "網址": "http://www.vkps.ntct.edu.tw"
  },
  {
    "代碼": "084700",
    "學校名稱": "縣立長流國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[544]南投縣國姓鄉長流村大長路559號",
    "電話": "(049)2431006",
    "網址": "http://www.clps.ntct.edu.tw"
  },
  {
    "代碼": "084701",
    "學校名稱": "縣立南港國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[544]南投縣國姓鄉南港路112之8號",
    "電話": "(049)2451937",
    "網址": "http://www.nkes.ntct.edu.tw"
  },
  {
    "代碼": "084702",
    "學校名稱": "縣立育樂國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[544]南投縣國姓鄉柑林村中正路2段223號",
    "電話": "(049)2721274",
    "網址": "http://www.wlps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084703",
    "學校名稱": "縣立港源國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[544]南投縣國姓鄉南港村港頭巷60號",
    "電話": "(049)2451933",
    "網址": "http://www.kwps.ntct.edu.tw"
  },
  {
    "代碼": "084704",
    "學校名稱": "縣立長福國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[544]南投縣國姓鄉長福村大長路71號",
    "電話": "(049)2431295",
    "網址": "http://www.cfups.ntct.edu.tw"
  },
  {
    "代碼": "084705",
    "學校名稱": "縣立乾峰國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[544]南投縣國姓鄉乾溝村中西巷3號",
    "電話": "(049)2721385",
    "網址": "http://www.cfps.ntct.edu.tw"
  },
  {
    "代碼": "084706",
    "學校名稱": "縣立水里國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[553]南投縣水里鄉南光村民族路151號",
    "電話": "(049)2770014",
    "網址": "http://www.slps.ntct.edu.tw"
  },
  {
    "代碼": "084707",
    "學校名稱": "縣立郡坑國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[553]南投縣水里鄉上安村水信路3段303巷8號",
    "電話": "(049)2821421",
    "網址": "http://www.gkps.ntct.edu.tw"
  },
  {
    "代碼": "084708",
    "學校名稱": "縣立民和國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[553]南投縣水里鄉民和村文明路70號",
    "電話": "(049)2741140",
    "網址": "http://www.mhps.ntct.edu.tw"
  },
  {
    "代碼": "084709",
    "學校名稱": "縣立新興國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[553]南投縣水里鄉新興村新興巷15號",
    "電話": "(049)2811187",
    "網址": "http://www.sseps.ntct.edu.tw/"
  },
  {
    "代碼": "084711",
    "學校名稱": "縣立永興國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[553]南投縣水里鄉永興村林朋巷148號",
    "電話": "(049)2772545",
    "網址": "http://www.usps.ntct.edu.tw"
  },
  {
    "代碼": "084714",
    "學校名稱": "縣立成城國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[553]南投縣水里鄉中山路二段150號",
    "電話": "(049)2770140",
    "網址": "http://www.cchups.ntct.edu.tw"
  },
  {
    "代碼": "084716",
    "學校名稱": "縣立信義國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉明德村玉山路24號",
    "電話": "(049)2791263",
    "網址": "http://www.hips.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084717",
    "學校名稱": "縣立羅娜國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉羅娜村信義巷一鄰73號",
    "電話": "(049)2831327",
    "網址": "http://www.lnps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084718",
    "學校名稱": "縣立同富國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉同富村同和巷4號",
    "電話": "(049)2701503",
    "網址": "http://www.tfups.ntct.edu.tw"
  },
  {
    "代碼": "084719",
    "學校名稱": "縣立愛國國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉愛國村愛國巷108號",
    "電話": "(049)2791465",
    "網址": "http://www.akps.ntct.edu.tw"
  },
  {
    "代碼": "084720",
    "學校名稱": "縣立人和國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉人和村民生巷3號",
    "電話": "(049)2773259",
    "網址": "http://www.zhes.ntct.edu.tw"
  },
  {
    "代碼": "084721",
    "學校名稱": "縣立地利國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉地利村開信巷17號",
    "電話": "(049)2741254",
    "網址": "http://www.dlps.ntct.edu.tw"
  },
  {
    "代碼": "084722",
    "學校名稱": "縣立東埔國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉東埔村開高巷63號",
    "電話": "(049)2701340",
    "網址": "http://www.tpups.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084724",
    "學校名稱": "縣立潭南國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉潭南村和平巷49號",
    "電話": "(049)2741381",
    "網址": "http://www.tnps.ntct.edu.tw"
  },
  {
    "代碼": "084727",
    "學校名稱": "縣立桐林國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉同富村太平巷18號",
    "電話": "(049)2701483",
    "網址": "http://www.tlps.ntct.edu.tw"
  },
  {
    "代碼": "084728",
    "學校名稱": "縣立隆華國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉神木村民和巷65-6號",
    "電話": "(049)2701478",
    "網址": "http://a164.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084729",
    "學校名稱": "縣立新鄉國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉新鄉村80號",
    "電話": "(049)2831591",
    "網址": "http://a165.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084730",
    "學校名稱": "縣立久美國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉望美村美信巷54號",
    "電話": "(049)2831506",
    "網址": "http://www.loxa.edu.tw/schoolweb.html?webId=581"
  },
  {
    "代碼": "084731",
    "學校名稱": "縣立雙龍國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉雙龍村光復巷4號",
    "電話": "(049)2741325",
    "網址": "http://www.sles.ntct.edu.tw"
  },
  {
    "代碼": "084732",
    "學校名稱": "縣立豐丘國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[556]南投縣信義鄉豐丘村高平巷77號",
    "電話": "(049)2791723",
    "網址": "http://www.fcps.ntct.edu.tw"
  },
  {
    "代碼": "084733",
    "學校名稱": "縣立仁愛國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉大同村山農巷5號",
    "電話": "(049)2802373",
    "網址": "http://www.raes.ntct.edu.tw"
  },
  {
    "代碼": "084734",
    "學校名稱": "縣立親愛國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉親愛村高平路3號",
    "電話": "(049)2974033",
    "網址": "http://www.caps.ntct.edu.tw"
  },
  {
    "代碼": "084735",
    "學校名稱": "縣立法治國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉法治村界山巷6號",
    "電話": "(049)2977061",
    "網址": "http://www.fzps.ntct.edu.tw"
  },
  {
    "代碼": "084736",
    "學校名稱": "縣立合作國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉合作村平生路23號",
    "電話": "(049)2970063",
    "網址": "http://www.hzps.ntct.edu.tw"
  },
  {
    "代碼": "084737",
    "學校名稱": "縣立互助國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉互助村中華路19號",
    "電話": "(049)2941016",
    "網址": "http://www.hgps.ntct.edu.tw/"
  },
  {
    "代碼": "084738",
    "學校名稱": "縣立力行國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉力行村新望洋67號",
    "電話": "(049)2955078",
    "網址": "http://www.lhes.ntct.edu.tw"
  },
  {
    "代碼": "084739",
    "學校名稱": "縣立南豐國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉南豐村楓林路3號",
    "電話": "(049)2920037",
    "網址": "http://www.nfps.ntct.edu.tw"
  },
  {
    "代碼": "084740",
    "學校名稱": "縣立中正國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉中正村平等巷114號",
    "電話": "(049)2925114",
    "網址": "http://www.ccheps.ntct.edu.tw"
  },
  {
    "代碼": "084741",
    "學校名稱": "縣立廬山國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉精英村中華巷19號",
    "電話": "(049)2970385",
    "網址": "http://a175.ntct.edu.tw"
  },
  {
    "代碼": "084742",
    "學校名稱": "縣立發祥國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉發祥村光復巷24號",
    "電話": "(049)2955030",
    "網址": "http://www.fsnps.ntct.edu.tw"
  },
  {
    "代碼": "084743",
    "學校名稱": "縣立萬豐國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉萬豐村清華巷8號",
    "電話": "(049)2974123",
    "網址": "http://www.wfps.ntct.edu.tw"
  },
  {
    "代碼": "084744",
    "學校名稱": "縣立都達國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉都達村法觀路17號",
    "電話": "(049)2970053",
    "網址": "http://www.tdps.ntct.edu.tw/"
  },
  {
    "代碼": "084745",
    "學校名稱": "縣立春陽國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉春陽村永樂巷58號",
    "電話": "(049)2802573",
    "網址": "http://www.cwps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084746",
    "學校名稱": "縣立紅葉國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉發祥村仁盛路70號",
    "電話": "(049)2955075",
    "網址": "http://www.hyps.ntct.edu.tw"
  },
  {
    "代碼": "084747",
    "學校名稱": "縣立清境國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[546]南投縣仁愛鄉定遠新村24號",
    "電話": "(049)2802572",
    "網址": "http://www.cgps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084748",
    "學校名稱": "縣立漳興國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市復興路669號",
    "電話": "(049)2224013",
    "網址": "http://www.chps.ntct.edu.tw"
  },
  {
    "代碼": "084749",
    "學校名稱": "縣立虎山國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[542]南投縣草屯鎮新生路76號",
    "電話": "(049)2339107",
    "網址": "http://www.wsnps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "084750",
    "學校名稱": "縣立康壽國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[540]南投縣南投市南陽路269號",
    "電話": "(049)2243457",
    "網址": "http://www.kses.ntct.edu.tw"
  },
  {
    "代碼": "084751",
    "學校名稱": "縣立前山國小",
    "公/私立": "公立",
    "縣市名稱": "[08]南投縣",
    "地址": "[557]南投縣竹山鎮自強路100號",
    "電話": "(049)2660662",
    "網址": "http://www.csunps.ntct.edu.tw/bin/home.php"
  },
  {
    "代碼": "091601",
    "學校名稱": "私立維多利亞小學",
    "公/私立": "私立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市鎮南路1110號",
    "電話": "(05)5378899",
    "網址": "http://www.victoria.ylc.edu.tw"
  },
  {
    "代碼": "091602",
    "學校名稱": "私立福智國小",
    "公/私立": "私立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉麻園村平和21號",
    "電話": "(05)5828222",
    "網址": "http://bwes.ylc.edu.tw/"
  },
  {
    "代碼": "091603",
    "學校名稱": "私立福智實驗國小",
    "公/私立": "私立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉麻園村平和21號",
    "電話": "(05)5828222",
    "網址": ""
  },
  {
    "代碼": "094601",
    "學校名稱": "縣立鎮西國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市中和里西平路3號",
    "電話": "(05)5322047",
    "網址": "http://www.jces.ylc.edu.tw"
  },
  {
    "代碼": "094602",
    "學校名稱": "縣立鎮東國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市鎮東里文化路205號",
    "電話": "(05)5322700",
    "網址": "http://www.jdps.ylc.edu.tw"
  },
  {
    "代碼": "094603",
    "學校名稱": "縣立溝壩國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市溝壩里仁義路93號",
    "電話": "(05)5220734",
    "網址": "http://www.gbes.ylc.edu.tw"
  },
  {
    "代碼": "094604",
    "學校名稱": "縣立梅林國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市梅林里梅林路256號",
    "電話": "(05)5572211",
    "網址": "http://www.mlps.ylc.edu.tw"
  },
  {
    "代碼": "094605",
    "學校名稱": "縣立石榴國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市榴南里南仁路51號",
    "電話": "(05)5573386",
    "網址": "http://www.slps.ylc.edu.tw"
  },
  {
    "代碼": "094606",
    "學校名稱": "縣立溪洲國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市溪洲里萬年路809號",
    "電話": "(05)5512485",
    "網址": "http://www.sjps.ylc.edu.tw/"
  },
  {
    "代碼": "094607",
    "學校名稱": "縣立林頭國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市林頭里榮譽路177號",
    "電話": "(05)5322538",
    "網址": "http://www.ltps.ylc.edu.tw/"
  },
  {
    "代碼": "094608",
    "學校名稱": "縣立保長國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市保庄里保長路155號",
    "電話": "(05)5322982",
    "網址": "http://www.bjes.ylc.edu.tw"
  },
  {
    "代碼": "094609",
    "學校名稱": "縣立鎮南國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市社口里南揚街60號",
    "電話": "(05)5322524",
    "網址": "http://www.jnes.ylc.edu.tw"
  },
  {
    "代碼": "094610",
    "學校名稱": "縣立公誠國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市鎮北里北平路95號",
    "電話": "(05)5322536",
    "網址": "http://www.gces.ylc.edu.tw"
  },
  {
    "代碼": "094611",
    "學校名稱": "縣立久安國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市久安里久安南路120號",
    "電話": "(05)5222724",
    "網址": "http://www.jaes.ylc.edu.tw"
  },
  {
    "代碼": "094612",
    "學校名稱": "縣立古坑國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉朝陽路3號",
    "電話": "(05)5821024",
    "網址": "http://www.kkes.ylc.edu.tw"
  },
  {
    "代碼": "094613",
    "學校名稱": "縣立東和國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉東和村文化路120號",
    "電話": "(05)5263153",
    "網址": "http://www.thps.ylc.edu.tw"
  },
  {
    "代碼": "094614",
    "學校名稱": "縣立永光國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉永光村文昌路84號",
    "電話": "(05)5821057",
    "網址": "http://www.ykes.ylc.edu.tw"
  },
  {
    "代碼": "094615",
    "學校名稱": "縣立華山國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉華山村41號",
    "電話": "(05)5901243",
    "網址": "http://www.hses.ylc.edu.tw"
  },
  {
    "代碼": "094616",
    "學校名稱": "縣立棋山國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉棋盤村棋山路49號",
    "電話": "(05)5263673",
    "網址": "http://www.cses.ylc.edu.tw"
  },
  {
    "代碼": "094617",
    "學校名稱": "縣立桂林國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉桂林村95號",
    "電話": "(05)5901048",
    "網址": "http://www.gles.ylc.edu.tw"
  },
  {
    "代碼": "094618",
    "學校名稱": "縣立樟湖生態國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉樟湖村石橋50-2號",
    "電話": "(05)5811044",
    "網址": "https://sites.google.com/site/newjhanghu/"
  },
  {
    "代碼": "094619",
    "學校名稱": "縣立草嶺生態地質國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉草嶺村56-8號",
    "電話": "(05)5831010",
    "網址": "http://tw.school.uschoolnet.com/?id=es00002075"
  },
  {
    "代碼": "094620",
    "學校名稱": "縣立華南國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉華南村28號",
    "電話": "(05)5901529",
    "網址": "http://www.hnes.ylc.edu.tw"
  },
  {
    "代碼": "094621",
    "學校名稱": "縣立興昌國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉崁腳村南昌路6號",
    "電話": "(05)5821060",
    "網址": "http://www.scps.ylc.edu.tw"
  },
  {
    "代碼": "094622",
    "學校名稱": "縣立山峰華德福實小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉荷苞村山峰17號",
    "電話": "(05)5822075",
    "網址": "http://tw.school.uschoolnet.com/?id=es00002076"
  },
  {
    "代碼": "094623",
    "學校名稱": "縣立水碓國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉水碓村97號",
    "電話": "(05)5821049",
    "網址": "http://www.sdps.ylc.edu.tw"
  },
  {
    "代碼": "094624",
    "學校名稱": "縣立新光國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[646]雲林縣古坑鄉新庄村新庄1之1號",
    "電話": "(05)5260572",
    "網址": "http://www.sgps.ylc.edu.tw"
  },
  {
    "代碼": "094625",
    "學校名稱": "縣立林內國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[643]雲林縣林內鄉中正路111號",
    "電話": "(05)5892019",
    "網址": "http://www.lnps.ylc.edu.tw"
  },
  {
    "代碼": "094626",
    "學校名稱": "縣立重興國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[643]雲林縣林內鄉重興村重興路15號",
    "電話": "(05)5892044",
    "網址": "http://www.ches.ylc.edu.tw"
  },
  {
    "代碼": "094627",
    "學校名稱": "縣立九芎國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[643]雲林縣林內鄉九芎村文化路10號",
    "電話": "(05)5892647",
    "網址": "http://www.jcps.ylc.edu.tw"
  },
  {
    "代碼": "094628",
    "學校名稱": "縣立成功國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[643]雲林縣林內鄉坪頂村1號",
    "電話": "(05)5892090",
    "網址": "http://www.ckes.ylc.edu.tw"
  },
  {
    "代碼": "094629",
    "學校名稱": "縣立林中國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[643]雲林縣林內鄉林中村公園路4號",
    "電話": "(05)5892064",
    "網址": "http://www.lces.ylc.edu.tw"
  },
  {
    "代碼": "094630",
    "學校名稱": "縣立民生國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[643]雲林縣林內鄉烏塗村華興路1號",
    "電話": "(05)5892714",
    "網址": "http://www.msps.ylc.edu.tw"
  },
  {
    "代碼": "094631",
    "學校名稱": "縣立斗南國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[630]雲林縣斗南鎮西歧里文昌路150號",
    "電話": "(05)5972020",
    "網址": "http://www.tnps.ylc.edu.tw"
  },
  {
    "代碼": "094632",
    "學校名稱": "縣立大東國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[630]雲林縣斗南鎮新崙里港漧路1號",
    "電話": "(05)5972434",
    "網址": "http://www.ttes.ylc.edu.tw"
  },
  {
    "代碼": "094633",
    "學校名稱": "縣立石龜國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[630]雲林縣斗南鎮石龜里南生路1號",
    "電話": "(05)5972354",
    "網址": "http://www.skes.ylc.edu.tw"
  },
  {
    "代碼": "094634",
    "學校名稱": "縣立重光國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[630]雲林縣斗南鎮將軍里溫厝1號",
    "電話": "(05)5972454",
    "網址": "http://www.cges.ylc.edu.tw"
  },
  {
    "代碼": "094635",
    "學校名稱": "縣立文安國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[630]雲林縣斗南鎮延平路一段189號",
    "電話": "(05)5972265",
    "網址": "http://www.waes.ylc.edu.tw"
  },
  {
    "代碼": "094636",
    "學校名稱": "縣立僑真國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[630]雲林縣斗南鎮延平路二段642號",
    "電話": "(05)5972241",
    "網址": "http://www.cjnes.ylc.edu.tw"
  },
  {
    "代碼": "094637",
    "學校名稱": "縣立莿桐國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[647]雲林縣莿桐鄉莿桐村中正路147號",
    "電話": "(05)5842065",
    "網址": "http://www.ttps.ylc.edu.tw"
  },
  {
    "代碼": "094638",
    "學校名稱": "縣立饒平國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[647]雲林縣莿桐鄉饒平村饒平路1號",
    "電話": "(05)5842071",
    "網址": "http://www.zpes.ylc.edu.tw"
  },
  {
    "代碼": "094639",
    "學校名稱": "縣立大美國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[647]雲林縣莿桐鄉大美村23號",
    "電話": "(05)5842504",
    "網址": "http://www.dmes.ylc.edu.tw"
  },
  {
    "代碼": "094640",
    "學校名稱": "縣立六合國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[647]雲林縣莿桐鄉六合村東興路63號",
    "電話": "(05)5712810",
    "網址": "http://www.lhes.ylc.edu.tw"
  },
  {
    "代碼": "094641",
    "學校名稱": "縣立僑和國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[647]雲林縣莿桐鄉興桐村新庄1號",
    "電話": "(05)5842230",
    "網址": "http://www.choes.ylc.edu.tw"
  },
  {
    "代碼": "094642",
    "學校名稱": "縣立育仁國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[647]雲林縣莿桐鄉甘厝村75號",
    "電話": "(05)5842525",
    "網址": "http://www.yrps.ylc.edu.tw"
  },
  {
    "代碼": "094643",
    "學校名稱": "縣立大埤國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[631]雲林縣大埤鄉南和村中正路23號",
    "電話": "(05)5912007",
    "網址": "http://www.dpps.ylc.edu.tw"
  },
  {
    "代碼": "094644",
    "學校名稱": "縣立舊庄國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[631]雲林縣大埤鄉怡然村怡然路92號",
    "電話": "(05)5912094",
    "網址": "http://www.jjes.ylc.edu.tw"
  },
  {
    "代碼": "094645",
    "學校名稱": "縣立仁和國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[631]雲林縣大埤鄉豐岡村仁和路4號",
    "電話": "(05)5912454",
    "網址": "http://www.zhes.ylc.edu.tw"
  },
  {
    "代碼": "094646",
    "學校名稱": "縣立嘉興國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[631]雲林縣大埤鄉嘉興村嘉興路65號",
    "電話": "(05)5912434",
    "網址": "http://www.jsps.ylc.edu.tw"
  },
  {
    "代碼": "094647",
    "學校名稱": "縣立聯美國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[631]雲林縣大埤鄉聯美村聯豐路99號",
    "電話": "(05)5912224",
    "網址": "http://www.lmps.ylc.edu.tw"
  },
  {
    "代碼": "094648",
    "學校名稱": "縣立虎尾國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[632]雲林縣虎尾鎮明正路88號",
    "電話": "(05)6322026",
    "網址": "http://www.hwes.ylc.edu.tw"
  },
  {
    "代碼": "094649",
    "學校名稱": "縣立立仁國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[632]雲林縣虎尾鎮立仁街40號",
    "電話": "(05)6322205",
    "網址": "http://www.ljes.ylc.edu.tw"
  },
  {
    "代碼": "094650",
    "學校名稱": "縣立大屯國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[632]雲林縣虎尾鎮東屯里147號",
    "電話": "(05)6651284",
    "網址": "http://www.dtps.ylc.edu.tw"
  },
  {
    "代碼": "094651",
    "學校名稱": "縣立中溪國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[632]雲林縣虎尾鎮頂溪里過溪路63號",
    "電話": "(05)6222385",
    "網址": "http://www.jses.ylc.edu.tw"
  },
  {
    "代碼": "094652",
    "學校名稱": "縣立光復國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[632]雲林縣虎尾鎮墾地里光復莊11鄰39號",
    "電話": "(05)6326257",
    "網址": "http://www.gfps.ylc.edu.tw"
  },
  {
    "代碼": "094653",
    "學校名稱": "縣立中正國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[632]雲林縣虎尾鎮林森路一段64號",
    "電話": "(05)6322710",
    "網址": "http://www.ctps.ylc.edu.tw"
  },
  {
    "代碼": "094654",
    "學校名稱": "縣立平和國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[632]雲林縣虎尾鎮平和里光復路22號",
    "電話": "(05)6322742",
    "網址": "http://www.phps.ylc.edu.tw"
  },
  {
    "代碼": "094655",
    "學校名稱": "縣立廉使國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[632]雲林縣虎尾鎮廉使里文科路1410號",
    "電話": "(05)6322703",
    "網址": "http://www.lses.ylc.edu.tw"
  },
  {
    "代碼": "094656",
    "學校名稱": "縣立惠來國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[632]雲林縣虎尾鎮惠來里114號",
    "電話": "(05)6222344",
    "網址": "http://www.hles.ylc.edu.tw"
  },
  {
    "代碼": "094657",
    "學校名稱": "縣立拯民國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[632]雲林縣虎尾鎮建國三村10號",
    "電話": "(05)6324840",
    "網址": "https://www.facebook.com/KIST.ZM/"
  },
  {
    "代碼": "094658",
    "學校名稱": "縣立安慶國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[632]雲林縣虎尾鎮民主路36號",
    "電話": "(05)6322161",
    "網址": "http://www.aces.ylc.edu.tw"
  },
  {
    "代碼": "094659",
    "學校名稱": "縣立土庫國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[633]雲林縣土庫鎮順天里中正路69號",
    "電話": "(05)6622524",
    "網址": "http://www.tkps.ylc.edu.tw"
  },
  {
    "代碼": "094660",
    "學校名稱": "縣立馬光國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[633]雲林縣土庫鎮西平里馬光路174號",
    "電話": "(05)6653864",
    "網址": "http://www.mkps.ylc.edu.tw"
  },
  {
    "代碼": "094661",
    "學校名稱": "縣立埤腳國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[633]雲林縣土庫鎮埤腳里56號",
    "電話": "(05)6622204",
    "網址": "http://www.pjes.ylc.edu.tw"
  },
  {
    "代碼": "094662",
    "學校名稱": "縣立後埔國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[633]雲林縣土庫鎮後埔里241號",
    "電話": "(05)6650483",
    "網址": "http://163.27.214.193/"
  },
  {
    "代碼": "094663",
    "學校名稱": "縣立秀潭國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[633]雲林縣土庫鎮奮起里秀潭9號",
    "電話": "(05)6622004",
    "網址": "http://www.stes.ylc.edu.tw"
  },
  {
    "代碼": "094664",
    "學校名稱": "縣立新庄國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[633]雲林縣土庫鎮新庄里南新庄1號",
    "電話": "(05)6652874",
    "網址": "http://www.sjes.ylc.edu.tw"
  },
  {
    "代碼": "094665",
    "學校名稱": "縣立宏崙國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[633]雲林縣土庫鎮崙內里果圍41號",
    "電話": "(05)6653824",
    "網址": "http://www.hlps.ylc.edu.tw"
  },
  {
    "代碼": "094666",
    "學校名稱": "縣立褒忠國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[634]雲林縣褒忠鄉埔姜村中勝路72號",
    "電話": "(05)6972054",
    "網址": "http://www.bcps.ylc.edu.tw"
  },
  {
    "代碼": "094667",
    "學校名稱": "縣立龍巖國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[634]雲林縣褒忠鄉民生路28巷15號",
    "電話": "(05)6972454",
    "網址": "http://www.lyes.ylc.edu.tw"
  },
  {
    "代碼": "094668",
    "學校名稱": "縣立復興國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[634]雲林縣褒忠鄉新湖村復興路71號",
    "電話": "(05)6972490",
    "網址": "http://www.fhes.ylc.edu.tw"
  },
  {
    "代碼": "094669",
    "學校名稱": "縣立潮厝華德福實驗國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[634]雲林縣褒忠鄉潮厝村96號",
    "電話": "(05)6972644",
    "網址": "http://www.ctes.ylc.edu.tw"
  },
  {
    "代碼": "094670",
    "學校名稱": "縣立東勢國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[635]雲林縣東勢鄉東南村東榮路29巷9號",
    "電話": "(05)6991036",
    "網址": "http://www.tsps.ylc.edu.tw"
  },
  {
    "代碼": "094671",
    "學校名稱": "縣立安南國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[635]雲林縣東勢鄉安南村安南路109號",
    "電話": "(05)6991454",
    "網址": "http://www.anes.ylc.edu.tw"
  },
  {
    "代碼": "094672",
    "學校名稱": "縣立明倫國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[635]雲林縣東勢鄉月眉村中山路10號",
    "電話": "(05)6991504",
    "網址": "http://www.miles.ylc.edu.tw"
  },
  {
    "代碼": "094673",
    "學校名稱": "縣立同安國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[635]雲林縣東勢鄉同安路67號",
    "電話": "(05)6991244",
    "網址": "http://www.taes.ylc.edu.tw"
  },
  {
    "代碼": "094674",
    "學校名稱": "縣立龍潭國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[635]雲林縣東勢鄉龍潭村信義路1號",
    "電話": "(05)6991414",
    "網址": "http://www.ltes.ylc.edu.tw"
  },
  {
    "代碼": "094675",
    "學校名稱": "縣立臺西國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[636]雲林縣臺西鄉台西村民權路9號",
    "電話": "(05)6982045",
    "網址": "http://www.tsies.ylc.edu.tw"
  },
  {
    "代碼": "094676",
    "學校名稱": "縣立崙豐國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[636]雲林縣臺西鄉永豐村崙豐路42號",
    "電話": "(05)6982142",
    "網址": "http://www.lfes.ylc.edu.tw"
  },
  {
    "代碼": "094677",
    "學校名稱": "縣立泉州國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[636]雲林縣臺西鄉牛厝村舊泉州20號",
    "電話": "(05)6982145",
    "網址": "http://www.cjps.ylc.edu.tw"
  },
  {
    "代碼": "094678",
    "學校名稱": "縣立新興國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[636]雲林縣臺西鄉和豐村復興路53號",
    "電話": "(05)6900143",
    "網址": "http://163.27.202.2/xoops232/index.php"
  },
  {
    "代碼": "094679",
    "學校名稱": "縣立尚德國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[636]雲林縣臺西鄉溪頂村12號",
    "電話": "(05)6982074",
    "網址": "http://www.sdes.ylc.edu.tw"
  },
  {
    "代碼": "094680",
    "學校名稱": "縣立文昌國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[648]雲林縣西螺鎮中興里延平路504號",
    "電話": "(05)5862013",
    "網址": "http://163.27.195.252/~school/"
  },
  {
    "代碼": "094681",
    "學校名稱": "縣立中山國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[648]雲林縣西螺鎮廣福里新街路2號",
    "電話": "(05)5862039",
    "網址": "http://www.csps.ylc.edu.tw"
  },
  {
    "代碼": "094682",
    "學校名稱": "縣立廣興國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[648]雲林縣西螺鎮廣興里廣興路59號",
    "電話": "(05)5863094",
    "網址": "http://www.gses.ylc.edu.tw"
  },
  {
    "代碼": "094683",
    "學校名稱": "縣立安定國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[648]雲林縣西螺鎮河南里埔心路210號",
    "電話": "(05)5863154",
    "網址": "http://www.atps.ylc.edu.tw"
  },
  {
    "代碼": "094684",
    "學校名稱": "縣立吳厝國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[648]雲林縣西螺鎮九隆里44號",
    "電話": "(05)5863074",
    "網址": "http://www.wtps.ylc.edu.tw"
  },
  {
    "代碼": "094685",
    "學校名稱": "縣立大新國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[648]雲林縣西螺鎮大新里215號",
    "電話": "(05)5862974",
    "網址": "http://www.dhes.ylc.edu.tw"
  },
  {
    "代碼": "094686",
    "學校名稱": "縣立文賢國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[648]雲林縣西螺鎮公館里1號",
    "電話": "(05)5863164",
    "網址": "http://www.wsps.ylc.edu.tw"
  },
  {
    "代碼": "094687",
    "學校名稱": "縣立文興國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[648]雲林縣西螺鎮新豐里新社路257號",
    "電話": "(05)5863174",
    "網址": "http://www.wses.ylc.edu.tw"
  },
  {
    "代碼": "094688",
    "學校名稱": "縣立二崙國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[649]雲林縣二崙鄉崙東村中興路36號",
    "電話": "(05)5982241",
    "網址": "http://www.elps.ylc.edu.tw"
  },
  {
    "代碼": "094689",
    "學校名稱": "縣立三和國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[649]雲林縣二崙鄉三和村二鄰17號",
    "電話": "(05)5982795",
    "網址": "http://www.shoes.ylc.edu.tw"
  },
  {
    "代碼": "094690",
    "學校名稱": "縣立油車國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[649]雲林縣二崙鄉港後村港後路60號",
    "電話": "(05)5982600",
    "網址": "http://www.yces.ylc.edu.tw/html/index/index.php"
  },
  {
    "代碼": "094691",
    "學校名稱": "縣立大同國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[649]雲林縣二崙鄉大同村大同路5號",
    "電話": "(05)5982996",
    "網址": "http://www.tatps.ylc.edu.tw"
  },
  {
    "代碼": "094692",
    "學校名稱": "縣立永定國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[649]雲林縣二崙鄉永定村永定路238號",
    "電話": "(05)5982796",
    "網址": "http://www.ydes.ylc.edu.tw"
  },
  {
    "代碼": "094693",
    "學校名稱": "縣立義賢國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[649]雲林縣二崙鄉楊賢村楊賢路1號",
    "電話": "(05)5982797",
    "網址": "http://www.ysps.ylc.edu.tw"
  },
  {
    "代碼": "094694",
    "學校名稱": "縣立旭光國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[649]雲林縣二崙鄉大庄村酒姑村54號",
    "電話": "(05)5982601",
    "網址": "http://www.sges.ylc.edu.tw"
  },
  {
    "代碼": "094695",
    "學校名稱": "縣立來惠國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[649]雲林縣二崙鄉來惠村來惠路12號",
    "電話": "(05)5982995",
    "網址": "http://www.lhps.ylc.edu.tw"
  },
  {
    "代碼": "094696",
    "學校名稱": "縣立崙背國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[637]雲林縣崙背鄉大同路154號",
    "電話": "(05)6962004",
    "網址": "http://www.lpes.ylc.edu.tw"
  },
  {
    "代碼": "094697",
    "學校名稱": "縣立豐榮國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[637]雲林縣崙背鄉豐榮村豐榮路21號",
    "電話": "(05)6552006",
    "網址": "http://www.flps.ylc.edu.tw"
  },
  {
    "代碼": "094698",
    "學校名稱": "縣立大有國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[637]雲林縣崙背鄉大有村一鄰14號",
    "電話": "(05)6962741",
    "網址": "http://www.dyes.ylc.edu.tw"
  },
  {
    "代碼": "094699",
    "學校名稱": "縣立中和國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[637]雲林縣崙背鄉草湖村二鄰2號",
    "電話": "(05)6552384",
    "網址": "http://www.jhes.ylc.edu.tw"
  },
  {
    "代碼": "094700",
    "學校名稱": "縣立陽明國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[637]雲林縣崙背鄉水尾村頂街1號",
    "電話": "(05)6967670",
    "網址": "http://www.ymes.ylc.edu.tw"
  },
  {
    "代碼": "094701",
    "學校名稱": "縣立東興國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[637]雲林縣崙背鄉羅厝村東興路1號",
    "電話": "(05)6962164",
    "網址": "http://www.dses.ylc.edu.tw"
  },
  {
    "代碼": "094702",
    "學校名稱": "縣立麥寮國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[638]雲林縣麥寮鄉麥津村中山路260號",
    "電話": "(05)6932266",
    "網址": "http://www.mles.ylc.edu.tw"
  },
  {
    "代碼": "094703",
    "學校名稱": "縣立橋頭國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[638]雲林縣麥寮鄉橋頭村仁德路248號",
    "電話": "(05)6912358",
    "網址": "http://www.chtes.ylc.edu.tw"
  },
  {
    "代碼": "094704",
    "學校名稱": "縣立明禮國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[638]雲林縣麥寮鄉崙後村92號",
    "電話": "(05)6932244",
    "網址": "http://163.27.218.130/xoops/"
  },
  {
    "代碼": "094705",
    "學校名稱": "縣立興華國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[638]雲林縣麥寮鄉興華村興化路1號",
    "電話": "(05)6932574",
    "網址": "http://www.shps.ylc.edu.tw"
  },
  {
    "代碼": "094706",
    "學校名稱": "縣立豐安國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[638]雲林縣麥寮鄉後安村227號",
    "電話": "(05)6932759",
    "網址": "http://www.faes.ylc.edu.tw"
  },
  {
    "代碼": "094707",
    "學校名稱": "縣立南陽國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[651]雲林縣北港鎮南安里光明路59號",
    "電話": "(05)7832106",
    "網址": "http://www.nyes.ylc.edu.tw"
  },
  {
    "代碼": "094708",
    "學校名稱": "縣立北辰國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[651]雲林縣北港鎮新街里成功路30號",
    "電話": "(05)7832153",
    "網址": "http://www.bces.ylc.edu.tw"
  },
  {
    "代碼": "094709",
    "學校名稱": "縣立好收國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[651]雲林縣北港鎮好收里好收路20號",
    "電話": "(05)7922254",
    "網址": "http://www.hsps.ylc.edu.tw"
  },
  {
    "代碼": "094710",
    "學校名稱": "縣立育英國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[651]雲林縣北港鎮番溝里84號",
    "電話": "(05)7921224",
    "網址": "http://www.yyes.ylc.edu.tw"
  },
  {
    "代碼": "094711",
    "學校名稱": "縣立東榮國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[651]雲林縣北港鎮溝皂里174號",
    "電話": "(05)7921304",
    "網址": "http://www.dles.ylc.edu.tw"
  },
  {
    "代碼": "094712",
    "學校名稱": "縣立朝陽國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[651]雲林縣北港鎮扶朝里76號",
    "電話": "(05)7832904",
    "網址": "http://www.cyes.ylc.edu.tw"
  },
  {
    "代碼": "094713",
    "學校名稱": "縣立辰光國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[651]雲林縣北港鎮新厝里49號",
    "電話": "(05)7834320",
    "網址": "http://www.cgps.ylc.edu.tw"
  },
  {
    "代碼": "094714",
    "學校名稱": "縣立僑美國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[651]雲林縣北港鎮草湖里華勝路655號",
    "電話": "(05)7832307",
    "網址": "http://www.cmes.ylc.edu.tw"
  },
  {
    "代碼": "094715",
    "學校名稱": "縣立元長國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[655]雲林縣元長鄉長南村元西路76號",
    "電話": "(05)7882017",
    "網址": "http://tw.school.uschoolnet.com/?id=es00002150"
  },
  {
    "代碼": "094716",
    "學校名稱": "縣立新生國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[655]雲林縣元長鄉瓦搖村新生路11號",
    "電話": "(05)7983093",
    "網址": "http://www.ssps.ylc.edu.tw"
  },
  {
    "代碼": "094717",
    "學校名稱": "縣立客厝國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[655]雲林縣元長鄉頂寮村11號",
    "電話": "(05)7882718",
    "網址": "http://www.kcps.ylc.edu.tw"
  },
  {
    "代碼": "094718",
    "學校名稱": "縣立山內國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[655]雲林縣元長鄉山內村南山路3號",
    "電話": "(05)7882152",
    "網址": "http://www.snes.ylc.edu.tw"
  },
  {
    "代碼": "094719",
    "學校名稱": "縣立仁德國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[655]雲林縣元長鄉內寮村內寮路6號",
    "電話": "(05)7861976",
    "網址": "http://www.rdps.ylc.edu.tw"
  },
  {
    "代碼": "094720",
    "學校名稱": "縣立忠孝國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[655]雲林縣元長鄉龍岩村148號",
    "電話": "(05)7882096",
    "網址": "http://www.jshes.ylc.edu.tw"
  },
  {
    "代碼": "094721",
    "學校名稱": "縣立仁愛國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[655]雲林縣元長鄉潭西村潭內路1號",
    "電話": "(05)7882710",
    "網址": "http://www.raes.ylc.edu.tw"
  },
  {
    "代碼": "094722",
    "學校名稱": "縣立信義國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[655]雲林縣元長鄉五塊村北水路8號",
    "電話": "(05)7882712",
    "網址": "http://163.27.222.196"
  },
  {
    "代碼": "094723",
    "學校名稱": "縣立和平國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[655]雲林縣元長鄉子茂村4號",
    "電話": "(05)7882684",
    "網址": "http://www.hpes.ylc.edu.tw"
  },
  {
    "代碼": "094724",
    "學校名稱": "縣立四湖國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[654]雲林縣四湖鄉四湖村中山東路37號",
    "電話": "(05)7872100",
    "網址": "http://www.shes.ylc.edu.tw"
  },
  {
    "代碼": "094725",
    "學校名稱": "縣立東光國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[654]雲林縣四湖鄉溪底村成功路11號",
    "電話": "(05)7821728",
    "網址": "http://www.tkes.ylc.edu.tw"
  },
  {
    "代碼": "094726",
    "學校名稱": "縣立飛沙國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[654]雲林縣四湖鄉飛沙村大同路26號",
    "電話": "(05)7723688",
    "網址": "http://www.fses.ylc.edu.tw"
  },
  {
    "代碼": "094727",
    "學校名稱": "縣立林厝國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[654]雲林縣四湖鄉林東村中華路1號",
    "電話": "(05)7721320",
    "網址": "http://www.lotps.ylc.edu.tw"
  },
  {
    "代碼": "094728",
    "學校名稱": "縣立三崙國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[654]雲林縣四湖鄉崙南村延平南路18號",
    "電話": "(05)7721044",
    "網址": "http://www.sales.ylc.edu.tw"
  },
  {
    "代碼": "094729",
    "學校名稱": "縣立建陽國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[654]雲林縣四湖鄉箔子村箔子寮333號",
    "電話": "(05)7721851",
    "網址": "http://www.jyes.ylc.edu.tw"
  },
  {
    "代碼": "094730",
    "學校名稱": "縣立南光國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[654]雲林縣四湖鄉蔡厝村蔡厝路90號",
    "電話": "(05)7872434",
    "網址": "http://www.nges.ylc.edu.tw"
  },
  {
    "代碼": "094731",
    "學校名稱": "縣立鹿場國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[654]雲林縣四湖鄉鹿場村中鹿場路27號",
    "電話": "(05)7872454",
    "網址": "http://www.lcps.ylc.edu.tw"
  },
  {
    "代碼": "094732",
    "學校名稱": "縣立明德國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[654]雲林縣四湖鄉溪尾村中溪尾30號",
    "電話": "(05)7872450",
    "網址": "http://www.mdps.ylc.edu.tw"
  },
  {
    "代碼": "094733",
    "學校名稱": "縣立建華國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[654]雲林縣四湖鄉羊稠村建華路35號",
    "電話": "(05)7875635",
    "網址": "http://www.jahes.ylc.edu.tw"
  },
  {
    "代碼": "094734",
    "學校名稱": "縣立內湖國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[654]雲林縣四湖鄉內湖村三塊厝123號",
    "電話": "(05)7872054",
    "網址": "http://163.27.223.176/dyna/webs/index.php?account=admin"
  },
  {
    "代碼": "094735",
    "學校名稱": "縣立口湖國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[653]雲林縣口湖鄉湖東村文明路103巷46號",
    "電話": "(05)7892154",
    "網址": "http://www.khps.ylc.edu.tw"
  },
  {
    "代碼": "094736",
    "學校名稱": "縣立文光國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[653]雲林縣口湖鄉梧南村文光路100號",
    "電話": "(05)7907189",
    "網址": "http://www.wgps.ylc.edu.tw"
  },
  {
    "代碼": "094737",
    "學校名稱": "縣立金湖國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[653]雲林縣口湖鄉港東村民主路15號",
    "電話": "(05)7971188",
    "網址": "http://www.chps.ylc.edu.tw"
  },
  {
    "代碼": "094738",
    "學校名稱": "縣立下崙國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[653]雲林縣口湖鄉崙中村福安路200號",
    "電話": "(05)7991184",
    "網址": "http://www.shlps.ylc.edu.tw"
  },
  {
    "代碼": "094739",
    "學校名稱": "縣立興南國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[653]雲林縣口湖鄉埔南村9號",
    "電話": "(05)7892190",
    "網址": "http://www.hnps.ylc.edu.tw"
  },
  {
    "代碼": "094740",
    "學校名稱": "縣立崇文國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[653]雲林縣口湖鄉蚵寮村崇文路二段285號",
    "電話": "(05)7892186",
    "網址": "http://tw.school.uschoolnet.com/?id=es00002129"
  },
  {
    "代碼": "094741",
    "學校名稱": "縣立成龍國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[653]雲林縣口湖鄉成龍村201號",
    "電話": "(05)7971187",
    "網址": "http://www.cles.ylc.edu.tw"
  },
  {
    "代碼": "094742",
    "學校名稱": "縣立臺興國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[653]雲林縣口湖鄉台子村台興路130號",
    "電話": "(05)7971494",
    "網址": "http://www.tses.ylc.edu.tw"
  },
  {
    "代碼": "094743",
    "學校名稱": "縣立頂湖國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[653]雲林縣口湖鄉頂湖村121號",
    "電話": "(05)7892185",
    "網址": "http://www.dhps.ylc.edu.tw"
  },
  {
    "代碼": "094746",
    "學校名稱": "縣立蔦松國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[652]雲林縣水林鄉松中村蔦松路213號",
    "電話": "(05)7841230",
    "網址": "http://www.nses.ylc.edu.tw"
  },
  {
    "代碼": "094747",
    "學校名稱": "縣立尖山國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[652]雲林縣水林鄉大山村大山路6號",
    "電話": "(05)7851756",
    "網址": "http://www.chses.ylc.edu.tw"
  },
  {
    "代碼": "094748",
    "學校名稱": "縣立宏仁國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[652]雲林縣水林鄉灣東村宏仁路30號",
    "電話": "(05)7857080",
    "網址": "http://www.hres.ylc.edu.tw"
  },
  {
    "代碼": "094749",
    "學校名稱": "縣立文正國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[652]雲林縣水林鄉山腳村蕃東路1號",
    "電話": "(05)7841051",
    "網址": "http://www.wzes.ylc.edu.tw"
  },
  {
    "代碼": "094750",
    "學校名稱": "縣立誠正國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[652]雲林縣水林鄉蘇秦村1號",
    "電話": "(05)7921114",
    "網址": "http://www.cjes.ylc.edu.tw"
  },
  {
    "代碼": "094751",
    "學校名稱": "縣立中興國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[652]雲林縣水林鄉瓊埔村148號",
    "電話": "(05)7841064",
    "網址": "http://163.27.228.65"
  },
  {
    "代碼": "094753",
    "學校名稱": "縣立水燦林國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[652]雲林縣水林鄉水北村水林路6號",
    "電話": "(05)7852845",
    "網址": "http://www.tlps.ylc.edu.tw"
  },
  {
    "代碼": "094754",
    "學校名稱": "縣立大興國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[652]雲林縣水林鄉大溝村1號",
    "電話": "(05)7851300",
    "網址": "http://www.dsps.ylc.edu.tw"
  },
  {
    "代碼": "094755",
    "學校名稱": "縣立雲林國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市莊敬路111號",
    "電話": "(05)5361112",
    "網址": "http://www.yles.ylc.edu.tw"
  },
  {
    "代碼": "094756",
    "學校名稱": "縣立斗六國小",
    "公/私立": "公立",
    "縣市名稱": "[09]雲林縣",
    "地址": "[640]雲林縣斗六市鎮東路225號",
    "電話": "(05)5379600",
    "網址": "http://163.27.242.60/websiteX/"
  },
  {
    "代碼": "104601",
    "學校名稱": "縣立朴子國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[613]嘉義縣朴子市山通路11號",
    "電話": "(05)3792032",
    "網址": "http://www.ptps.cyc.edu.tw"
  },
  {
    "代碼": "104602",
    "學校名稱": "縣立大同國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[613]嘉義縣朴子市大同路239號",
    "電話": "(05)2949036",
    "網址": "http://www.ttps.cyc.edu.tw"
  },
  {
    "代碼": "104603",
    "學校名稱": "縣立雙溪國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[613]嘉義縣朴子市雙溪里30號",
    "電話": "(05)3795549",
    "網址": "http://www.ssps.cyc.edu.tw"
  },
  {
    "代碼": "104604",
    "學校名稱": "縣立竹村國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[613]嘉義縣朴子市竹村里36號",
    "電話": "(05)3693628",
    "網址": "http://www.jtps.cyc.edu.tw"
  },
  {
    "代碼": "104605",
    "學校名稱": "縣立松梅國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[613]嘉義縣朴子市松華里345號",
    "電話": "(05)3691083",
    "網址": "http://www.smps.cyc.edu.tw"
  },
  {
    "代碼": "104606",
    "學校名稱": "縣立大鄉國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[613]嘉義縣朴子市大鄉里560號",
    "電話": "(05)3792952",
    "網址": "http://www.dsps.cyc.edu.tw"
  },
  {
    "代碼": "104607",
    "學校名稱": "縣立布袋國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[625]嘉義縣布袋鎮新厝里65號",
    "電話": "(05)3472007",
    "網址": "http://www.ptes.cyc.edu.tw"
  },
  {
    "代碼": "104608",
    "學校名稱": "縣立景山國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[625]嘉義縣布袋鎮東港里136號",
    "電話": "(05)3472734",
    "網址": "http://www.jsps.cyc.edu.tw"
  },
  {
    "代碼": "104609",
    "學校名稱": "縣立永安國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[625]嘉義縣布袋鎮永安里126之2號",
    "電話": "(05)3472654",
    "網址": "http://www.yaps.cyc.edu.tw"
  },
  {
    "代碼": "104610",
    "學校名稱": "縣立過溝國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[625]嘉義縣布袋鎮中安里頂厝1號",
    "電話": "(05)3451010",
    "網址": "http://www.ggps.cyc.edu.tw"
  },
  {
    "代碼": "104611",
    "學校名稱": "縣立貴林國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[625]嘉義縣布袋鎮樹林里82號",
    "電話": "(05)3451641",
    "網址": "http://www.glps.cyc.edu.tw"
  },
  {
    "代碼": "104612",
    "學校名稱": "縣立新塭國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[625]嘉義縣布袋鎮新民里374號",
    "電話": "(05)3431064",
    "網址": "http://www.sups.cyc.edu.tw"
  },
  {
    "代碼": "104613",
    "學校名稱": "縣立新岑國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[625]嘉義縣布袋鎮新岑里4號",
    "電話": "(05)3431525",
    "網址": "http://www.scps.cyc.edu.tw"
  },
  {
    "代碼": "104614",
    "學校名稱": "縣立好美國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[625]嘉義縣布袋鎮好美里106號",
    "電話": "(05)3431524",
    "網址": "http://www.hmes.cyc.edu.tw"
  },
  {
    "代碼": "104615",
    "學校名稱": "縣立大林國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[622]嘉義縣大林鎮西林里中正路423號",
    "電話": "(05)2652061",
    "網址": "http://www.tles.cyc.edu.tw"
  },
  {
    "代碼": "104616",
    "學校名稱": "縣立三和國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[622]嘉義縣大林鎮中興路二段937號",
    "電話": "(05)2952145",
    "網址": "http://www.shes.cyc.edu.tw"
  },
  {
    "代碼": "104617",
    "學校名稱": "縣立中林國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[622]嘉義縣大林鎮中林里147號",
    "電話": "(05)2653941",
    "網址": "http://www.cles.cyc.edu.tw"
  },
  {
    "代碼": "104618",
    "學校名稱": "縣立排路國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[622]嘉義縣大林鎮排路里231號",
    "電話": "(05)2694974",
    "網址": "http://www.plps.cyc.edu.tw"
  },
  {
    "代碼": "104620",
    "學校名稱": "縣立社團國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[622]嘉義縣大林鎮三角里下林頭51號",
    "電話": "(05)2952504",
    "網址": "http://www.stps.cyc.edu.tw"
  },
  {
    "代碼": "104621",
    "學校名稱": "縣立民雄國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[621]嘉義縣民雄鄉中樂村民族路43號",
    "電話": "(05)2262022",
    "網址": "http://www.mhps.cyc.edu.tw"
  },
  {
    "代碼": "104622",
    "學校名稱": "縣立東榮國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[621]嘉義縣民雄鄉頂崙村105號",
    "電話": "(05)2262076",
    "網址": "http://www.trps.cyc.edu.tw"
  },
  {
    "代碼": "104623",
    "學校名稱": "縣立三興國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[621]嘉義縣民雄鄉三興村陳厝寮55號",
    "電話": "(05)2720042",
    "網址": "http://www.sasps.cyc.edu.tw"
  },
  {
    "代碼": "104624",
    "學校名稱": "縣立菁埔國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[621]嘉義縣民雄鄉菁埔村130之3號",
    "電話": "(05)2262581",
    "網址": "http://www.cpps.cyc.edu.tw"
  },
  {
    "代碼": "104625",
    "學校名稱": "縣立興中國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[621]嘉義縣民雄鄉興中村30號",
    "電話": "(05)2214725",
    "網址": "http://www.sces.cyc.edu.tw"
  },
  {
    "代碼": "104626",
    "學校名稱": "縣立秀林國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[621]嘉義縣民雄鄉北斗村北勢仔17號",
    "電話": "(05)2215690",
    "網址": "http://www.sles.cyc.edu.tw"
  },
  {
    "代碼": "104627",
    "學校名稱": "縣立松山國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[621]嘉義縣民雄鄉松山村松仔腳43之1號",
    "電話": "(05)2721831",
    "網址": "http://www.sses.cyc.edu.tw"
  },
  {
    "代碼": "104628",
    "學校名稱": "縣立大崎國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[621]嘉義縣民雄鄉秀林村林仔尾66號",
    "電話": "(05)2212871",
    "網址": "http://www.dcps.cyc.edu.tw"
  },
  {
    "代碼": "104629",
    "學校名稱": "縣立溪口國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[623]嘉義縣溪口鄉溪北村中山路53號",
    "電話": "(05)2691013",
    "網址": "http://www.skes.cyc.edu.tw"
  },
  {
    "代碼": "104630",
    "學校名稱": "縣立美林國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[623]嘉義縣溪口鄉美北村78之3號",
    "電話": "(05)2691150",
    "網址": "http://www.mlps.cyc.edu.tw"
  },
  {
    "代碼": "104631",
    "學校名稱": "縣立柴林國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[623]嘉義縣溪口鄉柴林村107號",
    "電話": "(05)2691440",
    "網址": "http://www.chlps.cyc.edu.tw"
  },
  {
    "代碼": "104632",
    "學校名稱": "縣立柳溝國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[623]嘉義縣溪口鄉柳溝村南靖厝5號",
    "電話": "(05)2691071",
    "網址": "http://www.lgps.cyc.edu.tw"
  },
  {
    "代碼": "104633",
    "學校名稱": "縣立新港國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[616]嘉義縣新港鄉福德村登雲路105號",
    "電話": "(05)3742039",
    "網址": "http://www.hkps.cyc.edu.tw"
  },
  {
    "代碼": "104634",
    "學校名稱": "縣立文昌國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[616]嘉義縣新港鄉宮前村古民街12號",
    "電話": "(05)3742172",
    "網址": "http://www.wcps.cyc.edu.tw"
  },
  {
    "代碼": "104635",
    "學校名稱": "縣立月眉國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[616]嘉義縣新港鄉月眉村20鄰123號",
    "電話": "(05)3772059",
    "網址": "http://www.ymps.cyc.edu.tw"
  },
  {
    "代碼": "104636",
    "學校名稱": "縣立古民國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[616]嘉義縣新港鄉古民村161號",
    "電話": "(05)3742154",
    "網址": "http://www.gmps.cyc.edu.tw"
  },
  {
    "代碼": "104637",
    "學校名稱": "縣立復興國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[616]嘉義縣新港鄉北崙村75之4號",
    "電話": "(05)3760424",
    "網址": "http://www.fsps.cyc.edu.tw"
  },
  {
    "代碼": "104638",
    "學校名稱": "縣立安和國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[616]嘉義縣新港鄉安和村46號",
    "電話": "(05)3772940",
    "網址": "http://www.ahps.cyc.edu.tw"
  },
  {
    "代碼": "104639",
    "學校名稱": "縣立蒜頭國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[615]嘉義縣六腳鄉蒜頭村188號",
    "電話": "(05)3802025",
    "網址": "http://www.shtes.cyc.edu.tw"
  },
  {
    "代碼": "104640",
    "學校名稱": "縣立六腳國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[615]嘉義縣六腳鄉六腳村105號",
    "電話": "(05)3781403",
    "網址": "http://www.ljps.cyc.edu.tw"
  },
  {
    "代碼": "104641",
    "學校名稱": "縣立六美國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[615]嘉義縣六腳鄉六斗村31號",
    "電話": "(05)7812708",
    "網址": "http://www.lmes.cyc.edu.tw"
  },
  {
    "代碼": "104642",
    "學校名稱": "縣立灣內國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[615]嘉義縣六腳鄉灣南村15鄰6號",
    "電話": "(05)3802306",
    "網址": "http://www.wnps.cyc.edu.tw"
  },
  {
    "代碼": "104643",
    "學校名稱": "縣立更寮國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[615]嘉義縣六腳鄉更寮村19號",
    "電話": "(05)3796309",
    "網址": "http://www.gles.cyc.edu.tw"
  },
  {
    "代碼": "104645",
    "學校名稱": "縣立北美國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[615]嘉義縣六腳鄉崙陽村122號",
    "電話": "(05)7811429",
    "網址": "http://www.bmps.cyc.edu.tw"
  },
  {
    "代碼": "104647",
    "學校名稱": "縣立東石國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[614]嘉義縣東石鄉猿樹村117號",
    "電話": "(05)3732295",
    "網址": "http://www.tsps.cyc.edu.tw"
  },
  {
    "代碼": "104648",
    "學校名稱": "縣立塭港國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[614]嘉義縣東石鄉塭港村170號",
    "電話": "(05)3732653",
    "網址": "http://www.ugps.cyc.edu.tw"
  },
  {
    "代碼": "104649",
    "學校名稱": "縣立三江國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[614]嘉義縣東石鄉三家村16號",
    "電話": "(05)3732634",
    "網址": "http://www.sjps.cyc.edu.tw"
  },
  {
    "代碼": "104650",
    "學校名稱": "縣立龍港國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[614]嘉義縣東石鄉龍港村113號",
    "電話": "(05)3732671",
    "網址": "http://www.lges.cyc.edu.tw"
  },
  {
    "代碼": "104651",
    "學校名稱": "縣立下楫國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[614]嘉義縣東石鄉下楫村147號",
    "電話": "(05)3601508",
    "網址": "http://www.sjes.cyc.edu.tw"
  },
  {
    "代碼": "104652",
    "學校名稱": "縣立港墘國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[614]嘉義縣東石鄉港墘村64號",
    "電話": "(05)3795350",
    "網址": "http://www.kcps.cyc.edu.tw"
  },
  {
    "代碼": "104653",
    "學校名稱": "縣立龍崗國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[614]嘉義縣東石鄉西崙村131號",
    "電話": "(05)3451070",
    "網址": "http://www.logps.cyc.edu.tw"
  },
  {
    "代碼": "104654",
    "學校名稱": "縣立網寮國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[614]嘉義縣東石鄉網寮村鹽田27號",
    "電話": "(05)3451544",
    "網址": "http://www.wlps.cyc.edu.tw"
  },
  {
    "代碼": "104655",
    "學校名稱": "縣立鹿草國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[611]嘉義縣鹿草鄉西井村12鄰長壽路221號",
    "電話": "(05)3752004",
    "網址": "http://www.ltes.cyc.edu.tw"
  },
  {
    "代碼": "104656",
    "學校名稱": "縣立重寮國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[611]嘉義縣鹿草鄉重寮村中寮160號",
    "電話": "(05)3752544",
    "網址": "http://www.clps.cyc.edu.tw"
  },
  {
    "代碼": "104657",
    "學校名稱": "縣立下潭國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[611]嘉義縣鹿草鄉光潭村196號",
    "電話": "(05)3651544",
    "網址": "http://www.htps.cyc.edu.tw"
  },
  {
    "代碼": "104658",
    "學校名稱": "縣立碧潭國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[611]嘉義縣鹿草鄉碧潭村184號",
    "電話": "(05)3652001",
    "網址": "http://www.btps.cyc.edu.tw"
  },
  {
    "代碼": "104659",
    "學校名稱": "縣立竹園國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[611]嘉義縣鹿草鄉松竹村69號",
    "電話": "(05)3650524",
    "網址": "http://www.jyps.cyc.edu.tw"
  },
  {
    "代碼": "104660",
    "學校名稱": "縣立後塘國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[611]嘉義縣鹿草鄉三角村毛蟹行11號",
    "電話": "(05)3752548",
    "網址": "http://www.htes.cyc.edu.tw"
  },
  {
    "代碼": "104661",
    "學校名稱": "縣立義竹國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[624]嘉義縣義竹鄉六桂村208號",
    "電話": "(05)3412203",
    "網址": "http://www.icps.cyc.edu.tw"
  },
  {
    "代碼": "104663",
    "學校名稱": "縣立光榮國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[624]嘉義縣義竹鄉東光村64號",
    "電話": "(05)3420104",
    "網址": "http://www.krps.cyc.edu.tw"
  },
  {
    "代碼": "104665",
    "學校名稱": "縣立過路國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[624]嘉義縣義竹鄉西過村279號",
    "電話": "(05)3437884",
    "網址": "http://www.gulps.cyc.edu.tw"
  },
  {
    "代碼": "104666",
    "學校名稱": "縣立和順國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[624]嘉義縣義竹鄉官順村356號",
    "電話": "(05)3436121",
    "網址": "http://www.hsps.cyc.edu.tw"
  },
  {
    "代碼": "104668",
    "學校名稱": "縣立南興國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[624]嘉義縣義竹鄉新店村77號",
    "電話": "(05)3427394",
    "網址": "http://www.nses.cyc.edu.tw"
  },
  {
    "代碼": "104669",
    "學校名稱": "縣立太保國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[612]嘉義縣太保市太保里31號",
    "電話": "(05)2949031",
    "網址": "http://www.tbps.cyc.edu.tw"
  },
  {
    "代碼": "104670",
    "學校名稱": "縣立安東國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[612]嘉義縣太保市崙頂里1號",
    "電話": "(05)3714068",
    "網址": "http://www.adps.cyc.edu.tw"
  },
  {
    "代碼": "104671",
    "學校名稱": "縣立南新國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[612]嘉義縣太保市中山路一段140號",
    "電話": "(05)2373005",
    "網址": "http://www.nsps.cyc.edu.tw"
  },
  {
    "代碼": "104672",
    "學校名稱": "縣立新埤國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[612]嘉義縣太保市新埤里214號",
    "電話": "(05)3611075",
    "網址": "http://www.spps.cyc.edu.tw"
  },
  {
    "代碼": "104673",
    "學校名稱": "縣立水上國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[608]嘉義縣水上鄉水上村正義路182號",
    "電話": "(05)2682073",
    "網址": "http://www.shsps.cyc.edu.tw"
  },
  {
    "代碼": "104674",
    "學校名稱": "縣立大崙國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[608]嘉義縣水上鄉大崙村111號",
    "電話": "(05)3711381",
    "網址": "http://www.dlps.cyc.edu.tw"
  },
  {
    "代碼": "104675",
    "學校名稱": "縣立柳林國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[608]嘉義縣水上鄉柳林村92號",
    "電話": "(05)2683240",
    "網址": "http://www.llps.cyc.edu.tw"
  },
  {
    "代碼": "104676",
    "學校名稱": "縣立忠和國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[608]嘉義縣水上鄉忠和村檳榔樹角35號",
    "電話": "(05)2890365",
    "網址": "http://www.jhps.cyc.edu.tw"
  },
  {
    "代碼": "104677",
    "學校名稱": "縣立義興國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[608]嘉義縣水上鄉義興村2之1號",
    "電話": "(05)2890096",
    "網址": "http://www.ysps.cyc.edu.tw"
  },
  {
    "代碼": "104678",
    "學校名稱": "縣立成功國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[608]嘉義縣水上鄉三界埔153號",
    "電話": "(05)2393485",
    "網址": "http://www.ckps.cyc.edu.tw"
  },
  {
    "代碼": "104679",
    "學校名稱": "縣立北回國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[608]嘉義縣水上鄉三和村榮典路100號",
    "電話": "(05)2356941",
    "網址": "http://www.bhps.cyc.edu.tw"
  },
  {
    "代碼": "104680",
    "學校名稱": "縣立南靖國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[608]嘉義縣水上鄉靖和村46號",
    "電話": "(05)2682004",
    "網址": "http://www.njps.cyc.edu.tw"
  },
  {
    "代碼": "104681",
    "學校名稱": "縣立中埔國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[606]嘉義縣中埔鄉中埔村中正路1號",
    "電話": "(05)2531003",
    "網址": "http://www.jpps.cyc.edu.tw"
  },
  {
    "代碼": "104682",
    "學校名稱": "縣立大有國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[606]嘉義縣中埔鄉裕民村石頭厝17之1號",
    "電話": "(05)2531561",
    "網址": "http://www.dyps.cyc.edu.tw"
  },
  {
    "代碼": "104683",
    "學校名稱": "縣立中山國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[606]嘉義縣中埔鄉石弄村22號",
    "電話": "(05)2531231",
    "網址": "http://www.csps.cyc.edu.tw"
  },
  {
    "代碼": "104684",
    "學校名稱": "縣立頂六國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[606]嘉義縣中埔鄉金蘭村頂山門25號",
    "電話": "(05)2394042",
    "網址": "http://www.tlps.cyc.edu.tw"
  },
  {
    "代碼": "104685",
    "學校名稱": "縣立和睦國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[606]嘉義縣中埔鄉和美村後庄16號",
    "電話": "(05)2304511",
    "網址": "http://www.hmps.cyc.edu.tw"
  },
  {
    "代碼": "104686",
    "學校名稱": "縣立同仁國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[606]嘉義縣中埔鄉同仁村同仁24號",
    "電話": "(05)2531205",
    "網址": "http://www.tres.cyc.edu.tw"
  },
  {
    "代碼": "104688",
    "學校名稱": "縣立沄水國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[606]嘉義縣中埔鄉沄水村五鄰16號",
    "電話": "(05)2531654",
    "網址": "http://www.yses.cyc.edu.tw"
  },
  {
    "代碼": "104690",
    "學校名稱": "縣立社口國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[606]嘉義縣中埔鄉隆興村二鄰8號",
    "電話": "(05)2531094",
    "網址": "http://www.shkps.cyc.edu.tw"
  },
  {
    "代碼": "104692",
    "學校名稱": "縣立灣潭國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[606]嘉義縣中埔鄉灣潭村下庄1之1號",
    "電話": "(05)2531660",
    "網址": "http://www.utps.cyc.edu.tw"
  },
  {
    "代碼": "104693",
    "學校名稱": "縣立民和國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[602]嘉義縣番路鄉下坑村菜公店91號",
    "電話": "(05)2592188",
    "網址": "http://www.mhes.cyc.edu.tw"
  },
  {
    "代碼": "104694",
    "學校名稱": "縣立內甕國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[602]嘉義縣番路鄉內甕村凸湖1-1號",
    "電話": "(05)2591434",
    "網址": "http://www.nwps.cyc.edu.tw"
  },
  {
    "代碼": "104695",
    "學校名稱": "縣立黎明國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[602]嘉義縣番路鄉觸口村觸口164號",
    "電話": "(05)2591194",
    "網址": "http://www.limps.cyc.edu.tw"
  },
  {
    "代碼": "104696",
    "學校名稱": "縣立大湖國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[602]嘉義縣番路鄉大湖村下坪仔8號",
    "電話": "(05)2581159",
    "網址": "http://www.dhes.cyc.edu.tw"
  },
  {
    "代碼": "104698",
    "學校名稱": "縣立隙頂國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[602]嘉義縣番路鄉公田村隙頂40號",
    "電話": "(05)2586218",
    "網址": "http://www.sdps.cyc.edu.tw"
  },
  {
    "代碼": "104700",
    "學校名稱": "縣立竹崎國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[604]嘉義縣竹崎鄉竹崎村文化路28號",
    "電話": "(05)2611018",
    "網址": "http://www.jcps.cyc.edu.tw"
  },
  {
    "代碼": "104702",
    "學校名稱": "縣立龍山國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[604]嘉義縣竹崎鄉龍山村水道70號",
    "電話": "(05)2612843",
    "網址": "http://www.lsps.cyc.edu.tw"
  },
  {
    "代碼": "104704",
    "學校名稱": "縣立鹿滿國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[604]嘉義縣竹崎鄉鹿滿村鹿鳴路2號",
    "電話": "(05)2611472",
    "網址": "http://www.lmps.cyc.edu.tw"
  },
  {
    "代碼": "104705",
    "學校名稱": "縣立圓崇國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[604]嘉義縣竹崎鄉灣橋村387巷28號",
    "電話": "(05)2790508",
    "網址": "http://www.ytps.cyc.edu.tw"
  },
  {
    "代碼": "104706",
    "學校名稱": "縣立內埔國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[604]嘉義縣竹崎鄉內埔村元興路2號",
    "電話": "(05)2541005",
    "網址": "http://www.npps.cyc.edu.tw"
  },
  {
    "代碼": "104707",
    "學校名稱": "縣立桃源國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[604]嘉義縣竹崎鄉桃源村七鄰78號",
    "電話": "(05)2541077",
    "網址": "http://www.typs.cyc.edu.tw"
  },
  {
    "代碼": "104708",
    "學校名稱": "縣立中和國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[604]嘉義縣竹崎鄉中和村奮起湖215號",
    "電話": "(05)2561013",
    "網址": "http://www.chps.cyc.edu.tw"
  },
  {
    "代碼": "104709",
    "學校名稱": "縣立中興國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[604]嘉義縣竹崎鄉中和村石棹10-1號",
    "電話": "(05)2561021",
    "網址": "http://www.cses.cyc.edu.tw"
  },
  {
    "代碼": "104710",
    "學校名稱": "縣立光華國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[604]嘉義縣竹崎鄉光華村四鄰10號",
    "電話": "(05)2561074",
    "網址": "http://www.ghps.cyc.edu.tw"
  },
  {
    "代碼": "104712",
    "學校名稱": "縣立義仁國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[604]嘉義縣竹崎鄉義和村22號",
    "電話": "(05)2110534",
    "網址": "http://www.yrps.cyc.edu.tw"
  },
  {
    "代碼": "104713",
    "學校名稱": "縣立沙坑國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[604]嘉義縣竹崎鄉沙坑村57之2號",
    "電話": "(05)2611244",
    "網址": "http://www.sakps.cyc.edu.tw"
  },
  {
    "代碼": "104715",
    "學校名稱": "縣立梅山國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[603]嘉義縣梅山鄉梅東村中山路28號之1",
    "電話": "(05)2621004",
    "網址": "http://www.msps.cyc.edu.tw"
  },
  {
    "代碼": "104716",
    "學校名稱": "縣立梅圳國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[603]嘉義縣梅山鄉圳南村三源23號",
    "電話": "(05)2621615",
    "網址": "http://www.mjps.cyc.edu.tw"
  },
  {
    "代碼": "104717",
    "學校名稱": "縣立太平國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[603]嘉義縣梅山鄉太平村大坪4號",
    "電話": "(05)2571004",
    "網址": "http://www.tpps.cyc.edu.tw"
  },
  {
    "代碼": "104719",
    "學校名稱": "縣立太興國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[603]嘉義縣梅山鄉太興村4號",
    "電話": "(05)2571087",
    "網址": "http://www.tses.cyc.edu.tw"
  },
  {
    "代碼": "104720",
    "學校名稱": "縣立瑞里國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[603]嘉義縣梅山鄉瑞里村112號",
    "電話": "(05)2501020",
    "網址": "http://www.rlps.cyc.edu.tw"
  },
  {
    "代碼": "104721",
    "學校名稱": "縣立大南國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[603]嘉義縣梅山鄉大南村大草埔15號",
    "電話": "(05)2621253",
    "網址": "http://www.dnps.cyc.edu.tw"
  },
  {
    "代碼": "104722",
    "學校名稱": "縣立瑞峰國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[603]嘉義縣梅山鄉瑞峰村生毛樹19號之1",
    "電話": "(05)2501204",
    "網址": "http://www.rfps.cyc.edu.tw"
  },
  {
    "代碼": "104724",
    "學校名稱": "縣立太和國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[603]嘉義縣梅山鄉太和村三鄰18號",
    "電話": "(05)2661069",
    "網址": "http://www.thps.cyc.edu.tw"
  },
  {
    "代碼": "104725",
    "學校名稱": "縣立仁和國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[603]嘉義縣梅山鄉太和村社前湖6號",
    "電話": "(05)2661130",
    "網址": "http://www.rhps.cyc.edu.tw"
  },
  {
    "代碼": "104726",
    "學校名稱": "縣立大埔國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[607]嘉義縣大埔鄉大埔村57號",
    "電話": "(05)2521014",
    "網址": "http://www.dpjes.cyc.edu.tw"
  },
  {
    "代碼": "104727",
    "學校名稱": "縣立達邦國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[605]嘉義縣阿里山鄉達邦村一鄰1號",
    "電話": "(05)2511017",
    "網址": "http://www.dbps.cyc.edu.tw"
  },
  {
    "代碼": "104729",
    "學校名稱": "縣立十字國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[605]嘉義縣阿里山鄉十字村16號",
    "電話": "(05)2511340",
    "網址": "http://www.shtps.cyc.edu.tw"
  },
  {
    "代碼": "104730",
    "學校名稱": "縣立來吉國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[605]嘉義縣阿里山鄉來吉村四鄰91號",
    "電話": "(05)2661232",
    "網址": "http://www.ljes.cyc.edu.tw"
  },
  {
    "代碼": "104731",
    "學校名稱": "縣立豐山實驗國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[605]嘉義縣阿里山鄉豐山村47號",
    "電話": "(05)2661071",
    "網址": "http://www.fses.cyc.edu.tw"
  },
  {
    "代碼": "104732",
    "學校名稱": "縣立山美國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[605]嘉義縣阿里山鄉山美村61號",
    "電話": "(05)2586520",
    "網址": "http://www.smes.cyc.edu.tw"
  },
  {
    "代碼": "104733",
    "學校名稱": "縣立新美國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[605]嘉義縣阿里山鄉新美村四鄰78號",
    "電話": "(05)2513015",
    "網址": "http://www.shmps.cyc.edu.tw"
  },
  {
    "代碼": "104734",
    "學校名稱": "縣立阿里山國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[605]嘉義縣阿里山鄉樂野村一鄰31號",
    "電話": "(05)2561180",
    "網址": "http://www.aljes.cyc.edu.tw/"
  },
  {
    "代碼": "104735",
    "學校名稱": "縣立香林國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[605]嘉義縣阿里山鄉香林村二鄰41號",
    "電話": "(05)2679713",
    "網址": "http://www.slps.cyc.edu.tw"
  },
  {
    "代碼": "104736",
    "學校名稱": "縣立布新國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[625]嘉義縣布袋鎮岑海里文昌街61號",
    "電話": "(05)3476886",
    "網址": "http://www.bsps.cyc.edu.tw"
  },
  {
    "代碼": "104737",
    "學校名稱": "縣立茶山國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[605]嘉義縣阿里山鄉茶山村三鄰74號",
    "電話": "(05)2513014",
    "網址": "http://www.chses.cyc.edu.tw"
  },
  {
    "代碼": "104738",
    "學校名稱": "縣立和興國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[606]嘉義縣中埔鄉和興村和興路91號",
    "電話": "(05)2306717",
    "網址": "http://www.hhps.cyc.edu.tw"
  },
  {
    "代碼": "104739",
    "學校名稱": "縣立平林國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[622]嘉義縣大林鎮平林里民權路80號",
    "電話": "(05)2650887",
    "網址": "http://www.ples.cyc.edu.tw"
  },
  {
    "代碼": "104740",
    "學校名稱": "縣立梅北國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[603]嘉義縣梅山鄉梅北村民生街1號",
    "電話": "(05)2626545",
    "網址": "http://www.mbps.cyc.edu.tw"
  },
  {
    "代碼": "104742",
    "學校名稱": "縣立祥和國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[613]嘉義縣朴子市祥和二路西段9號",
    "電話": "(05)3621839",
    "網址": "http://www.shps.cyc.edu.tw"
  },
  {
    "代碼": "104743",
    "學校名稱": "縣立福樂國小",
    "公/私立": "公立",
    "縣市名稱": "[10]嘉義縣",
    "地址": "[621]嘉義縣民雄鄉福樂村光明二街191號",
    "電話": "(05)2206112",
    "網址": "http://www.flps.cyc.edu.tw"
  },
  {
    "代碼": "130601",
    "學校名稱": "國立屏東大學實小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市長春里廣東路20號",
    "電話": "(08)7220451",
    "網址": "http://www.nptups.ptc.edu.tw"
  },
  {
    "代碼": "131601",
    "學校名稱": "私立崇華國小",
    "公/私立": "私立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[908]屏東縣長治鄉復興村新興路38號",
    "電話": "(08)7626365",
    "網址": "http://www.chbes.ptc.edu.tw/"
  },
  {
    "代碼": "134601",
    "學校名稱": "縣立中正國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市文明里蘇州街75號",
    "電話": "(08)7324113",
    "網址": "http://www.ccps.ptc.edu.tw"
  },
  {
    "代碼": "134602",
    "學校名稱": "縣立仁愛國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市興樂里仁愛路98號",
    "電話": "(08)7361114",
    "網址": "http://www.raps.ptc.edu.tw"
  },
  {
    "代碼": "134603",
    "學校名稱": "縣立海豐國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市三山里海豐街3號",
    "電話": "(08)7367023",
    "網址": "http://www.hfps.ptc.edu.tw"
  },
  {
    "代碼": "134604",
    "學校名稱": "縣立公館國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市龍華里公華街182號",
    "電話": "(08)7522205",
    "網址": "http://www.ggps.ptc.edu.tw"
  },
  {
    "代碼": "134605",
    "學校名稱": "縣立大同國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市安鎮里建國路202號",
    "電話": "(08)7524115",
    "網址": "http://www.dtps.ptc.edu.tw"
  },
  {
    "代碼": "134606",
    "學校名稱": "縣立鶴聲國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市崇武里建國路121號",
    "電話": "(08)7521207",
    "網址": "http://www.hsps.ptc.edu.tw"
  },
  {
    "代碼": "134607",
    "學校名稱": "縣立凌雲國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市鵬程里光大巷61號",
    "電話": "(08)7659025",
    "網址": "http://www.lyes.ptc.edu.tw"
  },
  {
    "代碼": "134608",
    "學校名稱": "縣立勝利國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市勝利里蘭州街2號",
    "電話": "(08)7652038",
    "網址": "http://www.slps.ptc.edu.tw"
  },
  {
    "代碼": "134609",
    "學校名稱": "縣立歸來國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市湖西里歸國巷1號",
    "電話": "(08)7223241",
    "網址": "http://www.glps.ptc.edu.tw"
  },
  {
    "代碼": "134610",
    "學校名稱": "縣立前進國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市建國路405巷100弄39號",
    "電話": "(08)7523291",
    "網址": "http://www.cjes.ptc.edu.tw"
  },
  {
    "代碼": "134611",
    "學校名稱": "縣立唐榮國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市崇禮里中山路41號",
    "電話": "(08)7322306",
    "網址": "http://www.trps.ptc.edu.tw"
  },
  {
    "代碼": "134612",
    "學校名稱": "縣立民和國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市自立路213號",
    "電話": "(08)7229210",
    "網址": "http://www.mhps.ptc.edu.tw"
  },
  {
    "代碼": "134613",
    "學校名稱": "縣立建國國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市萬年里建興路36號",
    "電話": "(08)7521464",
    "網址": "http://www.jgps.ptc.edu.tw"
  },
  {
    "代碼": "134614",
    "學校名稱": "縣立復興國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市永安里復興南路376號",
    "電話": "(08)7525402",
    "網址": "http://www.fsps.ptc.edu.tw"
  },
  {
    "代碼": "134615",
    "學校名稱": "縣立忠孝國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市建豐路204號",
    "電話": "(08)7371939",
    "網址": "http://www.chps.ptc.edu.tw"
  },
  {
    "代碼": "134616",
    "學校名稱": "縣立和平國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市中正里華正路80號",
    "電話": "(08)7364440",
    "網址": "http://w3.hpps.ptc.edu.tw/bin/home.php"
  },
  {
    "代碼": "134617",
    "學校名稱": "縣立萬丹國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[913]屏東縣萬丹鄉寶厝村萬新路1497號",
    "電話": "(08)7772014",
    "網址": "http://www.wtps.ptc.edu.tw"
  },
  {
    "代碼": "134618",
    "學校名稱": "縣立新庄國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[913]屏東縣萬丹鄉新庄村新鐘路410號",
    "電話": "(08)7772230",
    "網址": "http://www.sjps.ptc.edu.tw"
  },
  {
    "代碼": "134619",
    "學校名稱": "縣立興華國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[913]屏東縣萬丹鄉崙頂村崙頂路860號",
    "電話": "(08)7061481",
    "網址": "http://www.shps.ptc.edu.tw"
  },
  {
    "代碼": "134620",
    "學校名稱": "縣立新興國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[913]屏東縣萬丹鄉灣內村聖賢路241號",
    "電話": "(08)7062144",
    "網址": "http://www.sses.ptc.edu.tw"
  },
  {
    "代碼": "134621",
    "學校名稱": "縣立社皮國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[913]屏東縣萬丹鄉社口村社皮路2段550號",
    "電話": "(08)7072162",
    "網址": "http://www.spps.ptc.edu.tw"
  },
  {
    "代碼": "134622",
    "學校名稱": "縣立廣安國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[913]屏東縣萬丹鄉廣安村廣志街8巷15號",
    "電話": "(08)7772444",
    "網址": "http://www.kaps.ptc.edu.tw"
  },
  {
    "代碼": "134623",
    "學校名稱": "縣立興化國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[913]屏東縣萬丹鄉興全村興國路52號",
    "電話": "(08)7050390",
    "網址": "http://www.shes.ptc.edu.tw"
  },
  {
    "代碼": "134624",
    "學校名稱": "縣立麟洛國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[909]屏東縣麟洛鄉麟趾村中華路86號",
    "電話": "(08)7221670",
    "網址": "http://www.llps.ptc.edu.tw"
  },
  {
    "代碼": "134625",
    "學校名稱": "縣立九如國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[904]屏東縣九如鄉九如路二段3-1號",
    "電話": "(08)7392159",
    "網址": "http://163.24.6.149/school/web/index.php"
  },
  {
    "代碼": "134626",
    "學校名稱": "縣立後庄國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[904]屏東縣九如鄉耆老村中正路2號",
    "電話": "(08)7750861",
    "網址": "http://www.hjps.ptc.edu.tw"
  },
  {
    "代碼": "134627",
    "學校名稱": "縣立惠農國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[904]屏東縣九如鄉東寧村新庄路1號",
    "電話": "(08)7392805",
    "網址": "http://www.hnps.ptc.edu.tw"
  },
  {
    "代碼": "134628",
    "學校名稱": "縣立長興國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[908]屏東縣長治鄉新潭村潭頭路50號",
    "電話": "(08)7368567",
    "網址": "http://www.csps.ptc.edu.tw"
  },
  {
    "代碼": "134629",
    "學校名稱": "縣立繁華國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[908]屏東縣長治鄉繁華村水源路96號",
    "電話": "(08)7621254",
    "網址": "http://www.fhps.ptc.edu.tw"
  },
  {
    "代碼": "134630",
    "學校名稱": "縣立德協國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[908]屏東縣長治鄉德成村中興路551號",
    "電話": "(08)7621844",
    "網址": "http://www.dses.ptc.edu.tw"
  },
  {
    "代碼": "134631",
    "學校名稱": "縣立鹽埔國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[907]屏東縣鹽埔鄉鹽南村光復路90號",
    "電話": "(08)7932271",
    "網址": "http://www.ypps.ptc.edu.tw"
  },
  {
    "代碼": "134632",
    "學校名稱": "縣立仕絨國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[907]屏東縣鹽埔鄉仕絨村東平街16號",
    "電話": "(08)7932544",
    "網址": "http://www.srps.ptc.edu.tw"
  },
  {
    "代碼": "134633",
    "學校名稱": "縣立高朗國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[907]屏東縣鹽埔鄉高朗村民治路37號",
    "電話": "(08)7932444",
    "網址": "http://www.gles.ptc.edu.tw"
  },
  {
    "代碼": "134634",
    "學校名稱": "縣立新圍國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[907]屏東縣鹽埔鄉新二村維新路67號",
    "電話": "(08)7932269",
    "網址": "http://web.shwps.ptc.edu.tw/"
  },
  {
    "代碼": "134635",
    "學校名稱": "縣立彭厝國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[907]屏東縣鹽埔鄉洛陽村七份路21號",
    "電話": "(08)7031509",
    "網址": "http://www.pcps.ptc.edu.tw"
  },
  {
    "代碼": "134636",
    "學校名稱": "縣立振興國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[907]屏東縣鹽埔鄉振興村光明路36號",
    "電話": "(08)7020501",
    "網址": "http://www.jses.ptc.edu.tw"
  },
  {
    "代碼": "134637",
    "學校名稱": "縣立高樹國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[906]屏東縣高樹鄉高樹村華光路32號",
    "電話": "(08)7960802",
    "網址": "http://www.gsps.ptc.edu.tw"
  },
  {
    "代碼": "134638",
    "學校名稱": "縣立舊寮國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[906]屏東縣高樹鄉舊寮村長美路42號",
    "電話": "(08)7962994",
    "網址": "http://www.jolps.ptc.edu.tw"
  },
  {
    "代碼": "134639",
    "學校名稱": "縣立新豐國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[906]屏東縣高樹鄉新豐村泰和路26號",
    "電話": "(08)7916486",
    "網址": "http://www.sfps.ptc.edu.tw"
  },
  {
    "代碼": "134640",
    "學校名稱": "縣立田子國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[906]屏東縣高樹鄉鹽樹村公平路19號",
    "電話": "(08)7962714",
    "網址": "http://www.tzps.ptc.edu.tw"
  },
  {
    "代碼": "134641",
    "學校名稱": "縣立新南國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[906]屏東縣高樹鄉新南村興店路5號",
    "電話": "(08)7962782",
    "網址": "http://www.snps.ptc.edu.tw"
  },
  {
    "代碼": "134642",
    "學校名稱": "縣立泰山國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[906]屏東縣高樹鄉泰山村產業路84號",
    "電話": "(08)7956274",
    "網址": "http://www.tsps.ptc.edu.tw"
  },
  {
    "代碼": "134644",
    "學校名稱": "縣立大路關國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[906]屏東縣高樹鄉廣興村中正路176號",
    "電話": "(08)7957412",
    "網址": "http://www.gosps.ptc.edu.tw"
  },
  {
    "代碼": "134645",
    "學校名稱": "縣立南華國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[906]屏東縣高樹鄉南華村世一路1號",
    "電話": "(08)7956544",
    "網址": "http://www.nhes.ptc.edu.tw"
  },
  {
    "代碼": "134646",
    "學校名稱": "縣立里港國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[905]屏東縣里港鄉過江路41號",
    "電話": "(08)7752161",
    "網址": "http://www.lgps.ptc.edu.tw"
  },
  {
    "代碼": "134647",
    "學校名稱": "縣立載興國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[905]屏東縣里港鄉載興村載興路1之3號",
    "電話": "(08)7752271",
    "網址": "http://www.tses.ptc.edu.tw"
  },
  {
    "代碼": "134648",
    "學校名稱": "縣立土庫國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[905]屏東縣里港鄉中和村中和路33號",
    "電話": "(08)7731553",
    "網址": "http://www.tkps.ptc.edu.tw"
  },
  {
    "代碼": "134649",
    "學校名稱": "縣立三和國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[905]屏東縣里港鄉土庫路111之3號",
    "電話": "(08)7731198",
    "網址": "http://www.sahes.ptc.edu.tw"
  },
  {
    "代碼": "134651",
    "學校名稱": "縣立潮州國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[920]屏東縣潮州鎮同榮里育英路31號",
    "電話": "(08)7882770",
    "網址": "http://www.cjps.ptc.edu.tw"
  },
  {
    "代碼": "134652",
    "學校名稱": "縣立光春國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[920]屏東縣潮州鎮光春里志成路201號",
    "電話": "(08)7896550",
    "網址": "http://www.gcps.ptc.edu.tw"
  },
  {
    "代碼": "134653",
    "學校名稱": "縣立光華國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[920]屏東縣潮州鎮光華里南進路2號",
    "電話": "(08)7882105",
    "網址": "http://www.khps.ptc.edu.tw"
  },
  {
    "代碼": "134654",
    "學校名稱": "縣立四林國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[920]屏東縣潮州鎮泗林里開元路2巷1號",
    "電話": "(08)7882174",
    "網址": "http://www.sles.ptc.edu.tw"
  },
  {
    "代碼": "134655",
    "學校名稱": "縣立潮南國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[920]屏東縣潮州鎮興美里興美路24號",
    "電話": "(08)7882312",
    "網址": "http://www.cnps.ptc.edu.tw"
  },
  {
    "代碼": "134656",
    "學校名稱": "縣立潮東國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[920]屏東縣潮州鎮九塊里復興路66號",
    "電話": "(08)7871364",
    "網址": "http://www.ctps.ptc.edu.tw"
  },
  {
    "代碼": "134657",
    "學校名稱": "縣立萬巒國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[923]屏東縣萬巒鄉萬全村堡忠路28號",
    "電話": "(08)7811627",
    "網址": "http://www.wles.ptc.edu.tw"
  },
  {
    "代碼": "134658",
    "學校名稱": "縣立五溝國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[923]屏東縣萬巒鄉五溝村西盛路89號",
    "電話": "(08)7832285",
    "網址": "http://web.wgps.ptc.edu.tw/home/index2"
  },
  {
    "代碼": "134659",
    "學校名稱": "縣立佳佐國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[923]屏東縣萬巒鄉佳和村佳興路59號",
    "電話": "(08)7832018",
    "網址": "http://www.jtes.ptc.edu.tw"
  },
  {
    "代碼": "134661",
    "學校名稱": "縣立赤山國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[923]屏東縣萬巒鄉赤山村建興路40號",
    "電話": "(08)7832205",
    "網址": "http://www.chsps.ptc.edu.tw"
  },
  {
    "代碼": "134662",
    "學校名稱": "縣立內埔國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[912]屏東縣內埔鄉內埔村廣濟路2號",
    "電話": "(08)7792055",
    "網址": "http://www.npes.ptc.edu.tw"
  },
  {
    "代碼": "134663",
    "學校名稱": "縣立育英國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[912]屏東縣內埔鄉興南村育文路73號",
    "電話": "(08)7793004",
    "網址": "http://www.yips.ptc.edu.tw"
  },
  {
    "代碼": "134664",
    "學校名稱": "縣立僑智國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[912]屏東縣內埔鄉美和村學人路55號",
    "電話": "(08)7792544",
    "網址": "http://www.chjps.ptc.edu.tw"
  },
  {
    "代碼": "134665",
    "學校名稱": "縣立崇文國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[912]屏東縣內埔鄉龍泉村原勝路6號",
    "電話": "(08)7701004",
    "網址": "http://www.cwps.ptc.edu.tw"
  },
  {
    "代碼": "134666",
    "學校名稱": "縣立新生國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[912]屏東縣內埔鄉大新村大新路109號",
    "電話": "(08)7708211",
    "網址": "http://www.ssps.ptc.edu.tw"
  },
  {
    "代碼": "134667",
    "學校名稱": "縣立榮華國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[912]屏東縣內埔鄉建興村建興路216號",
    "電話": "(08)7701104",
    "網址": "http://www.rhes.ptc.edu.tw"
  },
  {
    "代碼": "134668",
    "學校名稱": "縣立黎明國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[912]屏東縣內埔鄉黎明村西淇路177號",
    "電話": "(08)7701200",
    "網址": "http://www.lmps.ptc.edu.tw"
  },
  {
    "代碼": "134669",
    "學校名稱": "縣立隘寮國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[912]屏東縣內埔鄉隘寮村清涼路二段238號",
    "電話": "(08)7991036",
    "網址": "http://www.alps.ptc.edu.tw"
  },
  {
    "代碼": "134670",
    "學校名稱": "縣立泰安國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[912]屏東縣內埔鄉老埤村壽比路116號",
    "電話": "(08)7783031",
    "網址": "http://www.taps.ptc.edu.tw"
  },
  {
    "代碼": "134671",
    "學校名稱": "縣立東勢國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[912]屏東縣內埔鄉東勢村大同路三段20號",
    "電話": "(08)7792449",
    "網址": "http://www.dsps.ptc.edu.tw"
  },
  {
    "代碼": "134672",
    "學校名稱": "縣立豐田國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[912]屏東縣內埔鄉豐田村新中路49號",
    "電話": "(08)7792445",
    "網址": "http://www.ftes.ptc.edu.tw"
  },
  {
    "代碼": "134673",
    "學校名稱": "縣立富田國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[912]屏東縣內埔鄉富田村里仁路202號",
    "電話": "(08)7791034",
    "網址": "http://www.ftps.ptc.edu.tw"
  },
  {
    "代碼": "134674",
    "學校名稱": "縣立竹田國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[911]屏東縣竹田鄉竹田村公正路35號",
    "電話": "(08)7710834",
    "網址": "http://www.jtps.ptc.edu.tw"
  },
  {
    "代碼": "134675",
    "學校名稱": "縣立西勢國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[911]屏東縣竹田鄉西勢村光明路8之1號",
    "電話": "(08)7793474",
    "網址": "http://www.sisps.ptc.edu.tw"
  },
  {
    "代碼": "134676",
    "學校名稱": "縣立大明國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[911]屏東縣竹田鄉泗洲村洲中路40號",
    "電話": "(08)7887780",
    "網址": "http://www.dmps.ptc.edu.tw"
  },
  {
    "代碼": "134677",
    "學校名稱": "縣立新埤國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[925]屏東縣新埤鄉新埤村新華路165號",
    "電話": "(08)7971027",
    "網址": "http://www.spes.ptc.edu.tw"
  },
  {
    "代碼": "134678",
    "學校名稱": "縣立大成國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[925]屏東縣新埤鄉打鐵村東興路33號",
    "電話": "(08)7971078",
    "網址": "http://www.dcps.ptc.edu.tw"
  },
  {
    "代碼": "134679",
    "學校名稱": "縣立萬隆國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[925]屏東縣新埤鄉萬隆村中山路126號",
    "電話": "(08)7870191",
    "網址": "http://www.wlps.ptc.edu.tw"
  },
  {
    "代碼": "134680",
    "學校名稱": "縣立餉潭國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[925]屏東縣新埤鄉餉潭村龍潭路149號",
    "電話": "(08)7981600",
    "網址": "http://www.stps.ptc.edu.tw"
  },
  {
    "代碼": "134681",
    "學校名稱": "縣立枋寮國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[940]屏東縣枋寮鄉德興路197號",
    "電話": "(08)8782034",
    "網址": "http://www.flps.ptc.edu.tw"
  },
  {
    "代碼": "134682",
    "學校名稱": "縣立僑德國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[940]屏東縣枋寮鄉隆山村僑德路186號",
    "電話": "(08)8782096",
    "網址": "http://www.cdps.ptc.edu.tw"
  },
  {
    "代碼": "134683",
    "學校名稱": "縣立建興國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[940]屏東縣枋寮鄉人和村建興路39號",
    "電話": "(08)8713562",
    "網址": "http://www.jsps.ptc.edu.tw"
  },
  {
    "代碼": "134684",
    "學校名稱": "縣立東海國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[940]屏東縣枋寮鄉東海村東海路486號",
    "電話": "(08)8712209",
    "網址": "http://163.24.90.249"
  },
  {
    "代碼": "134686",
    "學校名稱": "縣立東港國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[928]屏東縣東港鎮興東里中正路一段10號",
    "電話": "(08)8322019",
    "網址": "http://www.dkps.ptc.edu.tw"
  },
  {
    "代碼": "134687",
    "學校名稱": "縣立東隆國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[928]屏東縣東港鎮共和里共和路45號",
    "電話": "(08)8322064",
    "網址": "http://www.dlps.ptc.edu.tw"
  },
  {
    "代碼": "134688",
    "學校名稱": "縣立海濱國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[928]屏東縣東港鎮豐漁里豐漁街34之2號",
    "電話": "(08)8322244",
    "網址": "http://www.loxa.edu.tw/schoolweb.html?webId=318"
  },
  {
    "代碼": "134689",
    "學校名稱": "縣立以栗國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[928]屏東縣東港鎮船頭里船頭路25號",
    "電話": "(08)8322816",
    "網址": "http://www.ylps.ptc.edu.tw"
  },
  {
    "代碼": "134690",
    "學校名稱": "縣立大潭國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[928]屏東縣東港鎮大潭里大潭路93號",
    "電話": "(08)8324226",
    "網址": "http://www.dtes.ptc.edu.tw"
  },
  {
    "代碼": "134691",
    "學校名稱": "縣立新園國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[932]屏東縣新園鄉新園村媽祖路49號",
    "電話": "(08)8681113",
    "網址": "http://www.syps.ttct.edu.tw/front/bin/home.phtml"
  },
  {
    "代碼": "134692",
    "學校名稱": "縣立仙吉國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[932]屏東縣新園鄉仙吉村仙吉路91號",
    "電話": "(08)8681087",
    "網址": "http://www.sjes.ptc.edu.tw"
  },
  {
    "代碼": "134693",
    "學校名稱": "縣立烏龍國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[932]屏東縣新園鄉南龍村南興路217號",
    "電話": "(08)8325896",
    "網址": "http://www.wules.ptc.edu.tw"
  },
  {
    "代碼": "134694",
    "學校名稱": "縣立港西國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[932]屏東縣新園鄉港西村中和路101號",
    "電話": "(08)8681644",
    "網址": "http://www.ksps.ptc.edu.tw"
  },
  {
    "代碼": "134695",
    "學校名稱": "縣立鹽洲國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[932]屏東縣新園鄉鹽埔村鹽洲路379號",
    "電話": "(08)8322975",
    "網址": "http://www.yjps.ptc.edu.tw"
  },
  {
    "代碼": "134696",
    "學校名稱": "縣立琉球國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[929]屏東縣琉球鄉大福村中正路293號",
    "電話": "(08)8612597",
    "網址": "http://www.lcps.ptc.edu.tw"
  },
  {
    "代碼": "134697",
    "學校名稱": "縣立天南國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[929]屏東縣琉球鄉南福村忠孝路27號",
    "電話": "(08)8612300",
    "網址": "http://www.tnps.ptc.edu.tw"
  },
  {
    "代碼": "134698",
    "學校名稱": "縣立全德國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[929]屏東縣琉球鄉杉福村復興路97號",
    "電話": "(08)8612534",
    "網址": "http://www.cdes.ptc.edu.tw"
  },
  {
    "代碼": "134699",
    "學校名稱": "縣立白沙國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[929]屏東縣琉球鄉漁福村三民路124號",
    "電話": "(08)8612510",
    "網址": "http://163.23.88.193"
  },
  {
    "代碼": "134700",
    "學校名稱": "縣立崁頂國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[924]屏東縣崁頂鄉崁頂村中正路119號",
    "電話": "(08)8631959",
    "網址": "http://www.kdes.ptc.edu.tw"
  },
  {
    "代碼": "134701",
    "學校名稱": "縣立港東國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[924]屏東縣崁頂鄉越溪村復興路96號",
    "電話": "(08)8633272",
    "網址": "http://www.kdps.ptc.edu.tw"
  },
  {
    "代碼": "134702",
    "學校名稱": "縣立力社國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[924]屏東縣崁頂鄉力社村力社路36號",
    "電話": "(08)8631544",
    "網址": "http://www.lsps.ptc.edu.tw"
  },
  {
    "代碼": "134703",
    "學校名稱": "縣立林邊國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[927]屏東縣林邊鄉林邊村中山路68號",
    "電話": "(08)8752120",
    "網址": "http://www.lbps.ptc.edu.tw"
  },
  {
    "代碼": "134704",
    "學校名稱": "縣立仁和國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[927]屏東縣林邊鄉仁和村中山路417號",
    "電話": "(08)8752201",
    "網址": "http://www.rhps.ptc.edu.tw"
  },
  {
    "代碼": "134705",
    "學校名稱": "縣立竹林國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[927]屏東縣林邊鄉竹林村中興路122號",
    "電話": "(08)8752884",
    "網址": "http://www.jlps.ptc.edu.tw"
  },
  {
    "代碼": "134706",
    "學校名稱": "縣立崎峰國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[927]屏東縣林邊鄉崎峰村裕後路2號",
    "電話": "(08)8752400",
    "網址": "http://www.cfps.ptc.edu.tw"
  },
  {
    "代碼": "134707",
    "學校名稱": "縣立水利國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[927]屏東縣林邊鄉水利村豐漁路117號",
    "電話": "(08)8752745",
    "網址": "http://www.sulps.ptc.edu.tw"
  },
  {
    "代碼": "134708",
    "學校名稱": "縣立南州國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[926]屏東縣南州鄉溪南村人和路191號",
    "電話": "(08)8642097",
    "網址": "http://www.njps.ptc.edu.tw"
  },
  {
    "代碼": "134709",
    "學校名稱": "縣立同安國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[926]屏東縣南州鄉同安村同安路79號",
    "電話": "(08)8642334",
    "網址": "http://www.taes.ptc.edu.tw"
  },
  {
    "代碼": "134710",
    "學校名稱": "縣立溪北國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[926]屏東縣南州鄉溪北村仁里路102號",
    "電話": "(08)8642081",
    "網址": "http://www.sbps.ptc.edu.tw"
  },
  {
    "代碼": "134711",
    "學校名稱": "縣立佳冬國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[931]屏東縣佳冬鄉進學街150號",
    "電話": "(08)8662009",
    "網址": "http://www.jdps.ptc.edu.tw"
  },
  {
    "代碼": "134712",
    "學校名稱": "縣立塭子國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[931]屏東縣佳冬鄉塭豐村中興路1號",
    "電話": "(08)8662094",
    "網址": "http://www.uzps.ptc.edu.tw"
  },
  {
    "代碼": "134713",
    "學校名稱": "縣立羌園國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[931]屏東縣佳冬鄉羌園村羌光路669號",
    "電話": "(08)8662474",
    "網址": "http://www.cyps.ptc.edu.tw"
  },
  {
    "代碼": "134714",
    "學校名稱": "縣立昌隆國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[931]屏東縣佳冬鄉昌隆村中正路44號",
    "電話": "(08)8662093",
    "網址": "http://www.chles.ptc.edu.tw"
  },
  {
    "代碼": "134715",
    "學校名稱": "縣立大新國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[931]屏東縣佳冬鄉大同村武丁路33號",
    "電話": "(08)8662175",
    "網址": "http://www.dasps.ptc.edu.tw"
  },
  {
    "代碼": "134716",
    "學校名稱": "縣立玉光國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[931]屏東縣佳冬鄉玉光村民學路81號",
    "電話": "(08)8662629",
    "網址": "http://www.yges.ptc.edu.tw"
  },
  {
    "代碼": "134717",
    "學校名稱": "縣立恆春國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[946]屏東縣恆春鎮城北里北門路39巷26號",
    "電話": "(08)8892035",
    "網址": "http://www.hcps.ptc.edu.tw"
  },
  {
    "代碼": "134718",
    "學校名稱": "縣立僑勇國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[946]屏東縣恆春鎮城北里西門路146號",
    "電話": "(08)8892141",
    "網址": "http://www.chyps.ptc.edu.tw"
  },
  {
    "代碼": "134720",
    "學校名稱": "縣立山海國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[946]屏東縣恆春鎮山海里山海路86號",
    "電話": "(08)8869026",
    "網址": "http://www.sahps.ptc.edu.tw"
  },
  {
    "代碼": "134721",
    "學校名稱": "縣立大光國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[946]屏東縣恆春鎮大光里大光路8之6號",
    "電話": "(08)8867064",
    "網址": "http://www.dgps.ptc.edu.tw"
  },
  {
    "代碼": "134722",
    "學校名稱": "縣立水泉國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[946]屏東縣恆春鎮水泉里頂泉路1號",
    "電話": "(08)8866208",
    "網址": "http://www.scps.ptc.edu.tw"
  },
  {
    "代碼": "134723",
    "學校名稱": "縣立大平國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[946]屏東縣恆春鎮頭溝里頭溝路161號",
    "電話": "(08)8891584",
    "網址": "http://www.dpps.ptc.edu.tw"
  },
  {
    "代碼": "134724",
    "學校名稱": "縣立墾丁國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[946]屏東縣恆春鎮墾丁里墾丁路65號",
    "電話": "(08)8861081",
    "網址": "http://www.ktps.ptc.edu.tw"
  },
  {
    "代碼": "134725",
    "學校名稱": "縣立車城國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[944]屏東縣車城鄉福興村中山路66號",
    "電話": "(08)8821006",
    "網址": "http://www.cces.ptc.edu.tw"
  },
  {
    "代碼": "134729",
    "學校名稱": "縣立滿州國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[947]屏東縣滿州鄉滿州村中山路39號",
    "電話": "(08)8801162",
    "網址": "http://www.mjps.ptc.edu.tw"
  },
  {
    "代碼": "134730",
    "學校名稱": "縣立長樂國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[947]屏東縣滿州鄉長樂村大公路35號",
    "電話": "(08)8811045",
    "網址": "http://www.chlps.ptc.edu.tw"
  },
  {
    "代碼": "134731",
    "學校名稱": "縣立永港國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[947]屏東縣滿州鄉永靖村新庄路25號",
    "電話": "(08)8801125",
    "網址": "http://www.ygps.ptc.edu.tw"
  },
  {
    "代碼": "134735",
    "學校名稱": "縣立楓港國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[941]屏東縣枋山鄉善餘村光復路24號",
    "電話": "(08)8771158",
    "網址": "http://www.fkps.ptc.edu.tw"
  },
  {
    "代碼": "134736",
    "學校名稱": "縣立加祿國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[941]屏東縣枋山鄉加祿村會社路28號",
    "電話": "(08)8720060",
    "網址": "http://www.clups.ptc.edu.tw"
  },
  {
    "代碼": "134737",
    "學校名稱": "縣立地磨兒國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[901]屏東縣三地門鄉三地村行政街9號",
    "電話": "(08)7991462",
    "網址": "http://www.sdps.ptc.edu.tw"
  },
  {
    "代碼": "134740",
    "學校名稱": "縣立青山國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[901]屏東縣三地門鄉青山村民族巷9號",
    "電話": "(08)7962493",
    "網址": "http://www.chses.ptc.edu.tw"
  },
  {
    "代碼": "134741",
    "學校名稱": "縣立青葉國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[901]屏東縣三地門鄉青葉村光復巷1之2號",
    "電話": "(08)7961997",
    "網址": "http://www.cyes.ptc.edu.tw"
  },
  {
    "代碼": "134742",
    "學校名稱": "縣立口社國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[901]屏東縣三地門鄉口社村信義巷65號",
    "電話": "(08)7956495",
    "網址": "http://www.kses.ptc.edu.tw"
  },
  {
    "代碼": "134744",
    "學校名稱": "縣立佳義國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[903]屏東縣瑪家鄉佳義村泰平巷12號",
    "電話": "(08)7990038",
    "網址": "http://www.jyps.ptc.edu.tw"
  },
  {
    "代碼": "134746",
    "學校名稱": "縣立北葉國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[903]屏東縣瑪家鄉北葉村風景巷1-22號",
    "電話": "(08)7991649",
    "網址": "http://www.byps.ptc.edu.tw"
  },
  {
    "代碼": "134748",
    "學校名稱": "縣立霧臺國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[902]屏東縣霧臺鄉霧臺村中山巷45號",
    "電話": "(08)7902230",
    "網址": "http://www.wutps.ptc.edu.tw"
  },
  {
    "代碼": "134751",
    "學校名稱": "縣立武潭國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[921]屏東縣泰武鄉武潭村潭中巷45號",
    "電話": "(08)7833179",
    "網址": "http://www.wtes.ptc.edu.tw"
  },
  {
    "代碼": "134752",
    "學校名稱": "縣立泰武國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[921]屏東縣泰武鄉泰武村阿夫魯岸路1號",
    "電話": "(08)7837093",
    "網址": "http://www.twps.ptc.edu.tw"
  },
  {
    "代碼": "134753",
    "學校名稱": "縣立萬安國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[921]屏東縣泰武鄉萬安村萬安路1號",
    "電話": "(08)7831902",
    "網址": "http://www.waps.ptc.edu.tw"
  },
  {
    "代碼": "134754",
    "學校名稱": "縣立來義國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[922]屏東縣來義鄉丹林村古義路2號",
    "電話": "(08)7850411",
    "網址": "http://www.lyps.ptc.edu.tw"
  },
  {
    "代碼": "134755",
    "學校名稱": "縣立望嘉國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[922]屏東縣來義鄉望嘉村165號",
    "電話": "(08)7981383",
    "網址": "http://www.wcps.ptc.edu.tw"
  },
  {
    "代碼": "134756",
    "學校名稱": "縣立文樂國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[922]屏東縣來義鄉文樂村新樂路1號",
    "電話": "(08)7981836",
    "網址": "http://www.welps.ptc.edu.tw"
  },
  {
    "代碼": "134757",
    "學校名稱": "縣立南和國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[922]屏東縣來義鄉南和村51號",
    "電話": "(08)8791263",
    "網址": "http://www.nhps.ptc.edu.tw"
  },
  {
    "代碼": "134758",
    "學校名稱": "縣立古樓國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[922]屏東縣來義鄉古樓村育英路2號",
    "電話": "(08)7850281",
    "網址": "http://www.ghes.ptc.edu.tw"
  },
  {
    "代碼": "134759",
    "學校名稱": "縣立春日國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[942]屏東縣春日鄉春日村春日路314號",
    "電話": "(08)8782433",
    "網址": "http://www.crps.ptc.edu.tw"
  },
  {
    "代碼": "134760",
    "學校名稱": "縣立力里國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[942]屏東縣春日鄉七佳村自強一路92號",
    "電話": "(08)8791020",
    "網址": "http://www.lles.ptc.edu.tw"
  },
  {
    "代碼": "134761",
    "學校名稱": "縣立古華國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[942]屏東縣春日鄉古華村古華路3巷63號",
    "電話": "(08)8781040",
    "網址": "http://www.ghps.ptc.edu.tw"
  },
  {
    "代碼": "134762",
    "學校名稱": "縣立楓林國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[943]屏東縣獅子鄉楓林村楓林2巷22-2號",
    "電話": "(08)8771371",
    "網址": "http://www.fles.ptc.edu.tw"
  },
  {
    "代碼": "134763",
    "學校名稱": "縣立丹路國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[943]屏東縣獅子鄉丹路村丹路1巷39號",
    "電話": "(08)8771455",
    "網址": "http://www.dles.ptc.edu.tw"
  },
  {
    "代碼": "134764",
    "學校名稱": "縣立內獅國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[943]屏東縣獅子鄉內獅村1號",
    "電話": "(08)8720003",
    "網址": "http://www.nses.ptc.edu.tw"
  },
  {
    "代碼": "134765",
    "學校名稱": "縣立草埔國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[943]屏東縣獅子鄉草埔村1巷5號",
    "電話": "(08)8701449",
    "網址": "http://www.cpps.ptc.edu.tw"
  },
  {
    "代碼": "134766",
    "學校名稱": "縣立石門國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[945]屏東縣牡丹鄉石門村石門路33號",
    "電話": "(08)8831005",
    "網址": "http://www.smps.ptc.edu.tw"
  },
  {
    "代碼": "134768",
    "學校名稱": "縣立高士國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[945]屏東縣牡丹鄉高士村高士路29號",
    "電話": "(08)8810246",
    "網址": "http://www.gses.ptc.edu.tw"
  },
  {
    "代碼": "134769",
    "學校名稱": "縣立牡丹國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[945]屏東縣牡丹鄉牡丹村牡丹路93號",
    "電話": "(08)8830065",
    "網址": "http://www.mdps.ptc.edu.tw"
  },
  {
    "代碼": "134771",
    "學校名稱": "縣立三多國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[904]屏東縣九如鄉三塊村三民路307號",
    "電話": "(08)7759095",
    "網址": "http://www.sdes.ptc.edu.tw"
  },
  {
    "代碼": "134772",
    "學校名稱": "縣立信義國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市信義路262號",
    "電話": "(08)7383628",
    "網址": "http://www.syps.ptc.edu.tw"
  },
  {
    "代碼": "134773",
    "學校名稱": "縣立瑞光國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市興豐路86號",
    "電話": "(08)7383011",
    "網址": "http://www.rgps.ptc.edu.tw"
  },
  {
    "代碼": "134774",
    "學校名稱": "縣立崇蘭國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市廣東路1259號",
    "電話": "(08)7333477",
    "網址": "http://www.clps.ptc.edu.tw"
  },
  {
    "代碼": "134775",
    "學校名稱": "縣立四維國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[913]屏東縣萬丹鄉四維村四維東路567號",
    "電話": "(08)7761987",
    "網址": "http://www.swps.ptc.edu.tw"
  },
  {
    "代碼": "134776",
    "學校名稱": "縣立東寧國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[912]屏東縣內埔鄉內田村自強路85號",
    "電話": "(08)7785563",
    "網址": "http://www.dnps.ptc.edu.tw"
  },
  {
    "代碼": "134777",
    "學校名稱": "縣立潮昇國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[920]屏東縣潮州鎮北門路25號",
    "電話": "(08)7805568",
    "網址": "http://www.csnps.ptc.edu.tw"
  },
  {
    "代碼": "134778",
    "學校名稱": "縣立潮和國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[920]屏東縣潮州鎮三和里華巖街1號",
    "電話": "(08)7804052",
    "網址": "http://www.chhps.ptc.edu.tw"
  },
  {
    "代碼": "134779",
    "學校名稱": "縣立東興國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[928]屏東縣東港鎮興東里興東路43-1號",
    "電話": "(08)8310406",
    "網址": "http://www.ts.ptc.edu.tw"
  },
  {
    "代碼": "134780",
    "學校名稱": "縣立塔樓國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[905]屏東縣里港鄉塔樓村塔樓路21之3號",
    "電話": "(08)7756625",
    "網址": "http://www.tlps.ptc.edu.tw"
  },
  {
    "代碼": "134781",
    "學校名稱": "縣立玉田國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[905]屏東縣里港鄉玉田村八德路91號",
    "電話": "(08)7752816",
    "網址": "http://www.ytes.ptc.edu.tw"
  },
  {
    "代碼": "134783",
    "學校名稱": "縣立東光國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[928]屏東縣東港鎮興東里長春二路15號",
    "電話": "(08)8324927",
    "網址": "http://www.dkes.ptc.edu.tw"
  },
  {
    "代碼": "134784",
    "學校名稱": "縣立賽嘉國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[901]屏東縣三地門鄉賽嘉村2號",
    "電話": "(08)7992220",
    "網址": "http://www.sces.ptc.edu.tw"
  },
  {
    "代碼": "134785",
    "學校名稱": "縣立瓦磘國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[932]屏東縣新園鄉瓦磘村四環路3號",
    "電話": "(08)8684050",
    "網址": "http://www.wyps.ptc.edu.tw"
  },
  {
    "代碼": "134786",
    "學校名稱": "縣立民生國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[900]屏東縣屏東市瑞光里安心四橫巷139-1號",
    "電話": "(08)7216566",
    "網址": "http://www.msps.ptc.edu.tw"
  },
  {
    "代碼": "134787",
    "學校名稱": "縣立長榮百合國小",
    "公/私立": "公立",
    "縣市名稱": "[13]屏東縣",
    "地址": "[903]屏東縣瑪家鄉瑪家村和平路一段65號",
    "電話": "(08)7992750",
    "網址": "http://163.24.148.10"
  },
  {
    "代碼": "140601",
    "學校名稱": "國立臺東大學附小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市博愛路345號",
    "電話": "(089)322047",
    "網址": "http://www.nttues.nttu.edu.tw"
  },
  {
    "代碼": "144601",
    "學校名稱": "縣立仁愛國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市四維路一段400號",
    "電話": "(089)322071",
    "網址": "http://www.raps.ttct.edu.tw"
  },
  {
    "代碼": "144602",
    "學校名稱": "縣立復興國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市鐵花路87巷55號",
    "電話": "(089)322672",
    "網址": "http://www.fsps.ttct.edu.tw"
  },
  {
    "代碼": "144603",
    "學校名稱": "縣立光明國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市中興路二段150巷29弄1號",
    "電話": "(089)226304",
    "網址": "http://www.gmps.ttct.edu.tw"
  },
  {
    "代碼": "144604",
    "學校名稱": "縣立寶桑國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市四維路二段23號",
    "電話": "(089)322807",
    "網址": "http://www.bsps.ttct.edu.tw"
  },
  {
    "代碼": "144605",
    "學校名稱": "縣立新生國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市更生路474巷45號",
    "電話": "(089)322039",
    "網址": "http://www.ssps.ttct.edu.tw"
  },
  {
    "代碼": "144606",
    "學校名稱": "縣立豐里國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市中華路三段80號",
    "電話": "(089)324040",
    "網址": "http://www.flps.ttct.edu.tw"
  },
  {
    "代碼": "144607",
    "學校名稱": "縣立豐榮國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市中華路二段154巷150號",
    "電話": "(089)323353",
    "網址": "http://www.frps.ttct.edu.tw"
  },
  {
    "代碼": "144608",
    "學校名稱": "縣立馬蘭國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市馬蘭里新社三街6號",
    "電話": "(089)229437",
    "網址": "http://www.mlps.ttct.edu.tw"
  },
  {
    "代碼": "144609",
    "學校名稱": "縣立豐源國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市中華路四段392號",
    "電話": "(089)322063",
    "網址": "http://www.fayps.ttct.edu.tw"
  },
  {
    "代碼": "144610",
    "學校名稱": "縣立康樂國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市山西路一段256號",
    "電話": "(089)226131",
    "網址": "http://www.klps.ttct.edu.tw"
  },
  {
    "代碼": "144611",
    "學校名稱": "縣立豐年國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市中興路三段320號",
    "電話": "(089)226733",
    "網址": "http://www.fnps.ttct.edu.tw"
  },
  {
    "代碼": "144612",
    "學校名稱": "縣立卑南國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市卑南里更生北路317號",
    "電話": "(089)223096",
    "網址": "http://www.bnps.ttct.edu.tw"
  },
  {
    "代碼": "144613",
    "學校名稱": "縣立岩灣國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市岩灣路50巷401弄11號",
    "電話": "(089)223415",
    "網址": "http://www.yups.ttct.edu.tw"
  },
  {
    "代碼": "144614",
    "學校名稱": "縣立南王國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市南王里更生北路726號",
    "電話": "(089)223203",
    "網址": "http://www.nwps.ttct.edu.tw"
  },
  {
    "代碼": "144615",
    "學校名稱": "縣立知本國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市知本路三段107號",
    "電話": "(089)512234",
    "網址": "http://www.jbps.ttct.edu.tw"
  },
  {
    "代碼": "144616",
    "學校名稱": "縣立建和國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市青海路三段640號",
    "電話": "(089)512381",
    "網址": "http://www.jhps.ttct.edu.tw"
  },
  {
    "代碼": "144617",
    "學校名稱": "縣立豐田國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市中興路四段750號",
    "電話": "(089)380345",
    "網址": "http://www.ftps.ttct.edu.tw"
  },
  {
    "代碼": "144618",
    "學校名稱": "縣立富岡國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市松江路一段441號",
    "電話": "(089)281352",
    "網址": "http://www.fgps.ttct.edu.tw"
  },
  {
    "代碼": "144619",
    "學校名稱": "縣立新園國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市新園路88號",
    "電話": "(089)382590",
    "網址": "http://www.syps.ttct.edu.tw"
  },
  {
    "代碼": "144620",
    "學校名稱": "縣立賓朗國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[954]臺東縣卑南鄉賓朗村474巷2號",
    "電話": "(089)224129",
    "網址": "http://www.blps.ttct.edu.tw"
  },
  {
    "代碼": "144621",
    "學校名稱": "縣立溫泉國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[954]臺東縣卑南鄉溫泉村溫泉路291號",
    "電話": "(089)512354",
    "網址": "http://www.hses.ttct.edu.tw/"
  },
  {
    "代碼": "144622",
    "學校名稱": "縣立利嘉國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[954]臺東縣卑南鄉利嘉村利嘉路666號",
    "電話": "(08)9380121",
    "網址": "http://www.ljps.ttct.edu.tw"
  },
  {
    "代碼": "144623",
    "學校名稱": "縣立初鹿國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[954]臺東縣卑南鄉初鹿村梅園路97號",
    "電話": "(089)571027",
    "網址": "http://www.clps.ttct.edu.tw"
  },
  {
    "代碼": "144624",
    "學校名稱": "縣立東成國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[954]臺東縣卑南鄉美農村22鄰班鳩92號",
    "電話": "(089)571124",
    "網址": "http://www.dcps.ttct.edu.tw"
  },
  {
    "代碼": "144625",
    "學校名稱": "縣立富山國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[954]臺東縣卑南鄉富山村漁場三鄰66號",
    "電話": "(089)281086",
    "網址": "http://www.fses.ttct.edu.tw"
  },
  {
    "代碼": "144627",
    "學校名稱": "縣立大南國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[954]臺東縣卑南鄉東興村東園一街40號",
    "電話": "(089)382402",
    "網址": "http://www.dnps.ttct.edu.tw"
  },
  {
    "代碼": "144628",
    "學校名稱": "縣立太平國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[954]臺東縣卑南鄉泰安村6號",
    "電話": "(089)381418",
    "網址": "http://www.tpps.ttct.edu.tw"
  },
  {
    "代碼": "144629",
    "學校名稱": "縣立大王國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[963]臺東縣太麻里鄉大王村文化路60號",
    "電話": "(089)781342",
    "網址": "http://www.dwps.ttct.edu.tw"
  },
  {
    "代碼": "144630",
    "學校名稱": "縣立香蘭國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[963]臺東縣太麻里鄉香蘭村四鄰28號",
    "電話": "(089)781505",
    "網址": "http://www.slps.ttct.edu.tw"
  },
  {
    "代碼": "144632",
    "學校名稱": "縣立三和國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[963]臺東縣太麻里鄉三和村漁場94號",
    "電話": "(089)512291",
    "網址": "http://www.sahps.ttct.edu.tw"
  },
  {
    "代碼": "144633",
    "學校名稱": "縣立美和國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[963]臺東縣太麻里鄉美和村美和90號",
    "電話": "(089)512487",
    "網址": "http://www.mhps.ttct.edu.tw"
  },
  {
    "代碼": "144635",
    "學校名稱": "縣立大溪國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[963]臺東縣太麻里鄉多良村十五鄰大溪85號",
    "電話": "(089)761017",
    "網址": "http://www.dsps.ttct.edu.tw"
  },
  {
    "代碼": "144636",
    "學校名稱": "縣立尚武國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[965]臺東縣大武鄉尚武村八鄰學府路7號",
    "電話": "(089)791458",
    "網址": "http://www.swps.ttct.edu.tw"
  },
  {
    "代碼": "144637",
    "學校名稱": "縣立大武國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[965]臺東縣大武鄉大武村民族街2巷49號",
    "電話": "(089)791135",
    "網址": "http://www.twps.ttct.edu.tw"
  },
  {
    "代碼": "144638",
    "學校名稱": "縣立大鳥國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[965]臺東縣大武鄉大鳥村一鄰12號",
    "電話": "(089)791530",
    "網址": "http://www.danps.ttct.edu.tw"
  },
  {
    "代碼": "144640",
    "學校名稱": "縣立綠島國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[951]臺東縣綠島鄉中寮村3號",
    "電話": "(089)672543",
    "網址": "http://www.ldps.ttct.edu.tw"
  },
  {
    "代碼": "144641",
    "學校名稱": "縣立公館國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[951]臺東縣綠島鄉公館村98號",
    "電話": "(089)672548",
    "網址": "http://210.240.131.84"
  },
  {
    "代碼": "144642",
    "學校名稱": "縣立鹿野國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[955]臺東縣鹿野鄉鹿野村中華路一段402號",
    "電話": "(089)551371",
    "網址": "http://www.lyes.ttct.edu.tw"
  },
  {
    "代碼": "144643",
    "學校名稱": "縣立龍田國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[955]臺東縣鹿野鄉龍田村光榮路236號",
    "電話": "(089)551078",
    "網址": "http://www.ltps.ttct.edu.tw"
  },
  {
    "代碼": "144644",
    "學校名稱": "縣立永安國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[955]臺東縣鹿野鄉永安村鹿寮路31號",
    "電話": "(089)551172",
    "網址": "http://www.yaps.ttct.edu.tw"
  },
  {
    "代碼": "144645",
    "學校名稱": "縣立瑞豐國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[955]臺東縣鹿野鄉瑞豐村中山路67號",
    "電話": "(089)581118",
    "網址": "http://www.rfps.ttct.edu.tw"
  },
  {
    "代碼": "144646",
    "學校名稱": "縣立瑞源國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[955]臺東縣鹿野鄉瑞源村文化路67號",
    "電話": "(089)581064",
    "網址": "http://www.ryes.ttct.edu.tw"
  },
  {
    "代碼": "144647",
    "學校名稱": "縣立關山國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[956]臺東縣關山鎮中華路101號",
    "電話": "(089)811012",
    "網址": "http://www.ksps.ttct.edu.tw"
  },
  {
    "代碼": "144648",
    "學校名稱": "縣立月眉國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[956]臺東縣關山鎮月眉里盛豐路二鄰1號",
    "電話": "(089)811074",
    "網址": "http://www.ymps.ttct.edu.tw"
  },
  {
    "代碼": "144649",
    "學校名稱": "縣立德高國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[956]臺東縣關山鎮德高里7鄰永豐16號",
    "電話": "(089)931149",
    "網址": "http://www.dgps.ttct.edu.tw"
  },
  {
    "代碼": "144650",
    "學校名稱": "縣立電光國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[956]臺東縣關山鎮電光里中興路93號",
    "電話": "(089)951142",
    "網址": "http://www.tkes.ttct.edu.tw"
  },
  {
    "代碼": "144651",
    "學校名稱": "縣立福原國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[958]臺東縣池上鄉福原村中華路17號",
    "電話": "(089)862017",
    "網址": "http://www.fyes.ttct.edu.tw"
  },
  {
    "代碼": "144652",
    "學校名稱": "縣立大坡國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[958]臺東縣池上鄉慶豐村八鄰87號",
    "電話": "(089)862664",
    "網址": "http://www.dpps.ttct.edu.tw"
  },
  {
    "代碼": "144653",
    "學校名稱": "縣立萬安國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[958]臺東縣池上鄉萬安村一鄰5之2號",
    "電話": "(089)862371",
    "網址": "http://www.waes.ttct.edu.tw"
  },
  {
    "代碼": "144655",
    "學校名稱": "縣立東河國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[959]臺東縣東河鄉東河村十六鄰35號",
    "電話": "(089)896160",
    "網址": "http://www.thps.ttct.edu.tw"
  },
  {
    "代碼": "144656",
    "學校名稱": "縣立都蘭國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[959]臺東縣東河鄉都蘭村431號",
    "電話": "(089)531224",
    "網址": "http://www.thes.ttct.edu.tw"
  },
  {
    "代碼": "144659",
    "學校名稱": "縣立泰源國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[959]臺東縣東河鄉泰源村11鄰297號",
    "電話": "(089)891011",
    "網址": "http://www.tyes.ttct.edu.tw"
  },
  {
    "代碼": "144660",
    "學校名稱": "縣立北源國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[959]臺東縣東河鄉北源村順那21號",
    "電話": "(089)891103",
    "網址": "http://www.pyes.ttct.edu.tw"
  },
  {
    "代碼": "144662",
    "學校名稱": "縣立三民國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[961]臺東縣成功鎮三民路18號",
    "電話": "(089)851024",
    "網址": "http://www.smps.ttct.edu.tw"
  },
  {
    "代碼": "144663",
    "學校名稱": "縣立成功國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[961]臺東縣成功鎮太平路28號",
    "電話": "(089)851021",
    "網址": "http://www.cgps.ttct.edu.tw"
  },
  {
    "代碼": "144664",
    "學校名稱": "縣立信義國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[961]臺東縣成功鎮信義里都歷路58號",
    "電話": "(089)841029",
    "網址": "http://www.syips.ttct.edu.tw"
  },
  {
    "代碼": "144665",
    "學校名稱": "縣立三仙國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[961]臺東縣成功鎮三仙里基翬路八鄰16號",
    "電話": "(089)851960",
    "網址": "http://www.sasps.ttct.edu.tw"
  },
  {
    "代碼": "144667",
    "學校名稱": "縣立忠孝國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[961]臺東縣成功鎮忠孝里成廣路8號",
    "電話": "(089)871109",
    "網址": "http://www.jsps.ttct.edu.tw"
  },
  {
    "代碼": "144669",
    "學校名稱": "縣立長濱國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[962]臺東縣長濱鄉長濱村五鄰11號",
    "電話": "(089)831038",
    "網址": "http://www.cbps.ttct.edu.tw"
  },
  {
    "代碼": "144671",
    "學校名稱": "縣立寧埔國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[962]臺東縣長濱鄉寧埔村十鄰41號",
    "電話": "(089)801082",
    "網址": "http://nbps.boe.ttct.edu.tw"
  },
  {
    "代碼": "144672",
    "學校名稱": "縣立竹湖國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[962]臺東縣長濱鄉竹湖村十鄰14號",
    "電話": "(089)831295",
    "網址": "http://www.jhes.ttct.edu.tw"
  },
  {
    "代碼": "144673",
    "學校名稱": "縣立三間國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[962]臺東縣長濱鄉三間村七鄰16號",
    "電話": "(089)831306",
    "網址": "http://www.sjps.ttct.edu.tw"
  },
  {
    "代碼": "144674",
    "學校名稱": "縣立樟原國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[962]臺東縣長濱鄉樟原村一鄰7號",
    "電話": "(089)881019",
    "網址": "http://www.jyps.ttct.edu.tw"
  },
  {
    "代碼": "144676",
    "學校名稱": "縣立嘉蘭國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[964]台東縣金峰鄉嘉蘭村3鄰132號",
    "電話": "(089)751224",
    "網址": "http://www.jles.ttct.edu.tw"
  },
  {
    "代碼": "144677",
    "學校名稱": "縣立介達國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[964]臺東縣金峰鄉正興村105號",
    "電話": "(089)781583",
    "網址": "http://www.jdes.ttct.edu.tw"
  },
  {
    "代碼": "144678",
    "學校名稱": "縣立新興國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[964]臺東縣金峰鄉新興村一鄰1號",
    "電話": "(089)781687",
    "網址": "http://www.shsps.ttct.edu.tw"
  },
  {
    "代碼": "144679",
    "學校名稱": "縣立賓茂國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[964]臺東縣金峰鄉賓茂村68號",
    "電話": "(089)771007",
    "網址": "http://www.bmps.ttct.edu.tw"
  },
  {
    "代碼": "144680",
    "學校名稱": "縣立安朔國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[966]臺東縣達仁鄉安朔村八鄰113號",
    "電話": "(089)702257",
    "網址": "http://www.asps.ttct.edu.tw"
  },
  {
    "代碼": "144681",
    "學校名稱": "縣立土坂國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[966]臺東縣達仁鄉土坂村10號",
    "電話": "(089)761225",
    "網址": "https://sites.google.com/a/tbps.ttct.edu.tw/103xue-nian-du-tu-ban-guo-xiao-jiao-yu-huo-ban/"
  },
  {
    "代碼": "144683",
    "學校名稱": "縣立臺坂國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[966]臺東縣達仁鄉台坂村十鄰59號之1",
    "電話": "(089)761252",
    "網址": "http://www.tabps.ttct.edu.tw"
  },
  {
    "代碼": "144685",
    "學校名稱": "縣立蘭嶼國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[952]臺東縣蘭嶼鄉紅頭村1之1號",
    "電話": "(089)731576",
    "網址": "http://www.laps.ttct.edu.tw"
  },
  {
    "代碼": "144686",
    "學校名稱": "縣立椰油國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[952]臺東縣蘭嶼鄉椰油村忠孝街1號",
    "電話": "(089)732017",
    "網址": "http://www.yyps.ttct.edu.tw"
  },
  {
    "代碼": "144687",
    "學校名稱": "縣立東清國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[952]臺東縣蘭嶼鄉東清村1號",
    "電話": "(089)732989",
    "網址": "http://www.donps.ttct.edu.tw"
  },
  {
    "代碼": "144688",
    "學校名稱": "縣立朗島國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[952]臺東縣蘭嶼鄉朗島村17號",
    "電話": "(089)732019",
    "網址": "http://www.lodps.ttct.edu.tw"
  },
  {
    "代碼": "144689",
    "學校名稱": "縣立桃源國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[953]臺東縣延平鄉桃源村昇平路84號",
    "電話": "(089)561275",
    "網址": "http://www.toyps.ttct.edu.tw"
  },
  {
    "代碼": "144690",
    "學校名稱": "縣立武陵國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[953]臺東縣延平鄉武陵村一鄰明野路16號",
    "電話": "(089)551249",
    "網址": "http://www.wlps.ttct.edu.tw"
  },
  {
    "代碼": "144692",
    "學校名稱": "縣立鸞山國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[953]臺東縣延平鄉鸞山村一鄰14號",
    "電話": "(089)568027",
    "網址": "http://www.lsps.ttct.edu.tw"
  },
  {
    "代碼": "144693",
    "學校名稱": "縣立紅葉國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[953]臺東縣延平鄉紅葉村紅谷路1號",
    "電話": "(089)561015",
    "網址": "http://www.hyps.ttct.edu.tw"
  },
  {
    "代碼": "144694",
    "學校名稱": "縣立海端國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[957]臺東縣海端鄉海端村山界路36號",
    "電話": "(089)931184",
    "網址": "http://www.hdps.ttct.edu.tw"
  },
  {
    "代碼": "144695",
    "學校名稱": "縣立初來國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[957]臺東縣海端鄉海端村七鄰5號",
    "電話": "(089)931049",
    "網址": "http://www.clips.ttct.edu.tw"
  },
  {
    "代碼": "144696",
    "學校名稱": "縣立崁頂國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[957]臺東縣海端鄉崁頂村中福21號",
    "電話": "(089)811447",
    "網址": "http://www.kdps.ttct.edu.tw"
  },
  {
    "代碼": "144697",
    "學校名稱": "縣立廣原國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[957]臺東縣海端鄉廣原村大埔路一鄰1號",
    "電話": "(089)862923",
    "網址": "http://www.gyps.ttct.edu.tw"
  },
  {
    "代碼": "144698",
    "學校名稱": "縣立錦屏國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[957]臺東縣海端鄉廣原村錦屏1號",
    "電話": "(089)861762",
    "網址": "http://www.jpps.ttct.edu.tw"
  },
  {
    "代碼": "144700",
    "學校名稱": "縣立加拿國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[957]臺東縣海端鄉加拿村加南5號",
    "電話": "(089)811108",
    "網址": "http://www.jnps.ttct.edu.tw"
  },
  {
    "代碼": "144701",
    "學校名稱": "縣立東海國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市長沙街329號",
    "電話": "(089)350879",
    "網址": "http://www.dhips.ttct.edu.tw"
  },
  {
    "代碼": "144702",
    "學校名稱": "縣立霧鹿國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[957]臺東縣海端鄉霧鹿村12號",
    "電話": "(089)35053",
    "網址": "http://www.wulps.ttct.edu.tw"
  },
  {
    "代碼": "144703",
    "學校名稱": "縣立興隆國小",
    "公/私立": "公立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[959]臺東縣東河鄉興昌村155號",
    "電話": "(089)531283",
    "網址": "http://www.slops.ttct.edu.tw"
  },
  {
    "代碼": "150601",
    "學校名稱": "國立東華大學附設實小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市永安街100號",
    "電話": "(03)8222344",
    "網址": "http://www.efs.hlc.edu.tw"
  },
  {
    "代碼": "151602",
    "學校名稱": "私立海星國小",
    "公/私立": "私立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市永興路21號",
    "電話": "(03)8225407",
    "網址": "http://www.rcsmps.hlc.edu.tw"
  },
  {
    "代碼": "154601",
    "學校名稱": "縣立明禮國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市明禮路6號",
    "電話": "(03)8322353",
    "網址": "http://www.mlips.hlc.edu.tw"
  },
  {
    "代碼": "154602",
    "學校名稱": "縣立明義國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市明義街107號",
    "電話": "(03)8326686",
    "網址": "http://www.myps.hlc.edu.tw"
  },
  {
    "代碼": "154603",
    "學校名稱": "縣立明廉國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市中山路903號",
    "電話": "(03)8569088",
    "網址": "http://www.mleps.hlc.edu.tw"
  },
  {
    "代碼": "154604",
    "學校名稱": "縣立明恥國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市中興路41號",
    "電話": "(03)8222231",
    "網址": "http://www.mcps.hlc.edu.tw"
  },
  {
    "代碼": "154605",
    "學校名稱": "縣立中正國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市中正路210號",
    "電話": "(03)8322819",
    "網址": "http://www.czps.hlc.edu.tw"
  },
  {
    "代碼": "154606",
    "學校名稱": "縣立信義國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市主計里信義街1號",
    "電話": "(03)8331163",
    "網址": "http://www.syips.hlc.edu.tw"
  },
  {
    "代碼": "154607",
    "學校名稱": "縣立復興國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市府前路682號",
    "電話": "(03)8223208",
    "網址": "http://www.fshps.hlc.edu.tw"
  },
  {
    "代碼": "154608",
    "學校名稱": "縣立中華國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市國盛二街22號",
    "電話": "(03)8324308",
    "網址": "http://www.chps.hlc.edu.tw"
  },
  {
    "代碼": "154610",
    "學校名稱": "縣立忠孝國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市中華路298號",
    "電話": "(03)8351218",
    "網址": "http://www.cshps.hlc.edu.tw"
  },
  {
    "代碼": "154611",
    "學校名稱": "縣立北濱國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市北濱街113號",
    "電話": "(03)8324093",
    "網址": "http://www.bbps.hlc.edu.tw"
  },
  {
    "代碼": "154612",
    "學校名稱": "縣立鑄強國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市永興路20號",
    "電話": "(03)8223787",
    "網址": "http://www.tcps.hlc.edu.tw"
  },
  {
    "代碼": "154613",
    "學校名稱": "縣立國福國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市福光街277巷1號",
    "電話": "(03)8561395",
    "網址": "http://www.gfups.hlc.edu.tw"
  },
  {
    "代碼": "154614",
    "學校名稱": "縣立新城國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[971]花蓮縣新城鄉新城村博愛路30號",
    "電話": "(03)8611006",
    "網址": "http://www.scps.hlc.edu.tw"
  },
  {
    "代碼": "154615",
    "學校名稱": "縣立北埔國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[971]花蓮縣新城鄉北埔路170號",
    "電話": "(03)8264624",
    "網址": "http://www.bpps.hlc.edu.tw"
  },
  {
    "代碼": "154616",
    "學校名稱": "縣立康樂國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[971]花蓮縣新城鄉康樂村康樂一街38號",
    "電話": "(03)8265597",
    "網址": "http://www.klps.hlc.edu.tw"
  },
  {
    "代碼": "154617",
    "學校名稱": "縣立嘉里國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[971]花蓮縣新城鄉嘉里村嘉里三街28號",
    "電話": "(03)8266945",
    "網址": "http://www.zlips.hlc.edu.tw"
  },
  {
    "代碼": "154618",
    "學校名稱": "縣立吉安國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[973]花蓮縣吉安鄉吉安路二段97號",
    "電話": "(03)8523984",
    "網址": "http://www.gaps.hlc.edu.tw"
  },
  {
    "代碼": "154619",
    "學校名稱": "縣立宜昌國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[973]花蓮縣吉安鄉宜昌一街45號",
    "電話": "(03)8520209",
    "網址": "http://www.ycps.hlc.edu.tw"
  },
  {
    "代碼": "154620",
    "學校名稱": "縣立北昌國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[973]花蓮縣吉安鄉北昌村自強路533號",
    "電話": "(03)8562619",
    "網址": "http://www.bcps.hlc.edu.tw"
  },
  {
    "代碼": "154621",
    "學校名稱": "縣立稻香國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[973]花蓮縣吉安鄉稻香村稻香路99號",
    "電話": "(03)8524663",
    "網址": "http://www.dshps.hlc.edu.tw"
  },
  {
    "代碼": "154622",
    "學校名稱": "縣立光華國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[973]花蓮縣吉安鄉光華村光華二街180號",
    "電話": "(03)8421611",
    "網址": "http://www.khps.hlc.edu.tw"
  },
  {
    "代碼": "154623",
    "學校名稱": "縣立南華國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[973]花蓮縣吉安鄉干城村吉安路6段60號",
    "電話": "(03)8525043",
    "網址": "http://www.nhps.hlc.edu.tw"
  },
  {
    "代碼": "154624",
    "學校名稱": "縣立化仁國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[973]花蓮縣吉安鄉東里十一街83號",
    "電話": "(03)8528720",
    "網址": "http://www.hzps.hlc.edu.tw"
  },
  {
    "代碼": "154625",
    "學校名稱": "縣立太昌國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[973]花蓮縣吉安鄉太昌村明義五街1號",
    "電話": "(03)8571746",
    "網址": "http://www.tacps.hlc.edu.tw"
  },
  {
    "代碼": "154626",
    "學校名稱": "縣立壽豐國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[974]花蓮縣壽豐鄉壽豐村壽山路37號",
    "電話": "(03)8651024",
    "網址": "http://www.sfops.hlc.edu.tw"
  },
  {
    "代碼": "154627",
    "學校名稱": "縣立豐山國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[974]花蓮縣壽豐鄉豐山村中山路41號",
    "電話": "(03)8651640",
    "網址": "http://www.fsnps.hlc.edu.tw"
  },
  {
    "代碼": "154628",
    "學校名稱": "縣立豐裡國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[974]花蓮縣壽豐鄉豐裡村中山路299號",
    "電話": "(03)8652183",
    "網址": "http://www.flps.hlc.edu.tw"
  },
  {
    "代碼": "154629",
    "學校名稱": "縣立志學國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[974]花蓮縣壽豐鄉志學村中正路120巷10號",
    "電話": "(03)8662600",
    "網址": "http://www.jsps.hlc.edu.tw"
  },
  {
    "代碼": "154630",
    "學校名稱": "縣立平和國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[974]花蓮縣壽豐鄉平和路34號",
    "電話": "(03)8661223",
    "網址": "http://www.phps.hlc.edu.tw"
  },
  {
    "代碼": "154631",
    "學校名稱": "縣立溪口國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[974]花蓮縣壽豐鄉溪口村溪口路87號",
    "電話": "(03)8652275",
    "網址": "http://www.skps.hlc.edu.tw"
  },
  {
    "代碼": "154632",
    "學校名稱": "縣立月眉國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[974]花蓮縣壽豐鄉月眉村月眉三段24號",
    "電話": "(03)8631011",
    "網址": "http://www.ymps.hlc.edu.tw"
  },
  {
    "代碼": "154633",
    "學校名稱": "縣立水璉國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[974]花蓮縣壽豐鄉水璉村水璉二街20號",
    "電話": "(03)8601228",
    "網址": "http://www.sulps.hlc.edu.tw"
  },
  {
    "代碼": "154634",
    "學校名稱": "縣立鳳林國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[975]花蓮縣鳳林鎮中正路二段1號",
    "電話": "(03)8762031",
    "網址": "http://www.flips.hlc.edu.tw"
  },
  {
    "代碼": "154636",
    "學校名稱": "縣立大榮國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[975]花蓮縣鳳林鎮大榮里復興路85號",
    "電話": "(03)8763904",
    "網址": "http://www.dlps.hlc.edu.tw"
  },
  {
    "代碼": "154637",
    "學校名稱": "縣立鳳仁國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[975]花蓮縣鳳林鎮鳳仁里光復路94號",
    "電話": "(03)8762201",
    "網址": "http://www.fjps.hlc.edu.tw"
  },
  {
    "代碼": "154638",
    "學校名稱": "縣立北林國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[975]花蓮縣鳳林鎮北林里平園路32號",
    "電話": "(03)8762554",
    "網址": "http://www.blps.hlc.edu.tw/index.php"
  },
  {
    "代碼": "154640",
    "學校名稱": "縣立長橋國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[975]花蓮縣鳳林鎮長橋里長橋路2號",
    "電話": "(03)8751654",
    "網址": "http://www.chcps.hlc.edu.tw"
  },
  {
    "代碼": "154642",
    "學校名稱": "縣立林榮國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[975]花蓮縣鳳林鎮林榮里永安街2號",
    "電話": "(03)8771024",
    "網址": "http://www.llps.hlc.edu.tw"
  },
  {
    "代碼": "154643",
    "學校名稱": "縣立光復國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[976]花蓮縣光復鄉大馬村中山路三段75號",
    "電話": "(03)8701029",
    "網址": "http://www.kfps.hlc.edu.tw"
  },
  {
    "代碼": "154644",
    "學校名稱": "縣立太巴塱國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[976]花蓮縣光復鄉中正路二段23號",
    "電話": "(03)8701134",
    "網址": "http://www.tplps.hlc.edu.tw"
  },
  {
    "代碼": "154648",
    "學校名稱": "縣立大進國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[976]花蓮縣光復鄉大進村糖廠街4號",
    "電話": "(03)8701049",
    "網址": "http://www.dchps.hlc.edu.tw"
  },
  {
    "代碼": "154649",
    "學校名稱": "縣立瑞穗國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[978]花蓮縣瑞穗鄉溫泉路一段19號",
    "電話": "(03)8876366",
    "網址": "http://www.zshps.hlc.edu.tw"
  },
  {
    "代碼": "154650",
    "學校名稱": "縣立瑞北國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[978]花蓮縣瑞穗鄉中正北路二段35號",
    "電話": "(03)8872642",
    "網址": "http://www.zbps.hlc.edu.tw"
  },
  {
    "代碼": "154651",
    "學校名稱": "縣立瑞美國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[978]花蓮縣瑞穗鄉中山路二段389號",
    "電話": "(03)8872014",
    "網址": "http://www.zmeps.hlc.edu.tw"
  },
  {
    "代碼": "154652",
    "學校名稱": "縣立鶴岡國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[978]花蓮縣瑞穗鄉鶴岡村167號",
    "電話": "(03)8872740",
    "網址": "http://www.hgps.hlc.edu.tw"
  },
  {
    "代碼": "154653",
    "學校名稱": "縣立舞鶴國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[978]花蓮縣瑞穗鄉舞鶴村64號",
    "電話": "(03)8872394",
    "網址": "http://www.whps.hlc.edu.tw"
  },
  {
    "代碼": "154654",
    "學校名稱": "縣立富源國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[978]花蓮縣瑞穗鄉富源村30號",
    "電話": "(03)8811029",
    "網址": "http://www.fyps.hlc.edu.tw"
  },
  {
    "代碼": "154655",
    "學校名稱": "縣立豐濱國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[977]花蓮縣豐濱鄉豐濱村民族街5號",
    "電話": "(03)8791111",
    "網址": "http://www.fbps.hlc.edu.tw"
  },
  {
    "代碼": "154656",
    "學校名稱": "縣立港口國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[977]花蓮縣豐濱鄉港口村43號",
    "電話": "(03)8781037",
    "網址": "http://www.gkps.hlc.edu.tw"
  },
  {
    "代碼": "154657",
    "學校名稱": "縣立靜浦國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[977]花蓮縣豐濱鄉靜浦村64號",
    "電話": "(03)8781021",
    "網址": "http://www.zpps.hlc.edu.tw"
  },
  {
    "代碼": "154658",
    "學校名稱": "縣立新社國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[977]花蓮縣豐濱鄉新社村150號",
    "電話": "(03)8711138",
    "網址": "http://www.ssps.hlc.edu.tw"
  },
  {
    "代碼": "154660",
    "學校名稱": "縣立玉里國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[981]花蓮縣玉里鎮忠智路43號",
    "電話": "(03)8882007",
    "網址": "http://www.ylps.hlc.edu.tw"
  },
  {
    "代碼": "154661",
    "學校名稱": "縣立中城國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[981]花蓮縣玉里鎮中山路一段1號",
    "電話": "(03)8881982",
    "網址": "http://www.ccps.hlc.edu.tw"
  },
  {
    "代碼": "154662",
    "學校名稱": "縣立源城國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[981]花蓮縣玉里鎮源城里水源62號",
    "電話": "(03)8882290",
    "網址": "http://www.jcps.hlc.edu.tw"
  },
  {
    "代碼": "154663",
    "學校名稱": "縣立樂合國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[981]花蓮縣玉里鎮樂合里新民41號",
    "電話": "(03)8886087",
    "網址": "http://www.lhps.hlc.edu.tw"
  },
  {
    "代碼": "154664",
    "學校名稱": "縣立觀音國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[981]花蓮縣玉里鎮觀音里16鄰9號",
    "電話": "(03)8851006",
    "網址": "http://www.gips.hlc.edu.tw/"
  },
  {
    "代碼": "154665",
    "學校名稱": "縣立高寮國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[981]花蓮縣玉里鎮高寮92號",
    "電話": "(03)8851078",
    "網址": "http://www.glps.hlc.edu.tw"
  },
  {
    "代碼": "154666",
    "學校名稱": "縣立松浦國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[981]花蓮縣玉里鎮松浦里12鄰212號",
    "電話": "(03)8851131",
    "網址": "http://www.spups.hlc.edu.tw"
  },
  {
    "代碼": "154667",
    "學校名稱": "縣立春日國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[981]花蓮縣玉里鎮春日里泰林95號",
    "電話": "(03)8872628",
    "網址": "http://www.czips.hlc.edu.tw"
  },
  {
    "代碼": "154668",
    "學校名稱": "縣立德武國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[981]花蓮縣玉里鎮德武里9鄰171號",
    "電話": "(03)8872824",
    "網址": "http://www.dwes.hlc.edu.tw"
  },
  {
    "代碼": "154669",
    "學校名稱": "縣立三民國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[981]花蓮縣玉里鎮三民里118號",
    "電話": "(03)8841183",
    "網址": "http://www.smps.hlc.edu.tw"
  },
  {
    "代碼": "154670",
    "學校名稱": "縣立大禹國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[981]花蓮縣玉里鎮中禹里225號",
    "電話": "(03)8883274",
    "網址": "http://www.dyps.hlc.edu.tw"
  },
  {
    "代碼": "154671",
    "學校名稱": "縣立長良國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[981]花蓮縣玉里鎮長良里163號",
    "電話": "(03)8801171",
    "網址": "http://www.clps.hlc.edu.tw"
  },
  {
    "代碼": "154672",
    "學校名稱": "縣立富里國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[983]花蓮縣富里鄉富里村永安街52號",
    "電話": "(03)8831042",
    "網址": "http://www.fulps.hlc.edu.tw"
  },
  {
    "代碼": "154674",
    "學校名稱": "縣立東里國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[983]花蓮縣富里鄉東里村道化路74號",
    "電話": "(03)8861161",
    "網址": "http://www.tlps.hlc.edu.tw"
  },
  {
    "代碼": "154675",
    "學校名稱": "縣立明里國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[983]花蓮縣富里鄉明里村1鄰16號",
    "電話": "(03)8846003",
    "網址": "http://www.mrps.hlc.edu.tw"
  },
  {
    "代碼": "154676",
    "學校名稱": "縣立吳江國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[983]花蓮縣富里鄉吳江村6鄰52號",
    "電話": "(03)8861242",
    "網址": "http://www.wcps.hlc.edu.tw/"
  },
  {
    "代碼": "154677",
    "學校名稱": "縣立學田國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[983]花蓮縣富里鄉學田村光明路1號",
    "電話": "(03)8831324",
    "網址": "http://www.stps.hlc.edu.tw"
  },
  {
    "代碼": "154678",
    "學校名稱": "縣立永豐國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[983]花蓮縣富里鄉豐南村永豐84號",
    "電話": "(03)8831195",
    "網址": "http://www.yfps.hlc.edu.tw"
  },
  {
    "代碼": "154679",
    "學校名稱": "縣立萬寧國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[983]花蓮縣富里鄉萬寧村鎮寧108號",
    "電話": "(03)8861211",
    "網址": "http://www.wlips.hlc.edu.tw"
  },
  {
    "代碼": "154680",
    "學校名稱": "縣立東竹國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[983]花蓮縣富里鄉竹田村富田3號",
    "電話": "(03)8821514",
    "網址": "http://www.djps.hlc.edu.tw"
  },
  {
    "代碼": "154681",
    "學校名稱": "縣立秀林國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[972]花蓮縣秀林鄉秀林村秀林路76號",
    "電話": "(03)8611393",
    "網址": "http://www.slips.hlc.edu.tw"
  },
  {
    "代碼": "154682",
    "學校名稱": "縣立富世國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[972]花蓮縣秀林鄉富世村127號",
    "電話": "(03)8611431",
    "網址": "http://www.fusps.hlc.edu.tw"
  },
  {
    "代碼": "154683",
    "學校名稱": "縣立崇德國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[972]花蓮縣秀林鄉崇德村72號",
    "電話": "(03)8621220",
    "網址": "http://www.cdps.hlc.edu.tw"
  },
  {
    "代碼": "154684",
    "學校名稱": "縣立和平國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[972]花蓮縣秀林鄉和平村112號",
    "電話": "(03)8681056",
    "網址": "http://www.hpps.hlc.edu.tw"
  },
  {
    "代碼": "154685",
    "學校名稱": "縣立景美國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[972]花蓮縣秀林鄉景美村加灣112號",
    "電話": "(03)8266707",
    "網址": "http://www.zmps.hlc.edu.tw"
  },
  {
    "代碼": "154686",
    "學校名稱": "縣立三棧國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[972]花蓮縣秀林鄉景美村三棧102號",
    "電話": "(03)8260330",
    "網址": "http://www.szps.hlc.edu.tw"
  },
  {
    "代碼": "154687",
    "學校名稱": "縣立佳民國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[972]花蓮縣秀林鄉佳民村119號",
    "電話": "(03)8264900",
    "網址": "http://www.cmps.hlc.edu.tw"
  },
  {
    "代碼": "154688",
    "學校名稱": "縣立銅門國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[972]花蓮縣秀林鄉銅門村69號",
    "電話": "(03)8641174",
    "網址": "http://210.240.63.2"
  },
  {
    "代碼": "154689",
    "學校名稱": "縣立水源國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[972]花蓮縣秀林鄉水源村111號",
    "電話": "(03)8570781",
    "網址": "http://www.syps.hlc.edu.tw"
  },
  {
    "代碼": "154690",
    "學校名稱": "縣立銅蘭國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[972]花蓮縣秀林鄉文蘭村70號",
    "電話": "(03)8641005",
    "網址": "http://www.tlaps.hlc.edu.tw"
  },
  {
    "代碼": "154691",
    "學校名稱": "縣立文蘭國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[972]花蓮縣秀林鄉文蘭村米亞丸1號",
    "電話": "(03)8641020",
    "網址": "http://www.wlps.hlc.edu.tw"
  },
  {
    "代碼": "154692",
    "學校名稱": "縣立萬榮國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[979]花蓮縣萬榮鄉萬榮村2鄰31號",
    "電話": "(03)8751449",
    "網址": "http://www.wlops.hlc.edu.tw"
  },
  {
    "代碼": "154693",
    "學校名稱": "縣立明利國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[979]花蓮縣萬榮鄉明利村35號",
    "電話": "(03)8751048",
    "網址": "http://www.mlps.hlc.edu.tw"
  },
  {
    "代碼": "154694",
    "學校名稱": "縣立見晴國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[979]花蓮縣萬榮鄉見晴村89號",
    "電話": "(03)8771574",
    "網址": "http://www.cchps.hlc.edu.tw"
  },
  {
    "代碼": "154695",
    "學校名稱": "縣立馬遠國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[979]花蓮縣萬榮鄉馬遠村39號",
    "電話": "(03)8811371",
    "網址": "http://www.myups.hlc.edu.tw"
  },
  {
    "代碼": "154696",
    "學校名稱": "縣立西林國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[979]花蓮縣萬榮鄉西林村8鄰114號",
    "電話": "(03)8771064",
    "網址": "http://www.slps.hlc.edu.tw"
  },
  {
    "代碼": "154697",
    "學校名稱": "縣立紅葉國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[979]花蓮縣萬榮鄉紅葉村1鄰4號",
    "電話": "(03)8872784",
    "網址": "http://www.hyps.hlc.edu.tw"
  },
  {
    "代碼": "154698",
    "學校名稱": "縣立卓溪國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[982]花蓮縣卓溪鄉卓溪村中正路72號",
    "電話": "(03)8883514",
    "網址": "http://www.zsps.hlc.edu.tw"
  },
  {
    "代碼": "154699",
    "學校名稱": "縣立崙山國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[982]花蓮縣卓溪鄉崙山村1鄰12號",
    "電話": "(03)8841350",
    "網址": "http://www.lsaps.hlc.edu.tw"
  },
  {
    "代碼": "154700",
    "學校名稱": "縣立立山國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[982]花蓮縣卓溪鄉立山村9鄰90號",
    "電話": "(03)8841358",
    "網址": "http://www.lsps.hlc.edu.tw"
  },
  {
    "代碼": "154701",
    "學校名稱": "縣立太平國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[982]花蓮縣卓溪鄉太平村1鄰2號",
    "電話": "(03)8841359",
    "網址": "http://www.tpps.hlc.edu.tw"
  },
  {
    "代碼": "154702",
    "學校名稱": "縣立卓清國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[982]花蓮縣卓溪鄉卓清村清水6號",
    "電話": "(03)8801163",
    "網址": "http://www.zcps.hlc.edu.tw"
  },
  {
    "代碼": "154703",
    "學校名稱": "縣立卓樂國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[982]花蓮縣卓溪鄉卓清村卓樂86號",
    "電話": "(03)8889075",
    "網址": "http://www.zlps.hlc.edu.tw"
  },
  {
    "代碼": "154704",
    "學校名稱": "縣立古風國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[982]花蓮縣卓溪鄉古風村崙天18號",
    "電話": "(03)8846058",
    "網址": "http://www.gfps.hlc.edu.tw"
  },
  {
    "代碼": "154705",
    "學校名稱": "縣立奇美國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[978]花蓮縣瑞穗鄉奇美村47號",
    "電話": "(03)8991077",
    "網址": "http://www.gmps.hlc.edu.tw"
  },
  {
    "代碼": "154706",
    "學校名稱": "縣立卓楓國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[982]花蓮縣卓溪鄉古風村十二鄰古楓6號",
    "電話": "(03)8846027",
    "網址": "http://www.zfps.hlc.edu.tw"
  },
  {
    "代碼": "154707",
    "學校名稱": "縣立西富國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[976]花蓮縣光復鄉西富村民有街51巷3號",
    "電話": "(03)8702765",
    "網址": "http://www.sfps.hlc.edu.tw"
  },
  {
    "代碼": "154708",
    "學校名稱": "縣立大興國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[976]花蓮縣光復鄉大興村民權街17號",
    "電話": "(03)8702987",
    "網址": "http://www.dsps.hlc.edu.tw"
  },
  {
    "代碼": "154710",
    "學校名稱": "縣立西寶國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[972]花蓮縣秀林鄉富世村西寶11號",
    "電話": "(03)8691040",
    "網址": "http://www.spps.hlc.edu.tw"
  },
  {
    "代碼": "154711",
    "學校名稱": "縣立中原國小",
    "公/私立": "公立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市中原路531號",
    "電話": "(03)8333547",
    "網址": "http://www.cyps.hlc.edu.tw"
  },
  {
    "代碼": "164601",
    "學校名稱": "縣立馬公國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市光明里明遠路2號",
    "電話": "(06)9272165",
    "網址": "http://www.mkps.phc.edu.tw"
  },
  {
    "代碼": "164602",
    "學校名稱": "縣立中正國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市中興里民生路38號",
    "電話": "(06)9272758",
    "網址": "http://www.ccps.phc.edu.tw"
  },
  {
    "代碼": "164603",
    "學校名稱": "縣立中興國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市光復路186號",
    "電話": "(06)9272779",
    "網址": "http://www.chps.phc.edu.tw"
  },
  {
    "代碼": "164604",
    "學校名稱": "縣立中山國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市案山里大案山25之6號",
    "電話": "(06)9211342",
    "網址": "http://www.csps.phc.edu.tw"
  },
  {
    "代碼": "164605",
    "學校名稱": "縣立石泉國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市石泉里1號",
    "電話": "(06)9210228",
    "網址": "http://www.scps.phc.edu.tw"
  },
  {
    "代碼": "164606",
    "學校名稱": "縣立東衛國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市東衛里169號",
    "電話": "(06)9213238",
    "網址": "http://www.dwps.phc.edu.tw"
  },
  {
    "代碼": "164607",
    "學校名稱": "縣立興仁國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市烏崁里129號",
    "電話": "(06)9211443",
    "網址": "http://www.sjps.phc.edu.tw"
  },
  {
    "代碼": "164608",
    "學校名稱": "縣立山水國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市山水里140號",
    "電話": "(06)9951004",
    "網址": "http://www.ssps.phc.edu.tw"
  },
  {
    "代碼": "164609",
    "學校名稱": "縣立五德國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市五德里32號",
    "電話": "(06)9951327",
    "網址": "http://www.wdps.phc.edu.tw"
  },
  {
    "代碼": "164610",
    "學校名稱": "縣立時裡國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市時裡里114號",
    "電話": "(06)9951332",
    "網址": "http://www.slps.phc.edu.tw"
  },
  {
    "代碼": "164611",
    "學校名稱": "縣立風櫃國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市風櫃里66號",
    "電話": "(06)9951280",
    "網址": "http://www.fkps.phc.edu.tw"
  },
  {
    "代碼": "164612",
    "學校名稱": "縣立虎井國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市虎井里1號",
    "電話": "(06)9291025",
    "網址": "http://www.hjps.phc.edu.tw"
  },
  {
    "代碼": "164614",
    "學校名稱": "縣立成功國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[885]澎湖縣湖西鄉成功村1之4號",
    "電話": "(06)9212971",
    "網址": "http://www.cgps.phc.edu.tw"
  },
  {
    "代碼": "164615",
    "學校名稱": "縣立西溪國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[885]澎湖縣湖西鄉西溪村130號",
    "電話": "(06)9921082",
    "網址": "http://www.sips.phc.edu.tw"
  },
  {
    "代碼": "164616",
    "學校名稱": "縣立湖西國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[885]澎湖縣湖西鄉湖西村151號",
    "電話": "(06)9921008",
    "網址": "http://www.hsps.phc.edu.tw"
  },
  {
    "代碼": "164618",
    "學校名稱": "縣立龍門國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[885]澎湖縣湖西鄉尖山村4號",
    "電話": "(06)9921202",
    "網址": "http://www.lmps.phc.edu.tw"
  },
  {
    "代碼": "164619",
    "學校名稱": "縣立隘門國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[885]澎湖縣湖西鄉隘門村15號",
    "電話": "(06)9212692",
    "網址": "http://www.amps.phc.edu.tw"
  },
  {
    "代碼": "164620",
    "學校名稱": "縣立沙港國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[885]澎湖縣湖西鄉鼎灣村75號",
    "電話": "(06)9210087",
    "網址": "http://www.skps.phc.edu.tw"
  },
  {
    "代碼": "164621",
    "學校名稱": "縣立中屯國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[884]澎湖縣白沙鄉中屯村130之2號",
    "電話": "(06)9931561",
    "網址": "http://www.ctps.phc.edu.tw"
  },
  {
    "代碼": "164623",
    "學校名稱": "縣立講美國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[884]澎湖縣白沙鄉講美村141之1號",
    "電話": "(06)9931268",
    "網址": "http://www.jmps.phc.edu.tw"
  },
  {
    "代碼": "164625",
    "學校名稱": "縣立赤崁國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[884]澎湖縣白沙鄉赤崁村370號",
    "電話": "(06)9931048",
    "網址": "http://www.ckps.phc.edu.tw"
  },
  {
    "代碼": "164627",
    "學校名稱": "縣立鳥嶼國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[884]澎湖縣白沙鄉鳥嶼村57號",
    "電話": "(06)9916077",
    "網址": "www.nyps.phc.edu.tw"
  },
  {
    "代碼": "164628",
    "學校名稱": "縣立吉貝國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[884]澎湖縣白沙鄉吉貝村8號",
    "電話": "(06)9911086",
    "網址": "http://www.cpps.phc.edu.tw"
  },
  {
    "代碼": "164629",
    "學校名稱": "縣立後寮國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[884]澎湖縣白沙鄉後寮村218號",
    "電話": "(06)9931027",
    "網址": "http://www.hlps.phc.edu.tw"
  },
  {
    "代碼": "164630",
    "學校名稱": "縣立合橫國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[881]澎湖縣西嶼鄉合界村76號之2",
    "電話": "(06)9981471",
    "網址": "http://www.hhps.phc.edu.tw"
  },
  {
    "代碼": "164631",
    "學校名稱": "縣立竹灣國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[881]澎湖縣西嶼鄉竹灣村15號",
    "電話": "(06)9981437",
    "網址": "http://www.cwps.phc.edu.tw"
  },
  {
    "代碼": "164633",
    "學校名稱": "縣立大池國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[881]澎湖縣西嶼鄉大池村129號",
    "電話": "(06)9981179",
    "網址": "http://www.dtps.phc.edu.tw"
  },
  {
    "代碼": "164634",
    "學校名稱": "縣立池東國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[881]澎湖縣西嶼鄉池東村104號",
    "電話": "(06)9981108",
    "網址": "http://www.cdps.phc.edu.tw"
  },
  {
    "代碼": "164636",
    "學校名稱": "縣立內垵國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[881]澎湖縣西嶼鄉內垵村229號",
    "電話": "(06)9981107",
    "網址": "http://www.naps.phc.edu.tw"
  },
  {
    "代碼": "164637",
    "學校名稱": "縣立外垵國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[881]澎湖縣西嶼鄉外垵村29號之3",
    "電話": "(06)9981176",
    "網址": "http://www.wips.phc.edu.tw"
  },
  {
    "代碼": "164638",
    "學校名稱": "縣立望安國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[882]澎湖縣望安鄉西安村46號",
    "電話": "(06)9991006",
    "網址": "http://www.waps.phc.edu.tw"
  },
  {
    "代碼": "164639",
    "學校名稱": "縣立將軍國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[882]澎湖縣望安鄉將軍村12-8號",
    "電話": "(06)9902065",
    "網址": "http://www.jjps.phc.edu.tw"
  },
  {
    "代碼": "164641",
    "學校名稱": "縣立花嶼國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[882]澎湖縣望安鄉花嶼村2號",
    "電話": "(06)9991740",
    "網址": "http://www.hyps.phc.edu.tw"
  },
  {
    "代碼": "164643",
    "學校名稱": "縣立七美國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[883]澎湖縣七美鄉中和村4鄰1號",
    "電話": "(06)9971003",
    "網址": "http://www.cmps.phc.edu.tw"
  },
  {
    "代碼": "164644",
    "學校名稱": "縣立雙湖國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[883]澎湖縣七美鄉西湖村七鄰9號",
    "電話": "(06)9971018",
    "網址": "http://www.tlps.phc.edu.tw"
  },
  {
    "代碼": "164645",
    "學校名稱": "縣立文澳國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市西文里文學路221號",
    "電話": "(06)9212412",
    "網址": "http://www.wops.phc.edu.tw"
  },
  {
    "代碼": "164646",
    "學校名稱": "縣立文光國小",
    "公/私立": "公立",
    "縣市名稱": "[16]澎湖縣",
    "地址": "[880]澎湖縣馬公市光榮里三多路450號",
    "電話": "(06)9264181",
    "網址": "http://www.wgps.phc.edu.tw"
  },
  {
    "代碼": "173601",
    "學校名稱": "市立中正國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[202]基隆市中正區中船路36巷4號",
    "電話": "(02)24223064",
    "網址": "http://www.ccps.kl.edu.tw"
  },
  {
    "代碼": "173602",
    "學校名稱": "市立正濱國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[202]基隆市中正區祥豐街216號",
    "電話": "(02)24635551",
    "網址": "http://www.cbps.kl.edu.tw"
  },
  {
    "代碼": "173603",
    "學校名稱": "市立忠孝國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[202]基隆市中正區中正路656巷150號",
    "電話": "(02)24622934",
    "網址": "http://www.csps.kl.edu.tw"
  },
  {
    "代碼": "173604",
    "學校名稱": "市立和平國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[202]基隆市中正區和一路84巷26號",
    "電話": "(02)24622106",
    "網址": "http://www.hpps.kl.edu.tw"
  },
  {
    "代碼": "173605",
    "學校名稱": "市立八斗國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[202]基隆市中正區北寧路396巷52號",
    "電話": "(02)24693391",
    "網址": "http://www.bdps.kl.edu.tw"
  },
  {
    "代碼": "173606",
    "學校名稱": "市立東信國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[201]基隆市信義區正信路26號",
    "電話": "(02)24652133",
    "網址": "http://www.dsps.kl.edu.tw"
  },
  {
    "代碼": "173607",
    "學校名稱": "市立中興國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[201]基隆市信義區信二路40號",
    "電話": "(02)24225038",
    "網址": "http://www.chsps.kl.edu.tw"
  },
  {
    "代碼": "173608",
    "學校名稱": "市立深澳國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[201]基隆市信義區深澳坑路55-1號",
    "電話": "(02)24652940",
    "網址": "http://www.saps.kl.edu.tw"
  },
  {
    "代碼": "173609",
    "學校名稱": "市立月眉國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[201]基隆市信義區月眉路250巷22號",
    "電話": "(02)24653493",
    "網址": "http://www.ymps.kl.edu.tw"
  },
  {
    "代碼": "173610",
    "學校名稱": "市立東光國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[201]基隆市信義區東信路236巷16號",
    "電話": "(02)24650329",
    "網址": "http://www.dgps.kl.edu.tw"
  },
  {
    "代碼": "173611",
    "學校名稱": "市立仁愛國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[200]基隆市仁愛區仁二路139號",
    "電話": "(02)24289131",
    "網址": "http://www.raps.kl.edu.tw"
  },
  {
    "代碼": "173612",
    "學校名稱": "市立信義國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[200]基隆市仁愛區仁二路135號",
    "電話": "(02)24213960",
    "網址": "http://www.syps.kl.edu.tw"
  },
  {
    "代碼": "173613",
    "學校名稱": "市立成功國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[200]基隆市仁愛區獅球路6號",
    "電話": "(02)24313939",
    "網址": "http://www.ckps.kl.edu.tw"
  },
  {
    "代碼": "173614",
    "學校名稱": "市立南榮國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[200]基隆市仁愛區南榮路321號",
    "電話": "(02)24223038",
    "網址": "http://www.nrps.kl.edu.tw"
  },
  {
    "代碼": "173615",
    "學校名稱": "市立尚智國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[200]基隆市仁愛區龍安街326號",
    "電話": "(02)24223362",
    "網址": "http://www.szps.kl.edu.tw"
  },
  {
    "代碼": "173616",
    "學校名稱": "市立安樂國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[204]基隆市安樂區安一路177巷23號",
    "電話": "(02)24220814",
    "網址": "http://www.alps.kl.edu.tw"
  },
  {
    "代碼": "173617",
    "學校名稱": "市立西定國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[204]基隆市安樂區新西街1號",
    "電話": "(02)24223856",
    "網址": "http://210.240.21.251"
  },
  {
    "代碼": "173618",
    "學校名稱": "市立武崙國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[204]基隆市安樂區武崙街203號",
    "電話": "(02)24310018",
    "網址": "http://www.wlps.kl.edu.tw"
  },
  {
    "代碼": "173619",
    "學校名稱": "市立中和國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[203]基隆市中山區中和路64號",
    "電話": "(02)24371751",
    "網址": "http://www.chps.kl.edu.tw"
  },
  {
    "代碼": "173620",
    "學校名稱": "市立仙洞國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[203]基隆市中山區仁安街141號",
    "電話": "(02)24223031",
    "網址": "http://www.sdps.kl.edu.tw"
  },
  {
    "代碼": "173621",
    "學校名稱": "市立中山國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[203]基隆市中山區通仁街28號",
    "電話": "(02)24223053",
    "網址": "http://www.cshps.kl.edu.tw"
  },
  {
    "代碼": "173622",
    "學校名稱": "市立港西國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[203]基隆市中山區中山一路291巷45號",
    "電話": "(02)24223068",
    "網址": "http://www.gsps.kl.edu.tw"
  },
  {
    "代碼": "173623",
    "學校名稱": "市立中華國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[203]基隆市中山區中華路46巷26號",
    "電話": "(02)24225530",
    "網址": "http://www.ches.kl.edu.tw"
  },
  {
    "代碼": "173625",
    "學校名稱": "市立德和國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[203]基隆市中山區文化路164號",
    "電話": "(02)24278095",
    "網址": "http://www.dhps.kl.edu.tw"
  },
  {
    "代碼": "173626",
    "學校名稱": "市立七堵國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[206]基隆市七堵區明德一路184號",
    "電話": "(02)24567116",
    "網址": "http://www.cdps.kl.edu.tw"
  },
  {
    "代碼": "173627",
    "學校名稱": "市立華興國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[206]基隆市七堵區泰安路34號",
    "電話": "(02)24512022",
    "網址": "http://www.hsps.kl.edu.tw"
  },
  {
    "代碼": "173628",
    "學校名稱": "市立五堵國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[206]基隆市七堵區百一街25號",
    "電話": "(02)24511457",
    "網址": "http://www.wdps.kl.edu.tw"
  },
  {
    "代碼": "173629",
    "學校名稱": "市立堵南國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[206]基隆市七堵區大德路120號",
    "電話": "(02)24511339",
    "網址": "http://www.dnps.kl.edu.tw"
  },
  {
    "代碼": "173630",
    "學校名稱": "市立瑪陵國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[206]基隆市七堵區大成街1號",
    "電話": "(02)24565663",
    "網址": "http://www.mlps.kl.edu.tw"
  },
  {
    "代碼": "173631",
    "學校名稱": "市立復興國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[206]基隆市七堵區華新二路21號",
    "電話": "(02)24515601",
    "網址": "http://www.fsps.kl.edu.tw"
  },
  {
    "代碼": "173632",
    "學校名稱": "市立尚仁國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[206]基隆市七堵區八德路20號",
    "電話": "(02)24311708",
    "網址": "http://www.srps.kl.edu.tw"
  },
  {
    "代碼": "173633",
    "學校名稱": "市立建德國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[204]基隆市安樂區安和一街392號",
    "電話": "(02)24313034",
    "網址": "http://www.jdps.kl.edu.tw"
  },
  {
    "代碼": "173634",
    "學校名稱": "市立八堵國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[205]基隆市暖暖區源遠路27號",
    "電話": "(02)24573287",
    "網址": "http://www.badps.kl.edu.tw"
  },
  {
    "代碼": "173635",
    "學校名稱": "市立暖暖國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[205]基隆市暖暖區暖暖街121號",
    "電話": "(02)24583795",
    "網址": "http://www.nnps.kl.edu.tw"
  },
  {
    "代碼": "173636",
    "學校名稱": "市立暖江國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[205]基隆市暖暖區寧靜街3號",
    "電話": "(02)24574348",
    "網址": "http://www.njps.kl.edu.tw"
  },
  {
    "代碼": "173637",
    "學校名稱": "市立碇內國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[205]基隆市暖暖區源遠路258號",
    "電話": "(02)24581300",
    "網址": "http://www.dinps.kl.edu.tw"
  },
  {
    "代碼": "173638",
    "學校名稱": "市立隆聖國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[204]基隆市安樂區武隆街107號",
    "電話": "(02)24311480",
    "網址": "http://www.lsps.kl.edu.tw"
  },
  {
    "代碼": "173639",
    "學校名稱": "市立長興國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[206]基隆市七堵區東新街3號",
    "電話": "(02)24552096",
    "網址": "http://www.cses.kl.edu.tw"
  },
  {
    "代碼": "173640",
    "學校名稱": "市立長樂國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[204]基隆市安樂區樂利三街30巷123號",
    "電話": "(02)24322765",
    "網址": "http://www.clps.kl.edu.tw"
  },
  {
    "代碼": "173641",
    "學校名稱": "市立深美國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[201]基隆市信義區深美街198號",
    "電話": "(02)24654821",
    "網址": "http://www.smps.kl.edu.tw"
  },
  {
    "代碼": "173642",
    "學校名稱": "市立暖西國小",
    "公/私立": "公立",
    "縣市名稱": "[17]基隆市",
    "地址": "[205]基隆市暖暖區暖暖街350號",
    "電話": "(02)24588583",
    "網址": "http://www.nsps.kl.edu.tw"
  },
  {
    "代碼": "180601",
    "學校名稱": "國立清華大學附小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市北區四維路47號",
    "電話": "(03)5282420",
    "網址": "http://www.sctcps.hc.edu.tw"
  },
  {
    "代碼": "181601",
    "學校名稱": "私立曙光國小",
    "公/私立": "私立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區北大路70號",
    "電話": "(03)5328283",
    "網址": "http://www.sgps.hc.edu.tw"
  },
  {
    "代碼": "181603",
    "學校名稱": "私立新竹市康橋國(中)小",
    "公/私立": "私立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區柴橋路95號",
    "電話": "(03)5192000",
    "網址": "http://www.kcbs.ntpc.edu.tw/ch/HsinchuCampus/index.html"
  },
  {
    "代碼": "183601",
    "學校名稱": "市立新竹國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區興學街106號",
    "電話": "(03)5222153",
    "網址": "http://www.hsps.hc.edu.tw"
  },
  {
    "代碼": "183602",
    "學校名稱": "市立北門國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市北區水田里水田街33號",
    "電話": "(03)5316668",
    "網址": "http://www.bmps.hc.edu.tw"
  },
  {
    "代碼": "183603",
    "學校名稱": "市立民富國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市北區西大路561號",
    "電話": "(03)5222102",
    "網址": "http://www.mfps.hc.edu.tw"
  },
  {
    "代碼": "183604",
    "學校名稱": "市立東門國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區民族路33號",
    "電話": "(03)5222109",
    "網址": "http://www.tmps.hc.edu.tw"
  },
  {
    "代碼": "183605",
    "學校名稱": "市立西門國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市北區北大路450號",
    "電話": "(03)5222492",
    "網址": "http://www.cmps.hc.edu.tw"
  },
  {
    "代碼": "183606",
    "學校名稱": "市立竹蓮國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區食品路226號",
    "電話": "(03)5223066",
    "網址": "http://www.jlps.hc.edu.tw"
  },
  {
    "代碼": "183607",
    "學校名稱": "市立東園國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區園後街25號",
    "電話": "(03)5712496",
    "網址": "http://www.pups.hc.edu.tw"
  },
  {
    "代碼": "183608",
    "學校名稱": "市立三民國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區自由路66號",
    "電話": "(03)5326345",
    "網址": "http://www.smps.hc.edu.tw"
  },
  {
    "代碼": "183609",
    "學校名稱": "市立龍山國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區光復路一段574號",
    "電話": "(03)5774287",
    "網址": "http://www3.lsps.hc.edu.tw/front/index.html"
  },
  {
    "代碼": "183610",
    "學校名稱": "市立關東國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區關東路53號",
    "電話": "(03)5775645",
    "網址": "http://www.ktps.hc.edu.tw"
  },
  {
    "代碼": "183611",
    "學校名稱": "市立載熙國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市北區武陵里東大路二段386號",
    "電話": "(03)5316675",
    "網址": "http://www.thps.hc.edu.tw"
  },
  {
    "代碼": "183612",
    "學校名稱": "市立南寮國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市北區東大路三段465號",
    "電話": "(03)5363448",
    "網址": "http://www.nlps.hc.edu.tw"
  },
  {
    "代碼": "183613",
    "學校名稱": "市立建功國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區建功一路104巷22號",
    "電話": "(03)5713447",
    "網址": "http://www.ckps.hc.edu.tw"
  },
  {
    "代碼": "183614",
    "學校名稱": "市立水源國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區仰德路11號",
    "電話": "(03)5711125",
    "網址": "http://www.syps.hc.edu.tw"
  },
  {
    "代碼": "183615",
    "學校名稱": "市立香山國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市香山區牛埔東路260號",
    "電話": "(03)5386164",
    "網址": "http://www.hhps.hc.edu.tw"
  },
  {
    "代碼": "183616",
    "學校名稱": "市立虎林國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市香山區延平路二段78號",
    "電話": "(03)5381820",
    "網址": "http://www.hlps.hc.edu.tw"
  },
  {
    "代碼": "183617",
    "學校名稱": "市立港南國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市香山區海埔路171巷91號",
    "電話": "(03)5382964",
    "網址": "http://www.gnps.hc.edu.tw"
  },
  {
    "代碼": "183618",
    "學校名稱": "市立大庄國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市香山區大庄路48號",
    "電話": "(03)5384035",
    "網址": "http://www.ttps.hc.edu.tw"
  },
  {
    "代碼": "183619",
    "學校名稱": "市立茄苳國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市香山區茄苳里茄苳路70號",
    "電話": "(03)5373543",
    "網址": "http://www.cdps.hc.edu.tw"
  },
  {
    "代碼": "183620",
    "學校名稱": "市立朝山國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市香山區中華路五段648巷126號",
    "電話": "(03)5374304",
    "網址": "http://www.csps.hc.edu.tw"
  },
  {
    "代碼": "183621",
    "學校名稱": "市立大湖國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市香山區大湖里五福路一段530號",
    "電話": "(03)5374609",
    "網址": "http://www.dhps.hc.edu.tw"
  },
  {
    "代碼": "183622",
    "學校名稱": "市立內湖國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市香山區內湖路109號",
    "電話": "(03)5373184",
    "網址": "http://www.nhps.hc.edu.tw"
  },
  {
    "代碼": "183623",
    "學校名稱": "市立南隘國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市香山區南隘路二段31號",
    "電話": "(03)5371405",
    "網址": "http://www.nips.hc.edu.tw"
  },
  {
    "代碼": "183625",
    "學校名稱": "市立頂埔國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市香山區頂埔路23號",
    "電話": "(03)5386204",
    "網址": "http://www.dpps.hc.edu.tw"
  },
  {
    "代碼": "183626",
    "學校名稱": "市立舊社國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市北區金竹路99號",
    "電話": "(03)5342022",
    "網址": "http://www.jsps.hc.edu.tw"
  },
  {
    "代碼": "183627",
    "學校名稱": "市立陽光國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區明湖路200號",
    "電話": "(03)5629600",
    "網址": "http://www.ycps.hc.edu.tw"
  },
  {
    "代碼": "183628",
    "學校名稱": "市立科園國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區科學園路171號",
    "電話": "(03)6668421",
    "網址": "http://www.kyps.hc.edu.tw"
  },
  {
    "代碼": "183629",
    "學校名稱": "市立高峰國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區高翠路332巷2號",
    "電話": "(03)5626909",
    "網址": "http://www.gfps.hc.edu.tw"
  },
  {
    "代碼": "183630",
    "學校名稱": "市立青草湖國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區明湖路1211號",
    "電話": "(03)5200360",
    "網址": "http://www.cthps.hc.edu.tw/cth/"
  },
  {
    "代碼": "183631",
    "學校名稱": "市立華德福實驗國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市香山區中華路六段331巷168號",
    "電話": "(03)5370531",
    "網址": "http://sunwaldorf.blogspot.tw/"
  },
  {
    "代碼": "183632",
    "學校名稱": "市立關埔國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區慈濟路2號",
    "電話": "(03)6669086",
    "網址": "https://www.gpps.hc.edu.tw"
  },
  {
    "代碼": "200601",
    "學校名稱": "國立嘉義大學附小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市東區林森東路46號",
    "電話": "(05)2788002",
    "網址": "http://www.ncyes.ncyu.edu.tw"
  },
  {
    "代碼": "203601",
    "學校名稱": "市立崇文國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市東區垂楊路241號",
    "電話": "(05)2222310",
    "網址": "http://www.cwes.cy.edu.tw"
  },
  {
    "代碼": "203602",
    "學校名稱": "市立博愛國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市西區友愛路822號",
    "電話": "(05)2322863",
    "網址": "http://www.paes.cy.edu.tw/"
  },
  {
    "代碼": "203603",
    "學校名稱": "市立垂楊國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市西區垂楊路605號",
    "電話": "(05)2853151",
    "網址": "http://www.cyes.cy.edu.tw"
  },
  {
    "代碼": "203604",
    "學校名稱": "市立民族國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市東區民族路235號",
    "電話": "(05)2222113",
    "網址": "http://www.mtes.cy.edu.tw"
  },
  {
    "代碼": "203605",
    "學校名稱": "市立宣信國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市東區宣信街266號",
    "電話": "(05)2223904",
    "網址": "http://www.sses.cy.edu.tw"
  },
  {
    "代碼": "203606",
    "學校名稱": "市立大同國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市西區成功街15號",
    "電話": "(05)2222114",
    "網址": "http://www.ttes.cy.edu.tw"
  },
  {
    "代碼": "203607",
    "學校名稱": "市立志航國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市西區世賢路四段141號",
    "電話": "(05)2857924",
    "網址": "http://www.ches.cy.edu.tw"
  },
  {
    "代碼": "203608",
    "學校名稱": "市立嘉北國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市東區嘉北街65號",
    "電話": "(05)2757091",
    "網址": "http://www.cpes.cy.edu.tw"
  },
  {
    "代碼": "203609",
    "學校名稱": "市立僑平國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市西區德安路10號",
    "電話": "(05)2333746",
    "網址": "http://www.gpes.cy.edu.tw"
  },
  {
    "代碼": "203610",
    "學校名稱": "市立林森國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市東區林森東路346號",
    "電話": "(05)2762063",
    "網址": "http://www.lses.cy.edu.tw"
  },
  {
    "代碼": "203611",
    "學校名稱": "市立北園國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市西區北湖里北社尾路168號",
    "電話": "(05)2371330",
    "網址": "http://www.pyes.cy.edu.tw"
  },
  {
    "代碼": "203612",
    "學校名稱": "市立精忠國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市東區林森東路840號",
    "電話": "(05)2760474",
    "網址": "http://www.gces.cy.edu.tw"
  },
  {
    "代碼": "203613",
    "學校名稱": "市立育人國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市西區育人路211號",
    "電話": "(05)2351595",
    "網址": "http://www.ynes.cy.edu.tw"
  },
  {
    "代碼": "203614",
    "學校名稱": "市立蘭潭國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市東區小雅路419號",
    "電話": "(05)2763492",
    "網址": "http://www.ltes.cy.edu.tw"
  },
  {
    "代碼": "203615",
    "學校名稱": "市立興安國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市東區興安街35號",
    "電話": "(05)2232280",
    "網址": "http://www.haes.cy.edu.tw"
  },
  {
    "代碼": "203616",
    "學校名稱": "市立世賢國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市西區世賢路一段687號",
    "電話": "(05)2338264",
    "網址": "http://163.27.44.1"
  },
  {
    "代碼": "203617",
    "學校名稱": "市立興嘉國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市西區重慶路51號",
    "電話": "(05)2860885",
    "網址": "http://www.sces.cy.edu.tw"
  },
  {
    "代碼": "203618",
    "學校名稱": "市立港坪國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市四維路63號",
    "電話": "(05)2369037",
    "網址": "http://www.gpps.cy.edu.tw"
  },
  {
    "代碼": "203619",
    "學校名稱": "市立文雅國小",
    "公/私立": "公立",
    "縣市名稱": "[20]嘉義市",
    "地址": "[600]嘉義市東區盧厝里11鄰文雅街2號",
    "電話": "(05)2768011",
    "網址": "http://163.27.250.6/"
  },
  {
    "代碼": "714601",
    "學校名稱": "縣立金湖國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[891]金門縣金湖鎮新市里林森路12號",
    "電話": "(082)335977",
    "網址": "http://www.khes.km.edu.tw"
  },
  {
    "代碼": "714602",
    "學校名稱": "縣立金寧國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[892]金門縣金寧鄉安岐1號",
    "電話": "(082)322535",
    "網址": "http://www.cnes.km.edu.tw"
  },
  {
    "代碼": "714603",
    "學校名稱": "縣立中正國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[893]金門縣金城鎮珠浦北路38號",
    "電話": "(082)325645",
    "網址": "http://www.jjes.km.edu.tw"
  },
  {
    "代碼": "714604",
    "學校名稱": "縣立賢庵國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[893]金門縣金城鎮賢厝村庵前18之2號",
    "電話": "(082)325463",
    "網址": "https://sites.google.com/a/cnc.km.edu.tw/saps/"
  },
  {
    "代碼": "714605",
    "學校名稱": "縣立古城國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[893]金門縣金城鎮古城村大古崗100號",
    "電話": "(082)325274",
    "網址": "http://www.gcps.km.edu.tw"
  },
  {
    "代碼": "714606",
    "學校名稱": "縣立開瑄國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[891]金門縣金湖鎮瓊林里中興崗9-1號",
    "電話": "(082)332165",
    "網址": "http://www.ksps.km.edu.tw"
  },
  {
    "代碼": "714607",
    "學校名稱": "縣立柏村國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[891]金門縣金湖鎮料羅村新塘10號",
    "電話": "(082)332465",
    "網址": "http://www.btps.km.edu.tw"
  },
  {
    "代碼": "714608",
    "學校名稱": "縣立多年國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[890]金門縣金沙鎮環島東路4段600號",
    "電話": "(082)332470",
    "網址": "http://www.dnes.km.edu.tw"
  },
  {
    "代碼": "714609",
    "學校名稱": "縣立金沙國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[890]金門縣金沙鎮汶沙里后浦頭117號",
    "電話": "(082)352569",
    "網址": "http://www.kses.km.edu.tw"
  },
  {
    "代碼": "714610",
    "學校名稱": "縣立何浦國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[890]金門縣金沙鎮浦山里下塘頭39號",
    "電話": "(082)352455",
    "網址": "http://www.hpps.km.edu.tw"
  },
  {
    "代碼": "714611",
    "學校名稱": "縣立安瀾國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[890]金門縣金沙鎮碧山村81號",
    "電話": "(082)352861",
    "網址": "http://www.alps.km.edu.tw"
  },
  {
    "代碼": "714612",
    "學校名稱": "縣立述美國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[890]金門縣金沙鎮沙青路999號",
    "電話": "(082)352860",
    "網址": "http://www.smps.km.edu.tw"
  },
  {
    "代碼": "714613",
    "學校名稱": "縣立古寧國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[892]金門縣金寧鄉古寧村北山1號",
    "電話": "(082)325750",
    "網址": "http://www.gnes.km.edu.tw"
  },
  {
    "代碼": "714614",
    "學校名稱": "縣立金鼎國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[892]金門縣金寧鄉盤山村下堡123號",
    "電話": "(082)325700",
    "網址": "http://www.cdps.km.edu.tw"
  },
  {
    "代碼": "714615",
    "學校名稱": "縣立卓環國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[894]金門縣烈嶼鄉西路23號",
    "電話": "(082)362124",
    "網址": "http://www.jhes.km.edu.tw"
  },
  {
    "代碼": "714616",
    "學校名稱": "縣立上岐國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[894]金門縣烈嶼鄉青岐123號",
    "電話": "(082)362409",
    "網址": "http://tw.school.uschoolnet.com/?id=es00003410"
  },
  {
    "代碼": "714618",
    "學校名稱": "縣立湖埔國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[892]金門縣金寧鄉湖下196號",
    "電話": "(082)326424",
    "網址": "http://www.hupu.km.edu.tw/"
  },
  {
    "代碼": "714619",
    "學校名稱": "縣立正義國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[891]金門縣金湖鎮正義里成功129號",
    "電話": "(082)332734",
    "網址": "http://www.jyps.km.edu.tw"
  },
  {
    "代碼": "714620",
    "學校名稱": "縣立西口國小",
    "公/私立": "公立",
    "縣市名稱": "[71]金門縣",
    "地址": "[894]金門縣烈嶼鄉西口村西方69號",
    "電話": "(082)362411",
    "網址": "http://www.hkes.km.edu.tw"
  },
  {
    "代碼": "724601",
    "學校名稱": "縣立介壽國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[72]連江縣",
    "地址": "[209]連江縣南竿鄉介壽村13號",
    "電話": "(0836)22192",
    "網址": "http://www.jsps.matsu.edu.tw"
  },
  {
    "代碼": "724602",
    "學校名稱": "縣立中正國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[72]連江縣",
    "地址": "[209]連江縣南竿鄉馬祖村4號",
    "電話": "(0836)22196",
    "網址": "http://www.jjps.matsu.edu.tw"
  },
  {
    "代碼": "724603",
    "學校名稱": "縣立仁愛國小",
    "公/私立": "公立",
    "縣市名稱": "[72]連江縣",
    "地址": "[209]連江縣南竿鄉仁愛村95號",
    "電話": "(0836)22146",
    "網址": "http://www.raps.matsu.edu.tw"
  },
  {
    "代碼": "724604",
    "學校名稱": "縣立塘岐國小",
    "公/私立": "公立",
    "縣市名稱": "[72]連江縣",
    "地址": "[210]連江縣北竿鄉塘岐村56號",
    "電話": "(0836)55420",
    "網址": "http://www.tcps.matsu.edu.tw"
  },
  {
    "代碼": "724606",
    "學校名稱": "縣立敬恆國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[72]連江縣",
    "地址": "[211]連江縣莒光鄉青帆村19號",
    "電話": "(0836)88179",
    "網址": "http://www.jhjh.matsu.edu.tw"
  },
  {
    "代碼": "724607",
    "學校名稱": "縣立東莒國小",
    "公/私立": "公立",
    "縣市名稱": "[72]連江縣",
    "地址": "[211]連江縣莒光鄉大坪村4號",
    "電話": "(0836)89043",
    "網址": "http://www.djps.matsu.edu.tw"
  },
  {
    "代碼": "724608",
    "學校名稱": "縣立東引國(中)小",
    "公/私立": "公立",
    "縣市名稱": "[72]連江縣",
    "地址": "[212]連江縣東引鄉中柳村94號",
    "電話": "(0836)77210",
    "網址": "http://www.tyjh.matsu.edu.tw"
  },
  {
    "代碼": "011301",
    "學校名稱": "私立淡江高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[01]新北市",
    "地址": "[251]新北市淡水區真理街26號",
    "電話": "(02)26203850",
    "網址": "http://www.tksh.ntpc.edu.tw"
  },
  {
    "代碼": "011302",
    "學校名稱": "私立康橋高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[01]新北市",
    "地址": "[231]新北市新店區華城路800號",
    "電話": "(02)22166000",
    "網址": "http://www.kcbs.ntpc.edu.tw"
  },
  {
    "代碼": "011307",
    "學校名稱": "新北市裕德高級中等學校附設國小",
    "公/私立": "私立",
    "縣市名稱": "[01]新北市",
    "地址": "[236]新北市土城區擺接堡路1號",
    "電話": "(02)82617889",
    "網址": "http://www.yuteh.ntpc.edu.tw/yuteh/"
  },
  {
    "代碼": "011330",
    "學校名稱": "新北市林口康橋高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[01]新北市",
    "地址": "[244]新北市林口區興林路55號",
    "電話": "(02)26015888",
    "網址": "http://www.kcislk.ntpc.edu.tw/zh/index.html"
  },
  {
    "代碼": "400144",
    "學校名稱": "國立臺灣戲曲學院附設國小",
    "公/私立": "公立",
    "縣市名稱": "[40]臺北市",
    "地址": "[114]臺北市內湖路二段177號",
    "電話": "(02)27962666",
    "網址": "http://www.tcpa.edu.tw"
  },
  {
    "代碼": "421303",
    "學校名稱": "臺北市私立奎山實驗高級中學附設國小",
    "公/私立": "私立",
    "縣市名稱": "[42]臺北市",
    "地址": "[112]臺北市北投區榮華里明德路200號",
    "電話": "(02)28212009",
    "網址": "https://www.kshs.tp.edu.tw/"
  },
  {
    "代碼": "031320",
    "學校名稱": "桃園市新興高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[03]桃園市",
    "地址": "[334]桃園市八德區永豐路563號",
    "電話": "(03)3796996",
    "網址": "http://www.hshs.tyc.edu.tw"
  },
  {
    "代碼": "031326",
    "學校名稱": "私立大華高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區三民路二段200號",
    "電話": "(03)4820506",
    "網址": "http://www.thsh.tyc.edu.tw"
  },
  {
    "代碼": "034529",
    "學校名稱": "市立仁美國中附設國小",
    "公/私立": "公立",
    "縣市名稱": "[03]桃園市",
    "地址": "[326]桃園市楊梅區高榮里梅獅路539巷1號",
    "電話": "(03)4641123",
    "網址": "http://www.zmjhs.tyc.edu.tw"
  },
  {
    "代碼": "061322",
    "學校名稱": "華德福大地實驗學校附設國小",
    "公/私立": "私立",
    "縣市名稱": "[06]臺中市",
    "地址": "[414]臺中市烏日區公園路36號",
    "電話": "(04)23377520",
    "網址": "http://www.motherearth.com.tw/"
  },
  {
    "代碼": "191301",
    "學校名稱": "私立東大附中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[19]臺中市",
    "地址": "[407]臺中市西屯區臺灣大道四段1727號",
    "電話": "(04)23590269",
    "網址": "http://www.hn.thu.edu.tw"
  },
  {
    "代碼": "191302",
    "學校名稱": "私立葳格高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區軍福十八路328號",
    "電話": "(04)24371728",
    "網址": "https://senior.wagor.tc.edu.tw/"
  },
  {
    "代碼": "191315",
    "學校名稱": "私立磊川華德福實驗教育學校附設國小",
    "公/私立": "私立",
    "縣市名稱": "[19]臺中市",
    "地址": "[406]臺中市北屯區松竹五路233號及366號",
    "電話": "(04)24350110",
    "網址": "http://www.leichuan.tc.edu.tw/"
  },
  {
    "代碼": "110328",
    "學校名稱": "國立南科國際實驗高中附設國小",
    "公/私立": "公立",
    "縣市名稱": "[11]臺南市",
    "地址": "[744]臺南市新市區三舍里大順6路12巷6號",
    "電話": "(06)5052916",
    "網址": "http://www.nnkieh.tn.edu.tw"
  },
  {
    "代碼": "211320",
    "學校名稱": "財團法人慈濟高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[21]臺南市",
    "地址": "[708]臺南市安平區建平五街111號",
    "電話": "(06)2932323",
    "網址": "http://www.tcsh.tn.edu.tw"
  },
  {
    "代碼": "121320",
    "學校名稱": "私立義大國際高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[12]高雄市",
    "地址": "[840]高雄市大樹區學城路1段6號",
    "電話": "(07)6577115 ",
    "網址": "https://www.iis.kh.edu.tw/tw"
  },
  {
    "代碼": "121502",
    "學校名稱": "私立光禾華德福實驗學校(國中)附設國小",
    "公/私立": "私立",
    "縣市名稱": "[12]高雄市",
    "地址": "[830]高雄市鳳山區中崙四路25號",
    "電話": "(07)7531735",
    "網址": "http://waldorf-tw-kh.weebly.com/"
  },
  {
    "代碼": "521301",
    "學校名稱": "私立明誠高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[52]高雄市",
    "地址": "[804]高雄市鼓山區中華一路97號",
    "電話": "(07)5521593",
    "網址": "http://www.mcsh.kh.edu.tw"
  },
  {
    "代碼": "521303",
    "學校名稱": "私立大榮高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[52]高雄市",
    "地址": "[804]高雄市鼓山區大榮街1號",
    "電話": "(07)5613281",
    "網址": "http://www.dystcs.kh.edu.tw"
  },
  {
    "代碼": "521401",
    "學校名稱": "私立中華藝校附設國小",
    "公/私立": "私立",
    "縣市名稱": "[52]高雄市",
    "地址": "[804]高雄市鼓山區美術館路89號",
    "電話": "(07)5549696",
    "網址": "http://www.charts.kh.edu.tw"
  },
  {
    "代碼": "551303",
    "學校名稱": "南海月光實驗學校附設國小",
    "公/私立": "私立",
    "縣市名稱": "[55]高雄市",
    "地址": "[807]高雄市三民區大中一路110巷52弄15號",
    "電話": "(07)3458068",
    "網址": "https://rise-education2018.webnode.tw/"
  },
  {
    "代碼": "580301",
    "學校名稱": "國立高師大附中附設國小",
    "公/私立": "公立",
    "縣市名稱": "[58]高雄市",
    "地址": "[802]高雄市苓雅區凱旋二路89號",
    "電話": "(07)7613875",
    "網址": "http://www.nknush.kh.edu.tw"
  },
  {
    "代碼": "021310",
    "學校名稱": "私立中道高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[263]宜蘭縣壯圍鄉功勞村中央路三段312巷7號",
    "電話": "(03)9306696",
    "網址": "http://www.cdsh.ilc.edu.tw"
  },
  {
    "代碼": "024325",
    "學校名稱": "縣立慈心華德福實中附設國小",
    "公/私立": "公立",
    "縣市名稱": "[02]宜蘭縣",
    "地址": "[269]宜蘭縣冬山鄉照安路257號",
    "電話": "(039)596222",
    "網址": "http://blog.ilc.edu.tw/blog/blog/25783"
  },
  {
    "代碼": "051307",
    "學校名稱": "私立全人實驗高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[05]苗栗縣",
    "地址": "[369]苗栗縣卓蘭鎮內灣里內灣141-3號",
    "電話": "(04)25896909",
    "網址": "http://holistic.org.tw/"
  },
  {
    "代碼": "081313",
    "學校名稱": "私立弘明實驗高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[08]南投縣",
    "地址": "[551]南投縣名間鄉東湖村大老巷102號",
    "電話": "(049)2731799",
    "網址": "http://www.holdmean.org.tw"
  },
  {
    "代碼": "141301",
    "學校名稱": "臺東縣均一高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[14]臺東縣",
    "地址": "[950]臺東縣臺東市中興路二段366巷36號",
    "電話": "(089)223301",
    "網址": "http://junyi.tw"
  },
  {
    "代碼": "151312",
    "學校名稱": "財團法人慈濟大學附中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[15]花蓮縣",
    "地址": "[970]花蓮縣花蓮市介仁街178號",
    "電話": "(03)8572823",
    "網址": "http://www.tcsh.hlc.edu.tw"
  },
  {
    "代碼": "171306",
    "學校名稱": "私立二信高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[17]基隆市",
    "地址": "[202]基隆市中正區立德路243號",
    "電話": "(02)24623131",
    "網址": "http://www.essh.kl.edu.tw/essh/index.asp"
  },
  {
    "代碼": "171308",
    "學校名稱": "輔大聖心高中附設國小",
    "公/私立": "私立",
    "縣市名稱": "[17]基隆市",
    "地址": "[203]基隆市中山區西定路166號",
    "電話": "(02)24282454",
    "網址": "http://www.shsh.kl.edu.tw"
  },
  {
    "代碼": "180301",
    "學校名稱": "國立科學工業園區實驗高中附設國小",
    "公/私立": "公立",
    "縣市名稱": "[18]新竹市",
    "地址": "[300]新竹市東區介壽路300號",
    "電話": "(03)5777011",
    "網址": "http://www.nehs.hc.edu.tw"
  }]

export default {
  school,
}