import React, { useState, useCallback, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import Header from "components/Header";
import Footer from "components/Footer";
import { Beforeunload } from 'components/Beforeunload';
import { useStore, StoreTypes } from "context";
import ParamsStorer from "../../util/paramsStorer";
import { twCity } from "../../constants/twCitySelector/twCity";
import { create, pay } from "../../util/orders";
import Loading from "components/Loading";
import DialogModule from "components/Dialogs/";
import RuleContent from "components/RuleContent";
import { getMainPage } from "util/getMainPage";


let payMoney = 0;
const PurchaseFillInfos = () => {
    const router = useStore(StoreTypes.router);
    const ruleCheckRef = useRef();
    const [education, setEducation] = useState("E");
    const [counties, setCounties] = useState("0");
    const [districts, setDistricts] = useState("0");
    const [productPrice, setProductPrice] = useState(0);
    const [finelPay, setFinelPay] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isRule, setIsRule] = useState(false);
    const [invoice, setInvoice] = useState("invoice1");
    const [discountMessage, setDiscountMessage] = useState("");
    const [school, setSchool] = useState();
    const [discountCode, setDiscountCode] = useState('');
    const [{ userId }, userDispatch] = useStore(StoreTypes.user);
    const userProfile = ParamsStorer.Instance().getData("userProfile");
    const educationObj = [
        {
            value: "E",
            name: "國小"
        },
        {
            value: "J",
            name: "國中"
        },
        {
            value: "H",
            name: "高中"
        },
        {
            value: "Other",
            name: "其他"
        },
    ];

    // const createItem = useCallback(() => {
    //     educationObj
    // }, [router]);
    function educationSelect(e) {
        const { value } = e.target;
        setEducation(value);
    }

    const createEducationItem = useCallback(() => {
        let educationOptions = educationObj.map((data, index) => {
            const value = data["value"];
            const name = data["name"];
            return (
                <option value={value} name={name}>{name}</option>
            )
        });
        return (
            <select id="education" className={styles.itemFullInput} onChange={educationSelect}>
                {educationOptions}
            </select>
        );
    });


    const countiesSelect = useCallback((e) => {
        const { value } = e.target;
        setCounties(value);
    });



    const createCountiesItem = useCallback(() => {
        if (education === "Other") {
            return;
        }
        let countiesOptions = twCity.counties.map((data, index) => {
            const value = index;
            const name = data;
            return (
                <option value={value} name={name}>{name}</option>
            )
        });

        return (
            <select id="cityCounties" className={styles.itemHalfInput} onChange={countiesSelect}>
                {countiesOptions}
            </select>
        );
    }, [education]);




    const districtsSelect = useCallback((e) => {
        const { value } = e.target;
        setDistricts(value);
    });



    const createDistrictsItem = useCallback((e) => {
        if (education === "Other") {
            return;
        }
        if (counties) {
            let districtsItem = twCity.districts[counties];

            let districtsOptions = districtsItem[0].map((data, index) => {
                const value = index;
                const name = data;
                return (
                    <option value={value} name={name}>{name}</option>
                )
            });
            return (
                <select id="cityDistricts" className={styles.itemHalfInput} onChange={districtsSelect}>
                    {districtsOptions}
                </select>
            );
        }
        else {
            return (
                <select id="cityDistricts" className={styles.itemHalfInput} onChange={districtsSelect}>

                </select>
            );
        }


    }, [counties, education]);


    const schoolSelect = useCallback((e) => {
        const { value } = e.target;
        setSchool(value);
    });



    const createSchoolItem = useCallback((e) => {
        if (education === "Other") {
            return;
        }


        if (education && districts && counties) {
            // let districtsItem = twCity.districts[counties];

            let schoolItem = twCity[education]["school"]

            let countyItem = twCity.counties[counties];
            let districtsItem = twCity.districts[counties][0][districts];
            let schools = schoolItem.filter(function (item) {
                var isTrue = false;
                if (item["縣市名稱"].indexOf(countyItem) > 0 && item["地址"].indexOf(districtsItem) > 0) {
                    isTrue = true;
                }
                return isTrue;
            });


            // 代碼: "011601"
            // 公/私立: "私立"
            // 地址: "[234]新北市永和區福和路125巷20號"
            // 學校名稱: "私立育才國小"
            // 網址: "http://www.ytes.ntpc.edu.tw"
            // 縣市名稱: "[01]新北市"
            // 電話: "(02)29214630"
            let schoolOptions = schools.map((data, index) => {
                const value = data["代碼"];
                const name = data["學校名稱"];
                // const address = 
                return (
                    <option value={value} name={name}>{name}</option>
                )
            });
            if (schoolOptions.length === 0) {
                schoolOptions = (<option value="0" name="其他">其他</option>)
            } else {
                schoolOptions.push(<option value="0" name="其他">其他</option>);
            }
            return (
                <select id="school" className={styles.itemFullInput} onChange={schoolSelect}>
                    {schoolOptions}
                </select>
            );
        }
        else {
            return (
                <select id="school" className={styles.itemFullInput}></select>
            );
        }
    }, [education, districts, counties]);

    useEffect(() => {
        const datas = ParamsStorer.Instance().getData("PurchaseFillInfos");
        if (!datas) {
            router.history.push(`/MainPage`);
        }
        if (datas && datas.length > 0) {
            var price = 0;
            for (var i = 0; i < datas.length; i++) {
                const product = datas[i];
                const strPrice = product["preferential price"];
                price += strPrice ? parseInt(strPrice) : 0;
            }
            const uiPrice = document.getElementById('price');
            uiPrice.innerHTML = price;

            setProductPrice(price)
            setFinelPay(price)
            payMoney = price
            // also fill user's profile
            const uiFamilyName = document.getElementById('familyName');
            if (uiFamilyName && userProfile.lastName) uiFamilyName.value = userProfile.lastName;
            const uiPersonalName = document.getElementById('personalName');
            if (uiPersonalName && userProfile.firstName) uiPersonalName.value = userProfile.firstName;
            const uiPhone = document.getElementById('phone');
            if (uiPhone && userProfile.phone) uiPhone.value = userProfile.phone;
        }
    }, [setFinelPay, setProductPrice]);

    const getProducts = useCallback((course) => {
        // courseId is like "國小一上", "國中七上"...
        return new Promise((resolve, reject) => {
            const curTime = new Date();
            var year = curTime.getFullYear();
            if (curTime.getMonth() < 8) year -= 1;
            year = year - 1911;

            var apiDomain = `${process.env.REACT_APP_API_DOMAIN}`;//getAPIDomain();
            var data = {
                year: year.toString(),
                filters: {
                    course: course
                }
            };

            fetch(`${apiDomain}/apis/getProducts`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { "Content-type": "application/json; charset=UTF-8" }
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (response) {
                    if (response.status === "success") {
                        resolve(response.data);
                    }
                    else {
                        reject(response.error.message);
                    }
                })
                .catch(function (err) {
                    reject(err.message ? err.message : err);
                });
        });
    });

    const product = useCallback(() => {
        const datas = ParamsStorer.Instance().getData("PurchaseFillInfos");
        if (!datas) {
            ParamsStorer.Instance().setData("userOrderInfos", null);
            router.history.push(`/MyOrders`);
            return '';
        }
        var product = datas && datas.length > 0 ? `${datas[0].course}` : "";
        return product;
    }, [router]);

    const expireDate = useCallback(() => {
        const curTime = new Date();
        const expireDate = curTime.setDate(curTime.getDate() + 366);
        const date = new Date(expireDate);
        const year = date.getFullYear() - 1911;
        const month = date.getMonth() + 1;
        const day = date.getDate();
        var strDate = `${year}年${month}月${day}日`;
        return strDate;
    });

    const userAccount = useCallback(() => {
        if (userId) {
            return userId;
        }
        else {
            router.history.push(getMainPage());
            return "";
        }
    }, [userId]);

    const backClick = useCallback(() => {
        router.history.goBack();
    }, [router]);

    const payOrder = useCallback(async () => {

        var errMsg = null;
        var education = null;
        var cityCounties = null;
        var cityDistricts = null;
        var school = null;
        var options = {};
        const datas = ParamsStorer.Instance().getData("PurchaseFillInfos");
        if (!datas) errMsg = "產品資料為空";
        else if (datas.length === 0) errMsg = "未選取課程";
        else {
            const uiInvoiceRadio1 = document.getElementById('invoiceRadio1');
            const uiInvoiceRadio2 = document.getElementById('invoiceRadio2');

            const uiFamilyName = document.getElementById('familyName');
            const uiPersonalName = document.getElementById('personalName');
            const uiPhoneNo = document.getElementById('phoneNo');
            const uiEmail = document.getElementById('email');
            const uiEducation = document.getElementById('education');
            const uiCityCounties = document.getElementById('cityCounties');
            const uiCityDistricts = document.getElementById('cityDistricts');
            const uiSchool = document.getElementById('school');
            const uiAgreement1 = document.getElementById('agreement1');
            const uiAgreement2 = document.getElementById('agreement2');
            // assgin params
            options["姓"] = uiFamilyName.value;
            options["名"] = uiPersonalName.value;
            options["行動電話"] = uiPhoneNo.value;
            options["電子信箱"] = uiEmail.value;
            education = uiEducation.options[uiEducation.selectedIndex].innerText;
            cityCounties = uiCityCounties.options[uiCityCounties.selectedIndex].innerText;
            cityDistricts = uiCityDistricts.options[uiCityDistricts.selectedIndex].innerText;
            school = uiSchool.options[uiSchool.selectedIndex].innerText;

            if (!options["姓"]) errMsg = "請填寫姓";
            else if (!options["名"]) errMsg = "請填寫名";
            else if (!options["行動電話"]) errMsg = "請填電話";
            else if (!options["電子信箱"]) errMsg = "請填電子信箱";
            else if (!education) errMsg = "請填學制";
            else if (!cityCounties) errMsg = "請填縣市";
            else if (!cityDistricts) errMsg = "請填鄉鎮";
            else if (!school) errMsg = "請填學校";
            else if (!uiAgreement1.checked) errMsg = "請同意退費流程";
            else if (!uiAgreement2.checked) errMsg = "請同意交易相關說明";
            // check invoce datas
            if (uiInvoiceRadio1.checked) options["發票類型"] = "二聯式";
            else if (uiInvoiceRadio2.checked) {
                const uiInvoiceInput1 = document.getElementById('invoiceInput1');
                const uiInvoiceInput2 = document.getElementById('invoiceInput2');
                if (uiInvoiceInput1.value && uiInvoiceInput2.value) {
                    options["發票類型"] = "三聯式";
                    options["統編號碼"] = uiInvoiceInput1.value;
                    options["發票抬頭"] = uiInvoiceInput2.value;
                }
                else {
                    errMsg = "請選發票類型";
                }
            }
            else {
                errMsg = "請選發票類型";
            }
        }

        try {
            if (!errMsg) {
                // disable btn or maybe show mask over all screen
                const uiBtnPay = document.getElementById('btnPay');
                uiBtnPay.disabled = true;

                setIsLoading(true);

                if (!datas) {
                    ParamsStorer.Instance().setData("userOrderInfos", null);
                    router.history.push(`/MyOrders`);
                }

                var course = datas && datas.length > 0 ? `${datas[0].course}` : "";

                var orderId = await create(userId, course, education, cityCounties, cityDistricts, school, options, payMoney);
                const paySuccessUrl = `${document.location.origin}/PurchaseSuccess?userId=${userId}&orderId=${orderId}`;
                pay(orderId, paySuccessUrl, discountCode)
                    .then(function (result) {
                        // enable btn
                        uiBtnPay.disabled = false;
                        switch (result.content.type) {
                            case "刷卡單次":
                            case "刷卡分期": {
                                ParamsStorer.Instance().setData("PurchaseRouter", result.content.html);
                                router.history.push(`/PurchaseRouter`);
                            }
                                break;
                            case "免費": {
                                // purchasing done, maybe redirect to courses list
                                setIsLoading(false);
                            }
                                break;
                        }
                    })
                    .catch(function (err) {
                        console.log(err.message ? err.message : err);
                        router.history.push('/PurchaseFailed');
                    });
            }
            else {
                alert(errMsg);
                setIsLoading(false);
            }
        }
        catch (err) {
            alert(err.message ? err.message : err);
            setIsLoading(false);
        }
    }, [discountCode, router]);

    const onChangeInvoice = (e) => {
        const key = e.target.value;
        setInvoice(key);
    }

    const loadingText = <div>
        <div>正在進入付款</div>
        <Loading />
    </div>

    const showRuleDialog = () => {
        setIsRule(true)
    }

    const selectRuleOkInput = useCallback(() => {
        if (!ruleCheckRef.current) return;
        ruleCheckRef.current.checked = true;
    }, [ruleCheckRef]);

    const ruleContent = (
        <RuleContent
            setIsRule={setIsRule}
            selectRuleOkInput={selectRuleOkInput}
        />
    );

    const checkDicountCode = useCallback((e) => {
        const data = {
            discountCode,
            userId,
            productPrice
        }

        fetch(`${process.env.REACT_APP_API_DOMAIN}/order/getDiscount`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-type": "application/json; charset=UTF-8" }
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                if (response.status === "success") {
                    const { discount: discountNum } = response.data;
                    console.log("discountNum", discountNum);
                    console.log("productPrice", productPrice);
                    let newDiscount
                    if (discountNum.indexOf('%') > -1) {
                        const percent = parseInt(discountNum.split("%")[0]) / 100
                        console.log("percent", percent);
                        newDiscount = -Math.ceil(productPrice - (productPrice * percent))
                        console.log("newDiscount", newDiscount);

                        payMoney = (productPrice + newDiscount)
                        if (payMoney <= 0) {
                            payMoney = 0
                        }
                        console.log("payMoney", payMoney);
                        setDiscount(Math.ceil(newDiscount));
                        setFinelPay(payMoney)
                        setDiscountMessage('')
                    } else if (discountNum.indexOf('only') > -1) {
                        newDiscount = parseInt(discountNum.split('only')[0])
                        payMoney = newDiscount
                        setDiscount(newDiscount);
                        setFinelPay(payMoney)
                        setDiscountMessage('')
                    } else {
                        payMoney = productPrice + parseInt(discountNum)
                        setDiscount(discountNum);
                        setFinelPay(payMoney)
                        setDiscountMessage('')
                    }
                } else {
                    console.log("response", response);
                    if (response.error.message === '已過期') {
                        setDiscountMessage('優惠代碼無效，可能是輸入錯誤或是已無法再使用')
                    } else if (response.error.message === '無此優惠碼') {
                        setDiscountMessage('優惠代碼無效，可能是輸入錯誤或是已無法再使用')
                    } else {
                        setDiscountMessage(response.error.message)
                    }
                }
            })
            .catch(function (err) {
                console.log(err.message ? err.message : err);
            });
    }, [discountCode, userId, productPrice])

    return (
        <Beforeunload>
            <div className={styles.purchaseFillInfos}>
                <DialogModule open={isLoading} content={loadingText} />
                <DialogModule open={isRule} content={ruleContent} />
                <Header />
                <div className={styles.mainArea}>
                    <div className={styles.back} onClick={() => backClick()}>
                        <img src="./assets/img/chevron_left_outline.svg" />
                        <span>返回上一頁</span>
                    </div>
                    <div className={styles.infos}>
                        <div>
                            <div className={styles.title}>
                                <b>購買資訊</b>
                            </div>
                            <br></br>
                            <div>
                                <span className={styles.itemName}>
                                    課程名稱
                                </span>
                                <span className={styles.itemValue}>
                                    {product()}
                                </span>
                                <br></br>
                            </div>
                            <br></br>
                            <div className={styles.productMoney}>
                                <h5 className={styles.itemName2}>優惠碼</h5>
                                <input
                                    type="text"
                                    id="discountCode"
                                    name=""
                                    value={discountCode}
                                    onChange={(e) => setDiscountCode(e.target.value)}
                                ></input>
                                <button onClick={(e) => checkDicountCode(e)}>使用</button>
                                <div className={styles.dicountErrorMessage}>{discountMessage}</div>
                            </div>
                            <div className={styles.productMoney}>
                                <h5 className={styles.itemName3}>商品金額</h5>
                                <span
                                    id="price"
                                    className={styles.itemValue}
                                ></span>
                            </div>
                            <div className={styles.productMoney}>
                                <h5 className={styles.itemName4}>折扣</h5>
                                <span
                                    className={styles.itemValue2}
                                >{discount}</span>
                            </div>
                            <div className={styles.productMoney}>
                                <h5 className={styles.itemName3}>付款金額</h5>
                                <span
                                    className={styles.itemValue}
                                >{finelPay}</span>
                            </div>
                            <br></br>
                            <div>
                                <span className={styles.itemName}>
                                    使用期限
                                </span>
                                <span className={styles.itemValue}>
                                    {expireDate()}
                                </span>
                                <br></br>
                            </div>
                            <br></br>
                            <div>
                                <span className={styles.itemName}>
                                    開立發票
                                </span>
                                <span className={styles.itemValue}>
                                    <div style={{ width: "100%" }}>
                                        <input
                                            type="radio"
                                            id="invoiceRadio1"
                                            name="invoiceType"
                                            value="invoice1"
                                            checked={invoice === "invoice1"}
                                            onChange={(e) => onChangeInvoice(e)}
                                        ></input>
                                        <span style={{ margin: "0px 5px" }}>
                                            二聯式
                                        </span>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <div style={{ float: "left" }}>
                                            <input
                                                type="radio"
                                                id="invoiceRadio2"
                                                name="invoiceType"
                                                value="invoice2"
                                                checked={invoice === "invoice2"}
                                                onChange={(e) => onChangeInvoice(e)}
                                            ></input>
                                            <span style={{ margin: "0px 5px" }}>
                                                三聯式，
                                            </span>
                                        </div>
                                        <div style={{ float: "left" }}>
                                            <div>
                                                <span>
                                                    統編號碼
                                                </span>
                                                <input
                                                    id="invoiceInput1"
                                                    type="text"
                                                    className={
                                                        styles.invoiceInput1
                                                    }
                                                ></input>
                                            </div>
                                            <div>
                                                <span>
                                                    發票抬頭
                                                </span>
                                                <input
                                                    id="invoiceInput2"
                                                    type="text"
                                                    className={
                                                        styles.invoiceInput2
                                                    }
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className={styles.userInfo}>
                            <br></br>
                            <br></br>
                            <br></br>
                            <hr className={styles.hr}></hr>
                            <br></br>
                            <h5>確認帳號資訊</h5>
                            <br></br>
                            <div style={{ width: "100%" }}>
                                <span className={styles.itemName}>帳號</span>
                                <span className={styles.itemValue}>
                                    {userAccount()}
                                </span>
                                <br></br>
                            </div>
                            <br></br>
                            <div style={{ width: "100%" }}>
                                <div className={styles.itemHalfName}>
                                    <div>*姓</div>
                                    <div>
                                        <input
                                            id="familyName"
                                            type="text"
                                            className={styles.itemHalfInput}
                                        ></input>
                                    </div>
                                </div>
                                <div
                                    className={styles.itemHalfName}
                                    style={{ marginLeft: "10px" }}
                                >
                                    <div>*名</div>
                                    <div>
                                        <input
                                            id="personalName"
                                            type="text"
                                            className={styles.itemHalfInput}
                                        ></input>
                                    </div>
                                </div>
                                <br></br>
                            </div>
                            <br></br>
                            <span className={styles.itemFullName}>
                                *行動電話
                            </span>
                            <br></br>
                            <div>
                                <input
                                    id="phoneNo"
                                    type="text"
                                    className={styles.itemFullInput}
                                ></input>
                                <br></br>
                            </div>
                            <span className={styles.itemFullName}>
                                *電子信箱(電子發票寄送用途)
                            </span>
                            <br></br>
                            <div>
                                <input
                                    id="email"
                                    type="text"
                                    className={styles.itemFullInput}
                                ></input>
                                <br></br>
                            </div>
                            <br></br>
                            <span className={styles.itemFullName}>*學制</span>
                            <br></br>
                            <div>
                                {createEducationItem()}
                                <br></br>
                            </div>
                            <br></br>

                            <div style={{ width: "100%" }}>
                                <div className={styles.itemHalfName}>
                                    <div>*縣市</div>
                                    {createCountiesItem()}
                                </div>
                                <div
                                    className={styles.itemHalfName}
                                    style={{ marginLeft: "10px" }}
                                >
                                    <div>*鄉鎮</div>
                                    {createDistrictsItem()}
                                </div>
                                <br></br>
                            </div>
                            <br></br>
                            <div className={styles.itemFullName}>*學校</div>
                            <br></br>
                            <div style={{ width: "100%" }}>
                                {createSchoolItem()}
                            </div>
                            <br></br>
                        </div>
                        <hr className={styles.hr}></hr>
                        <div style={{ paddingTop: "20px" }}>
                            <div>
                                <input
                                    type="checkbox"
                                    id="agreement1"
                                    name="agreement1"
                                    className={styles.agreeCheckBox}
                                ></input>
                                <span
                                    for="agreement1"
                                    className={styles.agreement}
                                >
                                    若辦理課程退費，本人同意由萬通教育股份有限公司代為處理發票及退費折讓行政流程。
                                </span>
                                <br></br>
                            </div>
                            <div>
                                <input
                                    ref={ruleCheckRef}
                                    type="checkbox"
                                    id="agreement2"
                                    name="agreement2"
                                    className={styles.agreeCheckBox}
                                ></input>
                                <span
                                    for="agreement2"
                                    className={styles.agreement2}
                                    onClick={() => showRuleDialog()}
                                >
                                    我已閱讀並同意課程交易相關說明。
                                </span>
                                <br></br>
                            </div>
                        </div>
                        <div
                            style={{
                                padding: "20px 0",
                                width: "100%",
                                textAlign: "center",
                            }}
                        >
                            <button id="btnPay" onClick={() => payOrder()}>
                                前往結帳
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Beforeunload>
    );
}

export default PurchaseFillInfos;