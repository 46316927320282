import React from 'react';
import { useStore, StoreTypes } from "context";
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import styles from "./index.module.scss";
import OrderLoading from 'components/Loading/OrderLoading';
import { useState, useCallback, useEffect } from 'react';
import classnames from "classnames";
import short_uuid from "util/uuid"

const CreateDiscount = (props) => {
	const { naniProducts } = props
	const params = (new URL(document.location)).searchParams;
	const [{ token, userId, name, isLogin }, userDispatch] = useStore(StoreTypes.user);
	const [productsList, setProducts] = useState(naniProducts)
	const [loading, setLoading] = useState(false)
	const [loadingMessage, setLoadingMessage] = useState('查詢中...')
	const [exportData, setExportData] = useState([])
	const [startTime, setStartTime] = useState(new Date);
	const [endTime, setEndTime] = useState((new Date).getTime() + (86400000 * 90));
	const [discountInfo, setDiscountInfo] = useState({
		id: '',
		name: '',
		directions: '',
		useCount: '1',
		autoCreateNumber: 1,
		autoFristCode: '',
		discount: '',
		reachAmount: '1',
		startDate: '',
		endDate: '',
	})
	const [discountCodes, setDiscountCodes] = useState('')

	const [discountPlanSelect, setDiscountPlanSelect] = useState('autoStr')
	const [discountPlan, setDiscountPlan] = useState({
		autoStr: '',
		autoCount: '',
		onlyCount: '',
	})

	const [selectDiscountCode, setSelectDiscountCode] = useState('customDiscount')
	const [selectUseCount, setSelectUseCount] = useState('useCount')

	useEffect(() => {
		if (naniProducts) {
			setProducts(naniProducts)
		}
	}, [naniProducts])

	const sendDiscountInfo = useCallback(() => {
		let { id, discount, useCount, autoFristCode } = discountInfo
		let newDiscountInfo = discountInfo

		if (selectDiscountCode === "customDiscount" && !discountCodes) return alert("自定義代碼不內為空值!")
		switch (discountPlanSelect) {
			case 'autoStr':
				if (!discountPlan[discountPlanSelect]) return alert("打折欄位不得為空值!")
				if (parseInt(discountPlan[discountPlanSelect]) < 50 || parseInt(discountPlan[discountPlanSelect]) > 99) {
					return alert("打折欄位只能輸入50~99")
				};
				discount = discountPlan[discountPlanSelect] + "%"
				break;
			case 'autoCount':
				if (!discountPlan[discountPlanSelect]) return alert("折抵欄位不得為空值!")
				if (parseInt(discountPlan[discountPlanSelect]) < 100 || parseInt(discountPlan[discountPlanSelect]) > 1000) {
					return alert("打折欄位只能輸入100~1000")
				};
				discount = "-" + discountPlan[discountPlanSelect]
				break;
			case 'onlyCount':
				if (!discountPlan[discountPlanSelect]) return alert("一口價欄位不得為空值!")
				if (parseInt(discountPlan[discountPlanSelect]) < 1000 || parseInt(discountPlan[discountPlanSelect]) > 2500) {
					return alert("打折欄位只能輸入1000~2500")
				};
				discount = discountPlan[discountPlanSelect] + "only"
				break;
		}

		console.log("endTime", (new Date(endTime)).getTime());
		console.log("nowTime", (new Date()).getTime());
		if ((new Date(endTime)).getTime() <= (new Date()).getTime()) {
			alert("結束時間不得小於現在時間!")
			return;
		}
		if (!startTime || !endTime) {
			alert("時間欄位不能為空值!")
			return;
		}

		newDiscountInfo = {
			discountInfo: {
				...discountInfo,
				agentId: userId,
				id,
				startDate: startTime,
				endDate: endTime,
				discount
			}
		}
		console.log("discountCodes", discountCodes);
		if (selectDiscountCode === "customDiscount") {
			newDiscountInfo.discountInfo.id = discountCodes
			newDiscountInfo.discountInfo.autoCreateNumber = 1
			if (selectUseCount === "noCount") {
				newDiscountInfo.discountInfo.useCount = -1
			}
		} else {
			newDiscountInfo.discountInfo.useCount = 1
		}
		console.log(newDiscountInfo);
		console.log("newDiscountInfo", newDiscountInfo);

		setLoading(true)
		fetch(`${process.env.REACT_APP_API_DOMAIN}/apis/setDiscountInfo`, {
			method: "POST",
			headers: { "Content-type": "application/json; charset=UTF-8" },
			body: JSON.stringify(newDiscountInfo)
		}).then(function (response) {
			return response.json();
		}).then(function (response) {
			setLoading(false)
			if (response.status === "success") {
				setExportData(response.data)
			} else {
				alert(response.error.message[0])
			}
		})
	}, [discountInfo, discountCodes, discountPlan, selectDiscountCode, selectUseCount, discountPlanSelect, startTime, endTime, userId])

	const getConsumedExchangeCodes = (exportData) => {
		if (!exportData) return;
		const row = exportData.map((info, index) => {
			const { id } = info
			return [id]
		})
		let csvContent = "data:text/csv;charset=utf-8,\uFEFF"
			+ row.map(e => e.join(",")).join("\n");
		var encodedUri = encodeURI(csvContent);
		window.open(encodedUri);
	}

	const inputNameHandler = useCallback((e, type) => {
		let value = e.target.value
		switch (type) {
			case 'reachAmount':
			case 'autoCreateNumber':
			case 'useCount':
				value = value.replace(/\D/g, '')
				if (parseInt(value) <= 1 || !value) {
					value = '1'
				};
				if (value.indexOf('.') > -1) {
					value = value.replace(/./g, '')
				}
				break;
			default:
				break;
		}
		setDiscountInfo({ ...discountInfo, [type]: value })
	}, [discountInfo])

	const inputDiscountHandler = useCallback((e) => {
		setDiscountCodes(e.target.value)
	}, [])

	const inputDiscountPlanHandler = useCallback((e, type) => {
		let value = e.target.value
		console.log(type);
		value = value.replace(/\D/g, '')
		if (parseInt(value) <= 0) {
			value = '0'
		};
		if (value.indexOf('.') > -1) {
			value = value.replace(/./g, '')
		}
		switch (type) {
			case 'autoStr':
				if (parseInt(value) > 99) {
					value = '99'
				};
				break;

			default:
				break;
		}

		console.log(value);
		setDiscountPlan({ ...discountPlan, [type]: value })
	}, [discountPlan])

	const selectDiscountCodeHandler = useCallback((e) => {
		setSelectDiscountCode(e.target.value)
	})

	const selectUseCountHandler = useCallback((e) => {
		setSelectUseCount(e.target.value)
	})

	const showExportData = (exportData) => {
		let dataStr = ''
		exportData.forEach(element => {
			dataStr += element.id + ','
		});
		return dataStr.substring(0, dataStr.length - 1)
	}
	return (
		<div className={styles.CreateDiscount}>
			{loading && <OrderLoading message={loadingMessage} />}
			<div className={styles.tabs}>
				<div
					id="mainLeft"
					className={styles.leftSelect}
				>
					<h5>折扣卷</h5>
					<div className={styles.discountName}>
						<div className={styles.name}>名稱</div>
						<input
							type='text'
							className={styles.nameInput}
							label=""
							placeholder="折扣碼名稱不會向賣家顯示"
							value={discountInfo.name}
							onChange={(e) => inputNameHandler(e, 'name')}
						/>

					</div>
					<div className={styles.discountRule}>
						<div>說明</div>
						<TextareaAutosize
							maxRows={4}
							aria-label="maximum height"
							placeholder="可記錄此次活動原由或特殊安排"
							defaultValue=""
							style={{ width: '100%', height: 100 }}
							value={discountInfo.directions}
							onChange={(e) => inputNameHandler(e, 'directions')}
						/>
					</div>


					<h5>折扣碼類型</h5>
					<div className={styles.discountType}>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							value={selectDiscountCode}
							name="radio-buttons-group"
							onChange={(e) => selectDiscountCodeHandler(e)}
						>
							<div className={styles.customDiscount}>
								<div>
									<FormControlLabel
										value={"customDiscount"}
										control={<Radio />}
										label="自定義代碼"
									/>
									<input
										type='text'
										className={classnames(styles.nameInput, {
											[styles.hide]: selectDiscountCode !== "customDiscount",
										})}
										min={8}
										label=""
										defaultValue=""
										placeholder="可自行輸入折扣碼，最少八碼"
										value={discountCodes}
										onChange={(e) => inputDiscountHandler(e)}
									/>
								</div>

								<div className={classnames(styles.customUseCount, {
									[styles.hide]: selectDiscountCode !== "customDiscount",
								})}>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										value={selectUseCount}
										name="radio-buttons-group"
										onChange={(e) => selectUseCountHandler(e)}
									>
										<div>
											<FormControlLabel value="useCount" control={<Radio />} label="使用次數" />
											<input
												type='text'
												className={styles.useCount}
												label=""
												defaultValue=""
												value={discountInfo.useCount}
												onChange={(e) => inputNameHandler(e, 'useCount')}
											/>
											{" 次"}
										</div>
										<FormControlLabel value="noCount" control={<Radio />} label="不限次數" />
									</RadioGroup>
								</div>
							</div>
							<div className={styles.autoDiscount}>
								<FormControlLabel value="autoDiscount" control={<Radio />} label="自動產出五位數亂碼" />
								<div className={classnames(styles.autoCreate, {
									[styles.hide]: selectDiscountCode !== "autoDiscount",
								})}>
									<div className={styles.autoFristText}>
										{"自動首字元 "}
										<input
											type='text'
											className={styles.strInput}
											label=""
											defaultValue=""
											placeholder="可不填"
											value={discountInfo.autoFristCode.replace(/[^\w=@#]|_/ig, '')}
											onChange={(e) => inputNameHandler(e, 'autoFristCode')}
										/>
									</div>
									<div>
										{"產出 "}
										<input
											type='text'
											min={1}
											className={styles.useCount}
											label=""
											defaultValue=""
											value={discountInfo.autoCreateNumber}
											onChange={(e) => inputNameHandler(e, 'autoCreateNumber')}
										/>
										{" 組"}
									</div>
								</div>

							</div>
						</RadioGroup>
					</div>
				</div>
				<div
					id="mainCenter"
					className={styles.centerSelect}
				>
					<h5>折扣方案</h5>
					<div className={styles.plan}>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							defaultValue="autoStr"
							name="radio-buttons-group"
							value={discountPlanSelect}
							onChange={(e) => setDiscountPlanSelect(e.target.value)}
						>
							<div>
								<FormControlLabel value="autoStr" control={<Radio />} label="打折" />
								<input
									type='text'
									className={classnames(styles.autoStrInput, {
										[styles.hide]: discountPlanSelect !== "autoStr",
									})}
									label=""
									defaultValue=""
									placeholder="要打95折，請輸入95(只能輸入50~99)"
									value={(discountPlan.autoStr)}
									onChange={(e) => inputDiscountPlanHandler(e, 'autoStr')}
								/>
								{" %"}
							</div>
							<div>
								<FormControlLabel value="autoCount" control={<Radio />} label="折抵" />
								<input
									type='text'
									className={classnames(styles.autoCountInput, {
										[styles.hide]: discountPlanSelect !== "autoCount",
									})}
									label=""
									defaultValue=""
									placeholder="輸入300，即是折抵300元(只能輸入100~1000)"
									value={discountPlan.autoCount.replace(/\D/g, '')}
									onChange={(e) => inputDiscountPlanHandler(e, 'autoCount')}
								/>
							</div>
							<div>
								<FormControlLabel value="onlyCount" control={<Radio />} label="一口價" />
								<input
									type='text'
									className={classnames(styles.onlyCountInput, {
										[styles.hide]: discountPlanSelect !== "onlyCount",
									})}
									label=""
									defaultValue=""
									placeholder="不管原價，只收固定加額(只能輸入1000~2500)"
									value={discountPlan.onlyCount.replace(/\D/g, '')}
									onChange={(e) => inputDiscountPlanHandler(e, 'onlyCount')}
								/>
							</div>
						</RadioGroup>
					</div>

					<h5>折扣條件</h5>
					<div className={styles.factor}>
						<div>
							{"折扣限制: 訂單金額達 "}
							<input
								type='text'
								className={styles.useCount}
								label=""
								defaultValue=""
								value={discountInfo.reachAmount}
								onChange={(e) => inputNameHandler(e, 'reachAmount')}
							/>
							{" 元以上"}
						</div>
						<div className={styles.selectTimeTitle}>
							{"折扣起訖"}
						</div>
						<div className={styles.selectTime}>
							<div className={styles.dateBtn}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateTimePicker
										label="開始時間"
										value={startTime}
										type="datetime-local"
										onChange={(value) => {
											setStartTime(value)
										}}
										renderInput={(params) => <TextField {...params} />}
									/>
								</LocalizationProvider>
							</div>
							<div className={styles.dateBtn}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateTimePicker
										label="結束時間"
										value={endTime}
										type="datetime-local"
										onChange={(value) => {
											setEndTime(value)
										}}
										renderInput={(params) => <TextField {...params} />}
									/>
								</LocalizationProvider>
							</div>
						</div>
						<div className={styles.sendBtn}>
							<Button onClick={() => sendDiscountInfo()}>送出</Button>
						</div>
					</div>
					<div className={styles.exportCSV}>
						<TextareaAutosize
							maxRows={4}
							aria-label="maximum height"
							placeholder=""
							defaultValue=""
							style={{ width: '100%', height: 50 }}
							value={showExportData(exportData)}
						/>
						<div className={styles.sendBtn}>
							<Button onClick={() => getConsumedExchangeCodes(exportData)}>匯出表單</Button>
						</div>
					</div>

				</div>
			</div>
		</div >
	);
}

export default CreateDiscount;