import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import { StoreTypes, useStore } from "context";
import styles from "./index.module.scss";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import classnames from "classnames";
import {
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TextField,
  Divider,
} from "@mui/material/";
import { grey } from "@mui/material/colors";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import StudentsInfoTable from "../StudentsInfoTable";
import OrganizationTable from "../OrganizationTable";
import ProductTable from "../ProductTable";
import ExportAllReport from "../ExportAllReport";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { timeFormatTommss } from "util/timeFormater";
import { exportJSONToCSV2 } from "util/exportJSONToCSV";

import {
  everyPageNumData,
  tabs,
  searchTypeDatas,
  exportUsersExcelHeaders,
  EveryMonth,
  TAB_NAME,
  SECOND_TAB_NAME,
  OrientationType
} from "constants/studentUserInfoTypes";
import { toHalfWidthChars } from "util/halfFullWidthWord";

const VideoExamSearch = ({ state }) => {
  const { setVideoExamFindType ,setOrientation}=state
  const [{ userId, token, role, isTeacher }] = useStore(StoreTypes.user);
  const { startTime, setStartTime, worker } = state;
  const { endTime, setEndTime } = state;
  const { loading, setLoading } = state;
  const { studentsInfo, setStudentsInfo } = state;
  const { userInfo, setUserInfo } = state;
  const { selectYear, setSelectYear } = state;
  const { selectMonth, setSelectMonth } = state;
  const [everyPageNum, setEveryPageNum] = useState(everyPageNumData[0]);
  const [pageIndex, setPageIndex] = useState(0);
  const {currentTab, setCurrentTab} = state;
  const {secondTab, setSecondTab} = state;
  const [searchType, setSearchType] = useState(searchTypeDatas[currentTab][secondTab][0].name);
  const [searchKey, setSearchKey] = useState("");
  const [isAdmin, setIsAdmin] = useState(null);
  const [schoolName, setSchoolName] = useState("");
  const [selectSchoolInfo, setSelectSchoolInfo] = useState(null);
  const [filterTab, setFilterTab] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [infoFlieCreateTime, setInfoFlieCreateTime] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState("查詢中...");


  const getStudentsInfoForSchool = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/apis/getStudentsInfo`,
      {
        method: "POST",
        body: JSON.stringify({
          isAdmin,
          userId,
          pageIndex: pageIndex,
          everyPageNum: everyPageNum,
          startDate: startTime,
          endDate: endTime,
          searchKey: toHalfWidthChars(searchKey),
          searchType: searchType,
          findSchoolid: isAdmin ? selectSchoolInfo ? selectSchoolInfo.id : null : null
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    const result = await res.json();

    if (result.status === "success") {
      if (isAdmin) {
        if (result.data.users[0]) {
          const schoolid = result.data.users[0].schoolID ? result.data.users[0].schoolID : result.data.users[0].schoolid
          const res = await fetch(
            `https://oidc.tanet.edu.tw/moeresource/api/v2/eduoid/all/eduid/${schoolid}`,
            { method: "GET" }
          ).then((res) => res.json()).catch(err => {
            console.log(err);
          });
          setSchoolName(res[0] ? res[0].schoolname : "未知")
          setLoading(false);
        } else { // 查無搜尋結果
          setLoading(false)
        }

        setStudentsInfo(result.data)
      } else {
        setStudentsInfo(result.data);
        setLoading(false);
      }
    } else {
      setStudentsInfo([]);
      setLoading(false);
    }
  }

  const getStudentsInfo = async (isAdmin) => {
    getStudentsInfoForSchool();
  };

  const getUserInfo = async () => {
    try {
      const getUserResult = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/apis/getStudyUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ id: userId }),
        }
      );

      const userResult = await getUserResult.json();
      if (userResult.data.identity !== "教師") {
        return;
      }
      if (userResult.data.isAdmin) {
        setIsAdmin(userResult.data.isAdmin);
      } else {
        setIsAdmin(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  const searchInfo = async (isAdmin) => {
    //setLoading(true);
    await getStudentsInfo(isAdmin)
  };

  useEffect(() => {
    if (!token || !userId) return;
    getUserInfo();
  }, [userId, token]);

  useEffect(() => {
    if (isAdmin === null) return;
    setCurrentTab(currentTab)
    setFilterTab(tabs)

    const currentYear = new Date().getFullYear(); // 获取当前年份
    const years = []; // 创建空数组用于存储年份

    for (let year = 2022; year <= currentYear; year++) {
      years.push(year); // 将每个年份添加到数组中
    }

    setYearList(years)
    setSelectYear(currentYear)
  }, [pageIndex, everyPageNum, currentTab, selectSchoolInfo, isAdmin]);

  const secondClick = (currentTab,secondTab) => {
    setSecondTab(secondTab)
    const targetKey=Object.keys(SECOND_TAB_NAME).find(key => SECOND_TAB_NAME[key].key === secondTab);

    setVideoExamFindType(SECOND_TAB_NAME[targetKey].type)
    setOrientation(OrientationType[currentTab][secondTab])
  }

  const getTable=(currentTab,studentsInfo)=>{
    switch (currentTab) {
      case TAB_NAME.STUDENT_RECORD:
        return <StudentsInfoTable 
                  searchType={searchType} 
                  searchKey={toHalfWidthChars(searchKey)} 
                  currentTab={currentTab} 
                  secondTab={secondTab} 
                  studentsInfo={studentsInfo} 
                  schoolName={schoolName} 
                  userInfo={userInfo} 
                  loading={loading} 
                  loadingMessage={loadingMessage} 
                />
      case TAB_NAME.ORGANIZATION_RECORD:
        return <OrganizationTable 
                  searchType={searchType} 
                  searchKey={toHalfWidthChars(searchKey)} 
                  currentTab={currentTab} 
                  secondTab={secondTab} 
                  studentsInfo={studentsInfo} 
                  schoolName={schoolName} 
                  userInfo={userInfo} 
                  loading={loading} 
                  loadingMessage={loadingMessage} 
                />
      case TAB_NAME.PRODUCT_RECORD:
        return <ProductTable 
                  searchType={searchType} 
                  searchKey={toHalfWidthChars(searchKey)} 
                  currentTab={currentTab} 
                  secondTab={secondTab} 
                  studentsInfo={studentsInfo} 
                  schoolName={schoolName} 
                  userInfo={userInfo}
                  loading={loading} 
                  loadingMessage={loadingMessage} 
                />
      default:
        break;
    }
  }

  const createSecondButton = (currentTab, secondTab) => {
    return < div className={styles.secondTypeContent} >
      <div className={styles.secondTypeBorder}>
        {
          tabs.find(tab => tab.name === currentTab).secondTabs.map((sTab) => {
            return <div
              className={classnames(styles.secondTab, {
                [styles.show]: secondTab === sTab,
              })}
              onClick={() => secondClick(currentTab,sTab)}
            >
              {sTab}
            </div>
          })
        }
      </div>
    </div>
  }

  return (
    <div className={styles.videoExamSearch}>
      <div className={styles.tabControl}>
        <Box
          sx={{
            display: "flex",
            px: "24px",
            py: "30px",
            fontSize: "18px",
            bgcolor: "#FEF2F0",
            alignItems: "center",
            width: "100%"
          }}
        >
          <Box
            sx={{
              color: "#212B36",
              fontWeight: 500,
              fontSize: "24px",
              display: "flex",
              alignItems: "center",
              width: "13%",
              display: "flex",
              justifyContent: "center",
              mr: "8px",
            }}
          >
            學習報告
          </Box>
          <div className={styles.tabs}>
            {filterTab.map((data) => (
              <li
                className={
                  data.name === currentTab && styles.currentTab
                }
                value={data.name}
                onClick={() => {
                  if (currentTab !== data.name) {
                    setCurrentTab(data.name);
                    setStudentsInfo([]);
                    setLoading(true);
                    setSecondTab(data.secondTabs[0])
                    const targetKey=Object.keys(SECOND_TAB_NAME).find(key => SECOND_TAB_NAME[key].key === data.secondTabs[0]);

                    setVideoExamFindType(SECOND_TAB_NAME[targetKey].type)
                    setOrientation(OrientationType[data.name][data.secondTabs[0]])
                    setSearchType(searchTypeDatas[data.name][data.secondTabs[0]][0].name)
                  }
                }}
              >
                {data.name}
              </li>
            ))}
          </div>
          {/* <ExportAllReport />  還沒做完暫時拿掉*/}
        </Box>
        {
          createSecondButton(currentTab, secondTab)
        }
        {
          <Box
            sx={{
              p: "24px",
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <div className={styles.searchConditionControl}>
              <FormControl sx={{ width: "190px" }} size="small">
                <InputLabel id="searchCondi" sx={{ color: grey[500] }}>
                  搜尋條件
                </InputLabel>
                <Select
                  id="searchCondi"
                  label="搜尋條件"
                  value={searchType}
                  IconComponent={KeyboardArrowUpIcon}
                  sx={{ borderRadius: "8px" }}
                >
                  {searchTypeDatas[currentTab][secondTab].map((data) => (
                    <MenuItem
                      value={data.name}
                      onClick={() => {
                        setSearchType(data.name);
                      }}
                    >
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={styles.roundedInput}>
                <TextField
                  variant="outlined"
                  placeholder="搜尋"
                  sx={{ width: "200px" }}
                  onChange={(event) => {
                    setSearchKey(event.target.value);
                  }}
                  value={searchKey}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: grey[500] }} />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                />
              </div>
              <Divider
                sx={{
                  backgroundColor: "#919EAB",
                  width: "36px",
                  transform: "rotate(90deg)",
                }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="起始日期"
                  value={startTime || null}
                  sx={{ width: "268px", overflow: "hidden" }}
                  onChange={(value) => {
                    const targetDate = dayjs(value);
                    const today = dayjs();
                    const diffInMonths = today.diff(targetDate, 'month');
                    // 判斷差距是否超過六個月
                    if (diffInMonths >= 12) {
                      alert('僅能提供一年內資料');
                    }else{
                      setStartTime(value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <img src={`./assets/img/date.svg`} alt="date" />
                      </InputAdornment>
                    ),
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} sx={{ width: "230px" }} />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="結束日期"
                  value={endTime || null}
                  sx={{ width: "268px" }}
                  onChange={(value) => {
                    setEndTime(value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <img src={`./assets/img/date.svg`} alt="date" />
                      </InputAdornment>
                    ),
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" sx={{ width: "230px" }} />
                  )}
                />
              </LocalizationProvider>
              {/* <button
                className={styles.searchBtn}
                onClick={() => searchInfo(isAdmin)}
              >
                搜尋
              </button> */}
            </div>
            <button
              className={styles.exportBtn}
              onClick={() => {
                //匯出實體每月報表
                //exportJSONToCSV(exportUsersExcelHeaders[0].headers, "yun-2023-04-03-studentInfo.json")

                //匯出當下報表
                exportJSONToCSV2({currentTab,secondTab,studentsInfo:studentsInfo.users,userInfo})
              }}
            >
              <img src={`./assets/img/export.svg`} alt="export" />
              匯出表單
            </button>
          </Box>
        }
        {studentsInfo && getTable(currentTab,studentsInfo)}
      </div>
    </div>
  );
};

export default VideoExamSearch;
