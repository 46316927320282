import { useUserEventHandler } from "events/eventHandlers/UserEventHandler";
import { useGetEducationInfo } from 'components/AppService/getEducationInfo';
import { useHistory } from "react-router-dom";
const useMobileRedirect = () => {
    const history = useHistory();
}
export const AppService = () => {
    useMobileRedirect();
    useUserEventHandler();
    useGetEducationInfo();
    return null;
};